import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import {
  Avatar as SproutAvatar,
  AvatarBadge,
  SeedAvatarProps,
  SeedAvatarSize,
} from '@daangn/sprout-components-avatar';

import { IconKing } from '../../assets/icons/svgs';
import { isHigherManager } from '../../utils';

type Props = {
  src?: string;
  size?: SeedAvatarSize;
  onClick?: () => void;
  role?: GroupMemberRoleEnum;
} & Pick<SeedAvatarProps, Exclude<keyof SeedAvatarProps, 'src' | 'alt' | 'fallback'>>;

const BadgeIcon = ({ size = 'small', role = 'none' }: Props) => {
  const defaultSize = 20;
  const sizeMap = new Map<SeedAvatarSize, number>([
    ['xxsmall', 8],
    ['xsmall', 10],
    ['small', 16],
    ['medium', defaultSize],
    ['large', defaultSize],
    ['xlarge', defaultSize],
  ]);

  return (
    <IconKing
      size={sizeMap.get(size)}
      fill={role === 'superHost' ? 'staticYellow300' : 'staticGreen400'}
    />
  );
};

const GroupAvatar = ({ src = '', size = 'small', onClick, role = 'none', ...props }: Props) => {
  return (
    <div onKeyDown={onClick} onClick={onClick} style={{ display: 'inline' }} role="button">
      <SproutAvatar
        src={src}
        size={size}
        fallback={
          <img
            src="https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png"
            alt="profile_default_image"
          />
        }
        alt=""
        {...props}
      >
        {isHigherManager(role) && (
          <AvatarBadge>
            <BadgeIcon size={size} role={role} />
          </AvatarBadge>
        )}
      </SproutAvatar>
    </div>
  );
};

export default GroupAvatar;
