import { UserMeetupMemberReviewMessage } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';
import { useMemo } from 'react';

import { MemberProfileSectionKey } from '@/components/group/profile/MemberProfileDetail/hooks/useMemberProfileScrollTo';
import { useFlow } from '@/stackflow';

import { ReviewMessageItem } from '../ReviewMessageItem';
import * as s from './style.css';

type Props = {
  meetupMemberReviewMessages: UserMeetupMemberReviewMessage[];
  userId: string;
  groupId?: string;
  isMyProfile: boolean;
};

export const ReviewMessageList = ({
  meetupMemberReviewMessages,
  userId,
  groupId = '',
  isMyProfile,
}: Props) => {
  const { push } = useFlow();

  const handleMoreButtonClick = () => {
    push('ProfileMeetupMemberReviewMessagesPage', {
      userId,
      groupId,
    });
  };

  const hasOnlyOneReviewMessage = meetupMemberReviewMessages.length === 1;

  const showMoreButton = useMemo(
    () =>
      meetupMemberReviewMessages.filter((meetupMemberReviewMessage) => {
        if (isMyProfile) return true;

        return meetupMemberReviewMessage.reviewExposureRange !== 'ONLY_ME';
      }).length > 2,
    [meetupMemberReviewMessages, isMyProfile]
  );

  if (hasOnlyOneReviewMessage)
    return (
      <div
        className={s.SingleItemWrapper}
        id={MemberProfileSectionKey.MEETUP_MEMBER_REVIEW_MESSAGES}
      >
        <ReviewMessageItem
          meetupMemberReviewMessage={meetupMemberReviewMessages[0]}
          isMyProfile={isMyProfile}
        />
      </div>
    );

  return (
    <div className={s.ListWrapper} id={MemberProfileSectionKey.MEETUP_MEMBER_REVIEW_MESSAGES}>
      {meetupMemberReviewMessages.map((meetupMemberReviewMessage, index) => {
        if (index > 1) return;

        return (
          <li key={meetupMemberReviewMessage.id} className={s.ListItemWrapper}>
            <ReviewMessageItem
              key={meetupMemberReviewMessage.id}
              meetupMemberReviewMessage={meetupMemberReviewMessage}
              isMyProfile={isMyProfile}
            />
          </li>
        );
      })}
      {showMoreButton && (
        <li className={s.MoreButtonWrapper} onClick={handleMoreButtonClick}>
          <Typography typography="label3Regular">더보기</Typography>
          <IconChevronRightFill size={14} />
        </li>
      )}
    </div>
  );
};
