import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog';
import { ActivityComponentType } from '@stackflow/react';

import { useConnectedFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

// params로 받아온 값을 이용하여 알럿을 노출
// 간단한 텍스트와 단순 확인 버튼만 있는 Alert를 띄워주고 싶을 때 사용
const SimpleAlertDialog: ActivityComponentType = () => {
  const { pop } = useConnectedFlow();

  const { title, description, primaryLabel, secondaryLabel } = useQueryParams();

  return (
    <DialogContainer
      onOutsideClick={() =>
        pop().send({
          type: 'cancel',
        })
      }
    >
      <AlertDialog
        title={title}
        description={description ?? ''}
        primaryActionLabel={primaryLabel ?? '확인'}
        secondaryActionLabel={secondaryLabel}
        onPrimaryAction={() => {
          pop().send({
            type: 'confirm',
          });
        }}
        secondaryActionIntent="neutral"
        onSecondaryAction={() => {
          pop().send({
            type: 'cancel',
          });
        }}
      />
    </DialogContainer>
  );
};

export default SimpleAlertDialog;
