import { GroupRestrictedMemberPresentation } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';

import { usePostRestrictedMemberReJoin } from '@/api/hooks/usePostRestrictedMemberReJoin';
import { usePostRestrictedMemberRestrict } from '@/api/hooks/usePostRestrictedMemberRestrict';
import { usePostRestrictedMemberUnblock } from '@/api/hooks/usePostRestrictedMemberUnblock';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { openGroupProfileReport } from '@/utils/link';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

type Props = {
  groupId: string;
  onReportProfile?: () => void;
} & GroupRestrictedMemberPresentation;

const MemberProfileDetailRestrictedBottomSheet = ({
  groupId,
  id: userId,
  nickname,
  restrictedState,
}: Props) => {
  const { close: closeBottomSheet } = useBottomSheet();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate: mutateRejoin } = usePostRestrictedMemberReJoin({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: `${nickname}님을 다시 초대했어요.`,
        },
      });
      refetchGroupMemberList({ groupId });
      closeBottomSheet();
    },
    onError: (error) => {
      handleErrorWithToast(error);
      closeBottomSheet();
    },
  });

  const { mutate: mutateRestrict } = usePostRestrictedMemberRestrict({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: `${nickname}님을 차단했어요.`,
        },
      });
      refetchGroupMemberList({ groupId });
      closeBottomSheet();
    },
    onError: (error) => {
      handleErrorWithToast(error);
      closeBottomSheet();
    },
  });

  const { mutate: mutateUnblock } = usePostRestrictedMemberUnblock({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: `${nickname}님의 차단을 해제했어요.`,
        },
      });
      refetchGroupMemberList({ groupId });
      closeBottomSheet();
    },
    onError: (error) => {
      handleErrorWithToast(error);
      closeBottomSheet();
    },
  });

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={() => {
            mutateRejoin({
              groupId,
              userId: userId.toString(),
            });
          }}
        >
          다시 초대
        </ActionListItem>
        {restrictedState === 'RESTRICTED' ? (
          <ActionListItem
            onClick={() => {
              mutateUnblock({
                groupId,
                userId: userId.toString(),
              });
            }}
          >
            차단 해제
          </ActionListItem>
        ) : (
          <ActionListItem
            onClick={() => {
              mutateRestrict({
                groupId,
                userId: userId.toString(),
              });
            }}
          >
            차단
          </ActionListItem>
        )}
        <ActionListItem
          onClick={() => {
            trackEvent({
              event: 'click_report',
              params: {
                type: 'profile',
              },
            });
            closeBottomSheet();
            openGroupProfileReport({
              groupId: groupId.toString() ?? '',
              userId: userId.toString() ?? '',
            });
          }}
        >
          신고
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};

export default MemberProfileDetailRestrictedBottomSheet;
