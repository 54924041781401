import React from 'react';

import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Props = {
  groupId: string;
};

const useCheckRequestMeetupReviewIdParams = ({ groupId }: Props) => {
  const { replace } = useFlow();

  const { requestMeetupReviewId: meetupId } = useQueryParams();

  const requestMeetupReviewIdCheck = React.useCallback(() => {
    if (meetupId) {
      replace(
        'GroupPostNewPage',
        {
          groupId,
          meetupId,
          postType: 'meetupReview',
        },
        {
          animate: false,
        }
      );

      return true;
    }
    return false;
  }, [groupId, meetupId, replace]);

  return requestMeetupReviewIdCheck;
};

export default useCheckRequestMeetupReviewIdParams;
