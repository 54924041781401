import { groupClient } from '@community-group/api';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { queryClient } from '../instance';
import { useFetchInstance } from './instance/useFetchInstance';

export const getPostCommentsPath = (groupId?: string, postId?: string) =>
  groupClient.api.CommentApi.getapiV1GroupsIdPostsPostIdCommentsGetPath(
    Number(groupId),
    Number(postId)
  );

export const getMeetupCommentsPath = (groupId?: string, meetupId?: string) =>
  groupClient.api.CommentApi.getapiV1GroupsIdMeetupsMeetupIdCommentsGetPath(
    Number(groupId),
    Number(meetupId)
  );

type RelatedContentType = 'post' | 'meetup';

type Params = {
  groupId?: string;
  relatedId?: string;
  commentId?: string;
  relatedContentType?: RelatedContentType;
  limit?: number;
  order?: 'created_at_desc' | 'created_at_asc';
  subCommentLimit?: number;
  subCommentOrder?: 'created_at_desc' | 'created_at_asc';
};

export const useGetComments = ({
  groupId,
  relatedId,
  commentId,
  relatedContentType,
  limit = 15,
  order = 'created_at_asc',
  subCommentLimit = 6,
  subCommentOrder = 'created_at_desc',
}: Params) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsGet
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsGet;

  const fetchInstance = useFetchInstance();
  const getComments = apiMethod({
    axios: fetchInstance,
  });

  const path = isPostComment
    ? getPostCommentsPath(groupId, relatedId)
    : getMeetupCommentsPath(groupId, relatedId);
  const parentCommentId = commentId ? Number(commentId) : undefined;

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [path, parentCommentId],
    ({ pageParam = undefined }) =>
      getComments(
        Number(groupId),
        Number(relatedId),
        parentCommentId,
        pageParam === null ? undefined : pageParam,
        limit,
        order,
        subCommentLimit,
        subCommentOrder
      ),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data.endCursor : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};

const updateCommentsEmotion =
  (addMyEmotion: boolean, relatedContentType: RelatedContentType) =>
  (groupId?: string, relatedId?: string, commentId?: string) => {
    if (!groupId || !relatedId || !commentId) return;

    const parentCommentId = commentId ? Number(commentId) : undefined;
    const updateEmotion = (comment) => {
      if (comment.id === parentCommentId) {
        return {
          ...comment,
          emotion: {
            count: addMyEmotion ? comment.emotion.count + 1 : comment.emotion.count - 1,
            myEmotion: addMyEmotion ? 'like' : '',
          },
        };
      }

      return comment;
    };

    const path =
      relatedContentType === 'post'
        ? getPostCommentsPath(groupId, relatedId)
        : getMeetupCommentsPath(groupId, relatedId);

    queryClient.setQueriesData<InfiniteData<AxiosResponse<groupClient.model.CommentListResponse>>>(
      [path],
      (prev) => {
        if (!prev) return;

        const next = prev.pages.map((page) => {
          const comments = page.data.comments.map(updateEmotion);

          return {
            ...page,
            data: {
              ...page.data,
              comments: comments.map((comment) => ({
                ...comment,
                subComments: comment.subComments.map(updateEmotion),
              })),
            },
          };
        });

        return {
          pages: next,
          pageParams: prev.pageParams,
        };
      }
    );
  };

export const deleteLikePostComments = updateCommentsEmotion(false, 'post');
export const patchLikePostComments = updateCommentsEmotion(true, 'post');

export const deleteLikeMeetupComments = updateCommentsEmotion(false, 'meetup');
export const patchLikeMeetupComments = updateCommentsEmotion(true, 'meetup');
