import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { isDevelopmentAppStage, isExposeInternalUser } from '@/utils/check';

import { isDuringRealJoin11월독서GroupMarketingPromotion } from '../utils';

function useGroup11월독서MarketingPromotion() {
  const [marketingPromotionBottomSheet] = useStorage('promotion_group_2411_reading_create', false);
  const [marketingPromotionBottomSheetJoin] = useStorage(
    'promotion_group_2411_reading_join',
    false
  );
  const { push } = useFlow();
  const { userConfig } = useUserConfig();

  const { is11월독서MarketingJoinable, is11월독서MarketingCreate } = useStore();

  const isOngoing11월독서GroupJoinMarketingPromotion = useFeatureFlag(
    'shown11월독서MarketingPromotion'
  );

  const validIsOnGoing11월독서GroupCreateMarketingPromotion = () => {
    if (isDevelopmentAppStage()) return true;

    if (!isOngoing11월독서GroupJoinMarketingPromotion) return false;

    if (marketingPromotionBottomSheet) return false;

    if (!isDuringRealJoin11월독서GroupMarketingPromotion()) return false;

    return true;
  };

  const validIsOnGoing11월독서GroupJoinMarketingPromotion = () => {
    if (isDevelopmentAppStage()) return true;
    if (isExposeInternalUser(userConfig?.userId?.toString() ?? '', false)) return true;

    if (!isOngoing11월독서GroupJoinMarketingPromotion) return false;

    if (marketingPromotionBottomSheetJoin) return false;

    if (!isDuringRealJoin11월독서GroupMarketingPromotion()) return false;

    return true;
  };

  const handleOpen11월독서MarketingPromotionBottomSheet = ({
    groupId,
    type,
  }: {
    groupId: string;
    type: 'create' | 'join' | 'apply';
  }) => {
    if ((type === 'join' || type === 'apply') && is11월독서MarketingJoinable) {
      const isValid = validIsOnGoing11월독서GroupJoinMarketingPromotion();
      if (!isValid) return false;

      setTimeout(() => {
        push('BottomSheet/DoneJoinGroup11월독서MarketingPromotionBottomSheet', {
          groupId,
          type,
        });
      }, 500);
      return true;
    }

    if (type === 'create') {
      const isValid = validIsOnGoing11월독서GroupCreateMarketingPromotion();
      if (!isValid) return false;

      setTimeout(() => {
        push('BottomSheet/DoneCreateGroup11월독서MarketingPromotionBottomSheet', {
          groupId,
        });
      }, 500);
      return true;
    }
    return false;
  };

  return {
    handleOpen11월독서MarketingPromotionBottomSheet,
    validIsOnGoing11월독서GroupCreateMarketingPromotion,
  };
}

export default useGroup11월독서MarketingPromotion;
