import { groupClient } from '@community-group/api';

import { getApplicationPath } from '@/api/hooks/useGetApplications';
import { getGroupMePath } from '@/api/hooks/useGetGroupMe';
import { getMemberListPath, MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { queryClient } from '@/api/instance';

type Params = {
  groupId: string;
  order?: MemberListOrderType;
  shownOnlyHost?: boolean;
  selectedMemberOrderType?: MemberListOrderType;
};

/*
 *  모임 멤버목록 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupMemberList = ({
  groupId,
  order,
  shownOnlyHost,
  selectedMemberOrderType,
}: Params) => {
  queryClient.refetchQueries([getMemberListPath(groupId)]);
  queryClient.refetchQueries([
    getMemberListPath(groupId),
    order,
    shownOnlyHost,
    selectedMemberOrderType,
  ]);
  queryClient.refetchQueries([getGroupMePath(groupId)]);
  queryClient.refetchQueries([getApplicationPath(groupId)]);
  queryClient.refetchQueries([
    groupClient.api.GroupRestrictedMemberApi.getapiV1GroupsIdRestrictedMembersGetPath(
      Number(groupId)
    ),
  ]);
};
