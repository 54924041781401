import { axiosClient } from '@community-group/api';

const STORAGE_BASE_URL = globalThis.appConfig.isAppEnvProduction
  ? 'https://community-web-kv.karrot.workers.dev/'
  : 'https://community-web-kv-alpha.karrot.workers.dev/';
const STORAGE_BASE_PATH = '/api/storage';

export type StorageRequestData = {
  value: string;
  metadata: object;
};

const initStorageInstance = () => {
  const instance = axiosClient.initFetchInstance(
    {
      baseURL: STORAGE_BASE_URL,
    },
    {}
  );

  return {
    get: async (key: string) => {
      const response = await instance.get(`${STORAGE_BASE_PATH}/${key}`);

      return response.data;
    },
    put: async (key: string, data: StorageRequestData) => {
      await instance.put(`${STORAGE_BASE_PATH}/${key}`, data);
    },
    delete: async (key: string) => {
      await instance.delete(`${STORAGE_BASE_PATH}/${key}`);
    },
  };
};

export const storageInstance = initStorageInstance();
