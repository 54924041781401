import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { useStore } from '@/store';

interface FixedLayoutContextProps {
  isFixedLayout: boolean;
  handleFixedLayout: (options: boolean) => void;
}

const FixedLayoutContext = createContext<FixedLayoutContextProps | undefined>(undefined);

export const FixedLayoutProvider = ({ children }: { children: ReactNode }) => {
  const { fixedLayout, setFixedLayout } = useStore();
  const [isFixedLayout, setIsFixedLayout] = useState(fixedLayout);

  const handleFixedLayout = useCallback(
    (options: boolean) => {
      setIsFixedLayout(options);
      setFixedLayout(options);
    },
    [setFixedLayout]
  );

  const controls = useMemo(
    () => ({ isFixedLayout, handleFixedLayout }),
    [isFixedLayout, handleFixedLayout]
  );

  return <FixedLayoutContext.Provider value={controls}>{children}</FixedLayoutContext.Provider>;
};

export const useFixedLayout = () => {
  const controls = useContext(FixedLayoutContext);

  if (controls === undefined) {
    throw new Error('FixedLayoutContext 안에서 사용해주세요.');
  }

  return controls;
};
