import { useMemo } from 'react';

import * as s from './index.css';

interface Props {
  text: string;
}

const Highlight = ({ text }: Props) => {
  const splitedText = text.split(HighlightTagRegex);
  const splitedTextLength = splitedText.length;

  const wrappedHighlightText = useMemo(() => {
    return splitedText.map((text, index) => {
      const isFirstText = index === 0;
      const isLastText = index === splitedTextLength;
      const isNeedsEmphasis = index % 2; // <em> 등의 tag가 있을 때 split 했기 때문에 홀수번째 순서에 Bold로 감싸줘야함

      if (isFirstText) return text;
      if (isLastText) return text;

      if (isNeedsEmphasis) {
        return (
          <span className={s.bold} key={`${text}-${index}`}>
            {text}
          </span>
        );
      }

      return text;
    });
  }, [splitedText]);

  return <>{wrappedHighlightText}</>;
};

const HighlightTagRegex = new RegExp('<em>|</em>|<mark>|</mark>');

export default Highlight;
