import React from 'react';

import { useFlow } from '@/stackflow';

import useTurnOnNotificationBottomSheet from '../../Detail/hooks/useTurnOnNotificationBottomSheet';

interface Props {
  groupId: string;
}

const useCheckTurnOnNotificationBottomSheet = ({ groupId }: Props) => {
  const { isRenderBottomSheet } = useTurnOnNotificationBottomSheet(groupId);
  const { push } = useFlow();

  const checkTurnOnNotificationBottomSheet = React.useCallback(() => {
    if (isRenderBottomSheet()) {
      push('BottomSheet/TurnOnNotificationBottomSheet', {
        groupId: groupId,
      });
      return true;
    }
    return false;
  }, [isRenderBottomSheet, push, groupId]);

  return checkTurnOnNotificationBottomSheet;
};

export default useCheckTurnOnNotificationBottomSheet;
