import { vars } from '@seed-design/design-token';

import { RESIZE_IMAGE_PRESET, resizeImage } from '../../utils';

type Props = {
  src?: string;
  size?: number;
  borderRadius?: number;
  badge?: React.ReactNode;
};

const Thumbnail = ({ src = '', size = 64, borderRadius = 12.4, badge }: Props) => {
  const size2X = size * 2.5;
  const url = resizeImage(src, {
    ...RESIZE_IMAGE_PRESET.thumbnail_default,
    size: `${size2X}x${size2X}`,
  });

  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: `${borderRadius}px`,
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'block',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: `${borderRadius}px`,
          border: `${borderRadius === 0 ? 'none' : `1px solid ${vars.$scale.color.grayAlpha50}`}`,
          boxSizing: 'border-box',
        }}
      />
      {badge}
    </div>
  );
};

export default Thumbnail;
