import { Spacing, Typography } from '@community-group/components';
import { PropsWithChildren } from 'react';

import { AppScreen } from '@/stackflow/components/AppScreen';

import * as s from './PhotoContestGuidePage.css';

const PhotoContestGuideV3Page = () => {
  return (
    <AppScreen
      appBar={{
        title: '안내',
      }}
    >
      <div className={s.Container}>
        <Typography typography="subtitle1Bold" color="gray900">
          이벤트 참여 방법
        </Typography>
        <Spacing size={4} />
        <Typography typography="caption1Regular" color="gray900">
          [이벤트 1]
        </Typography>
        <ListItem prefix="1.">
          우리동네 운동 모임을 둘러보고 마음에 드는 운동 모임에 가입해요.
        </ListItem>
        <ListItem prefix="2.">
          멤버들과 온/오프라인으로 모여 함께 &apos;러닝&apos; 인증샷을 찍어요.
        </ListItem>
        <ListItem prefix="3.">
          글쓰기를 누르고 &apos;전국모임자랑&apos; 참여를 선택한 뒤 인증샷을 업로드해요.
        </ListItem>
        <ListItem>
          이번 이벤트는 &apos;운동&apos; 카테고리로 개설된 모임만 참여할 수 있어요.
        </ListItem>
        <Spacing size={16} />
        <Typography typography="caption1Regular" color="gray900">
          [이벤트 2]
        </Typography>
        <ListItem prefix="1.">전국모임자랑에 올라온 모임 활동 인증샷을 구경해요.</ListItem>
        <ListItem prefix="2.">마음에 드는 사진을 발견하면 게시글에 공감을 눌러요.</ListItem>
        <ListItem prefix="3.">많은 글에 공감을 눌러 공감왕에 도전해보세요.</ListItem>
        <Spacing size={24} />
        <Typography typography="subtitle1Bold" color="gray900">
          이벤트 진행 기간
        </Typography>
        <Spacing size={4} />
        <ListItem>2024년 08월 13일 (화) 16시 ~ 2024년 08월 20일 (화) 16시</ListItem>
        <Spacing size={24} />
        <Typography typography="subtitle1Bold" color="gray900">
          상품 지급 안내
        </Typography>
        <Spacing size={4} />
        <ListItem>
          [이벤트 1] 게시글의 공감 수(70%)와 운영진 평가 점수(30%)를 합산하여 순위가 결정됩니다.
        </ListItem>
        <div className={s.SubContentContainer}>
          <ListItem>사진에 인물이 포함된 경우에만 상금 지급 대상이 됩니다.</ListItem>
        </div>
        <ListItem>
          [이벤트 2] &apos;공감왕&apos;은 공감을 가장 많이 누른 순으로 결정됩니다.
        </ListItem>
        <ListItem>상금은 게시글 작성자에게 당근머니로 지급됩니다.</ListItem>
        <ListItem>이벤트 종료 후 수상자분들에게는 채팅 등을 통해 개별 알림을 드립니다.</ListItem>
        <ListItem>중복 당첨은 불가합니다.</ListItem>
        <Spacing size={24} />
        <Typography typography="subtitle1Bold" color="gray900">
          유의사항
        </Typography>
        <Spacing size={4} />
        <ListItem>
          {`이벤트에 참여한 글과 사진, 닉네임, 모임명은 추후 당근 내 콘텐츠에 활용 및 가공될 수 있습니다.`}
        </ListItem>
        <ListItem>아래의 경우에 해당하는 경우 당첨자 제외 및 당첨이 취소될 수 있습니다.</ListItem>
        <div className={s.SubContentContainer}>
          <ListItem>무의미하거나 반복적인 글, 댓글을 작성한 경우</ListItem>
          <ListItem>운영 정책을 위반한 글, 댓글을 작성한 경우</ListItem>
          <ListItem>상품 지급일 이전에 작성한 글이나 댓글을 삭제한 경우</ListItem>
          <ListItem>상품 지급일 이전에 당근 및 가입한 모임에서 탈퇴, 제재된 사용자인 경우</ListItem>
          <ListItem>상품 지급 기간 내에 당첨 정보를 제출하지 않은 경우</ListItem>
          <ListItem>이미지 무단 도용 등 타인의 권리를 침해하는 경우</ListItem>
          <ListItem>모임 참여자들의 얼굴이 모두 가려져있는 경우</ListItem>
        </div>
        <ListItem>운영 정책 위반 시 서비스 이용이 제한될 수 있습니다.</ListItem>
        <ListItem>주제와 맞지 않는 글, 사진은 이벤트 참여가 취소될 수 있습니다.</ListItem>
        <ListItem>
          상품 미지급 관련 문의는 수상자 발표 후 7일 이내에 고객센터의 문의하기를 통해 접수해주세요.
        </ListItem>
        <ListItem>기한 이후에는 이벤트 관련 문의가 불가하다는 점을 유의해주세요.</ListItem>
        <ListItem>
          상금 지급 시점에 당근페이에 가입되어 있어야 하며, 미가입 시 지급이 취소됨을 유의해 주세요.
        </ListItem>
        <ListItem>이벤트와 관련 없는 게시글은 예고없이 사진전에서 미노출될 수 있습니다.</ListItem>
        <ListItem>
          이벤트 일정 및 내용이 변경될 수 있으며, 사전 예고 없이 종료될 수 있습니다.
        </ListItem>
      </div>
    </AppScreen>
  );
};

export default PhotoContestGuideV3Page;

const ListItem = ({ prefix = '∙', children }: PropsWithChildren<{ prefix?: string }>) => (
  <div className={s.ListItem}>
    <Typography typography="caption1Regular" color="gray900">
      {prefix}
    </Typography>
    <Typography typography="caption1Regular" color="gray900">
      {children}
    </Typography>
  </div>
);
