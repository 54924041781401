import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  id: number;
  postId: number;
  limit?: number;
  joinedUser?: boolean;
};

export const getGroupPostEmotionListPath = (id: number, postId: number) =>
  groupClient.api.PostApi.getapiV1GroupsIdPostsPostIdEmotionsGetPath(id, postId);

export const useGetGroupPostEmotionList = ({ id, postId, limit = 30, joinedUser }: Props) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.PostApi.apiV1GroupsIdPostsPostIdEmotionsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getGroupPostEmotionListPath(id, postId), joinedUser],
    ({ pageParam = undefined }) => {
      const cursor = pageParam === null ? undefined : pageParam;
      return get(id, postId, cursor, limit, joinedUser);
    },
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
