import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { getMyGroupListPath, GROUP_URL } from '../base/group';
import { queryClient } from '../instance';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<undefined, Error, string>;

export const usePostLeftGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();

  const postLeftGroup = async (id: string) => {
    const { data } = await fetchInstance.post<undefined, AxiosResponse>(`${GROUP_URL}/${id}/left`);

    return data;
  };

  return useThrottleMutation(postLeftGroup, {
    onError,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });

      onSuccess?.(data, variables, context);
    },
  });
};
