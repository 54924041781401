import { useEffect } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';

// 유저가 사전신청 마케팅 페이지에서 넘어왔는지 확인해서 저장하는 훅
const useCheckUserFrom11월독서MarketingPromotion = () => {
  const { from = '' } = useQueryParams();

  // 해당 진입 세션에서만 사전신청 가능해야해서 store에 저장
  const { set11월독서MarketingJoinable, set11월독서MarketingCreate } = useStore();

  const isCheck11월독서 = from === 'create_11월독서_marketing';

  useEffect(() => {
    if (from === 'join_11월독서_marketing') {
      set11월독서MarketingJoinable(true);
    }

    if (from === 'create_11월독서_marketing') {
      set11월독서MarketingCreate(true);
    }
  }, [from, set11월독서MarketingCreate, set11월독서MarketingJoinable]);

  return {
    isCheck11월독서,
  };
};

export default useCheckUserFrom11월독서MarketingPromotion;
