import { vars } from '@seed-design/design-token';

import * as s from './index.css';

type Props = {
  background?: string;
  label: string;
  prefixIcon?: React.ReactNode;
};

// 모임에서만 사용하는 Badge 스타일이에요. (디자인 시스템의 Badge 보다 size가 커요.)
export const GroupCustomBadge = ({
  background = vars.$scale.color.gray100,
  label,
  prefixIcon,
}: Props) => {
  if (label.length === 0) return null;

  return (
    <div className={s.wrapper} style={{ background }}>
      {prefixIcon && <div className={s.iconWrapper}>{prefixIcon}</div>}
      {label}
    </div>
  );
};
