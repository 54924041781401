import { Image, Video } from '@community-group/api/lib/group/models';
import { AsyncBoundary, isMember, ViewError } from '@community-group/components';
import { MouseEvent, useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { MeetupBanner } from '@/components/common/Banner/MeetupBanner';
import { Container } from '@/components/common/Container';
import LinkifyContainer from '@/components/common/Linkify';
import OpenGraph from '@/components/common/OpenGraph';
import { PoiMapCard } from '@/components/common/PoiMapCard';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { usePostNotificationTurnOffSnackbarWithPostDetail } from '@/hooks/PostNotification/usePostNotificationTurnOffSnackbar';
import { useCurrentPosition } from '@/hooks/useCurrentPosition';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { isShowOpenGraphContent } from '@/utils/check';
import { openPoiDetailPage } from '@/utils/link';

import { PollCard } from '../../../Poll/PollCard';
import { PostDetailError } from './../PostDetailError';
import PostDetailBreadcrumbs from './Breadcrumbs';
import PostDetailImages from './PostDetailImages';
import * as s from './PostDetailInformation.css';
import Reaction from './Reaction';
import VideoPlayerItem from './VideoPlayerItem';
import WriterUserInformation from './WriterUserInformation';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleRestrictedUser } from '@/hooks/useHandleRestrictedUser';

type Props = {
  onClickCommentIcon?: () => void;
};

const PostDetailInformation = ({ onClickCommentIcon }: Props) => {
  const { groupId, postId } = usePathParams();
  const { group } = useGetGroupDetail(groupId);
  const { data: geolocationData } = useCurrentPosition();
  const { push } = useFlow();
  const { handleGroupOnly } = useHandleGroupOnly({ groupId });
  const { replace } = useFlow();

  useHandleRestrictedUser({
    serviceType: 'post',
    groupId,
    postId,
  });

  const geolocationPermission =
    geolocationData?.geolocation?.geolocationAccessResponse.granted ?? false;

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { post, error: postError } = useGetPostDetail(groupId, postId, {
    onError: (error) => {
      handleErrorWithThrowErrorStatusPage({
        error,
        serviceType: 'post',
        groupId,
        postId,
      });
    },
  });

  const canReadMeetup = useMemo(() => {
    const isGroupMember = isMember(post.currentUserInfo?.role);

    return post?.meetupInfo?.exposureRange === 'ALL' ? true : isGroupMember;
  }, [post]);

  usePostNotificationTurnOffSnackbarWithPostDetail({
    groupId,
    groupName: group?.name,
  });

  const handleVotedMembersClick = (pollId: string) => {
    push('GroupPollVotedMembersPage', {
      groupId,
      postId: post?.id.toString() ?? '',
      pollId,
    });
  };

  const handleSelectedOptionIdsChange = (onChanged: () => void) => {
    handleGroupOnly({
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getPoll,
      onSettled: () => {
        onChanged();
      },
      onSuccess() {
        // TODO: groupPostInfo 에 isSettingSubNickname 추가
        // if (groupInfo.isSettingSubNickname) {
        //   push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
        //     groupId,
        //   });
        // }
      },
    });
  };

  const handleMeetupBannerClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!post?.meetupInfo) return;

    push('GroupMeetupDetailPage', {
      groupId,
      meetupId: post.meetupInfo.id.toString(),
    });
  };

  const mediaVideos = post.medias
    ?.filter((media) => media.type === 'video')
    .map((media) => media.media as Video);

  const mediaImages = post.medias
    ?.filter((media) => media.type === 'image')
    .map((media) => media.media as Image);

  // 본문 존재 여부에 따라 제목의 폰트가 다름
  // 관련 이슈 : https://daangn.slack.com/archives/C0336FFHA8L/p1710816075026709
  const renderPostTitleAndContent = useMemo(() => {
    if (!post) return;
    return (
      <>
        <div className={s.TextContent}>
          <LinkifyContainer text={post.content} />
        </div>
      </>
    );
  }, [post]);

  if (!post) {
    if (postError.response?.status === 404) return <PostDetailError />;

    return <ViewError />;
  }

  return (
    <div className={s.Wrapper}>
      <Container key={post.id} className={s.Contents}>
        <PostDetailBreadcrumbs post={post} group={group} />
        <WriterUserInformation post={post} />
        <div className={s.TextContentsWrapper}>{renderPostTitleAndContent}</div>
        {isShowOpenGraphContent(post.content) && <OpenGraph text={post.content} type="DETAIL" />}
        {post.poll && (
          <PollCard
            poll={post.poll}
            myPollVoting={post.myPollVoting}
            groupPostInfo={{
              groupId,
              postId,
              groupName: post.groupInfo?.name ?? '',
            }}
            currentUserInfo={post.currentUserInfo}
            onVotedMembersClick={handleVotedMembersClick}
            onSelectedOptionIdsChange={handleSelectedOptionIdsChange}
          />
        )}
        {mediaVideos?.length > 0 &&
          mediaVideos.map((video) => (
            <VideoPlayerItem
              key={video.id}
              video={video as Video}
              groupId={groupId}
              postId={postId}
            />
          ))}
        {mediaImages?.length > 0 && (
          <PostDetailImages images={mediaImages} groupId={groupId} postId={postId} />
        )}
        {post.pois?.[0] && post.pois?.[0].name && (
          <AsyncBoundary>
            <div className={s.PoiWrapper}>
              <PoiMapCard
                key={post.pois[0].id}
                poi={post.pois[0]}
                currentPosition={
                  geolocationPermission
                    ? geolocationData?.geolocation?.currentPosition?.position
                    : undefined
                }
                onCardClick={() => {
                  if (!post?.pois?.[0]) return;
                  trackEvent({ event: 'click_poi_detail', sample: true });

                  openPoiDetailPage({
                    id: post.pois[0].id,
                    type: post.pois[0].type,
                  });
                }}
              />
            </div>
          </AsyncBoundary>
        )}
        {canReadMeetup && post.meetupInfo && (
          <MeetupBanner
            meetup={post.meetupInfo}
            isReview={post.postType?.type === 'meetupReview'}
            onClick={handleMeetupBannerClick}
          />
        )}
        <Reaction post={post} onClickCommentIcon={onClickCommentIcon} />
      </Container>
      <div className={s.Divider} />
    </div>
  );
};

export default PostDetailInformation;
