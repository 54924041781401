import {
  GroupCurrentUser,
  GroupFeedMeetupInfoPresentation,
  PostDetail,
} from '@community-group/api/lib/group/models';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { MouseEvent } from 'react';
import React from 'react';

import FeedItemContents from './FeedItemContents';
import FeedItemFeedbackBox from './FeedItemFeedbackBox';
import FeedItemProfile from './FeedItemProfile';
import * as s from './index.css';

type Props = {
  post: PostDetail;
  currentUser: GroupCurrentUser;
  meetup?: GroupFeedMeetupInfoPresentation;
  onClick?: () => void;
  onProfileClick?: (e: MouseEvent) => void;
  onProfileButtonClick?: (e: MouseEvent) => void;
  onMeetupBannerClick?: (e: MouseEvent) => void;
  onPollBannerClick?: (e: MouseEvent) => void;
  onRefetchFeed?: () => void;
};

const FeedItem = ({
  post,
  currentUser,
  meetup,
  onClick,
  onProfileClick,
  onProfileButtonClick,
  onMeetupBannerClick,
  onPollBannerClick,
  onRefetchFeed,
}: Props) => {
  const groupId = post.groupInfo?.id.toString() ?? '';

  return (
    <motion.div className={clsx(['feed-item-wrapper', s.container])} key={post.id}>
      <div className={s.wrapper} onClick={onClick} aria-hidden="true">
        <div className={s.header}>
          <FeedItemProfile post={post} currentUser={currentUser} onClick={onProfileClick} />
          <button onClick={onProfileButtonClick}>
            <IconMoreVertRegular size={24} color={vars.$scale.color.gray600} />
          </button>
        </div>
        <Spacing size={12} />
        <div style={{ width: '100%' }}>
          <FeedItemContents
            post={post}
            currentUserRole={currentUser.role}
            meetup={meetup}
            onMeetupBannerClick={onMeetupBannerClick}
            onPollBannerClick={onPollBannerClick}
          />
          <Spacing size={12} />
          <FeedItemFeedbackBox
            post={post}
            groupId={groupId}
            refetchFeed={() => onRefetchFeed?.()}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(FeedItem);
