import { DismissableInlineAlert } from '@daangn/sprout-components-inline-alert';

import { useFlow } from '@/stackflow';

import useGroupDetailGroupBotBannerClosed from '../../hooks/useGroupDetailGroupBotBannerClosed';
import * as s from './index.css';

type Props = {
  groupId: number;
};

const GroupDetailGroupBotBanner = ({ groupId }: Props) => {
  const { push } = useFlow();
  const [_, setGroupBotBannerClosed] = useGroupDetailGroupBotBannerClosed(groupId.toString());

  const handleClose = () => {
    setGroupBotBannerClosed(true);
  };
  const handleClick = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;

    setGroupBotBannerClosed(true);
    push('GroupSettingGroupBotPage', {
      groupId: groupId.toString(),
      from: 'banner',
    });
  };

  return (
    <div onClick={handleClick} className={s.Banner}>
      <DismissableInlineAlert
        variant="info"
        title="새기능"
        description="모임장봇을 설정하고 새로운 이웃에게 자동으로 환영 메시지를 보내보세요."
        onClose={handleClose}
      />
    </div>
  );
};

export default GroupDetailGroupBotBanner;
