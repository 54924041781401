import { UseFormReturn } from 'react-hook-form';

import { useUploadVideos } from '../hooks/useUploadVideos';
import { FormType } from '../pages/UploadSeparateTab';
import VideoUploadItem from './VideoUploadItem';

const UploadVideoList = ({
  formMethods,
}: {
  formMethods: UseFormReturn<FormType, any, undefined>;
}) => {
  const { getValues, setValue } = formMethods;
  const uploads = getValues('uploads');

  const { cancelUpload, retryUpload } = useUploadVideos({
    uploads,
    setUploads: (updater) => {
      const currentUploads = getValues('uploads');
      setValue('uploads', updater(currentUploads));
    },
  });

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        padding: '10px 0',
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
      }}
    >
      {uploads.map((fileState, index) => (
        <VideoUploadItem
          key={index}
          fileState={fileState}
          onClickDelete={(fileState) => {
            cancelUpload(fileState);
          }}
          onRetryUpload={() => {
            retryUpload(fileState);
          }}
        />
      ))}
    </div>
  );
};

export default UploadVideoList;
