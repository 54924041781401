import { postUploadImageV2, UploadImageType } from '@community-group/api';
import { AxiosInstance } from 'axios';
import imageBlobReduce from 'image-blob-reduce';
import Pica from 'pica';
import { v4 } from 'uuid';

const pica = Pica({ features: ['js', 'wasm', 'cib'] });
const imageReducer = new imageBlobReduce({ pica });

export const defaultOptions = {
  orientation: true,
  canvas: true,
  meta: true,
  maxWidth: 1500,
  maxHeight: 1500,
};

type UploadImageParams = {
  image: Blob;
  fetchCommunityInstance: AxiosInstance;
};

export const uploadImage = async ({ image, fetchCommunityInstance }: UploadImageParams) => {
  const response = await postUploadImageV2({
    imageFile: image,
    fetchCommunityInstance,
  });

  return response;
};

export const reduceBlobs = (files: File[] | Blob[]) => {
  const loadedImages = files.map((file) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any>((resolve) => {
      imageReducer.toBlob(file, { max: 800 }).then((blob: Blob) => {
        resolve(new File([blob], v4(), { type: file.type }));
      });
    });
  });

  return Promise.all(loadedImages);
};

export type Dimension = {
  width: number;
  height: number;
};

export const imageDimensions = (blobs: Blob[]) => {
  const imageDimensionsMap: Promise<Dimension>[] = blobs.map((blob) => {
    return new Promise((resolve, reject) => {
      const objUrl = URL.createObjectURL(blob);
      const img = document.createElement('img');
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        img.remove();
        resolve({ width, height });
      };
      img.onerror = reject;
      img.src = objUrl;
    });
  });

  return Promise.all(imageDimensionsMap);
};

type UploadImagesParams = {
  images: Blob[];
  fetchCommunityInstance: AxiosInstance;
};
export const uploadImages = async ({ images, fetchCommunityInstance }: UploadImagesParams) => {
  const pressedImages = await reduceBlobs(images);

  const uploadedImages = pressedImages.map((image) => {
    return new Promise<UploadImageType>((resolve, reject) => {
      uploadImage({ image, fetchCommunityInstance })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  });

  return Promise.all(uploadedImages);
};
