import { vars } from '@seed-design/design-token';

interface Props {
  strokeColor?: string;
  size?: number;
}

const NewBadge = ({ size = 8, strokeColor: propsStrokeColor }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$scale.color.gray00;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7V1.42857L7 7V1"
        stroke={strokeColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewBadge;
