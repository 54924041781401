import { GroupLeaderBoardItem, Image } from '@community-group/api/lib/group/models';
import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import LeaderBoardItem from './LeaderBoardItem';

const LeaderBoardSection = () => {
  const { push } = useFlow();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % 5);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  const { data: leaderBoardData } = useGetGroupLeaderBoard({ weekAgo: 0 });

  const top5Groups = leaderBoardData?.rankList.slice(0, 5) ?? [];

  const handleClickAllGroup = () => {
    trackEvent({
      event: 'click_leaderboard_more_home_section',
    });
    push('GroupLeaderBoard', {});
  };

  const handleClickGroupItem = (itemRankData: GroupLeaderBoardItem) => {
    trackEvent({
      event: 'click_leaderboard_item_home_section',
      params: {
        groupId: itemRankData.groupId,
        groupName: itemRankData.groupName,
        currentRanking: itemRankData.rank,
        currentWeekPoint: itemRankData.weeklyActivityPoint,
      },
    });
    push('GroupLeaderBoard', {});
  };

  useEnterTrackEvent({
    event: 'impression_leaderboard_home_section',
    params: {
      leaderBoardRankLength: leaderBoardData?.rankList.length,
      existLeaderBoard: leaderBoardData?.rankList.length > 5,
    },
  });

  // 모임 item의 애니메이션&렌더링 상태 계산 및 반환
  const getItemState = (index: number) => {
    const isCurrent = currentIndex === index;
    const isNext = (currentIndex + 1) % 5 === index;
    const isPrevious = (currentIndex + 4) % 5 === index;

    let animateConfig = {};
    if (isCurrent) {
      animateConfig = {
        opacity: 1,
        bottom: 0,
      };
    } else if (isNext) {
      animateConfig = {
        bottom: '-200%',
      };
    } else {
      animateConfig = {
        bottom: '100%',
      };
    }

    return { animateConfig, isRender: isNext || isPrevious || isCurrent };
  };

  // 5개 이하면 섹션 노출하지 않음
  if (leaderBoardData?.rankList.length <= 5) return null;

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <Typography typography="title3Bold">이번 주 모임 Top 50</Typography>
        <div className={s.TextButton} onClick={handleClickAllGroup}>
          <Typography typography="label3Regular">전체보기</Typography>
          <VerticalSpacing size={2} />
          <IconChevronRightFill size={14} />
        </div>
      </div>
      <Spacing size={18} />
      <div className={s.LeaderBoardListOuterWrapper}>
        <div className={s.LeaderBoardListInnerWrapper}>
          <AnimatePresence>
            {top5Groups.map((group, idx) => {
              const { animateConfig, isRender } = getItemState(idx);
              if (!isRender) return null;

              return (
                <motion.div
                  key={group.groupId}
                  animate={animateConfig}
                  initial={{
                    opacity: 0,
                    bottom: '-100%',
                  }}
                  transition={{
                    duration: 0.4,
                    ease: 'easeOut',
                  }}
                  className={s.LeaderBoardItemWrapper}
                >
                  <LeaderBoardItem
                    rank={group.rank}
                    groupId={group.groupId}
                    groupName={group.groupName}
                    groupThumbnailUrl={group.groupThumbnailUrl}
                    weeklyActivityPoint={group.weeklyActivityPoint}
                    onClick={() => {
                      handleClickGroupItem(group);
                    }}
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
export default LeaderBoardSection;
