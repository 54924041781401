type Props = { size?: number };

function Large2DLevel1({ size = 48 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9986 5.99989C25.1422 4.9281 22.855 4.9281 20.9986 5.99989L9.91016 12.4018C8.05375 13.4736 6.91016 15.4544 6.91016 17.598V30.4018C6.91016 32.5454 8.05375 34.5262 9.91016 35.598L20.9986 41.9999C22.855 43.0717 25.1422 43.0717 26.9986 41.9999L38.0871 35.598C39.9435 34.5262 41.0871 32.5454 41.0871 30.4018V17.598C41.0871 15.4544 39.9435 13.4736 38.0871 12.4018L26.9986 5.99989Z"
        fill="#815A4F"
        stroke="#DDC7B8"
        strokeWidth="3"
      />
      <mask
        id="mask0_8594_118728"
        {...({ maskType: 'alpha' } as any)}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="38"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5141 5.09673L38.6063 11.5005C40.779 12.7584 42.1259 15.0836 42.1259 17.5993V30.4068C42.1259 32.9226 40.779 35.2478 38.6063 36.5057L27.5141 42.9094C25.3414 44.1673 22.6478 44.1673 20.475 42.9094L9.38281 36.5057C7.2101 35.2478 5.86328 32.9226 5.86328 30.4068V17.5993C5.86328 15.0836 7.2101 12.7584 9.38281 11.5005L20.475 5.09673C22.6478 3.83885 25.3414 3.83885 27.5141 5.09673Z"
          fill="#DDC7B8"
        />
      </mask>
      <g mask="url(#mask0_8594_118728)">
        <g filter="url(#filter0_d_8594_118728)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5155 5.09812L38.6039 11.5C40.7825 12.7578 42.1245 15.0823 42.1245 17.5979V30.4017C42.1245 32.9173 40.7825 35.2418 38.6039 36.4996L27.5155 42.9015C25.3369 44.1593 22.6528 44.1593 20.4743 42.9015L9.38582 36.4996C7.20728 35.2418 5.86523 32.9173 5.86523 30.4017V17.5979C5.86523 15.0823 7.20727 12.7578 9.38582 11.5L20.4743 5.09812C22.6528 3.84034 25.3369 3.84034 27.5155 5.09812ZM26.2449 7.29885C24.8526 6.495 23.1372 6.495 21.7449 7.29885L10.6564 13.7008C9.26411 14.5046 8.40641 15.9902 8.40641 17.5979V30.4017C8.40641 32.0094 9.26411 33.495 10.6564 34.2989L21.7449 40.7008C23.1372 41.5046 24.8526 41.5046 26.2449 40.7008L37.3333 34.2989C38.7256 33.495 39.5833 32.0094 39.5833 30.4017V17.5979C39.5833 15.9902 38.7256 14.5046 37.3333 13.7008L26.2449 7.29885Z"
            fill="#DDC7B8"
          />
        </g>
        <g filter="url(#filter1_d_8594_118728)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5155 5.09812L38.6039 11.5C40.7825 12.7578 42.1245 15.0823 42.1245 17.5979V30.4017C42.1245 32.9173 40.7825 35.2418 38.6039 36.4996L27.5155 42.9015C25.3369 44.1593 22.6528 44.1593 20.4743 42.9015L9.38582 36.4996C7.20728 35.2418 5.86523 32.9173 5.86523 30.4017V17.5979C5.86523 15.0823 7.20727 12.7578 9.38582 11.5L20.4743 5.09812C22.6528 3.84034 25.3369 3.84034 27.5155 5.09812ZM26.2449 7.29885C24.8526 6.495 23.1372 6.495 21.7449 7.29885L10.6564 13.7008C9.26411 14.5046 8.40641 15.9902 8.40641 17.5979V30.4017C8.40641 32.0094 9.26411 33.495 10.6564 34.2989L21.7449 40.7008C23.1372 41.5046 24.8526 41.5046 26.2449 40.7008L37.3333 34.2989C38.7256 33.495 39.5833 32.0094 39.5833 30.4017V17.5979C39.5833 15.9902 38.7256 14.5046 37.3333 13.7008L26.2449 7.29885Z"
            fill="#DDC7B8"
          />
        </g>
      </g>
      <g filter="url(#filter2_d_8594_118728)">
        <path
          d="M24.0086 15.6001C21.6086 15.6001 16.8086 17.1001 16.8086 23.4001C16.8086 27.9064 19.7238 31.9819 22.4604 33.2194C22.8379 33.3901 23.2286 33.0895 23.2286 32.6752V27.6001C23.2286 27.1681 23.5766 26.8201 24.0086 26.8201C24.4406 26.8201 24.7886 27.1681 24.7886 27.6001V32.6784C24.7886 33.0917 25.1775 33.3923 25.5547 33.2232C28.2919 31.9959 31.2086 27.9185 31.2086 23.4001C31.2086 17.1001 26.4086 15.6001 24.0086 15.6001Z"
          fill="url(#paint0_linear_8594_118728)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8594_118728"
          x="3.32406"
          y="0.343021"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118728" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118728"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8594_118728"
          x="3.32406"
          y="2.8842"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118728" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118728"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8594_118728"
          x="16.1733"
          y="15.6001"
          width="15.671"
          height="19.2664"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.96" />
          <feGaussianBlur stdDeviation="0.317647" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118728" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118728"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8594_118728"
          x1="24.0086"
          y1="15.6001"
          x2="24.0086"
          y2="33.4921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Large2DLevel1;
