import { Typography } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { IconEditFill } from '@seed-design/icon';
import { IconCloseFill } from '@seed-design/icon';
import { Fragment } from 'react';

import checkIcon from '@/assets/images/poll/checkcircle-enabled.png';

import PollLayout from './PollLayout';
import * as s from './pollPreview.css';
import * as pollCommonStyle from './PollStyle.css';

type Props = {
  readOnly?: boolean;
  title: string;
  pollOptionTitles: string[];
  isMultiple: boolean;
  hideOverflowOptions?: boolean;
  onClick?: () => void;
  onEditClick?: () => void;
  onRemoveClick?: () => void;
};
const PollPreview = ({
  readOnly = false,
  title,
  pollOptionTitles,
  isMultiple,
  hideOverflowOptions = false,
  onClick,
  onEditClick,
  onRemoveClick,
}: Props) => {
  const renderPollRows = () => {
    if (hideOverflowOptions) {
      return (
        <div className={pollCommonStyle.pollItemRows}>
          {pollOptionTitles.map((optionTitle, index) => {
            if (index > 2) return <></>;
            return (
              <Fragment key={index}>
                <div className={s.pollItem}>
                  <img className={s.voteRadioButton} src={checkIcon} alt="투표 라디오버튼" />
                  <VerticalSpacing size={8} />
                  <div>{optionTitle}</div>
                </div>
                {index !== pollOptionTitles.length - 1 && <Spacing size={8} />}
              </Fragment>
            );
          })}
          <p className={pollCommonStyle.pollMoreLabel}>더보기</p>
        </div>
      );
    }

    return (
      <div className={pollCommonStyle.pollItemRows}>
        {pollOptionTitles.map((optionTitle, index) => {
          return (
            <Fragment key={index}>
              <div className={s.pollItem}>
                <img className={s.voteRadioButton} src={checkIcon} alt="투표 라디오버튼" />
                <VerticalSpacing size={8} />
                <div>{optionTitle}</div>
              </div>
              {index !== pollOptionTitles.length - 1 && <Spacing size={8} />}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <PollLayout
      onClick={onClick}
      headRight={
        !readOnly ? (
          <div className={s.headIcons}>
            <button
              className={s.headIconButton}
              onClick={(e) => {
                e.stopPropagation();
                onEditClick?.();
              }}
            >
              <IconEditFill size={12} />
            </button>
            <VerticalSpacing size={10} />
            <button
              className={s.headIconButton}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick?.();
              }}
            >
              <IconCloseFill size={12} />
            </button>
          </div>
        ) : null
      }
    >
      <h2 className={pollCommonStyle.pollTitle}>{title}</h2>
      {isMultiple && (
        <>
          <Spacing size={6} />
          <Typography typography="caption2Regular" color="gray600">
            복수선택
          </Typography>
        </>
      )}
      <Spacing size={12} />
      {renderPollRows()}
    </PollLayout>
  );
};

export default PollPreview;
