import { Media, Video } from '@community-group/api/lib/group/models';
import { useMemo } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { useOptimizedImageList } from '@/components/common/base/slider/ImageSlider/hooks/useOptimizedImageList';
import { getMinHeightImageList } from '@/components/common/base/slider/ImageSlider/utils/images';
import { usePreventScrollOnSwipe } from '@/hooks/usePreventScrollOnSwipe';

import * as s from './FeedItemMediaSlider.css';
import ImageItem from './FeedMediaItem/ImageItem';
import VideoItem from './FeedMediaItem/VideoItem';

type Props = {
  medias: Media[];
};

const FeedItemMediaSlider = ({ medias }: Props) => {
  const swiperRef = usePreventScrollOnSwipe();

  // video가 먼저 나오도록 정렬
  const sortedMediaArr = medias.sort((prev, next) => {
    if (prev.type === 'video' && next.type === 'image') return -1;
    if (prev.type === 'image' && next.type === 'video') return 1;
    return 0;
  });
  const mediasArray = sortedMediaArr.map((media) => media.media);
  const imageHeight = useMemo(() => getMinHeightImageList(mediasArray), [mediasArray]);
  const optimizedImages = useOptimizedImageList(mediasArray, imageHeight);

  if (optimizedImages.length === 1) {
    const type = medias[0].type;
    const media = optimizedImages[0];

    // 정방형, 세로형(landscape)인 경우 1:1 비율
    // 가로형(portrait)인 경우 16:9 비율
    const isPortrait = media.width <= media.height;
    const aspectRatio = isPortrait ? '1 / 1' : '16 / 9';

    return (
      <div className={s.Wrapper}>
        {type === 'image' ? (
          <ImageItem image={media} aspectRatio={aspectRatio} />
        ) : (
          <VideoItem video={medias[0].media as Video} aspectRatio={aspectRatio} />
        )}
      </div>
    );
  }

  const swiperSlideClassName = optimizedImages.length === 2 ? s.SwiperSlidePair : s.SwiperSlide;

  return (
    <div className={s.SwiperWrapper}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        lazyPreloadPrevNext={1}
        className={s.Swiper}
        ref={swiperRef as React.Ref<SwiperRef>}
      >
        {optimizedImages.map((image, index) => {
          const type = medias[index].type;

          return (
            <SwiperSlide key={image.id + index} className={swiperSlideClassName}>
              {type === 'image' ? (
                <ImageItem image={image} aspectRatio={'1 / 1'} />
              ) : (
                <VideoItem
                  video={medias[index].media as Video}
                  aspectRatio={'1 / 1'}
                  isMultipleItem
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default FeedItemMediaSlider;
