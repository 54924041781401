import { RequestGroupDirectChat } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Dialog,
  GroupAvatar,
  Spacing,
  Typography,
  useDialog,
  useSnackbarAdapter,
} from '@community-group/components';
import { useState } from 'react';

import { useGetGroup1on1TalkTargetUri } from '@/api/hooks/useGetGroup1on1TalkTargetUri';
import { getUserChatRequestGroupDirectChatsQueryKey } from '@/api/hooks/useGetUserChatRequestGroupDirectChats';
import { usePutUserChatRequestGroupDirectChats } from '@/api/hooks/usePutUserChatRequestGroupDirectChats';
import { queryClient } from '@/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { formatDate } from '@/utils/date';
import { openHref } from '@/utils/link';

import * as s from './style.css';

type Props = {
  requestDirectChat: RequestGroupDirectChat;
};

export const RequestDirectChatItem = ({
  requestDirectChat: { id, requestUser, groupName, groupId, createdAt, requestMessage },
}: Props) => {
  const [isAcceptedFetchLoading, setIsAcceptedFetchLoading] = useState(false);

  const { push } = useFlow();
  const handleProfile = () => {
    push('GroupUserProfileDetailPage', {
      groupId: groupId.toString(),
      userId: requestUser.id.toString(),
    });
  };

  const snackbar = useSnackbarAdapter();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateRequestChats } = usePutUserChatRequestGroupDirectChats({
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries(getUserChatRequestGroupDirectChatsQueryKey);
    },
  });
  const { mutate: mutateGroup1on1Chat } = useGetGroup1on1TalkTargetUri({
    onError: handleErrorWithToast,
    onSuccess: ({ data }) => {
      openHref(data?.talkTargetUri);
    },
  });

  const { open: openDialog, close: closeDialog } = useDialog();
  const handleReject = () => {
    closeDialog();
    mutateRequestChats(
      {
        groupDirectChatId: id,
        requestGroupDirectChatConfirmForm: {
          isAccepted: false,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_reject_1on1_chat_request_button',
            params: {
              groupId,
              message: requestMessage,
            },
            sample: true,
          });

          snackbar.create({
            message: '채팅을 거절했어요.',
            type: 'default',
            timeout: 3000,
            onClick: () => {
              snackbar.dismiss();
            },
          });
        },
      }
    );
  };

  const handleRejectButtonClick = () => {
    openDialog({
      element: (
        <Dialog
          title="채팅 요청을 거절할까요?"
          description="채팅 요청을 거절해도 상대방은 알 수 없어요."
          primaryActionLabel="거절"
          onPrimaryAction={handleReject}
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
        />
      ),
    });
  };

  const handleAccept = () => {
    if (isAcceptedFetchLoading) return;

    setIsAcceptedFetchLoading(true);
    mutateRequestChats(
      {
        groupDirectChatId: id,
        requestGroupDirectChatConfirmForm: {
          isAccepted: true,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_accept_1on1_chat_request_button',
            params: {
              groupId,
              message: requestMessage,
            },
            sample: true,
          });

          mutateGroup1on1Chat(
            {
              id: groupId.toString(),
              memberId: requestUser.id.toString(),
            },
            {
              onSettled: () => {
                setIsAcceptedFetchLoading(false);
              },
            }
          );
        },
        onError: () => {
          setIsAcceptedFetchLoading(false);
        },
      }
    );
  };

  return (
    <div className={s.Wrapper}>
      <div className={s.Header} onClick={handleProfile}>
        <GroupAvatar size="small" src={requestUser.profileImage} />
        <div className={s.ProfileInfo}>
          <div>
            <Typography typography="bodyM2Regular" style={{ marginRight: '4px' }}>
              {requestUser.nickname}
            </Typography>
            <Typography typography="caption1Regular" color="gray600">
              {requestUser.region.name}
            </Typography>
          </div>
          <Spacing size={2} />
          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            {groupName} ⸱ {formatDate(new Date(createdAt), 'yyyy.MM.dd 요청')}
          </Typography>
        </div>
      </div>
      <div className={s.Message}>
        <Typography typography="bodyL2Regular" style={{ whiteSpace: 'pre-wrap' }}>
          {requestMessage}
        </Typography>
      </div>
      <div className={s.Footer}>
        <BoxButton
          variant="secondary"
          size="medium"
          UNSAFE_style={{ width: '100%' }}
          onClick={handleRejectButtonClick}
        >
          거절
        </BoxButton>
        <BoxButton
          variant="primaryLow"
          size="medium"
          UNSAFE_style={{ width: '100%' }}
          isLoading={isAcceptedFetchLoading}
          onClick={handleAccept}
        >
          승인
        </BoxButton>
      </div>
    </div>
  );
};
