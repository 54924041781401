import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useMemo } from 'react';

import {
  getPhotoContestEntriesPath,
  useGetPhotoContestEntries,
} from '@/api/hooks/useGetPhotoContestEntries';
import { queryClient } from '@/api/instance';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';

import PhotoContestEntrySection from '../components/PhotoContestEntrySection';
import PhotoContestFloatingButton from '../components/PhotoContestFloatingButton';
import PhotoContestHeroSlide from '../components/PhotoContestHeroSlide';
import PhotoContestResultSection from '../components/PhotoContestResultSection';
import PhotoContestSampleSection from '../components/PhotoContestSampleSection';

const PhotoContestPageV2 = () => {
  const { push } = useFlow();

  const { data } = useGetPhotoContestEntries({
    order: 'latest',
    localOnly: false,
  });

  const useContestResultFeature = useFeatureFlag('useContestResult');

  const isContestResult = useMemo(() => {
    if (!useContestResultFeature) return false;
    return data?.pages[0].data.isCurrentContestOngoing === false;
  }, [data?.pages, useContestResultFeature]);

  const referParams = useReferQueryParams();
  useEnterTrackEvent({
    event: 'enter_photo_event_page',
    params: {
      isContestResult,
      ...referParams,
    },
  });

  const renderContents = useCallback(() => {
    // 종료 결과 섹션 분기
    if (isContestResult) {
      return <PhotoContestResultSection />;
    }

    return (
      <>
        <PhotoContestHeroSlide />
        <Spacing size={32} />
        <PhotoContestSampleSection />
        <Spacing size={16} />
        <PhotoContestEntrySection />
        <PhotoContestFloatingButton />
      </>
    );
  }, [isContestResult]);

  return (
    <AppScreen
      disabledClientOnly
      onPull={() => {
        queryClient.refetchQueries([getPhotoContestEntriesPath()]);
      }}
      appBar={{
        title: '전국모임자랑',
        renderRight: () => (
          <span
            onClick={() => {
              push('PhotoContestGuidePage', {});
            }}
            style={{
              paddingRight: '0.5rem',
            }}
          >
            안내
          </span>
        ),
      }}
    >
      {renderContents()}
    </AppScreen>
  );
};

export default PhotoContestPageV2;
