import { UploadImageType } from '@community-group/api';

import { StoreSlice } from '..';

export type CreateGroupFormType = {
  // category page
  categoryId?: string;

  // name page
  name?: string;
  description?: string;

  // approval page
  joinType?: 'free' | 'approval';
  question?: string;
  requiredQuestion?: boolean;
  hasQuestion?: boolean;

  // recruitment condition page
  ageRange?: {
    minAge?: string;
    maxAge?: string;
  };
  maximumMember?: string;

  // group region range
  joinableRegionRange?: string;

  // sub nickname page
  subNicknameQuestion?: string;
  hasSubNickname?: boolean;

  // verification page
  needVerification?: boolean;
  canViewVerifiedUserInfo?: boolean;

  // image page
  profileImage?: UploadImageType;
  backgroundImage?: UploadImageType;
} & OnlyFormValueType;

// 가입 플로우 내에서만 사용하는 타입
type OnlyFormValueType = {
  category?: string;
  minAge?: number;
  maxAge?: number;
  haveQuestion?: string;
  selectedAgeRangeChip?: number;
  selectedMaximumMemberChip?: number;
  from?: string;
  createType?: string;
};

export type GroupCreateSlice = {
  createForm: CreateGroupFormType;
  setCreateForm: (value: CreateGroupFormType) => void;
  resetCreateForm: () => void;
};

export const createGroupSlice: StoreSlice<GroupCreateSlice> = (set) => ({
  createForm: {},
  setCreateForm: (value) => {
    set((state) => {
      return { ...state, createForm: value };
    });
  },
  resetCreateForm: () => {
    set((state) => ({ ...state, createForm: {} }));
  },
});
