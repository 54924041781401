import { useUserConfig } from '@/contexts/UserConfig';

import { useGetGroupProfile } from './useGetGroupProfile';

type Props = {
  groupId: number;
  queryOptions?: {
    enabled?: boolean;
    suspense?: boolean;
    hasOnError?: boolean;
    onError?: (error: unknown) => void;
    refetchOnWindowFocus?: boolean;
  };
  queryKeys?: (string | number)[];
};

export const useGetMyGroupProfile = ({ groupId, queryOptions, queryKeys = [] }: Props) => {
  const { userConfig } = useUserConfig();

  return useGetGroupProfile({
    groupId,
    userId: userConfig.userId ?? 0,
    queryOptions,
    queryKeys: [...queryKeys, 'MyGroupProfile'],
  });
};
