import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { GroupLeaderBoardPresentation } from '@community-group/api/lib/group/models';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type OptionType = UseQueryOptions<
  AxiosResponse<model.GroupLeaderBoardPresentation, any> | undefined,
  unknown,
  AxiosResponse<model.GroupLeaderBoardPresentation, any> | undefined,
  string[]
>;

export const getGroupLeaderBoardPath = (weekAgo: number) =>
  groupClient.api.GroupLeaderboardApi.getapiV1GroupsLeaderboardGetPath(weekAgo);

type Params = {
  weekAgo: number;
};

export const useGetGroupLeaderBoard = ({ weekAgo }: Params, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getGroupLeaderBoard = groupClient.api.GroupLeaderboardApi.apiV1GroupsLeaderboardGet({
    axios: fetchInstance,
  });

  const { data, isLoading, isError, refetch } = useQuery(
    [getGroupLeaderBoardPath(weekAgo), weekAgo.toString()],
    () => getGroupLeaderBoard(weekAgo),
    {
      suspense: true,

      ...options,
    }
  );

  const response = data?.data as GroupLeaderBoardPresentation;

  return { data: response, isLoading, isError, refetch };
};
