import { vars } from '@seed-design/design-token';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { ComponentType, memo, useMemo, useRef, useState } from 'react';

import { AppendRightProps } from '@/components/common/TransparentNavScreen';
import { TransparentNavScreenContext } from '@/components/common/TransparentNavScreen/TransparentNavScreenContext';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutRefProps } from '@/stackflow/components/Layout/components/ContentsLayout';

type Props = {
  name?: string;
  renderRight?: ComponentType<AppendRightProps>;
  children: React.ReactNode;
  fallbackLoading?: React.ReactNode;
};
// 히어로 슬라이더 섹션이 존재하지 않는 경우 기본적으로 사용되는 GroupDetailAppScreen
const GroupDetailDefaultAppScreen = ({
  renderRight: RenderRight,
  fallbackLoading,
  name,
  children,
}: Props) => {
  const layoutRef = useRef<LayoutRefProps>(null);

  const [isNavbarTitleShow, setIsNavbarTitleShow] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);
  const transparentContextValue = useMemo(() => ({ scrollRef, setIsNavbarTitleShow }), []);

  return (
    <AppScreen
      layoutRef={layoutRef}
      appBar={{
        title: isNavbarTitleShow ? name : '',
        renderRight: RenderRight
          ? () => <RenderRight iconColor={vars.$scale.color.gray900} />
          : undefined,
        backButton: {
          renderIcon: () => (
            <IconChevronLeftRegular
              color={vars.$scale.color.gray900}
              size={24}
              data-action="back"
            />
          ),
        },
      }}
      fallbackLoading={fallbackLoading}
    >
      <TransparentNavScreenContext.Provider value={transparentContextValue}>
        {children}
      </TransparentNavScreenContext.Provider>
    </AppScreen>
  );
};

export default memo(GroupDetailDefaultAppScreen);
