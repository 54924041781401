import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  meetupId: string;
  userId: string;
  meetupAttendanceStatus: groupClient.model.GroupMeetupAttendanceStatusEnum;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupMeetupAttendanceStatus = ({
  onError,
  onSuccess,
  onMutate,
  onSettled,
}: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetupJoinStatus =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdAttendanceUserIdPut({
      axios: fetchInstance,
    });

  const putGroupMeetupJoinStatusWrapperFp = ({
    groupId,
    meetupId,
    userId,
    meetupAttendanceStatus,
  }: Params) => {
    return putGroupMeetupJoinStatus(
      parseInt(groupId),
      parseInt(meetupId),
      parseInt(userId),
      meetupAttendanceStatus
    );
  };

  return useThrottleMutation(putGroupMeetupJoinStatusWrapperFp, {
    onMutate,
    onSettled,
    onError,
    onSuccess,
  });
};
