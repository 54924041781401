import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { ChipFilter, Spacing, Typography, useBottomSheet } from '@community-group/components';
import { useMemo } from 'react';

import { ORDER_MEETUP_KR_TYPO } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import { Container } from '@/components/common/Container';
import SortingMeetupFilterBottomSheet, {
  SortingListValue,
} from '@/components/common/SortingFilterBottomSheet/SortingMeetupFilterBottomSheet';
import { trackEvent } from '@/utils/analytics';

import CategoryFilterList from '../../../common/Filter/CategoryFilterList';
import { MeetupFilterType } from '..';
import * as s from './MeetupListFilter.css';

type Props = {
  filter: MeetupFilterType;
  setFilter: React.Dispatch<React.SetStateAction<MeetupFilterType>>;
};

const MeetupListFilter = ({ filter, setFilter }: Props) => {
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { categories } = useGetCategories();

  const setSelectedSorting = (sorting: SortingListValue) => {
    setFilter({ ...filter, order: sorting });
  };

  const categoryList = useMemo(
    () => [{ id: 0, name: '전체', icon: '', createdAt: '' }, ...categories],
    [categories]
  );

  return (
    <div className={s.wrapper}>
      <Container paddingY={0} paddingX={0}>
        <Typography className={s.title} typography="title3Bold" as="h3" color="gray900">
          주제별 모임 일정
        </Typography>

        <CategoryFilterList
          categories={categoryList}
          onClickItem={(item: Category | CategoryItemType) => {
            setFilter({ ...filter, categoryId: item.id !== 0 ? item.id : undefined });
          }}
        />
        <Spacing size={8} />

        <div className={s.switchOrderWrapper}>
          <ChipFilter
            label={ORDER_MEETUP_KR_TYPO[filter.order]}
            onClick={() => {
              trackEvent({
                event: 'click_sorting_meetup',
                params: {
                  selectedSorting: filter.order,
                  referrer: 'home',
                },
              });
              openBottomSheet({
                element: (
                  <SortingMeetupFilterBottomSheet
                    setSelectedSorting={setSelectedSorting}
                    selectedSorting={filter.order}
                  />
                ),
                onDimClose: closeBottomSheet,
              });
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default MeetupListFilter;
