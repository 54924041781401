import { Typography } from '@community-group/components';
import { PropsWithChildren } from 'react';

import ChipRadio from '@/components/common/base/Input/Radio/Chip';

import * as s from './FeedSummarySection.css';

type Props = PropsWithChildren<{
  totalCount: number;
  boardOptions: { key: number; value: string }[];
  selectedBoardKey: number;
  onBoardChange: (option: { key: number; value: string }) => void;
}>;

const FeedSummarySection = ({
  totalCount,
  boardOptions,
  selectedBoardKey,
  onBoardChange,
  children,
}: Props) => {
  return (
    <section className={s.Container}>
      <Typography className={s.Title} typography="title3Bold" color="gray900">
        게시글 {totalCount > 0 && totalCount}
      </Typography>
      <ChipRadio
        id="group-detail-board-category"
        label=""
        options={boardOptions}
        selectedOptionKey={selectedBoardKey}
        onChange={onBoardChange}
      />
      {children}
    </section>
  );
};

export default FeedSummarySection;
