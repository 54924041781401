import {
  MultilineTextField as SproutMultilineTextField,
  SeedMultilineTextFieldProps,
  SeedTextFieldProps,
  TextField as SproutTextField,
} from '@daangn/sprout-components-text-field';
import { useState } from 'react';
export const TextField = ({
  invalidAfterBlurred = true,
  isSubmitted = false,
  ...props
}: SeedTextFieldProps & { invalidAfterBlurred?: boolean; isSubmitted?: boolean }) => {
  const [blurredInput, setBlurredInput] = useState(false);

  const canCheckInvalid = isSubmitted || (invalidAfterBlurred ? blurredInput : true);

  return (
    <SproutTextField
      {...props}
      onBlur={(e) => {
        props.onBlur?.(e);
        setBlurredInput(true);
      }}
      isInvalid={canCheckInvalid && props.isInvalid}
    />
  );
};

export const MultilineTextField = ({
  invalidAfterBlurred = true,
  isSubmitted = false,
  ...props
}: SeedMultilineTextFieldProps & { invalidAfterBlurred?: boolean; isSubmitted?: boolean }) => {
  const [blurredInput, setBlurredInput] = useState(false);

  const canCheckInvalid = isSubmitted || (invalidAfterBlurred ? blurredInput : true);

  return (
    <SproutMultilineTextField
      {...props}
      onBlur={(e) => {
        props.onBlur?.(e);
        setBlurredInput(true);
      }}
      isInvalid={canCheckInvalid && props.isInvalid}
    />
  );
};
