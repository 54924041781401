import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';

type Props = {
  onClone: () => void;
  onDelete: () => void;
  onReport?: () => void;
};

export const GroupHigherManagerMoreMenuAppBarBottomSheet = ({
  onClone,
  onDelete,
  onReport,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();

            onClone();
          }}
        >
          복제
        </ActionListItem>
        <ActionListItem
          color={vars.$semantic.color.danger}
          onClick={async () => {
            await closeBottomSheet();

            onDelete();
          }}
        >
          삭제
        </ActionListItem>
        {onReport && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();

              onReport();
            }}
          >
            신고
          </ActionListItem>
        )}
      </ActionList>
    </ActionSheet>
  );
};
