import { GroupDetailPresentation, GroupFeedType } from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { memo } from 'react';

import { useGetGroupFeedList } from '@/api/hooks/useGetGroupFeedList';
import SurveyFeedbackBanner from '@/components/common/Banner/SurveyFeedbackBanner';
import EmptySection from '@/components/common/Empty';
import { LoadMoreFeedContainer } from '@/components/common/LoadMoreContainer';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { mapGroupFeedToPostDetail } from '../../utils/mapGroupFeedToPostDetail';
import { MemoizingFeedItem } from './FeedItem';
import * as s from './FeedList.css';

interface Props {
  selectedCategoryKey: number;
  groupDetail: GroupDetailPresentation;
  feedType?: GroupFeedType; // 기존의 모임 상세의 경우 post 타입만 조회 (post 타입에는 일반 게시글, 모임 후기를 포함)
}

const FeedList = ({ selectedCategoryKey, groupDetail, feedType }: Props) => {
  return (
    <AsyncBoundary rejectedFallback={<></>} pendingFallback={<ViewLoader />}>
      <FeedListInner
        selectedCategoryKey={selectedCategoryKey}
        groupDetail={groupDetail}
        feedType={feedType}
      />
    </AsyncBoundary>
  );
};

const FeedListInner = ({ selectedCategoryKey, groupDetail, feedType = 'post' }: Props) => {
  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe(groupId);

  const { data: feedListData, hasNextPage } = useGetGroupFeedList(groupId, feedType, [
    selectedCategoryKey,
  ]);

  if (!feedListData) return null;

  if (
    feedListData &&
    feedListData.pages[0].data.items &&
    feedListData.pages[0].data.items.length < 1
  ) {
    return (
      <EmptySection>
        아직 게시글이 없어요. <br />
        가장 먼저 게시글을 남겨보세요.
      </EmptySection>
    );
  }

  const isShowFeedbackBanner = feedListData.pages[0].data.items.length > 7;

  return (
    <>
      <div className={s.feedItemList}>
        {feedListData?.pages.map((post) =>
          post.data.items
            .map((item) => ({
              post: mapGroupFeedToPostDetail(groupDetail, currentUser)(item),
              meetup: item.meetupInfo,
            }))
            .map(({ post, meetup }) => (
              <MemoizingFeedItem
                key={post.id}
                post={post}
                currentUser={currentUser}
                meetup={meetup}
                shouldSetSubNickname={groupDetail?.subNicknameSetting.isSettingOn}
              />
            ))
        )}

        {isShowFeedbackBanner && (
          <SurveyFeedbackBanner
            padding="0 1rem"
            surveyId="groupDetailFeedList"
            referrer="bottom_of_home_feed"
            topSpacing={16}
          />
        )}
        {hasNextPage && (
          <LoadMoreFeedContainer selectedCategoryKey={selectedCategoryKey} feedType={feedType} />
        )}
      </div>
    </>
  );
};

export default memo(FeedList);
