import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getUserChatRequestGroupDirectChatsQueryKey = ['RequestGroupDirectChats'];

export const useGetUserChatRequestGroupDirectChats = () => {
  const fetchInstance = useFetchInstance();
  const getUserChatRequestGroupDirectChats =
    groupClient.api.GroupDirectChatApi.apiV1UserChatRequestGroupDirectChatsGet({
      axios: fetchInstance,
    });

  const { data, ...rest } = useQuery(
    ['RequestGroupDirectChats'],
    getUserChatRequestGroupDirectChats
  );

  const response = data?.data;

  return {
    data: response,
    ...rest,
  };
};
