import { Portal } from '@daangn/karrot-clothes';
import { AnimatePresence, motion, Transition } from 'framer-motion';
import { MouseEventHandler, PropsWithChildren } from 'react';

import * as s from './index.css';

type Props = PropsWithChildren<{
  isOpened: boolean;
  onClickClose: MouseEventHandler;
  zIndex?: number;
  selector?: string;
}>;

const transitionProps: Transition = {
  ease: [0.19, 0, 0.51, 1],
  duration: 0.25,
};

const defaultZIndex = 1000;

export const Drawer = ({
  selector,
  children,
  onClickClose,
  isOpened,
  zIndex = defaultZIndex,
}: Props) => {
  return (
    <Portal selector={selector}>
      <AnimatePresence>
        {isOpened && (
          <>
            <motion.div
              className={s.Overlay}
              animate={{ opacity: 1 }}
              style={{ zIndex }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={onClickClose}
              transition={transitionProps}
            />
            <motion.div
              className={s.Body}
              animate={{ translateX: 0 }}
              style={{ zIndex: zIndex + 1 }}
              exit={{ translateX: '100%' }}
              initial={{ translateX: '100%' }}
              transition={transitionProps}
            >
              {children}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};
