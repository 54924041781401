import { BoxButton } from '@community-group/components';
import { useState } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePutGroupMeetupJoinStatus } from '@/api/hooks/usePutGroupMeetupJoinStatus';
import { useBridge } from '@/contexts/Bridge';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import { GroupMeetupDetailAccessoryBarWrapper } from './shared/GroupMeetupDetailAccessoryBarWrapper';

export const NotJoinedGroupMeetupJoinButton = () => {
  const { groupId, meetupId } = usePathParams();
  const { group } = useGetGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();
  const { data } = useGetGroupMeetupDetail({ groupId, meetupId });
  const { bridge } = useBridge();
  const [loading, setLoading] = useState(false);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroupMeetupJoinStatus({
    onError: handleErrorWithToast,
    onSettled: () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      refetchGroupMeetupDetail({
        groupId,
        meetupId,
      });
      refetchGroupDetail({ groupId });
    },
  });

  const handleJoinClick = () => {
    setLoading(true);
    trackEvent({
      event: 'click_group_meetup_join_button',
      params: {
        meetupId,
        groupId: data?.groupInfo.id,
        meetupName: data?.title,
        groupName: data?.groupInfo.name,
        groupCategory: group?.category.name,
        isGroupMember: data?.currentUserInfo.groupJoined,
        isGroupMeetupMember: data?.currentUserInfo.meetupJoined,
        isLikedGroupMeetup: data?.currentUserInfo.like,
      },
    });

    mutate(
      {
        groupId,
        meetupId,
        meetupJoinStatus: 'join',
      },
      {
        onSuccess: () => {
          bridge.openToast({
            toast: {
              body: '일정에 참여했어요',
            },
          });

          openLink(data?.channelInfo?.targetUri);

          trackEvent({
            event: 'click_join_group_meetup',
            params: {
              meetupId,
              groupId: data?.groupInfo.id,
              userRole: data?.currentUserInfo.role,
              meetupJoined: data?.currentUserInfo.meetupJoined,
              maximumParticipantsNumber: data?.maximumParticipantsNumber,
              currentParticipantsNumber: data?.currentParticipantsNumber,
              currentMeetupJoinStatus: data?.currentUserInfo.meetupJoinStatus,
              category: group?.category.name,
              isInstantMeetupJoin: false,
              currentUserState: currentUser.state,
              needVerification: group?.needVerification,
            },
            loggerType: extendAppsflyerLoggerType,
          });
        },
      }
    );
  };

  const isFullyRecruited = data?.progressStatus?.status === 'fullyRecruited';

  if (data.progressStatus.status === 'closed') {
    return <></>;
  }

  return (
    <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
      <BoxButton
        size="xlarge"
        width="100%"
        isLoading={loading}
        isDisabled={isFullyRecruited}
        onClick={handleJoinClick}
      >
        {isFullyRecruited ? '모집 마감' : '참여하기'}
      </BoxButton>
    </GroupMeetupDetailAccessoryBarWrapper>
  );
};
