// 10월 운동 프로모션 가입 마케팅 프로모션 기간인지 확인하는 함수
export const isDuringRealJoin10월운동GroupMarketingPromotion = () => {
  // 한국시 10월 28일 00시 부터 11월 29일 24시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 9, 27, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 10, 9, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};
