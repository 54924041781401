import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconLockFill } from '@seed-design/icon';
import React from 'react';

import { trackEvent } from '@/utils/analytics';

import { GROUP_LEVEL_ICONS } from '../../utils/levelIcons';
import { GROUP_LEVEL_POLICY_INFORMATION } from '../../utils/levelPolicyInformation';
import * as s from './index.css';

function LevelPolicyInformationSection({
  currentLevel,
  groupId,
}: {
  currentLevel?: number;
  groupId?: string;
}) {
  const defaultLevel = !currentLevel ? 1 : currentLevel !== 0 ? currentLevel : 1;
  const [selectedLevel, setSelectedLevel] = React.useState(defaultLevel);

  const handleSelectLevel = (level: number) => {
    trackEvent({
      event: 'click_level_detail_policy_mission_badge',
      params: {
        currentLevel: currentLevel,
        selectedLevel: level,
        groupId,
      },
    });
    setSelectedLevel(level);
  };

  const renderMissions = () => {
    const currentLevel_ = currentLevel ?? 0;
    const isLocked = selectedLevel > currentLevel_ + 1;
    if (isLocked) {
      return (
        <div key={'levelup-mission'}>
          <div className={s.MissionItemWrapper}>
            <div className={s.MissionItemText}>
              <IconLockFill className={s.MissionIcon} color={vars.$scale.color.gray600} />
              <VerticalSpacing size={12} />
              <Typography typography="subtitle2Regular" color="gray600">
                미션을 확인하려면 Lv.{selectedLevel - 1} 모임이 되어보세요.
              </Typography>
            </div>
          </div>
        </div>
      );
    }

    return selectedLevelPolicy.missions?.map((mission, index) => {
      const isLastMission = index === selectedLevelPolicy.missions.length - 1;

      return (
        <div key={'levelup-mission-' + index}>
          <div className={s.MissionItemWrapper}>
            <div className={s.MissionItemText}>
              <mission.icon className={s.MissionIcon} color={vars.$scale.color.gray700} />
              <VerticalSpacing size={12} />
              <Typography typography="subtitle2Regular" color="gray900">
                {mission.title}
              </Typography>
            </div>
            <Typography typography="caption1Regular" color="gray600">
              {mission.achieveGoal}
            </Typography>
          </div>
          {!isLastMission && <Spacing size={8} />}
        </div>
      );
    });
  };

  const selectedLevelPolicy = GROUP_LEVEL_POLICY_INFORMATION[selectedLevel];

  return (
    <div className={s.Container}>
      <div className={s.HeaderWrapper}>
        <Typography typography="title3Bold" color="gray900">
          모임 레벨
        </Typography>
        <Spacing size={6} />
        <Typography typography="label3Regular" color="gray600">
          레벨업 미션과 혜택을 확인해보세요.
        </Typography>
      </div>

      <div className={s.LevelPolicyInformationWrapper}>
        <div className={s.LevelItemListWrapper}>
          {Object.entries(GROUP_LEVEL_ICONS._2d.large)
            .filter(([level]) => Number(level) !== 0)
            .map(([level, icon], index) => {
              const numericLevel = Number(level);
              const isSelected = selectedLevel === numericLevel;
              const currentLevel_ = currentLevel ?? 0;
              const isLocked = numericLevel > currentLevel_; // currentLevel보다 높은 레벨은 잠금 처리

              return (
                <div
                  key={'level-info-' + index}
                  className={s.LevelItemWrapper({ isSelected })}
                  onClick={() => handleSelectLevel(numericLevel)}
                >
                  <img
                    src={isLocked ? GROUP_LEVEL_ICONS._2d.large_lock[level] : icon}
                    className={s.LevelIcon}
                    alt="level-icon"
                  />
                  <Spacing size={6} />
                  <Typography
                    typography={isSelected ? 'caption1Bold' : 'caption1Regular'}
                    color={isSelected ? 'gray900' : 'gray600'}
                  >
                    Lv.{level}
                  </Typography>
                </div>
              );
            })}
        </div>
        <Spacing size={30} />
        <Typography typography="subtitle1Bold" color="gray700">
          레벨업 미션
        </Typography>
        <Spacing size={16} />
        {renderMissions()}
        <Spacing size={30} />
        <Typography typography="subtitle1Bold" color="gray700">
          혜택
        </Typography>
        <Spacing size={12} />
        {selectedLevelPolicy.benefits?.map((benefit, index) => {
          const isLastBenefit = index === selectedLevelPolicy.benefits.length - 1;
          return (
            <div key={'levelup-benefit-' + index}>
              <div key={'levelup-benefit-' + index}>
                <div className={s.BenefitTitleWrapper}>
                  <div className={s.BenefitIconWrapper}>
                    <Typography typography="caption1Bold" color="gray700">
                      {index + 1}
                    </Typography>
                  </div>
                  <VerticalSpacing size={8} />
                  <div className={s.BenefitTextWrapper}>
                    <Typography typography="bodyL1Regular" color="gray700">
                      {benefit.title}
                    </Typography>
                    {benefit?.description && (
                      <>
                        <Spacing size={4} />
                        <Typography typography="subtitle2Regular" color="gray600">
                          {benefit.description}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
                {benefit.contents}
              </div>
              {!isLastBenefit && <Spacing size={24} />}
            </div>
          );
        })}
        <div></div>
      </div>
    </div>
  );
}

export default LevelPolicyInformationSection;
