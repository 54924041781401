import { vars } from '@seed-design/design-token';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  paddingX?: number;
  paddingY?: number;
}

export const Container = ({ paddingX = 16, paddingY = 16, ...props }: Props) => (
  <div
    {...props}
    style={{
      backgroundColor: vars.$semantic.color.paperDefault,
      padding: `${paddingY}px ${paddingX}px`,
      ...props.style,
    }}
  />
);
