import { UserProfileSnsTagPresentation } from '@community-group/api/lib/group/models';

import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { openSnsLink } from '../utils/openSnsLink';
import { SnsTagItem } from './SnsTagItem';
import * as s from './SnsTagSection.css';

type Props = {
  snsTags?: UserProfileSnsTagPresentation[];
};

export const SnsTagOtherMemberSection = ({ snsTags }: Props) => {
  const currentUser = useCurrentGroupMe();

  if (!snsTags) return <></>;

  if (!snsTags.length) return <></>;

  return (
    <div className={s.SnsTagSection}>
      {snsTags.map((snsTag) => (
        <SnsTagItem
          key={snsTag.snsId}
          name={snsTag.snsId}
          iconType={snsTag.snsType}
          onClick={() => {
            trackEvent({
              event: 'click_move_external_sns',
              params: {
                snsTagId: snsTag.snsId,
                snsTagType: snsTag.snsType,
                currentUserState: currentUser.state,
              },
            });

            openSnsLink({
              snsId: snsTag.snsId,
              snsType: snsTag.snsType,
            });
          }}
        />
      ))}
    </div>
  );
};
