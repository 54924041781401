import {
  GroupMeetupMemberReviewRequest,
  GroupMeetupReviewCreateForm,
} from '@community-group/api/lib/group/models';
import { useState } from 'react';

import { usePostGroupMeetupReview } from '@/api/hooks/usePostGroupMeetupReview';
import { usePostGroupMeetupReviewMembers } from '@/api/hooks/usePostGroupMeetupReviewMembers';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import {
  MeetupMemberReviewFormHandler,
  MeetupReviewFormHandler,
} from '../components/MeetupReviewForm/type';

type Props = {
  meetupReviewFormHandler: MeetupReviewFormHandler;
  meetupMemberReviewFormHandler: MeetupMemberReviewFormHandler;
};

export const useGroupMeetupCreateReviewForm = ({
  meetupReviewFormHandler,
  meetupMemberReviewFormHandler,
}: Props) => {
  const [isMeetupReviewFetchSuccess, setIsMeetupReviewFetchSuccess] = useState(false);
  const [isMeetupMemberReviewFetchSuccess, setIsMeetupMemberReviewFetchSuccess] = useState(false);

  const { groupId, meetupId } = usePathParams();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutateAsync: mutateMeetupReview, isLoading: isLoadingMeetupReview } =
    usePostGroupMeetupReview({
      groupId: parseInt(groupId),
      meetupId: parseInt(meetupId),
      onSuccess: () => {
        setIsMeetupReviewFetchSuccess(true);
      },
      onError: handleErrorWithToast,
    });

  const { mutateAsync: mutateMeetupReviewMembers, isLoading: isLoadingMeetupMemberReview } =
    usePostGroupMeetupReviewMembers({
      groupId: parseInt(groupId),
      meetupId: parseInt(meetupId),
      onSuccess: () => {
        setIsMeetupMemberReviewFetchSuccess(true);
      },
      onError: handleErrorWithToast,
    });

  const validateForm = async () => {
    const isMeetupReviewFormReady = await meetupReviewFormHandler.trigger();
    const isMeetupMemberReviewFormReady = await meetupMemberReviewFormHandler.trigger();

    return !isMeetupReviewFormReady || !isMeetupMemberReviewFormReady;
  };

  const fetchMeetupReviewForm = async () => {
    const meetupReviewFormData = meetupReviewFormHandler.getValues();

    await mutateMeetupReview({
      groupMeetupReviewCreateForm: meetupReviewFormData as GroupMeetupReviewCreateForm,
    });
  };

  const fetchMeetupMemberReviewForm = async () => {
    const meetupMemberReviewFormData = meetupMemberReviewFormHandler.getValues();
    const requestFormData = meetupMemberReviewFormData.feedbackMembers
      .filter(({ feedbackRating }) => !!feedbackRating)
      .map((feedback) => ({
        ...feedback,
        feedbackTypeIds: feedback?.feedbackTypeIds ?? [],
      }));

    if (requestFormData.length === 0) {
      setIsMeetupMemberReviewFetchSuccess(true);
      return;
    }

    await mutateMeetupReviewMembers({
      groupMeetupMemberReviewRequest: {
        feedbackMembers: requestFormData,
      } as GroupMeetupMemberReviewRequest,
    });
  };

  const handleSubmit = async ({ onSuccess }: { onSuccess?: () => void }) => {
    const isFormValid = await validateForm();
    if (isFormValid) {
      meetupReviewFormHandler.setFocus('reviewPost.content');
      return;
    }

    if (!isMeetupReviewFetchSuccess) {
      await fetchMeetupReviewForm();
    }

    if (!isMeetupMemberReviewFetchSuccess) {
      await fetchMeetupMemberReviewForm();
    }

    onSuccess?.();
  };

  return {
    isLoading: isLoadingMeetupReview || isLoadingMeetupMemberReview,
    handleSubmit,
  };
};
