import { postUploadImageV2 } from '@community-group/api';
import {
  ImageFieldItem,
  ImageForm,
  PrevImageForm,
  useHandleImages,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCameraFill } from '@seed-design/icon';
import { useEffect } from 'react';
import { Path, UseFormSetValue } from 'react-hook-form';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';

import * as s from './Images.css';

interface Props<T extends object> {
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  prevImages?: PrevImageForm[];
}

export const GroupMeetupFormImagesField = <T extends object>({
  name,
  setValue,
  prevImages,
}: Props<T>) => {
  const { bridge } = useBridge();
  const fetchCommunityInstance = useFetchInstance();

  const { images, setImages, removeImages, retryImage } = useHandleImages({
    prevImages,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
  });

  useEffect(() => {
    const currentImages = images.map(({ id }: ImageForm) => id) ?? [];

    setValue(name, currentImages);
  }, [images]);

  const handleImageClick = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const originImages = await bridge.driver.compat.pickImages();

    if (originImages) {
      setImages(originImages);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.imageAddButton} onClick={handleImageClick}>
        <IconCameraFill size={24} color={vars.$scale.color.gray600} />
        <span style={{ color: vars.$scale.color.gray600, fontSize: '0.8125rem' }}>
          <strong
            style={{
              fontWeight: 400,
              color: images.length > 0 ? vars.$scale.color.carrot500 : vars.$scale.color.gray600,
            }}
          >
            {images.length}
          </strong>
          /10
        </span>
      </div>
      {images.map((image: ImageForm) => (
        <ImageFieldItem
          className={s.imageField}
          key={image.id}
          imageId={image.id}
          image={image?.src ?? image?.image ?? ''}
          onRemoveClick={() => {
            removeImages(image.id);
          }}
          onRetryClick={() => {
            retryImage(image.id);
          }}
        />
      ))}
    </div>
  );
};
