import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/feed';
import { useFetchInstance } from './instance/useFetchInstance';

export const getFeedListQueryKey = (id: string | undefined) => {
  return `${GROUP_URL}/${id}/posts`;
};

export const useGetFeedList = (id?: string, boardCategories?: number[], enabled = true) => {
  const fetchInstance = useFetchInstance();
  const getGroupFeedList = groupClient.api.PostApi.apiV1GroupsIdPostsGet({
    axios: fetchInstance,
  });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useInfiniteQuery(
    [getFeedListQueryKey(id), boardCategories],
    ({ pageParam = undefined }) =>
      getGroupFeedList(
        Number(id),
        pageParam,
        20,
        !boardCategories || boardCategories[0] === 0 ? undefined : boardCategories
      ),
    {
      enabled,
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
