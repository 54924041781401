export const makeStorage = <T>(initValue?: T) => {
  let data = initValue;

  return {
    setData: (nextData: T): void => {
      data = nextData;
    },
    getData: (): T => {
      // https://daangn.slack.com/archives/C0336FFHA8L/p1702434221195319
      // 논의에 의해 임시 주석처리해요
      /*
      if (!data) {
        throw new Error(`makeStorage is not initialized`);
      }*/

      return data as T;
    },
  };
};
