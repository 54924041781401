import { Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import QRCodeSVG from 'qrcode.react'; // Note: esm import 에러나서 임시조치
import React, { useMemo } from 'react';

import { useGetTalkRoomInfo } from '@/api/hooks/apartment/useGetTalkRoomInfo';
import CircleContainerIcon from '@/components/common/Icons/circleContainer';
import { useBridge } from '@/contexts/Bridge';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { getMultiRoomPermalink } from '@/utils/link';

import * as s from './InviteQR.css';

export type TalkRoomInviteQRPageParams = Pick<PageParams, 'groupId' | 'roomId'>;

const TalkRoomInviteQRPage: ActivityComponentType<TalkRoomInviteQRPageParams> = () => {
  const { groupId, roomId } = usePathParams();

  const { data: room } = useGetTalkRoomInfo(Number(groupId), Number(roomId));
  const inviteCode = room?.talkRoom?.password;

  const url = useMemo(
    () => getMultiRoomPermalink(room?.talkRoom?.permalink?.id),
    [room?.talkRoom?.permalink?.id]
  );
  const { bridge } = useBridge();
  const handleCopyText = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    bridge.share({
      share: {
        url,
        text: '당근 모임 채팅방에 초대해요.',
      },
    });
  };

  return (
    <AppScreen appBar={{ title: '초대 링크' }}>
      <div className={s.inviteWrapper}>
        {inviteCode && (
          <div className={s.inviteMessage}>
            <p>초대 링크로 접속하고</p>
            <p>초대 코드를 입력하면 참여할 수 있어요.</p>
          </div>
        )}
        <div className={s.qrWrapper}>
          <QRCodeSVG width="200" height="200" value={url} renderAs="svg" />
        </div>
        {inviteCode && (
          <div className={s.inviteCodeWrapper}>
            <Typography as="div" typography="title3Bold" color="gray600">
              초대 코드
            </Typography>
            <Typography
              as="div"
              typography="title1Bold"
              color="gray900"
              style={{
                marginTop: '0.25rem',
              }}
            >
              {inviteCode}
            </Typography>
          </div>
        )}
        <div className={s.inviteCodeInfo}>
          <div className={s.inviteCodeInfoLeft}>
            <CircleContainerIcon />
            <Typography as="div" typography="bodyM2Regular" color="yellow950" textAlign="left">
              {url}
            </Typography>
          </div>
          <button className={s.inviteCodeCopyButton} onClick={handleCopyText}>
            복사
          </button>
        </div>
      </div>
    </AppScreen>
  );
};

export default TalkRoomInviteQRPage;
