import { ActivityComponentType } from '@stackflow/react';

import { PageParams } from '@/stackflow/types/params';

import GroupHomePage from './GroupHome';

export type HomePageParams = Pick<
  PageParams,
  'activeHomeTab' | 'scrollTo' | 'groupCategory' | 'from'
>;

const HomePage: ActivityComponentType<HomePageParams> = () => {
  return <GroupHomePage />;
};

export default HomePage;
