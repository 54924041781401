import {
  AsyncBoundary,
  BoxButton,
  Spacing,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { IconProhibitionRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import * as s from './GuardForbiddenWordGroupBottomSheet.css';

export const GuardForbiddenWordPopSendEnum = {
  needToChangeGroupInfo: 'needToChangeGroupInfo',
  doNotNeedChangeGroupInfo: 'doNotNeedChangeGroupInfo',
};

export const GuardForbiddenWordGroupBottomSheet: ActivityComponentType = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<ViewLoader />}>
        <GuardForbiddenWordGroup />
      </AsyncBoundary>
    </BottomSheet>
  );
};

export const GuardForbiddenWordGroup = () => {
  const { pop } = useConnectedActions();

  const { data: user } = useGetMyMainProfile({});

  const handleBackForbiddenWord = () => {
    trackEvent({
      event: 'click_not_forbidden_word_group_bottom_sheet_close',
    });
    pop().send({
      type: GuardForbiddenWordPopSendEnum.needToChangeGroupInfo,
    });
  };

  const handleBackNotForbiddenWord = () => {
    trackEvent({
      event: 'click_do_not_forbidden_word_group_bottom_sheet_confirm',
    });
    pop().send({
      type: GuardForbiddenWordPopSendEnum.doNotNeedChangeGroupInfo,
    });
  };

  const handleClickFaq = () => {
    openLink('https://www.daangn.com/wv/faqs/13102');
  };

  useEnterTrackEvent({
    event: 'enter_guard_forbidden_word_group_bottom_sheet',
  });

  return (
    <>
      <Spacing size={15} />
      <Typography style={{ width: '100%' }} as="h4" typography="title2Bold" color="gray900">
        {user?.nickname}님, <br />
        혹시 운영 정책을 위반하는 모임 아닌가요?
      </Typography>
      <Spacing size={15.2} />
      <p className={s.ForbiddenWordBox}>
        <IconProhibitionRegular size={16} color={vars.$scale.color.red500} />
        <Typography as="span" typography="bodyM1Regular" color="gray900">
          투자, 펀드, 가상화폐 관련 모임
        </Typography>
      </p>
      <Spacing size={16} />
      <Typography as="p" typography="bodyL1Regular" color="gray900">
        운영 정책을 위반하는 모임을 만들면 별도의 공지 없이 해당 모임이 제재 또는 삭제될 수 있어요.{' '}
        <span
          style={{
            textDecorationLine: 'underline',
            cursor: 'pointer',
            color: vars.$scale.color.gray600,
          }}
          onClick={handleClickFaq}
        >
          자세히 보기
        </span>
      </Typography>
      <Spacing size={8} />
      <div className={s.FixedButtonBox}>
        <BoxButton width="100%" size="xlarge" onClick={handleBackForbiddenWord}>
          수정하기
        </BoxButton>
      </div>
      <Typography
        onClick={handleBackNotForbiddenWord}
        typography="subtitle2Bold"
        style={{
          padding: '0.5rem 0',
          width: '100%',
        }}
        textAlign="center"
      >
        정책 위반 모임이 아니에요
      </Typography>
    </>
  );
};
