//info: https://www.w3.org/WAI/ARIA/apg/patterns/meter/ 요구사항 준수
//info: https://www.figma.com/design/u1jLOFOxfC6hKDkHLFLK8U/App-Pattern?node-id=1446-44520&node-type=vector&m=dev 디자인 참고

import { vars } from '@seed-design/design-token';
import { AnimationProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { forwardRef, useMemo } from 'react';

type Direction = 'ltr' | 'rtl' | 'auto';
type Props = {
  value: number;
  dir?: Direction;
  backgroundColor?: string;
  fillColor?: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  animateOnRender?: boolean;
  transitionDuration?: number;
} & AnimationProps;

export const ProgressBar = forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      dir = 'ltr',
      backgroundColor = vars.$scale.color.gray200,
      fillColor = vars.$semantic.color.primary,
      height = '0.625rem',
      width = '100%',
      borderRadius = '62.4375rem',
      animateOnRender,
      transitionDuration = 0.3,
      ...props
    },
    ref
  ) => {
    const progressBarStyles = useMemo(
      () => ({
        width,
        height,
        backgroundColor,
        borderRadius,
        overflow: 'hidden',
        position: 'relative' as React.CSSProperties['position'],
      }),
      [width, height, backgroundColor, borderRadius]
    );

    const fillStyles = useMemo(
      () => ({
        backgroundColor: fillColor,
        height,
        width: `${value}%`, // 추가: 최소 min 값을 px로 설정하여 보장
        transformOrigin: dir === 'ltr' ? 'left' : 'right',
        borderRadius,
      }),
      [fillColor, height, value, dir, borderRadius]
    );

    const motionProps: AnimationProps = useMemo(
      () =>
        animateOnRender
          ? {
              initial: { width: '0%' },
              animate: { width: `${value}%` }, // 추가: 최소값 포함
              transition: { duration: transitionDuration },
              ...props,
            }
          : {
              animate: { width: `${value}%` }, // 추가: 최소값 포함
              ...props,
            },
      [animateOnRender, value, transitionDuration, props]
    );

    return (
      <div
        dir={dir}
        ref={ref}
        role="meter"
        aria-valuenow={Number(value)}
        aria-valuetext={`${value}`}
        style={progressBarStyles}
      >
        <motion.div style={fillStyles} {...motionProps} />
      </div>
    );
  }
);
