import { BoxButton } from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import * as s from './GroupMeetupHostButton.css';
import { GroupMeetupDetailAccessoryBarWrapper } from './shared/GroupMeetupDetailAccessoryBarWrapper';

export const GroupMeetupHostButton = () => {
  const { push } = useFlow();

  const { groupId, meetupId } = usePathParams();
  const { data } = useGetGroupMeetupDetail({ groupId, meetupId });

  const handleAttendanceClick = () => {
    push('GroupMeetupDetailAttendancePage', {
      groupId,
      meetupId,
    });
  };

  const reviewLabel = useMemo(
    () => (data.currentUserInfo.reviewWritten ? '후기 보기' : '후기 작성'),
    [data.currentUserInfo.reviewWritten]
  );
  const handleReviewClick = () => {
    if (data.currentUserInfo.reviewWritten) {
      trackEvent({
        event: 'click_group_meetup_review_post_button',
        params: {
          groupId,
          meetupId,
          groupName: data.groupInfo.name,
          groupMeetupName: data.title,
        },
      });

      return push('GroupPostDetailPage', {
        groupId,
        postId: (data.currentUserInfo.writtenReviewPostId ?? '').toString(),
      });
    }

    // todo: groupCategoryName 추가할 것
    trackEvent({
      event: 'click_write_group_meetup_review_button',
      params: {
        groupId,
        meetupId,
        groupName: data.groupInfo.name,
        groupMeetupName: data.title,
      },
    });

    return push('GroupMeetupCreateReviewPage', {
      groupId,
      meetupId,
    });
  };

  const handleChannelChatOpen = () => {
    openLink(data?.channelInfo?.targetUri);
  };

  if (data.progressStatus.status === 'closed') {
    if (data.currentParticipantsNumber <= 1) {
      return (
        <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
          <div className={s.buttonWrapper}>
            <BoxButton size="xlarge" variant="secondary" width="100%">
              모임종료
            </BoxButton>
          </div>
        </GroupMeetupDetailAccessoryBarWrapper>
      );
    }

    return (
      <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
        <div className={s.buttonWrapper}>
          <BoxButton width="100%" variant="secondary" size="xlarge" onClick={handleAttendanceClick}>
            출석 체크
          </BoxButton>
          <BoxButton width="100%" size="xlarge" onClick={handleReviewClick}>
            {reviewLabel}
          </BoxButton>
        </div>
      </GroupMeetupDetailAccessoryBarWrapper>
    );
  }

  if (data.currentParticipantsNumber <= 1) {
    return (
      <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
        <div className={s.buttonWrapper}>
          <BoxButton size="xlarge" variant="primary" width="100%" onClick={handleChannelChatOpen}>
            대화 중인 채팅방
          </BoxButton>
        </div>
      </GroupMeetupDetailAccessoryBarWrapper>
    );
  }

  return (
    <GroupMeetupDetailAccessoryBarWrapper groupId={groupId} meetupId={meetupId}>
      <div className={s.buttonWrapper}>
        <BoxButton width="100%" size="xlarge" variant="primaryLow" onClick={handleAttendanceClick}>
          출석 체크
        </BoxButton>
        <BoxButton width="100%" size="xlarge" onClick={handleChannelChatOpen}>
          채팅방
        </BoxButton>
      </div>
    </GroupMeetupDetailAccessoryBarWrapper>
  );
};
