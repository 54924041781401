import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect } from 'react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import SettingGroupBotSection from '../components/SettingGroupBot/SettingGroupBotSection';

type Params = Pick<PageParams, 'groupId' | 'from'>;

const GroupSettingGroupBotPage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();

  useEffect(() => {
    trackEvent({
      event: 'enter_bot_setting',
      params: { from },
    });
  }, [from]);

  return (
    <AppScreen
      appBar={{
        title: '모임장봇 설정',
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
      }}
    >
      <SettingGroupBotSection groupId={groupId} />
    </AppScreen>
  );
};

export default GroupSettingGroupBotPage;
