type Props = { size?: number };

function Large2DLevel4({ size = 48 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20.3909C0 19.5936 0.646326 18.9473 1.44361 18.9473H13.8947V29.0525H8.66165C3.87795 29.0525 0 25.1746 0 20.3909Z"
        fill="url(#paint0_linear_8594_118731)"
      />
      <path
        d="M48 20.3909C48 19.5936 47.3537 18.9473 46.5564 18.9473H34.1053V29.0525H39.3383C44.122 29.0525 48 25.1746 48 20.3909Z"
        fill="url(#paint1_linear_8594_118731)"
      />
      <path
        d="M26.9986 5.99989C25.1422 4.9281 22.855 4.9281 20.9986 5.99989L9.91016 12.4018C8.05375 13.4736 6.91016 15.4544 6.91016 17.598V30.4018C6.91016 32.5454 8.05375 34.5262 9.91016 35.598L20.9986 41.9999C22.855 43.0717 25.1422 43.0717 26.9986 41.9999L38.0871 35.598C39.9435 34.5262 41.0871 32.5454 41.0871 30.4018V17.598C41.0871 15.4544 39.9435 13.4736 38.0871 12.4018L26.9986 5.99989Z"
        fill="#FF9C28"
        stroke="#FFDD87"
        strokeWidth="3"
      />
      <mask
        id="mask0_8594_118731"
        {...({ maskType: 'alpha' } as any)}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="38"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5161 5.09673L38.6083 11.5005C40.781 12.7584 42.1278 15.0836 42.1278 17.5993V30.4068C42.1278 32.9226 40.781 35.2478 38.6083 36.5057L27.5161 42.9094C25.3434 44.1673 22.6497 44.1673 20.477 42.9094L9.38476 36.5057C7.21206 35.2478 5.86523 32.9226 5.86523 30.4068V17.5993C5.86523 15.0836 7.21206 12.7584 9.38476 11.5005L20.477 5.09673C22.6497 3.83885 25.3434 3.83885 27.5161 5.09673Z"
          fill="#DDC7B8"
        />
      </mask>
      <g mask="url(#mask0_8594_118731)">
        <g filter="url(#filter0_d_8594_118731)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5174 5.09812L38.6059 11.5C40.7844 12.7578 42.1264 15.0823 42.1264 17.5979V30.4017C42.1264 32.9173 40.7844 35.2418 38.6059 36.4996L27.5174 42.9015C25.3389 44.1593 22.6548 44.1593 20.4762 42.9015L9.38778 36.4996C7.20923 35.2418 5.86719 32.9173 5.86719 30.4017V17.5979C5.86719 15.0823 7.20923 12.7578 9.38778 11.5L20.4762 5.09812C22.6548 3.84034 25.3389 3.84034 27.5174 5.09812ZM26.2468 7.29885C24.8545 6.495 23.1391 6.495 21.7468 7.29885L10.6584 13.7008C9.26606 14.5046 8.40836 15.9902 8.40836 17.5979V30.4017C8.40836 32.0094 9.26606 33.495 10.6584 34.2989L21.7468 40.7008C23.1391 41.5046 24.8545 41.5046 26.2468 40.7008L37.3353 34.2989C38.7276 33.495 39.5853 32.0094 39.5853 30.4017V17.5979C39.5853 15.9902 38.7276 14.5046 37.3353 13.7008L26.2468 7.29885Z"
            fill="#FFDD87"
          />
        </g>
        <g filter="url(#filter1_d_8594_118731)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5174 5.09812L38.6059 11.5C40.7844 12.7578 42.1264 15.0823 42.1264 17.5979V30.4017C42.1264 32.9173 40.7844 35.2418 38.6059 36.4996L27.5174 42.9015C25.3389 44.1593 22.6548 44.1593 20.4762 42.9015L9.38778 36.4996C7.20923 35.2418 5.86719 32.9173 5.86719 30.4017V17.5979C5.86719 15.0823 7.20923 12.7578 9.38778 11.5L20.4762 5.09812C22.6548 3.84034 25.3389 3.84034 27.5174 5.09812ZM26.2468 7.29885C24.8545 6.495 23.1391 6.495 21.7468 7.29885L10.6584 13.7008C9.26606 14.5046 8.40836 15.9902 8.40836 17.5979V30.4017C8.40836 32.0094 9.26606 33.495 10.6584 34.2989L21.7468 40.7008C23.1391 41.5046 24.8545 41.5046 26.2468 40.7008L37.3353 34.2989C38.7276 33.495 39.5853 32.0094 39.5853 30.4017V17.5979C39.5853 15.9902 38.7276 14.5046 37.3353 13.7008L26.2468 7.29885Z"
            fill="#FFDD87"
          />
        </g>
      </g>
      <g filter="url(#filter2_d_8594_118731)">
        <path
          d="M31.752 23.5439C31.224 23.0159 30.216 22.3799 29.004 22.3079C29.64 21.6359 30.168 20.7839 30.42 19.8239C31.236 16.7759 29.112 15.4439 27.948 15.1319C26.976 14.8679 25.08 14.9039 24 16.6919C22.92 14.9159 21.012 14.8799 20.052 15.1319C18.888 15.4439 16.764 16.7879 17.58 19.8239C17.844 20.7959 18.372 21.6479 18.996 22.3079C17.784 22.3799 16.776 23.0159 16.248 23.5439C15.264 24.5279 14.268 27.2639 16.488 29.7239C17.645 30.9998 20.2828 32.3439 22.4966 32.8168C22.8713 32.8969 23.208 32.6006 23.208 32.2175V29.4719L18.84 26.9519C18.468 26.7359 18.336 26.2559 18.552 25.8839C18.768 25.5119 19.248 25.3799 19.62 25.5959L23.208 27.6599V23.6399L21.288 20.8919C21.036 20.5439 21.132 20.0519 21.48 19.7999C21.828 19.5479 22.32 19.6439 22.572 19.9919L24 22.0319L25.428 19.9919C25.68 19.6439 26.16 19.5599 26.52 19.7999C26.88 20.0399 26.964 20.5319 26.712 20.8919L24.792 23.6399V27.7079L28.44 25.5959C28.812 25.3799 29.292 25.5119 29.508 25.8839C29.724 26.2559 29.592 26.7359 29.22 26.9519L24.792 29.5079V32.2179C24.792 32.6009 25.1285 32.8972 25.5031 32.8178C27.7244 32.3473 30.3545 31.0112 31.512 29.7239C33.744 27.2639 32.748 24.5279 31.752 23.5439Z"
          fill="url(#paint2_linear_8594_118731)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8594_118731"
          x="3.32601"
          y="0.343021"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118731" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118731"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8594_118731"
          x="3.32601"
          y="2.8842"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118731" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118731"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8594_118731"
          x="14.5151"
          y="15.0122"
          width="18.9786"
          height="19.4139"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.96" />
          <feGaussianBlur stdDeviation="0.317647" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118731" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118731"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8594_118731"
          x1="0"
          y1="23.9999"
          x2="13.8947"
          y2="23.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9C28" />
          <stop offset="1" stopColor="#FF6F0F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8594_118731"
          x1="48"
          y1="23.9999"
          x2="34.1053"
          y2="23.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9C28" />
          <stop offset="1" stopColor="#FF6F0F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8594_118731"
          x1="24.0044"
          y1="15.0122"
          x2="24.0044"
          y2="32.9399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Large2DLevel4;
