import {
  AsyncBoundary,
  Spacing,
  Spacing as SpacingComponents,
  ViewLoader,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useRef } from 'react';

import GuidebookBanner from '@/components/common/Banner/GuidebookBanner';
import SurveyFeedbackBanner from '@/components/common/Banner/SurveyFeedbackBanner';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import GroupApplicationSettingSection from '../components/SettingSection/GroupApplication';
import GroupInfoSettingSection from '../components/SettingSection/GroupInfo';
import GroupInfoSection from '../components/SettingSection/GroupInfoSection';
import GroupManagementSettingSection from '../components/SettingSection/GroupManagement';
import MyGroupProfileSettingSection from '../components/SettingSection/MyGroupProfile';
import NotificationSettingSection from '../components/SettingSection/Notification';
import OtherSettingSection from '../components/SettingSection/Other';
import useCheckNeedToSetCategoryBottomSheet from '../hooks/useCheckNeedToSetCategoryBottomSheet';

export type GroupSettingPageParams = Pick<PageParams, 'groupId' | 'scrollToBottom'>;

const GroupSettingPage: ActivityComponentType<GroupSettingPageParams> = () => {
  return (
    <AppScreen
      appBar={{
        title: '모임 설정',
        borderSize: '0.5px',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <SettingWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupSettingPage;

const SettingWrapper = () => {
  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();

  const { scrollToBottom } = useQueryParams();
  const layoutWrapperRef = useRef(null);

  useEffect(() => {
    if (scrollToBottom === 'true') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layoutWrapperRef.current.scrollTop = layoutWrapperRef.current.scrollHeight;
    }
  }, [scrollToBottom, layoutWrapperRef]);

  const checkSetCategory = useCheckNeedToSetCategoryBottomSheet({ groupId });

  useDidMountSequentialProcess([checkSetCategory]);

  return (
    <LayoutWrapper ref={layoutWrapperRef}>
      <GroupInfoSection />
      <CheckAccessibleRole currentRole={currentUser.role}>
        <NotificationSettingSection />
        <Spacing size={1} fill={vars.$semantic.color.divider1} />
      </CheckAccessibleRole>

      <CheckAccessibleRole
        currentRole={currentUser.role}
        accessibleRoles={['superHost', 'manager']}
      >
        <GroupManagementSettingSection />
        <Spacing size={1} fill={vars.$semantic.color.divider1} />
      </CheckAccessibleRole>

      <CheckAccessibleRole
        currentRole={currentUser.role}
        accessibleRoles={['superHost', 'manager']}
      >
        <GroupApplicationSettingSection />
        <Spacing size={1} fill={vars.$semantic.color.divider1} />
      </CheckAccessibleRole>

      <CheckAccessibleRole currentRole={currentUser.role}>
        <MyGroupProfileSettingSection />
        <Spacing size={1} fill={vars.$semantic.color.divider1} />
      </CheckAccessibleRole>

      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <GroupInfoSettingSection />
        <Spacing size={1} fill={vars.$semantic.color.divider1} />
      </CheckAccessibleRole>

      <CheckAccessibleRole currentRole={currentUser.role}>
        <OtherSettingSection />
      </CheckAccessibleRole>

      <SpacingComponents size={8} />

      <GuidebookBanner />
      <SpacingComponents size={14} />
      <SurveyFeedbackBanner
        surveyId="groupSettingSettingList"
        referrer="bottom_of_home_feed"
        padding="0.25rem 1rem 0.75rem 1rem"
      />
      <SpacingComponents size={80} />
    </LayoutWrapper>
  );
};
