import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getMeetupReviewInfoPath = (groupId: string) =>
  `${GROUP_URL}/${groupId}/meetup-review-info`;

export const useGetMeetupReviewInfo = (groupId?: string, suspense = true) => {
  const fetchInstance = useFetchInstance();
  const getMeetupReviewInfo = groupClient.api.MeetupApi.apiV1GroupsIdMeetupReviewInfoGet({
    axios: fetchInstance,
  });

  const { data, refetch, isInitialLoading } = useQuery(
    [getMeetupReviewInfoPath(groupId ?? '')],
    () => getMeetupReviewInfo(Number(groupId)),
    {
      suspense,
    }
  );

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading };
};
