import { vars } from '@seed-design/design-token';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import AgeInput from './age-range-input';

type Props = {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  disable?: boolean;
};

function AgeRangeInputWrapper({ watch, setValue, disable = false }: Props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
      <AgeInput watch={watch} setValue={setValue} valueName="minAge" disable={disable} />
      <div style={{ marginTop: '0.25rem', padding: '0.75rem', color: vars.$scale.color.gray700 }}>
        ~
      </div>
      <AgeInput watch={watch} setValue={setValue} valueName="maxAge" disable={disable} />
    </div>
  );
}

export default AgeRangeInputWrapper;
