import { BoxButton, useSnackbarAdapter } from '@community-group/components';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { usePostShareContent } from '@/api/hooks/usePostShareContent';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { getNativeChatTabScheme } from '@/utils/link';

import EditMultiLineTextarea from '../../Setting/components/SettingEditGroupDescription/EditMultiLineTextarea';
import { ShareContentFormType } from '..';
import * as s from './ShareMessageInput.css';

type Props = {
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<ShareContentFormType>;
  handleBlur: () => void;
  handleFocus: () => void;
  groupId: string;
};

const ShareMessageInput = ({ setValue, watch, handleBlur, handleFocus, groupId }: Props) => {
  const { pop } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();

  const { mutate } = usePostShareContent({
    onSuccess: () => {
      const targets = watch('targets');
      const targetCount = targets.length;

      trackEvent({
        event: 'click_share_content_to_target',
        params: {
          groupId: groupId,
          type: watch('content.type'),
          postId: watch('content.id'),
          targetCount: targetCount,
          message: watch('message'),
        },
        sample: true,
      });

      const singleTarget = targetCount === 1;
      const actionLabel = singleTarget ? '채팅방으로 이동' : '채팅목록 보기';
      const targetUri = singleTarget ? targets[0].targetUri : getNativeChatTabScheme();

      snackbarAdapter.create({
        message: '채팅방에 게시글을 공유했어요.',
        type: 'default',
        timeout: 2000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
        actionLabel,
        onAction: () => {
          setTimeout(() => {
            window.location.href = targetUri;
          }, 100);
        },
      });
    },
  });

  return (
    <>
      <div className={s.MessageInputOuterWrapper}>
        <div className={s.MessageInputWrapper}>
          <EditMultiLineTextarea
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={s.Textarea}
            defaultRows={1}
            placeholder="메시지를 입력해주세요.(선택)"
            onChange={(e) => {
              setValue('message', e.target.value);
            }}
            value={watch('message')}
          />
        </div>
      </div>
      <div className={s.FooterButtonWrapper}>
        <BoxButton
          onClick={() => {
            pop();
            mutate({
              content: watch('content'),
              targets: watch('targets'),
              message: watch('message'),
            });
          }}
          width="100%"
          size="xlarge"
        >
          채팅방에 보내기
        </BoxButton>
      </div>
    </>
  );
};

export default ShareMessageInput;
