import { useEffect } from 'react';

const useWrapperOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  outsideClickCallback: () => void,
  insideClickCallback?: () => void
) => {
  const clickInside = (e: MouseEvent) => {
    e?.stopPropagation();
    // textarea의 기본 동작은 그대로 둔다. (text 중간으로 cursur 이동하는 동작)
    if ((e.target as HTMLElement)?.localName !== 'textarea') {
      e?.preventDefault();
    }
    insideClickCallback?.();
  };

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickCallback);
    ref.current?.addEventListener('mousedown', clickInside);

    return () => {
      document.removeEventListener('mousedown', outsideClickCallback);
      ref.current?.removeEventListener('mousedown', clickInside);
    };
  }, [outsideClickCallback]);
};

export default useWrapperOutsideClick;
