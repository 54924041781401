import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';

import LoadingFullScreen from '@/components/common/LoadingFullScreen';
import { COOKIE } from '@/constants/keys';
import { useBridge } from '@/contexts/Bridge';
import { routes } from '@/stackflow/activity/routes';

const TokenSettingPage = () => {
  const { isInitialized } = useBridge();

  const redirectErrorPage = () => (window.location.href = routes.NotFoundPage);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    const params = new URLSearchParams(window.location.search);
    const redirect = decodeURIComponent(params.get('redirect') ?? '');

    if (isInitialized) {
      // 여기를 거친 이후엔 쿠키에 토큰이 있어야해요.
      // 그럼에도 토큰이 없으면 비정상적인 접근이므로 에러 페이지로 보내요.
      const cookies = new Cookies();

      if (!cookies.get(COOKIE.AUTH_TOKEN)) {
        Sentry.captureMessage('토큰 초기화에 실패했어요.', {
          level: 'error',
          extra: {
            originPath: redirect,
            isInitialized,
            reason: 'COOKIE_NOT_FOUND',
            cookies: cookies?.getAll?.(),
            documentCookie: document?.cookie,
          },
        });
        redirectErrorPage();
        return;
      }
      // 여기를 거친 이후엔 토큰이 쿠키에 세팅이 되어있을 것이므로 redirectPath로 보내요.
      window.history.replaceState(null, '', redirect);
      window.location.href = redirect;
    } else {
      timer = setTimeout(() => {
        Sentry.captureMessage('토큰 초기화에 실패했어요.', {
          level: 'error',
          extra: {
            originPath: redirect,
            isInitialized,
            reason: 'TIMEOUT',
          },
        });
        redirectErrorPage();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isInitialized]);
  return <LoadingFullScreen />;
};

export default TokenSettingPage;
