import { vars } from '@seed-design/design-token';
import React from 'react';

interface Props {
  strokeColor?: string;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
}

const IconFlagWithCircle = ({ size = 24, strokeColor: propsStrokeColor, onClick }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$static.color.staticWhite;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={vars.$scale.color.red500} />
      <g clipPath="url(#clip0_8691_184544)">
        <path
          d="M7.05029 16.95V7.5"
          stroke={strokeColor}
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.05029 14.2501C10.6503 11.5501 13.3503 16.9501 16.9503 14.2501V7.50013C13.3503 10.2001 10.6503 4.80013 7.05029 7.50013"
          fill={strokeColor}
        />
        <path
          d="M7.05029 14.2501C10.6503 11.5501 13.3503 16.9501 16.9503 14.2501V7.50013C13.3503 10.2001 10.6503 4.80013 7.05029 7.50013"
          stroke={strokeColor}
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8691_184544">
          <rect
            width="14.4"
            height="14.4"
            fill={strokeColor}
            transform="translate(4.80005 4.80005)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFlagWithCircle;
