import { useCallback, useMemo } from 'react';

import { useDeleteGroupFeedback } from '@/api/hooks/useDeleteGroupFeedback';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { usePostGroupFeedback } from '@/api/hooks/usePostGroupFeedback';

import { useHandleErrorWithToast } from '../useHandleErrorWithToast';

export type FetchIsNotViewGroupFeedbackQueryOptions = {
  onSuccess?: () => void;
};

type Props = {
  groupId: string;
  options?: FetchIsNotViewGroupFeedbackQueryOptions;
};

export const useFetchIsNotViewGroupFeedback = ({ groupId, options }: Props) => {
  const { data: myInfo, refetch } = useGetGroupMe(groupId);
  const isNotViewGroup = useMemo(
    () => myInfo?.currentUser.isNotViewGroup,
    [myInfo?.currentUser.isNotViewGroup]
  );

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateGroupFeedback } = usePostGroupFeedback({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetch();
      options?.onSuccess?.();
    },
  });

  const { mutate: mutateDeleteGroupFeedback } = useDeleteGroupFeedback({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetch();
      options?.onSuccess?.();
    },
  });

  const setIsNotViewGroup = useCallback(
    (isSetNotViewGroup: boolean, options?: FetchIsNotViewGroupFeedbackQueryOptions) => {
      if (isSetNotViewGroup) {
        return mutateGroupFeedback(
          {
            groupId,
            groupFeedbackCreateForm: {
              reason: 'not_view_group',
            },
          },
          {
            onSuccess: () => {
              options?.onSuccess?.();
            },
          }
        );
      }

      return mutateDeleteGroupFeedback(
        {
          groupId,
          groupFeedbackReason: 'not_view_group',
        },
        {
          onSuccess: () => {
            options?.onSuccess?.();
          },
        }
      );
    },
    [myInfo?.currentUser.isNotViewGroup]
  );

  return {
    isNotViewGroup,
    setIsNotViewGroup,
  };
};
