import { Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconAddFill, IconChevronRightFill } from '@seed-design/icon';

import * as s from './ChallengeTemplateItem.css';

type Props = {
  name: string;
  defaultData: { name: string };
  isTempSavedData?: boolean;
  onClick: () => void;
};

// 챌린지 생성 템플릿 옵션 아이템
export const ChallengeTemplateItem = ({ name, onClick, isTempSavedData = false }: Props) => {
  return (
    <div className={s.Wrapper} onClick={onClick}>
      <Typography typography="label2Bold" ellipsisAfterLines={1}>
        {isTempSavedData && (
          <Typography typography="label3Regular" color={'gray500'} style={{ marginRight: 6 }}>
            임시 저장
          </Typography>
        )}
        {name}
      </Typography>
      <IconChevronRightFill size={20} color={vars.$scale.color.gray500} />
    </div>
  );
};

// 챌린지 생성 직접 만들기 아이템
export const ChallengeCustomItem = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={s.CustomWrapper} onClick={onClick}>
      <Typography typography="label2Bold">직접 만들기</Typography>
      <VerticalSpacing size={10} />
      <IconAddFill size={18} color={vars.$scale.color.gray500} />
    </div>
  );
};
