import {
  AsyncBoundary,
  Tab,
  TabList,
  TabPanel,
  TabPanelGroup,
  Tabs,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useRef, useState } from 'react';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import SearchBar from '../components/SearchBar';
import SearchHomeBanner from '../components/SearchHomeBanner';
import SearchResultAllResult from '../components/SearchResultAllResult';
import SearchResultGroup from '../components/SearchResultGroup';
import SearchResultMeetup from '../components/SearchResultMeetup';
import * as s from './Result.css';

export type SearchResultPageParams = Pick<PageParams, 'query' | 'searchCurrentTab'>;

const SearchResultPage: ActivityComponentType<SearchResultPageParams> = () => {
  const { query = '' } = usePathParams();
  const { searchCurrentTab = 'all' } = useQueryParams();
  const { replace } = useFlow();

  const [currentTab, setCurrentTab] = useState<'all' | 'group' | 'meetup' | 'onlyGroup'>(
    searchCurrentTab
  );

  return (
    <AppScreen
      appBar={{
        renderRight: () => (
          <div
            style={{
              position: 'relative',
              left: '-8px',
              width: 'calc(100vw - 3.25rem - 14px)',
            }}
          >
            <SearchBar
              initValue={decodeURIComponent(query)}
              onSubmit={(value) => {
                replace(
                  'SearchResultPage',
                  {
                    query: encodeURIComponent(value),
                    searchCurrentTab: currentTab,
                  },
                  { animate: false }
                );
              }}
            />
          </div>
        ),
      }}
    >
      {currentTab === 'onlyGroup' ? (
        <SearchResultGroup />
      ) : (
        <SearchResultPageWrapper currentTab={currentTab} setCurrentTab={setCurrentTab} />
      )}
    </AppScreen>
  );
};

const SearchResultPageWrapper = ({
  currentTab,
  setCurrentTab,
}: {
  currentTab: 'all' | 'group' | 'meetup' | 'onlyGroup';
  setCurrentTab: React.Dispatch<React.SetStateAction<'all' | 'group' | 'meetup' | 'onlyGroup'>>;
}) => {
  const { query = '' } = usePathParams();

  const { referrer = 'community_group.client' } = useQueryParams();
  const referQueryParams = useReferQueryParams();

  const SearchTabContents = [
    {
      value: 'all',
      label: '전체',
      content: (
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <SearchResultAllResult setCurrentTab={setCurrentTab} />
        </AsyncBoundary>
      ),
    },
    {
      value: 'meetup',
      label: '일정',
      content: <SearchResultMeetup />,
    },
    {
      value: 'group',
      label: '모임',
      content: <SearchResultGroup />,
    },
  ];

  useEnterTrackEvent({
    event: 'enter_search_result',
    params: {
      keyword: decodeURIComponent(query),
      from: referrer,
      ...referQueryParams,
    },
    sample: true,
  });

  const ref = useRef<HTMLDivElement>(null);
  // tab 전환시 최상단으로 스크롤
  const scrollToTabTop = () => {
    const rect = ref.current?.getClientRects()[0];
    if (rect?.top && rect.top < 50) {
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  return (
    <>
      {referrer !== 'community_group.client' && (
        <>
          <SearchHomeBanner />
        </>
      )}
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <div className={s.tabsWrapper} ref={ref}>
          <Tabs
            layout="hug"
            defaultValue="all"
            value={currentTab}
            isLazy
            lazyMode="unmount"
            isSwipeable={false}
            onChange={(value) => {
              scrollToTabTop();
              trackEvent({
                event: 'click_search_tab',
                params: {
                  tab: value as 'all' | 'group' | 'meetup',
                  keyword: decodeURIComponent(query),
                  from: referrer,
                  ...referQueryParams,
                },
                sample: true,
              });
              setCurrentTab(value as 'all' | 'group' | 'meetup');
            }}
          >
            <TabList>
              {SearchTabContents.map((item) => (
                <Tab key={item.value} value={item.value}>
                  {item.label}
                </Tab>
              ))}
            </TabList>
            <TabPanelGroup>
              {SearchTabContents.map((item) => (
                <TabPanel key={item.value} value={item.value}>
                  {item.content}
                </TabPanel>
              ))}
            </TabPanelGroup>
          </Tabs>
        </div>
      </AsyncBoundary>
    </>
  );
};

export default withAsyncBoundary(SearchResultPage, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <ViewError />,
});
