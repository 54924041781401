import { MEETUP_PROGRESS_STATUS_FILTER } from '@/api/hooks/useGetMeetupList';
import { getMyGroupsMeetupLikesPath } from '@/api/hooks/useGetMyGroupsMeetupLikes';
import { queryClient } from '@/api/instance';

/*
 *  관심 일정 목록 페이지에서 리페치 하는 함수
 */
export const refetchMyInfoLikedMeetupList = () => {
  queryClient.refetchQueries([getMyGroupsMeetupLikesPath, MEETUP_PROGRESS_STATUS_FILTER.OPENED]);
  queryClient.refetchQueries([getMyGroupsMeetupLikesPath, MEETUP_PROGRESS_STATUS_FILTER.CLOSED]);
};
