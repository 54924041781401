import { Dialog, encodeURL, useDialog } from '@community-group/components';
import React from 'react';
import { useCallback } from 'react';
import Linkify from 'react-linkify';

import { getElementFromMention } from '@/components/common/Mention';
import { useBridge } from '@/contexts/Bridge';
import { hasOpenKakaoExternalLink } from '@/hooks/useCheckOpenKakao';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';

type Props = {
  text: string;
  currentUserId?: string;
};

const LinkifyContainer = ({ text, currentUserId }: Props) => {
  const { bridge } = useBridge();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { groupId } = usePathParams();
  const { push } = useFlow();

  const handleOnClickMention = useCallback(
    (userId: string, event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      trackEvent({
        event: 'click_mention_user',
        params: {
          userId,
          groupId,
        },
      });
      push('GroupUserProfileDetailPage', {
        groupId: groupId.toString(),
        userId: userId,
      });
    },
    [groupId]
  );

  const getPlainTextFromMentionTextFunction = useCallback(() => {
    return getElementFromMention(text, 'tag', handleOnClickMention);
  }, [text, currentUserId]);

  const openLink = useCallback((decoratedHref: string) => {
    bridge.pushRouter({
      router: {
        remote: encodeURL(decoratedHref),
        navbar: true,
        enableSafeAreaInsets: false,
        backSwipable: true,
      },
    });
  }, []);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, decoratedHref: string) => {
      e.stopPropagation();

      if (hasOpenKakaoExternalLink(decoratedHref)) {
        openDialog({
          element: (
            <Dialog
              title="외부 링크로 이동할까요?"
              description="이웃과의 활동은 당근 모임을 이용하는 것이 가장 안전해요."
              secondaryActionLabel="취소"
              onSecondaryAction={async () => {
                await closeDialog();
                return;
              }}
              primaryActionLabel="이동"
              onPrimaryAction={async () => {
                await closeDialog();
                openLink(decoratedHref);
              }}
            />
          ),
        });
        return;
      }

      openLink(decoratedHref);
    },
    [closeDialog, openDialog, openLink]
  );

  const renderLinkHandleMention = useCallback(() => {
    const jsxElements = getPlainTextFromMentionTextFunction().map((item, key) => (
      <span key={key}>
        {typeof item === 'string'
          ? item
              .split('\n')
              .map((line, lineKey) => (
                <React.Fragment key={`${key}_${lineKey}`}>{line}</React.Fragment>
              ))
          : item}
      </span>
    ));

    return jsxElements;
  }, [getPlainTextFromMentionTextFunction]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => {
        if (/[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/.test(decoratedText)) {
          return decoratedText;
        }
        return (
          <a key={key} className={s.link} onClick={(e) => handleOnClick(e, decoratedHref)}>
            {decoratedText}
          </a>
        );
      }}
    >
      {renderLinkHandleMention()}
    </Linkify>
  );
};

export default React.memo(LinkifyContainer);
