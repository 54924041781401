import { Skeleton } from '@community-group/components';

import * as s from './ListSkeleton.css';

const ListSkeleton = () => (
  <div className={s.wrapper}>
    <div className={s.contentsWrapper}>
      {Array.from({ length: 8 }).map((item, index) => (
        <div className={s.item} key={`talk-skeleton-${index}`}>
          <div className={s.profileWrapper}>
            <Skeleton width="4.5rem" height="4.5rem" borderRadius="1rem" />
          </div>
          <div className={s.contentsWrapper}>
            <Skeleton width="100%" height="16px" enableAnimation />
            <div className={s.avatarListWrapper}>
              {Array.from({ length: 3 }).map((item, index) => (
                <Skeleton
                  key={index}
                  width="1.25rem"
                  height="1.25rem"
                  borderRadius="50%"
                  style={{ marginLeft: '-5px' }}
                />
              ))}
            </div>
            <div className={s.avatarListWrapper}>
              <Skeleton width="3.875rem" height="1.1875rem" style={{ marginRight: '.375rem' }} />
              <Skeleton width="2rem" height="1.25rem" style={{ marginRight: '.375rem' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ListSkeleton;
