import { addHours, addMonths, isAfter, parse, setMinutes } from 'date-fns';
import ko from 'date-fns/locale/ko/index';
import { format as formatDateDefault, OptionsWithTZ } from 'date-fns-tz';

export const KARROT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DATE = HOUR * 24;

export const getDateDiffFromNow = (updatedAt: Date) => {
  const nowDate = new Date();
  const diffMs = +nowDate - +updatedAt;

  if (diffMs < 0) {
    return { text: `0초`, type: 'second', diff: 0 };
  }

  if (diffMs < MINUTE) {
    return {
      text: `방금`,
      type: 'second',
      diff: diffMs,
    };
  } else if (diffMs < HOUR) {
    return {
      text: `${Math.floor(diffMs / MINUTE)}분`,
      type: 'minute',
      diff: diffMs,
    };
  } else if (diffMs < DATE) {
    return {
      text: `${Math.floor(diffMs / HOUR)}시간`,
      type: 'hour',
      diff: diffMs,
    };
  } else if (diffMs < DATE * 30) {
    return {
      text: `${Math.floor(diffMs / DATE)}일`,
      type: 'date',
      diff: diffMs,
    };
  }

  const nowYear = nowDate.getFullYear();
  const updatedYear = updatedAt.getFullYear();
  const yearDiff = nowYear - updatedYear;
  const nowMonth = nowDate.getMonth() + 1;
  const updatedMonth = updatedAt.getMonth() + 1;

  if (nowMonth === updatedMonth) {
    if (yearDiff) {
      if (nowDate.getDate() < updatedAt.getDate()) {
        return yearDiff === 1
          ? { text: `12개월`, type: 'month', diff: diffMs }
          : { text: `${yearDiff - 1}년`, type: 'year', diff: diffMs };
      } else {
        return { text: `${yearDiff}년`, type: 'year', diff: diffMs };
      }
    } else {
      return { text: `1개월`, type: 'month', diff: diffMs };
    }
  } else if (nowMonth > updatedMonth) {
    if (nowYear > updatedYear) {
      return { text: `${nowYear - updatedYear}년`, type: 'year', diff: diffMs };
    } else {
      return {
        text: `${nowMonth - updatedMonth || 1}개월`,
        type: 'month',
        diff: diffMs,
      };
    }
  } else {
    if (nowYear > updatedYear + 1) {
      return { text: `${nowYear - updatedYear}년`, type: 'year', diff: diffMs };
    } else {
      return {
        text: `${12 - updatedMonth + nowMonth}개월`,
        type: 'month',
        diff: diffMs,
      };
    }
  }
};

export const formatDate = (date: Date, format: string, config?: OptionsWithTZ) =>
  formatDateDefault(date, format, {
    locale: ko,
    ...config,
  });

export const initMeetupTimeAt = () =>
  formatDate(setMinutes(addHours(new Date(), 1), 0), KARROT_DATE_FORMAT);

export { addMonths, isAfter, parse };
