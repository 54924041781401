import { AsyncBoundary, Dialog, Typography, useDialog } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { usePutGroup } from '@/api/hooks/usePutGroup';
import { FormRadioForApproval } from '@/components/group/new/Approval/components/FormRadioForApproval';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

export type GroupSettingApplicationApprovalBottomSheetParams = Pick<
  PageParams,
  'groupId' | 'initialJoinType'
>;

const GroupSettingApplicationApprovalBottomSheet: ActivityComponentType<
  GroupSettingApplicationApprovalBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingApplicationApprovalBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingApplicationApprovalBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { initialJoinType = 'free' } = useQueryParams();

  const { pop, push } = useFlow();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { watch, register, setValue } = useForm<FieldValues>({
    defaultValues: {
      joinType: initialJoinType,
    },
  });
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });

      bridge.openToast({
        toast: {
          body: `수정되었어요.`,
        },
      });
    },
  });

  const joinTypeFieldValue = watch('joinType');
  const handleSubmit = () => {
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          joinType: joinTypeFieldValue,
          applicationConfig: {
            questions: [{ question: '', required: false }],
            haveQuestion: false,
          },
        },
      },
      {
        onSuccess: () => {
          pop();

          if (joinTypeFieldValue === 'approval') {
            setTimeout(() => {
              push('BottomSheet/GroupSettingApplicationQuestionBottomSheet', {
                groupId,
                initialQuestionRequired: 'false',
                initialQuestionValue: '',
              });
            }, 200);
          }
        },
      }
    );
  };

  useEffect(() => {
    if (initialJoinType === joinTypeFieldValue) return;

    if (joinTypeFieldValue === 'free') {
      openDialog({
        element: (
          <Dialog
            title="가입 방식 변경 안내"
            description="바로 가입으로 변경하면 가입 대기 중인 이웃이 모두 가입돼요. 바로 가입으로 변경할까요?"
            primaryActionLabel="변경"
            secondaryActionLabel="취소"
            onSecondaryAction={async () => {
              await closeDialog();
              setValue('joinType', 'approval');
            }}
            onPrimaryAction={async () => {
              await closeDialog();
              handleSubmit();
            }}
          />
        ),
      });
      return;
    }
    handleSubmit();
  }, [joinTypeFieldValue]);

  return (
    <>
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900">
        가입 방식
      </Typography>
      <Spacing size={16} />
      <FormRadioForApproval
        register={register}
        value="free"
        name="joinType"
        title="바로 가입"
        subtitle="누구나 가입할 수 있어요."
        checked={joinTypeFieldValue === 'free'}
      />
      <Spacing size={8} />
      <FormRadioForApproval
        register={register}
        value="approval"
        name="joinType"
        title="승인 후 가입"
        subtitle="모임장이 승인해야 가입할 수 있어요."
        checked={joinTypeFieldValue === 'approval'}
      />
    </>
  );
};

export default GroupSettingApplicationApprovalBottomSheet;
