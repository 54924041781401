import { useQuery } from '@tanstack/react-query';

import { getXproperty, getXpropertyPath, XpropertyData } from '@/api/base/xproperty';
import { useXPropertyFetchInstance } from '@/api/hooks/instance/useXPropertyFetchInstance';

export const useXproperty = (): XpropertyData => {
  const xpropertyFetchInstance = useXPropertyFetchInstance();

  const getXpropertyFs = async () => {
    return getXproperty({
      xpropertyFetchInstance,
    });
  };
  const { data: xpropertys } = useQuery([getXpropertyPath], getXpropertyFs, { suspense: true });

  return xpropertys as XpropertyData;
};
