import { GroupRunningGrowthResponseGrowthList } from '@community-group/api/lib/group/models';

import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';

export const getCurrentRunningGrowthStep = (calloutType: ActivityQueryParams['calloutType']) => {
  const calloutArr = [
    'running-growth-mission1-set-group-description',
    'running-growth-mission2-set-notice',
    'running-growth-mission3-set-running-course',
    'running-growth-mission4-set-first-meetup',
    'running-growth-mission5-set-3-meetup',
  ];

  if (!calloutType) return undefined;
  if (!calloutArr.includes(calloutType)) return undefined;

  return calloutArr.indexOf(calloutType) + 1;
};

//현재 진행중인 미션 스텝 확인
export const checkStepSuccess = (runningGrowthData?: GroupRunningGrowthResponseGrowthList[]) => {
  if (runningGrowthData && runningGrowthData?.length !== 0) {
    for (let i = 0; i < runningGrowthData.length; i++) {
      if (!runningGrowthData[i].isStepCompleted && runningGrowthData[i].isStepSuccess) {
        return runningGrowthData[i].step;
      }
    }

    for (let i = 0; i < runningGrowthData.length; i++) {
      if (!runningGrowthData[i].isStepCompleted && !runningGrowthData[i].isStepSuccess) {
        return runningGrowthData[i].step;
      }
    }
  }

  return undefined;
};
