type Props = { size?: number };

function Large2DLevel2({ size = 48 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0025 5.99989C25.1461 4.9281 22.8589 4.9281 21.0025 5.99989L9.91406 12.4018C8.05766 13.4736 6.91406 15.4544 6.91406 17.598V30.4018C6.91406 32.5454 8.05766 34.5262 9.91406 35.598L21.0025 41.9999C22.8589 43.0717 25.1461 43.0717 27.0025 41.9999L38.091 35.598C39.9474 34.5262 41.091 32.5454 41.091 30.4018V17.598C41.091 15.4544 39.9474 13.4736 38.091 12.4018L27.0025 5.99989Z"
        fill="#3D7FFF"
        stroke="#A3D8FF"
        strokeWidth="3"
      />
      <mask
        id="mask0_8594_118729"
        {...({ maskType: 'alpha' } as any)}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="38"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.52 5.09673L38.6122 11.5005C40.7849 12.7584 42.1317 15.0836 42.1317 17.5993V30.4068C42.1317 32.9226 40.7849 35.2478 38.6122 36.5057L27.52 42.9094C25.3473 44.1673 22.6536 44.1673 20.4809 42.9094L9.38867 36.5057C7.21596 35.2478 5.86914 32.9226 5.86914 30.4068V17.5993C5.86914 15.0836 7.21596 12.7584 9.38867 11.5005L20.4809 5.09673C22.6536 3.83885 25.3473 3.83885 27.52 5.09673Z"
          fill="#DDC7B8"
        />
      </mask>
      <g mask="url(#mask0_8594_118729)">
        <g filter="url(#filter0_d_8594_118729)">
          <path
            d="M27.739 15.0513C24.547 15.0513 22.231 17.2833 22.339 21.7953C20.311 19.7193 17.095 20.4033 16.027 21.5553C14.875 22.8033 13.711 25.4553 16.327 29.0673C17.827 31.1313 20.959 32.7273 22.759 33.1353V29.8113L19.819 27.3393C19.495 27.0633 19.447 26.5713 19.723 26.2353C19.999 25.9113 20.491 25.8633 20.827 26.1393L23.383 28.2873L25.855 24.0033C26.071 23.6313 26.551 23.4993 26.923 23.7153C27.295 23.9313 27.427 24.4113 27.211 24.7833L24.331 29.7753V33.1473C27.511 32.4513 32.551 28.0233 32.551 21.1953C32.551 16.4673 29.707 15.0513 27.751 15.0513H27.739Z"
            fill="url(#paint0_linear_8594_118729)"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_d_8594_118729)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5213 5.09812L38.6098 11.5C40.7883 12.7578 42.1304 15.0823 42.1304 17.5979V30.4017C42.1304 32.9173 40.7883 35.2418 38.6098 36.4996L27.5213 42.9015C25.3428 44.1593 22.6587 44.1593 20.4801 42.9015L9.39168 36.4996C7.21314 35.2418 5.87109 32.9173 5.87109 30.4017V17.5979C5.87109 15.0823 7.21313 12.7578 9.39168 11.5L20.4801 5.09812C22.6587 3.84034 25.3428 3.84034 27.5213 5.09812ZM26.2507 7.29885C24.8584 6.495 23.143 6.495 21.7507 7.29885L10.6623 13.7008C9.26997 14.5046 8.41227 15.9902 8.41227 17.5979V30.4017C8.41227 32.0094 9.26997 33.495 10.6623 34.2989L21.7507 40.7008C23.143 41.5046 24.8584 41.5046 26.2507 40.7008L37.3392 34.2989C38.7315 33.495 39.5892 32.0094 39.5892 30.4017V17.5979C39.5892 15.9902 38.7315 14.5046 37.3392 13.7008L26.2507 7.29885Z"
            fill="#A3D8FF"
          />
        </g>
        <g filter="url(#filter2_d_8594_118729)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5213 5.09812L38.6098 11.5C40.7883 12.7578 42.1304 15.0823 42.1304 17.5979V30.4017C42.1304 32.9173 40.7883 35.2418 38.6098 36.4996L27.5213 42.9015C25.3428 44.1593 22.6587 44.1593 20.4801 42.9015L9.39168 36.4996C7.21314 35.2418 5.87109 32.9173 5.87109 30.4017V17.5979C5.87109 15.0823 7.21313 12.7578 9.39168 11.5L20.4801 5.09812C22.6587 3.84034 25.3428 3.84034 27.5213 5.09812ZM26.2507 7.29885C24.8584 6.495 23.143 6.495 21.7507 7.29885L10.6623 13.7008C9.26997 14.5046 8.41227 15.9902 8.41227 17.5979V30.4017C8.41227 32.0094 9.26997 33.495 10.6623 34.2989L21.7507 40.7008C23.143 41.5046 24.8584 41.5046 26.2507 40.7008L37.3392 34.2989C38.7315 33.495 39.5892 32.0094 39.5892 30.4017V17.5979C39.5892 15.9902 38.7315 14.5046 37.3392 13.7008L26.2507 7.29885Z"
            fill="#A3D8FF"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8594_118729"
          x="14.1108"
          y="15.0513"
          width="19.0753"
          height="19.6912"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.96" />
          <feGaussianBlur stdDeviation="0.317647" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118729" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118729"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8594_118729"
          x="3.32992"
          y="0.343021"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118729" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118729"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8594_118729"
          x="3.32992"
          y="2.8842"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118729" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118729"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8594_118729"
          x1="23.6485"
          y1="15.0513"
          x2="23.6485"
          y2="33.1473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Large2DLevel2;
