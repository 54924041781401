import {
  UserMeetupMemberReview,
  UserMeetupMemberReviewMessage,
} from '@community-group/api/lib/group/models';
import { HorizontalSpacing, Spacing, Typography } from '@community-group/components';
import { IconChevronRightRegular } from '@seed-design/icon';
import { useMemo } from 'react';

import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';
import { useFlow } from '@/stackflow';

import { ReviewLabelItem } from './ReviewLabelItem';
import { ReviewMessageList } from './ReviewMessageList';
import * as s from './style.css';

type Props = {
  meetupMemberReviews: UserMeetupMemberReview[];
  meetupMemberReviewMessages: UserMeetupMemberReviewMessage[];
  isMyProfile: boolean;
  userId: string;
  groupId?: string;
};

const MAX_REVIEWS = 3;

export const MemberProfileMeetupMemberReviews = ({
  meetupMemberReviews,
  meetupMemberReviewMessages,
  isMyProfile,
  userId,
  groupId,
}: Props) => {
  const { push } = useFlow();
  const hasMeetupMemberReviews = useMemo(() => {
    return meetupMemberReviews.length > 0;
  }, [meetupMemberReviews]);

  const hasMeetupMemberReviewMessages = useMemo(() => {
    return meetupMemberReviewMessages.length > 0;
  }, [meetupMemberReviewMessages]);

  const filteredMeetupMemberReviewMessages = useMemo(() => {
    if (isMyProfile) return meetupMemberReviewMessages;

    return meetupMemberReviewMessages.filter(
      ({ reviewExposureRange }) => reviewExposureRange === 'ALL'
    );
  }, [meetupMemberReviewMessages, isMyProfile]);

  const filteredLikeReviews = meetupMemberReviews.filter(({ feedbackTypeId }) =>
    MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId)
  );

  const totalReviewCount = filteredLikeReviews.reduce((acc, { count }) => acc + count, 0);
  const renderReviews = useMemo(() => {
    if (!hasMeetupMemberReviews) return null;

    if (!filteredLikeReviews.length) return null;

    const initialReviews = filteredLikeReviews.slice(0, MAX_REVIEWS);
    const resetReviews = filteredLikeReviews.slice(MAX_REVIEWS);
    const restReviewCount = resetReviews.reduce((acc, { count }) => acc + count, 0);

    return (
      <>
        <HorizontalSpacing size={16} />
        <div className={s.LabelListWrapper}>
          <InitialReviews reviews={initialReviews} />
          {restReviewCount > 0 && (
            <AdditionalReviews reviewCount={restReviewCount} userId={userId} groupId={groupId} />
          )}
        </div>
      </>
    );
  }, [filteredLikeReviews, groupId, hasMeetupMemberReviews, userId]);

  return (
    <section className={s.Wrapper}>
      <div className={s.TitleSection}>
        <div className={s.TitleWrapper}>
          <Typography as="h6" typography="title3Bold">
            받은 후기 {totalReviewCount}
          </Typography>
          <HorizontalSpacing size={6} />
          <Typography typography="label3Regular" color="gray600">
            일정에 참여하고 멤버들에게 받은 후기예요.
          </Typography>
        </div>
        <IconChevronRightRegular
          size={20}
          onClick={() =>
            push('ProfileMeetupMemberReviewMessagesPage', { userId, groupId: groupId ?? '' })
          }
        />
      </div>
      {renderReviews}
      {hasMeetupMemberReviewMessages && (
        <>
          <Spacing size={16} />
          <ReviewMessageList
            meetupMemberReviewMessages={filteredMeetupMemberReviewMessages}
            userId={userId}
            groupId={groupId}
            isMyProfile={isMyProfile}
          />
        </>
      )}
    </section>
  );
};

const InitialReviews = ({ reviews }: { reviews: UserMeetupMemberReview[] }) => (
  <>
    {reviews.map((review) => (
      <ReviewLabelItem key={review.feedbackTypeId} meetupMemberReview={review} />
    ))}
  </>
);

type AdditionalReviewsProps = Pick<Props, 'userId' | 'groupId'> & {
  reviewCount: number;
};
const AdditionalReviews = ({ reviewCount, userId, groupId }: AdditionalReviewsProps) => {
  const { push } = useFlow();
  return (
    <div
      className={s.LabelWrapper}
      onClick={() =>
        push('ProfileMeetupMemberReviewMessagesPage', { userId, groupId: groupId ?? '' })
      }
    >
      <Typography as="p" typography="label3Regular">
        +{reviewCount}
      </Typography>
    </div>
  );
};
