export const AGE_RANGE_OPTIONS = [
  {
    id: 0,
    name: '누구나',
    value: {
      minAge: undefined,
      maxAge: undefined,
    },
    showCustomInput: false,
  },
  {
    id: 1,
    name: '20대',
    value: {
      minAge: 20,
      maxAge: 29,
    },
    showCustomInput: false,
  },
  {
    id: 2,
    name: '30대',
    value: {
      minAge: 30,
      maxAge: 39,
    },
    showCustomInput: false,
  },
  {
    id: 3,
    name: '40대',
    value: {
      minAge: 40,
      maxAge: 49,
    },
    showCustomInput: false,
  },
  {
    id: 4,
    name: '50대',
    value: {
      minAge: 50,
      maxAge: 59,
    },
    showCustomInput: false,
  },
  {
    id: 5,
    name: '60대',
    value: {
      minAge: 60,
      maxAge: 69,
    },
    showCustomInput: false,
  },
  {
    id: 6,
    name: '직접 입력',
    value: undefined,
    showCustomInput: true,
  },
];
