import { groupClient } from '@community-group/api';
import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { GroupAvatar } from '@community-group/components';
import clsx from 'clsx';
import React from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';
import { convertNicknameDisplay } from '@/utils/nickname';

import CheckAccessibleRole from '../CheckAccessibleRole';
import ColoredKing from '../Icons/coloredKing';
import * as s from './ListItem.css';

export type ProfileListItemUser = Pick<
  GroupMemberSummaryPresentation,
  'id' | 'nickname' | 'isAccountDeleted' | 'userRegion' | 'profileImage' | 'role' | 'subNickname'
> &
  Partial<
    Omit<
      GroupMemberSummaryPresentation,
      | 'id'
      | 'nickname'
      | 'isAccountDeleted'
      | 'userRegion'
      | 'profileImage'
      | 'role'
      | 'subNickname'
    >
  >;
export interface ProfileListItemProps {
  appendLabel?: string;
  appendDescription?: string;
  user: ProfileListItemUser;
  config?: {
    shownMeBadge?: boolean;
    handledBadgeClick?: boolean;
  };
}

interface Props extends React.InputHTMLAttributes<HTMLDivElement>, ProfileListItemProps {}

const ProfileListItem = ({
  appendLabel,
  appendDescription,
  user: {
    nickname,
    subNickname,
    isAccountDeleted,
    description,
    userRegion,
    profileImage = '',
    role,
    userRegionCheckInCount,
  },
  config: { shownMeBadge = false, handledBadgeClick = true } = {},
  ...props
}: Props) => {
  const isHost = role === groupClient.model.GroupMemberRoleEnum.superHost;

  const { userConfig } = useUserConfig();
  const { groupId } = usePathParams();

  const regionLabel = () => {
    if (appendLabel) {
      return appendLabel;
    }
    if (userRegionCheckInCount) {
      return `${userRegion?.name} 인증 ${userRegionCheckInCount}회`;
    }
    return userRegion?.name;
  };

  return (
    <div {...props} className={clsx([props.className, s.wrapper])}>
      {/* TODO: createdDate 3월 11일 프로필 실험을 위한 임시 코드 */}
      <GroupAvatar
        src={getUserProfileUrlInduceExperiment({
          groupId: parseInt(groupId),
          userId: userConfig.userId ?? 0,
          defaultUrl: profileImage,
        })}
        size="small"
      />
      <div className={s.contentsWrapper}>
        <div className={s.titleWrapper}>
          <h6 className={s.title({ isAccountDeleted })}>
            {shownMeBadge && <div className={s.meBadge}>나</div>}
            {convertNicknameDisplay({ nickname, subNickname })}
            <CheckAccessibleRole currentRole={role} accessibleRoles={['superHost', 'manager']}>
              <div
                className={s.badge}
                onClick={() => {
                  if (!handledBadgeClick) return;
                }}
              >
                <div className={s.icon}>
                  <ColoredKing fill={isHost ? 'staticYellow300' : 'staticGreen400'} />
                </div>
              </div>
            </CheckAccessibleRole>
          </h6>

          <div className={s.label}>{regionLabel()}</div>
        </div>
        {(description || appendDescription) && (
          <p className={s.description}>{appendDescription ?? description}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileListItem;
