import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import RoomList from '../../Detail/components/RoomList';

type GroupDetailChatRoomPageParams = Pick<PageParams, 'groupId'>;

const GroupDetailChatRoomPage: ActivityComponentType<GroupDetailChatRoomPageParams> = () => {
  return (
    <AppScreen appBar={{ title: '채팅' }}>
      <RoomList />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailChatRoomPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
