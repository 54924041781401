import { useEffect, useRef } from 'react';
import { SwiperRef } from 'swiper/react';

const SWIPE_OPERATING_RANGE = 5;

export const usePreventScrollOnSwipe = () => {
  const swiperRef = useRef<SwiperRef>();

  useEffect(() => {
    let prevPageX: number;

    const touchStartEvent = (e: TouchEvent) => {
      const { pageX } = e.changedTouches[0];
      prevPageX = pageX;
    };

    const touchMoveEvent = (e: TouchEvent) => {
      const { pageX } = e.changedTouches[0];

      const swipeDistance = Math.abs(pageX - prevPageX);
      const isSwiping = swipeDistance > SWIPE_OPERATING_RANGE;
      if (isSwiping) {
        e.preventDefault(); // prevent scroll
      }

      prevPageX = pageX;
    };

    const targetElement = swiperRef.current as unknown as HTMLElement;
    if (!targetElement) {
      return;
    }

    targetElement.addEventListener('touchstart', touchStartEvent, {
      passive: false,
    });
    targetElement.addEventListener('touchmove', touchMoveEvent, {
      passive: false,
    });

    return () => {
      targetElement.removeEventListener('touchstart', touchStartEvent);
      targetElement.removeEventListener('touchmove', touchMoveEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return swiperRef;
};
