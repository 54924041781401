import { Dialog, useDialog, useSnackbarAdapter } from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { TypeActivityNames, useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { isExposeInternalUser } from '@/utils/check';

import { RUNNING_GROWTH_FLOW } from '../utils/runningGrowthFlow';
import { useCheckRunningGrowthGroupStep } from './useCheckRunningGrowthGroupStep';

interface RunningGrowthMission {
  step: number;
  title: React.ReactNode | string;
  description: string;
  onButtonClick: () => void;
  isCompleted: boolean;
}

const useRunningGrowthMission = (): RunningGrowthMission[] => {
  const { push } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { groupId = '0' } = usePathParams();
  const { checkSelectedRunningGrowthStep } = useCheckRunningGrowthGroupStep(groupId);
  const { runningGrowthData = [] } = useGetRunningGrowthMission(groupId);

  const runningMissionAllOpen = useFeatureFlag('runningMissionAllOpen');
  const { userConfig } = useUserConfig();

  const handleOpenDialog = useCallback(
    ({ isOpenMission, clickedStep }: { isOpenMission: boolean; clickedStep: number }) => {
      const currentUncompletedStep = runningGrowthData.find((data) => !data.isStepCompleted)?.step;

      if (!isOpenMission) {
        openDialog({
          element: (
            <Dialog
              title={`아직 ${clickedStep}단계 미션 기간이 아니에요`}
              description="현재 미션 기간에 해당하는 미션만 진행할 수 있어요."
              primaryActionLabel="확인"
              onPrimaryAction={closeDialog}
            />
          ),
        });
        return;
      }

      // TODO: 미션별 일정 로직 분기 추가해야함
      openDialog({
        element: (
          <Dialog
            title={`${currentUncompletedStep}단계 미션을 먼저 완료해주세요`}
            description="이전 미션을 완료하거나, 담당자의 검토가 완료된 후 다음 미션을 진행할 수 있어요."
            primaryActionLabel="확인"
            onPrimaryAction={closeDialog}
          />
        ),
      });
    },
    [closeDialog, openDialog, runningGrowthData]
  );

  const handleClickStep = useCallback(
    ({
      currentStep,
      missionPage,
      params,
      isOpenMission,
    }: {
      currentStep: number;
      missionPage: TypeActivityNames;
      params?: object;
      isOpenMission: boolean;
    }) => {
      const { previousStepComplete, isStepCompleted, isStepSuccess } =
        checkSelectedRunningGrowthStep(currentStep);

      if (!previousStepComplete || !isOpenMission) {
        handleOpenDialog({ clickedStep: currentStep, isOpenMission });
        return;
      }

      if (isStepCompleted) {
        snackbarAdapter.create({
          message: '리워드 신청이 완료되었어요.',
          type: 'default',
          timeout: 3000,
          onClick: () => {
            snackbarAdapter.dismiss();
          },
        });
        return;
      }

      if (isStepSuccess && !isStepCompleted) {
        return push('BottomSheet/RunningGrowthMissionCompleteBottomSheet', {
          groupId,
          step: currentStep,
        });
      }
      //미션 수행 페이지로 이동
      trackEvent({
        event: 'click_runninggrowth_onboarding_mission_button',
        params: {
          groupId,
          currentStep,
        },
        sample: true,
      });
      push(missionPage, {
        groupId,
        ...params,
      });
    },
    [checkSelectedRunningGrowthStep, groupId, handleOpenDialog, push, snackbarAdapter]
  );

  const quest = useMemo(() => {
    return RUNNING_GROWTH_FLOW.map((flow) => {
      const { isRewardSent } = checkSelectedRunningGrowthStep(flow.step);
      const isOpenMission =
        new Date(flow.missionOpenDate) <
        new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));

      // TODO: 전국러닝 QA이후 제거 가능 - 내부 유저의 경우 피쳐플래그에 따라 미션 오픈 여부를 결정

      const isInternalUser =
        userConfig.userId && isExposeInternalUser(userConfig.userId.toString(), false);
      const isOpenMissionInDev = isInternalUser
        ? runningMissionAllOpen || isOpenMission
        : isOpenMission;

      return {
        step: flow.step,
        title: flow.title,
        description: flow.description,
        onButtonClick: () => {
          handleClickStep({
            currentStep: flow.step,
            missionPage: flow.pageName,
            params: flow.params,
            isOpenMission: isOpenMissionInDev,
          });
        },
        isCompleted: isRewardSent, // 관리자의 리워드 지급 승인 여부
      };
    });
  }, [checkSelectedRunningGrowthStep, handleClickStep, runningMissionAllOpen, userConfig.userId]);

  return quest.sort((prev, current) => {
    if (prev.isCompleted && !current.isCompleted) {
      return -1;
    }
    if (!prev.isCompleted && current.isCompleted) {
      return 1;
    }
    return 0;
  });
};

export default useRunningGrowthMission;
