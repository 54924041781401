import { vars } from '@seed-design/design-token';

interface Props {
  strokeColor?: string;
  size?: number;
}

const ChevronDown = ({ size = 14, strokeColor: propsStrokeColor }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$scale.color.gray900;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71163 4.33665C1.89388 4.15441 2.18936 4.15441 2.3716 4.33665L6.99995 8.965L11.6283 4.33665C11.8105 4.15441 12.106 4.15441 12.2883 4.33665C12.4705 4.5189 12.4705 4.81438 12.2883 4.99662L7.32993 9.95495C7.14769 10.1372 6.85221 10.1372 6.66997 9.95495L1.71163 4.99662C1.52939 4.81438 1.52939 4.5189 1.71163 4.33665Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default ChevronDown;
