import { groupClient } from '@community-group/api';
import { CoveredLazyLoadImage, Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconLocationFill, IconProfileFill } from '@seed-design/icon';
import clsx from 'clsx';

import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';

import * as s from './ListView.css';
import RepresentativeGroupBadge from './RepresentativeGroupBadge';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  group: groupClient.model.GroupSummaryPresentation;
  displayRegionName?: string;
  config?: {
    showDescription?: boolean;
    showMemberList?: boolean;
    enableLazyLoadImage?: boolean;
  };
  titleChildren?: React.ReactNode;
  descriptionChildren?: React.ReactNode;
}

const GroupInfoListViewItem = ({
  group: { id, profileImage, name, description, memberCount, category, region },
  displayRegionName,
  config,
  titleChildren,
  descriptionChildren,
  ...props
}: Props) => {
  const { showDescription = true, enableLazyLoadImage = true } = config ?? {};
  const { isTargetGroup } = useGetParentingGroupStorage(id);

  const renderDescription = () => {
    if (descriptionChildren) {
      return descriptionChildren;
    }

    return (
      showDescription &&
      description && (
        <Typography typography="caption1Regular" color="gray800" ellipsisAfterLines={1}>
          {description}
        </Typography>
      )
    );
  };

  const regionName = displayRegionName ?? region?.town;

  return (
    <div {...props} className={clsx([props.className, s.Wrapper])}>
      <div className={s.GroupImage}>
        <CoveredLazyLoadImage
          insideStroke
          isLazy={enableLazyLoadImage}
          src={profileImage.small}
          width="4.5rem"
          height="4.5rem"
          style={{ borderRadius: '1rem' }}
        />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.TitleContainer}>
          {isTargetGroup && <RepresentativeGroupBadge />}
          {titleChildren || (
            <Typography typography="subtitle1Bold" color="gray900" ellipsisAfterLines={1}>
              {name}
            </Typography>
          )}
        </div>
        {renderDescription()}
        <div className={s.BadgeWrapper}>
          {regionName && (
            <span className={s.Badge}>
              <IconLocationFill size={12} color={vars.$scale.color.gray500} />
              <VerticalSpacing size={2} />
              <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
                {regionName}
              </Typography>
              <div className={s.Space}>⸱</div>
            </span>
          )}
          <span className={s.Badge}>
            <IconProfileFill size={12} color={vars.$scale.color.gray500} />
            <VerticalSpacing size={2} />
            <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
              {memberCount}명
            </Typography>
            <div className={s.Space}>⸱</div>
          </span>
          <span className={s.Badge}>
            <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
              {category?.name}
            </Typography>
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupInfoListViewItem;
