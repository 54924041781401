import { GroupSearchPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';

import GroupInfoListViewItem from '@/components/common/GroupInfoListItem/ListView';
import Highlight from '@/components/common/Highlight';
import { DAANGN_RACE_GROUP_IDS } from '@/components/group/Home/components/ExplorerGroupTab/RecommendCurationGroupSection';

import * as s from './SearchResultItem.css';

interface Props {
  result: GroupSearchPresentation;
  onClick: () => void;
  textBold?: boolean;
}

export const SearchResultItem = ({
  result: {
    id,
    name,
    description,
    profileImage,
    memberCount,
    isJustActivated,
    category,
    region,
    displayRegionName,
    highlight,
    updatedAt,
    createdAt,
  },
  onClick,
  textBold,
}: Props) => {
  const groupRegion = DAANGN_RACE_GROUP_IDS.includes(id) ? undefined : region;

  return (
    <GroupInfoListViewItem
      onClick={onClick}
      config={{
        showMemberList: false,
        enableLazyLoadImage: false,
      }}
      displayRegionName={displayRegionName}
      group={{
        id,
        name,
        description,
        profileImage,
        memberCount,
        isJustActivated,
        category,
        region: groupRegion,
        postCount: 0,
        memberProfileImages: [],
        updatedAt,
        createdAt,
        domainType: 'group',
        isBlocked: false,
      }}
      titleChildren={
        <Typography
          typography={textBold ? 'bodyM1Bold' : 'bodyM1Regular'}
          color="gray900"
          ellipsisAfterLines={1}
        >
          <Highlight text={highlight?.name ?? name} />
        </Typography>
      }
      descriptionChildren={
        description && (
          <p className={s.Description}>
            <Highlight text={highlight?.description ?? description} />
          </p>
        )
      }
    />
  );
};
