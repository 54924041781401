import { Checkbox as SproutCheckbox, SeedCheckboxProps } from '@daangn/sprout-components-checkbox';

export const Checkbox = ({
  shape = 'square',
  size = 'medium',
  children,
  isSelected,
  isDisabled,
  onChange,
  ...props
}: SeedCheckboxProps) => {
  return (
    <SproutCheckbox
      shape={shape}
      size={size}
      isSelected={isSelected}
      isDisabled={isDisabled}
      onChange={onChange}
      {...props}
    >
      {children}
    </SproutCheckbox>
  );
};
