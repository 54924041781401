import AppBarCloseButton from '@/components/common/AppBarCloseButton/AppBarCloseButton';
import { trackEvent } from '@/utils/analytics';
const AppBarCloseCreateGroupButton = () => {
  const handleConfirm = () => {
    trackEvent({ event: 'click_close_create_group' });
  };

  return (
    <AppBarCloseButton
      popActivityName="HomePage"
      needConfirm
      onConfirm={handleConfirm}
      dialog={{
        title: '모임 만들기를 그만할까요?',
        description: '작성한 내용은 저장되고 언제든 다시 등록할 수 있어요.',
        primaryActionLabel: '그만두기',
        secondaryActionLabel: '취소',
      }}
    />
  );
};

export default AppBarCloseCreateGroupButton;
