import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

const getMeetupReviewsForNotMemberPath = (id: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedMeetupReviewsForNotMemberGetPath(id);

export const useGetMeetupReviewsForNotMember = (groupId?: string, suspense = true) => {
  const fetchInstance = useFetchInstance();
  const getMeetupReviewsForNotMember =
    groupClient.api.GroupFeedApi.apiV1GroupsIdFeedMeetupReviewsForNotMemberGet({
      axios: fetchInstance,
    });

  const { data, refetch, isInitialLoading } = useQuery(
    [getMeetupReviewsForNotMemberPath(Number(groupId))],
    () => getMeetupReviewsForNotMember(Number(groupId)),
    {
      suspense,
    }
  );

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading };
};
