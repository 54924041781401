import { ParentingGroupKidForm } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Divider,
  Spacing,
  Typography,
  useKeyboardSize,
} from '@community-group/components';
import { IconCloseRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { usePostParentingGroupKids } from '@/api/hooks/usePostParentingGroupKids';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { formatDateStringToKebabCase } from '@/utils/date';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';

import ParentingGroupKidProfileDescription from '../components/ParentingGroupKidProfileDescription';
import ParentingGroupKidProfileFormList from '../components/ParentingGroupKidProfileFormList';
import { DEFAULT_KID_PROFILE } from '../constants';
import * as s from './ParentingGroupFreshMemberKidProfileCreatePage.css';

type FormValues = {
  kids: ParentingGroupKidForm[];
};

export type Params = Pick<PageParams, 'groupId' | 'createKidProfileFlowType'>;

// NOTE: 신규 멤버를 위한 아이 정보 입력 페이지
const ParentingGroupFreshMemberKidProfileCreatePage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { createKidProfileFlowType = 'onlyKidProfile' } = useQueryParams();
  const createKidProfileFlowWithSubNickname = createKidProfileFlowType === 'withSubNickname';

  useEnterTrackEvent({
    event: 'enter_kid_profile_form_page',
    params: {
      groupId,
      type: 'fresh_member',
      createKidProfileFlowType: createKidProfileFlowWithSubNickname
        ? 'with_sub_nickname'
        : 'only_kid_profile',
    },
  });

  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const handleMoveDescription = useCallback((e) => {
    e.preventDefault();
    scrollTargetRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }, []);

  const appBar = createKidProfileFlowWithSubNickname
    ? undefined
    : {
        backButton: { renderIcon: () => <IconCloseRegular size={24} /> },
        closeButton: { renderIcon: () => <IconCloseRegular size={24} /> },
      };
  const modalPresentationStyle = createKidProfileFlowWithSubNickname ? undefined : 'fullScreen';

  const methods = useForm<FormValues>({
    defaultValues: {
      kids: [DEFAULT_KID_PROFILE],
    },
  });
  const { handleSubmit, watch } = methods;

  const { pop } = useFlow();
  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);
  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const { mutateAsync: postKids } = usePostParentingGroupKids({
    onSuccess: () => {
      pop();
      bridge.openToast({
        toast: {
          body: '아이 정보를 입력했어요. 아이 정보는 모임 프로필에서 확인하고 모임 설정에서 수정할 수 있어요.',
        },
      });
    },
  });

  const handleComplete = handleSubmit(async (form) => {
    trackEvent({ event: 'click_form_complete_kid_profile', params: { groupId } });

    const formattedForm = {
      kids: form.kids.map((kid) => ({
        ...kid,
        birthday: formatDateStringToKebabCase(kid.birthday.trim()),
      })),
    };

    await postKids({
      groupId: Number(groupId),
      form: formattedForm,
    });
  });

  const formValue = watch();
  const isButtonDisabled = useMemo(() => {
    return !formValue.kids.every((kid) => {
      const kidResult = groupMemberProfileFieldSchema.kidProfile.safeParse(kid);
      return kidResult.success;
    });
  }, [formValue]);

  return (
    <AppScreen
      appBar={appBar}
      CUPERTINO_ONLY_modalPresentationStyle={modalPresentationStyle}
      accessoryBar={
        !isKeyboardOn && (
          <div className={s.SubmitButtonWrapper}>
            <BoxButton
              size="xlarge"
              width="100%"
              type="submit"
              variant="primary"
              onClick={handleComplete}
              isDisabled={isButtonDisabled}
            >
              완료
            </BoxButton>
          </div>
        )
      }
    >
      <FormProvider {...methods}>
        <div className={s.Container}>
          <section className={s.Section}>
            <Typography typography="title1Bold" color="gray900">
              아이 정보를 입력해주세요
            </Typography>
            <Spacing size={8} />
            <Typography typography="subtitle1Regular" color="gray900">
              아이 정보는 모임장만 볼 수 있어요.{' '}
              <span className={s.AnchorText} onClick={handleMoveDescription}>
                왜 아이 정보가 필요한가요?
              </span>
            </Typography>
          </section>
          <section className={s.Section}>
            <Spacing size={32} />
            <ParentingGroupKidProfileFormList />
            <Spacing size={32} />
            <Divider />
            <Spacing ref={scrollTargetRef} size={32} />
            <ParentingGroupKidProfileDescription />
          </section>
        </div>
      </FormProvider>
    </AppScreen>
  );
};

export default ParentingGroupFreshMemberKidProfileCreatePage;
