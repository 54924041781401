import {
  ChallengeDetailPresentation,
  ChallengeParticipantsPreviewPresentation,
} from '@community-group/api/lib/group/models';
import { Avatar, AvatarGroup, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { shortening } from '@/utils/text';

import * as s from './index.css';

type Props = Pick<ChallengeDetailPresentation, 'isJoined'> & {
  participantsPreview: ChallengeParticipantsPreviewPresentation;
  currentUserNickname: string;
  onClick?: () => void;
};

const ChallengeJoinedCountMessage = ({
  participantsPreview,

  isJoined,
  currentUserNickname,
  onClick,
}: Props) => {
  const { participantsCount, participantsProfileImages, representativeParticipantsName } =
    participantsPreview;

  const joinedMemberMessage = useMemo(() => {
    if (participantsCount === 1) {
      return '1명이 참여중이에요.';
    }

    const userNickname = isJoined ? currentUserNickname : representativeParticipantsName;
    const displayUserName = userNickname ? shortening(userNickname, 8) : '';
    const count = participantsCount - 1;

    return `${displayUserName} 외 ${count}명이 참여중이에요.`;
  }, [currentUserNickname, isJoined, participantsCount, representativeParticipantsName]);

  return (
    <div className={s.ChallengeJoinedCountMessage} onClick={onClick} aria-hidden="true">
      <AvatarGroup size="xxsmall">
        {participantsProfileImages.slice(0, 3).map((profileImage, index) => (
          <Avatar
            key={index}
            src={profileImage}
            alt={'참가자 프로필 이미지'}
            UNSAFE_style={{
              border: `1px solid ${vars.$semantic.color.paperDefault}`,
              boxSizing: 'border-box',
            }}
            fallback={
              <img
                src="https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png"
                alt="profile_default_image"
              />
            }
          />
        ))}
      </AvatarGroup>
      <VerticalSpacing size={4} />
      <Typography typography="caption1Regular" color="gray700">
        {joinedMemberMessage}
      </Typography>
    </div>
  );
};

export default ChallengeJoinedCountMessage;
