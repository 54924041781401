import {
  AsyncBoundary,
  BottomBasicButton,
  MultilineTextField,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { getGroupPath } from '@/api/base/group';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { queryClient } from '@/api/instance';
import { Container } from '@/components/common/Container';
import { FormItem } from '@/components/common/Form';
import { useBridge } from '@/contexts/Bridge';
import { useCheckOpenKakao } from '@/hooks/useCheckOpenKakao';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import {
  validateSchemaWithBoolean,
  validateSchemaWithHandleToast,
  validateSchemaWithMessage,
} from '@/utils/validate/util';

import GroupDescriptionCallout from '../../new/Common/GroupDescriptionCallout';
import useCheckUnCompletedQuest from '../hooks/useCheckUnCompletedQuest';
export type GroupOnBoardDescriptionPageParams = Pick<PageParams, 'groupId'>;

const GroupOnBoardDescriptionPage: ActivityComponentType = () => {
  const handleErrorWithToast = useHandleErrorWithToast();

  const { groupId } = usePathParams();

  const { group } = useGetGroupDetail(groupId);

  const { watch, setValue } = useForm();
  const descriptionFieldValue = watch('description', group?.description);

  const { mutate: updateGroup, isLoading: isUpdateGroupLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: ({ data }) => {
      queryClient.setQueryData([getGroupPath(String(data.group?.id))], { data: data });
    },
  });

  const { pop, replace } = useFlow();

  const uncompletedQuestList = useCheckUnCompletedQuest();

  const handleSubmitForm = async () => {
    if (!validateSchemaWithHandleToast(groupFieldSchema.description, descriptionFieldValue)) {
      return;
    }

    await updateGroup(
      {
        id: String(groupId),
        groupModifyForm: { description: descriptionFieldValue },
      },
      {
        onSuccess: () => {
          trackEvent({ event: 'click_write_description_on_onboarding' });

          refetchGroupDetail({ groupId });
          if (uncompletedQuestList?.[0]) {
            replace(
              uncompletedQuestList[0].activityName,
              {
                groupId,
              },
              {
                animate: true,
              }
            );
            return;
          }
          pop();
        },
      }
    );
  };

  const { showOpenKakaoBanner } = useCheckOpenKakao(undefined, descriptionFieldValue, false);
  const { bridge } = useBridge();

  useEffect(() => {
    if (showOpenKakaoBanner) {
      bridge.setHapticError({});
    }
  }, [showOpenKakaoBanner]);

  const isDisable = useMemo(() => {
    const validResult = validateSchemaWithBoolean(
      groupFieldSchema.description,
      descriptionFieldValue
    );
    return !validResult;
  }, [descriptionFieldValue, groupFieldSchema.description]);

  const descriptionFieldErrorMessage = useMemo(() => {
    if (!descriptionFieldValue) return '';

    const result = validateSchemaWithMessage(groupFieldSchema.description, descriptionFieldValue);
    if (result.success) return '';
    return result.message ?? '';
  }, [descriptionFieldValue]);

  return (
    <AppScreen
      preventSwipeBack={true}
      accessoryBar={
        <BottomBasicButton
          disabled={isDisable}
          loading={isUpdateGroupLoading}
          onClick={handleSubmitForm}
        >
          완료
        </BottomBasicButton>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Container>
              <Typography
                as="p"
                typography="title1Bold"
                style={{ marginBottom: '1.5rem', marginTop: '1rem' }}
              >
                모임을 소개해주세요
              </Typography>
              <FormItem>
                <div id="description-textarea">
                  <MultilineTextField
                    value={descriptionFieldValue ?? ''}
                    onChange={(value) => {
                      setValue('description', value);
                    }}
                    placeholder="활동 중심으로 모임을 소개해주세요. 모임 설정에서 언제든지 바꿀 수 있어요. (선택)"
                    maxLength={groupFieldSchema.description.maxLength ?? 0}
                    minLength={groupFieldSchema.description?.minLength ?? 0}
                    isInvalid={!!descriptionFieldErrorMessage}
                    errorMessage={descriptionFieldErrorMessage}
                    minHeight="12.5rem"
                  />
                </div>
                <Spacing size={24} />
                <GroupDescriptionCallout />
              </FormItem>
              <Spacing size={64} />
            </Container>
          </div>
        </div>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupOnBoardDescriptionPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
