import { GroupMeetupPoiPresentation } from '@community-group/api/lib/group/models';
import { ContainerWithGap, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconLocationFill } from '@seed-design/icon';
import { memo, useMemo } from 'react';

import { GroupCustomBadge } from '@/components/common/GroupCustomBadge';
import { DAANGN_RACE_GROUP_IDS } from '@/components/group/Home/components/ExplorerGroupTab/RecommendCurationGroupSection';

import * as s from './index.css';
import MeetupPlace from './MeetupPlace';

type Props = {
  description: string;
  categoryName: string;
  joinableAgeRangeText: string;
  meetupPlaces: GroupMeetupPoiPresentation[];
  createdRegionTown: string;
  groupId: number;
};

const GroupDetailSummary = ({
  description,
  categoryName,
  joinableAgeRangeText,
  meetupPlaces,
  createdRegionTown,
  groupId,
}: Props) => {
  const showMeetupPlace = useMemo(
    () => meetupPlaces.length > 0 && meetupPlaces[0].coordinates.latitude !== 0,
    [meetupPlaces]
  );

  const groupRegion = DAANGN_RACE_GROUP_IDS.includes(groupId) ? undefined : createdRegionTown;

  return (
    <section className={s.Container}>
      <ContainerWithGap name="GroupDetailSummary" gap={16}>
        <div className={s.BadgeContainer}>
          {groupRegion && (
            <GroupCustomBadge
              prefixIcon={<IconLocationFill size={15} color={vars.$scale.color.gray600} />}
              label={createdRegionTown}
            />
          )}
          <GroupCustomBadge label={categoryName} />
          {joinableAgeRangeText && <GroupCustomBadge label={joinableAgeRangeText} />}
        </div>
        {description && (
          <Typography typography="bodyL2Regular" color="gray900" ellipsisAfterLines={30}>
            {description}
          </Typography>
        )}
      </ContainerWithGap>
      {showMeetupPlace && (
        <>
          <Spacing size={30} />
          <MeetupPlace place={meetupPlaces[0]} />
        </>
      )}
    </section>
  );
};

export default memo(GroupDetailSummary);
