import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { User, USER_ME_URL } from '@/api/base/user';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetMe = () => {
  const fetchInstance = useFetchInstance();

  const getUserMe = async () => {
    return await fetchInstance.get<undefined, AxiosResponse<User>>(USER_ME_URL);
  };
  const { data, refetch } = useQuery([USER_ME_URL], () => getUserMe(), {
    suspense: true,
  });

  return { data: data?.data as User, refetch };
};
