import { ImageFieldItem } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCameraFill } from '@seed-design/icon';
import { useMemo } from 'react';

import { GroupChallengeFormProps } from '../../../types';
import * as s from './style.css';
import { useHandleImageClick } from './useHandleImageClick';

type Props = GroupChallengeFormProps;

export const GroupChallengeFormImagesField = ({ formHandler }: Props) => {
  const handleImageClick = useHandleImageClick({ formHandler });

  const images = useMemo(() => {
    return formHandler.watch('originImages') ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHandler.watch('originImages')]);

  const handleImageRemove = (id: string) => {
    formHandler.setValue(
      'originImages',
      formHandler.getValues('originImages').filter((image) => image.id !== id)
    );
  };

  return (
    <div className={s.Wrapper}>
      <button className={s.ImageAddButton} onClick={handleImageClick}>
        <IconCameraFill size={24} color={vars.$scale.color.gray600} />
        <span style={{ color: vars.$scale.color.gray600, fontSize: '0.8125rem' }}>
          <strong
            style={{
              fontWeight: 400,
              color: images.length > 0 ? vars.$scale.color.carrot500 : vars.$scale.color.gray600,
            }}
          >
            {images.length}
          </strong>
          /10
        </span>
      </button>
      {images.map((image) => (
        <ImageFieldItem
          key={image.url}
          image={image.url}
          onRemoveClick={handleImageRemove}
          imageId={image.id}
        />
      ))}
    </div>
  );
};
