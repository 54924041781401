import { BoxButton, Spacing, Typography } from '@community-group/components';
import { useState } from 'react';

import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import * as s from './JoinableRegionRangeExpandBanner.css';

type Props = {
  groupId: string;
};

export const JoinableRegionRangeExpandBanner = ({ groupId }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutGroup({
    onError: (error) => {
      handleErrorWithToast(error);
      setLoading(false);
    },
    onSuccess: () => {
      refetchGroupMemberList({ groupId });
      refetchGroupDetail({ groupId });

      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });
  const { bridge } = useBridge();
  const handleRegionRangeChange = () => {
    setLoading(true);
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          joinableRegionRange: 'unlimited',
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_lift_group_range_restrictions',
            params: {
              groupId,
            },
          });

          bridge.openToast({
            toast: {
              body: '모집 동네 범위 제한을 풀었어요.',
            },
          });
        },
      }
    );
  };

  return (
    <div className={s.wrapper}>
      <Typography typography="subtitle2Bold">모집 동네 범위 안내</Typography>
      <Spacing size={4} />
      <Typography typography="bodyM2Regular" wordBreak="keep-all">
        먼 동네의 이웃이 가입을 신청했어요. 가입을 승인하려면 모임의 모집 범위 제한을 풀어주세요.
      </Typography>
      <Spacing size={16} />
      <BoxButton
        size="small"
        onClick={handleRegionRangeChange}
        isLoading={loading}
        UNSAFE_style={{ minWidth: '10.1817rem' }}
      >
        모집 동네 범위 제한 풀기
      </BoxButton>
    </div>
  );
};
