import { isClient, isServer } from '@community-group/components';

import { StoreSlice } from '..';

const SEARCH_HISTORY_LIST_KEY = 'SEARCH_HISTORY_LIST';

export type SearchHistoryListSlice = {
  searchHistoryList: string[];
  addSearchHistoyItem: (value: string) => void;
  removeSearchHistoyItemIndex: (index: number) => void;
  removeSearchHistoyItemAll: () => void;
};

export const createSearchHistoryListSlice: StoreSlice<SearchHistoryListSlice> = (set) => ({
  searchHistoryList: isClient()
    ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LIST_KEY) ?? '[]')
    : [],
  addSearchHistoyItem: (value: string) => {
    set((state) => {
      const filteredSearchHistoryList = state.searchHistoryList.filter((item) => item !== value);
      const searchHistoryList = [value, ...filteredSearchHistoryList];

      if (isServer()) {
        return { ...state, searchHistoryList };
      }
      localStorage.setItem(SEARCH_HISTORY_LIST_KEY, JSON.stringify(searchHistoryList));

      return { ...state, searchHistoryList };
    });
  },
  removeSearchHistoyItemIndex: (index: number) => {
    set((state) => {
      const searchHistoryList = state.searchHistoryList.filter(
        (_, currentIndex) => currentIndex !== index
      );

      localStorage.setItem(SEARCH_HISTORY_LIST_KEY, JSON.stringify(searchHistoryList));

      return { ...state, searchHistoryList };
    });
  },
  removeSearchHistoyItemAll: () => {
    set((state) => {
      const searchHistoryList: string[] = [];

      localStorage.setItem(SEARCH_HISTORY_LIST_KEY, JSON.stringify(searchHistoryList));

      return { ...state, searchHistoryList };
    });
  },
});
