import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetCheckHasGroupProfile = () => {
  const fetchInstance = useFetchInstance();
  const getUserProfile = groupClient.api.GroupProfileApi.apiV1GroupsProfileCheckGet({
    axios: fetchInstance,
  });

  const { data, refetch, isInitialLoading } = useQuery(
    ['GroupProfleCheck', groupClient.api.GroupProfileApi.getapiV1GroupsProfileCheckGetPath()],
    () => getUserProfile(),
    {
      suspense: true,
    }
  );

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading };
};
