type Params = {
  nickname: string;
  subNickname?: string;
};

export const convertNicknameDisplay = ({ nickname, subNickname }: Params) => {
  if (!subNickname) {
    return nickname;
  }
  return `${nickname} (${subNickname})`;
};
