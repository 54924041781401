import { Spacing, useSnackbarAdapter } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCopyFill } from '@seed-design/icon';
import { useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { IconShareRegular } from '@/components/common/Icons/shareRegular';
import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import ActionButtonItem from '@/components/group/SharePost/components/ActionButtonItem';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { useTheme } from '@/stackflow/hooks/useTheme';
import { trackEvent } from '@/utils/analytics';
import { copyToClipboard, getPermalink } from '@/utils/link';

import * as s from './InviteMemberShareActionButtonGroup.css';

type Props = {
  groupId: string;
  groupName: string;
  publicId: string;
  from?: string;
};

const InviteMemberShareActionButtonGroup = ({ groupId, groupName, publicId, from }: Props) => {
  const { pop } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();
  const { bridge } = useBridge();

  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;
  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });
  const { group } = useGetGroupDetail(groupId);

  const {
    userConfig: { userId },
  } = useUserConfig();

  const handleCopyLinkClipboard = () => {
    pop();

    copyToClipboard(getPermalink(publicId));

    trackEvent({
      event: 'click_invite_member_external',
      params: {
        groupId,
        publicId,
        type: 'copy_clipboard',
        from,
        currentLevel: level?.currentLevel,
        currentProgressPercentage: level?.forLevelUp.percentage,
        userId: userId ?? 0,
        categoryId: group?.category.id,
        categoryName: group?.category.name,
      },
    });

    snackbarAdapter.create({
      message: '링크가 복사되었어요.',
      type: 'default',
      timeout: 1500,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
  };

  const handleShareExternal = () => {
    pop();

    trackEvent({
      event: 'click_invite_member_external',
      params: {
        groupId,
        type: 'share_external',
        from,
        currentLevel: level?.currentLevel,
        currentProgressPercentage: level?.forLevelUp.percentage,
        userId: userId ?? 0,
        categoryId: group?.category.id,
        categoryName: group?.category.name,
      },
    });

    bridge.share({
      share: {
        url: getPermalink(publicId),
        text: `${groupName} 모임 공유하기`,
      },
    });
  };

  return (
    <div className={s.ActionButtonListWrapper}>
      <ActionButtonItem
        buttonText="링크 복사"
        icon={<IconCopyFill size={20} color={vars.$scale.color.gray700} />}
        onClick={handleCopyLinkClipboard}
      />
      {deviceType === 'cupertino' ? <Spacing size={11} /> : <Spacing size={12} />}
      <ActionButtonItem
        buttonText="외부 공유"
        icon={<IconShareRegular size={20} color={vars.$scale.color.gray700} />}
        onClick={handleShareExternal}
      />
    </div>
  );
};

export default InviteMemberShareActionButtonGroup;
