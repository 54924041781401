import { BoxButton, MultilineTextField } from '@community-group/components';
import { IconPhotoRegular, IconVideoRegular } from '@seed-design/icon';
import React, { useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useFlow } from '@/stackflow';

import { usePostFormPhotoHandler } from '../../PostForm/hooks/usePostFormPhotoHandler';
import { FileUploadState } from '../api/upload';
import UploadedImageList from '../components/UploadedImageList';
import UploadVideoList from '../components/UploadVideoList';
import { useUploadVideos } from '../hooks/useUploadVideos';
import * as s from './UploadSeparateTab.css';

export type FormType = {
  images: any[];
  originImages: any[];
  uploads: FileUploadState[];
};

export const DEFAULT_CONTENTS = `
글 작성 예시 텍스트에요~~~\n
지난 주에 진행한 일정 사진이에요!\n
메뉴 : 복숭아 타르트, 바스크 치즈케이크, 레몬타르트\n
베이커즈에서는 누구라도 함께 하고 나중에 또 혼자 할 수 있는 베이킹 일정을 진행해요.\n
관심 있으시다면 언제라도 궁금하신 것 질문 주세요 😇😇\n`;

//분리해서 업로드 하는 페이지
function UploadSeparateTab() {
  const { push } = useFlow();
  const [postText, setPostText] = React.useState(DEFAULT_CONTENTS);
  const formMethods = useForm({
    defaultValues: {
      videos: [],
      images: [],
      originImages: [],
      uploads: [],
    } as FormType,
  });
  const { watch, getValues, setValue } = formMethods;

  const uploads = watch('uploads');

  const { addFiles } = useUploadVideos({
    uploads,
    setUploads: (updater) => {
      const currentUploads = getValues('uploads');
      setValue('uploads', updater(currentUploads));
    },
    onUploadStart: (file) => console.log('Upload started for:', file.name),
    onUploadSuccess: (file, response) => console.log('Upload success for:', file.name, response),
    onUploadError: (file, error) => console.log('Upload failed for:', file.name, error),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFiles(Array.from(event.target.files));
    }
    // input 값을 초기화하여 같은 파일도 다시 업로드 가능하게 함
    event.target.value = '';
  };

  // 영상 업로드
  const videoUploadRef = useRef<HTMLInputElement>(null);

  const isUploadingVideo = useMemo(() => {
    return uploads.some((upload) => upload.status === 'uploading');
  }, [uploads]);

  // 이미지 업로드
  const { openPhotoSelector: handlePhotoClick } = usePostFormPhotoHandler({
    formHandler: formMethods,
  });

  const isDisabledSubmit = postText.length === 0 || isUploadingVideo;

  const handleSubmit = () => {
    if (postText === '') {
      alert('내용을 입력해주세요');
      return;
    }
    if (isUploadingVideo) {
      alert('영상 업로드가 완료될 때까지 잠시만 기다려주세요');
      return;
    }
    const videoIds = uploads.map((el) => el.videoInfo?.id);
    const imageIds = watch('images').map((image) => encodeURIComponent(image.id));
    const content = encodeURIComponent(postText);

    push('ViewVideoPage', {
      content,
      videoIds: videoIds.join(','),
      imageIds: imageIds.join(','),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <div className={s.Wrapper}>
        <div className={s.InputWrapper}>
          <MultilineTextField
            width="100%"
            name="review"
            placeholder="내용을 입력해주세요"
            value={postText}
            onChange={(value) => setPostText(value)}
            UNSAFE_style={{
              border: 'none',
            }}
          />
          <UploadedImageList formMethods={formMethods} />
          <UploadVideoList formMethods={formMethods} />
        </div>

        <div className={s.AppBarWrapper}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <IconPhotoRegular size={24} onClick={handlePhotoClick} />
            <>
              <IconVideoRegular
                size={24}
                onClick={() => {
                  videoUploadRef.current?.click();
                }}
              />
              <input
                ref={videoUploadRef}
                type="file"
                accept={'video/*'}
                onChange={handleFileChange}
                hidden
                multiple
              />
            </>
          </div>
          <div>
            <BoxButton
              UNSAFE_style={{ padding: '0 20px' }}
              size="small"
              variant="primary"
              onClick={handleSubmit}
              isDisabled={isDisabledSubmit}
            >
              제출
            </BoxButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default UploadSeparateTab;
