import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { AsyncBoundary, isHigherManager, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import {
  IconCalendarRegular,
  IconChattingRegular,
  IconFileRegular,
  IconLoudspeakerRegular,
  IconUserGroupRegular,
} from '@seed-design/icon';
import { useMemo } from 'react';

import { useGetMyRoomList } from '@/api/hooks/apartment/useGetMyRoomList';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupDetailShortcutMenu.css';

type Props = { group: GroupDetailPresentation; currentUserRole: GroupMemberRoleEnum };
export type ShortcutMenuProps = 'notice' | 'meetup' | 'chat' | 'members';

const GroupDetailShortcutMenu = ({ group, currentUserRole }: Props) => {
  const activity = useFlow();
  const groupId = group.id.toString();
  const useGroupChallenge = useFeatureFlag('useGroupChallenge');

  const eventParams = {
    event: 'click_group_detail_shortcut_menu',
    params: {
      groupId,
      groupName: group.name,
      groupCategoryName: group.category.name,
      isGroupCategoryOn: group.isBoardManaged,
      role: currentUserRole,
      version: '2',
    },
  } as const;

  const MenuCondition = {
    notice: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'notice',
          },
          sample: true,
        });
        activity.push('GroupDetailNoticeMorePage', {
          groupId,
        });
      },
    },
    meetup: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'meetup',
          },
          sample: true,
        });
        activity.push('GroupDetailMeetupPage', {
          groupId,
        });
      },
    },
    chat: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'chat',
          },
          sample: true,
        });
        activity.push('GroupDetailChatRoomPage', {
          groupId,
        });
      },
    },
    members: {
      onClick: () => {
        trackEvent({
          event: eventParams.event,
          params: {
            ...eventParams.params,
            groupDetailShortcutMenu: 'members',
          },
          sample: true,
        });
        if (isHigherManager(currentUserRole)) {
          activity.push('GroupMemberListForHostPage', {
            groupId,
          });
          return;
        }
        activity.push('GroupMemberListPage', {
          groupId,
        });
      },
    },
  };

  const handleClick = (menu: ShortcutMenuProps) => {
    MenuCondition[menu].onClick();
  };

  return (
    <ul className={s.Wrapper}>
      <li
        className={s.Menu}
        onClick={() => {
          handleClick('notice');
        }}
      >
        <IconLoudspeakerRegular size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          공지
        </Typography>
      </li>
      <li
        className={s.Menu}
        onClick={() => {
          handleClick('meetup');
        }}
      >
        <IconCalendarRegular size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          일정
        </Typography>
      </li>
      <AsyncBoundary>
        <li
          className={s.Menu}
          onClick={() => {
            handleClick('chat');
          }}
        >
          <ChattingButton groupId={groupId} />
          <Typography typography="caption1Regular" color="gray800">
            채팅
          </Typography>
        </li>
      </AsyncBoundary>
      <li
        className={s.Menu}
        onClick={() => {
          handleClick('members');
        }}
      >
        <IconUserGroupRegular size={20} color={vars.$scale.color.gray800} />
        <Typography typography="caption1Regular" color="gray800">
          멤버
        </Typography>
      </li>
      {useGroupChallenge && (
        <li
          className={s.Menu}
          onClick={() => {
            activity.push('GroupChallengeNewTemplatePage', {
              groupId,
            });
          }}
        >
          <IconFileRegular size={20} color={vars.$scale.color.gray800} />
          <Typography typography="caption1Regular" color="gray800">
            챌린지
          </Typography>
        </li>
      )}
    </ul>
  );
};

export default GroupDetailShortcutMenu;

type ChattingButtonProps = {
  groupId: string;
};

const ChattingButton = ({ groupId }: ChattingButtonProps) => {
  const { data: roomList } = useGetMyRoomList(Number(groupId));
  const hasNewTalk = useMemo(
    () =>
      roomList?.pages?.some(({ data }) =>
        data.talkRooms.some((room) => {
          if (!room.lastTalkInfo) return false;
          return room.lastTalkInfo?.isNew;
        })
      ),
    [roomList?.pages]
  );

  return (
    <div className={s.ChattingButtonWrapper}>
      <div className={hasNewTalk ? s.RedDot : undefined}>
        <IconChattingRegular size={20} color={vars.$scale.color.gray800} />
      </div>
    </div>
  );
};
