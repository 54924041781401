import { GroupFeedType } from '@community-group/api/lib/group/models';
import { AsyncBoundary } from '@community-group/components';
import { Spacing } from '@community-group/components';
import React, { useMemo } from 'react';

import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import ChipRadio from '@/components/common/base/Input/Radio/Chip';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import FeedList from './FeedList';

type Props = {
  enableCategoryFilterAllTime?: boolean; // Feed 게시판 목록 칩 필터를 항상 띄울지 여부
  feedType?: GroupFeedType;
};

type BoardCategory = {
  value: string;
  key: number;
};

const Feed = ({ enableCategoryFilterAllTime, feedType }: Props) => {
  return (
    <AsyncBoundary rejectedFallback={<></>} pendingFallback={<></>}>
      <FeedInner enableCategoryFilterAllTime={enableCategoryFilterAllTime} feedType={feedType} />
    </AsyncBoundary>
  );
};

const FeedInner = ({ enableCategoryFilterAllTime, feedType }: Props) => {
  const { groupId = '0' } = usePathParams();
  const { boardCategoryKey = '0' } = useQueryParams();

  const [selectedBoardKey, setSelectedBoardKey] = React.useState<BoardCategory['key']>(
    parseInt(boardCategoryKey)
  );

  const { group } = useGetGroupDetail(groupId);

  const { data: boardCategories } = useGetBoardTypeList(Number(groupId));
  const options = useMemo(
    () =>
      boardCategories?.boardCategories?.map((item) => ({
        key: item.id,
        value: item.category,
        id: item.id as unknown as string,
      })) ?? [],
    [boardCategories?.boardCategories]
  );

  const handleBoardChange = (category: BoardCategory) => {
    const isSelectedBoardCategoryType = () => {
      if (category.value === '전체') return 'all';
      if (category.value === '자유 게시판') return 'default';
      return 'other';
    };
    trackEvent({
      event: 'click_group_category',
      params: {
        isGroupCategoryOn: group?.isBoardManaged,
        isSelectedBoardCategoryType: isSelectedBoardCategoryType(),
      },
      sample: true,
    });

    setSelectedBoardKey(category.key);
  };

  if (!group) return null;

  const showCategoryChipsCondition = enableCategoryFilterAllTime || group.isBoardManaged;

  return (
    <div>
      {showCategoryChipsCondition && (
        <ChipRadio
          id="group-detail-board-category"
          label=""
          onChange={handleBoardChange}
          selectedOptionKey={selectedBoardKey}
          options={[{ key: 0, value: '전체' }, ...options]}
        />
      )}
      <Spacing size={8} />
      <FeedList selectedCategoryKey={selectedBoardKey} groupDetail={group} feedType={feedType} />
    </div>
  );
};

export default React.memo(Feed);
