import { groupClient } from '@community-group/api';

import { getGroupPath } from '@/api/base/group';
import { getGroupDetailPath } from '@/api/hooks/useGetGroupDetail';
import { queryClient } from '@/api/instance';

type Params = {
  groupId: string;
};

/*
 *  모임 설정 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupSetting = ({ groupId }: Params) => {
  queryClient.refetchQueries([getGroupPath(groupId)]);
  queryClient.refetchQueries([getGroupDetailPath(groupId)]);
  queryClient.refetchQueries([
    groupClient.api.PermissionsApi.getapiV1GroupsIdPermissionsGetPath(Number(groupId)),
  ]);
};
