import { CapsuleToggleButton } from '@community-group/components';

type Props = {
  isSelected?: boolean;
  onChange: (isSelected: boolean) => void;
  children: (isSelected: boolean) => React.ReactNode;
};

export const ToggleButton = ({ isSelected = false, onChange, children }: Props) => {
  return (
    <CapsuleToggleButton isSelected={isSelected} onChange={onChange}>
      {children(isSelected)}
    </CapsuleToggleButton>
  );
};
