import { useCallback } from 'react';

import { useDeleteLikePost } from '@/api/hooks/useDeleteLikePost';
import { usePatchLikePost } from '@/api/hooks/usePatchLikePost';
import { useBridge } from '@/contexts/Bridge';

import { useHandleErrorWithToast } from './useHandleErrorWithToast';

interface Props {
  onLikeClick: (liked: boolean) => void;
  onDislikeClick: (liked: boolean) => void;
}

const useHandlePostEmotion = ({ onLikeClick, onDislikeClick }: Props) => {
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateLikePost } = usePatchLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      onLikeClick(true);
    },
  });
  const { bridge } = useBridge();
  const { mutate: mutateDislikePost } = useDeleteLikePost({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      onDislikeClick(false);
    },
  });

  const handleEmotionClick = useCallback(
    ({
      liked,
      groupId,
      postId,
      categories,
    }: {
      liked: boolean;
      groupId: string;
      postId: string;
      categories: number[];
    }) => {
      bridge.setHapticLightFeedback({});

      if (liked) {
        mutateDislikePost({
          groupId: groupId,
          postId: postId,
          boardCategories: categories,
        });
        return;
      }

      mutateLikePost({
        groupId: groupId,
        postId: postId,
        boardCategories: categories,
      });
    },
    [mutateDislikePost, mutateLikePost]
  );

  return {
    mutateLikePost,
    mutateDislikePost,
    handleEmotionClick,
  };
};

export default useHandlePostEmotion;
