import { isNotMember } from '@community-group/components';
import { isAfter } from 'date-fns';
import { useCallback } from 'react';

import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import useParentingGroupKidProfile from '@/hooks/useParentingGroupKidProfile';
import { useReleaseDate } from '@/hooks/useReleaseDate';
import { useFlow } from '@/stackflow';

type Props = {
  groupId: string;
};

const useCheckNeedToSetParentingGroupKidProfile = ({ groupId }: Props) => {
  const { push } = useFlow();
  const currentUser = useCurrentGroupMe();

  const releasedAt = useReleaseDate('parentingGroupKidProfile');
  const shouldUseParentingKidProfile = useParentingGroupKidProfile(groupId);

  const checkNeedToSetParentingGroupKidProfile = useCallback(() => {
    if (isNotMember(currentUser.role)) return false;
    if (!shouldUseParentingKidProfile) return false;
    if (!currentUser.joinedAt || !releasedAt) return false;

    const releaseDate = new Date(releasedAt);
    const joinedDate = new Date(currentUser.joinedAt);
    const isFreshMember = isAfter(joinedDate, releaseDate);
    const pageName = isFreshMember
      ? 'ParentingGroupFreshMemberKidProfileCreatePage'
      : 'ParentingGroupMemberKidProfileCreatePage';

    push(pageName, { groupId });
    return true;
  }, [
    currentUser.role,
    currentUser.joinedAt,
    shouldUseParentingKidProfile,
    releasedAt,
    push,
    groupId,
  ]);

  return checkNeedToSetParentingGroupKidProfile;
};

export default useCheckNeedToSetParentingGroupKidProfile;
