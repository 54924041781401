import {
  ActionList,
  ActionListItem,
  ActionSheet,
  Dialog,
  Typography,
  useBottomSheet,
  useDialog,
} from '@community-group/components';

type Props = {
  onDefaultDelete: () => void;
  onRecurringDelete: () => void;
};

export const RecurringGroupMeetupDeleteBottomSheet = ({
  onDefaultDelete,
  onRecurringDelete,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: '0.25rem 1rem 0.875rem',
        }}
      >
        <Typography
          typography="caption1Regular"
          color="gray600"
          textAlign="center"
          style={{ width: '100%' }}
        >
          반복 일정 삭제
        </Typography>
      </div>
      <ActionList>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();
            openDialog({
              element: (
                <Dialog
                  title="일정을 삭제할까요?"
                  description="모든 데이터가 삭제되고 다시 볼 수 없어요."
                  primaryActionLabel="삭제"
                  secondaryActionLabel="취소"
                  onSecondaryAction={async () => {
                    await closeDialog();
                  }}
                  onPrimaryAction={async () => {
                    await closeDialog();
                    onDefaultDelete();
                  }}
                />
              ),
            });
          }}
        >
          이 일정만 삭제
        </ActionListItem>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();
            openDialog({
              element: (
                <Dialog
                  title="일정을 삭제할까요?"
                  description="모든 데이터가 삭제되고 다시 볼 수 없어요."
                  primaryActionLabel="삭제"
                  secondaryActionLabel="취소"
                  onSecondaryAction={async () => {
                    await closeDialog();
                  }}
                  onPrimaryAction={async () => {
                    await closeDialog();
                    onRecurringDelete();
                  }}
                />
              ),
            });
          }}
        >
          이후 모든 일정 삭제
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};
