import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type RequestBody = {
  requestGroupDirectChatId: number;
};

type Props = UseMutationOptions<AxiosResponse<void>, Error, RequestBody>;

export const useDeleteUserChatRequestGroupDirectChats = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteUserChatRequestGroupDirectChats =
    groupClient.api.GroupDirectChatApi.apiV1UserChatRequestGroupDirectChatsRequestGroupDirectChatIdDelete(
      {
        axios: fetchInstance,
      }
    );

  const deleteUserChatRequestGroupDirectChatsFp = ({ requestGroupDirectChatId }: RequestBody) => {
    return deleteUserChatRequestGroupDirectChats(requestGroupDirectChatId);
  };

  return useThrottleMutation(deleteUserChatRequestGroupDirectChatsFp, {
    onError,
    onSuccess,
  });
};
