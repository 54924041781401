import { Spacing, Typography } from '@community-group/components';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import useRedTeamGroupLevel from '@/components/group/GroupLevel/hooks/useRedTeamGroupLevel';

import * as s from './GroupOfTheMonthBanner.css';

const BannerImage =
  'https://assetstorage.krrt.io/1025317940251365954/964003a2-7bd4-45c8-8975-5c7f9ede4e02/width=210,height=210.png';
type Props = {
  groupId: number;
};

// 이달의 모임 배너 - 모임 레벨 5 혜택
function GroupOfTheMonthBanner({ groupId }: Props) {
  const { data } = useGetGroupLevel({ groupId: Number(groupId) });
  const useGroupLevel = useRedTeamGroupLevel();
  if (!data || data.currentLevel !== 5 || !useGroupLevel) return null;

  return (
    <>
      <Spacing size={16} />
      <div className={s.BannerWrapper}>
        <div className={s.TextInfoWrapper}>
          <Typography typography="subtitle2Bold" color="carrot950">
            이 달의 우수 모임
          </Typography>
          <Spacing size={4} />
          <Typography typography="caption2Regular" color="carrot900">
            멤버들의 활동이 많은 모임이에요
          </Typography>
        </div>
        <img className={s.Badge} src={BannerImage} />
      </div>
    </>
  );
}

export default GroupOfTheMonthBanner;
