import { ResizeOptions } from './type';
import { parseResizeImageOptionsParams } from './utils';

/**
 * Example
 *
 * 서버에서 기존에 내려갈 수 있는 URL 형태 (4가지)
 * https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png
 * https://d4zbl7j2h4ct2.cloudfront.net/community/community/20220506/6033fcca-c4d5-43dd-81aa-58394002545et.png&q=100&s=640x640&t=inside
 * https://community-api-cdn.alpha.kr.karrotmarket.com/v1/resource/images/load?id=kr-7141%231522473446226821120
 * https://community-api-cdn.alpha.kr.karrotmarket.com/v1/resource/images/load?id=kr-7141%231522473446226821120&q=100&s=640x640&t=inside
 *
 * resizeImage('https://example.com/image.jpg', { quality: 80, size: '100x100', type: 'crop' });
 * => 'https://example.com/image.jpg?q=80&s=100x100&t=crop'
 */

export const resizeImage = (
  imageUrl: string,
  { quality = 70, size, type = 'crop', file }: ResizeOptions
) => {
  const resizeParams = parseResizeImageOptionsParams({
    quality,
    size,
    type,
    file,
  });

  const url = new URL(imageUrl);

  url.searchParams.set('q', resizeParams.q.toString());
  url.searchParams.set('s', resizeParams.s);
  url.searchParams.set('t', resizeParams.t);
  if (resizeParams.f) {
    url.searchParams.set('f', resizeParams.f);
  }

  return url.toString();
};

export const RESIZE_IMAGE_PRESET: Record<string, ResizeOptions> = {
  avatar_default: {
    quality: 'low',
    size: '160x160',
    type: 'crop',
    file: 'webp',
  },
  avatar_xxsmall: {
    quality: 'low',
    size: '45x45',
    type: 'crop',
    file: 'webp',
  },
  avatar_xsmall: {
    quality: 'low',
    size: '60x60',
    type: 'crop',
    file: 'webp',
  },
  avatar_small: {
    quality: 'low',
    size: '90x90',
    type: 'crop',
    file: 'webp',
  },
  avatar_medium: {
    quality: 'low',
    size: '120x120',
    type: 'crop',
    file: 'webp',
  },
  avatar_large: {
    quality: 'low',
    size: '160x160',
    type: 'crop',
    file: 'webp',
  },
  avatar_xxlarge: {
    quality: 'low',
    size: '240x240',
    type: 'crop',
    file: 'webp',
  },
  thumbnail_default: {
    quality: 'low',
    size: '160x160',
    type: 'crop',
    file: 'webp',
  },
};

export type ResizeImagePresetKeys = keyof typeof RESIZE_IMAGE_PRESET;

export const resizeImagePreset = (url: string, preset: ResizeImagePresetKeys) => {
  const options = RESIZE_IMAGE_PRESET[preset] as ResizeOptions;

  return resizeImage(url, options);
};

export * from './type';
