import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import ProfileListItem from '@/components/common/Profile/ListItem';
import { useUserConfig } from '@/contexts/UserConfig';

import * as s from './SubNicknamePreview.css';
type Props = {
  subNicknameText?: string;
};

const SubNicknamePreview = ({ subNicknameText }: Props) => {
  const { data: profile } = useGetMyMainProfile({ suspense: false, hasOnError: false });
  const { userConfig } = useUserConfig();

  const profileData = profile
    ? {
        nickname: subNicknameText
          ? `${profile?.nickname} ${`(${subNicknameText})`}`
          : `${profile?.nickname} (별명)`,
        id: profile?.id ?? 0,
        isAccountDeleted: false,
        profileImage: profile?.profileImage || '',
        role: 'none' as GroupMemberRoleEnum,
        description: '',
        userRegion: profile?.userRegion ?? {
          id: 0,
          name: '',
          province: '',
          city: '',
          town: '',
        },
      }
    : {
        nickname: subNicknameText
          ? `${userConfig.userNickname} ${`(${subNicknameText})`}`
          : `${userConfig.userNickname}`,
        id: userConfig.userId ?? 0,
        isAccountDeleted: false,
        profileImage: userConfig.userProfileImageUrl || '',
        role: 'none' as GroupMemberRoleEnum,
        description: '',
        userRegion: {
          id: userConfig.regionId ?? 0,
          name: userConfig.regionTownName ?? '',
          province: userConfig.regionProvinceName ?? '',
          city: userConfig.regionCityName ?? '',
          town: userConfig.regionTownName ?? '',
        },
      };

  return (
    <div className={s.Wrapper}>
      <div className={s.ProfileBox}>
        <ProfileListItem user={profileData} />
      </div>
    </div>
  );
};

export default SubNicknamePreview;
