import { MultilineTextField } from '@community-group/components';

import * as s from './style.css';

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

export const MemberReportReasonField = ({ value, onChange }: Props) => (
  <div className={s.Wrapper}>
    <MultilineTextField
      width="100%"
      name="reportReason"
      placeholder="구체적인 상황을 함께 적어주시면, 당근 팀에서 검토 후 이웃을 제재할 수 있어요."
      value={value}
      onChange={onChange}
    />
  </div>
);
