import { GroupMeetupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { GroupAvatar, Typography } from '@community-group/components';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';

import { useFlow } from '@/stackflow';
import { openGroupProfileReport } from '@/utils/link';

import { MEETUP_FEEDBACK_TYPES } from '../../../constant/reviewOptions';
import { MeetupMemberReviewFormHandler } from '../../MeetupReviewForm/type';
import { MemberFeedbackRatingField } from './MemberFeedbackRatingField';
import { MemberFeedbackTypeField } from './MemberFeedbackTypeField';
import { MemberReportReasonField } from './MemberReportReasonField';
import { MemberReviewMessageField } from './MemberReviewMessageField';
import * as s from './style.css';

type Props = {
  groupId: string;
  memberData: GroupMeetupMemberSummaryPresentation;
  index: number;
  formHandler: MeetupMemberReviewFormHandler;
};
export const MemberReviewFieldItem = ({ groupId, memberData, index, formHandler }: Props) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const { push } = useFlow();

  const { profileImage, nickname, subNickname, description, userId, meetupRole } = memberData;

  const { setValue, watch, control, resetField } = formHandler;

  useEffect(() => {
    setValue(`feedbackMembers.${index}.targetMemberId`, userId);
  }, [setValue, index, userId]);

  const displayName = subNickname ? `${nickname}(${subNickname})` : nickname;

  const feedbackRating = useMemo(
    () => watch(`feedbackMembers.${index}.feedbackRating`),
    [watch, index, watch(`feedbackMembers.${index}.feedbackRating`)]
  );

  const shownReportReason = useMemo(() => {
    const selectedFeedbackTypeIds = watch(`feedbackMembers.${index}.feedbackTypeIds`);

    const hasReportReason = (selectedFeedbackTypeIds ?? []).find((id) => {
      const feedbackType = MEETUP_FEEDBACK_TYPES.DISLIKE.get(id);

      return feedbackType?.postReport;
    });

    return hasReportReason;
  }, [watch, index, watch(`feedbackMembers.${index}.feedbackTypeIds`)]);

  return (
    <div className={s.ItemWrapper}>
      <div className={s.ItemHeader}>
        <div className={s.ItemHeaderLeft}>
          <GroupAvatar size="small" src={profileImage} />
          <div
            className={s.Info}
            onClick={() => {
              push('GroupUserProfileDetailPage', {
                userId: userId.toString(),
                groupId,
              });
            }}
          >
            <div className={s.InfoTitle}>
              <Typography typography="bodyM1Regular" ellipsisAfterLines={1}>
                {displayName}
              </Typography>
              {meetupRole === 'HOST' && <span className={s.Badge}>주최자</span>}
            </div>
            <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
              {description}
            </Typography>
          </div>
        </div>
        <div className={s.ItemHeaderRight}>
          <Controller
            control={control}
            name={`feedbackMembers.${index}.feedbackRating`}
            render={({ field: { onChange, value } }) => {
              const handleChange = (value) => {
                onChange(value);
                resetField(`feedbackMembers.${index}.reportReason`);
                resetField(`feedbackMembers.${index}.feedbackTypeIds`);
                resetField(`feedbackMembers.${index}.review`);
              };

              return <MemberFeedbackRatingField value={value} onChange={handleChange} />;
            }}
          />
        </div>
      </div>
      <AnimatePresence>
        {feedbackRating && (
          <motion.div
            key={userId}
            {...motionConfig}
            onAnimationComplete={(animationDefinition: { opacity: number }) => {
              if (animationDefinition.opacity === 1) {
                bottomRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }
            }}
          >
            <Controller
              control={control}
              name={`feedbackMembers.${index}.feedbackTypeIds`}
              render={({ field: { onChange, value } }) => (
                <MemberFeedbackTypeField
                  currentUserMeetupRole={meetupRole}
                  feedbackRating={feedbackRating}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {shownReportReason && (
              <Controller
                control={control}
                name={`feedbackMembers.${index}.reportReason`}
                render={({ field: { onChange, value } }) => (
                  <MemberReportReasonField value={value} onChange={onChange} />
                )}
              />
            )}

            {feedbackRating === 'DISLIKE' && (
              <div className={s.ReportBannerWrapper}>
                <div className={s.ReportBanner}>
                  <Typography typography="bodyM2Regular" color="gray700">
                    혹시 멤버 신고가 필요한가요?
                  </Typography>
                  <Typography
                    as="button"
                    typography="label3Regular"
                    onClick={() => {
                      openGroupProfileReport({
                        groupId,
                        userId: userId.toString(),
                      });
                    }}
                  >
                    신고하기
                  </Typography>
                </div>
              </div>
            )}

            {feedbackRating !== 'DISLIKE' && (
              <Controller
                control={control}
                name={`feedbackMembers.${index}.review`}
                render={({ field: { onChange, value } }) => (
                  <MemberReviewMessageField value={value} onChange={onChange} />
                )}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <div ref={bottomRef} />
    </div>
  );
};

const motionConfig = {
  initial: {
    opacity: 0,
    height: 0,
    y: '10px',
    overflow: 'hidden',
    willChange: 'transform, opacity, height',
  },
  animate: { opacity: 1, height: 'auto', y: 0, animationTimingFunction: 'ease' },
  transition: {
    duration: 0.2,
    when: 'beforeChildren',
  },
  exit: { opacity: 0, height: 0, y: '10px', animationTimingFunction: 'linear' },
};
