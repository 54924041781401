import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import { GroupMeetupDetailMemberListAppTitle } from '../components/GroupMeetupDetailMemberList/AppTitle';
import { MemberListByMeetupJoinStatus } from '../components/GroupMeetupDetailMemberList/MemberListByMeetupJoinStatus';

export type GroupMeetupDetailMemberListPageParams = Pick<
  PageParams,
  'groupId' | 'meetupId' | 'groupJoined'
>;

const GroupMeetupDetailMemberListPage: ActivityComponentType<
  GroupMeetupDetailMemberListPageParams
> = () => {
  return (
    <AppScreen
      appBar={{
        title: (
          <AsyncBoundary>
            <GroupMeetupDetailMemberListAppTitle />
          </AsyncBoundary>
        ),
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <MemberListByMeetupJoinStatus meetupJoinStatus="join" />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupMeetupDetailMemberListPage;
