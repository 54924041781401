import { Container, Spinner } from '@community-group/components';

import {
  MEETUP_PROGRESS_STATUS_FILTER,
  MEETUP_PROGRESS_STATUS_FILTER_KEYS,
} from '@/api/hooks/useGetMeetupList';
import { useGetMyGroupsMeetupLikes } from '@/api/hooks/useGetMyGroupsMeetupLikes';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './LikedMeetupListMoreLoader.css';

type Props = { selectedMeetupProgressStatusKey: MEETUP_PROGRESS_STATUS_FILTER_KEYS };

export const LikedMeetupListMoreLoader = ({ selectedMeetupProgressStatusKey }: Props) => {
  const { isFetchingNextPage, fetchNextPage, hasNextPage } = useGetMyGroupsMeetupLikes({
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER[selectedMeetupProgressStatusKey],
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner size="small" variant="primary" />
      </div>
    </Container>
  );
};
