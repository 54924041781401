import { groupClient } from '@community-group/api';
import { GroupFeedbackReason } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  groupFeedbackReason: GroupFeedbackReason;
};
type Props = UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const useDeleteGroupFeedback = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteGroupFeedback = groupClient.api.GroupFeedbackApi.apiV1GroupsGroupIdFeedbacksDelete({
    axios: fetchInstance,
  });

  const fetchDeleteGroupFeedback = ({ groupId, groupFeedbackReason }: Params) => {
    return deleteGroupFeedback(Number(groupId), groupFeedbackReason);
  };

  return useThrottleMutation(fetchDeleteGroupFeedback, {
    onError,
    onSuccess,
  });
};
