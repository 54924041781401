import { useMemo } from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';

type Props = {
  groupId: string;
  onlyPublic?: boolean;
};

function useGetIsExistHeroSlider({ groupId, onlyPublic }: Props) {
  const { data: photoList } = useGetGroupPhotoList({ groupId, onlyPublic });

  const isExistHeroSlider = useMemo(
    () => (photoList?.pages?.[0]?.photos ?? []).length > 0,
    [photoList?.pages]
  );

  return isExistHeroSlider;
}

export default useGetIsExistHeroSlider;
