import { GroupPhotoListResponse, Image } from '@community-group/api/lib/group/models';
import { InfiniteData } from '@tanstack/react-query';

type ImageQuality = 'small' | 'medium' | 'large';

export const getImagesForViewer = (images: Image[], quality: ImageQuality = 'medium') => {
  return JSON.stringify(images.map((image) => image[quality]));
};

export const getInfinitiveImagesFlatList = (
  data: InfiniteData<GroupPhotoListResponse> | undefined
) => {
  if (!data || !data?.pages) return [];
  return data?.pages.map((data) => data.photos).flat(1);
};
