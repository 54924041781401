import { BoardCategoryPresentation } from '@community-group/api/lib/group/models';
import { BoxButton } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconHandleRegular } from '@seed-design/icon';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { useEffect, useRef } from 'react';

import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './boardCategoryItem.css';

interface Props {
  item: BoardCategoryPresentation;
  sortMode: boolean;
  onDeleteItem: (category: string) => void;
}

export const BoardCategoryItem = ({ item, sortMode, onDeleteItem }: Props) => {
  const { push } = useFlow();
  const { groupId } = usePathParams();

  const y = useMotionValue(0);
  const dragControls = useDragControls();

  const iRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const touchHandler: React.TouchEventHandler<HTMLElement> = (e) => {
      if (e.cancelable) e.preventDefault();
    };

    const iTag = iRef.current;

    if (iTag) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      iTag.addEventListener('touchstart', touchHandler, { passive: false });

      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        iTag.removeEventListener('touchstart', touchHandler, {
          passive: false,
        });
      };
    }
  }, [iRef]);

  return (
    <div className={s.wrapper}>
      <Reorder.Item
        value={item}
        id={item.category}
        style={{ y }}
        dragListener={false}
        dragControls={dragControls}
        ref={iRef}
        className={s.list}
      >
        <span className={s.BoardText}>
          {item.category} {item.type === 'default' && '(기본)'}
        </span>
        <div className={s.item}>
          {item.type === 'custom' && !sortMode && (
            <div
              onTouchStart={(e) => {
                e.stopPropagation();
                e.preventDefault();
                push('BottomSheet/GroupSettingEditBoardCategoryNameBottomSheet', {
                  groupId,
                  initialBoardCategoryName: item.category,
                });
              }}
            >
              <BoxButton variant="secondary" size="medium" flexShrink={0}>
                수정
              </BoxButton>
            </div>
          )}
          {item.type === 'custom' && !sortMode && (
            <div
              onTouchStart={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeleteItem(item.category);
              }}
            >
              <BoxButton variant="secondary" size="medium" flexShrink={0}>
                {' '}
                삭제
              </BoxButton>
            </div>
          )}
          {sortMode && (
            <div>
              <div onPointerDown={(event) => dragControls.start(event)} className={s.handle}>
                <IconHandleRegular size={24} color={vars.$scale.color.gray500} />
              </div>
            </div>
          )}
        </div>
      </Reorder.Item>
    </div>
  );
};
