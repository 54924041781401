import { Skeleton } from '@community-group/components';
import { Spacing } from '@community-group/components';

import * as s from './Skeleton.css';

const MyGroupSkeleton = () => (
  <>
    <div className={s.wrapper}>
      <Spacing size={24} />
      <h3 className={s.title}>내 모임</h3>
      <Spacing size={8} />
      <div className={s.contentsWrapper}>
        {Array.from({ length: 8 }).map((item, index) => (
          <div className={s.item} key={`my-skeleton-${index}`}>
            <Skeleton width="3.5rem" height="3.5rem" borderRadius="0.5rem" />
            <Skeleton width="1.25rem" height="0.8rem" borderRadius="0.3rem" />
          </div>
        ))}
      </div>
    </div>
    <div className={s.divider} />
  </>
);

export default MyGroupSkeleton;
