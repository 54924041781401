import { model } from '@community-group/api/lib/group';
import { Dialog, useDialog, useSnackbarAdapter } from '@community-group/components';

import { usePatchApplicationAcceptance } from '@/api/hooks/usePatchApplicationAcceptance';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useConnectedFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { MemberRejectMenuBottomSheetRequest } from '../pages/BottomSheet/MemberRejectMenuBottomSheet';
import { GroupMemberRejectMessagePageRequest } from '../pages/MemberRejectMessage';

interface Props {
  userId: number;
  groupId: string;
  isJoinable: boolean;
  currentUserRole?: model.GroupMemberRoleEnum;
  onPatchSuccess?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
}

export const useHandleApplication = ({
  userId,
  groupId,
  isJoinable,
  currentUserRole,
  onPatchSuccess,
  onAccept,
  onReject,
}: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const snackbarAdapter = useSnackbarAdapter();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutateAsync: patchApplication } = usePatchApplicationAcceptance({
    onError: handleErrorWithToast,
    onSuccess: onPatchSuccess,
  });
  const { mutate: mutateJoinableRegionRange } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupMemberList({ groupId });
      refetchGroupDetail({ groupId });
    },
  });

  const acceptApplication = async () => {
    await patchApplication({
      id: Number(groupId),
      userId,
      form: {
        status: 'accept',
      },
    });
    snackbarAdapter.create({
      message: '가입을 승인했어요. 새로 온 이웃에게 참여를 요청해보세요.',
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
    onAccept?.();
  };

  const handleAccept = () => {
    if (isJoinable) {
      acceptApplication();
      return;
    }

    if (currentUserRole !== 'superHost') {
      openDialog({
        element: (
          <Dialog
            description="가입을 승인하려면 모임장이 모집 동네 범위를 넓혀야 해요."
            primaryActionLabel="확인"
            secondaryActionIntent="nonpreferred"
            onPrimaryAction={async () => {
              await closeDialog();
            }}
          />
        ),
        onOutsideClick: closeDialog,
      });
      return;
    }

    openDialog({
      element: (
        <Dialog
          title="모집 동네 범위 안내"
          description="가입을 승인하려면 모집 동네 범위 제한을 풀어야 해요. 제한을 풀고 이웃을 가입시킬까요?"
          primaryActionLabel="제한 풀고 가입 승인"
          secondaryActionLabel="취소"
          secondaryActionIntent="nonpreferred"
          onSecondaryAction={async () => {
            await closeDialog();
          }}
          onPrimaryAction={async () => {
            mutateJoinableRegionRange(
              {
                id: groupId,
                groupModifyForm: {
                  joinableRegionRange: 'unlimited',
                },
              },
              {
                onSuccess: () => {
                  acceptApplication();
                  trackEvent({
                    event: 'click_lift_group_range_restrictions',
                    params: {
                      groupId,
                    },
                  });
                },
              }
            );
            await closeDialog();
          }}
        />
      ),
      onOutsideClick: closeDialog,
    });
  };

  const rejectApplication = async (comment?: string) => {
    await patchApplication({
      id: Number(groupId),
      userId,
      form: {
        status: 'reject',
        comment,
      },
    });
    snackbarAdapter.create({
      message: '가입을 거절했어요.',
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
    onReject?.();
  };

  const { push } = useConnectedFlow();
  const rejectWithMessage = async () => {
    const request = (await push('GroupMemberRejectMessagePage', {
      groupId,
    })) as unknown as GroupMemberRejectMessagePageRequest;

    switch (request.method) {
      case 'reject':
        rejectApplication(request.message);
        break;
      case 'close':
      default:
        return;
    }
  };

  const handleReject = async () => {
    const request = (await push('BottomSheet/MemberRejectMenuBottomSheet', {
      groupId,
      userId,
    })) as unknown as MemberRejectMenuBottomSheetRequest;

    switch (request) {
      case 'reject':
        rejectApplication();
        break;
      case 'rejectWithMessage':
        await rejectWithMessage();
        break;
    }
  };

  return {
    handleAccept,
    handleReject,
  };
};
