import { groupClient } from '@community-group/api';

import { useGetGroupMeetupMembers } from '@/api/hooks/useGetGroupMeetupMembers';
import EmptySection from '@/components/common/Empty';
import { useUserConfig } from '@/contexts/UserConfig';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { GroupMeetupMemberItem } from './GroupMeetupMemberItem';
import { LoadMoreGroupMeetupMemberList } from './LoadMoreMemberList';

type Props = {
  meetupJoinStatus: groupClient.model.GroupMeetupJoinStatusEnum;
  emptyMemberListMessage?: string;
};

export const MemberListByMeetupJoinStatus = ({
  meetupJoinStatus,
  emptyMemberListMessage,
}: Props) => {
  const { groupId = '0', meetupId = '0' } = usePathParams();
  const { userConfig } = useUserConfig();

  const { data, hasNextPage } = useGetGroupMeetupMembers({
    groupId,
    meetupId,
    meetupJoinStatus,
  });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];

  if (flattenMemberList.length <= 0) {
    return <EmptySection style={{ height: '80vh' }}>{emptyMemberListMessage}</EmptySection>;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {flattenMemberList.map((member) => (
        <GroupMeetupMemberItem
          key={member.userId}
          groupId={groupId}
          user={member}
          shownMeBadge={member.userId === userConfig.userId}
        />
      ))}
      {hasNextPage && (
        <LoadMoreGroupMeetupMemberList
          groupId={groupId}
          meetupId={meetupId}
          meetupJoinStatus={meetupJoinStatus}
        />
      )}
    </div>
  );
};
