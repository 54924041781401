import { useMemo } from 'react';

import { useGetMembersForMention } from '@/api/hooks/useGetMembersForMention';
import { convertNicknameDisplay } from '@/utils/nickname';

export const useMembersForMentions = (groupId: string) => {
  const { data: memberList } = useGetMembersForMention(groupId);

  const flattenMemberList = memberList?.pages.map((page) => page.data.members);
  const flatten = useMemo(() => flattenMemberList?.flat(1), [flattenMemberList]);

  const membersForMentions = useMemo(
    () =>
      flatten?.map((member) => {
        return {
          id: member.id,
          display: `@${convertNicknameDisplay({
            nickname: member.nickname,
            subNickname: member.subNickname,
          })}`,
          imageSrc: member.profileImage,
        };
      }) || [],
    [flatten]
  );

  return membersForMentions;
};
