import { useBottomSheet } from '@community-group/components';
import { IconCloseRegular } from '@seed-design/icon';
import React from 'react';
import { Zoom } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import * as s from './ImageSliderBottomSheet.css';
interface Props extends SwiperProps {
  images: string[];
  initialIndex: number | null;
}

const ImageSliderBottomSheet: React.FC<Props> = ({
  images,
  initialIndex,
  zoom = true,
  ...props
}) => {
  const { close: closeBottomSheet } = useBottomSheet();

  return (
    <div className={s.wrapper}>
      <Swiper
        {...props}
        zoom={zoom}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom]}
        initialSlide={initialIndex ?? 0}
        style={{
          height: '100%',
        }}
      >
        {images.map((src, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="swiper-zoom-container">
                <img src={src} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={s.closeButton} onClick={closeBottomSheet}>
        <IconCloseRegular size={24} />
      </div>
    </div>
  );
};

export default ImageSliderBottomSheet;
