import { ParentingGroupKidForm } from '@community-group/api/lib/group/models';

export const KID_COUNT_TEXT = [
  '첫째',
  '둘째',
  '셋째',
  '넷째',
  '다섯째',
  '여섯째',
  '일곱째',
  '여덟째',
  '아홉째',
  '열째',
];

export const DEFAULT_KID_PROFILE: ParentingGroupKidForm = {
  birthday: '',
  gender: 'male',
};
