import { TextField, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconAddRegular, IconSubtractionRegular, IconUserGroupRegular } from '@seed-design/icon';
import { useMemo } from 'react';
import { Path, PathValue, UnpackNestedValue, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { meetupFieldSchema } from '@/utils/validate/formSchema/meetup';

import { getFormFieldQuerySelector } from '../../../utils/selector';
import { GroupMeetupFormListItem, GroupMeetupFormListItemProps } from '../../ListItem';
import * as s from './index.css';
interface Props<T extends object>
  extends Pick<GroupMeetupFormListItemProps, 'children' | 'onClick'> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}
export const GroupMeetupFormMaximumParticipantsNumberField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const currentParticipantsNumber = watch(name);

  const minimumParticipantsNumber = meetupFieldSchema.maximumParticipantsNumber.minValue ?? 3;
  const maximumParticipantsNumber = 100;

  const handleSubtractMember = () => {
    if (currentParticipantsNumber <= minimumParticipantsNumber) return;

    setValue(
      name,
      (parseInt(currentParticipantsNumber) - 1) as UnpackNestedValue<PathValue<T, Path<T>>>
    );
  };

  // 지우고 + 룰
  const handleAddMember = () => {
    if (currentParticipantsNumber >= maximumParticipantsNumber) return;

    setValue(
      name,
      (parseInt(currentParticipantsNumber) + 1) as UnpackNestedValue<PathValue<T, Path<T>>>
    );
  };

  const handleInput = (value: string) => {
    const parsedValue = !value ? value : parseInt(value);
    setValue(name, parsedValue as UnpackNestedValue<PathValue<T, Path<T>>>);
  };

  const validateInput = useMemo(() => {
    if (currentParticipantsNumber < minimumParticipantsNumber) {
      return {
        isInvalid: true,
        errorMessage: `최소 인원은 ${minimumParticipantsNumber}명이에요.`,
      };
    }

    if (currentParticipantsNumber > maximumParticipantsNumber) {
      return {
        isInvalid: true,
        errorMessage: `최대 인원은 ${maximumParticipantsNumber}명이에요.`,
      };
    }

    return {
      isInvalid: false,
      errorMessage: '',
    };
  }, [currentParticipantsNumber]);

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconUserGroupRegular size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="인원"
      fieldName={name}
      fieldValue={
        <Typography as="p" typography="label2Regular" color="gray900" textAlign="right">
          {currentParticipantsNumber}명
        </Typography>
      }
    >
      <div className={s.wrapper}>
        <div className={s.inputWrapper}>
          {/* TODO: Fix TextField */}
          <TextField
            type="number"
            value={currentParticipantsNumber?.toString() ?? '4'}
            onChange={handleInput}
            suffix="명"
            variant="outlined"
            isInvalid={validateInput.isInvalid}
            errorMessage={validateInput.errorMessage}
            minWidth={100}
            onFocus={() => {
              setTimeout(() => {
                const descriptionFieldDom = getFormFieldQuerySelector(name);
                descriptionFieldDom?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 350);
            }}
            onBlur={() => {
              if (currentParticipantsNumber === '') {
                setValue(
                  name,
                  minimumParticipantsNumber as UnpackNestedValue<PathValue<T, Path<T>>>
                );
              }
            }}
            invalidAfterBlurred={false}
          />
        </div>
        <div className={s.controlWrapper}>
          <button className={s.button} type="button" onClick={handleSubtractMember}>
            <IconSubtractionRegular size={24} color={vars.$scale.color.gray900} />
          </button>
          <button className={s.button} type="button" onClick={handleAddMember}>
            <IconAddRegular size={24} color={vars.$scale.color.gray900} />
          </button>
        </div>
      </div>
    </GroupMeetupFormListItem>
  );
};
