import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from '../instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.TalkRoomResponse>,
  Error,
  { id: number; talkRoomCreateFrom: model.TalkRoomCreateFrom }
>;

export const usePostCreateRoom = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postCreateRoom = groupClient.api.TalkRoomApi.apiV1GroupsIdRoomsPost({
    axios: fetchInstance,
  });

  const postCreateRoomWrapperFp = ({
    id,
    talkRoomCreateFrom,
  }: {
    id: number;
    talkRoomCreateFrom: model.TalkRoomCreateFrom;
  }) => {
    return postCreateRoom(id, talkRoomCreateFrom);
  };

  return useThrottleMutation(postCreateRoomWrapperFp, {
    onError,
    onSuccess,
  });
};
