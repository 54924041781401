import { vars } from '@seed-design/design-token';
import React, { useState } from 'react';

import RemoveCircle from '@/components/common/Icons/removeCircle';
import { useBridge } from '@/contexts/Bridge';
import { useStore } from '@/store';

import * as s from './SearchBar.css';

interface Props {
  initValue?: string;
  onSubmit?: (value: string) => void;
  autoFocus?: boolean;
}

const SearchBar = ({ initValue = '', onSubmit, autoFocus }: Props) => {
  const [searchValue, setSearchValue] = useState(initValue);
  const { addSearchHistoyItem } = useStore();

  const searchValueLength = searchValue.length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);
  };
  const { bridge } = useBridge();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchValue.trim().length === 0) {
      return bridge.openToast({
        toast: {
          body: '검색어를 입력해주세요.',
        },
      });
    }

    onSubmit?.(searchValue);
    addSearchHistoyItem(searchValue);
    handleResetClick();
  };

  const handleResetClick = () => {
    setSearchValue('');
  };

  const handleSubmitKeyClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const SUBMIT_KEY = 13;

    if (event.keyCode === SUBMIT_KEY) {
      event.preventDefault();
      event.currentTarget.blur();
    }
  };

  return (
    <div className={s.Wrapper}>
      <form className={s.Form} action="." noValidate onSubmit={handleSubmit}>
        <input
          className={s.Input}
          type="search"
          value={searchValue}
          onChange={handleChange}
          placeholder="모임 검색"
          autoFocus={autoFocus}
          onKeyUp={handleSubmitKeyClick}
        />
        {searchValueLength > 0 && (
          <div className={s.Adornment} onClick={handleResetClick}>
            <RemoveCircle size={15} fill={vars.$scale.color.gray600} />
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchBar;
