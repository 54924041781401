import { ViewError, ViewLoader } from '@community-group/components';
import {
  AsyncBoundary,
  MeetupCommonListCardItem,
  MeetupProgressStatus,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetGroupMeetups } from '@/api/hooks/useGetGroupMeetups';
import { useGetMe } from '@/api/hooks/useGetMe';
import { View } from '@/components/common/View';
import { renderMeetupDateText } from '@/components/group/Home/utils/date';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import RecommendMeetupLoader from './RecommendMeetupLoader';

const RecommendMeetupMorePage: ActivityComponentType = () => {
  useEnterTrackEvent({
    event: 'enter_recommend_meetup_more_page',
  });

  return (
    <AppScreen appBar={{ title: '모임 일정' }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <RecommendMeetupMoreView />
        </AsyncBoundary>
      </View>
    </AppScreen>
  );
};

export default RecommendMeetupMorePage;

const RecommendMeetupMoreView = () => {
  const { push } = useFlow();

  const { data: me } = useGetMe();
  const { data, hasNextPage } = useGetGroupMeetups({ regionId: me.regionCheckIns.regionId });

  const meetups = data?.pages[0].data.meetups ?? [];
  const flattenMeetups = meetups.flat(1);

  return (
    <div className={s.wrapper}>
      {flattenMeetups.map((meetup, index) => (
        <MeetupCommonListCardItem
          key={meetup.id}
          progressStatus={meetup.progressStatus as MeetupProgressStatus}
          label={
            meetup.groupInfo.groupCreatedRegion?.name
              ? ` ⸱ ${meetup.groupInfo.groupCreatedRegion.name}`
              : ''
          }
          title={meetup.title}
          infoList={[
            {
              type: 'user_group',
              text: meetup.groupInfo.name ?? '',
            },
            {
              type: 'calendar',
              text: renderMeetupDateText(meetup.meetDate, meetup.meetTime),
            },
          ]}
          memberList={meetup.participantsProfileImages}
          currentParticipantsNumber={meetup.currentParticipantsNumber}
          maximumParticipantsNumber={meetup.maximumParticipantsNumber}
          createdAt={meetup.createdAt}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();

            push('GroupMeetupDetailPage', {
              meetupId: meetup.id.toString(),
              groupId: (meetup.groupInfo.id ?? '').toString(),
              from: 'recommendMeetupMorePage',
            });

            trackEvent({
              event: 'click_recommend_meetup',
              params: {
                meetupId: meetup.id,
                meetupTitle: meetup.title,
                groupId: meetup.groupInfo.id,
                groupName: meetup.groupInfo.name,
                groupCategoryName: meetup.groupInfo.category?.name,
                listItemIndex: index,
              },
              sample: true,
            });
          }}
        />
      ))}
      {hasNextPage && <RecommendMeetupLoader />}
      <div className={s.safeAreaBottom} />
    </div>
  );
};
