import { PostDetail } from '@community-group/api/lib/group/models';
import { GroupAvatar, Typography } from '@community-group/components';

import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';
import { GroupRoutes } from '@/utils/analytics/type';
import { convertNicknameDisplay } from '@/utils/nickname';
import { getPostDetailInfoText } from '@/utils/postInfoText';

import * as s from './WriterUserInformation.css';

interface Props {
  post: PostDetail;
}

const WriterUserInformation = ({ post }: Props) => {
  const { groupId } = usePathParams();
  const { push } = useFlow();

  const profileImage = getUserProfileUrlInduceExperiment({
    groupId: groupId ? parseInt(groupId) : 0,
    userId: post.currentUserInfo?.id ?? 0,
    defaultUrl: post.author.profileImage ?? '',
  });

  return (
    <div
      className={s.ProfileBox}
      onClick={() => {
        if (post.author.isAccountDeleted) return;
        trackEvent({
          event: 'click_profile',
          params: {
            referrer: GroupRoutes.GROUP_DETAIL,
            clickedUserId: post.author?.id,
            clickedUserRole: post.author?.role,
            clickedUserState: post.author?.state,
            type: 'post',
          },
        });
        setTimeout(() => {
          push('GroupUserProfileDetailPage', {
            groupId: groupId,
            userId: post.author.id.toString(),
          });
        }, 200);
      }}
      aria-hidden="true"
    >
      <GroupAvatar src={profileImage} role={post.author.role} />
      <div className={s.PostDetailInformationItemRightSection}>
        <Typography
          typography="caption1Bold"
          color={post.author.isAccountDeleted ? 'gray500' : 'gray900'}
        >
          {convertNicknameDisplay({
            nickname: post.author.nickname,
            subNickname: post.author.subNickname,
          })}
        </Typography>
        <p className={s.Info}>
          {getPostDetailInfoText({
            createdAt: post.createdAt,
            isPublished: post.isPublished,
            isGroupOnly: post.publishType === 'groupOnly',
            isNoticed: post.isNoticed,
            boardCategory: post.boardCategories[post.boardCategories.length - 1]?.category,
          })}
        </p>
      </div>
    </div>
  );
};

export default WriterUserInformation;
