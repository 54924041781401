import { useCallback, useState } from 'react';

import { FileUploadState } from '../types/Video';

export type ManageUploadStateProps = {
  externalStateSelector?: {
    externalState: FileUploadState[];
    externalStateHandler: (updater: (prev: FileUploadState[]) => FileUploadState[]) => void;
  };
};

export const useManageUploadState = ({ externalStateSelector }: ManageUploadStateProps) => {
  const [internalState, setInternalState] = useState<FileUploadState[]>([]);

  // 업로드 상태를 사용할 때 외부 상태가 있으면 외부 상태를 사용
  const uploadedFiles = externalStateSelector?.externalState ?? internalState;
  const setUploadedFiles = externalStateSelector?.externalStateHandler ?? setInternalState;

  // 업로드 상태 추가
  const addUpload = useCallback(
    (upload: FileUploadState) => {
      setUploadedFiles((prev) => [...prev, upload]);
    },
    [setUploadedFiles]
  );

  // 업로드 상태 업데이트
  const updateUploadStatus = useCallback(
    (fileId: string, updates: Partial<FileUploadState>) => {
      const updateFiles = (prev: FileUploadState[]) =>
        prev.map((upload) => (upload.id === fileId ? { ...upload, ...updates } : upload));

      setUploadedFiles((prev) => updateFiles(prev));
    },
    [setUploadedFiles]
  );

  // 업로드 상태 제거
  const removeUpload = useCallback(
    (fileId: string) => {
      const removeFiles = (prev: FileUploadState[]) =>
        prev.filter((upload) => upload.id !== fileId);

      setUploadedFiles((prev) => removeFiles(prev));
    },
    [setUploadedFiles]
  );

  return {
    uploadedFiles,
    addUpload,
    updateUploadStatus,
    removeUpload,
  };
};
