import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { queryClient } from '../instance';
import { useFetchInstance } from './instance/useFetchInstance';
import { deleteLikeMeetupComment, deleteLikePostComment } from './useGetComment';
import {
  deleteLikeMeetupComments,
  deleteLikePostComments,
  getMeetupCommentsPath,
  getPostCommentsPath,
} from './useGetComments';

type Request = { groupId?: string; relatedId?: string; commentId?: string };
type Props = {
  relatedContentType: 'post' | 'meetup';
} & UseMutationOptions<string | undefined, Error, Request>;

export const useDeleteCommentEmotion = ({
  relatedContentType,
  onError,
  onSuccess,
  onSettled,
}: Props) => {
  const isPostComment = relatedContentType === 'post';

  const onMutate = async (variables: Request) => {
    if (isPostComment) {
      const commentsKey = getPostCommentsPath(variables.groupId, variables.relatedId);
      await queryClient.cancelQueries({ queryKey: [commentsKey] });
      const previousCommentData = queryClient.getQueryData([commentsKey]);
      updatePostComments(variables.groupId, variables.relatedId, variables.commentId);
      return previousCommentData;
    }

    const commentsKey = getMeetupCommentsPath(variables.groupId, variables.relatedId);
    await queryClient.cancelQueries({ queryKey: [commentsKey] });
    const previousCommentData = queryClient.getQueryData([commentsKey]);
    updateMeetupComments(variables.groupId, variables.relatedId, variables.commentId);
    return previousCommentData;
  };
  const handleSettled: typeof onSettled = (data, error, variables, context) => {
    onSettled?.(data, error, variables, context);
  };

  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsCommentIdEmotionsDelete
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsCommentIdEmotionsDelete;

  const fetchInstance = useFetchInstance();
  const deleteCommentEmotion = apiMethod({
    axios: fetchInstance,
  });

  return useThrottleMutation(
    async ({ groupId, relatedId, commentId }: Request) => {
      await deleteCommentEmotion(Number(groupId), Number(relatedId), Number(commentId));
      return commentId;
    },
    {
      onError,
      onSuccess,
      onMutate,
      onSettled: handleSettled,
    }
  );
};

const updatePostComments = (groupId?: string, relatedId?: string, commentId = '0') => {
  deleteLikePostComments(groupId, relatedId, commentId);
  deleteLikePostComment(groupId, relatedId, commentId);
};

const updateMeetupComments = (groupId?: string, relatedId?: string, commentId = '0') => {
  deleteLikeMeetupComments(groupId, relatedId, commentId);
  deleteLikeMeetupComment(groupId, relatedId, commentId);
};
