import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export type SEARCH_MEETUP_ORDER_TYPE = 'created_at_desc' | 'meet_at_asc';

type Params = {
  groupId: number;
  userId: number;
};

export const getMemberFeedsSummaryQueryKey = (groupId: number, userId: number) =>
  groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersUserIdFeedsGetPath(groupId, userId);

export const useGetMemberFeeds = ({ groupId, userId }: Params) => {
  const fetchInstance = useFetchInstance();
  const getMemberFeeds = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdFeedsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getMemberFeedsSummaryQueryKey(groupId, userId)],
    ({ pageParam = undefined }) => getMemberFeeds(groupId, userId, pageParam, 20),
    {
      getNextPageParam: ({ data }) => (data.endCursor ? data?.endCursor : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
