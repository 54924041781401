import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

import { ShortcutMenuProps } from '@/components/group/DetailV2/components/GroupDetailShortcutMenu';

export type GroupEventParams = GroupEventParamsBase & UTMParams & ReferParams;

export interface GroupEventParamsBase
  extends Record<string, string | number | undefined | boolean> {
  id?: string | number;
  groupId?: string | number;
  category?: string;
  groupName?: string;
  groupProvince?: string;
  groupTown?: string;
  groupCity?: string;
  postId?: string | number;
  contentType?: 'post' | 'comment' | 'meetup';
  route?: GroupRoutes;
  talkId?: string | number;
  referrer?: Referrer;
  type?: 'post' | 'comment' | 'talk' | 'meetup' | 'feed' | string;
  profileImage?: boolean;
  backgroundImage?: boolean;
  step?: string;
  exitType?: 'group_app' | string;
  isNewTalk?: boolean;
  checkinReigionId?: string | number;
  checkinMostAccurate?: string;
  role?: GroupMemberRoleEnum;
  roomId?: string | number;
  roomName?: string;
  from?: string;
  name?: string;
  boardCategoryName?: string;
  pollName?: string;
  pollOptionCount?: number;
  pollMultipleOption?: boolean;
  pollId?: string | number;
  hasPoll?: boolean;
  description?: string;
  isInstantMeetupJoin?: boolean;
  groupCategory?: string;
  currentUserState?:
    | 'member'
    | 'restricted'
    | 'applied'
    | 'notMember'
    | 'rejected'
    | 'removed'
    | string;
  permission?: string;
  isSettingOnSubnickname?: boolean;

  isPayPromotionTarget?: boolean; // 페이 프로모션 이벤트 대상자 여부
  promotionCode?: string; // 페이 프로모션 이벤트 코드
  promotionType?: 'join_direct' | 'approval'; // 마케팅 프로모션 이벤트 타입
  hasVerifiedInfo?: boolean;
  verifiedInfo?: string;
  feedbackType?: 'groupDetail' | 'postDetail' | 'meetupDetail';
  isSwitchOn?: boolean;
  searchTab?: 'all' | 'group' | 'meetup';

  candidateType?: string; // 본인인증 바텀시트 타입
  month?: string; // 새소식 몇월호 클릭
  version?: '1' | '2';
  isChatRequired?: boolean; // 전체 채팅방 강결합 여부 - 강결합 상태이면 true
  isChatActivated?: boolean; // 전체 채팅방 존재 여부
  boardName?: string; // 게시판 이름
  snsTagType?: string;
  snsTagId?: string;
  hasSnsTag?: boolean;
  directChatEnabledStatus?: string;
  isPublishedToFeed?: boolean;
  hasAlcoholKeywordPostContent?: boolean;
  hasRomanceKeywordPostContent?: boolean;
  hasAlcoholKeyWordGroupDescription?: boolean;
  hasRomanceKeyWordGroupDescription?: boolean;
  postContent?: string;

  groupDetailShortcutMenu?: ShortcutMenuProps;
  sectionName?: string;

  createGroupFlowType?: 'normal' | 'recommend';
  clickCategoryId?: number | string;
  clickRecommendCategoryIndex?: number | string;

  createKidProfileFlowType?: 'only_kid_profile' | 'with_sub_nickname';
  kidsCount?: number;
  kidIndex?: number;
}

export type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

export type ReferParams = {
  refer_root?: ReferRootType;
  refer_section?: ReferSectionType;
  refer_content?: ReferContentType;
  refer_content_index?: string;
  refer_action?: ReferActionType;
};

export type Referrer =
  | 'member_list'
  | 'home_top_banner'
  | 'home_bottom_banner'
  | 'first_to_create_in_neighborhood'
  | string;

export type ReferRootType = 'community_tab' | 'notification' | 'search';
export type ReferSectionType = 'lnb' | 'feed';
export type ReferActionType =
  | 'feed_group_tag'
  | 'view_more_groups'
  | 'join_group'
  | 'mission_complete'; //그로스 미션

export type ReferContentType =
  | 'group_home'
  | 'my_group'
  | 'recommended_group'
  | 'collection_overview'
  | 'new_group_promote'
  | 'new_group_meetup'
  | 'group_meetup_review'
  | 'group_post'
  | 'group_like'
  | 'group_comment'
  | 'group_new_member_request'
  | 'group_access_granted'
  | 'group_access_denied'
  | 'group_meetup_how_was_it'
  | 'group_talk'
  | 'group_talk_mention'
  | 'new_post_notification'
  | 'meetup_member_review_messages'
  | 'new_feature';

export enum FirebaseEventType {
  SHOW = 'show_group',
  ACTION = 'action_group',
}

export type SpecificEventType = EnterEventType | ActionEventType | ImpressionEventType;

export type EnterEventType = EnterGroupEventType | EnterMeetupEventType;

type EnterGroupEventType =
  | 'enter_home'
  | 'enter_group_detail'
  | 'enter_post_detail'
  | 'enter_create_group_step'
  | 'enter_member_list'
  | 'enter_search_home'
  | 'enter_recommend_meetup_more_page'
  | 'enter_daangn_activity_notification'
  | 'enter_sorting_group_list_bottom_sheet'
  | 'enter_group_meetup_detail'
  | 'enter_group_detail_notice_more_page'
  | 'enter_poll_result_page'
  | 'enter_group_meetup_attendance_page'
  | 'enter_curation_page'
  | 'enter_notice_publish_group_bottom_sheet'
  | 'enter_create_group_meetup_bottom_sheet'
  | 'enter_integreated_group_profile_page'
  | 'enter_search_result'
  | 'enter_group_write_post' // 앱스플라이어용
  | 'enter_create_meetup' // 앱스플라이어용
  | 'enter_create_group_step_category' // 앱스플라이어용
  | 'enter_create_group_step_name' // 앱스플라이어용
  | 'enter_edit_group_description'
  | 'enter_member_profile'
  | 'enter_application_rejected_bottom_sheet'
  | 'enter_verification_induce_bottomSheet'
  | 'enter_verification_edit_bottomSheet'
  | 'enter_verification_faq_page'
  | 'enter_chat_room_guide_page'
  | 'enter_guard_forbidden_word_group_bottom_sheet'
  | 'enter_group_pay_transfer_direct_chat'
  | 'enter_photo_event_page'
  | 'enter_from_marketing_promotion' // 프로모션 페이지에서 진입, 프로모션 종료 후 제거
  | 'enter_bot_setting'
  | 'enter_join_group_marketing_10월_운동_bottom_sheet' // TODO: 10월 운동 프로모션 가입 본 마케팅 프로모션 바텀시트
  | 'enter_create_group_marketing_10월_운동_bottom_sheet' // TODO: 10월 운동 프로모션 생성 본 마케팅 프로모션 바텀시트
  | 'enter_create_group_marketing_11월_독서_bottom_sheet' // TODO: 11월 독서 프로모션 생성 본 마케팅 프로모션 바텀시트
  | 'enter_done_create_group_marketing_11월_독서_bottom_sheet' // TODO: 11월 독서 프로모션 생성 완료 본 마케팅 프로모션 바텀시트
  | 'enter_join_group_marketing_11월_독서_bottom_sheet' // TODO: 11월 독서 프로모션 가입 본 마케팅 프로모션 바텀시트
  | 'enter_notice_group_level_bottom_sheet'
  | 'enter_level_up_alert_bottom_sheet'
  | 'enter_level_detail_page' // 모임 레벨 상세 페이지 진입
  | 'enter_invite_member_share_bottom_sheet'
  | 'enter_kid_profile_form_page'
  | 'enter_kid_profile_list_page';

type EnterMeetupEventType = 'enter_meetup_home' | 'enter_write_group_meetup_review_page';

export type ActionEventType = ActionGroupEventType | ActionMeetupEventType;

type ActionGroupEventType =
  | 'finish_modify_group_description'
  | 'click_finish_modify_group_description'
  | 'click_intend_banner_create_group_meetup'
  | 'click_intend_banner_close_group_meetup'
  | 'click_create_talk_room'
  | 'add_board_category'
  | 'click_add_board_category'
  | 'click_upload_group_profile'
  | 'click_group_summary_info_item'
  | 'click_explorer_group_list_item'
  | 'click_recommended_group_list_item'
  | 'click_search_result_list_item'
  | 'click_my_group'
  | 'click_like'
  | 'click_report'
  | 'click_write_fab'
  | 'click_write_post'
  | 'click_modify_post'
  | 'click_modify_comment'
  | 'click_comment_input'
  | 'click_delete'
  | 'click_delete_group'
  | 'click_cancel' // 모달로 한 번 더 물어봐서 취소 하는 경우
  | 'click_leave_group'
  | 'click_start_creating_group'
  | 'click_write_description_on_onboarding'
  | 'click_join_group'
  | 'click_share_group'
  | 'click_profile'
  | 'click_verify_neighboorhood'
  | 'click_cancel_verify_neighboorhood'
  | 'click_cancel_delete_group'
  | 'click_start_survey'
  | 'click_like_list'
  | 'pull_to_refresh'
  | 'click_pull_to_refresh'
  | 'click_group_meetup_create'
  | 'click_tab_home'
  | 'click_tab_feed'
  | 'click_tab_photo'
  | 'click_tab_meetup'
  | 'click_tab_talk'
  | 'click_simple_search_article' // 검색팀 로그 스펙 (https://karrot.atlassian.net/wiki/spaces/SCH/pages/1769308306)
  | 'click_viewrange_filter'
  | 'click_home_tab_my_group_tab'
  | 'click_home_explorer_group_tab'
  | 'click_recommend_meetup'
  | 'click_recommend_meetup_more'
  | 'click_search_icon'
  | 'click_hot_searched_item' // 많이 찾는 검색어 아이템
  | 'click_recent_searched_item' // 최근 검색어 아이템
  | 'click_popularity_group'
  | 'click_my_group_post_detail'
  | 'click_join_talk_room'
  | 'click_daangn_activity_notification'
  | 'click_member_list_order_type'
  | 'click_board_category_setting'
  | 'click_board_category_edit'
  | 'click_sorting_group_list'
  | 'click_group_category'
  | 'click_home_explorer_meetup_tab'
  | 'click_start_creating_meetup'
  | 'click_group_meetup_item'
  | 'click_sorting_meetup'
  | 'click_join_group_meetup'
  | 'click_cancel_join_meetup'
  | 'click_write_group_meetup_review_button'
  | 'click_group_home_tab_more_meetup'
  | 'click_group_home_tab_more_photo'
  | 'click_group_home_tab_more_feed'
  | 'click_group_home_tab_more_notice'
  | 'click_group_meetup_post'
  | 'click_group_meetup_edit'
  | 'click_attach_poll'
  | 'click_vote_poll'
  | 'click_end_poll'
  | 'click_close_create_group'
  | 'click_curation_group'
  | 'click_curation_group_home'
  | 'update_group_meetup_attendance'
  | 'click_update_group_meetup_attendance'
  | 'click_curation_group_create_button'
  | 'click_curation_group_filter'
  | 'finish_create_group'
  | 'click_finish_create_group' // 앱스플라이어용
  | 'click_go_to_publish_group'
  | 'click_onboarding_mission_button'
  | 'click_join_group_dialog'
  | 'click_guide_book_page'
  | 'click_group_meetup_like_button'
  | 'click_liked_group_meetup_list_item'
  | 'click_shortcut_setting_icon'
  | 'click_1on1_chat_button'
  | 'click_cancel_1on1_chat_request_button'
  | 'click_request_1on1_chat_button'
  | 'click_submit_request_1on1_chat_button'
  | 'click_reject_1on1_chat_request_button'
  | 'click_accept_1on1_chat_request_button'
  | 'click_group_meetup_join_button'
  | 'click_edit_permission'
  | 'click_integrated_group_profile_complete'
  | 'click_integrated_group_profile_icon'
  | 'click_group_meetup_member_list_more_button'
  | 'click_group_meetup_review_post_button'
  | 'click_poi_detail'
  | 'click_search_poi_page'
  | 'update_group_meetup_pois'
  | 'click_group_home_curation_item'
  | 'click_group_home_curation_more'
  | 'click_setting_on_subnickname'
  | 'click_member_edit_subnickname'
  | 'click_group_filter'
  | 'click_create_meetup_post' // 앱스플라이어용
  | 'click_first_join_group' // 앱스플라이어용
  | 'click_finish_first_create_group' // 앱스플라이어용
  | 'click_edit_description_popular_group_item'
  | 'click_edit_description_popular_group_refresh'
  | 'click_not_interested_post'
  | 'click_revert_not_interested_post'
  | 'click_group_member_request_approve_item'
  | 'click_group_member_request_reject_item'
  | 'click_lift_group_range_restrictions'
  | 'click_just_started_group_more'
  | 'click_just_started_group'
  | 'click_just_started_refresh'
  | 'click_notification_switch'
  | 'click_notification_turn_on_button'
  | 'click_notification_suggestion_snackbar'
  | 'click_notification_off_suggestion_snackbar'
  | 'click_close_create_talk_room'
  | 'click_continuation_of_create_group' // 기존 작성하던 모임 생성 데이터 불러올지 여부
  | 'click_search_tab'
  | 'click_application_rejected_bottom_sheet_close'
  | 'click_application_reject_with_comment_submit'
  | 'click_application_reject_with_comment_close'
  | 'click_application_reject_with_comment_menu'
  | 'click_application_reject_menu'
  | 'click_watch_group'
  | 'click_verification_from_superhost_update'
  | 'click_modify_group_info'
  | 'click_change_group_info_image'
  | 'click_recommend_similar_group_list_item' // 비슷한 모임 추천 리스트 아이템 클릭
  | 'click_recommend_similar_group_more_button' // 비슷한 모임 추천 리스트 더보기 버튼 클릭
  | 'click_user_profile_image'
  | 'click_not_forbidden_word_group_bottom_sheet_close'
  | 'click_do_not_forbidden_word_group_bottom_sheet_confirm'
  | 'click_sidebar'
  | 'click_chat'
  | 'click_share'
  | 'click_more'
  | 'click_sidebar_my_profile'
  | 'click_sidebar_album'
  | 'click_sidebar_members'
  | 'click_sidebar_settings'
  | 'click_sidebar_home'
  | 'click_sidebar_meetup'
  | 'click_sidebar_notices'
  | 'click_sidebar_board_settings'
  | 'click_sidebar_add_board'
  | 'click_sidebar_report'
  | 'click_sidebar_share'
  | 'click_sidebar_guide_book'
  | 'click_sidebar_board_category'
  | 'click_sidebar_mission_banner'
  | 'click_sidebar_level_banner'
  | 'click_waiting_member_callout'
  | 'click_write_input_placeholders'
  | 'click_notice_item'
  | 'click_unfold_notices'
  | 'click_board_category_chip'
  | 'click_album_image'
  | 'click_member_profile'
  | 'click_post_detail'
  | 'click_post_meetup_detail'
  | 'click_see_more_notices'
  | 'click_see_more_albums'
  | 'click_see_more_members'
  | 'click_see_more_meetups'
  | 'click_see_more_meetup_reviews'
  | 'click_see_more_feeds'
  | 'click_setting_region_range' // 모집범위 설정 완료
  | 'click_create_sns_tag'
  | 'click_edit_sns_tag'
  | 'click_delete_sns_tag'
  | 'click_move_external_sns'
  | 'click_hide_review_from_other'
  | 'click_show_review_from_other'
  | 'click_show_demography_banner'
  | 'click_post_shortcut_banner'
  | 'click_post_not_member_accessory_group_detail'
  | 'click_post_not_member_accessory_join_button'
  | 'click_home_banner_notice'
  | 'click_share_content_to_target' // 채팅방에 게시글 공유
  | 'click_share_external' // 외부 공유 옵션 클릭 - 링크복사, 브릿지 외부 공유
  | 'click_pay_transfer_direct_chat_button'
  | 'click_pay_transfer_direct_success'
  | 'click_pay_transfer_direct_show_update_alert'
  | 'click_home_banner_notice'
  | 'click_hero_slider'
  | 'click_hero_slider_gallery'
  | 'click_hero_nudge_slider'
  | 'click_pin_the_image_setting_page'
  | 'click_pin_the_image_album_page'
  | 'click_done_album_pin_page'
  | 'click_imageviewer_see_the_post'
  | 'click_imageviewer_pin_the_post'
  | 'click_imageviewer_unpin_the_post'
  | 'click_group_detail_shortcut_menu'
  | 'click_group_photo_event_page_sorting_button'
  | 'click_group_photo_event_page_photo_detail'
  | 'click_like_not_member_accessory_group_detail'
  | 'click_group_detail_setting_menu'
  | 'click_host_profile' //미가입자뷰 - 모임장 프로필 클릭
  | 'click_mention_user' // 멘션 클릭
  | 'click_group_meetup_comment_more'
  | 'click_group_meetup_group_information'
  | 'click_group_post_video_button'
  | 'click_post_video_play'
  | 'click_album_item'
  | 'click_mediaviewer_contents'
  | 'click_remove_video'
  | 'click_runninggrowth_onboarding_mission_button'
  | 'click_go_to_group_level'
  | 'click_bot_setting'
  | 'click_bot_message_update'
  | 'click_invite_member_external'
  | 'click_level_mission_item' //레벨업 미션 배너 아이템 클릭
  | 'click_move_to_level_detail' //레벨업 상세 이동 버튼 클릭
  | 'click_level_detail_policy_mission_badge'
  | 'click_level_detail_large_badge'
  | 'click_recommend_category'
  | 'click_recommend_category_more'
  | 'click_form_complete_kid_profile'
  | 'click_add_kid_profile'
  | 'click_add_complete_kid_profile'
  | 'click_leaderboard_more_home_section'
  | 'click_leaderboard_item_home_section'
  | 'click_leaderboard_item_leaderboard_page'
  | 'click_leaderboard_last_week_banner'
  | 'click_current_rank_banner_level_page';

type ActionMeetupEventType =
  | 'click_meetup_item'
  | 'click_write_group_meetup_review_callout'
  | 'click_submit_group_meetup_review'
  | 'click_submit_group_meetup_positive_review'
  | 'click_submit_group_meetup_negative_review'
  | 'click_write_additional_negative_group_meetup_review';

type ImpressionEventType =
  | 'impression_my_group_feed_load_more_container'
  | 'impression_3_my_group_feed_item'
  | 'impresssion_unregistered_group_load_more_container'
  | 'impression_10_unregistered_group_item'
  | 'impression_10_unregistered_meetup_item'
  | 'impresssion_unregistered_meetup_load_more_container'
  | 'impression_notification_suggestion_snackbar'
  | 'impression_notification_off_suggestion_snackbar'
  | 'impression_3_group_feed_item'
  | 'impression_leaderboard_home_section';

export type PostRelatedEvents = 'click_write_post';

// TODO: refferer 목적으로 사용된다면 제거할 것. 더이상 필요 없음 'previousHistoryPageName' 를 통해서 보면 됨
export enum GroupRoutes {
  HOME = 'home',
  GROUP_DETAIL = 'group_detail',
  POST_DETAIL = 'post_detail',
  MEMBER_LIST = 'member_list',
  CREATE_PHASE_1 = 'create_phase1',
  CREATE_PHASE_2 = 'create_phase2',
  FINISH_CREATE = 'finish_create',
}
