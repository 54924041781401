import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconPhotoSeveralFill } from '@seed-design/icon';

import * as s from './index.css';

type Props = {
  text: string;
  onClick?: () => void;
};

export const PhotoContestBreadcrumbs = ({ text, onClick }: Props) => {
  return (
    <div className={s.wrapper} onClick={onClick}>
      <div className={s.iconWrapper}>
        <IconPhotoSeveralFill size={18} color={vars.$semantic.color.accent} />
      </div>

      <Typography
        as="caption"
        typography="caption1Regular"
        color="blue950"
        className={s.BreadcrumbsText}
      >
        {text}
      </Typography>
    </div>
  );
};
