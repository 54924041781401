import { motion } from 'framer-motion';
import { forwardRef, MouseEventHandler } from 'react';

import { useHideBody } from '../../hooks/useHideBody';
import Portal from '../Portal';
import * as s from './index.css';

interface Props {
  isOpen: boolean;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
  dimDuration?: number;
  dimOpacity?: number;
}

export const Overlay = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, onDimClose, dimDuration = 0.15, dimOpacity = 1 }, ref) => {
    const { bind } = useHideBody(isOpen);

    return isOpen ? (
      <Portal>
        <div {...bind} className={s.wrapper} ref={ref}>
          <motion.div
            className={s.dim}
            initial={{ opacity: 0 }}
            animate={{
              opacity: dimOpacity,
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: dimDuration,
            }}
            onClick={(event: { preventDefault: () => void; stopPropagation: () => void }) => {
              event.preventDefault();
              event.stopPropagation();

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onDimClose?.();
            }}
          />
        </div>
      </Portal>
    ) : (
      <></>
    );
  }
);
