import { groupClient } from '@community-group/api';

import { ImageSlider } from '@/components/common/base/slider/ImageSlider';

interface Props {
  images: groupClient.model.Image[];
}

export const CommentImageSlider = ({ images }: Props) => {
  if (images.length <= 0) {
    return <></>;
  }

  const minHeight = calcMinHeights(images);

  return (
    <ImageSlider defaultHeight={minHeight} images={images} style={{ borderRadius: '0.375rem' }} />
  );
};

const COMMENT_WIDTH = 295;
const MAX_HEIGHT = 260;
const MIN_HEIGHT = 140;
const calcMinHeights = (images: groupClient.model.Image[]): number => {
  let currentMinHeight = MAX_HEIGHT;

  images.forEach((image) => {
    const displayRatio = image.width / COMMENT_WIDTH;
    const calcRatioHeight = image.height / displayRatio;

    if (calcRatioHeight > currentMinHeight) {
      return;
    }

    if (calcRatioHeight < MIN_HEIGHT) {
      currentMinHeight = MIN_HEIGHT;
      return;
    }

    if (calcRatioHeight < currentMinHeight) {
      currentMinHeight = calcRatioHeight;
    }
  });

  // 소수점 이슈 : https://daangn.slack.com/archives/C0336FFHA8L/p1715936410762929
  return Math.floor(currentMinHeight);
};
