import { makePlaceSearchClient } from '@daangn/place-search-sdk';

import { useBridge } from '@/contexts/Bridge';

export const usePlaceSearchClient = () => {
  const { bridge } = useBridge();
  const client = makePlaceSearchClient(bridge, {
    isProd: globalThis.appConfig.appEnv === 'production',
  });

  const openSearchPoiPage = ({
    resultType = 'LOCATION_EXIST',
    keywords,
  }: {
    resultType?: 'ALL' | 'LOCATION_EXIST';
    keywords?: string[];
  }) => {
    client.openWebView({
      funnelFrom: 'GROUP',
      resultType: resultType,
      keywords: keywords ? encodeURIComponent(keywords.join(',')) : '',
    });
  };

  return {
    openSearchPoiPage,
  };
};
