import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import * as s from './EditDescriptionItemAccordion.css';

type Props = {
  previewSection: React.ReactNode;
  children: React.ReactNode;
  addPaddingBottom?: boolean;
  callback: (state: 'open' | 'close') => void;
};

export const EditDescriptionItemAccordion = ({
  previewSection,
  children,
  addPaddingBottom,
  callback,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={clsx('accordion-wrapper', open ? 'open' : 'close', s.Wrapper)}>
      <header
        className={clsx('accordion-header', open ? 'open' : 'close', s.Header)}
        onClick={() => {
          callback(open ? 'close' : 'open');
          setOpen(!open);
        }}
      >
        {previewSection}
      </header>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto', opacity: 1 },
              collapsed: { height: 0, opacity: 0 },
            }}
            transition={{ duration: 0.15, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className={clsx('accordion-content', s.Content)}>{children}</div>
            {addPaddingBottom && (
              <div
                style={{
                  width: '100%',
                  height: '32px',
                  backgroundColor: vars.$semantic.color.paperDefault,
                }}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
