import { getPostCommentsPath } from '@/api/hooks/useGetComments';
import { usePutComment } from '@/api/hooks/usePutComment';
import { queryClient } from '@/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

type Params = {
  groupId: string;
  postId: string;
};

export const useHandlePutComment = ({ groupId, postId }: Params) => {
  const handleErrorWithToast = useHandleErrorWithToast();
  return usePutComment({
    relatedContentType: 'post',
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries([getPostCommentsPath(groupId, postId)]);
    },
  });
};
