export const SafeAreaBottom = () => {
  return (
    <div
      style={{
        paddingBottom: 'env(safe-area-inset-bottom)',
        width: '100%',
      }}
    />
  );
};
