import { groupClient } from '@community-group/api';
import { GroupMemberStateEnum } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { GROUP_URL } from '../base/group';
import { queryClient } from '../instance';
import { memberListResponse } from '../mock/memberList';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<{ groupId: number; userId: number }>,
  Error,
  { id?: string; userId?: string }
>;

export const useDeleteMember = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const deleteMember = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdDelete({
    axios: fetchInstance,
  });

  const deleteMemberFp = ({
    groupId,
    userId,
    memberState = 'removed',
  }: {
    groupId: string;
    userId: string;
    memberState?: GroupMemberStateEnum;
  }) => {
    return deleteMember(
      parseInt(groupId),
      parseInt(userId),
      memberState as 'removed' | 'restricted'
    );
  };

  const handleSuccess: typeof onSuccess = (data, variables, context) => {
    queryClient.setQueryData<typeof memberListResponse | undefined>(
      [`${GROUP_URL}/${variables.id}/members`],
      (prevData) => {
        if (prevData) {
          return {
            ...prevData,
            members: prevData?.members?.filter((member) => member.id !== data.data?.userId),
          };
        }
      }
    );
    onSuccess?.(data, variables, context);
  };

  return useThrottleMutation(deleteMemberFp, {
    onError,
    onSuccess: handleSuccess,
  });
};
