import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getJustStartedGroupListPath = () => `${GROUP_URL}/summary/just-started-group`;

type Props = {
  limit?: number;
};

export const useGetJustStartedGroupList = ({ limit = 30 }: Props) => {
  const fetchInstance = useFetchInstance();
  const getJustStartedGroupList = groupClient.api.GroupApi.apiV1GroupsSummaryJustStartedGroupGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isRefetching } =
    useInfiniteQuery(
      [getJustStartedGroupListPath()],
      ({ pageParam = null }) => getJustStartedGroupList(pageParam ? pageParam : undefined, limit),
      {
        suspense: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: 1000000,
        getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      }
    );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    isLoading,
    isRefetching,
    refetch,
  };
};
