import { StackflowPluginHook } from '@stackflow/core';
import { StackflowReactPlugin } from '@stackflow/react';

import { bridge } from '@/contexts/Bridge';

export const previewNotificationPlugin: () => StackflowReactPlugin = () => () => ({
  key: 'previewNotification',
  onInit: handleInit,
  onPushed: handleChangeActivity,
  onPopped: handleChangeActivity,
  onReplaced: handleChangeActivity,
});

const handleInit: StackflowPluginHook = () => {
  if (import.meta.env.SSR) return;

  const currentHost = window.location.host;

  // preview domain 체크
  if (currentHost.includes('preview-')) {
    console.log('preview 환경: ', currentHost);
    bridge.openToast({
      toast: {
        body: '현재 프리뷰 환경에서 보고 있어요.',
      },
    });
  }
};

const handleChangeActivity = () => {
  const currentHost = window.location.host;
  // preview domain 체크
  if (currentHost.includes('preview-')) {
    console.log('preview 환경: ', currentHost);
    bridge.openToast({
      toast: {
        body: '현재 프리뷰 환경에서 보고 있어요',
      },
    });
  }
};
