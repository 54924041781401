export const getFormFieldQuerySelector = (name: string): HTMLElement | undefined =>
  document.querySelector(`[data-field-name=${name}]`) as HTMLElement;

const openListItem = (name: string) => {
  const element = getFormFieldQuerySelector(name);

  if (element?.dataset.fieldToggle === 'false') {
    element?.click();
  }
};

const closeListItem = (name: string) => {
  const element = getFormFieldQuerySelector(name);

  if (element?.dataset.fieldToggle === 'true') {
    element?.click();
  }
};

export const openListItemFormFieldQuerySelector = (name: string | string[]) => {
  if (Array.isArray(name)) {
    name.forEach((item) => openListItem(item));
  } else {
    openListItem(name);
  }
};

export const closeListItemFormFieldQuerySelector = (name: string | string[]) => {
  if (Array.isArray(name)) {
    name.forEach((item) => closeListItem(item));
  } else {
    closeListItem(name);
  }
};
