import { Image, Spacing, Typography, VerticalSpacing } from '@community-group/components';

import * as s from './ParentingGroupKidProfileDescription.css';

const ParentingGroupKidProfileDescription = () => {
  return (
    <>
      <Typography typography="subtitle1Bold" color="gray900">
        왜 아이 정보가 필요한가요?
      </Typography>
      <Spacing size={16} />
      <div className={s.TextContainer}>
        <div className={s.TextNumberWrapper}>
          <Typography typography="caption1Bold" color="gray700">
            1
          </Typography>
        </div>
        <VerticalSpacing size={8} />
        <Typography typography="bodyL1Regular" color="gray900">
          아이 생년별 채팅방에 입장해요.
        </Typography>
      </div>
      <Spacing size={12} />
      <Image
        src="https://assetstorage.krrt.io/1107942635587460566/8053340c-2b93-469a-b3f3-125c8f2ab8f9/width=1372,height=640.png"
        className={s.Image}
      />
      <Spacing size={24} />
      <div className={s.TextContainer}>
        <div className={s.TextNumberWrapper}>
          <Typography typography="caption1Bold" color="gray700">
            2
          </Typography>
        </div>
        <VerticalSpacing size={8} />
        <Typography typography="bodyL1Regular" color="gray900">
          아이 100일, 첫 돌 등 축하 메시지를 보내드려요.
        </Typography>
      </div>
      <Spacing size={12} />
      <Image
        src="https://assetstorage.krrt.io/1107942635587460566/8f254c58-a3bb-464c-9f6d-22cbcc44eca8/width=1372,height=640.png"
        className={s.Image}
      />
      <Spacing size={24} />
      <div className={s.TextContainer}>
        <div className={s.TextNumberWrapper}>
          <Typography typography="caption1Bold" color="gray700">
            3
          </Typography>
        </div>
        <VerticalSpacing size={8} />
        <Typography typography="bodyL1Regular" color="gray900">
          추후 진행될 육아 모임 지원 이벤트의 지원 자격을 얻어요.
        </Typography>
      </div>
      <Spacing size={12} />
      <Image
        src="https://assetstorage.krrt.io/1107942635587460566/0623a715-0a96-4b11-98d5-8c54ba54ce78/width=1372,height=640.png"
        className={s.Image}
      />
    </>
  );
};

export default ParentingGroupKidProfileDescription;
