import { ViewError } from '@community-group/components';

import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './PostDetailError.css';

export const PostDetailError = () => {
  const { groupId } = usePathParams();
  const { replace } = useFlow();

  const handleClick = () => {
    replace('GroupDetailPage', { groupId });
  };

  return (
    <div className={s.Wrapper}>
      <ViewError
        description="삭제된 게시글이에요."
        buttonText="모임으로 가기"
        onClickButton={handleClick}
      />
    </div>
  );
};
