import { INTERNAL_USER_ID_LIST } from '@/constants/user';

import { parseLink } from './link';

const { appEnv, isAppEnvProduction, enabledRedTeam } = globalThis.appConfig;

export const isYoutubeLink = (url: string) => {
  return url.includes('youtu.be') || url.includes('youtube');
};
export const isKakao = (url: string) => {
  return url.includes('open.kakao');
};

export const isShowOpenGraphContent = (text: string) => {
  const url = parseLink(text);
  return url && !isKakao(url);
};

export const isForbiddenWord = (value: string) => {
  if (['주식', '가상화폐', '비트코인'].find((word) => value.includes(word))) {
    return true;
  }
};

export const isLocalhostAppStage = () => appEnv === 'dev';

export const isDevelopmentAppStage = () => appEnv === 'alpha' || appEnv === 'dev';
export const isRedTeamAppStage = () =>
  (appEnv === 'production' && enabledRedTeam) || isDevelopmentAppStage();

export const isExposeInternalUser = (userId: string, isOnlyDevelopmentAppStage = true) => {
  if (isOnlyDevelopmentAppStage && isAppEnvProduction) {
    return false;
  }

  return !!INTERNAL_USER_ID_LIST.find((internalUserId) => internalUserId == userId);
};

const alcoholKeywordList = [
  '술한잔',
  '한잔할',
  '맥주',
  '소주',
  '와인',
  '칵테일',
  '막걸리',
  '위스키',
  '보드카',
  '샴페인',
  '럼주',
  '양주',
  '파티',
  '주점',
  '바텐더',
  '🍺',
  '🍻',
  '🍷',
  '🍸',
  '🍹',
  '🍾',
  '🥂',
  '🥃',
  '🍶',
  '🍵',
  '🍼',
  '🥛',
];

const romanceKeywordList = [
  '연애',
  '소개팅',
  '데이트',
  '사랑',
  '연인',
  '커플',
  '로맨스',
  '짝사랑',
  '고백',
  '썸',
  '여자만',
  '남자만',
  '남자마감',
  '남마',
  '여자환경',
  '여자마감',
];

export const detectAlcoholKeywords = (text) => {
  return alcoholKeywordList.filter((keyword) => text?.includes(keyword));
};
export const detectRomanceKeywords = (text) => {
  return romanceKeywordList.filter((keyword) => text?.includes(keyword));
};
