import { ColorValue, Typography } from '@community-group/components';

import * as s from './style.css';

interface Props {
  onClick: () => void;
  text: string;
  isSelected: boolean;
  defaultColor: ColorValue;
  selectedColor: ColorValue;
  defaultIcon: string;
  selectedIcon: string;
}

const ReviewRatingFieldItem = ({
  onClick,
  text,
  isSelected,
  defaultColor,
  selectedColor,
  defaultIcon,
  selectedIcon,
}: Props) => {
  return (
    <div className={s.FieldItemWrapper} onClick={onClick}>
      <img className={s.FieldItemIcon} src={isSelected ? selectedIcon : defaultIcon} />
      <Typography typography="subtitle1Bold" color={isSelected ? selectedColor : defaultColor}>
        {text}
      </Typography>
    </div>
  );
};

export default ReviewRatingFieldItem;
