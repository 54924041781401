import {
  AsyncBoundary,
  BoxButton,
  TextButton,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import img_publish_group_noti from '@/assets/images/img_publish_group_noti.png';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import useCheckUnCompletedQuest from '../../OnBoard/hooks/useCheckUnCompletedQuest';
import * as s from './NoticePublishGroupBottomSheet.css';

export type NoticePublishGroupBottomSheetParams = Pick<PageParams, 'groupId' | 'from'>;

const NoticePublishGroupBottomSheet: ActivityComponentType<
  NoticePublishGroupBottomSheetParams
> = () => {
  const { pop } = useFlow();
  const { groupId } = usePathParams();

  const [
    closeSuperHostNoticePublishGroupBottomSheetList,
    setCloseSuperHostNoticePublishGroupBottomSheetList,
  ] = useStorage('closeSuperHostNoticePublishGroupBottomSheetList', []);

  const handleCloseBottomSheet = () => {
    setCloseSuperHostNoticePublishGroupBottomSheetList([
      ...(closeSuperHostNoticePublishGroupBottomSheetList ?? []),
      groupId,
    ]);
    pop();
  };

  return (
    <BottomSheet
      onOutsideClick={() => {
        setCloseSuperHostNoticePublishGroupBottomSheetList([
          ...(closeSuperHostNoticePublishGroupBottomSheetList ?? []),
          groupId,
        ]);
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <NoticePublishGroupBottomSheetWrapper onClose={handleCloseBottomSheet} />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const NoticePublishGroupBottomSheetWrapper = ({
  onClose: handleCloseBottomSheet,
}: {
  onClose: () => void;
}) => {
  useEnterTrackEvent({
    event: 'enter_notice_publish_group_bottom_sheet',
  });

  const [, setClosedSuperHostOnboardingBanner] = useStorage(
    'closedSuperHostOnboardingBanner',
    false
  );

  const uncompletedQuestList = useCheckUnCompletedQuest();

  const handleMoveToPublish = () => {
    trackEvent({ event: 'click_go_to_publish_group' });
    setClosedSuperHostOnboardingBanner(false);
    handleCloseBottomSheet();
    setTimeout(() => {
      uncompletedQuestList?.[0]?.onButtonClick();
    }, 200);
  };

  return (
    <div className={s.wrapper}>
      <Typography textAlign="center" as="h3" color="gray900" typography="title2Bold">
        사진과 소개를 채우고
        <br />
        모임을 <span style={{ color: vars.$semantic.color.primary }}>상단에 홍보</span>해보세요
      </Typography>
      <Spacing size={6} />
      <Typography textAlign="center" color="gray700" typography="subtitle1Regular" as="p">
        더 많은 이웃이 우리 모임을 볼 수 있어요.
      </Typography>
      <Spacing size={16} />
      <img style={{ width: '100%' }} src={img_publish_group_noti} />
      <Spacing size={12} />
      <BoxButton size="xlarge" width="100%" onClick={handleMoveToPublish}>
        모임 홍보하기
      </BoxButton>
      <Spacing size={12} />
      <TextButton
        variant="secondary"
        width="100%"
        size="small"
        bold={true}
        onClick={handleCloseBottomSheet}
      >
        다음에 할게요
      </TextButton>
    </div>
  );
};

export default NoticePublishGroupBottomSheet;
