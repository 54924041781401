import { ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useGetCommentEmotions } from '@/api/hooks/useGetCommentEmotions';
import EmotionUserList from '@/components/common/EmotionUserList';
import { useFlow } from '@/stackflow';
import { LayoutWrapper } from '@/stackflow/components/Layout';

import CommentEmotionListForNotMember from './CommentEmotionListForNotMember';

type Props = {
  groupId: number;
  relatedId: number;
  commentId: number;
  relatedContentType: 'post' | 'meetup';
};

const CommentEmotionList = ({ groupId, relatedId, commentId, relatedContentType }: Props) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetCommentEmotions({
    groupId: groupId,
    relatedId: relatedId,
    commentId: commentId,
    relatedContentType,
    joinedUser: true,
  });
  const joinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId: groupId.toString(),
      userId: userId.toString(),
      from: 'like_list',
    });
  };

  return (
    <LayoutWrapper>
      <EmotionUserList
        users={joinedUserList}
        onClickUserProfile={getMoveGroupUserProfileDetailPage}
        shouldFetchNextUsers={Boolean(hasNextPage && !isFetchingNextPage)}
        fetchNextUsers={fetchNextPage}
      />
      {!hasNextPage && (
        <CommentEmotionListForNotMember
          groupId={groupId}
          relatedId={relatedId}
          commentId={commentId}
          relatedContentType={relatedContentType}
          onClickUserProfile={getMoveGroupUserProfileDetailPage}
        />
      )}
    </LayoutWrapper>
  );
};

export default withAsyncBoundary(CommentEmotionList, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
