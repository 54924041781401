import { MultilineTextField } from '@community-group/components';

import * as s from './style.css';

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

export const MemberReviewMessageField = ({ value, onChange }: Props) => {
  return (
    <div className={s.Wrapper}>
      <MultilineTextField
        optionalIndicator="(선택)"
        label="감사 인사 전하기"
        width="100%"
        name="review"
        placeholder="남겨주신 내용은 상대방의 프로필에 공개돼요."
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
