import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { OPEN_GRAPH_URL } from '../base/opengraph';
import { openGraphResponse } from '../mock/openGraph';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetOpenGraph = (url?: string) => {
  const fetchInstance = useFetchInstance();
  const getOpenGraph = async (url?: string) => {
    const { data } = await fetchInstance.get<
      { url: string },
      AxiosResponse<typeof openGraphResponse>
    >(OPEN_GRAPH_URL, {
      params: {
        url: url,
      },
    });

    return data;
  };

  const { data } = useQuery([`${OPEN_GRAPH_URL}?url=${url}`], () => getOpenGraph(url), {
    suspense: true,
    retry: 1,
    useErrorBoundary: false,
    onError: () => {
      // og-image에 대한 에러는 전파하지 않음
      return;
    },
  });

  return { data: data as typeof openGraphResponse };
};
