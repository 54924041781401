import { GroupLevelPresentation } from '@community-group/api/lib/group/models';
import {
  HorizontalSpacing,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconArrowDropDownFill, IconExpandLessFill, IconExpandMoreFill } from '@seed-design/icon';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { GROUP_LEVEL_POLICY_INFORMATION } from '../../utils/levelPolicyInformation';
import * as s from './MaintainLevel.css';
type Props = {
  currentLevel: number;
  forMaintain?: GroupLevelPresentation['forMaintain'];
};

const UNCHECKED_CIRCLE =
  'https://assetstorage.krrt.io/1025317940251365954/c0341d7d-ba99-4320-b602-f5d45e9dd58a/width=60,height=60.png';
const CHECKED_CIRCLE =
  'https://assetstorage.krrt.io/1025317940251365954/9a0bb048-7c87-4d16-afec-7d63bcd600a6/width=60,height=60.png';

function MaintainLevel({ currentLevel, forMaintain }: Props) {
  const [fold, setFold] = React.useState(true);

  if (!forMaintain || !forMaintain.missions?.activityPoint || !forMaintain.nextPeriodExpectation)
    return null;

  const nextPeriodLevelIcon =
    GROUP_LEVEL_POLICY_INFORMATION[forMaintain.nextPeriodExpectation].levelBadge;

  const currentNextPeriodLevelDiff = forMaintain.nextPeriodExpectation - currentLevel;

  // 레벨 유지 미션 성공 여부
  const achieveMaintainMission =
    forMaintain.missions.activityPoint.currentCount >=
    forMaintain.missions.activityPoint.achieveCount;

  const handleFold = () => {
    setFold(!fold);
  };

  return (
    <AnimatePresence>
      <div className={s.Container}>
        <div className={s.HeaderWrapper} onClick={handleFold}>
          <div className={s.LeftWrapper}>
            <img src={nextPeriodLevelIcon} className={s.LevelIcon} />
            <VerticalSpacing size={12} />
            <div className={s.ExpectationWrapper}>
              <Typography typography="subtitle2Regular" color="gray700">
                다음 달 예상 레벨
              </Typography>
              <HorizontalSpacing size={2} />
              <div className={s.LevelTextWrapper}>
                <Typography typography="subtitle2Bold" color="gray800">
                  Lv.{forMaintain.nextPeriodExpectation}
                </Typography>
                {currentNextPeriodLevelDiff < 0 && (
                  <div className={s.Degradation}>
                    <IconArrowDropDownFill size={12} color={vars.$scale.color.red700} />
                    <Typography typography="caption2Regular" color={'red700'}>
                      1
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={s.FoldIconWrapper}>
            {fold ? (
              <IconExpandMoreFill size={20} color={vars.$scale.color.gray600} />
            ) : (
              <IconExpandLessFill size={20} color={vars.$scale.color.gray600} />
            )}
          </div>
        </div>
        <AnimatePresence initial={false}>
          {!fold && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                type: 'just',
              }}
            >
              <div className={s.InnerContentsWrapper}>
                <img
                  src={achieveMaintainMission ? CHECKED_CIRCLE : UNCHECKED_CIRCLE}
                  className={s.AchieveIcon}
                />
                <VerticalSpacing size={12} />
                <div className={s.MaintainTextWrapper}>
                  <Typography typography="caption1Regular" color="gray600">
                    다음 달에도 현재 레벨을 유지하려면
                  </Typography>
                  <VerticalSpacing size={2} />
                  <Typography typography="subtitle2Regular" color="gray900">
                    활동점수 {forMaintain.missions.activityPoint.achieveCount}점 더 모으기
                  </Typography>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Spacing size={20} />
    </AnimatePresence>
  );
}

export default MaintainLevel;
