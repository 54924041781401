import { ParentingGroupKidGenderEnum } from '@community-group/api/lib/group/models';
import { Radio, RadioGroup, Spacing, Typography } from '@community-group/components';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

import { FormInput } from '@/components/common/base/Input/Text';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';
import { validateSchemaWithMessage } from '@/utils/validate/util';

import * as s from './ParentingGroupKidProfileForm.css';

const ParentingGroupKidProfileForm = () => {
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const registerWithType = register as unknown as UseFormRegister<FieldValues>;

  return (
    <div className={s.Container}>
      <div className={s.TextContainer}>
        <Typography typography="label3Bold" color="gray900">
          생년월일
        </Typography>
      </div>
      <Spacing size={12} />
      <FormInput
        type="number"
        name="birthday"
        register={registerWithType}
        placeholder="YYYYMMDD"
        onKeyUpCapture={() => {
          const targetName = 'birthday';
          const result = validateSchemaWithMessage(
            groupMemberProfileFieldSchema.kidProfileBirthday,
            watch(targetName)
          );
          if (!result.success) return;
          clearErrors(targetName);
        }}
        onBlurCapture={() => {
          const targetName = 'birthday';
          const result = validateSchemaWithMessage(
            groupMemberProfileFieldSchema.kidProfileBirthday,
            watch(targetName)
          );

          if (result.success) return;
          setError(targetName, {
            type: 'pattern',
            message: result.message,
          });
        }}
        isInvalid={Boolean(errors?.birthday)}
        errorMessage={errors?.birthday?.message?.toString()}
      />
      <Spacing size={32} />
      <Typography typography="label3Bold" color="gray900">
        성별
      </Typography>
      <Spacing size={12} />
      <RadioGroup
        size="large"
        orientation="horizontal"
        defaultValue={watch('gender')}
        onChange={(value) => setValue('gender', value as ParentingGroupKidGenderEnum)}
      >
        <div className={s.RadioGroup}>
          <Radio value="male" UNSAFE_className={s.RadioButton}>
            남자
          </Radio>
          <Radio value="female" UNSAFE_className={s.RadioButton}>
            여자
          </Radio>
        </div>
      </RadioGroup>
    </div>
  );
};

export default ParentingGroupKidProfileForm;
