import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.BoardCategoryResponse>,
  Error,
  {
    id: number;
    boardCategoryCreateForm?: model.BoardCategoryCreateForm;
  }
>;

export const usePostBoardCategory = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postCreateBoardCategory =
    groupClient.api.PostBoardApi.apiV1GroupsIdPostsBoardCategoriesPost({ axios: fetchInstance });

  const postCreateBoardCategoryWrapperFp = ({
    id,
    boardCategoryCreateForm = {},
  }: {
    id: number;
    boardCategoryCreateForm?: model.BoardCategoryCreateForm;
  }) => {
    return postCreateBoardCategory(id, boardCategoryCreateForm);
  };

  return useThrottleMutation(postCreateBoardCategoryWrapperFp, {
    onError,
    onSuccess,
  });
};
