import { vars } from '@seed-design/design-token';
import React from 'react';

interface Props {
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
}

const CircleContainerIcon = ({ size = 32, onClick }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="16" cy="16" r="16" fill={vars.$scale.color.green500} />
      <path
        d="M16.8177 9.66613C18.3715 8.49988 20.5129 8.90466 21.6008 10.5702C22.6888 12.2358 22.3112 14.5315 20.7575 15.6977L19.9902 16.2736C19.7077 16.4857 19.3184 16.4121 19.1206 16.1093C18.9227 15.8064 18.9914 15.389 19.2739 15.177L20.0411 14.6011C21.0299 13.8589 21.2702 12.398 20.5778 11.3381C19.8855 10.2782 18.5228 10.0206 17.5341 10.7628L15.4881 12.2986C14.4993 13.0407 14.259 14.5016 14.9514 15.5615C15.2363 15.9977 15.6347 16.298 16.0739 16.4487C16.3808 16.5539 16.6443 16.8161 16.667 17.1607C16.6932 17.559 16.3942 17.9021 16.0299 17.8194C15.2082 17.633 14.4481 17.1251 13.9284 16.3294C12.8404 14.6638 13.218 12.3682 14.7718 11.2019L16.8177 9.66613Z"
        fill={vars.$scale.color.gray00}
      />
      <path
        d="M15.1823 22.3339C13.6286 23.5001 11.4871 23.0953 10.3992 21.4298C9.31125 19.7642 9.68885 17.4685 11.2426 16.3023L12.0098 15.7264C12.2923 15.5143 12.6817 15.5879 12.8795 15.8907C13.0773 16.1936 13.0086 16.611 12.7261 16.823L11.9589 17.3989C10.9701 18.1411 10.7299 19.602 11.4222 20.6619C12.1145 21.7218 13.4772 21.9794 14.466 21.2372L16.512 19.7014C17.5007 18.9593 17.741 17.4984 17.0487 16.4385C16.7638 16.0023 16.3653 15.702 15.9261 15.5513C15.6193 15.4461 15.3557 15.1839 15.3331 14.8393C15.3069 14.441 15.6058 14.0979 15.9702 14.1806C16.7918 14.367 17.5519 14.8749 18.0717 15.6706C19.1596 17.3362 18.782 19.6318 17.2283 20.7981L15.1823 22.3339Z"
        fill={vars.$scale.color.gray00}
      />
    </svg>
  );
};

export default CircleContainerIcon;
