import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';

import { getImageDimensions } from '../utils/image';

const UPLOAD_IMAGES_V2_PATH = '/api/v2/resource/images';

export type UploadImageType = {
  width: string;
  id: string;
  height: string;
  small: string;
  medium: string;
  large: string;
};

export type UploadImageRequestData = {
  imageFile: Blob;
};

type UploadImageResponseData = { image: UploadImageType; uploadUrl: string };

type Props = Omit<
  UseMutationOptions<UploadImageType, AxiosError, UploadImageRequestData>,
  'mutationFn'
>;
export const useUploadImage = (props: Props) => {
  const instance = useFetchInstance({});

  const mutation = async ({ imageFile: image }: UploadImageRequestData) => {
    const contentType = image.type;
    const { width, height } = await getImageDimensions(image);

    const { data } = await instance.post<UploadImageResponseData>(UPLOAD_IMAGES_V2_PATH, {
      contentType,
      service: 'community',
      width,
      height,
    });

    const { image: imageInfo, uploadUrl } = data;

    await instance.put(uploadUrl, image, {
      headers: {
        'Content-Type': image.type,
        'x-amz-meta-id': imageInfo.id,
      },
      // 이미지 업로드는 timeout을 없애요.
      timeout: 0,
    });

    return imageInfo;
  };

  return useMutation({
    mutationFn: mutation,
    ...props,
  });
};
