import { AsyncBoundary, Typography, ViewError, ViewLoader } from '@community-group/components';
import { Dispatch, SetStateAction } from 'react';

import { ORDER_SEARCH } from '@/api/base/group';
import { useGetMeetupSearchList } from '@/api/hooks/useGetMeetupSearchList';
import { useGetSearchGroupList } from '@/api/hooks/useGetSearchGroupList';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import PopularityGroupListSection from '../PopularityGroupListSection';
import SearchResultAllFilterMeetup from '../SearchResultAllFilteredMeetup';
import SearchResultList from '../SearchResultList';
import SearchResultEmptyView from '../SearchResultList/EmptyView';

type Props = {
  setCurrentTab: Dispatch<SetStateAction<'all' | 'group' | 'meetup' | 'onlyGroup'>>;
};

const SearchResultAllResult = ({ setCurrentTab }: Props) => {
  const { query = '' } = usePathParams();
  const { referrer = 'community_group.client', queryId } = useQueryParams();

  const { data: meetupSearchResult } = useGetMeetupSearchList({
    order: 'meet_at_asc',
    searchQuery: decodeURIComponent(query),
  });

  const searchFilter = {
    query,
    category: [],
    order: ORDER_SEARCH.RECOMMEND_SCORE_DESC,
    referrer,
    queryId,
  };

  const { data: groupSearchResult } = useGetSearchGroupList(searchFilter);

  const meetupData = meetupSearchResult?.pages.map(({ data }) => data.meetups);
  const meetupFlatten = meetupData?.flat(1) ?? [];

  const groupData = groupSearchResult?.pages.map(({ data }) => data.groups);
  const groupFlatten = groupData?.flat(1) ?? [];

  if (meetupFlatten.length <= 0 && groupFlatten.length <= 0) return <SearchResultEmptyView />;

  return (
    <div>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <SearchResultAllFilterMeetup setCurrentTab={setCurrentTab} />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <Typography
          typography="subtitle1Bold"
          style={{
            padding: '1.25rem 1rem 0.5rem 1rem',
          }}
        >
          모임
        </Typography>
        <SearchResultList />
        <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
          <PopularityGroupListSection />
        </AsyncBoundary>
      </AsyncBoundary>
    </div>
  );
};

export default SearchResultAllResult;
