import { AsyncBoundary, useKeyboardSize, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useState } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetMemberList } from '@/api/hooks/useGetMemberList';
import { Container } from '@/components/common/Container';
import { LoadMoreMemberListContainer } from '@/components/common/LoadMoreContainer';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { isMember } from '@/utils/role';

import { MemberListAppScreen } from '../components/MemberListAppScreen';
import MemberProfileSection from '../components/MemberProfileSection';

export type GroupMemberListPageParams = Pick<PageParams, 'groupId'>;

const GroupMemberListPage: ActivityComponentType<GroupMemberListPageParams> = () => {
  return (
    <MemberListAppScreen>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <MemberListWrapper />
      </AsyncBoundary>
    </MemberListAppScreen>
  );
};

const MemberListWrapper = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const { groupId } = usePathParams();

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { data, hasNextPage } = useGetMemberList({
    groupId,
    options: {
      onError: (error) =>
        handleErrorWithThrowErrorStatusPage({
          error,
          serviceType: 'group',
          groupId,
        }),
    },
  });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];
  const filteredMemberList = flattenMemberList.filter(({ nickname }) => {
    if (!searchValue) return true;

    return nickname.toLowerCase().includes(searchValue.toLowerCase());
  });

  const { data: me } = useGetGroupMe(groupId);

  useEnterTrackEvent({
    event: 'enter_member_list',
    params: { groupId },
    sample: true,
  });

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
    });
  };

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: isFocusedSearchBar
          ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
          : 'env(safe-area-inset-bottom)',
      }}
    >
      {isMember(me?.currentUser.role) && (
        <>
          <div style={{ paddingTop: '0.5rem' }}>
            <MemberSearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsFocusedSearchBar={setIsFocusedSearchBar}
              placeholder="닉네임을 검색해보세요"
            />
          </div>
        </>
      )}

      <Container paddingX={0} paddingY={0}>
        <ul>
          {filteredMemberList.map((member) => (
            <MemberProfileSection
              key={member.id}
              user={member}
              moveGroupUserProfileDetailPage={getMoveGroupUserProfileDetailPage(member.id)}
            />
          ))}
        </ul>
        {hasNextPage && <LoadMoreMemberListContainer />}
      </Container>
    </div>
  );
};

export default GroupMemberListPage;
