import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import React, { useEffect, useRef, useState } from 'react';

import * as s from './GroupDescription.css';

interface Props {
  description: GroupDetailPresentation['description'];
}

const MAX_LINE = 8;
const LINE_HEIGHT = 24;

const GroupDescription = ({ description = '' }: Props) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const current = textRef.current;
    if (!current) return;

    // 실제 텍스트 높이와 라인 하이트를 바탕으로 줄 수 계산
    const computedStyle = window.getComputedStyle(current);
    const actualLineHeight = parseInt(computedStyle.lineHeight, 10) || LINE_HEIGHT;
    const actualLines = Math.ceil(current.scrollHeight / actualLineHeight);
    setIsOverflow(actualLines > MAX_LINE);
  }, []);

  const handleShowMore = () => {
    setShowMore((value) => !value);
  };

  return (
    <div className={s.Wrapper}>
      <Typography
        as="p"
        ref={textRef}
        color="gray900"
        wordBreak="break-all"
        typography="bodyL1Regular"
        style={{
          maxHeight: showMore ? '100%' : `${LINE_HEIGHT * MAX_LINE}px`,
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
        }}
      >
        {description}
      </Typography>
      {isOverflow && (
        <Typography typography="bodyL1Regular" color="gray600" onClick={handleShowMore}>
          {showMore ? '...접기' : '...더보기'}
        </Typography>
      )}
    </div>
  );
};

export default GroupDescription;
