import { useXproperty } from './useXproperty';

// !프로덕션에 정의했는지 확인하기
// 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_web_dates&country=KR
// 알파 : https://xproperty.alpha.kr.wekarrot.net/admin/edit?pkey=community_group_web_dates&country=KR

// 사용중인 피쳐플래그 타입
export type ReleaseDatesType = {
  parentingGroupKidProfile?: string;
};

type DateKeys = keyof ReleaseDatesType;

export const useReleaseDate = <T extends DateKeys>(dateKey: T): ReleaseDatesType[T] | false => {
  const xProperty = useXproperty();

  return xProperty?.communityGroupWebDates?.[dateKey] ?? false;
};
