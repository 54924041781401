import { useMemo } from 'react';

import { useGetCheckOpenRegion } from './../api/hooks/useGetCheckOpenRegion';
import { useGetMe } from './../api/hooks/useGetMe';

export const useCurrentRegionCheckOpend = () => {
  const { data: userInfo } = useGetMe();

  const { data, isFetching } = useGetCheckOpenRegion(userInfo.regionCheckIns.regionId);
  const regionCertified = useMemo(() => userInfo.regionCheckIns.certified, [isFetching]);

  return {
    data,
    userInfo,
    isOpenRegion: data?.data?.isOpenRegion,
    regionCertified,
  };
};
