import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from '../instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.TalkRoomMemberResponse>,
  Error,
  { id: number; roomId: number; talkRoomJoinForm: model.TalkRoomJoinForm }
>;

export const usePostJoinRoom = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postJoinRoom = groupClient.api.TalkRoomMemberApi.apiV1GroupsIdRoomsRoomIdJoinPost({
    axios: fetchInstance,
  });

  const postJoinRoomWrapperFp = ({
    id,
    roomId,
    talkRoomJoinForm,
  }: {
    id: number;
    roomId: number;
    talkRoomJoinForm: model.TalkRoomJoinForm;
  }) => {
    return postJoinRoom(id, roomId, talkRoomJoinForm);
  };

  return useThrottleMutation(postJoinRoomWrapperFp, {
    onError,
    onSuccess,
  });
};
