import { Skeleton } from '@community-group/components';

import { Container } from '@/components/common/Container';

import * as s from './CurationFilterSkeleton.css';

const CurationFilterSkeleton = () => (
  <div className={s.Wrapper}>
    <Container className={s.CategoryContainer} paddingY={0} paddingX={16}>
      {Array.from({ length: 8 }).map((item, index) => (
        <div className={s.CategoryButton} key={index}>
          <Skeleton width="24px" height="20px" borderRadius="32px" />
        </div>
      ))}
    </Container>
  </div>
);

export default CurationFilterSkeleton;
