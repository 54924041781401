import { GroupMeetupPoiPresentation } from '@community-group/api/lib/group/models';
import { withAsyncBoundary } from '@community-group/components';

import { PoiMapCard } from '@/components/common/PoiMapCard';
import { useCurrentPosition } from '@/hooks/useCurrentPosition';
import { trackEvent } from '@/utils/analytics';
import { openPoiDetailPage } from '@/utils/link';

type Props = {
  place: GroupMeetupPoiPresentation;
};

const GroupMeetupDetailPlace = ({ place }: Props) => {
  const { data: geolocationData } = useCurrentPosition();

  const geolocationPermission =
    geolocationData?.geolocation?.geolocationAccessResponse.granted ?? false;

  return (
    <PoiMapCard
      key={place.id}
      poi={place}
      currentPosition={
        geolocationPermission ? geolocationData?.geolocation?.currentPosition?.position : undefined
      }
      onCardClick={() => {
        trackEvent({ event: 'click_poi_detail', sample: true });

        openPoiDetailPage({
          id: place.id,
          type: place.type,
        });
      }}
    />
  );
};

export default withAsyncBoundary(GroupMeetupDetailPlace, {});
