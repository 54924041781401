import { JoinableRegionRangeEnum } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  getBoundsByCoordinates,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import {
  initialGroupRegionRanges,
  REGION_RANGE_NAME,
  useGetGroupRegionRanges,
} from '@/api/hooks/useGetGroupRegionRanges';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { Map } from '@/components/common/Map';
import { getPolygonCoordinates } from '@/components/common/Map/components/Ploygon';
import { Coordinates, estimateZoomLevel } from '@/components/common/Map/utils/polygon';
import { SelectBoxRadio } from '@/components/common/SelectBox/SelectBoxRadio';
import { useUserConfig } from '@/contexts/UserConfig';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import * as s from './index.css';

export type GroupNewSetGroupRegionRangePageParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'maximumMemberCount'
  | 'minAgeTag'
  | 'maxAgeTag'
  | 'createType'
  | 'from'
  | 'description'
  | 'joinGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetGroupRegionRangePage: ActivityComponentType<
  GroupNewSetGroupRegionRangePageParams
> = () => {
  const {
    from,
    createGroupFlowType,
    ...postNewGroupPayload
  }: GroupNewSetGroupRegionRangePageParams = useQueryParams();
  const { push, pop } = useFlow();

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'regionRange',
      createGroupFlowType: createGroupFlowType ?? 'normal',
    },
  });

  const { userConfig } = useUserConfig();
  const currentRegionId = userConfig.regionId ?? 0;
  const { data: regionData } = useGetGroupRegionRanges(currentRegionId);
  const { joinableRegionRanges, regionInfo } = regionData ?? initialGroupRegionRanges();

  const { handleSubmit, watch, setValue } = useCreateForm({});

  const selectedRegionRangeFieldValue = watch('joinableRegionRange');

  const { nextPageName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    push(nextPageName, {
      ...postNewGroupPayload,
      from,
      joinableRegionRange: selectedRegionRangeFieldValue,
      createGroupFlowType,
    });
  });

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetGroupRegionRangePage')}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            buttonDisabled={selectedRegionRangeFieldValue === undefined}
            onBack={pop}
            onSubmit={onSubmit}
          />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <div className={s.wrapper}>
          <Typography typography="title1Bold">
            이웃들을 모집할
            <br />
            동네를 설정해주세요
          </Typography>
          <Spacing size={16} />
          {joinableRegionRanges.map(({ key, regionPolygons: originRegionPolygons, count }) => {
            if (key === 'unlimited') {
              return (
                <SelectBoxRadio
                  style={{
                    marginBottom: '0.75rem',
                  }}
                  key={key}
                  label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
                  description="어디서나 가입할 수 있어요."
                  selected={selectedRegionRangeFieldValue === key}
                  onClick={() => {
                    setValue('joinableRegionRange', key);
                  }}
                />
              );
            }

            // lat: 위도, lng: 경도, 서버 데이터에서는 [경도(lng), 위도(lat)] 순서로 오기 때문에 순서를 바꿔줍니다.
            const regionPolygons = originRegionPolygons.map(([lng, lat]) => [lat, lng]);

            const mappedPolygonData = getPolygonCoordinates(
              regionInfo.name,
              regionPolygons as Coordinates
            );

            // TODO: Region이 1개 이하일 경우에는 Map을 보여주지 않아요. Polygon 그리기가 애매한 이슈가 있어요. (참고: https://daangn.slack.com/archives/C017YMSBBDX/p1694585616574719)
            const hasRegionPolygon = regionPolygons.length > 1 && mappedPolygonData;
            const hasManyRegions = count > 1;

            const zoomLevel = estimateZoomLevel(regionPolygons as Coordinates, {
              width: 324,
              height: 240,
            });

            const description = hasManyRegions
              ? `${regionInfo.name}과 근처 동네 ${count - 1}개`
              : `${regionInfo.name}`;

            return (
              <SelectBoxRadio
                style={{
                  marginBottom: '0.75rem',
                }}
                key={key}
                label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
                description={description}
                selected={selectedRegionRangeFieldValue === key}
                onClick={() => {
                  setValue('joinableRegionRange', key);
                }}
              >
                {hasRegionPolygon && (
                  <div className={s.mapWrapper}>
                    <Map
                      className={s.mapStyle}
                      interactive
                      initialMapState={{
                        zoom: zoomLevel,
                        bounds: getBoundsByCoordinates([[regionPolygons]], {
                          container: { clientHeight: 240, clientWidth: 324 },
                        }),
                      }}
                      module={false}
                      onLoad={({ target }) => {
                        target.addPolygonLayer(mappedPolygonData, {
                          removeOthers: true,
                          moveToCenter: false,
                          style: {
                            fillPaint: {
                              'fill-color': '#FF7E36',
                              'fill-opacity': 0.15,
                            },
                            linePaint: {
                              'line-color': '#FF7E36',
                              'line-width': 1,
                            },
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </SelectBoxRadio>
            );
          })}
        </div>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetGroupRegionRangePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
