import { useEffect } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';

// 유저가 사전신청 마케팅 페이지에서 넘어왔는지 확인해서 저장하는 훅
const useCheckUserFrom10월운동MarketingPromotion = () => {
  const { from = '' } = useQueryParams();

  // 해당 진입 세션에서만 사전신청 가능해야해서 store에 저장
  const { set10월운동MarketingJoinable, set10월운동MarketingCreate } = useStore();

  const isCheck10월운동 = from === 'create_10월운동_marketing';

  useEffect(() => {
    if (from === 'join_10월운동_marketing') {
      set10월운동MarketingJoinable(true);
    }

    if (from === 'create_10월운동_marketing') {
      set10월운동MarketingCreate(true);
    }
  }, [from, set10월운동MarketingCreate, set10월운동MarketingJoinable]);

  return {
    isCheck10월운동,
  };
};

export default useCheckUserFrom10월운동MarketingPromotion;
