import { FC, SVGProps } from 'react';

import { PinDefault } from '../assets/PinDefault';
import { PinSelected } from '../assets/PinSelected';

export type DefaultMarkerVariation = 'pin-single-type' | 'pin-multi-type' | 'pin-selected-type';

type DefaultPinMapper = Record<
  DefaultMarkerVariation,
  {
    Component: FC<SVGProps<SVGSVGElement>>;
    size: number;
    offset: [number, number];
  }
>;
export const pinMapper: DefaultPinMapper = {
  'pin-single-type': {
    Component: PinSelected,
    size: 36,
    offset: [0, 3.6],
  },
  'pin-multi-type': { Component: PinDefault, size: 36, offset: [0, 3.6] },
  'pin-selected-type': { Component: PinSelected, size: 62, offset: [0, 6.2] },
};
