import { Bridge } from '@/contexts/Bridge';
import { AMPLITUDE_SAMPLE_RATE, isSampledUser } from '@/utils/analytics/userSampling';
import { makeStorage } from '@/utils/storage';

import { AppInfo, mockAppInfo } from './type';

/*
 * @description 해당 메서드는 SSR, 초기 렌더링 시 값이 보장되지 않아요. 가능한 useUserConfig를 사용해주세요.
 */
export const UNSAFE_appInfo = makeStorage<AppInfo>();

export const initAppInfo = async (bridge: Bridge) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (globalThis?.appConfig?.appEnv === 'dev' || bridge.driver.getCurrentEnvironment() === 'Web') {
    UNSAFE_appInfo.setData(mockAppInfo);
    return;
  }

  const [deviceInfo, regionInfo, userInfo] = await Promise.all([
    bridge.getAppInfo({}),
    bridge.getRegionInfo({}),
    bridge.getUserInfo({}),
  ]);

  const currentUser = {
    ...userInfo.info.user,
    sample: isSampledUser(userInfo.info.user.id, AMPLITUDE_SAMPLE_RATE),
  };

  const currentAppInfo = {
    app: deviceInfo.info.app,
    region: regionInfo.info.region,
    user: currentUser,
  } as AppInfo;

  UNSAFE_appInfo.setData(currentAppInfo);
};
