import {
  AsyncBoundary,
  CoveredLazyLoadImage,
  Typography,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { View } from '@/components/common/View';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';

import * as s from './index.css';

const GroupNotificationSelectPage: ActivityComponentType = () => {
  return (
    <AppScreen appBar={{ title: '모임별 알림' }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <GroupNotificationSelect />
        </AsyncBoundary>
      </View>
    </AppScreen>
  );
};

export default GroupNotificationSelectPage;

const GroupNotificationSelect = () => {
  const { data } = useGetMyGroupList();
  const { push } = useFlow();

  return (
    <div className={s.wrapper}>
      <ul className={s.ul}>
        {data?.groups.map((group) => {
          return (
            <li
              className={s.li}
              key={group?.id}
              onClick={() => {
                push('GroupSettingNotificationPage', {
                  groupId: group?.id.toString(),
                });
              }}
            >
              <CoveredLazyLoadImage
                src={group?.profileImage.small}
                width={40}
                height={40}
                isLazy={false}
                style={{
                  borderRadius: '0.375rem',
                }}
              />
              <Typography typography="bodyM1Regular" color="gray900">
                {group?.name}
              </Typography>
            </li>
          );
        })}
        <div className={s.safeareaBottom} />
      </ul>
    </div>
  );
};
