import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  groupId: number;
  meetupId: number;
  limit?: number;
  joinedUser?: boolean;
};

export const getGroupMeetupEmotionListPath = (groupId: number, meetupId: number) =>
  groupClient.api.MeetupApi.getapiV1GroupsIdMeetupsMeetupIdEmotionsGetPath(groupId, meetupId);

export const useGetGroupMeetupEmotionList = ({
  groupId,
  meetupId,
  limit = 30,
  joinedUser,
}: Props) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.MeetupApi.apiV1GroupsIdMeetupsMeetupIdEmotionsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getGroupMeetupEmotionListPath(groupId, meetupId), joinedUser],
    ({ pageParam = undefined }) => {
      const cursor = pageParam === null ? undefined : pageParam;
      return get(groupId, meetupId, cursor, limit, joinedUser);
    },
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
