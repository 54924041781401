import Cookies from 'universal-cookie';
import type VConsole from 'vconsole';

import { COOKIE } from '@/constants/keys';

const RED_TEAM_SELECT_KEY = 'red-team-select';
const RED_TEAM_BUTTON_KEY = 'red-team-submit-button';

export const initRedTeamVConsolePlugin = (vConsoleModule: typeof VConsole) => {
  const redTeamPlugin = new vConsoleModule.VConsolePlugin('RedTeam', 'RedTeam');

  redTeamPlugin.on('renderTab', (callback) => {
    const view = redTeamTemplate;

    callback(view);

    const submitButton = document.getElementById(RED_TEAM_BUTTON_KEY);
    // submitButton click event
    submitButton?.addEventListener('click', () => {
      const select = document.getElementById(RED_TEAM_SELECT_KEY) as HTMLSelectElement;
      const value = select.value;

      if (value === 'on') {
        setRedTeamCookie(true);
      } else {
        setRedTeamCookie(false);
      }

      window.location.reload();
    });
  });

  return redTeamPlugin;
};

const redTeamTemplate = `
  <div style="width: 100%; padding: 8px;">
    <h1 style="padding-bottom: 8px">Red Team</h1>
    <p style="font-size: 16px; line-height: 1.2">
      활성화를 하게되면 RedTeam이 동작하게 돼요.
      <br/>
      레드팀이 켜졌다면 vConsole이 빨간색으로 보여져요.
      <br/>
      <span style="font-size: 12px; font-weight: 700; color: red">※ 레드팀은 운영에서만 동작해요.</span>
    </p>
    <div style="display: flex; align-items: center; padding-top: 24px;">
      <label for=${RED_TEAM_SELECT_KEY} style="font-size: 16px;  font-weight: 700; margin-right: 8px;">레드팀 활성화:</label>
      <select id=${RED_TEAM_SELECT_KEY} style="font-size: 16px; width: 120px; padding: 8px 12px; border-radius: 4px;  margin-right: 8px;">
        <option value="on">On</option>
        <option value="off">Off</option>
      </select>
      <button id=${RED_TEAM_BUTTON_KEY} type="button" style="font-weight: 700; font-size: 14px; padding: 8px 12px; border-radius: 4px; border: 1px solid #777; background: #777">적용</button>
    </div>
  </div>
`;

const setRedTeamCookie = (enabled: boolean) => {
  const cookies = new Cookies();
  const cookieOptions = {
    maxAge: 60 * 60 * 6, // 6시간
    path: '/',
  };
  cookies.set(COOKIE.ENABLED_RED_TEAM, enabled, cookieOptions);
};
