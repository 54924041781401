import {
  AsyncBoundary,
  Dialog,
  MultilineTextField,
  Spacing,
  TextField,
  Typography,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo, useState } from 'react';
import { $Keys } from 'utility-types';

import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { Container } from '@/components/common/Container';
import { FormContainer, FormItem } from '@/components/common/Form';
import { openVerifyNeighborhood } from '@/constants/path';
import { useCurrentRegionCheckOpend } from '@/hooks/useCurrentRegionCheckOpend';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { isForbiddenWord } from '@/utils/check';
import { groupRecommendFieldSchema } from '@/utils/validate/formSchema/group';
import { validateSchemaWithHandleToast, validateSchemaWithMessage } from '@/utils/validate/util';

import useCheckUserFrom10월운동MarketingPromotion from '../../MarketingPromotion/24.10/hooks/useCheckUserFrom10월운동MarketingPromotion';
import useCheckUserFrom11월독서MarketingPromotion from '../../MarketingPromotion/24.11/hooks/useCheckUserFrom11월독서MarketingPromotion';
import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import GroupDescriptionCallout from '../Common/GroupDescriptionCallout';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { GuardForbiddenWordPopSendEnum } from '../Name/bottomSheet/GuardForbiddenWordGroupBottomSheet';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import { CategoryBadgeRecommendGenerated } from './CategoryBadgeRecommendGenerated';

export type GroupNewSetGroupCategoryRecommendPageParams = Pick<PageParams, 'from' | 'joinGrowth'>;

const GroupNewSetGroupCategoryRecommendPage: ActivityComponentType<
  GroupNewSetGroupCategoryRecommendPageParams
> = () => {
  const { from, joinGrowth } = useQueryParams();
  const { push: connectedPush } = useConnectedActions();
  const pop = useBack();

  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const {
    hasAutoSavedData,
    loadAndInitAutoSavedData,
    resetCreateForm,
    register,
    watch,
    setValue,
    handleSubmit,
  } = useCreateForm({
    mode: 'onSubmit',
  });
  const categoryFieldValue = watch('categoryId');

  const { isOpen: isOpenDialog, open: openDialog, close: closeDialog } = useDialog();

  const { data, regionCertified, userInfo, isOpenRegion } = useCurrentRegionCheckOpend();
  const [shouldValidateAfterSubmit, setShouldValidateAfterSubmit] = useState(false);

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'category',
      hasSavedData: hasAutoSavedData ?? false,
      from: from ?? '',
      createGroupFlowType: 'recommend',
    },
  });
  useEnterTrackEvent({
    event: 'enter_create_group_step_category',
    params: { step: 'category', from: from ?? '', createGroupFlowType: 'recommend' },
    loggerType: ['APPSFLYER'],
  });

  const { isCheck10월운동 } = useCheckUserFrom10월운동MarketingPromotion();
  const { isCheck11월독서 } = useCheckUserFrom11월독서MarketingPromotion();

  const nameFieldValue = watch('name') ?? '';
  const descriptionFieldValue = watch('description') ?? undefined;

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupRecommendFieldSchema.name, nameFieldValue))
      return false;
    if (
      !!descriptionFieldValue &&
      !validateSchemaWithHandleToast(groupRecommendFieldSchema.description, descriptionFieldValue)
    ) {
      return false;
    }
    if (
      !validateSchemaWithHandleToast(groupRecommendFieldSchema.categorySchema, categoryFieldValue)
    ) {
      return false;
    }
    return true;
  };

  const validateForbiddenWord = () => {
    if (isForbiddenWord(nameFieldValue)) {
      return false;
    }

    if (isForbiddenWord(descriptionFieldValue)) {
      return false;
    }

    return true;
  };

  const { nextPageRecommendFlowName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isRunningGrowthOld = queryParams.get('utm_campaign')?.includes('running_growth'); //과거
    const isRunningGrowthNew = queryParams.get('utm_campaign')?.includes('running_growth_country');

    setShouldValidateAfterSubmit(true);

    if (!validateForm()) return;

    if (!validateForbiddenWord()) {
      const popData = (await connectedPush(
        'BottomSheet/GuardForbiddenWordGroupBottomSheet',
        {}
      )) as {
        type: $Keys<typeof GuardForbiddenWordPopSendEnum>;
      };

      if (popData?.type === 'needToChangeGroupInfo') return;
    }

    connectedPush(nextPageRecommendFlowName, {
      name: nameFieldValue,
      description: descriptionFieldValue,
      categoryId: categoryFieldValue,
      joinRunningGrowth: isRunningGrowthOld || isRunningGrowthNew,
      joinGrowth: joinGrowth,
      createGroupFlowType: 'recommend',
      from,
    });
  });

  const nameFieldErrorMessage = useMemo(() => {
    if (!shouldValidateAfterSubmit) return '';
    if (!nameFieldValue) return '';

    const result = validateSchemaWithMessage(groupRecommendFieldSchema.name, nameFieldValue);
    if (result.success) return '';
    return result.message ?? '';
  }, [nameFieldValue, shouldValidateAfterSubmit]);

  const descriptionFieldErrorMessage = useMemo(() => {
    if (!shouldValidateAfterSubmit) return '';
    if (!descriptionFieldValue) return '';

    const result = validateSchemaWithMessage(
      groupRecommendFieldSchema.description,
      descriptionFieldValue
    );
    if (result.success) return '';
    return result.message ?? '';
  }, [descriptionFieldValue, shouldValidateAfterSubmit]);

  const categoryFieldErrorMessage = useMemo(() => {
    if (!shouldValidateAfterSubmit) return '';
    if (!descriptionFieldValue) return '';
    if (!nameFieldValue) return '';

    const result = validateSchemaWithMessage(
      groupRecommendFieldSchema.categorySchema,
      categoryFieldValue
    );
    if (result.success) return '';
    return result.message ?? '';
  }, [categoryFieldValue, descriptionFieldValue, nameFieldValue, shouldValidateAfterSubmit]);

  const isDisable = useMemo(() => {
    if (!nameFieldValue) return true;
    if (!descriptionFieldValue) return true;

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionFieldValue, groupRecommendFieldSchema.description]);

  useEffect(() => {
    if (isCheck10월운동) {
      setValue('categoryId', 1);
    }
    if (isCheck11월독서) {
      setValue('categoryId', 7);
    }
  }, [isCheck10월운동, isCheck11월독서, setValue]);

  useEffect(() => {
    if (!data) return;
    if (!isOpenRegion) {
      openDialog({
        element: (
          <Dialog
            title="모임 서비스 제한 지역"
            description={`${userInfo.regionCheckIns.regionName}에서는 모임을 이용할 수 없어요.`}
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (!regionCertified) {
      openDialog({
        element: (
          <Dialog
            title="동네인증"
            description={`모임을 만들려면 ${userInfo.regionCheckIns.regionName} 동네인증이 필요해요.`}
            primaryActionLabel="인증"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              trackEvent({ event: 'click_verify_neighboorhood' });
              openVerifyNeighborhood();
            }}
            onSecondaryAction={async () => {
              trackEvent({ event: 'click_cancel_verify_neighboorhood' });
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (hasAutoSavedData) {
      openDialog({
        element: (
          <Dialog
            description={`등록 중인 모임이 있어요. 이어서 등록할까요?`}
            primaryActionLabel="이어서 등록"
            secondaryActionLabel="새로 등록"
            onPrimaryAction={async () => {
              trackEvent({
                event: 'click_continuation_of_create_group',
                params: { answer: 'continue' },
              });
              await closeDialog();
              loadAndInitAutoSavedData();
            }}
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_continuation_of_create_group',
                params: { answer: 'reset' },
              });
              resetCreateForm();
              await closeDialog();
              setValue('categoryId', undefined);
              setValue('name', '');
              setValue('description', '');
            }}
          />
        ),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenRegion, regionCertified]);

  useEffect(() => {
    if (isOpenDialog && regionCertified) {
      closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDialog, regionCertified]);

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetGroupCategoryRecommendPage')}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup onSubmit={onSubmit} buttonDisabled={isDisable} />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <Container paddingY={0}>
          <div>
            <p
              style={{
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '2rem',
                marginBottom: '1.5rem',
                marginTop: '1rem',
              }}
            >
              어떤 모임을 만들까요?
            </p>
            <Typography as="div" typography="label3Bold" color="gray900">
              모임명
            </Typography>
            <Spacing size={12} />
            <FormContainer>
              <input type="text" style={{ display: 'none' }} />
              <FormItem marginBottom={0}>
                <TextField
                  variant={'outlined'}
                  onChange={(value) => {
                    setValue('name', value);
                  }}
                  placeholder="모임명이 짧을수록 이해하기 쉬워요."
                  isInvalid={shouldValidateAfterSubmit && !!nameFieldErrorMessage}
                  maxLength={groupRecommendFieldSchema.name.maxLength ?? 0}
                  value={nameFieldValue?.toString() ?? ''}
                  errorMessage={nameFieldErrorMessage}
                />
                {nameFieldErrorMessage && <Spacing size={24} />}
              </FormItem>
            </FormContainer>
            <Spacing size={8} />
            <Typography as="div" typography="label3Bold" color="gray900">
              카테고리
            </Typography>
            <Spacing size={12} />

            <FormItem marginBottom={32}>
              <CategoryBadgeRecommendGenerated
                onChange={(value) => {
                  setValue('categoryId', value);
                }}
                errorMessage={categoryFieldErrorMessage}
                categoryFieldValue={categoryFieldValue}
                text={nameFieldValue}
              />
            </FormItem>
            <Typography as="div" typography="label3Bold" color="gray900">
              모임 소개
            </Typography>
            <Spacing size={12} />
            <FormItem>
              <div id="description-textarea">
                <MultilineTextField
                  value={descriptionFieldValue ?? ''}
                  onChange={(value) => {
                    setValue('description', value);
                  }}
                  placeholder="활동 중심으로 모임을 소개해주세요. 모임 설정에서 언제든지 바꿀 수 있어요."
                  maxLength={groupRecommendFieldSchema.description.maxLength ?? 0}
                  minLength={groupRecommendFieldSchema.description?.minLength ?? 0}
                  isInvalid={shouldValidateAfterSubmit && !!descriptionFieldErrorMessage}
                  errorMessage={descriptionFieldErrorMessage}
                  minHeight="12.5rem"
                />
              </div>
              <Spacing size={24} />
              <GroupDescriptionCallout />
            </FormItem>
          </div>
        </Container>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetGroupCategoryRecommendPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
