import { StoreSlice } from '..';

export type HomeSlice = {
  hideHeaderTitle: boolean;
  setHideHeaderTitle: (show: boolean) => void;
};

export const createHomeHeaderSlice: StoreSlice<HomeSlice> = (set) => ({
  hideHeaderTitle: true,
  setHideHeaderTitle: (show) => {
    set((state) => ({ ...state, hideHeaderTitle: show }));
  },
});
