import { useEffect } from 'react';

const GroupDetailPreviewPageButton = () => {
  useEffect(() => {
    const disableClickEvent = (e) => {
      if (e.target.dataset?.seedIcon === 'true') {
        const parent = e.target.parentElement;
        if (parent.dataset?.action === 'back') return;
      }
      if (e.target.dataset?.action === 'back') return;

      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('click', disableClickEvent, true);
    return () => {
      document.removeEventListener('click', disableClickEvent, true);
    };
  }, []);

  return null;
};

export default GroupDetailPreviewPageButton;
