import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

interface Options {
  suspense?: boolean;
  refetchOnWindowFocus?: boolean;
}

export const getGroupMePath = (groupId?: string) => `${GROUP_URL}/${groupId}/me`;

export const useGetGroupMe = (id?: string, options?: Options) => {
  const fetchInstance = useFetchInstance();
  const getGroupMe = groupClient.api.GroupApi.apiV1GroupsIdMeGet({ axios: fetchInstance });

  const { data, refetch, isInitialLoading, isRefetching } = useQuery(
    [getGroupMePath(id)],
    () => {
      if (!id) return;
      return getGroupMe(Number(id));
    },
    {
      suspense: options?.suspense ?? true,
      refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
      enabled: !!id,
    }
  );

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading, isRefetching };
};
