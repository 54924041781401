import clsx from 'clsx';
import { forwardRef } from 'react';

import * as s from './index.css';

type Props = React.HTMLAttributes<HTMLUListElement>;

export const ActionList = forwardRef(
  ({ className, ...props }: Props, ref: React.Ref<HTMLUListElement>) => (
    <ul ref={ref} {...props} className={clsx(s.actionList, className)} />
  )
);
