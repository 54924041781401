import { BoxButton, Spacing, TextButton, Typography } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';

import ImgIdentification from '@/assets/images/group_profile_guide.png';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './CheckCanViewVerifiedUserInfoBottomSheet.css';

export const CheckCanViewVerifiedUserInfoBottomSheet: ActivityComponentType = () => {
  const { pop } = useConnectedActions();

  const handleOpenVerificationStep = () => {
    pop().send({
      canViewUser: true,
    });
  };
  const handlePendingClick = () => {
    pop().send({
      canViewUser: false,
    });
  };

  const renderText = () => {
    return (
      <div className={s.TextBox}>
        <Typography
          style={{ width: '100%' }}
          as="h4"
          typography="title2Bold"
          color="gray900"
          textAlign="center"
        >
          나이, 성별 정보 공개 안내
        </Typography>
        <Spacing size={6} />
        <Typography
          style={{ width: '100%' }}
          typography="bodyM1Regular"
          color="gray700"
          textAlign="center"
        >
          앞으로 모임장님이 멤버들의 정보를 볼 수 있어요. 멤버들도 모임장님의 정보를 볼 수 있지만
          멤버 간에는 정보가 공개되지 않아요.
        </Typography>
      </div>
    );
  };

  return (
    <BottomSheet
      onOutsideClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        pop();
      }}
    >
      <Spacing size={54} />
      <div className={s.ImageWrapper}>
        <img src={ImgIdentification} alt="본인인증" width={273} height={76} />
      </div>
      <Spacing size={61} />
      {renderText()}
      <Spacing size={24} />
      <BoxButton size="xlarge" width="100%" onClick={handleOpenVerificationStep}>
        알겠어요
      </BoxButton>
      <Spacing size={14} />
      <TextButton variant="secondary" bold onClick={handlePendingClick} width="100%">
        다음에 할게요
      </TextButton>
    </BottomSheet>
  );
};
