import create from 'zustand';

import { StickyInputProps as OriginStickyInputProps } from '..';
import { StickyInputFormProviderProps } from '../provider/StickyInputFormProvider';

type StickyInputProps = Pick<StickyInputFormProviderProps, 'initialValues'> &
  Pick<OriginStickyInputProps, 'type'>;

type Store = {
  focused: boolean;
  stickyInputProps?: StickyInputProps;
  setFocused: (focused: boolean, stickyInputProps?: StickyInputProps) => void;
};

export const useStickInputStore = create<Store>((set) => ({
  focused: false,
  stickyInputProps: undefined,
  setFocused: (focused, stickyInputProps) => {
    if (!focused) {
      return set(() => ({
        focused,
        stickyInputProps: undefined,
      }));
    }

    if (stickyInputProps) {
      set(() => ({
        stickyInputProps,
      }));
    }

    set(() => ({
      focused,
    }));
  },
}));
