import React from 'react';

import { useBridge } from '@/contexts/Bridge';
import { TypeActivities, useFlow } from '@/stackflow';

import useActiveActivities from './useActiveActivities';

const useBackToActivity = () => {
  const { isExistSpecificActivities, diffActivitiesFromCurrent } = useActiveActivities();
  const { pop } = useFlow();
  const { bridge } = useBridge();
  const handleBack = React.useCallback(
    (activityName?: keyof TypeActivities) => {
      if (!activityName) {
        bridge.closeRouter({});
        return;
      }
      if (isExistSpecificActivities(activityName)) {
        const diff = diffActivitiesFromCurrent(activityName);
        if (diff !== -1) {
          pop(diff);
          return;
        }
      }
      bridge.closeRouter({});
    },
    [isExistSpecificActivities, diffActivitiesFromCurrent, pop]
  );

  return handleBack;
};

export default useBackToActivity;
