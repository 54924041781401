import { Spacing } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconPollFill } from '@seed-design/icon';

import * as s from './pollLayout.css';

type Props = {
  children: React.ReactNode;
  headRight: React.ReactNode;
  onClick?: () => void;
};

const PollLayout = ({ children, headRight, onClick }: Props) => {
  return (
    <div className={s.wrapper} onClick={onClick}>
      <header className={s.head}>
        <div className={s.headTitle}>
          <IconPollFill size={15} />
          <VerticalSpacing size={4} />
          <div>투표</div>
        </div>
        {headRight}
      </header>
      <Spacing size={12} />
      {children}
    </div>
  );
};

export default PollLayout;
