import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import { AnimatePresence, motion } from 'framer-motion';

import { HookFormImagePlugins } from '../../HookForm/hooks/useHookFormImages';
import { useStickInputStore } from '..';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';
import * as s from './FocusedLeftButtons.css';
import { StickyInputImageButton } from './ImageButton';
import { StickyInputMentionButton } from './MentionButton';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  plugins: HookFormImagePlugins;
  type?: 'new' | 'edit';
  onFocusEvent?: (e: React.MouseEvent) => void;
}

export const FocusedLeftButtons = ({ plugins, type, onFocusEvent }: Props) => {
  const { watch, setValue } = useStickyInputFormContext();
  const { setFocused } = useStickInputStore();

  const handleClickFoldButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setValue('showFoldButton', false);
    setValue('allowMultiline', false);
  };

  return (
    <AnimatePresence>
      {watch('showFoldButton') ? (
        <AnimatePresence>
          <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <button className={s.FoldButtonWrapper} onClick={handleClickFoldButton}>
              <IconChevronRightRegular size={24} color={vars.$scale.color.gray600} />
            </button>
          </motion.button>
        </AnimatePresence>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.75rem' }}
        >
          {type === 'new' && (
            <StickyInputImageButton
              className={s.StickyInputImageButton}
              plugins={plugins}
              onClick={() => setFocused(false)}
            />
          )}
          <StickyInputMentionButton
            className={s.StickyInputMentionButton}
            onClick={(e) => {
              setFocused(true);
              onFocusEvent?.(e);
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
