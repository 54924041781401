import { useQuery } from '@tanstack/react-query';

import { useBridge } from '@/contexts/Bridge';
import { isLocalhostAppStage } from '@/utils/check';

export const useCurrentPosition = () => {
  const { bridge } = useBridge();
  const getCurrentPosition = async () =>
    isLocalhostAppStage() ? await getMockCurrentPosition() : await bridge.getCurrentPosition({});

  const { data } = useQuery(['currentPosition'], getCurrentPosition, {
    onError: () => ({
      geolocation: {
        geolocationAccessResponse: {
          granted: false,
        },
      },
    }),
  });

  return { data: data } as { data?: BridgeCurrentPosition };
};

export type Position = {
  longitude: number;
  latitude: number;
};

type BridgeCurrentPosition = {
  geolocation?: {
    geolocationAccessResponse: {
      granted: boolean;
    };
    currentPosition?: {
      position: Position;
      timestamp: number;
    };
  };
};

const getMockCurrentPosition = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        geolocation: {
          geolocationAccessResponse: {
            granted: true,
          },
          currentPosition: {
            position: {
              latitude: 37.50364101571133,
              longitude: 127.02425030827584,
            },
            timestamp: 1682954111.715641,
          },
        },
      });
    }, 200);
  });
