import { useActivity } from '@stackflow/react';
import { useEffect } from 'react';

import { useFixedLayout } from '@/providers/FixedLayout';

/**
 * 페이지 별로 useFiexedLayoutSize 훅을 사용할지 여부를 store에서 가져옴
 * useSetFixedLayoutSize 훅을 사용해 페이지 별로, 혹은 로직에 따라 fixedLayout을 사용할지 여부를 변경할 수 있음
 * @param {boolean} initState 페이지 접속시의 초기 fixedLayout 적용 상태
 * @returns {*} fixedLayout 가변 제어 함수
 */

const useSetFixedLayoutSize = (initState = false) => {
  const { handleFixedLayout } = useFixedLayout();
  const { isTop } = useActivity();

  useEffect(() => {
    if (isTop) {
      handleFixedLayout(initState);
      return () => {
        handleFixedLayout(true);
      };
    }

    handleFixedLayout(true);
    return () => {
      handleFixedLayout(true);
    };
  }, [initState, isTop, handleFixedLayout]);

  return handleFixedLayout;
};

export default useSetFixedLayoutSize;
