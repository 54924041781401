import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useLayoutEffect } from 'react';

import LottieDoneCheck from '@/assets/images/lottie_done_check.json';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './index.css';

//TODO: 11월 독서 프로모션 생성 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
const DoneCreateGroup11월독서MarketingPromotionBottomSheet: ActivityComponentType = () => {
  const { groupId } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_create_group_marketing_11월_독서_bottom_sheet',
    params: {
      groupId,
    },
  });

  const [_, setMarketingPromotionBottomSheet] = useStorage(
    'promotion_group_2411_reading_create',
    false
  );
  const { pop } = useFlow();

  const selector = `lottie-done-check`;

  useLayoutEffect(() => {
    const container = document.querySelector(`#${selector}`) as HTMLElement;
    container.innerHTML = '';
    import('lottie-web/build/player/lottie_light.min.js').then((lottieLight) => {
      const lottieDoneCheckData = lottieLight.loadAnimation({
        container,
        renderer: 'svg',
        autoplay: false,
        loop: false,
        animationData: LottieDoneCheck,
      });
      lottieDoneCheckData.pause();
      lottieDoneCheckData.setSpeed(1);
      setTimeout(() => {
        lottieDoneCheckData.play();
      }, 300);
    });
  }, [selector]);

  const renderText = {
    title: '새 모임을 만들었어요!',
    description: (
      <>
        모임 소개와 대표 사진을 올려 멤버를 모으고 <br />
        함께 독서 인증 활동을 시작해요
      </>
    ),
  };

  return (
    <BottomSheet
      onOutsideClick={() => {
        setMarketingPromotionBottomSheet(true);
        pop();
      }}
      style={{
        padding: '1.5rem 1rem .5rem 1rem',
      }}
    >
      <div className={s.BottomSheetWrapper}>
        <div id={selector} className={s.CheckIconImage} />
        <Spacing size={16} />
        <Typography typography="title2Bold">{renderText.title}</Typography>
        <Spacing size={6} />
        <Typography
          typography="bodyM1Regular"
          textAlign="center"
          style={{
            whiteSpace: 'pre-wrap !important',
          }}
          color="gray700"
        >
          {renderText.description}
        </Typography>
        <Spacing size={28} />
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            setMarketingPromotionBottomSheet(true);
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default DoneCreateGroup11월독서MarketingPromotionBottomSheet;
