import { Spinner, throttle } from '@community-group/components';
import { ReactNode, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { PaginationFetchNextProps } from '../../../utils';
import * as s from './style.css';

type Props<T> = {
  items: T[];
  render: (item: T, index: number) => ReactNode;
} & Omit<React.HTMLProps<HTMLUListElement>, 'children'> &
  PaginationFetchNextProps;

export const PaginationList = <T,>({
  items,
  render,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  ...listProps
}: Props<T>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      throttle(fetchNextPage, 200)();
    }
  }, [inView, fetchNextPage]);

  const Loader = useCallback(() => {
    if (!hasNextPage) return null;

    if (isFetchingNextPage)
      return (
        <div className={s.LoadingWrapper}>
          <Spinner />
        </div>
      );

    return <div className={s.Trigger} ref={ref} />;
  }, [isFetchingNextPage, hasNextPage, ref]);

  return (
    <div>
      <ul {...listProps}>{items.map((item, index) => render(item, index))}</ul>
      <Loader />
    </div>
  );
};
