import { PostDetail } from '@community-group/api/lib/group/models';
import { AsyncBoundary } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  MY_GROUP_POST_REQUEST_LIMIT,
  useGetMyGroupPostList,
} from '@/api/hooks/useGetMyGroupPostList';
import { LoadMoreMyGroupFeedContainer } from '@/components/common/LoadMoreContainer';
import { trackEvent } from '@/utils/analytics';

import { MemoizingMyFeedItem } from '../MyFeedItem';

export const MyFeedListWrapper = () => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
      <MyFeedList />
    </AsyncBoundary>
  );
};

const MyFeedList = () => {
  const { data, hasNextPage, isFetchingNextPage } = useGetMyGroupPostList();

  const feedItemTotalIndex = useMemo(() => {
    return (data?.pages && data.pages.length * MY_GROUP_POST_REQUEST_LIMIT) ?? 0;
  }, [data]);

  return (
    <div
      style={{
        backgroundColor: vars.$semantic.color.paperDefault,
        paddingBottom: 'calc(5rem + env(safe-area-inset-bottom))',
        overflowX: 'hidden',
      }}
    >
      {data?.pages.map(({ data }, pagesIdx) =>
        data.posts.map((post: PostDetail, postsIdx) => {
          const idx = pagesIdx * MY_GROUP_POST_REQUEST_LIMIT + (postsIdx + 1);
          if (idx === 3) {
            return (
              <div key={post.id}>
                <MemoizingMyFeedItem post={post} key={post.id} idx={idx} />
                <Impression3ItemsOnMyGroupFeedMemo />
              </div>
            );
          }
          return <MemoizingMyFeedItem post={post} key={post.id} idx={idx} />;
        })
      )}
      {hasNextPage && !isFetchingNextPage && (
        <LoadMoreMyGroupFeedContainer lastItemIdx={feedItemTotalIndex} />
      )}
    </div>
  );
};

export default React.memo(MyFeedList);

// 상위 Feed Item 3개 노출시 이벤트를 로깅하는 컴포넌트
const Impression3ItemsOnMyGroupFeed = () => {
  const { ref, inView } = useInView({
    root: null,
    rootMargin: '1px',
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      trackEvent({ event: 'impression_3_my_group_feed_item', sample: true });
    }

    return () => {};
  }, [inView]);

  return <div style={{ width: '100%' }} ref={ref}></div>;
};

const Impression3ItemsOnMyGroupFeedMemo = React.memo(Impression3ItemsOnMyGroupFeed);
