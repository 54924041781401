import { Typography, withAsyncBoundary } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconReplyRegular, IconThumbUpFill, IconThumbUpRegular } from '@seed-design/icon';

import { useDeleteMeetupEmotion } from '@/api/hooks/useDeleteMeetupEmotion';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { getGroupMeetupEmotionListPath } from '@/api/hooks/useGetGroupMeetupEmotionList';
import { usePatchMeetupEmotion } from '@/api/hooks/usePatchMeetupEmotion';
import { queryClient } from '@/api/instance';
import { IconShareRegularExperiment } from '@/components/common/Icons/shareRegular';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useShareMeetupContents } from '@/hooks/useShareMeetupContents';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupMeetupDetailEmotionCountMessage from './GroupMeetupDetailEmotionCountMessage';
import * as s from './GroupMeetupDetailReaction.css';

type Props = {
  groupId: string;
  meetupId: string;
};

const GroupMeetupDetailReaction = ({ groupId, meetupId }: Props) => {
  const { group } = useGetGroupDetail(groupId);
  const { data: meetup } = useGetGroupMeetupDetail({ groupId, meetupId });
  const { data: groupMe } = useGetGroupMe();

  const { push } = useFlow();
  const handleLikeMessageClick = () => {
    push('GroupMeetupDetailLikeListPage', {
      groupId,
      meetupId,
    });

    trackEvent({
      event: 'click_like_list',
      params: {
        type: 'meetup',
        contentType: 'meetup',
      },
      sample: true,
    });
  };

  const { bridge } = useBridge();
  const refetchMeetupEmotionList = () => {
    const queryKey = getGroupMeetupEmotionListPath(Number(groupId), Number(meetupId));
    queryClient.refetchQueries([queryKey]);
  };
  const { mutate: deleteMeetupEmotion } = useDeleteMeetupEmotion({
    onSuccess: refetchMeetupEmotionList,
  });
  const { mutate: patchMeetupEmotion } = usePatchMeetupEmotion({
    onSuccess: refetchMeetupEmotionList,
  });
  const handleLikeIconClick = (event) => {
    event.stopPropagation();
    trackEvent({
      event: 'click_like',
      params: {
        contentType: 'meetup',
      },
      sample: true,
    });

    bridge.setHapticLightFeedback({});
    if (meetup.emotion?.myEmotion) {
      deleteMeetupEmotion({ groupId, meetupId });
      return;
    }
    patchMeetupEmotion({ groupId, meetupId });
  };

  const { handleGroupOnly } = useHandleGroupOnly({ groupId });
  const handleCommentIconClick = () => {
    trackEvent({
      event: 'click_group_meetup_comment_more',
      params: {
        role: groupMe?.currentUser.role,
      },
      sample: true,
    });

    const moveToCommentListPage = () => {
      push('CommentListPage', {
        groupId,
        relatedId: meetupId,
        relatedContentType: 'meetup',
        commentCount: meetup.commentCount.toString(),
        groupName: group?.name,
      });
    };

    handleGroupOnly({
      isBlock: !meetup.currentUserInfo.groupJoined,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getComment,
      onSettled: moveToCommentListPage,
      onSuccess: moveToCommentListPage,
    });
  };

  const handleShareMeetupContents = useShareMeetupContents();
  const handleShareButtonClick = () => {
    if (!meetup.permalink.id) return;

    handleShareMeetupContents({
      groupId,
      meetupId,
      permalinkId: meetup.permalink.id,
      currentUserRole: meetup.currentUserInfo.groupRole ?? 'none',
      from: 'meetupDetailFeedback',
    });
  };

  return (
    <div>
      <GroupMeetupDetailEmotionCountMessage
        groupId={groupId}
        meetupId={meetupId}
        meetupEmotionCount={meetup.emotion.count}
        hasCurrentUserEmotion={Boolean(meetup.emotion.myEmotion)}
        onClick={handleLikeMessageClick}
      />
      <div className={s.Container}>
        <div className={s.FeedbackContainer}>
          <div className={s.IconWrapper} onClick={handleLikeIconClick}>
            {meetup.emotion?.myEmotion ? (
              <IconThumbUpFill width={22} height={22} color={vars.$semantic.color.primary} />
            ) : (
              <IconThumbUpRegular width={22} height={22} color={vars.$scale.color.gray800} />
            )}
            {meetup.emotion?.count > 0 && (
              <>
                <VerticalSpacing size={4} />
                <Typography
                  as="p"
                  typography="caption1Regular"
                  color={meetup.emotion.myEmotion ? 'primary' : 'gray900'}
                >
                  {meetup.emotion.count}
                </Typography>
              </>
            )}
          </div>
          <VerticalSpacing size={20} />
          <div className={s.IconWrapper} onClick={handleCommentIconClick}>
            <IconReplyRegular width={22} height={22} color={vars.$scale.color.gray800} />
            {meetup.commentCount > 0 && (
              <>
                <VerticalSpacing size={4} />
                <Typography as="p" typography="caption1Regular" color="gray900">
                  {meetup.commentCount}
                </Typography>
              </>
            )}
          </div>
          <VerticalSpacing size={20} />
          <div className={s.IconWrapper} onClick={handleShareButtonClick}>
            <IconShareRegularExperiment
              currentUserRole={meetup.currentUserInfo.groupRole}
              width={22}
              height={22}
              color={vars.$scale.color.gray800}
            />
          </div>
        </div>
        <p className={s.Counts}>
          관심 {meetup?.watchCount} ⸱ 조회 {meetup?.readCount}
        </p>
      </div>
    </div>
  );
};

export default withAsyncBoundary(GroupMeetupDetailReaction, {});
