import { useCallback } from 'react';

import { useGetApplicationAcceptance } from '@/api/hooks/useGetApplicationAcceptance';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

interface Props {
  groupId: string;
  userId?: number;
}

export const useCheckApplicationRejectedBottomSheet = ({ groupId, userId }: Props) => {
  const { applicationStatus: queryParamStatus } = useQueryParams();
  const { refetch: fetchApplicationStatus } = useGetApplicationAcceptance(groupId, userId);
  const { push } = useFlow();

  const checkTurnOnNotificationBottomSheet = useCallback(async () => {
    if (queryParamStatus !== 'rejected') return false;

    const { data } = await fetchApplicationStatus();
    const allowBottomSheet =
      data?.data.status === 'rejected' && data?.data.comment?.commentFromManager != null;
    if (!allowBottomSheet) return false;

    // 거절 메시지 확인 바텀 시트
    push('BottomSheet/ApplicationRejectedCommentBottomSheet', {
      groupId: groupId,
      userId: String(userId),
    });
    return true;
  }, [queryParamStatus, fetchApplicationStatus, push, groupId, userId]);

  return checkTurnOnNotificationBottomSheet;
};
