import { GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Divider } from '@community-group/components';

import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { DAANGN_RACE_GROUP_IDS } from '@/components/group/Home/components/ExplorerGroupTab/RecommendCurationGroupSection';

import GroupCountInfo from './GroupCountInfo';
import GroupDescription from './GroupDescription';
import GroupDetailInfo from './GroupDetailInfo';
import GroupOfTheMonthBanner from './GroupOfTheMonthBanner';
import GroupTags from './GroupTags';
import GroupTitle from './GroupTitle';
import * as s from './index.css';
import MeetupPoiMap from './MeetupPoiMap';

type Props = { group: GroupDetailPresentation };

const GroupInfoSection = ({ group }: Props) => {
  const {
    description,
    meetupPois,
    createdAt,
    canViewVerifiedUserInfo,
    needVerification,
    ageRangeTag,
  } = group;
  const { data: level } = useGetGroupLevel({ groupId: Number(group.id) });
  // TODO: 당근레이스 그룹은 지역을 표시하지 않는다. 이후 지우기
  const isRunningRaceGroup = DAANGN_RACE_GROUP_IDS.includes(group.id);
  const groupRegion = isRunningRaceGroup ? undefined : group.groupCreatedRegion;

  return (
    <section className={s.InformationSection}>
      <GroupTitle
        name={group.name}
        memberCount={group.memberCount}
        region={groupRegion}
        lastActivatedAt={group.lastActivatedAt}
        profileImage={group.profileImage.medium}
        isEventGroup={isRunningRaceGroup}
        groupLevel={level?.currentLevel}
      />
      <Divider />

      <GroupOfTheMonthBanner groupId={group.id} />
      <div className={s.InnerLayout}>
        <GroupDetailInfo
          groupId={group.id}
          createdAt={createdAt}
          ageRangeTag={ageRangeTag}
          canViewVerifiedUserInfo={canViewVerifiedUserInfo}
          needVerification={needVerification}
        />
        <GroupDescription description={description} />
        <GroupTags category={group.category} />
        <GroupCountInfo
          postCount={group.postCount}
          meetupCount={group.meetupCount}
          likeCount={group.watchedCount}
        />
        <MeetupPoiMap meetupPois={meetupPois} />
      </div>
    </section>
  );
};

export default GroupInfoSection;
