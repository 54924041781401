import { useEffect, useMemo, useState } from 'react';

import { isDarkTheme } from '@/stackflow/hooks/useTheme';

import Large2DLevel1 from './assets/badge/2d/large-1';
import Large2DLevel2 from './assets/badge/2d/large-2';
import Large2DLevel3 from './assets/badge/2d/large-3';
import Large2DLevel4 from './assets/badge/2d/large-4';
import Large2DLevel5 from './assets/badge/2d/large-5';
import Small2DLevel1 from './assets/badge/2d/small-1';
import Small2DLevel2 from './assets/badge/2d/small-2';
import Small2DLevel3 from './assets/badge/2d/small-3';
import Small2DLevel4 from './assets/badge/2d/small-4';
import Small2DLevel5 from './assets/badge/2d/small-5';

type Props = {
  type?: 'large' | 'small';
  theme?: 'auto' | 'light' | 'dark'; // new
  dimension?: '2d';
  size?: number;
  level: number;
} & React.HTMLAttributes<HTMLImageElement>;

const GroupLevel2DBadge = ({
  type = 'small',
  theme = 'auto',
  size = 28,
  level,
  ...rest
}: Props) => {
  const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>(
    isDarkTheme() ? 'dark' : 'light'
  );

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      setSystemTheme(e.matches ? 'dark' : 'light');
    });
  }, []);

  const BadgeSVG = useMemo(() => {
    switch (level) {
      case 0:
        return undefined;
      case 1:
        return type === 'small' ? Small2DLevel1 : Large2DLevel1;
      case 2:
        return type === 'small' ? Small2DLevel2 : Large2DLevel2;
      case 3:
        return type === 'small' ? Small2DLevel3 : Large2DLevel3;
      case 4:
        return type === 'small' ? Small2DLevel4 : Large2DLevel4;
      case 5:
        return type === 'small' ? Small2DLevel5 : Large2DLevel5;
      default:
        return type === 'small' ? Small2DLevel1 : Large2DLevel1;
    }
  }, [level, type]);

  // 스타일 설정
  const themeStyle = useMemo(() => {
    if (theme !== 'auto') return theme;
    return systemTheme;
  }, [theme, systemTheme]);

  if (!BadgeSVG || level === 0) return null;

  return (
    <div
      {...rest}
      style={{
        width: size + 'px',
        height: size + 'px',
      }}
    >
      <BadgeSVG theme={themeStyle} size={size} />
    </div>
  );
};

export default GroupLevel2DBadge;
