import { GroupMemberStateEnum } from '@community-group/api/lib/group/models';
import { Checkbox, Dialog, useDialog } from '@community-group/components';
import { useState } from 'react';

import { useDeleteMember } from '@/api/hooks/useDeleteMember';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './index.css';

type Props = {
  groupId: string;
  userId: string;
  nickname: string;
  subNickname?: string;
  cancelCallback?: () => void;
  confirmCallback?: () => void;
};

export const DeleteMemberDialog = ({
  groupId,
  userId,
  nickname,
  subNickname,
  cancelCallback,
  confirmCallback,
}: Props) => {
  const { close: closeDialog } = useDialog();
  const { bridge } = useBridge();
  const nicknameDisplayText = convertNicknameDisplay({
    nickname,
    subNickname,
  });

  const [memberState, setMemberState] = useState<GroupMemberStateEnum>('removed');
  const selectedRestricted = memberState === 'restricted';

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: mutateDeleteMember } = useDeleteMember({
    onSuccess: () => {
      const toastBody = selectedRestricted
        ? `${nicknameDisplayText}님을 차단했어요.`
        : `${nicknameDisplayText}님을 내보냈어요.`;

      bridge.openToast({
        toast: {
          body: toastBody,
        },
      });
    },
    onError: handleErrorWithToast,
  });

  return (
    <Dialog
      style={{ gap: '3px' }}
      title={`${convertNicknameDisplay({
        nickname,
        subNickname,
      })}님을 내보낼까요?`}
      optionalElement={
        <Checkbox
          isSelected={selectedRestricted}
          defaultSelected={selectedRestricted}
          size="large"
          onChange={() => {
            setMemberState(selectedRestricted ? 'removed' : 'restricted');
          }}
        >
          다시 가입할 수 없게 차단하기
        </Checkbox>
      }
      secondaryActionLabel="취소"
      onSecondaryAction={async () => {
        await closeDialog();
        cancelCallback?.();
      }}
      primaryActionLabel="내보내기"
      onPrimaryAction={async () => {
        await closeDialog();

        mutateDeleteMember({
          groupId,
          userId,
          memberState,
        });

        setTimeout(() => {
          confirmCallback?.();
        }, 250);
      }}
    />
  );
};
