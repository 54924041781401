import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';

type Props = { videoId?: string; enabled?: boolean };

type VideoData = {
  video: {
    id: string;
    playbackUrl: string;
    thumbnailUrl: string;
    duration: number; // double 형태로 내려가요
    isReadyToPlay: boolean; // 이 값이 true 여야 playbackUrl 재생 가능
    width: number;
    height: number;
    size: number; // 용량
  };
};

const getVideoDataPath = (videoId: string) => `/api/v1/resource/videos/${videoId}`;

const useGetVideoData = ({ videoId, enabled = true }: Props) => {
  const fetchInstance = useFetchInstance();

  return useQuery(
    [videoId ? getVideoDataPath(videoId) : 'getVideoData'],
    async () => {
      if (!videoId) return null;
      const response = await fetchInstance.get(getVideoDataPath(videoId));

      return response.data as VideoData;
    },
    {
      refetchInterval: (data) => {
        if (!videoId || !data) return false;
        const isReadyToPlay = (data as any)?.video.isReadyToPlay;
        if (isReadyToPlay) return false;
        return 1000;
      },
      enabled: enabled && !!videoId,
    }
  );
};

export default useGetVideoData;
