import { vars } from '@seed-design/design-token';

interface Props {
  strokeColor?: string;
  size?: number;
}

const Retry = ({ size = 18, strokeColor: propsStrokeColor }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$scale.color.gray900;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35259 8.99996C3.35259 6.01763 5.77025 3.59996 8.75259 3.59996C11.7349 3.59996 14.1526 6.01763 14.1526 8.99996C14.1526 9.2153 14.14 9.42746 14.1156 9.63579L12.9268 8.44703C12.6925 8.21271 12.3126 8.21271 12.0783 8.44703C11.844 8.68134 11.844 9.06124 12.0783 9.29556L14.1996 11.4169C14.434 11.6512 14.8139 11.6512 15.0482 11.4169L17.1695 9.29556C17.4038 9.06124 17.4038 8.68134 17.1695 8.44703C16.9352 8.21271 16.5553 8.21271 16.321 8.44703L15.3388 9.42915C15.348 9.2872 15.3526 9.14408 15.3526 8.99996C15.3526 5.35488 12.3977 2.39996 8.75259 2.39996C5.10751 2.39996 2.15259 5.35488 2.15259 8.99996C2.15259 12.645 5.10751 15.6 8.75259 15.6C9.91172 15.6 11.0027 15.3006 11.9507 14.7746C12.2405 14.6138 12.345 14.2486 12.1842 13.9588C12.0235 13.6691 11.6582 13.5645 11.3685 13.7253C10.5941 14.155 9.70284 14.4 8.75259 14.4C5.77025 14.4 3.35259 11.9823 3.35259 8.99996Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default Retry;
