import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse,
  Error,
  { id: number; groupNotificationSettingForm: model.GroupNotificationSettingV2Form }
>;

export const usePatchNotification = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putNotifications = groupClient.api.NotificationApi.apiV2GroupsIdNotificationPatch({
    axios: fetchInstance,
  });

  const putNotificationsWrapperFp = ({
    id,
    groupNotificationSettingForm,
  }: {
    id: number;
    groupNotificationSettingForm: model.GroupNotificationSettingV2Form;
  }) => {
    return putNotifications(id, groupNotificationSettingForm);
  };

  return useThrottleMutation(putNotificationsWrapperFp, {
    onError,
    onSuccess,
  });
};
