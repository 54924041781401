import clsx from 'clsx';
import React, { ImgHTMLAttributes, PropsWithChildren } from 'react';
import type { LazyLoadImageProps } from 'react-lazy-load-image-component';
import * as LazyLoad from 'react-lazy-load-image-component';

import * as s from './CoveredLazyLoadImage.css';

type PropsOf<TComponent extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>> =
  React.ComponentProps<TComponent>;

type CoveredLazyLoadImageProps = PropsWithChildren<LazyLoadImageProps> & {
  className?: string;
  isLazy?: boolean;
  insideStroke?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
} & PropsOf<typeof LazyLoad.LazyLoadImage>;

export const CoveredLazyLoadImage: React.FC<CoveredLazyLoadImageProps> = ({
  children,
  className,
  insideStroke,
  isLazy = true,
  containerRef,
  ...props
}) => {
  return (
    <div
      ref={containerRef}
      className={clsx([className, s.imageContainer({ insideStroke })])}
      style={{ ...props.style }}
    >
      {props.src &&
        (isLazy ? (
          <LazyLoad.LazyLoadImage effect="blur" style={{ objectFit: 'cover' }} {...props} />
        ) : (
          <img
            src={props.src}
            alt={props.alt}
            {...(props as ImgHTMLAttributes<HTMLImageElement>)}
          />
        ))}
      {children}
    </div>
  );
};
