import { useSnackbarAdapter } from '@community-group/components';
import { useEffect, useState } from 'react';

import { useGetNotifications } from '@/api/hooks/useGetNotifications';
import { usePatchNotification } from '@/api/hooks/usePatchNotifications';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { shortening } from '@/utils/text';

import { useHandleErrorWithToast } from '../useHandleErrorWithToast';
import {
  PostNotificationProps,
  useIsGroupMember,
  useShownStorage,
  useVisitCountsTrigger,
} from './base';

// 새글 알림이 켜져있고 새글 알림으로 3번 이상 방문한 경우.
// 새글 알림으로 들어왔는지 여부는 refer_root가 notification으로 들어온 것으로 판단.
// 참고: https://www.notion.so/daangn/Launch-6d56eb44ba2747dc8c0c9468bae44791#1e1dda3acdc043c4a082ca807e7e8764
export const usePostNotificationTurnOffSnackbarWithPostDetail = (props: PostNotificationProps) => {
  const { refer_root = '', refer_content = '' } = useQueryParams();
  const isNotificationStateOn = useIsNotificationStateOn(props.groupId);
  const [enableCount, setEnableCount] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!isNotificationStateOn) return;

    if (refer_root === 'notification' && refer_content === 'new_post_notification') {
      setEnableCount(true);
    }
  }, [isNotificationStateOn, refer_root, refer_content]);

  const snackbar = usePostNotificationTurnOffSnackbar({ ...props, from: 'PostDetail' });

  useVisitCountsTrigger({
    key: 'postDetailNotificationVisitCounts',
    targetId: props.groupId,
    triggerCount: 3,
    trigger: snackbar.trigger,
    enableCount,
  });
};

const usePostNotificationTurnOffSnackbar = ({
  groupId,
  groupName = '',
  from,
}: PostNotificationProps) => {
  const [shown, setShown] = useShownStorage({
    targetId: groupId,
    storageKey: 'shownPostNotificationTurnOffSnackbar',
  });

  const snackbarAdapter = useSnackbarAdapter();

  const handleErrorWithToast = useHandleErrorWithToast();

  const isGroupMember = useIsGroupMember(groupId);
  const { data: notificationState, refetch: refetchNotifications } = useGetNotifications(
    groupId,
    isGroupMember
  );
  const { mutate: mutateNotification } = usePatchNotification({
    onError: handleErrorWithToast,
    onSuccess: () => {
      snackbarAdapter.create({
        message: '이 모임의 새 글 알림을 받지 않아요',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });

      refetchNotifications();
    },
  });

  const showSnackbar = () => {
    trackEvent({
      event: 'impression_notification_off_suggestion_snackbar',
      params: {
        from,
        name: 'groupNewPostNotificationState',
      },
      sample: true,
    });

    const shortenedGroupName = shortening(groupName, 14);
    snackbarAdapter.create({
      message: `'${shortenedGroupName}'의 새 글 알림을 끄시겠어요?`,
      actionLabel: '알림 끄기',
      onAction: () => {
        trackEvent({
          event: 'click_notification_off_suggestion_snackbar',
          params: {
            from,
            name: 'groupNewPostNotificationState',
          },
        });

        if (notificationState?.daangnGroupNewPostNotificationState === 'all_on') {
          mutateNotification({
            id: Number(groupId),
            groupNotificationSettingForm: {
              groupNewPostNotificationState: 'all_off',
            },
          });
          return;
        }

        return;
      },
      type: 'default',
      timeout: 5000,
      shouldCloseOnAction: true,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
  };

  const trigger = () => {
    if (shown) return;
    showSnackbar();
    setShown(true);
  };

  return {
    trigger,
  };
};

const useIsNotificationStateOn = (groupId: string): boolean => {
  const isGroupMember = useIsGroupMember(groupId);
  const { data: notificationState } = useGetNotifications(groupId, isGroupMember);

  if (!isGroupMember) return false;
  if (!notificationState) return false;

  const isNotificationStateOn =
    notificationState.daangnGroupNewPostNotificationState === 'all_on' &&
    notificationState.groupNewPostNotificationState === 'all_on';

  return isNotificationStateOn;
};
