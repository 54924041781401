import { Spinner, Typography } from '@community-group/components';

type AppBarTextSubmitButtonProps = {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const AppBarTextSubmitButton = ({
  loading,
  disabled,
  onClick,
  children,
}: AppBarTextSubmitButtonProps) => {
  const handleSubmit = () => {
    onClick?.();
  };
  return (
    <Typography
      style={{ paddingRight: '0.5rem' }}
      typography="label2Regular"
      onClick={handleSubmit}
      color={loading || disabled ? 'gray500' : 'gray900'}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            boxSizing: 'border-box',
            padding: '0.625rem',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner variant="gray" width={18} height={18} />
        </div>
      ) : (
        children ?? '완료'
      )}
    </Typography>
  );
};
