import { Spinner } from '@community-group/components';

import { useGetCurationList } from '@/api/hooks/useGetCurationList';
import { Container } from '@/components/common/Container';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './GroupCurationWithKeywordLoader.css';

type Props = {
  topic: string;
  referrer: string;
};

const GroupCurationWithKeywordLoader = (props: Props) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetCurationList({
    ...props,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });
  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner size="small" variant="primary" />
      </div>
    </Container>
  );
};

export default GroupCurationWithKeywordLoader;
