import { Typography } from '@community-group/components';

import * as s from './CameraSelectItem.css';

type Props = {
  selectedIndex: number;
};

function CameraSelectItem({ selectedIndex }: Props) {
  return (
    <div className={s.Wrapper}>
      {selectedIndex !== -1 && (
        <div className={s.InnerCheck}>
          <Typography typography="label3Bold" color="staticWhite">
            {selectedIndex + 1}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CameraSelectItem;
