import { useBridge } from '@/contexts/Bridge';

export const usePickImages = () => {
  const { bridge } = useBridge();
  const pickImages = async (): Promise<Blob[]> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await bridge.driver.compat.pickImages();
  };

  return pickImages;
};
