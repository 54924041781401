import { Avatar, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCheckFill } from '@seed-design/icon';
import { useMemo } from 'react';

import * as s from './TargetItem.css';

type Props = {
  profileImages: string[];
  name: string;
  subText: string;
  isSelected: boolean;
  handleSelectTarget: () => void;
};

const DEFAULT_GRAYMAN_PROFILE_IMG =
  'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';

const TargetItem = ({ profileImages, name, subText, isSelected, handleSelectTarget }: Props) => {
  const defaultProfileImage = useMemo(
    () => (
      <img
        src={DEFAULT_GRAYMAN_PROFILE_IMG}
        alt="썸네일 이미지"
        style={{
          backgroundColor: vars.$semantic.color.paperDefault,
        }}
      />
    ),
    []
  );

  const renderProfileImage = useMemo(() => {
    if (profileImages.length === 0) {
      return (
        <Avatar
          alt="썸네일 이미지"
          width={56}
          height={56}
          fallback={defaultProfileImage}
          fallbackDelay={100}
          src={DEFAULT_GRAYMAN_PROFILE_IMG}
        ></Avatar>
      );
    }
    if (profileImages.length === 1) {
      return (
        <Avatar
          alt="썸네일 이미지"
          width={56}
          height={56}
          fallback={defaultProfileImage}
          fallbackDelay={100}
          src={profileImages[0]}
        ></Avatar>
      );
    }
    return (
      <div className={s.MultiProfileWrapper}>
        <div className={s.MultiAvatarTop}>
          <Avatar
            alt="썸네일 이미지"
            width={36}
            height={36}
            fallback={defaultProfileImage}
            fallbackDelay={100}
            src={profileImages[0]}
          />
        </div>
        <div className={s.MultiAvatarBottom}>
          <Avatar
            alt="썸네일 이미지"
            width={36}
            height={36}
            fallback={defaultProfileImage}
            fallbackDelay={100}
            src={profileImages[1]}
          />
        </div>
      </div>
    );
  }, [defaultProfileImage, profileImages]);

  return (
    <div className={s.Wrapper} onClick={handleSelectTarget}>
      <div className={s.ProfileImages}>
        {renderProfileImage}
        {isSelected && (
          <div className={s.SelectedIconWrapper}>
            <IconCheckFill size={12} color={vars.$semantic.color.onPrimary} />
          </div>
        )}
      </div>
      <div className={s.Name}>
        <Typography color="gray900" typography="caption1Regular" ellipsisAfterLines={2}>
          {name}
        </Typography>
      </div>
      <div className={s.SubText}>
        <Typography color="gray600" typography="caption2Regular">
          {subText}
        </Typography>
      </div>
    </div>
  );
};

export default TargetItem;
