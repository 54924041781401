import { GroupDetailPresentationCategory } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';

import * as s from './GroupTags.css';
interface Props {
  category?: GroupDetailPresentationCategory;
}

const GroupTags = ({ category }: Props) => {
  return (
    <div className={s.BadgeWrapper}>
      <div className={s.Badge}>
        <Typography typography="caption1Regular" color="gray700" ellipsisAfterLines={1}>
          {category?.name}
        </Typography>
      </div>
    </div>
  );
};

export default GroupTags;
