import { Typography, VerticalSpacing } from '@community-group/components';
import React from 'react';

import * as s from './index.css';

export interface ContentItem {
  title?: string;
  component?: React.ReactNode;
  content?: ContentItem[];
}

interface Props {
  items: ContentItem[];
}

const ListItem = ({ items }: Props) => {
  return (
    <ul className={s.List}>
      {items.map((item, index) => {
        const { title, content, component } = item as ContentItem;
        return (
          <div className={s.IncludedContentsWrapper} key={index}>
            {title && (
              <li className={s.ListItemStyle}>
                <Typography
                  typography="caption1Regular"
                  color="gray700"
                  className={s.InnerTextWrapper}
                >
                  •
                </Typography>
                <VerticalSpacing size={6} />
                <Typography
                  typography="caption1Regular"
                  color="gray700"
                  className={s.InnerTextWrapper}
                >
                  {title}
                </Typography>
              </li>
            )}
            {component && <div className={s.ComponentsWrapper}>{component}</div>}
            {content && <ListItem items={content} />}
          </div>
        );
      })}
    </ul>
  );
};

export default ListItem;
