import { UNSAFE_appInfo } from '@/utils/bridge/appInfo';

import { appLinkInfo } from './appLinkStore';

export const getAppVersion = () => {
  const userAgent = UNSAFE_appInfo.getData().app.userAgent;
  const [appInfo, osInfo] = userAgent.split(' ');
  const [, , version] = appInfo.split('/');
  const [os] = (osInfo || 'iOS').split('/');

  return { os, version: +version };
};

export const isHapticWorking = () => {
  const { version, os } = getAppVersion();
  if (os === 'iOS') {
    if (version >= 68300) {
      return true;
    }
  } else {
    if (version >= 64900) {
      return true;
    }
  }
  return false;
};

export const isViewHeightRatioWorking = () => {
  const { version, os } = getAppVersion();
  //TODO: app 버전 변경 필요 22.46.0
  if (os === 'iOS') {
    if (version >= 224402) {
      return true;
    }
  } else {
    if (version >= 224402) {
      return true;
    }
  }
  return false;
};

export const isLnbRefreshWorking = () => {
  const { version } = getAppVersion();
  if (version >= 225000 && appLinkInfo.getData().isCommunityAppLink) {
    return true;
  }
  return false;
};

export const checkVerifyIdentificationWorking = () => {
  if (!globalThis.appConfig.isAppEnvProduction) return true;
  const { version, os } = getAppVersion();
  if (os === 'iOS') {
    if (version >= 234500) {
      return true;
    }

    return false;
  } else {
    if (version >= 234600) {
      return true;
    }

    return false;
  }
};

export const checkHigherTargetVersion = (targetVersion: number) => {
  const { version } = getAppVersion();
  return version >= targetVersion;
};
