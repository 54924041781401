import AppBarCloseButton from '@/components/common/AppBarCloseButton/AppBarCloseButton';
import { trackEvent } from '@/utils/analytics';

const AppBarCloseTalkRoomButton = () => {
  const handleConfirm = () => {
    trackEvent({ event: 'click_close_create_talk_room' });
  };

  return (
    <AppBarCloseButton
      popActivityName="GroupDetailPage"
      needConfirm
      onConfirm={handleConfirm}
      dialog={{
        title: '채팅방 만들기를 그만할까요?',
        description: '작성한 내용은 저장되지 않아요.',
        primaryActionLabel: '그만두기',
        secondaryActionLabel: '취소',
      }}
    />
  );
};

export default AppBarCloseTalkRoomButton;
