import { Pin as DaangnPin } from '@community-group/components';
import { useEffect } from 'react';

import { useMap } from '..';
import { pinMapper } from '../utils/pinMapper';
import * as s from './Pin.css';

type PinProps = {
  label?: string;
  type?: Parameters<typeof DaangnPin>[0]['type'];
};
export function Pin({ label, type = 'pin-single-type' }: PinProps) {
  const { offset, size } = pinMapper[type];

  const { map } = useMap();

  useEffect(() => {
    if (label) map.current?.applyCenterOffset({ x: offset[0], y: size / 2 });
  }, [label, map, offset, size]);

  return (
    <div className={s.PinIconWrapper}>
      {label && (
        <div
          className={s.LabelOuterWrapper}
          style={{
            bottom: size + 'px',
          }}
        >
          <div className={s.Label}>{label}</div>
        </div>
      )}
      <DaangnPin
        type={type}
        style={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          height: size,
        }}
      />
    </div>
  );
}
