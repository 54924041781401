import { ActivityComponentType } from '@stackflow/react';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import PhotoContestPageV2 from '../PhotoContestV2/pages/index_V2';
import PhotoContestPageV3 from '../PhotoContestV3/pages/index_V3';

const PhotoContestPage: ActivityComponentType = () => {
  const photoContestVersion = useFeatureFlag('photoContestVersion');

  // 사진전 3회차인 경우
  if (photoContestVersion === 3) {
    return <PhotoContestPageV3 />;
  }

  // 사진전 2회차인 경우
  return <PhotoContestPageV2 />;
};

export default PhotoContestPage;
