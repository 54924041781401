import { vars } from '@seed-design/design-token';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import * as s from './Indicator.css';

interface Props {
  indicator?: {
    maxCount: number;
    count: number;
  };
}

const Indicator = ({ indicator }: Props) => {
  if (!indicator) return null;

  return (
    <div className={s.IndicatorWrapper}>
      {Array.from({ length: indicator?.maxCount }).map((item, index) => (
        <div
          className={s.IndicatorItem}
          key={index}
          style={{
            ...assignInlineVars({
              [s.IndicatorItemColor]:
                index + 1 <= indicator.count
                  ? vars.$scale.color.gray700
                  : vars.$scale.color.gray100,
            }),
          }}
        />
      ))}
    </div>
  );
};

export default Indicator;
