import { ActionListItem, Dialog, useDialog } from '@community-group/components';

import { trackEvent } from '@/utils/analytics';

type Props = {
  groupId: number;
};

export const GroupDirectPayChatStatusOnAwaitingApprovalActionListItem = ({ groupId }: Props) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const handlePayButtonClick = () => {
    trackEvent({
      event: 'click_pay_transfer_direct_chat_button',
      params: {
        groupId,
        type: 'profile_detail',
        directChatEnabledStatus: 'awaiting_approval',
      },
    });

    openDialog({
      element: (
        <Dialog
          title="송금은 채팅방에서 할 수 있어요"
          description="상대방의 채팅 수락을 기다리는 중이에요. 수락 후에 다시 시도해주세요."
          primaryActionLabel="확인"
          onPrimaryAction={async () => {
            await closeDialog();
          }}
        />
      ),
    });
  };

  return <ActionListItem onClick={handlePayButtonClick}>송금하기</ActionListItem>;
};
