import { AxiosInstance } from 'axios';

const serverLog = import.meta.env.SSR
  ? (message: string) => console.log(`%c ${message}`, 'color: #00aaff;')
  : () => {};

export const wrapSSRErrorHandler = (axiosInstance: AxiosInstance): AxiosInstance => {
  // 요청 인터셉터 추가
  axiosInstance.interceptors.request.use(
    function (config) {
      // 요청이 전달되기 전에 작업 수행
      serverLog(`[API Request] ${config.url}`);
      return config;
    },
    function (error) {
      // 요청 오류가 있는 경우 작업 수행
      console.log(`[Api Response Error] ${error.message}`);
    }
  );

  // 응답 인터셉터 추가
  axiosInstance.interceptors.response.use(
    async function (response) {
      serverLog(
        `[API Response] requested url: ${response.config.url} statusCode: ${response.status}`
      );
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
