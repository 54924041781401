import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { PageParams } from '@/stackflow/types/params';

import GroupMeetupList from '../../Detail/components/Meetup/List';

type GroupDetailMeetupParams = Pick<PageParams, 'groupId'>;

const GroupDetailMeetupPage: ActivityComponentType<GroupDetailMeetupParams> = () => {
  return (
    <AppScreen appBar={{ title: '일정' }}>
      <GroupMeetupList />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailMeetupPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
