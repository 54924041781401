// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import clsx from 'clsx';
import { useRef } from 'react';

import { Mention, MentionsInput as OriginMentionsInput } from '../../../mention';
import {
  StickyInputDefaultMentionStyle,
  StickyInputDefaultStyle,
} from '../../../mention/customStyle';
import {
  StickyInputFormData,
  useStickyInputFormContext,
} from '../provider/StickyInputFormProvider';
import { useStickInputStore } from '../store';
import * as s from './MentionsInput.css';

type Mention = {
  id: number;
  display: string;
  imageSrc?: string; // 유저 맨션에서 사용
  subDisplay?: string; // 챌린지 맨션에서 사용
};

type MentionConfig = {
  trigger: string;
  name: keyof StickyInputFormData;
  mentionList: Mention[];
};

export interface StickyMentionsInputProps extends React.HTMLAttributes<HTMLDivElement> {
  name: keyof StickyInputFormData;
  placeholder?: string;
  mentionConfig?: MentionConfig[];
}

const StickyMentionsInput = ({
  name,
  placeholder,
  mentionConfig,
  ...props
}: StickyMentionsInputProps) => {
  const { setFocused } = useStickInputStore();
  const { register, watch, setValue } = useStickyInputFormContext();
  const { onBlur } = register('content');
  const textareaRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    const mentionFormatRegex = /[^[}]+(?=})/g;
    const mentions = value.match(mentionFormatRegex);

    mentionConfig?.forEach(({ trigger, name }) => {
      const filteredMentions = mentions?.filter((mention) => mention[0] === trigger) ?? [];

      if (filteredMentions?.length <= 0) {
        return;
      }

      const mentionIdRegex = /[^{}]+(?=})/g;
      const mentionIds =
        filteredMentions
          .map((value) => value + '}')
          .join(', ')
          .match(mentionIdRegex) ?? [];

      setValue(name, mentionIds);
    });

    setValue(name, value);

    setTimeout(() => {
      const height = textareaRef.current?.scrollHeight ?? 40;
      setValue('allowMultiline', true);
      if (height > 40) {
        setValue('showFoldButton', true);
        return;
      }
    });
  };

  const overrideStyle = {
    ...{
      ...StickyInputDefaultStyle,
      maxHeight: watch('allowMultiline') ? '6.25rem' : '1rem',
      '&multiLine': {
        ...{
          ...StickyInputDefaultStyle['&multiLine'],
          input: {
            ...StickyInputDefaultStyle['&multiLine'].input,
          },
        },
      },
    },
  };

  return (
    <div
      ref={textareaRef}
      {...props}
      className={clsx([props.className, s.wrapper, 'StickyMentionsInput'])}
    >
      <OriginMentionsInput
        data-name={name}
        placeholder={placeholder}
        autoFocus={false}
        rows={1}
        onClick={() => {
          setFocused(true);
        }}
        onFocus={(event: { stopPropagation: () => void }) => {
          event.stopPropagation();
          setFocused(true);

          // Input Focus를 뒤에 두기 위한 트릭
          setTimeout(() => {
            setValue('content', watch('content') + ' ');
            setValue('content', watch('content').slice(0, -1));
          });
        }}
        onBlur={onBlur}
        style={overrideStyle}
        value={watch(name)}
        onChange={handleInputChange}
        className={undefined}
        display={undefined}
        classNames={undefined}
      >
        {mentionConfig ? (
          mentionConfig?.map(({ trigger, name, mentionList }) => (
            <Mention
              key={name}
              markup="[__display__]{__id__}"
              data={mentionList}
              trigger={trigger}
              style={overrideStyle}
              className={undefined}
              display={undefined}
              classNames={undefined}
            />
          ))
        ) : (
          <Mention
            markup="[__display__]{__id__}"
            data={[]}
            trigger={'UNDEFINED_MENTION_TRIGGER'}
            style={StickyInputDefaultMentionStyle}
            className={undefined}
            display={undefined}
            classNames={undefined}
          />
        )}
      </OriginMentionsInput>
    </div>
  );
};

export default StickyMentionsInput;
