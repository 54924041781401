import { Divider } from '@community-group/components';
import React from 'react';

import { GroupChallengeFormProps } from '../../types';
import {
  closeListItemFormFieldQuerySelector,
  getFormFieldQuerySelector,
} from '../../utils/selector';
import { GroupChallengeFormDateTimeField } from './DateTimeField';
import GroupChallengeFormDescriptionField from './DescriptionField';
import GroupChallengeFormDurationField from './DurationField';
import GroupChallengeFormFrequencyField from './FrequencyField';
import { GroupChallengeFormImagesField } from './ImagesField';
import * as s from './index.css';
import { GroupChallengeFormTitleField } from './TitleField';

const GroupChallengeForm = ({ formHandler }: GroupChallengeFormProps) => {
  return (
    <div className={s.Wrapper}>
      <GroupChallengeFormTitleField
        formHandler={formHandler}
        titleFieldName="name"
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'description',
            'startedAt',
            'totalDays',
            'certifyIntervalTargetCount',
          ]);
        }}
      />
      <GroupChallengeFormDateTimeField
        formHandler={formHandler}
        dateFieldName="startedAt"
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'title',
            'description',
            'totalDays',
            'certifyIntervalTargetCount',
          ]);
        }}
      />
      <Divider />
      <GroupChallengeFormDurationField
        formHandler={formHandler}
        dateFieldName="totalDays"
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'title',
            'description',
            'startedAt',
            'certifyIntervalTargetCount',
          ]);
        }}
      />
      <Divider />
      <GroupChallengeFormFrequencyField
        formHandler={formHandler}
        dateFieldName="certifyIntervalTargetCount"
        onClick={() => {
          closeListItemFormFieldQuerySelector(['title', 'description', 'startedAt', 'totalDays']);
        }}
      />
      <Divider />
      <GroupChallengeFormDescriptionField
        formHandler={formHandler}
        dateFieldName="description"
        onFocus={() => {
          setTimeout(() => {
            const descriptionFieldDom = getFormFieldQuerySelector('description');

            descriptionFieldDom?.scrollIntoView({
              behavior: 'smooth',
            });
          }, 350);
        }}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'title',
            'startedAt',
            'totalDays',
            'certifyIntervalTargetCount',
          ]);
        }}
      />
      <Divider />
      <GroupChallengeFormImagesField formHandler={formHandler} />
    </div>
  );
};

export default GroupChallengeForm;
