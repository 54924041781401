import { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';

import { COOKIE } from '@/constants/keys';

export const useWebviewSwitcher = () => {
  const cookieKey = COOKIE.GROUP_PREVIEW_URL;
  const cookieRef = useRef<Cookies>(new Cookies());

  // 초기값을 쿠키에서 바로 가져오도록 수정
  const [switchedOrigin, setSwitchedOrigin] = useState<string>(() => {
    const cookies = new Cookies(document.cookie);
    return cookies.get(cookieKey) || '';
  });

  // 쿠키 변경 감지를 위한 useEffect
  useEffect(() => {
    const handleCookieChange = () => {
      cookieRef.current = new Cookies(document.cookie);
      const newOrigin = cookieRef.current.get(cookieKey);
      if (newOrigin !== switchedOrigin) {
        setSwitchedOrigin(newOrigin || '');
      }
    };

    handleCookieChange();

    // 쿠키 변경 이벤트 리스너 (필요한 경우)
    window.addEventListener('storage', handleCookieChange);
    return () => {
      window.removeEventListener('storage', handleCookieChange);
    };
  }, [switchedOrigin]);

  const domain = import.meta.env.DEV ? 'localhost' : 'alpha.kr.karrotmarket.com';
  const cookieDefaultOption = {
    path: '/',
    domain,
  };

  return {
    switchedOrigin,
    enabled: Boolean(switchedOrigin),
    move: () => {
      if (import.meta.env.SSR || !switchedOrigin) return;
      const { pathname, search, hash } = window.location;
      window.location.href = `${switchedOrigin}${pathname}${search}${hash}`;
    },
    turnOn: (baseUrl: string) => {
      setSwitchedOrigin(baseUrl);
      cookieRef.current.set(cookieKey, baseUrl, {
        ...cookieDefaultOption,
        maxAge: 60 * 60 * 24 * 7, // 7일
      });
    },
    turnOff: () => {
      setSwitchedOrigin('');
      cookieRef.current.remove(cookieKey, cookieDefaultOption);
    },
  };
};
