import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getApplicationPath = (groupId: string) => `${getGroupPath(groupId)}/applications`;

export const useGetApplications = (id?: string) => {
  const fetchInstance = useFetchInstance();
  const getApplications = groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getApplicationPath(id ?? '')],
    ({ pageParam = undefined }) => getApplications(Number(id), pageParam, 30),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
