import { AsyncBoundary } from '@community-group/components';
import { ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import ExplorerMeetupSection from './ExplorerMeetupSection';

const MeetupTab = () => {
  return (
    <AsyncBoundary
      pendingFallback={
        <div
          style={{
            minHeight: '100vh',
            backgroundColor: vars.$semantic.color.paperDefault,
          }}
        >
          <ViewLoader />
        </div>
      }
      rejectedFallback={<></>}
    >
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: vars.$semantic.color.paperDefault,
        }}
      >
        <ExplorerMeetupSection />
      </div>
    </AsyncBoundary>
  );
};

export default MeetupTab;
