import * as s from './index.css';

type Props = {
  size: number;
  src: string;
  borderRadius?: number;
};

export const ProfileImage = ({ size, src, borderRadius = 12 }: Props) => (
  <div
    className={s.image}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`,
      borderRadius: `${borderRadius}px`,
      backgroundImage: `url(${src})`,
    }}
  >
    <img className="real-image" src={src} alt="프로필 이미지" />
    <div className={s.imageBorder} style={{ borderRadius: `${borderRadius}px` }} />
  </div>
);
