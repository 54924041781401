import { Category } from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { useState } from 'react';
import { $Values } from 'utility-types';

import { ORDER_SEARCH } from '@/api/base/group';

import PopularityGroupListSection from '../PopularityGroupListSection';
import SearchResultGroupFilter from '../SearchResultGroupFilter';
import SearchResultList from '../SearchResultList';

const SearchResultGroup = () => {
  const [selectedCategoryList, setSelectedCategoryList] = useState<Category[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<$Values<typeof ORDER_SEARCH>>(
    ORDER_SEARCH.RECOMMEND_SCORE_DESC
  );

  return (
    <div>
      <SearchResultGroupFilter
        selectedSorting={selectedSorting}
        setSelectedCategoryList={setSelectedCategoryList}
        setSelectedSorting={setSelectedSorting}
        selectedCategoryList={selectedCategoryList}
      />
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <SearchResultList
          selectedSorting={selectedSorting}
          setSelectedCategoryList={setSelectedCategoryList}
          setSelectedSorting={setSelectedSorting}
          selectedCategoryList={selectedCategoryList}
        />
      </AsyncBoundary>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <PopularityGroupListSection selectedCategoryList={selectedCategoryList} />
      </AsyncBoundary>
    </div>
  );
};

export default SearchResultGroup;
