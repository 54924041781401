import { getPostCommentsPath } from '@/api/hooks/useGetComments';
import { getPostDetailPath } from '@/api/hooks/useGetPostDetail';
import { trackEvent } from '@/utils/analytics';
import { GroupRoutes } from '@/utils/analytics/type';

import { queryClient } from '../../../../api/instance/index';

export const pullToRefreshPostDetail = (groupId: string, postId: string) => {
  const handleRefresh = async () => {
    trackEvent({
      event: 'pull_to_refresh',
      params: {
        route: GroupRoutes.POST_DETAIL,
      },
      loggerType: ['AMPLITUDE', 'FIREBASE'],
    });
    trackEvent({
      event: 'click_pull_to_refresh',
      params: {
        route: GroupRoutes.POST_DETAIL,
      },
      loggerType: ['KARROT'],
    });

    Promise.all([
      queryClient.refetchQueries({
        queryKey: [getPostDetailPath(groupId, postId)],
      }),
      queryClient.refetchQueries({
        queryKey: [getPostCommentsPath(groupId, postId)],
      }),
    ]);
  };

  return handleRefresh;
};
