import { Category } from '@community-group/api/lib/group/models';
import { BoxButton, Checkbox, useBottomSheet } from '@community-group/components';
import { useState } from 'react';

import Retry from '@/components/common/Icons/retry';

import * as s from './categoryFilterListBottomSheet.css';

interface Props {
  categoryList: Category[];
  selectedCategoryList: Category[];
  setSelectedCategoryList: (categoryList: Category[]) => void;
}

const CategoryFilterListBottomSheet = ({
  categoryList,
  selectedCategoryList,
  setSelectedCategoryList: handleSelectedCategoryList,
}: Props) => {
  const [currentSelectedList, setCurrentSelectedList] = useState(selectedCategoryList);
  const { closeAsync } = useBottomSheet();

  const isSelectedCategory = (id: number) =>
    currentSelectedList.some((category) => category.id === id);

  const handleClick = (category: Category) => {
    if (isSelectedCategory(category.id)) {
      return setCurrentSelectedList(
        currentSelectedList.filter((selectedCategory) => selectedCategory.id !== category.id)
      );
    }

    return setCurrentSelectedList([...currentSelectedList, category]);
  };

  const handleReset = () => {
    setCurrentSelectedList([]);
  };

  const handleSubmit = () => {
    handleSelectedCategoryList(currentSelectedList);

    closeAsync();
  };

  return (
    <div className={s.categoryFilterListBottomSheetWrapper}>
      <h1 className={s.categoryFilterListBottomSheetHeader}>카테고리</h1>
      <ul className={s.categoryList}>
        {categoryList.map((category) => (
          <li
            className={s.CategoryItem}
            key={category.id}
            onClick={() => {
              handleClick(category);
            }}
          >
            <div className={s.Checkbox}>
              <Checkbox isSelected={isSelectedCategory(category.id)} size={'large'} />
            </div>
            {category.name}
          </li>
        ))}
        <div className={s.Gradient} />
      </ul>
      <div className={s.ButtonWrapper}>
        <div
          className={s.ButtonItem}
          style={{
            flex: 1,
          }}
        >
          <BoxButton
            width="100%"
            variant="secondary"
            size="large"
            onClick={handleReset}
            prefix={
              <div className={s.IconWrapper}>
                <Retry />
              </div>
            }
          >
            초기화
          </BoxButton>
        </div>
        <div
          className={s.ButtonItem}
          style={{
            flex: 2,
          }}
        >
          <BoxButton width="100%" size="xlarge" onClick={handleSubmit}>
            적용하기
          </BoxButton>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilterListBottomSheet;
