import { Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import IMG_GROUP_ICON from '@/assets/images/icon_category_group.png';
import IMG_ENTRY_POINT_GUIDE from '@/assets/images/img_entry_point_guide.png';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { openCommunityHome } from '@/utils/link';

export const GroupEntryPointGuideBottomSheet: ActivityComponentType = () => {
  const { pop } = useFlow();

  const [, setShownEntryPointGuideBottomSheet] = useStorage(
    'shownEntryPointGuideBottomSheet',
    false
  );

  const handleClick = () => {
    setShownEntryPointGuideBottomSheet(true);
    pop();
  };

  return (
    <BottomSheet
      onOutsideClick={() => {
        setShownEntryPointGuideBottomSheet(true);
      }}
    >
      <Spacing size={20} />
      <Typography
        style={{ width: '100%', verticalAlign: 'baseline' }}
        textAlign="center"
        as="h3"
        typography="title2Bold"
        color="gray900"
      >
        <div>
          우리 동네{' '}
          <img
            src={IMG_GROUP_ICON}
            width={24}
            height={24}
            style={{
              verticalAlign: 'top',
            }}
          />{' '}
          모임은
        </div>
        동네생활 탭에서 찾을 수 있어요
      </Typography>
      <Spacing size={32} />
      <img style={{ width: '100%' }} src={IMG_ENTRY_POINT_GUIDE} alt="모임 진입 가이드" />
      <Spacing size={20} />
      <FixedButton onClick={handleClick}>확인했어요</FixedButton>
      <Spacing size={8} />
      <Typography
        textAlign="center"
        style={{
          padding: '0.375rem 0',
          width: '100%',
        }}
        typography="label3Bold"
        color="gray900"
        onClick={() => {
          openCommunityHome();
        }}
      >
        동네생활로 가보기
      </Typography>
    </BottomSheet>
  );
};
