import { Spinner } from '@community-group/components';
import { IconCloseFill } from '@seed-design/icon';

import { FileUploadState } from '../api/upload';
import * as s from './VideoUploadItem.css';

type Props = {
  fileState: FileUploadState;
  onClickDelete: (fileState: FileUploadState) => void;
  onRetryUpload: (fileState: FileUploadState) => void;
};

const VideoUploadItem = ({
  fileState,
  onClickDelete,

  onRetryUpload,
}: Props) => {
  const { status, progress, videoInfo, thumbnail } = fileState;

  if (status === 'uploading') {
    return (
      <div
        className={s.wrapper}
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className={s.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(fileState);
          }}
        >
          <IconCloseFill width={14} height={14} color="white" />
        </div>
        <span>업로드 중</span>
        <span
          style={{
            color: 'white',
            background: 'black',
          }}
        >
          {progress}%
        </span>
        <span>
          <Spinner size={'small'} />
        </span>
      </div>
    );
  }

  if (status === 'completed' && videoInfo) {
    return (
      <div className={s.wrapper}>
        <div
          className={s.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(fileState);
          }}
        >
          <IconCloseFill width={14} height={14} color="white" />
        </div>

        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            fontWeight: 700,
            overflow: 'hidden',
            zIndex: 10,
            color: 'white',
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
          }}
        >
          {videoInfo?.duration}
        </div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className={s.wrapper}>
        <span>업로드 실패</span>
        <button onClick={() => onRetryUpload(fileState)}>재시도</button>
        <div
          className={s.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(fileState);
          }}
        >
          <IconCloseFill width={14} height={14} />
        </div>
      </div>
    );
  }

  return null;
};

export default VideoUploadItem;
