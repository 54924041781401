import { Divider, Typography, VerticalSpacing } from '@community-group/components';
import React from 'react';

import * as s from './LevelUpGuideTable.css';

interface LevelUpGuideTableProps {
  currentLevel: number;
}

// 레벨 및 달성 조건 안내 테이블
function LevelUpGuideTable({ currentLevel }: LevelUpGuideTableProps) {
  // 피그마 상 table로 디자인되기가 어려워 div로 대체해 구현

  const contents = [
    {
      level: 'Lv.1',
      mission: ['모임 소개 작성하기', '모임 사진 등록하기', '첫 게시글 작성하기'],
      member: '-',
      open: true,
    },
    {
      level: 'Lv.2',
      mission: ['일정 2개 생성 또는 게시글 3개 남기기'],
      member: '3명',
      open: true,
    },
    {
      level: 'Lv.3',
      mission: ['활동 점수 150점 모으기'],
      member: '10명',
      open: true,
    },
    {
      level: 'Lv.4',
      mission: ['활동 점수 500점 모으기'],
      member: '30명',
      open: true,
    },
    {
      level: 'Lv.5',
      mission: ['활동 점수 5,000점 모으기'],
      member: '50명',
      open: true,
    },
  ];

  const filteredContents = contents.map((content) => {
    if (currentLevel + 1 < Number(content.level.split('.')[1])) {
      return {
        ...content,
        open: false,
      };
    }
    return content;
  });

  return (
    <div className={s.Container}>
      <div className={s.Thead}>
        <p className={s.ThStyle({ left: true })}>레벨</p>
        <VerticalSpacing size={12} />
        <p className={s.ThStyle()}>미션명</p>
        <VerticalSpacing size={12} />
        <p className={s.ThStyle({ right: true })}>멤버 수</p>
      </div>
      {filteredContents.map((content, index) => {
        return (
          <div key={'table-' + index}>
            <div className={s.TrStyle}>
              <p className={s.ThStyle({ left: true })}>{content.level}</p>
              <VerticalSpacing size={12} />
              {content.open ? (
                <>
                  <p
                    className={s.MissionListWrapper}
                    style={{
                      width: '100%',
                    }}
                  >
                    {content.mission.map((item, index) => (
                      <li key={index} className={s.LiStyle}>
                        <Typography
                          typography="caption1Regular"
                          color="gray700"
                          className={s.InnerTextWrapper}
                        >
                          •
                        </Typography>
                        <VerticalSpacing size={6} />
                        <Typography
                          typography="caption1Regular"
                          color="gray700"
                          className={s.InnerTextWrapper}
                        >
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </p>
                  <VerticalSpacing size={12} />
                  <p className={s.ThStyle({ right: true })}>{content.member}</p>
                </>
              ) : (
                <p
                  className={s.MissionListWrapper}
                  style={{
                    width: '100%',
                  }}
                >
                  <Typography
                    typography="caption1Regular"
                    color="gray600"
                    className={s.InnerTextWrapper}
                  >
                    미션을 확인하려면 {content.level} 모임이 되어보세요.
                  </Typography>
                </p>
              )}
            </div>
            <Divider />
          </div>
        );
      })}
    </div>
  );
}

export default LevelUpGuideTable;
