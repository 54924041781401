import { useEffect, useState } from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import { useTransparentNavbarState } from './TransparentNavScreenContext';

const TransparentNavTitleShowObserver = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const { setIsNavbarTitleShow } = useTransparentNavbarState();
  const { ref } = useIntersectionObserver({
    callback: (inView) => {
      if (!mounted) return;
      setIsNavbarTitleShow(!inView);
    },
    intersectionOptions: {
      threshold: 0.1,
    },
  });

  return <div ref={ref} />;
};

export default TransparentNavTitleShowObserver;
