import { vars } from '@seed-design/design-token';
import { motion, MotionProps } from 'framer-motion';
import { forwardRef } from 'react';

interface Props extends MotionProps {
  paddingX?: number;
  paddingY?: number;
  children?: React.ReactNode;
}

export const MotionContainer = forwardRef<HTMLDivElement, Props>(
  ({ paddingX = 16, paddingY = 16, children, ...props }: Props, ref) => {
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        {...props}
        style={{
          ...props.style,
          backgroundColor: vars.$semantic.color.paperDefault,
          padding: `${paddingY}px ${paddingX}px`,
        }}
      >
        {children}
      </motion.div>
    );
  }
);
