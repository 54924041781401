import {
  AsyncBoundary,
  Dialog,
  useDialog,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect } from 'react';

import { useGetCategories } from '@/api/hooks/useGetCategories';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import Chip from '@/components/common/base/Chip';
import FormBadgeRadio, { BadgeRadioWrapper } from '@/components/common/base/Input/Radio/Badge';
import { Container } from '@/components/common/Container';
import { FormContainer, FormItem } from '@/components/common/Form';
import { openVerifyNeighborhood } from '@/constants/path';
import { useCurrentRegionCheckOpend } from '@/hooks/useCurrentRegionCheckOpend';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import useCheckUserFrom10월운동MarketingPromotion from '../../MarketingPromotion/24.10/hooks/useCheckUserFrom10월운동MarketingPromotion';
import useCheckUserFrom11월독서MarketingPromotion from '../../MarketingPromotion/24.11/hooks/useCheckUserFrom11월독서MarketingPromotion';
import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';

export type GroupNewSetGroupCategoryPageParams = Pick<PageParams, 'from' | 'joinGrowth'>;

const GroupNewSetGroupCategoryPage: ActivityComponentType<
  GroupNewSetGroupCategoryPageParams
> = () => {
  const { from, joinGrowth } = useQueryParams();
  const { push } = useFlow();
  const pop = useBack();

  const { hasAutoSavedData, loadAndInitAutoSavedData, resetCreateForm, register, watch, setValue } =
    useCreateForm({
      initialize: false,
    });
  const categoryFieldValue = watch('categoryId');

  const { categories } = useGetCategories();

  const { isOpen: isOpenDialog, open: openDialog, close: closeDialog } = useDialog();

  const { data, regionCertified, userInfo, isOpenRegion } = useCurrentRegionCheckOpend();

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'category',
      hasSavedData: hasAutoSavedData ?? false,
      from: from ?? '',
      createGroupFlowType: 'normal',
    },
  });
  useEnterTrackEvent({
    event: 'enter_create_group_step_category',
    params: { step: 'category', from: from ?? '', createGroupFlowType: 'normal' },
    loggerType: ['APPSFLYER'],
  });

  const { isCheck10월운동 } = useCheckUserFrom10월운동MarketingPromotion();
  const { isCheck11월독서 } = useCheckUserFrom11월독서MarketingPromotion();

  useEffect(() => {
    if (isCheck10월운동) {
      setValue('categoryId', 1);
    }
    if (isCheck11월독서) {
      setValue('categoryId', 14); // 독서 카테고리 ID
    }
  }, [isCheck10월운동, isCheck11월독서, setValue]);

  useEffect(() => {
    if (!data) return;
    if (!isOpenRegion) {
      openDialog({
        element: (
          <Dialog
            title="모임 서비스 제한 지역"
            description={`${userInfo.regionCheckIns.regionName}에서는 모임을 이용할 수 없어요.`}
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (!regionCertified) {
      openDialog({
        element: (
          <Dialog
            title="동네인증"
            description={`모임을 만들려면 ${userInfo.regionCheckIns.regionName} 동네인증이 필요해요.`}
            primaryActionLabel="인증"
            secondaryActionLabel="취소"
            onPrimaryAction={async () => {
              trackEvent({ event: 'click_verify_neighboorhood' });
              openVerifyNeighborhood();
            }}
            onSecondaryAction={async () => {
              trackEvent({ event: 'click_cancel_verify_neighboorhood' });
              await closeDialog();
              setTimeout(() => {
                pop();
              }, 50);
            }}
          />
        ),
      });
      return;
    }

    if (hasAutoSavedData) {
      openDialog({
        element: (
          <Dialog
            description={`등록 중인 모임이 있어요. 이어서 등록할까요?`}
            primaryActionLabel="이어서 등록"
            secondaryActionLabel="새로 등록"
            onPrimaryAction={async () => {
              trackEvent({
                event: 'click_continuation_of_create_group',
                params: { answer: 'continue' },
              });
              await closeDialog();
              loadAndInitAutoSavedData();
            }}
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_continuation_of_create_group',
                params: { answer: 'reset' },
              });
              resetCreateForm();
              await closeDialog();
              setValue('categoryId', undefined);
            }}
          />
        ),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenRegion, regionCertified]);

  useEffect(() => {
    if (isOpenDialog && regionCertified) {
      closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDialog, regionCertified]);

  const { nextPageName } = useCreateGroupFunnel();

  const handleSubmit = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isRunningGrowthOld = queryParams.get('utm_campaign')?.includes('running_growth'); //과거
    const isRunningGrowthNew = queryParams.get('utm_campaign')?.includes('running_growth_country');
    push(nextPageName, {
      categoryId: categoryFieldValue,
      joinRunningGrowth: isRunningGrowthOld || isRunningGrowthNew,
      joinGrowth: joinGrowth,
      from,
    });
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetGroupCategoryPage')}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup onSubmit={handleSubmit} buttonDisabled={!categoryFieldValue} />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <Container paddingY={0}>
          <div>
            <p
              style={{
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '2rem',
                marginBottom: '1.5rem',
                marginTop: '1rem',
              }}
            >
              어떤 모임을 만들까요?
            </p>
            <FormContainer>
              <FormItem>
                <BadgeRadioWrapper>
                  {categories.map((item) => (
                    <FormBadgeRadio
                      key={item.id}
                      value={item.id}
                      register={register}
                      name="categoryId"
                      checked={item.id === Number(categoryFieldValue)}
                    >
                      <Chip text={item.name} />
                    </FormBadgeRadio>
                  ))}
                </BadgeRadioWrapper>
              </FormItem>
            </FormContainer>
          </div>
        </Container>
      </AsyncBoundary>
      {/* </AppScreen> */}
      {/* </GroupCreateAppScreen> */}
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetGroupCategoryPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
