import { CommentDetail, GroupCurrentUser } from '@community-group/api/lib/group/models';
import { Avatar, AvatarGroup, GroupAvatar, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { map, reverse, takeRight, uniqBy } from 'lodash-es';
import { MouseEventHandler, useMemo } from 'react';

import CommentFeedItem from './CommentFeedItem';
import * as s from './SubCommentList.css';

type Props = {
  items: CommentDetail[];
  totalCount: number;
  currentUser?: GroupCurrentUser;
  isCommentDetailPage?: boolean;
  disabledSubCommentInput?: boolean;
  onReadMoreClick?: MouseEventHandler;
  onSubCommentMenuClick?: (subComment: CommentDetail) => MouseEventHandler;
  onSubCommentContentClick?: MouseEventHandler;
  onSubCommentInputClick?: MouseEventHandler;
  onSubCommentLikeReactionClick?: (commentId: number) => MouseEventHandler;
  onSubCommentDislikeReactionClick?: (commentId: number) => MouseEventHandler;
  onSubCommentProfileClick?: (subComment: CommentDetail) => MouseEventHandler;
};

// Ref: https://github.com/daangn/community-web-agora/blob/main/apps/agora/src/features/CommentList/CommentListItem.tsx#L57
const makeRestSubCommentAuthors = (subComments: CommentDetail[]) => {
  const subCommentWriters = map(subComments, 'author');
  const uniqSubCommentWriters = uniqBy(subCommentWriters, 'id');

  return takeRight(uniqSubCommentWriters, 2);
};

const SubCommentList = ({
  items,
  totalCount,
  currentUser,
  isCommentDetailPage,
  disabledSubCommentInput,
  onReadMoreClick,
  onSubCommentMenuClick,
  onSubCommentContentClick,
  onSubCommentInputClick,
  onSubCommentLikeReactionClick,
  onSubCommentDislikeReactionClick,
  onSubCommentProfileClick,
}: Props) => {
  const subComments = useMemo(
    () => (isCommentDetailPage ? items : takeRight(reverse([...items]), 4)),
    [isCommentDetailPage, items]
  );

  const restAuthors = useMemo(() => makeRestSubCommentAuthors(items), [items]);
  const restCount = useMemo(
    () => totalCount - subComments.length,
    [subComments.length, totalCount]
  );

  const hasMoreSubComments = useMemo(
    () => !isCommentDetailPage && restCount > 0,
    [isCommentDetailPage, restCount]
  );
  const hasSubCommentInput = useMemo(
    () => !isCommentDetailPage && !disabledSubCommentInput,
    [isCommentDetailPage, disabledSubCommentInput]
  );

  return (
    <div className={s.SubCommentList}>
      {hasMoreSubComments && (
        <div className={s.MoreSubCommentList} onClick={onReadMoreClick}>
          <AvatarGroup size="xxsmall">
            {restAuthors.map((author) => (
              <Avatar
                key={author.id}
                src={author.profileImage}
                alt={author.nickname}
                fallback={
                  <img
                    src="https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png"
                    alt="profile_default_image"
                  />
                }
              />
            ))}
          </AvatarGroup>
          <VerticalSpacing size={4} />
          <Typography typography="label4Bold" color="gray700">
            이전 답글 {restCount}개 더보기
          </Typography>
          <VerticalSpacing size={4} />
          <IconChevronRightFill size={12} color={vars.$scale.color.gray600} />
        </div>
      )}
      {subComments.map((subComment) => (
        <CommentFeedItem
          key={subComment.id}
          item={subComment}
          onProfileClick={onSubCommentProfileClick?.(subComment)}
          onMenuClick={onSubCommentMenuClick?.(subComment)}
          onContentClick={onSubCommentContentClick}
          onLikeReactionClick={onSubCommentLikeReactionClick?.(subComment.id)}
          onDislikeReactionClick={onSubCommentDislikeReactionClick?.(subComment.id)}
        />
      ))}
      {hasSubCommentInput && currentUser && (
        <div className={s.SubCommentInputContainer}>
          <GroupAvatar src={currentUser.profileImage} size="xsmall" />
          <VerticalSpacing size={8} />
          <button className={s.SubCommentInputButton} onClick={onSubCommentInputClick}>
            <Typography typography="caption2Regular" color="gray600">
              답글을 입력해주세요.
            </Typography>
          </button>
        </div>
      )}
    </div>
  );
};

export default SubCommentList;
