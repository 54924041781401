import { groupClient } from '@community-group/api';
import { MeetupProgressStatus } from '@community-group/components';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getMyGroupsMeetupLikesPath = `${GROUP_URL}/my-groups/meetups/likes`;

type Props = {
  progressStatuses?: MeetupProgressStatus['status'][];
};

export const useGetMyGroupsMeetupLikes = ({ progressStatuses }: Props) => {
  const fetchInstance = useFetchInstance();
  const getMyGroupsMeetupLikes = groupClient.api.MyGroupsApi.apiV1GroupsMyGroupsMeetupsLikesGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, isFetchingNextPage, data, refetch, hasNextPage } = useInfiniteQuery(
    [getMyGroupsMeetupLikesPath, progressStatuses],
    ({ pageParam = undefined }) => getMyGroupsMeetupLikes(pageParam, 20, progressStatuses),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
