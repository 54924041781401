import { AsyncBoundary } from '@community-group/components';
import clsx from 'clsx';
import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import useVisualViewPortFocusInput, {
  VisualViewPortFocusInputProps,
} from '@/hooks/useVisualViewPortFocusInput';

import * as s from './ContentsLayout.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  padding?: string;
  className?: string;
  accessoryBar?: ReactNode;
};
export interface LayoutRefProps {
  layoutRef: MutableRefObject<HTMLDivElement | null>;
  setViweportInput: (props: VisualViewPortFocusInputProps) => void;
  internalRef?: MutableRefObject<HTMLDivElement | null>;
  contentsRef?: MutableRefObject<HTMLDivElement | null>;
}

const Layout = forwardRef((props: Props, ref: ForwardedRef<LayoutRefProps>) => {
  const { children, padding, accessoryBar, ...res } = props;
  const internalRef = React.useRef<HTMLDivElement>(null);
  const contentsRef = React.useRef<HTMLDivElement>(null);
  const [viewportInput, setViweportInput] = useState<VisualViewPortFocusInputProps>({});

  // input focus시 viewport에 맞춰서 스크롤 되도록 하는 hook
  useVisualViewPortFocusInput(viewportInput);

  const controls = useMemo(
    () => ({ layoutRef: internalRef, setViweportInput, internalRef, contentsRef }),
    [internalRef]
  );
  useImperativeHandle(ref, () => ({ ...controls }));

  return (
    <div
      className={clsx([res.className, s.viewLayoutWrapper, 'view-layout'])}
      ref={internalRef}
      {...res}
    >
      <div className={s.contentsSection} style={{ padding }} ref={contentsRef}>
        {children}
      </div>
      {accessoryBar && (
        <AsyncBoundary>
          <div
            className={clsx(['layer-bottom', s.bottomWrapper])}
            ref={internalRef}
            id="layer-bottom"
          >
            {accessoryBar}
          </div>
        </AsyncBoundary>
      )}
    </div>
  );
});

type LayoutWrapperProps = React.HTMLAttributes<HTMLDivElement> & { padding?: string };
const LayoutWrapper = forwardRef(
  ({ padding, ...rest }: LayoutWrapperProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div
      {...rest}
      className={clsx([rest.className, s.layoutWrapper])}
      style={{ padding: padding }}
      ref={ref}
    />
  )
);

export { Layout, LayoutWrapper };
