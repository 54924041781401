import { Map, Marker } from '@daangn/maps-react';

import { ClientOnly } from '../../ClientOnly';
import { PlaceTagProps } from '.';
import * as s from './style.css';

type Props = {
  placeTag: PlaceTagProps['placeTag'];
  theme: PlaceTagProps['theme'];
};

export const PlaceTagMap = ({ theme, placeTag }: Props) => {
  const position = {
    lng: placeTag.coordinates.longitude,
    lat: placeTag.coordinates.latitude,
  };

  if (!placeTag.coordinates.longitude || !placeTag.coordinates.latitude) return null;

  return (
    <ClientOnly
      fallback={<div className={s.Map} />}
      render={() => (
        <Map
          theme={theme}
          className={s.Map}
          initialMapState={{
            center: position,
            offsetByPixels: { y: 20 },
          }}
          interactive={false}
          module={false}
        >
          <Marker anchor="bottom" position={position} />
        </Map>
      )}
    />
  );
};
