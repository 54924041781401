import { ComponentType, memo } from 'react';

import TransparentNavScreen, { AppendRightProps } from '@/components/common/TransparentNavScreen';
import { AppScreen, AppScreenProps } from '@/stackflow/components/AppScreen';

import GroupDetailDefaultAppScreen from '../../Detail/components/GroupDetailDefaultAppScreen';
import * as s from './GroupDetailAppScreen.css';

type Props = {
  title: string;
  transparent: boolean;
  appendRight: ComponentType<AppendRightProps>;
  isExistHeroSlider: boolean;
} & AppScreenProps;

// V2 모임 상세 AppScreen
const GroupDetailAppScreen = ({
  title,
  transparent,
  appendRight,
  isExistHeroSlider,
  children,
  ...props
}: Props) => {
  // 히어로 슬라이더 사용하는 경우 && 히어로 슬라이더 있을 때
  if (isExistHeroSlider)
    return (
      <AppScreen disabledClientOnly hasAppBar={false} {...props}>
        <TransparentNavScreen
          name={title}
          navbarStyle={'gradient'}
          explicitIconStyle="back"
          renderRight={appendRight}
        >
          <div className={s.ChildrenWrapper}>{children}</div>
        </TransparentNavScreen>
      </AppScreen>
    );

  // 히어로 슬라이더 사용하는 경우 && 히어로 슬라이더 없을 때
  return (
    <GroupDetailDefaultAppScreen name={title} renderRight={appendRight} {...props}>
      {children}
    </GroupDetailDefaultAppScreen>
  );
};

export default memo(GroupDetailAppScreen);
