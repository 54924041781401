import {
  PostTypePresentation,
  PostTypePresentationType,
} from '@community-group/api/lib/group/models';

type GroupFeedType = PostTypePresentationType | 'meetup';

export const getGroupFeedType = (postType?: PostTypePresentation): GroupFeedType => {
  // post는 일반 게시글과 일정을 포함 / meetupReview는 일정 후기
  // meetup이 있으면서 post인 경우 일정(meetup)
  const typeName = postType?.type ?? 'post';
  const isMeetupPost = Boolean(typeName === 'post' && postType?.meetupId);
  return isMeetupPost ? 'meetup' : typeName;
};
