import React from 'react';

import * as s from './index.css';

type LoadingProgressCircleProps = {
  progress: number;
  width?: string; //  '100px'
  height?: string; // '100px'
  progressColor?: string; //  '#4caf50'
  trackColor?: string; // '#ddd'
  strokeWidth?: number; // 6
};

export const LoadingProgressCircle = ({
  progress,
  width = '100px',
  height = '100px',
  progressColor = '#4caf50',
  trackColor = '#ddd',
  strokeWidth = 2.8,
}: LoadingProgressCircleProps) => {
  const additionalSpace = strokeWidth / 2; // StrokeWidth에 맞춘 추가 공간
  const adjustedViewBox = `-${additionalSpace} -${additionalSpace} ${36 + strokeWidth} ${
    36 + strokeWidth
  }`; // ViewBox 중앙으로 이동

  return (
    <div className={s.ProgressCircle} style={{ width, height }}>
      <svg viewBox={adjustedViewBox} className={s.Icon}>
        <path
          className={s.Track}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
          style={{ stroke: trackColor, strokeWidth }}
        />
        <path
          className={s.Progress}
          style={{
            strokeDasharray: `${progress}, 100`,
            stroke: progressColor,
            strokeWidth,
          }}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
  );
};
