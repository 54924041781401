import imageBlobReduce from 'image-blob-reduce';
import { v4 } from 'uuid';

export const reduceImageFiles = async (files: Blob[]) => {
  const reduce = new imageBlobReduce();

  return Promise.all(
    files.map(async (file) => {
      const blob = await reduce.toBlob(file, { max: 900 });
      return new File([blob], v4(), { type: file.type });
    })
  );
};

export const getImageDimensions = async (
  imageFile: Blob
): Promise<{ width: number; height: number }> =>
  new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve({ width: image.width, height: image.height });

      URL.revokeObjectURL(image.src);
    };

    image.onerror = () => {
      resolve({ width: 300, height: 300 });

      URL.revokeObjectURL(image.src);
    };

    image.src = URL.createObjectURL(imageFile);
  });
