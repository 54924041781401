import { VITE_APP_HASH_ID, VITE_APP_USER_AUTH_TOKEN, VITE_APP_USER_ID } from '@/constants/common';

export type AppInfo = {
  app: AppConfigInfo;
  region: RegionInfo;
  user: UserInfo;
};

type AppConfigInfo = {
  locale: string;
  appVersion: string;
  country: string;
  appVersionCode: string;
  appHost: string;
  userAgent: string;
  deviceIdentity: string;
};

type RegionInfo = {
  fullname: string;
  id: number;
  name1Id: number;
  name2Id: number;
  name3Id: number;
  name: string;
  name1: string;
  name2: string;
  name3: string;
  centerCoordinates: {
    longitude: number;
    latitude: number;
  };
};

type UserInfo = {
  id: number;
  hashId: string;
  authToken: string;
  phone: string;
  nickname: string;
  profileImage: string;
  displayRegionCheckinsCount: number;
  sample: boolean;
};

export const mockAppInfo: AppInfo = {
  app: {
    locale: 'ko-KR',
    appVersion: '23.12.0',
    country: 'KR',
    appVersionCode: '231200',
    appHost: 'api.alpha.kr.karrotmarket.com',
    userAgent: 'TowneersApp/23.12.0/231200 iOS/16.1.0/1953.1 iPhone13,3',
    deviceIdentity: 'C65B6DF9-55B5-42CD-827E-25F85F34B079',
  },
  region: {
    fullname: '서울 서초구 서초4동',
    id: 366,
    name1Id: 1,
    name2Id: 87,
    name3Id: 366,
    name: '서초4동',
    name1: '서울',
    name2: '서초구',
    name3: '서초4동',
    centerCoordinates: {
      longitude: 127.022388,
      latitude: 37.502675,
    },
  },
  user: {
    id: VITE_APP_USER_ID,
    hashId: VITE_APP_HASH_ID,
    authToken: VITE_APP_USER_AUTH_TOKEN,
    phone: '01012345678',
    nickname: '진겸학우',
    profileImage:
      'https://d1rb42tpk2uaws.cloudfront.net/origin/profile/202301/9056CB4532164BD16498E2ABA301E297DFED570FAFF6687C477953E3CA54F12A.jpg?q=82&s=640x640&t=crop',
    displayRegionCheckinsCount: 1,
    sample: false,
  },
};
