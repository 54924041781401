import { DismissableInlineAlert, InlineAlert } from '@daangn/sprout-components-inline-alert';
import { useEffect } from 'react';

import useGetIsExistHeroSlider from '@/components/group/Detail/hooks/useGetIsExistHeroSlider';
import { useFlow } from '@/stackflow';

import useGroupDetailHeroImageBannerClosed from '../../hooks/useGroupDetailHeroImageBannerClosed';

type Props = { groupId: number };

function GroupDetailHeroSliderBanner({ groupId }: Props) {
  const { push } = useFlow();

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId: groupId.toString() });
  const [_, addGroupDetailHeroImageBannerShownCount] = useGroupDetailHeroImageBannerClosed(
    groupId.toString()
  );
  useEffect(() => {
    addGroupDetailHeroImageBannerShownCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAlert = (event) => {
    const contentDiv = event.currentTarget.querySelector('[data-part="content"]');
    if (!contentDiv || !contentDiv.contains(event.target)) return;
    if (isExistHeroSlider) {
      push('GroupSettingHeroImageSelectorPage', {
        groupId: groupId.toString(),
      });
      return;
    }
    push('GroupPostNewPage', {
      groupId,
      from: 'HeroImage',
    });
  };

  if (isExistHeroSlider) {
    return (
      <div onClick={handleClickAlert}>
        <DismissableInlineAlert
          description="모임 앨범에서 사진을 선택해 배경사진을 설정해보세요."
          variant="info"
          onClose={addGroupDetailHeroImageBannerShownCount}
        />
      </div>
    );
  }

  return (
    <div onClick={handleClickAlert}>
      <InlineAlert
        description="모임 상단에 사진을 노출하려면, 사진이 포함된 게시글을 작성해보세요."
        variant="info"
        action={{
          label: '글 작성하기',
          onClick: () => {
            push('GroupPostNewPage', {
              groupId,
              from: 'HeroImage',
            });
          },
        }}
      />
    </div>
  );
}

export default GroupDetailHeroSliderBanner;
