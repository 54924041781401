import clsx from 'clsx';
import { useState } from 'react';
import { Path, PathValue, UnpackNestedValue, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import * as s from './Title.css';

interface Props<T extends object> extends React.InputHTMLAttributes<HTMLInputElement> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupFormTitleField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value as UnpackNestedValue<PathValue<T, Path<T>>>);
  };

  return (
    <div className={s.wrapper}>
      {!focused && (watch(name) ?? '').length <= 0 && <div className={s.fakeCaret} />}
      <input
        data-field-name={name}
        {...props}
        className={clsx([props.className, s.input])}
        defaultValue={watch(name)}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};
