import { ActivityComponentType } from '@stackflow/react';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import PhotoContestGuideV2Page from '../PhotoContestV2/pages/PhotoContestGuidePage_V2';
import PhotoContestGuideV3Page from '../PhotoContestV3/pages/PhotoContestGuidePage_V3';

const PhotoContestGuidePage: ActivityComponentType = () => {
  const photoContestVersion = useFeatureFlag('photoContestVersion');

  // 사진전 3회차인 경우
  if (photoContestVersion === 3) {
    return <PhotoContestGuideV3Page />;
  }

  // 사진전 2회차인 경우
  return <PhotoContestGuideV2Page />;
};

export default PhotoContestGuidePage;
