import { Switch } from '@community-group/components';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutBoardManagedStatus } from '@/api/hooks/usePutBoardManagedStatus';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import SettingMenuItem from './SettingMenuItem';

const BoardCategorySection = () => {
  const { groupId } = usePathParams();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { group, refetch } = useGetGroupDetail(groupId);

  const { watch, control } = useForm<FieldValues>({
    defaultValues: {
      boardCategoryActive: group?.isBoardManaged,
    },
  });

  const boardCategoryActiveFieldValue = watch('boardCategoryActive');

  const { push } = useFlow();

  const { mutate } = usePutBoardManagedStatus({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetch();
    },
  });

  const handleGotoBoardCategorySetting = () => {
    trackEvent({
      event: `click_board_category_setting`,
      params: {
        groupId,
      },
    });
    push('BoardCategorySetting', {
      groupId: groupId,
    });
  };

  return (
    <>
      <SettingMenuItem
        title="게시판 나누기"
        description="피드 게시글을 게시판별로 관리할 수 있어요."
        renderRight={
          <Controller
            control={control}
            name="boardCategoryActive"
            render={({ field: { onChange, name, value } }) => (
              <Switch
                onChange={(e) => {
                  onChange(e);
                  mutate({
                    id: Number(groupId),
                    postBoardManagedForm: {
                      isBoardManaged: e,
                    },
                  });
                }}
                name={name}
                isSelected={value}
              />
            )}
          />
        }
      />
      {boardCategoryActiveFieldValue && (
        <SettingMenuItem title="게시판 관리" onClick={handleGotoBoardCategorySetting} />
      )}
    </>
  );
};

export default BoardCategorySection;
