import { ActionableCallout, Typography } from '@community-group/components';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

function PrevWeekBanner() {
  const { push } = useFlow();
  const handleClickPrevWeekBanner = () => {
    trackEvent({
      event: 'click_leaderboard_last_week_banner',
    });
    push('GroupLeaderBoard', {
      isCurrentWeek: 'false',
    });
  };
  return (
    <ActionableCallout width="100%" variant="normal" onClick={handleClickPrevWeekBanner}>
      <Typography typography="bodyM2Regular" color="gray900">
        지난 주 <b>1위</b>는 어떤 모임이었을까요?
      </Typography>
    </ActionableCallout>
  );
}

export default PrevWeekBanner;
