import { useMemo } from 'react';

import { useStorage } from './useStorage';

export const useGetParentingGroupStorage = (groupId: number) => {
  const [parentingGroup] = useStorage('parentingGroup', {});
  return useMemo(
    () => parentingGroup?.[groupId] ?? { isKidProfileEnabled: false, isTargetGroup: false },
    [groupId, parentingGroup]
  );
};
