import { useCallback } from 'react';
import { FieldValues, Resolver, useForm, UseFormProps, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useStorage } from '@/hooks/useStorage';
import { useStore } from '@/store';
import { CreateGroupFormType } from '@/store/groupCreate/createGroupSlice';

type Props = {
  initialize?: boolean;
} & FormOptions;

type FormOptions = UseFormProps<FieldValues, Resolver>;

// 모임 생성 플로우 데이터 자동 임시 저장 hook
const useCreateForm = ({ initialize = true, ...options }: Props) => {
  const [dataValue, handleSetDataValue] = useStorage('createGroupForm', {} as CreateGroupFormType);
  const { createForm, setCreateForm } = useStore();

  const { control, reset, ...rest } = useForm({
    ...options,
    defaultValues: {
      ...options.defaultValues,
      ...(initialize ? createForm : {}),
    },
  });

  const watchedData = useWatch({
    control: control,
  });

  // auto saved data 초기 셋팅
  const loadAndInitAutoSavedData = () => {
    if (dataValue) {
      setCreateForm(dataValue);
      reset(dataValue);
    }
  };

  const handleSaveTemp = useCallback(
    (newValue: CreateGroupFormType) => {
      handleSetDataValue({ ...createForm, ...newValue });
      setCreateForm({ ...createForm, ...newValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createForm]
  );

  // 데이터 변경 시 data 업데이트
  useDeepCompareEffect(() => {
    handleSaveTemp(watchedData);
  }, [watchedData, initialize]);

  // 로컬 스토리지에 저장된 auto saved data 유무
  const hasAutoSavedData = !!(dataValue && (dataValue?.categoryId || dataValue?.name));

  const resetCreateForm = useCallback(() => {
    reset({});
    setCreateForm({});
    handleSetDataValue({});
  }, [handleSetDataValue, reset, setCreateForm]);

  return {
    hasAutoSavedData,
    dataValue: createForm as CreateGroupFormType,
    loadAndInitAutoSavedData,
    resetCreateForm,
    control,
    ...rest,
  };
};

export default useCreateForm;
