import { Divider, Spacing, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useState } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupLevel } from '@/api/hooks/useGetGroupLevel';
import { useUserConfig } from '@/contexts/UserConfig';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import CurrentLevelBadge from '../Components/CurrentLevelAndMission';
import LevelCalculationGuide from '../Components/LevelCalculationGuide';
import LevelPolicyInformationSection from '../Components/LevelPolicyInformationSection';
import { GroupLevelKey, mockGroupLevelDetails } from '../utils/mockLevelData';

type GroupLevelDetailPageParams = Pick<PageParams, 'groupId' | 'openActivityPoint'>;

const GroupLevelDetailPage: ActivityComponentType<GroupLevelDetailPageParams> = () => {
  const [mockGroupLevelDetailResponseId, setMockGroupLevelDetailResponseId] =
    useState<GroupLevelKey>('서버에서-받아온-데이터');

  const isAlpha = globalThis.appConfig.appEnv !== 'production';
  const { groupId } = usePathParams();
  const { data: level } = useGetGroupLevel({ groupId: Number(groupId) });
  const { group } = useGetGroupDetail(groupId);

  const {
    userConfig: { userId },
  } = useUserConfig();
  useEnterTrackEvent({
    event: 'enter_level_detail_page',
    params: {
      currentLevel: level?.currentLevel,
      currentProgressPercentage: level?.forLevelUp.percentage,
      groupId,
      userId: userId ?? 0,
      categoryId: group?.category.id,
      categoryName: group?.category.name,
    },
  });

  // TODO: 배포 후 제거 - 알파테스트용 Mock data 변환 로직
  const groupLevelDetailResponse = useMemo(() => {
    if (isAlpha) {
      if (mockGroupLevelDetailResponseId === '서버에서-받아온-데이터') {
        return level;
      }
      return mockGroupLevelDetails[mockGroupLevelDetailResponseId];
    }

    return level;
  }, [level, isAlpha, mockGroupLevelDetailResponseId]);

  return (
    <AppScreen>
      {isAlpha && (
        <MockDataSelector
          mockGroupLevelDetailResponseId={mockGroupLevelDetailResponseId}
          setMockGroupLevelDetailResponseId={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setMockGroupLevelDetailResponseId(event.target.value as GroupLevelKey);
          }}
        />
      )}
      {groupLevelDetailResponse && (
        <CurrentLevelBadge
          currentLevel={groupLevelDetailResponse.currentLevel}
          nextLevel={groupLevelDetailResponse.nextLevel}
          forLevelUp={groupLevelDetailResponse.forLevelUp}
          forMaintain={groupLevelDetailResponse?.forMaintain}
          groupId={groupId}
        />
      )}
      <Spacing size={18} />
      <LevelPolicyInformationSection
        currentLevel={groupLevelDetailResponse?.currentLevel}
        groupId={groupId}
      />
      <Divider size={8} />
      <LevelCalculationGuide currentLevel={groupLevelDetailResponse?.currentLevel ?? 0} />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupLevelDetailPage, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});

// TODO: 모임 레벨 배포 후 제거 - 알파에만 노출되는 Mock data 변경 selector
const MockDataSelector = ({
  setMockGroupLevelDetailResponseId,
  mockGroupLevelDetailResponseId,
}: {
  mockGroupLevelDetailResponseId: GroupLevelKey;
  setMockGroupLevelDetailResponseId: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <select
      value={mockGroupLevelDetailResponseId}
      onChange={setMockGroupLevelDetailResponseId}
      style={{
        position: 'fixed',
        top: '100px',
        right: '30px',
        zIndex: 9999,
      }}
    >
      {Object.keys(mockGroupLevelDetails).map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  );
};
