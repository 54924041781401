import { useEffect, useRef } from 'react';

import { EventLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams, SpecificEventType } from '@/utils/analytics/type';

type Props = {
  event: SpecificEventType;
  params?: GroupEventParams;
  loggerType?: EventLoggerType[];
  sample?: boolean;
  enabled?: boolean;
};

export const useEnterTrackEvent = ({
  event,
  params,
  loggerType,
  sample,
  enabled = true,
}: Props) => {
  // enter event 2번 발생 제어
  const loggedEnter = useRef(false);

  useEffect(() => {
    if (!enabled) return;
    if (loggedEnter.current) return;

    loggedEnter.current = true;
    trackEvent({ event, params, sample, loggerType });
  }, [event, enabled]);
};
