import {
  GroupDetailPresentation,
  GroupFeedMeetupInfoPresentation,
  GroupMeetupExposureRangeEnum,
  GroupMeetupProgressStatusPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { getMeetupStatusColor } from '@/components/group/Meetup/Detail/utils/progressStatus';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailFeedMeetupItem from '../GroupDetailFeedMeetupItem';
import * as s from './MeetupSummarySection.css';

type Props = PropsWithChildren<{
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
  shouldSetSubNickname: boolean;
  totalCount: number;
  meetups: GroupFeedMeetupInfoPresentation[];
}>;

const MeetupSummarySection = ({
  group,
  currentUserRole,
  shouldSetSubNickname,
  totalCount,
  meetups,
  children,
}: Props) => {
  const hasMeetups = useMemo(() => totalCount > 0, [totalCount]);
  const hasMoreMeetups = useMemo(() => totalCount > 3, [totalCount]);
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const { handleGroupOnly, isGroupMember } = useHandleGroupOnly({ groupId });

  const getMeetupLabel = useCallback(
    (
      meetupState: GroupMeetupProgressStatusPresentation,
      exposureRange: GroupMeetupExposureRangeEnum
    ) => {
      const showGroupOnlyLabel = isGroupMember && exposureRange !== 'ALL';
      const exposureRangeLabel = showGroupOnlyLabel ? ' ∙ 모임에만 공개' : undefined;

      return (
        <>
          <Typography typography="caption1Regular" color={getMeetupStatusColor(meetupState.status)}>
            {meetupState.description}
          </Typography>
          {exposureRangeLabel}
        </>
      );
    },
    [isGroupMember]
  );

  const activity = useFlow();
  const handleMeetupListItemClick =
    (meetup: GroupFeedMeetupInfoPresentation, index: number) => () => {
      const blockedGroupOnly = !isGroupMember && meetup.exposureRange !== 'ALL';

      handleGroupOnly({
        isBlock: blockedGroupOnly,
        blockGroupOnlyTitle: '모임 가입 안내',
        blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.enterGroupMeetup,
        onSettled: () => {
          trackEvent({
            event: 'click_meetup_item',
            params: {
              meetupId: meetup.id,
              meetupTitle: meetup.title,
              listItemIndex: index,
              groupId: groupId,
              groupName: group.name,
              groupCategoryName: group.category.name,
              isGroupCategoryOn: group.isBoardManaged,
              role: currentUserRole,
              version: '2',
            },
            sample: true,
          });

          activity.push('GroupMeetupDetailPage', {
            meetupId: meetup.id.toString(),
            groupId,
          });
        },
        onSuccess() {
          if (!shouldSetSubNickname) return;
          activity.push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        },
      });
    };

  return (
    <section className={s.Container}>
      <Typography className={s.Title} typography="title3Bold" color="gray900">
        일정 {hasMeetups && totalCount}
      </Typography>
      {hasMeetups && (
        <>
          <Spacing size={8} />
          <div className={s.SwiperWrapper}>
            <Swiper
              pagination={{
                dynamicBullets: false,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {meetups.slice(0, 3).map((meetup, index) => {
                return (
                  <SwiperSlide key={meetup.id}>
                    <GroupDetailFeedMeetupItem
                      title={meetup.title}
                      label={getMeetupLabel(meetup.progressStatus, meetup.exposureRange)}
                      meetDate={meetup.meetDate}
                      currentParticipantsNumber={meetup.currentParticipantsNumber}
                      maximumParticipantsNumber={meetup.maximumParticipantsNumber}
                      image={meetup.imageUrl}
                      meetTime={meetup?.meetTime}
                      showMonth
                      blockedGroupOnly={!isGroupMember && meetup.exposureRange !== 'ALL'}
                      onClick={handleMeetupListItemClick(meetup, index)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </>
      )}
      {hasMoreMeetups && <div className={s.ButtonWrapper}>{children}</div>}
    </section>
  );
};

export default MeetupSummarySection;
