import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';

import * as s from './Wrapper.css';

interface ModalProps {
  children: React.ReactNode;
  isFullType?: boolean;
  canOutsideClick?: boolean;
  onOutsideClick?: () => void;
  paddingY?: number;
  paddingX?: number;
  innerPaddingBottom?: number;
}
const ModalWrapper = ({
  isFullType,
  children,
  onOutsideClick,
  paddingY,
  paddingX,
  innerPaddingBottom,
}: ModalProps) => {
  return ReactDOM.createPortal(
    <div style={{ position: 'relative' }}>
      {isFullType ? (
        <div style={{ overflowX: 'hidden', overflowY: 'auto', width: '100%', height: '100%' }}>
          <motion.div
            className={s.fullTypeInner}
            initial={{ height: 0 }}
            animate={{
              height: '100%',
            }}
            transition={{
              type: 'spring',
              bounce: 0.1,
              duration: 0.4,
            }}
            exit={{ height: 0 }}
          >
            {children}
          </motion.div>
        </div>
      ) : (
        <motion.div
          className={s.innerWrapper}
          initial={{ height: 0 }}
          animate={{
            height: 'auto',
          }}
          transition={{
            type: 'spring',
            bounce: 0.1,
            duration: 0.4,
          }}
          exit={{ height: '0' }}
        >
          <div
            style={{
              padding: `${paddingY}px ${paddingX}px calc(${
                innerPaddingBottom ?? 1
              }rem + env(safe-area-inset-bottom)) ${paddingX}px`,
            }}
          >
            {children}
          </div>
        </motion.div>
      )}
      <motion.div
        className={s.layerWrapper}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        onClick={onOutsideClick}
      />
    </div>,
    document.getElementById('layer') as Element
  );
};

export default ModalWrapper;

type HalfModalTitleProps = React.HTMLAttributes<HTMLParagraphElement>;
export const HalfModalTitle = forwardRef<HTMLParagraphElement, HalfModalTitleProps>(
  (props: HalfModalTitleProps, ref) => <p {...props} className={s.halfModalTitle} ref={ref} />
);
