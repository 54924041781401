import { useGetHomeList } from '@/api/hooks/useGetHomeList';
import { ProfileImage } from '@/components/common/Image/Profile';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './RecommendedGroupListSection.css';

const RecommendedGroupListSection = () => {
  const { push } = useFlow();

  const { data } = useGetHomeList({
    order: 'recommendScoreDesc',
  });

  const groupList = data?.pages[0].data.groups;

  if (!groupList) return <></>;

  return (
    <div className={s.Wrapper}>
      <div className={s.TitleWrapper}>
        <h3 className={s.Title}>추천 모임</h3>
      </div>
      <ul className={s.GroupListWrapper}>
        {groupList.map(({ id, profileImage, name, region, category, memberCount }) => {
          const descriptionSet: string[] = [];

          if (region) descriptionSet.push(`${region.name} ∙ `);
          if (category) descriptionSet.push(`${category.name} ∙ `);
          descriptionSet.push(`멤버 ${memberCount}`);

          return (
            <li
              className={s.GroupItem}
              key={id}
              onClick={() => {
                push('GroupDetailPage', {
                  groupId: id.toString(),
                });

                trackEvent({
                  event: 'click_recommended_group_list_item',
                  params: {
                    groupName: name,
                    groupId: id,
                  },
                  sample: true,
                });
              }}
            >
              <ProfileImage src={profileImage.small} size={40} />
              <div className={s.GroupContents}>
                <h4 className={s.GroupTitle}>{name}</h4>
                <p className={s.GroupDescription}>{descriptionSet.join('')}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecommendedGroupListSection;
