import { BoxButton, Dialog, Typography, useDialog } from '@community-group/components';

import { useDeleteApplication } from '@/api/hooks/useDeleteApplication';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import * as s from './PendingJoinedGroupView.css';

const PendingJoinedGroupView = () => {
  const { groupId } = usePathParams();

  const { refetch: refetchGroupMe } = useGetGroupMe(groupId);
  const { bridge } = useBridge();
  const { open: openDialog, close: closeDialog } = useDialog();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: deleteApplicationMutate } = useDeleteApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupMe();
      bridge.openToast({
        toast: {
          body: '가입 신청을 취소했어요.',
        },
      });
    },
  });

  const handleJoinCancel = () => {
    openDialog({
      element: (
        <Dialog
          title="가입 신청 취소"
          description="가입 신청을 그만둘까요?"
          primaryActionLabel="그만두기"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            await closeDialog();
            await deleteApplicationMutate(Number(groupId));
          }}
        />
      ),
    });
  };

  return (
    <div className={s.PendingContainer}>
      <Typography typography="label3Bold" color="gray700">
        가입 승인을 기다리고 있어요
      </Typography>
      <BoxButton size="medium" variant="secondary" onClick={handleJoinCancel}>
        신청 취소
      </BoxButton>
    </div>
  );
};

export default PendingJoinedGroupView;
