import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { getUserExperimentalABGroup } from '@/utils/analytics/experiment/userExperimentalGroup';
import { isDevelopmentAppStage } from '@/utils/check';

type Props = {
  from?: string;
};

export const useInitialCreateGroupLLMExperiment = ({ from }: Props) => {
  const { push } = useFlow();
  const { userConfig } = useUserConfig();

  const treatmentUser = getUserExperimentalABGroup(userConfig?.userId ?? '');

  const handleActivityCreateGroupLLMExperiment = () => {
    if (isDevelopmentAppStage() || treatmentUser === 'B') {
      push('GroupNewSetGroupCategoryRecommendPage', { from });
      return;
    }
    push('GroupNewSetGroupCategoryPage', { from });
  };

  return {
    handleActivityCreateGroupLLMExperiment,
  };
};
