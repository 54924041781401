import { RefObject } from 'react';

import MeetupCommentFormAccessoryBar from './components/MeetupCommentFormAccessory';
import PostCommentFormAccessoryBar from './components/PostCommentFormAccessoryBar';

type Props = {
  listRef: RefObject<HTMLDivElement>;
  groupId: string;
  relatedId: string;
  relatedContentType: 'post' | 'meetup';
  parentCommentId?: string;
  placeholder?: string;
};

const CommentFormAccessoryBar = ({ relatedContentType, ...props }: Props) => {
  if (relatedContentType === 'meetup') {
    return <MeetupCommentFormAccessoryBar {...props} />;
  }

  return <PostCommentFormAccessoryBar {...props} />;
};

export default CommentFormAccessoryBar;
