import {
  HelpBubbleTrigger as SproutHelpBubbleTrigger,
  SeedHelpBubbleProps,
} from '@daangn/sprout-components-help-bubble';
import React from 'react';

type Props = {
  children: React.ReactNode;
} & SeedHelpBubbleProps;

export const HelpBubbleTrigger = ({ children, ...props }: Props) => {
  return <SproutHelpBubbleTrigger {...props}>{children}</SproutHelpBubbleTrigger>;
};
