import memoize from 'lodash-es/memoize';

import { useDeviceConfig } from '@/contexts/DeviceConfig';

export type Theme = 'android' | 'cupertino';

export const isDarkTheme = () => document?.documentElement?.dataset?.seedScaleColor === 'dark';

export const useTheme = () => {
  const { deviceConfig } = useDeviceConfig();

  const getTheme = memoize((): Theme => {
    return deviceConfig.theme;
  });

  return {
    getTheme,
  };
};
