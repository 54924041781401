import { Skeleton, Spacing } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconUserGroupFill, IconViewCountFill } from '@seed-design/icon';

import * as s from './GroupMeetupHomeListSkeleton.css';

const GroupMeetupHomeListSkeleton = ({ length = 5 }: { length?: number }) => (
  <div className={s.wrapper}>
    <div className={s.contentsWrapper}>
      {Array.from({ length }).map((item, index) => (
        <div className={s.item} key={`talk-skeleton-${index}`}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Skeleton width="20px" height="20px" borderRadius="4px" />
            <VerticalSpacing size={8} />
            <Skeleton width="200px" height="0.8rem" enableAnimation />
          </div>
          <Spacing size={12} />
          <div className={s.body}>
            <div className={s.bodyContents}>
              <Skeleton width="90%" height="1rem" enableAnimation />
              <Spacing size={4} />
              <Skeleton width="30%" height="0.875rem" enableAnimation />
              <Spacing size={9} />
              <div className={s.footerWrapper}>
                <div className={s.infoWrapper}>
                  <IconUserGroupFill {...IconConfig} />
                  <Skeleton width="2rem" height="0.875rem" />
                </div>
                <div className={s.infoWrapper}>
                  <IconViewCountFill {...IconConfig} />
                  <Skeleton width="2rem" height="0.875rem" />
                </div>
              </div>
            </div>
            {index % 2 === 0 && <Skeleton width="72px" height="72px" borderRadius="6px" />}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default GroupMeetupHomeListSkeleton;

const IconConfig = {
  width: '16px',
  height: '16px',
  color: vars.$scale.color.gray500,
};
