import { getGroupMePath } from '@/api/hooks/useGetGroupMe';
import { queryClient } from '@/api/instance';
type Params = {
  groupId: string;
};

/*
 *  currentUser 상태관련 refetch
 */
export const refetchCurrentUser = ({ groupId }: Params) => {
  queryClient.refetchQueries([getGroupMePath(groupId)]);
};
