import 'dayjs/locale/ko';

import dayjs from 'dayjs';

export const renderMeetupDateText = (meetDate: string, meetTime?: string) => {
  const meetupDateText: string[] = [];

  meetupDateText.push(dayjs(meetDate).format('M월 D일 dddd'));

  if (meetTime) {
    meetupDateText.push(dayjs(meetTime).format('A hh:mm'));
  }

  return meetupDateText.join(', ');
};
