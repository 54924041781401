import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetBoardTypeList = (id: number, refetchInterval = 10000) => {
  const fetchInstance = useFetchInstance();
  const getGetBoardTypes = groupClient.api.PostBoardApi.apiV1GroupsIdPostsBoardCategoriesGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useQuery(
    [groupClient.api.PostBoardApi.getapiV1GroupsIdPostsBoardCategoriesGetPath(id)],
    () => getGetBoardTypes(id),
    {
      suspense: true,
      refetchInterval,
    }
  );

  return {
    data: data?.data,
    refetch,
  };
};
