import { groupClient } from '@community-group/api';
import { TalkRoomInfoResponse } from '@community-group/api/lib/group/models';
import { useQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export const useGetTalkRoomInfo = (
  groupId: number,
  roomId?: number | string,
  onError?: () => void,
  retry?: number
) => {
  const fetchInstance = useFetchInstance();
  const getTalkInfo = groupClient.api.TalkRoomApi.apiV1GroupsIdRoomsRoomIdGet({
    axios: fetchInstance,
  });

  const { data, refetch, isLoading, status } = useQuery(
    [`${GROUP_URL}/${groupId}/rooms/${roomId}`],
    () => {
      if (!roomId) return Promise.resolve({ data: {} });
      return getTalkInfo(groupId, Number(roomId));
    },
    {
      suspense: true,
      onError: onError,
      retry: retry,
    }
  );

  const response: TalkRoomInfoResponse | undefined = data?.data;

  return { data: response, refetch, isLoading, status };
};
