import { Spacing } from '@community-group/components';

import { Breadcrumbs as CommonBreadcrumbs } from '@/components/common/Breadcrumbs';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const MeetupDetailBreadcrumbs = () => {
  const { groupId } = usePathParams();
  const { push } = useFlow();

  return (
    <>
      <CommonBreadcrumbs
        prefixIconType="post"
        items={['일정']}
        onClick={() => {
          push('GroupDetailPage', {
            groupId,
            activeTab: 'meetups',
            boardCategoryKey: '1',
          });
        }}
      />
      <Spacing size={4} />
    </>
  );
};

export default MeetupDetailBreadcrumbs;
