import { StoreSlice } from '..';

export type InputSizeSlice = {
  stickyInputResize: boolean;
  setStickyInputResize: (resize: boolean) => void;
};

export const createInputSizeSlice: StoreSlice<InputSizeSlice> = (set) => ({
  stickyInputResize: false,
  setStickyInputResize: (resize) => {
    set((state) => ({
      ...state,
      stickyInputResize: resize,
    }));
  },
});
