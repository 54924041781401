import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog';
import { ActivityComponentType } from '@stackflow/react';

import { useConnectedFlow } from '@/stackflow';

export type CommentDeleteModalPopResponseType = {
  type: 'confirm' | 'cancel';
};

const CommentDeleteModal: ActivityComponentType = () => {
  const { pop } = useConnectedFlow();

  return (
    <DialogContainer
      onOutsideClick={() =>
        pop().send({
          type: 'cancel',
        })
      }
    >
      <AlertDialog
        title="댓글을 삭제할까요?"
        description="모든 데이터가 삭제되고 다시 볼 수 없어요."
        primaryActionLabel="삭제"
        secondaryActionLabel="취소"
        onPrimaryAction={() => {
          pop().send({
            type: 'confirm',
          });
        }}
        secondaryActionIntent="neutral"
        onSecondaryAction={() => {
          pop().send({
            type: 'cancel',
          });
        }}
      />
    </DialogContainer>
  );
};

export default CommentDeleteModal;
