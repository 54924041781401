import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Form = { groupId: number; kidId: string; form: model.ParentingGroupKidForm };

type Props = UseMutationOptions<AxiosResponse, Error, Form>;

export const usePutParentingGroupKidProfile = (props: Props) => {
  const fetchInstance = useFetchInstance();
  const putKid = groupClient.api.ParentingGroupApi.apiV1GroupsGroupIdParentingKidKidIdPut({
    axios: fetchInstance,
  });

  return useThrottleMutation(({ groupId, kidId, form }: Form) => {
    return putKid(groupId, kidId, form);
  }, props);
};
