import {
  SeedTextButtonProps,
  TextButton as SproutTextButton,
} from '@daangn/sprout-components-button';

export const TextButton = ({
  variant,
  size,
  children,
  isDisabled,
  UNSAFE_style,
  ...props
}: SeedTextButtonProps) => {
  return (
    <SproutTextButton
      variant={variant}
      size={size}
      isDisabled={isDisabled}
      UNSAFE_style={UNSAFE_style}
      {...props}
    >
      {children}
    </SproutTextButton>
  );
};
