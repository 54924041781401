import { Divider, TextButton } from '@community-group/components';
import { vars } from '@seed-design/design-token';

import * as s from './ParentingGroupKidProfileEditBottomSheet.css';

type Props = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

const ParentingGroupKidProfileEditBottomSheet = ({ onEditClick, onDeleteClick }: Props) => {
  return (
    <ul className={s.ActionSheetList}>
      <li className={s.ActionSheetListItem} onClick={onEditClick}>
        <TextButton variant="secondary">아이 정보 수정</TextButton>
      </li>
      <Divider background={vars.$semantic.color.divider2} padding={16} />
      <li className={s.ActionSheetListItem} onClick={onDeleteClick}>
        <TextButton variant="danger">삭제</TextButton>
      </li>
    </ul>
  );
};

export default ParentingGroupKidProfileEditBottomSheet;
