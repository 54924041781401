import { Error, ErrorProps } from '@daangn/error-component';
import { MouseEvent } from 'react';

import * as s from './ViewError.css';

type Props = {
  title?: ErrorProps['title'];
  description?: ErrorProps['description'];
  buttonText?: ErrorProps['buttonText'];
  onClickButton?: ErrorProps['onClickButton'];
};

const defaultMessage = (
  <>
    앗! 죄송해요.
    <br />
    해당 페이지로 접근이 불가능해요.
  </>
);

export const ViewError = ({
  title,
  description = defaultMessage,
  buttonText,
  onClickButton,
}: Props) => {
  const handleReloadWebview = () => {
    window.location.href = `${window.location.origin}/home`;
  };

  const handleOnClickButton = (event: MouseEvent<Element, globalThis.MouseEvent>) => {
    return onClickButton ? onClickButton(event) : handleReloadWebview();
  };

  return (
    <div className={s.Container}>
      <Error
        title={title}
        description={
          typeof description === 'string' ? (
            <div
              style={{ wordBreak: 'keep-all' }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <div>{description}</div>
          )
        }
        buttonText={buttonText || '홈으로 이동'}
        onClickButton={handleOnClickButton}
      />
    </div>
  );
};
