import {
  FeedbackRatingEnum,
  GroupMeetupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { ChipToggleButton } from '@community-group/components';

import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';

import * as s from './style.css';

type Props = {
  currentUserMeetupRole: GroupMeetupMemberRoleEnum;
  feedbackRating: FeedbackRatingEnum | undefined;
  onChange: (selectedFeedbackTypeIds: number[]) => void;
  value?: number[];
};
export const MemberFeedbackTypeField = ({
  currentUserMeetupRole,
  feedbackRating,
  value = [],
  onChange,
}: Props) => {
  if (!feedbackRating || feedbackRating === 'EXCELLENT') {
    return <></>;
  }

  const feedbackTypeList = Array.from(MEETUP_FEEDBACK_TYPES[feedbackRating].values()).filter(
    ({ shownUserRole }) => shownUserRole.includes(currentUserMeetupRole)
  );

  const handleChange = (selectedFeedbackTypeId: number) => {
    const isSelected = value.includes(selectedFeedbackTypeId);

    if (isSelected) {
      onChange(value.filter((id) => id !== selectedFeedbackTypeId));
    } else {
      onChange([...value, selectedFeedbackTypeId]);
    }
  };

  return (
    <div className={s.Wrapper}>
      {feedbackTypeList.map(({ id, label }) => {
        const isSelected = value.includes(id);

        return (
          <ChipToggleButton
            key={id}
            isSelected={isSelected}
            onClick={() => {
              handleChange(id);
            }}
          >
            {label}
          </ChipToggleButton>
        );
      })}
    </div>
  );
};
