import { Position } from '@/hooks/useCurrentPosition';

type DistanceResult = {
  distance: number;
  unit: 'm' | 'km';
};

// 각도를 라디안으로 변환하는 함수
const toRadians = (degree: number): number => {
  return degree * (Math.PI / 180);
};

// 하버사인 공식의 구성 요소를 계산하는 함수
const calculateHaversineFormulaComponent = (
  deltaLatitudeInRadians: number,
  deltaLongitudeInRadians: number,
  userLatitudeInRadians: number,
  poiLatitudeInRadians: number
): number => {
  return (
    Math.sin(deltaLatitudeInRadians / 2) * Math.sin(deltaLatitudeInRadians / 2) +
    Math.cos(userLatitudeInRadians) *
      Math.cos(poiLatitudeInRadians) *
      Math.sin(deltaLongitudeInRadians / 2) *
      Math.sin(deltaLongitudeInRadians / 2)
  );
};

type positions = {
  userPosition: Position;
  poiPosition: Position;
};

type config = {
  isRounding?: boolean;
};

// 두 지리적 위치 사이의 거리를 계산하는 함수
export const calculateDistance = (
  { userPosition, poiPosition }: positions,
  config: config = { isRounding: true }
): DistanceResult => {
  const earthRadiusInMeters = 6371e3;
  const userLatitudeInRadians = toRadians(userPosition.latitude);
  const poiLatitudeInRadians = toRadians(poiPosition.latitude);
  const deltaLatitudeInRadians = toRadians(poiPosition.latitude - userPosition.latitude);
  const deltaLongitudeInRadians = toRadians(poiPosition.longitude - userPosition.longitude);

  const haversineFormulaComponent = calculateHaversineFormulaComponent(
    deltaLatitudeInRadians,
    deltaLongitudeInRadians,
    userLatitudeInRadians,
    poiLatitudeInRadians
  );
  const angularDistance =
    2 * Math.atan2(Math.sqrt(haversineFormulaComponent), Math.sqrt(1 - haversineFormulaComponent));

  const distanceInMeters = earthRadiusInMeters * angularDistance;
  const isKilometer = distanceInMeters >= 1000;

  const distance = isKilometer ? distanceInMeters / 1000 : distanceInMeters;

  return {
    distance: config.isRounding ? Math.round(distance) : distance,
    unit: isKilometer ? 'km' : 'm',
  };
};
