import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';

import * as s from './index.css';

type Props = {
  text: string;
  icon?: ReactNode | string;
  iconLocation?: 'left' | 'right';
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * @deprecated
 * 일반적인 ChipButton은 ChipToggleButton을 이용해주세요.
 */

function Chip({ text, icon, iconLocation = 'left', ...rest }: Props) {
  const className = rest.className;

  return (
    <div {...rest} className={clsx(className, 'chip-wrapper', s.chipWrapper)}>
      {icon && iconLocation == 'left' && <IconWrapper icon={icon} />}
      <span className={clsx('chip-text', s.chipText)}>{text}</span>
      {icon && iconLocation == 'right' && <IconWrapper icon={icon} />}
    </div>
  );
}

const IconWrapper = ({ icon }: { icon?: ReactNode | string }) => {
  if (!icon) return null;
  if (React.isValidElement(icon)) {
    return icon as ReactElement | null;
  }
  return <img className={s.icon} src={icon as string} />;
};

export default Chip;
