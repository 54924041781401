import axios from 'axios';

import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';

import { useGetMainProfile } from './useGetMainProfile';

export const useGetMyMainProfile = ({
  suspense = true,
  hasOnError = true,
  queryKeys = [],
}: {
  suspense?: boolean;
  hasOnError?: boolean;
  queryKeys?: (string | number)[];
}) => {
  const { replace } = useFlow();
  const { bridge } = useBridge();
  const { userConfig } = useUserConfig();

  const { data, refetch, isInitialLoading } = useGetMainProfile({
    userId: userConfig.userId ?? 0,
    queryKeys: [...queryKeys, 'MyMainProfile'],
    queryOptions: {
      suspense,
      onError: (error) => {
        if (!hasOnError) return;
        bridge.openToast({
          toast: {
            body: '수정할 프로필이 없어요. 프로필을 생성해주세요.',
          },
        });
        // 프로필 수정시 프로필이 없는 경우 예외 처리
        if (axios.isAxiosError(error)) {
          if (error.response?.data.code === '404.group_member_not_found') {
            replace('GroupProfileCreatePage', {});
          }
        }
      },
    },
  });

  return { data, refetch, isLoading: isInitialLoading };
};
