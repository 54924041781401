import {
  GroupLevelPresentation,
  GroupLevelUpMissions,
} from '@community-group/api/lib/group/models';

export type GroupLevelDetailResponseType = GroupLevelDetailResponse;

type Missions = keyof GroupLevelUpMissions;

type LevelUpDetail = {
  percentage: number;
  missions: Missions;
};

type MaintainDetail = {
  nextPeriodExpectation: number;
  missions: Missions;
};

type GroupLevelDetailResponse = {
  currentLevel: number;
  nextLevel: number;
  forLevelUp: LevelUpDetail;
  forMaintain?: MaintainDetail;
};

const level0Up = {
  currentLevel: 0, // 현재 레벨
  nextLevel: 1, // 다음 레벨
  forLevelUp: {
    percentage: 12, // 레벨업 진행률
    missions: {
      hasGroupDescription: {
        currentCount: 1,
        achieveCount: 1,
      },
      hasGroupThumbnail: {
        currentCount: 1,
        achieveCount: 1,
      },
      writeFirstPost: {
        currentCount: 3,
        achieveCount: 5,
      },
    },
  },
};

const level1Up = {
  currentLevel: 1, // 현재 레벨
  nextLevel: 2, // 다음 레벨
  forLevelUp: {
    percentage: 20, // 레벨업 진행률
    missions: {
      writeMeetup: {
        currentCount: 1,
        achieveCount: 2,
      },
      writePost: {
        currentCount: 1,
        achieveCount: 3,
      },
      raiseMember: {
        currentCount: 3,
        achieveCount: 3,
      },
    },
  },
};
const level2Up = {
  currentLevel: 2, // 현재 레벨
  nextLevel: 3, // 다음 레벨
  forLevelUp: {
    percentage: 5, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 80,
        achieveCount: 150,
      },
      raiseMember: {
        currentCount: 5,
        achieveCount: 10,
      },
    },
  },
};

const level3Up = {
  currentLevel: 3, // 현재 레벨
  nextLevel: 4, // 다음 레벨
  forLevelUp: {
    percentage: 65, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 50,
        achieveCount: 500,
      },
      raiseMember: {
        currentCount: 15,
        achieveCount: 30,
      },
    },
  },
};

const level4Up = {
  currentLevel: 4, // 현재 레벨
  nextLevel: 5, // 다음 레벨
  forLevelUp: {
    percentage: 99.999, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 3000,
        achieveCount: 5000,
      },
      raiseMember: {
        currentCount: 15,
        achieveCount: 30,
      },
    },
  },
};

const level5Up = {
  currentLevel: 5, // 현재 레벨
  nextLevel: 5, // 다음 레벨
  forLevelUp: {
    percentage: 65, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 500,
        achieveCount: 1000,
      },
    },
  },
};

// 강등 예정
const level4Down = {
  currentLevel: 4, // 현재 레벨
  nextLevel: 5, // 다음 레벨
  forLevelUp: {
    percentage: 12, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 600,
        achieveCount: 5000,
      },
      raiseMember: {
        currentCount: 15,
        achieveCount: 30,
      },
    },
  },
  forMaintain: {
    nextPeriodExpectation: 3, // 레벨 유지 진행률
    missions: {
      activityPoint: {
        currentCount: 100,
        achieveCount: 200,
      },
    },
  },
};

const level5Down = {
  currentLevel: 5, // 현재 레벨
  nextLevel: 5, // 다음 레벨
  forLevelUp: {
    percentage: 65, // 레벨업 진행률
    missions: {
      activityPoint: {
        currentCount: 500,
        achieveCount: 1000,
      },
    },
  },
  forMaintain: {
    nextPeriodExpectation: 4, // 레벨 유지 진행률
    missions: {
      activityPoint: {
        currentCount: 500,
        achieveCount: 1000,
      },
    },
  },
};

// 강등 후
const level3AfterDown = {
  currentLevel: 3, // 현재 레벨
  nextLevel: 4, // 다음 레벨
  forLevelUp: {
    percentage: 65, // 레벨업 진행률
    missions: {
      rollbackActivityPoint: {
        currentCount: 50,
        achieveCount: 200,
      },
    },
  },
};

const level4AfterDown = {
  currentLevel: 4, // 현재 레벨
  nextLevel: 5, // 다음 레벨
  forLevelUp: {
    percentage: 12, // 레벨업 진행률
    missions: {
      rollbackActivityPoint: {
        currentCount: 600,
        achieveCount: 1000,
      },
    },
  },
};

export const mockGroupLevelDetails: {
  [key: string]: GroupLevelPresentation | undefined;
} = {
  '서버에서-받아온-데이터': undefined,
  '레벨0-일반': level0Up,
  '레벨1-일반': level1Up,
  '레벨2-일반': level2Up,
  '레벨3-일반': level3Up,
  '레벨3-강등 후': level3AfterDown,
  '레벨4-일반': level4Up,
  '레벨4-강등예정': level4Down,
  '레벨4-강등 후': level4AfterDown,
  '레벨5-일반': level5Up,
  '레벨5-강등예정': level5Down,
};

export type GroupLevelKey = keyof typeof mockGroupLevelDetails;
