import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { useState } from 'react';
import { P } from 'ts-pattern';

import Level0_front from '@/components/common/GroupLevel/assets/badge/3d/LV0_front.png';
import Level0_back from '@/components/common/GroupLevel/assets/badge/3d/LV0_front.png';
import Level1_back from '@/components/common/GroupLevel/assets/badge/3d/LV1_back.png';
import Level1_front from '@/components/common/GroupLevel/assets/badge/3d/LV1_front.png';
import Level2_back from '@/components/common/GroupLevel/assets/badge/3d/LV2_back.png';
import Level2_front from '@/components/common/GroupLevel/assets/badge/3d/LV2_front.png';
import Level3_back from '@/components/common/GroupLevel/assets/badge/3d/LV3_back.png';
import Level3_front from '@/components/common/GroupLevel/assets/badge/3d/LV3_front.png';
import Level4_back from '@/components/common/GroupLevel/assets/badge/3d/LV4_back.png';
import Level4_front from '@/components/common/GroupLevel/assets/badge/3d/LV4_front.png';
import Level5_back from '@/components/common/GroupLevel/assets/badge/3d/LV5_back.png';
import Level5_front from '@/components/common/GroupLevel/assets/badge/3d/LV5_front.png';
import LevelUnKnown_front from '@/components/common/GroupLevel/assets/badge/3d/LVUNKOWN_front.png';
import GroupLevel2DBadge from '@/components/common/GroupLevel/GroupLevel2DBadge';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import useAPNGController from '../../hooks/useAPNGController';
import * as s from './CurrentLevelBadge.css';

type Props = {
  currentLevel: number;
};

const CurrentLevelBadge = ({ currentLevel }: Props) => {
  const { groupId = '' } = usePathParams();
  const [badgeState, setBadgeState] = useState<'front' | 'back'>('front');
  const [isMoving, setIsMoving] = useState(false);

  const { src: frontImageUrl } = useAPNGController({
    url: getLevel3DBadgeImage(currentLevel, 'front'),
  });
  const { src: backImageUrl, replay } = useAPNGController({
    url: getLevel3DBadgeImage(currentLevel, 'back'),
  });

  const handleOnclick = () => {
    if (isMoving) return;
    trackEvent({
      event: 'click_level_detail_large_badge',
      params: {
        currentLevel,
        groupId,
      },
    });
    setBadgeState('back');

    setIsMoving(true);
    replay();

    setTimeout(() => {
      setIsMoving(false);
    }, 1300);
  };

  const render3dBadge = () => {
    // 0 레벨은 잠금 이미지 노출
    if (currentLevel === 0) {
      return <img className={s.LevelBadgeImg} src={Level0_back} draggable={false} />;
    }

    return (
      <>
        {badgeState === 'front' && (
          <img className={s.LevelBadgeImg} src={frontImageUrl} draggable={false} />
        )}
        <img
          draggable={false}
          className={s.LevelBadgeImg}
          style={{
            //이미지 리소스를 미리 로드해놓기 위해 scale을 아주 작게 미리 로드 - 렌더링 분기를 했을 경우에는 깜빡이면서 갈아끼워지는 현상이 발생
            opacity: badgeState === 'back' ? 1 : 0,
            zIndex: badgeState === 'back' ? 1 : -999,
          }}
          src={backImageUrl}
        />
      </>
    );
  };

  return (
    <div className={s.CurrentLevelTextWrapper}>
      <Typography typography="subtitle2Regular" color="gray700">
        이번 달 모임 레벨
      </Typography>
      <Spacing size={4} />
      <div className={s.LevelTextWrapper}>
        {currentLevel !== 0 ? (
          <GroupLevel2DBadge level={currentLevel} size={32} type="small" />
        ) : null}
        <VerticalSpacing size={2} />
        <Typography typography="title1Bold" color="gray900">
          Lv.{currentLevel}
        </Typography>
      </div>
      <Spacing size={16} />

      <div onClick={handleOnclick} className={s.LevelBadgeImg}>
        {render3dBadge()}
      </div>
    </div>
  );
};

export default CurrentLevelBadge;

export const getLevel3DBadgeImage = (currentLevel: number, type: 'back' | 'front') => {
  if (type === 'back') {
    switch (currentLevel) {
      case 0:
        return Level0_back;
      case 1:
        return Level1_back;
      case 2:
        return Level2_back;
      case 3:
        return Level3_back;
      case 4:
        return Level4_back;
      case 5:
        return Level5_back;
      default:
        return Level1_back;
    }
  }

  switch (currentLevel) {
    case 0:
      return Level0_front;
    case 1:
      return Level1_front;
    case 2:
      return Level2_front;
    case 3:
      return Level3_front;
    case 4:
      return Level4_front;
    case 5:
      return Level5_front;
    case -1:
      return LevelUnKnown_front;
    default:
      return Level1_front;
  }
};
