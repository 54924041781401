import { useEffect } from 'react';

export const useDialogLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      const child = root.children[0] ?? [];

      const AppScreen = child as HTMLElement;

      if (AppScreen) {
        AppScreen.style.height = 'auto';
        AppScreen.style.position = 'fixed';
        AppScreen.style.inset = '0';
      }
    }
  }, []);
};
