import { Activity, StackflowPluginPostEffectHook } from '@stackflow/core';
import { StackflowReactPlugin } from '@stackflow/react';

export const prevHistoryPlugin: () => StackflowReactPlugin = () => () => ({
  key: 'prev-history-plugin',
  onPushed: handlePushed,
  onPopped: handlePopped,
  onReplaced: handleReplaced,
});

const handlePushed: StackflowPluginPostEffectHook<'PUSHED'> = ({ actions }) => {
  const { activities } = actions.getStack();

  const filteredActivities = filterActivities(activities, 'enter');

  const currentActivity = filteredActivities[filteredActivities.length - 1];
  window.currentPageName = currentActivity.name;

  const prevActivity = filteredActivities[filteredActivities.length - 2];

  if (!prevActivity) return;

  window.previousHistoryPageName = prevActivity.name;
};

const handlePopped: StackflowPluginPostEffectHook<'POPPED'> = ({ actions }) => {
  const { activities } = actions.getStack();

  const filteredEnterActivities = filterActivities(activities, 'enter');

  const currentActivity = filteredEnterActivities[filteredEnterActivities.length - 1];
  window.currentPageName = currentActivity.name;

  const filteredExitActivities = filterActivities(activities, 'exit');

  const prevActivity = filteredExitActivities[filteredExitActivities.length - 1];

  if (!prevActivity) return;

  window.previousHistoryPageName = prevActivity.name;
};

const handleReplaced: StackflowPluginPostEffectHook<'REPLACED'> = ({ actions }) => {
  const { activities } = actions.getStack();
  const filteredActivities = filterActivities(activities, 'enter');

  const currentActivity = filteredActivities[filteredActivities.length - 1];
  window.currentPageName = currentActivity.name;

  const prevActivity = filteredActivities[filteredActivities.length - 2];

  if (!prevActivity) return;

  window.previousHistoryPageName = prevActivity.name;
};

const filterActivities = (activities: Activity[], key: 'enter' | 'exit') => {
  return activities.filter((activity) => activity.transitionState.includes(key));
};
