// eslint-disable-next-line no-useless-escape
const regexForMarkup = /\[.*?]\{([0-9]+?)\}/g;
const getBracketsText = /\[(.+)\](.+)/;

export const getPlainTextFromMention = (
  text: string,
  type: 'string' | 'tag' = 'tag',
  userName?: string
) => {
  // const plainText = getPlainText(text, ConfigForGetPlainTextInMention);
  const mentions = text.match(regexForMarkup);

  if (mentions) {
    const mentionList = mentions.map((mention) => {
      const display = getBracketsText.exec(mention);

      if (!display) return '';

      if (type === 'string') return `${display[1]}`;
      if (`@${userName}` === display[1]) {
        return `<span class="mention is_me"><span>${display[1]}</span></span>`;
      }
      return `<span class="mention"><span>${display[1]}</span></span>`;
    });
    return text.replace(regexForMarkup, () => {
      return mentionList.shift() || '';
    });
  }

  return text;
};
