import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

type Props = {
  url: string;
};

const useAPNGController = ({ url }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>();

  const [blob, setBlob] = useState<Blob>();

  const handleSetImageSrc = (blob: Blob) => {
    const prevObjectURL = imageSrc;
    const objectURL = URL.createObjectURL(blob);
    setImageSrc(objectURL);
    if (prevObjectURL) URL.revokeObjectURL(prevObjectURL);
    return objectURL;
  };

  const refetch = () => {
    if (blob) {
      handleSetImageSrc(blob);
    }
  };

  const { data } = useQuery({
    queryKey: ['apng', url],
    queryFn: async () => {
      const response = await fetch(url);
      const blob = await response.blob();
      setBlob(blob);
      const objectURL = handleSetImageSrc(blob);
      return objectURL;
    },
  });

  return { src: imageSrc || data, replay: refetch };
};

export default useAPNGController;
