import { groupClient } from '@community-group/api';
import {
  GroupMeetupModifyForm,
  GroupMeetupModifyResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: number;
  meetupId: number;
  meetupModifyForm: GroupMeetupModifyForm;
};
type Props = UseMutationOptions<AxiosResponse<GroupMeetupModifyResponse>, Error, Params>;

export const usePutGroupMeetup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetup = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdPut({
    axios: fetchInstance,
  });

  const fetchPutGroupMeetup = ({ groupId, meetupId, meetupModifyForm }: Params) => {
    return putGroupMeetup(groupId, meetupId, meetupModifyForm);
  };

  return useThrottleMutation(fetchPutGroupMeetup, {
    onError,
    onSuccess,
  });
};
