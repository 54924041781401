export const getThumbnailFromPosterUri = (posterUri: string): string => {
  const appEnv = globalThis.appConfig.appEnv;

  const url =
    appEnv === 'production'
      ? `https://bigstream.kr.karrotmarket.com/api/v1/stream/:posterUri/thumbnails`
      : `https://bigstream.alpha.kr.karrotmarket.com/api/v1/stream/:posterUri/thumbnails`;

  return url.replace(':posterUri', posterUri);
};

export const generateThumbnailFromVideo = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    document.body.appendChild(videoElement);
    window.URL = window.URL || window.webkitURL;
    videoElement.src = window.URL.createObjectURL(file);
    videoElement.crossOrigin = 'anonymous'; // CORS 문제 해결 시도
    videoElement.muted = true; // 모바일 웹뷰에서 자동재생을 허용하기 위해 무음 처리
    videoElement.playsInline = true; // iOS 웹뷰에서 인라인 재생 허용
    videoElement.autoplay = true;

    // 비디오의 메타데이터가 로드된 후 특정 시간으로 이동
    videoElement.onloadedmetadata = () => {
      // 썸네일로 캡처할 시간을 설정 (1초 지점)
      videoElement.currentTime = 0.1;
      videoElement.pause();
      // document.body.removeChild(videoElement);
    };

    // 비디오가 특정 시간으로 이동한 후 썸네일 생성
    videoElement.onseeked = () => {
      // 비디오 데이터를 불러온 후, 캔버스를 이용해 썸네일 생성
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      document.body.removeChild(videoElement);
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const thumbnailUrl = URL.createObjectURL(blob);
            console.log('thumbnailUrl', thumbnailUrl);
            resolve(thumbnailUrl);
          } else {
            reject('Failed to generate thumbnail');
          }
        }, 'image/png');
      } else {
        reject('Failed to get canvas context');
      }
    };

    videoElement.onerror = (error) => {
      document.body.removeChild(videoElement);
      reject('Error loading video file');
    };
  });
};
