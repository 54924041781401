import { postUploadImageV2 } from '@community-group/api';
import {
  ImageFieldItem,
  MentionInputField,
  useHookFormImages,
  useKeyboardSize,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { CSSProperties, RefObject, useEffect, useMemo, useState } from 'react';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './CommentEditForm.css';

type Props = {
  handleAddButton?: () => void;
  inputConfig?: {
    autoFocus?: boolean;
  };
  mentionSuggestionsRef?: RefObject<HTMLDivElement>;
  textareaParentRef?: RefObject<HTMLDivElement>;
};

const CommentEditForm = ({ inputConfig, textareaParentRef, mentionSuggestionsRef }: Props) => {
  const fetchCommunityInstance = useFetchInstance();

  const { groupId = '' } = usePathParams();

  const formMethods = useFormContext();
  const { register, watch, setValue } = formMethods;
  const { ref: contentRef } = register('content');

  const { bridge } = useBridge();
  const {
    images: originImages,
    removeImages,
    retryImage,
  } = useHookFormImages({
    handleHookFormContext: () => formMethods as unknown as UseFormReturn<FieldValues>,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
  });

  const mentionMemberList = useMembersForMentions(groupId);

  const { isKeyboardOn } = useKeyboardSize();
  const mentionStyle = useMemo(() => {
    return getMentionDefaultStyle(isKeyboardOn);
  }, [isKeyboardOn]);

  // useNavigation push 시 맨션 Input으로 인해 페이지 이동 애니메이션이 깨지는 이슈가 있음
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDidMount(true);
    }, 100);
  }, []);

  return (
    <div className={s.Wrapper} role="button">
      {didMount && (
        <>
          <div className={s.ContentWrapper} ref={textareaParentRef}>
            <MentionInputField
              ref={contentRef}
              style={mentionStyle as CSSProperties}
              suggestionsPortalHost={mentionSuggestionsRef?.current ?? null}
              autoFocus={inputConfig?.autoFocus}
              value={watch('content')}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 4000) return;
                setValue('content', value);
              }}
              onKeyDown={(e) => {
                if (e.key !== 'Backspace' || watch('content')?.length !== 0) return;
                e.preventDefault();
              }}
              placeholder="질문이나 이야기를 남겨보세요"
              mentionConfig={[
                {
                  trigger: '@',
                  name: 'mentionedUserIds',
                  mentionList: mentionMemberList,
                  setMentionList: (currentMentionList: string[]) => {
                    setValue(
                      'mentionedUserIds',
                      currentMentionList.map((id) => parseInt(id))
                    );
                  },
                },
              ]}
            />
          </div>
          <div className={s.ImageWrapper}>
            {originImages.map(({ id, image: src }) => (
              <ImageFieldItem
                key={id}
                imageId={id}
                image={src}
                className={s.ImageField}
                onRemoveClick={() => {
                  removeImages(id);
                }}
                onRetryClick={() => {
                  retryImage(id);
                }}
              />
            ))}
          </div>
        </>
      )}
      <Spacing size={96} />
    </div>
  );
};

const defaultStyle = {
  control: {
    backgroundColor: `${vars.$semantic.color.paperDefault}`,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  highlighter: {
    lineHeight: '1.35rem',
  },
  '&multiLine': {
    control: {},
    highlighter: {
      lineHeight: '1.35rem',
    },
    input: {
      minHeight: '7.5rem',
      outline: 0,
      border: 0,
      color: `${vars.$scale.color.gray900}`,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.35rem',
      padding: 0,
    },
  },
  suggestions: {
    maxHeight: '168px',
    position: 'absolute',
    width: '100%',
    margin: 0,
    top: 'undefined',
    bottom: '49px',
    backgroundColor: `${vars.$semantic.color.paperDefault}`,
    zIndex: 999,

    list: {
      backgroundColor: `${vars.$semantic.color.paperDefault}`,
      borderTop: `1px solid ${vars.$semantic.color.divider1}`,
      fontSize: '1rem',
      width: '100%',
      fontWeight: 400,
      maxHeight: '168px',
      overflow: 'auto',
      lineHeight: '1.375rem',
    },
    item: {
      padding: '0.75rem 1rem',
      backgroundColor: `${vars.$semantic.color.paperDefault}`,
      '&first-child': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '&focused': {
        backgroundColor: `${vars.$semantic.color.grayPressed}`,
      },
    },
  },
};

const getMentionDefaultStyle = (focused: boolean) => ({
  ...defaultStyle,
  suggestions: {
    ...defaultStyle.suggestions,
    bottom: focused ? '49px' : 'calc(env(safe-area-inset-bottom) + 49px)',
  },
  '&multiLine': {
    ...defaultStyle['&multiLine'],
    input: {
      ...defaultStyle['&multiLine'].input,
    },
  },
});

export default withAsyncBoundary(CommentEditForm, {});
