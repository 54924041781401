import { forwardRef, Ref, useCallback } from 'react';

import { useImpression } from './useImpression';

export * from './useImpression';

export interface ImpressionProps {
  children?: React.ReactNode;
  onStart?: () => void;
  threshold?: number;
  actionType?: 'none' | 'once' | 'always';
}

export const Impression = forwardRef(
  (
    { children, onStart, threshold, actionType }: ImpressionProps,
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    const impressionRef = useImpression({
      onStart,
      threshold,
      actionType,
    });

    const currentRefs = [forwardedRef, impressionRef];
    const ref = useCallback(
      (value: HTMLDivElement | null) => {
        for (const ref of currentRefs) {
          if (typeof ref === 'function') {
            ref(value);
          } else if (ref != null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current = value;
          }
        }
      },
      [forwardedRef, impressionRef]
    );

    return <div ref={ref}>{children}</div>;
  }
);
