import { UserMeetupMemberReview } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';

import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';

import * as s from './style.css';

type Props = {
  meetupMemberReview: UserMeetupMemberReview;
};

export const ReviewLabelItem = ({ meetupMemberReview: { feedbackTypeId, count } }: Props) => {
  const feedbackInfo = MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId);

  if (!feedbackInfo) return null;

  return (
    <div key={feedbackTypeId} className={s.LabelWrapper}>
      <Typography
        as="span"
        typography="label3Regular"
        style={{
          paddingRight: '4px',
        }}
      >
        {feedbackInfo.prefixIcon}
      </Typography>
      <Typography
        as="p"
        typography="label3Regular"
        style={{
          paddingRight: '2px',
        }}
      >
        {feedbackInfo.label}
      </Typography>
      <Typography as="p" typography="label3Bold">
        {count}
      </Typography>
    </div>
  );
};
