import { groupClient } from '@community-group/api';
import { AccessStatusServiceEnum } from '@community-group/api/lib/group/models';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  serviceType: AccessStatusServiceEnum;
  serviceId: string;
};

export const useGetAccessStatusService = ({ serviceType, serviceId }: Params) => {
  const fetchInstance = useFetchInstance();

  const getAccessStatusService = groupClient.api.AccessStatusApi.apiV1AccessStatusServiceGet({
    axios: fetchInstance,
  });

  return useQuery(['/error-status/page', serviceType, serviceId], () =>
    getAccessStatusService(serviceType, serviceId)
  );
};
