import { groupClient } from '@community-group/api';
import { GroupInfoPresentation } from '@community-group/api/lib/group/models';
import { BoxButton } from '@community-group/components';
import { useState } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useDeleteMember } from '@/api/hooks/useDeleteMember';
import { useGetMe } from '@/api/hooks/useGetMe';
import { getMyGroupListPath, useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { queryClient } from '@/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import * as s from './QuitGroup.css';

const KickMember = () => {
  const fetchInstance = useFetchInstance();
  const [open, setOpen] = useState(false);

  const { data: myGroupData } = useGetMyGroupList();
  const { data: me } = useGetMe();

  const myJoinedGroupData = myGroupData?.groups.filter((el) => el.isHost) ?? [];

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate: mutateDeleteMember } = useDeleteMember({
    onSuccess: () => {},
    onError: handleErrorWithToast,
  });

  const handleClickItem = async (group: GroupInfoPresentation) => {
    const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryMemberGet({
      axios: fetchInstance,
    });
    const { data: feedMembers } = await get(group.id);
    const filteredMembers = feedMembers?.members?.filter((member) => member.id !== me.id) ?? [];

    for (const member of filteredMembers) {
      console.log('member', {
        groupId: group.id.toString(),
        userId: member.id.toString(),
        memberState: 'removed',
      });
      await mutateDeleteMember({
        groupId: group.id.toString(),
        userId: member.id.toString(),
        memberState: 'removed',
      });
      await delay(1000);
    }
  };

  return (
    <div>
      <div
        className={s.TitleWrapper}
        onClick={() => {
          setOpen((prev) => !prev);
          queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
        }}
      >
        <h3>운영중 모임원 모두 내보내기</h3>
        {open ? '▲' : '▼'}
      </div>
      {open && (
        <form className={s.FormWrapper}>
          <div className={s.GroupListWrapper}>
            {myJoinedGroupData.map((group) => {
              return (
                <div key={group.id} className={s.ItemWrapper}>
                  <div
                    key={group.id}
                    className={s.ItemWrapper}
                    style={{ justifyContent: 'flex-start', gap: '10px' }}
                  >
                    <img src={group.profileImage.small} className={s.ItemImg} />
                    <p>{group.name}</p>
                  </div>
                  <BoxButton size="xsmall" onClick={() => handleClickItem(group)}>
                    모두 내보내기
                  </BoxButton>
                </div>
              );
            })}
          </div>
        </form>
      )}
    </div>
  );
};

export default KickMember;

// 딜레이 함수: milliseconds만큼 기다린 후 Promise를 해결합니다.
function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
