import { postUploadImageV2 } from '@community-group/api';
import { useHookFormImages, useKeyboardSize } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconKeyboardHidingRegular } from '@seed-design/icon';
import { IconMentionRegular } from '@seed-design/icon';
import { IconPhotoRegular } from '@seed-design/icon';
import { RefObject } from 'react';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';
import { useDeviceConfig } from '@/contexts/DeviceConfig';

import * as s from './CommentEditFormAccessoryBar.css';

type Props = {
  handleFocusTextarea: () => void;
  handleBlurTextarea: () => void;
  mentionSuggestionsRef: RefObject<HTMLDivElement>;
};

const CommentEditFormAccessoryBar = ({
  handleFocusTextarea,
  handleBlurTextarea,
  mentionSuggestionsRef,
}: Props) => {
  const { bridge } = useBridge();
  const { keyboardHeight, isKeyboardOn } = useKeyboardSize(bridge);
  const { deviceConfig } = useDeviceConfig();
  const isAndroid = deviceConfig.theme === 'android';

  const formMethods = useFormContext();
  const fetchCommunityInstance = useFetchInstance();
  const { setImages } = useHookFormImages({
    handleHookFormContext: () => formMethods as unknown as UseFormReturn<FieldValues>,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
  });

  const handlePhotoClick = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pickedImages = await bridge.driver.compat.pickImages();
    if (!pickedImages) return;
    setImages(pickedImages);
  };

  const handleMentionClick = () => {
    formMethods.setValue('content', `${formMethods.watch('content')} @`);
    handleFocusTextarea();
  };

  return (
    <div
      className={s.Wrapper}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        ref={mentionSuggestionsRef}
        className={s.AppBarWrapper({
          isOpenKeyboard: isKeyboardOn,
          isAndroid,
        })}
        onClick={handleFocusTextarea}
      >
        <div className={s.LeftSide}>
          <ul className={s.FeatureList}>
            <button className={s.FeatureButton} onClick={handlePhotoClick}>
              <IconPhotoRegular width={24} height={24} color={vars.$scale.color.gray600} />
            </button>

            <button className={s.FeatureButton} onClick={handleMentionClick}>
              <IconMentionRegular size={24} color={vars.$scale.color.gray600} />
            </button>
          </ul>
        </div>
        {keyboardHeight > 0 && (
          <button
            className={s.KeyboardHidingButton}
            onClick={(e) => {
              e.stopPropagation();
              handleBlurTextarea();
            }}
          >
            <IconKeyboardHidingRegular width={24} height={24} color={vars.$scale.color.gray600} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CommentEditFormAccessoryBar;
