import { UserProfileSnsTagPresentationSnsType } from '@community-group/api/lib/group/models';

export const openSnsLink = ({
  snsId,
  snsType,
}: {
  snsId: string;
  snsType: UserProfileSnsTagPresentationSnsType;
}) => {
  const openBlankLink = (url: string) => {
    window.open(url, '_blank');
    return;
  };

  if (snsType === 'x') {
    openBlankLink(`https://twitter.com/${snsId}`);
    return;
  }
  if (snsType === 'facebook') {
    openBlankLink(`https://www.facebook.com/${snsId}`);
    return;
  }

  openBlankLink(`https://www.instagram.com/${snsId}`);
  return;
};
