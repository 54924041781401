import { ChipRadioGroup, Spacing, Typography } from '@community-group/components';
import { ChipButton, ChipRadio } from '@daangn/sprout-components-chips';
import { vars } from '@seed-design/design-token';
import { IconExpandMoreRegular, IconWarningFill } from '@seed-design/icon';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { useGetRecommendCategory } from '@/api/hooks/useGetRecommendCategory';
import { trackEvent } from '@/utils/analytics';

const MAX_VISIBLE_CHIPS = 2;

type CategoryBadgeGeneratedLLMProps = {
  onChange?: (value: number) => void;
  categoryFieldValue: string;
  text?: string;
  errorMessage?: string;
};

export const CategoryBadgeRecommendGenerated = ({
  text = '',
  onChange,
  categoryFieldValue,
  errorMessage,
}: CategoryBadgeGeneratedLLMProps) => {
  const { data, refetch } = useGetRecommendCategory({ text });
  const [showAll, setShowAll] = useState(false);

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 200),
    [refetch]
  );

  useEffect(() => {
    if (text.trim()) {
      debouncedRefetch();
    }
  }, [text, debouncedRefetch]);

  if (!data) return null;

  const sortedCategories = [...data.categories];
  const selectedCategoryIndex = sortedCategories.findIndex(
    (item) => item.id === Number(categoryFieldValue)
  );

  if (selectedCategoryIndex > -1) {
    const [selectedCategory] = sortedCategories.splice(selectedCategoryIndex, 1);
    sortedCategories.unshift(selectedCategory);
  }

  const visibleCategories = showAll
    ? sortedCategories
    : sortedCategories.slice(0, MAX_VISIBLE_CHIPS);
  const showErrorMessage = !!errorMessage;

  return (
    <div>
      <ChipRadioGroup
        size="small"
        value={String(categoryFieldValue)}
        defaultValue={categoryFieldValue}
        onChange={(value) => {
          trackEvent({
            event: 'click_recommend_category',
            params: {
              clickCategoryId: value,
              clickRecommendCategoryIndex: selectedCategoryIndex,
            },
          });
          onChange?.(Number(value));
          setShowAll(false);
        }}
        UNSAFE_style={{
          rowGap: 12,
        }}
      >
        <>
          {visibleCategories.map((item) => (
            <ChipRadio key={item.id} value={String(item.id)}>
              {item.name === '기타' ? '그 외' : item.name}
            </ChipRadio>
          ))}
          <ChipButton
            size="small"
            onClick={() => {
              trackEvent({
                event: 'click_recommend_category_more',
              });
              setShowAll((prev) => !prev);
            }}
            suffix={
              <IconExpandMoreRegular
                size={16}
                color="gray600"
                style={{ transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            }
          >
            {showAll ? '접기' : '더보기'}
          </ChipButton>
        </>
      </ChipRadioGroup>
      {showErrorMessage ? (
        <>
          <Spacing size={12} />
          <Typography typography="label3Regular" color="danger">
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {errorMessage && <IconWarningFill color={vars.$semantic.color.danger} size={16} />}
              <Spacing size={4} direction="vertical" />
              {errorMessage}
            </p>
          </Typography>
        </>
      ) : null}
    </div>
  );
};
