import { SelectBox, SelectBoxProps } from '.';
import * as s from './SelectBoxRadio.css';

type Props = Omit<SelectBoxProps, 'renderRight'>;

export const SelectBoxRadio = ({ children, selected, ...props }: Props) => (
  <SelectBox selected={selected} {...props} renderRight={<div className={s.radio({ selected })} />}>
    {children}
  </SelectBox>
);
