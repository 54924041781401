import { Typography, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetNotices } from '@/api/hooks/useGetNotices';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import { MemoizingFeedItem } from '../components/Feed/FeedItem';
import * as s from './GroupDetailNoticeMorePage.css';

export type GroupDetailNoticeMorePageParams = Pick<PageParams, 'groupId' | 'activeTab'>;

const GroupDetailNoticeMorePage: ActivityComponentType<GroupDetailNoticeMorePageParams> = () => {
  const { groupId } = usePathParams();
  const { data } = useGetNotices(groupId);
  const currentUser = useCurrentGroupMe(groupId);

  useEnterTrackEvent({
    event: 'enter_group_detail_notice_more_page',
    sample: true,
  });

  const renderEmpty = () => {
    return (
      <div className={s.EmptyViewBox}>
        <Typography typography="subtitle1Regular" color="gray600">
          아직 공지사항이 없어요.
        </Typography>
      </div>
    );
  };

  const renderNotice = () => {
    if (!data?.notices || data.notices.length === 0) {
      return renderEmpty();
    }

    return data?.notices.map((notice) => {
      return (
        <MemoizingFeedItem
          key={notice.id}
          post={notice}
          currentUser={currentUser}
          shouldSetSubNickname={notice.isSettingOnSubNickname}
        />
      );
    });
  };

  return (
    <AppScreen appBar={{ title: '공지사항' }}>
      <div className={s.wrapper}>
        {renderNotice()}
        <div className={s.safeAreaBottom} />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailNoticeMorePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
