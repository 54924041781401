type Props = React.SVGProps<SVGSVGElement>;

export const PinDefault = (props: Props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2847_40408)">
      <g filter="url(#filter0_f_2847_40408)">
        <ellipse
          cx="18.0001"
          cy="32.2258"
          rx="6.09677"
          ry="2.03226"
          fill="black"
          fillOpacity="0.15"
        />
      </g>
      <path
        d="M29.9031 11.8349C29.9031 13.5149 29.5873 14.9682 29.0885 16.216C26.8662 21.7752 21.4241 25.7493 19.0146 31.2261L18.6997 31.9418C18.5756 32.2237 18.3018 32.405 17.9999 32.405C17.698 32.405 17.4242 32.2237 17.3001 31.9418L16.9853 31.2261C14.5757 25.7493 9.13357 21.7752 6.91133 16.216C6.41255 14.9682 6.09668 13.5149 6.09668 11.8349C6.09668 5.29867 11.4259 0 17.9999 0C24.5739 0 29.9031 5.29867 29.9031 11.8349Z"
        fill="#FF6F0F"
      />
      <path
        d="M29.4031 11.8349C29.4031 13.4504 29.0997 14.8409 28.6242 16.0304C27.546 18.7276 25.681 21.0541 23.7346 23.4356C23.6728 23.5112 23.6109 23.5868 23.549 23.6626C21.678 25.9504 19.7547 28.3022 18.5569 31.0247L18.242 31.7404C18.1957 31.8457 18.0983 31.905 17.9999 31.905C17.9015 31.905 17.8041 31.8457 17.7578 31.7404L17.4429 31.0247C16.2451 28.3022 14.3218 25.9504 12.4508 23.6626C12.3889 23.5868 12.327 23.5112 12.2652 23.4356C10.3188 21.0541 8.4538 18.7276 7.37561 16.0304C6.90011 14.8409 6.59668 13.4504 6.59668 11.8349C6.59668 5.57752 11.6994 0.5 17.9999 0.5C24.3004 0.5 29.4031 5.57752 29.4031 11.8349Z"
        stroke="black"
        strokeOpacity="0.1"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2847_40408"
        x="10.1614"
        y="28.4516"
        width="15.6775"
        height="7.54839"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.870968" result="effect1_foregroundBlur_2847_40408" />
      </filter>
      <clipPath id="clip0_2847_40408">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
