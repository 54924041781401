import murmurhash from 'murmurhash-js';

import { getUserExperimentalABCGroup } from '../userExperimentalGroup';

type Params = {
  groupId: number;
  userId: number;
  defaultUrl: string;
};

// 합정동 모임방 프로필이 있을경우 이미지 실험
// https://www.notion.so/daangn/6e6c53c1d46640c8a1aa025940b9561d?pvs=4
export const getUserProfileUrlInduceExperiment = ({ groupId, userId, defaultUrl }: Params) => {
  if (!groupId) return defaultUrl;
  if (groupId !== 342697) {
    return defaultUrl;
  }

  const currentUserExperimentalGroup = getUserExperimentalABCGroup(userId);

  if (currentUserExperimentalGroup === 'A') {
    return 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';
  }

  if (currentUserExperimentalGroup === 'B') {
    const hash = murmurhash(String(userId));
    const normalizedHash = hash / 0xffffffff;
    // 정규화된 해시 값이 0.5 미만인 경우 절반의 확률로 defaultUrl 반환
    if (normalizedHash < 0.5) {
      return defaultUrl;
    }
    return 'https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png';
  }

  return defaultUrl;
};
