import { CommentDetail } from '@community-group/api/lib/group/models';
import { ContainerWithGap, GroupAvatar, Typography } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';
import { MouseEventHandler, PropsWithChildren } from 'react';

import LinkifyContainer from '@/components/common/Linkify';
import OpenGraph from '@/components/common/OpenGraph';
import { isShowOpenGraphContent } from '@/utils/check';
import { getDateDiffFromNow } from '@/utils/date';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './CommentFeedItem.css';
import { CommentImageSlider } from './CommentImageSlider';
import CommentReaction from './CommentReaction';

type Props = PropsWithChildren<{
  item: CommentDetail;
  onMenuClick?: MouseEventHandler;
  onProfileClick?: MouseEventHandler;
  onLikeReactionClick?: MouseEventHandler;
  onDislikeReactionClick?: MouseEventHandler;
  onContentClick?: MouseEventHandler;
  onReplyClick?: MouseEventHandler;
}>;

const CommentFeedItem = (props: Props) => {
  if (props.item.deletedAt) {
    return <AbnormalCommentFeedItem {...props} />;
  }

  return <NormalCommentFeedItem {...props} />;
};

const NormalCommentFeedItem = ({
  item,
  onMenuClick,
  onProfileClick,
  onLikeReactionClick,
  onDislikeReactionClick,
  onContentClick,
  onReplyClick,
  children,
}: Props) => {
  const isSubComment = Boolean(item.parentId);
  const isAccountDeleted = Boolean(item.author.isAccountDeleted);

  return (
    <motion.div className={s.FeedItemContainer}>
      <div className={s.FeedItem}>
        <div onClick={onProfileClick} aria-hidden="true">
          <GroupAvatar
            src={item?.author?.profileImage || ''}
            role={item.author.role}
            size={isSubComment ? 'xsmall' : 'small'}
          />
        </div>
        <VerticalSpacing size={isSubComment ? 8 : 12} />
        <div className={s.FeedItemRightSection}>
          <div className={s.FeedItemRightTopSection}>
            <NormalAuthorSection item={item} onProfileClick={onContentClick} />
            <button onClick={onMenuClick}>
              <IconMoreVertRegular size={24} color={vars.$scale.color.gray600} />
            </button>
          </div>
          <Spacing size={8} />
          <ContainerWithGap
            className={s.Content({ isSubComment })}
            onClick={onContentClick}
            name="CommentFeedItemContent"
            gap={8}
          >
            {item.content && <LinkifyContainer text={item.content} />}
            {item.images?.length > 0 && <CommentImageSlider images={item.images} />}
            {isShowOpenGraphContent(item.content) && (
              <OpenGraph text={item.content} type="FEED" margin="0 0 .5rem 0" />
            )}
          </ContainerWithGap>
          {!isAccountDeleted && (
            <>
              <Spacing size={8} />
              <CommentReaction
                onLikeReactionClick={onLikeReactionClick}
                onDislikeReactionClick={onDislikeReactionClick}
                onReplyClick={onReplyClick}
                emotionCount={item.emotion.count}
                myEmotion={item.emotion.myEmotion}
                subCommentCount={item.subCommentCount}
                parentId={item.parentId}
              />
            </>
          )}
        </div>
      </div>
      {children}
    </motion.div>
  );
};

const AbnormalCommentFeedItem = ({ item, onContentClick, children }: Props) => {
  return (
    <motion.div className={s.FeedItemContainer}>
      <div className={s.FeedItem}>
        <GroupAvatar src={item?.author?.profileImage || ''} size={'small'} />
        <VerticalSpacing size={12} />
        <div className={s.FeedItemRightSection}>
          <AbnormalAuthorSection item={item} />
          <Spacing size={8} />
          <Typography typography="bodyM2Regular" color="gray600" onClick={onContentClick}>
            {item.content}
          </Typography>
        </div>
      </div>
      {children}
    </motion.div>
  );
};

const NormalAuthorSection = ({ item, onProfileClick }: Props) => {
  if (item.author.isAccountDeleted) {
    return <AbnormalAuthorSection item={item} />;
  }

  return (
    <div className={s.FeedItemAuthorSection} onClick={onProfileClick} aria-hidden="true">
      <Typography typography="caption1Bold" color="gray900">
        {convertNicknameDisplay({
          nickname: item.author.nickname ?? '',
          subNickname: item.author.subNickname,
        })}
      </Typography>
      <Spacing size={2} />
      <Typography typography="caption2Regular" color="gray600">
        {getDateDiffFromNow(new Date(Date.parse(item.createdAt))).text} 전{' '}
      </Typography>
    </div>
  );
};

const AbnormalAuthorSection = ({ item }: Props) => (
  <div className={s.FeedItemAuthorSection}>
    <Typography typography="caption1Bold" color="gray500">
      {convertNicknameDisplay({
        nickname: item.author.nickname ?? '',
        subNickname: item.author.subNickname,
      })}
    </Typography>
    <Spacing size={2} />
    <Typography typography="caption2Regular" color="gray600">
      {getDateDiffFromNow(new Date(Date.parse(item.createdAt))).text} 전{' '}
    </Typography>
  </div>
);

export default CommentFeedItem;
