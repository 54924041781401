import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_MEETUP } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export interface AllTypeMeetupProps {
  regionId: number;
  categoryId?: number;
  meetupStatus?: ('opened' | 'fully_recruited' | 'closed')[];
  order: $Values<typeof ORDER_MEETUP>;
}

export const getAllMeetupsPath = `${GROUP_URL}/all-type-meetups`;

export const useGetAllMeetups = ({
  regionId,
  meetupStatus = ['opened'],
  categoryId,
  order,
}: AllTypeMeetupProps) => {
  const fetchInstance = useFetchInstance();
  const getAllMeetups = groupClient.api.AllMeetupsApi.apiV1AllTypeMeetupsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getAllMeetupsPath, regionId, meetupStatus, categoryId, order],
    ({ pageParam = null }) =>
      getAllMeetups(
        pageParam ? pageParam : undefined,
        20,
        regionId,
        meetupStatus,
        categoryId,
        order
      ),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
