import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { isDevelopmentAppStage, isExposeInternalUser } from '@/utils/check';

// 그룹 레벨 기능 사용 여부
const useRedTeamGroupLevel = () => {
  const useGroupLevel = useFeatureFlag('useGroupLevel');
  const { userConfig } = useUserConfig();

  const isInternalUser =
    userConfig.userId && isExposeInternalUser(userConfig.userId.toString(), false);

  const isDev = isDevelopmentAppStage();

  return useGroupLevel || isInternalUser || isDev;
};

export default useRedTeamGroupLevel;
