import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import icon_warning_fill from '@/assets/images/icon_warning_fill.svg';

import * as s from './index.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
  maxLength?: number;
  register?: UseFormRegister<FieldValues>;
  prefixImage?: React.ReactNode;
  isInvalid?: boolean;
  errorMessage?: string;
}

export const FormInput = ({
  name,
  value = '',
  maxLength,
  register,
  prefixImage,
  isInvalid = false,
  errorMessage,
  ...props
}: Props) => {
  const textLength = value?.length ?? 0;

  const currentValue = register ? { defaultValue: value } : { value: value };

  return (
    <div className={s.wrapper}>
      {prefixImage && <div className={s.prefixImageWrapper}>{prefixImage}</div>}
      <input
        maxLength={maxLength}
        {...currentValue}
        {...props}
        className={clsx([props.className, s.input({ isInvalid, hasPrefix: !!prefixImage })])}
        {...(register && register(name))}
      />
      <div className={s.footerWrapper}>
        {errorMessage ? (
          <div className={s.errorMessageWrapper}>
            <img
              src={icon_warning_fill}
              width="16px"
              height="16px"
              style={{ width: '1rem', height: '1rem' }}
            />
            <Typography typography="label3Regular" color="danger">
              {errorMessage}
            </Typography>
          </div>
        ) : (
          <div className={s.errorMessageWrapper} />
        )}
        {maxLength && (
          <p className={s.textLengthWrapper}>
            <span
              style={{
                color: textLength !== 0 ? vars.$scale.color.gray900 : vars.$scale.color.gray600,
              }}
            >
              {textLength}
            </span>
            /{maxLength}
          </p>
        )}
      </div>
    </div>
  );
};
