import { vars } from '@seed-design/design-token';
import { BottomSheet as BottomSheetUI, BottomSheetProps } from '@stackflow/plugin-basic-ui';
import { ReactElement } from 'react';

type Props = BottomSheetProps &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'style'> & { safeAreaInsetBottomRemove?: boolean };

function BottomSheet({
  children,
  style,
  safeAreaInsetBottomRemove = false,
  ...props
}: Props): ReactElement {
  return (
    <BottomSheetUI
      dimBackgroundColor={vars.$semantic.color.overlayDim}
      backgroundColor={vars.$semantic.color.paperDefault}
      {...props}
    >
      <div
        style={{
          padding: '1rem',
          ...style,
        }}
      >
        <div
          style={{
            paddingBottom: safeAreaInsetBottomRemove ? '0' : 'env(safe-area-inset-bottom)',
          }}
        >
          {children}
        </div>
      </div>
    </BottomSheetUI>
  );
}

export default BottomSheet;
