import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  callback: () => void;
  intersectionOptions: {
    root?: HTMLDivElement | null;
    rootMargin?: string;
    threshold?: number;
    triggerOnce?: boolean;
  };
};
const useInfinityScroll = ({
  callback,
  intersectionOptions = { root: null, rootMargin: '0', threshold: 1, triggerOnce: true },
}: Props) => {
  const { ref, inView } = useInView({ ...intersectionOptions });

  useEffect(() => {
    if (inView) {
      callback();
    }

    return () => {};
  }, [callback, inView]);

  return { ref, inView };
};

export default useInfinityScroll;
