import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Params = {
  groupId: string;
  meetupId: string;
  meetupJoinStatus: groupClient.model.GroupMeetupJoinStatusEnum;
};
type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupMeetupJoinStatus = ({ onError, onSuccess, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupMeetupJoinStatus =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdMeetupJoinStatusPut({
      axios: fetchInstance,
    });

  const putGroupMeetupJoinStatusWrapperFp = ({ groupId, meetupId, meetupJoinStatus }: Params) => {
    return putGroupMeetupJoinStatus(parseInt(groupId), parseInt(meetupId), meetupJoinStatus);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(putGroupMeetupJoinStatusWrapperFp, {
    onMutate,
    onSettled,
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);

      if (variables.meetupJoinStatus === 'join') {
        mutateEventLogLocation({
          eventName: 'join_group_meetup',
          groupId: parseInt(variables.groupId),
        });
      }
    },
  });
};
