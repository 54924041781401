import { GroupSuperHostPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import SuperHostInformation from './SuperHostInformation';

type Props = {
  groupId: string;
  hostInfo: GroupSuperHostPresentation;
};

function HostInfoSection({ groupId, hostInfo }: Props) {
  const { push } = useFlow();

  const handleMoveToProfileDetail = (e: React.MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();

    trackEvent({
      event: 'click_host_profile',
      params: {
        groupId,
        hostId: hostInfo.id?.toString() ?? '',
        role: 'none',
      },
      sample: true,
    });

    push('GroupUserProfileDetailPage', {
      groupId: groupId,
      userId: hostInfo.id?.toString() ?? '',
    });
  };

  return (
    <section className={s.Wrapper} onClick={handleMoveToProfileDetail}>
      <Typography typography="title3Bold">모임장 소개</Typography>
      <Spacing size={24} />
      <SuperHostInformation
        profile={hostInfo}
        onClickProfileImage={() => {}}
        visibleVerifiedInfoIcon={false}
      />
    </section>
  );
}

export default HostInfoSection;
