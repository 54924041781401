import { IconAddFill, IconCloseFill, IconEditFill } from '@seed-design/icon';
import { $Values } from 'utility-types';

import * as s from './index.css';

export const FLOATING_BUTTON_TYPE = {
  DEFAULT: 'default',
  POST: 'post',
  CLOSE: 'close',
} as const;

type Props = {
  onClick: () => void;
  type?: $Values<typeof FLOATING_BUTTON_TYPE>;
};

const FloatingIcon = ({ onClick, type = FLOATING_BUTTON_TYPE.DEFAULT }: Props) => {
  if (type === FLOATING_BUTTON_TYPE.CLOSE) {
    return (
      <button className={s.button({ close: true })} onClick={onClick}>
        <IconCloseFill size={24} />
      </button>
    );
  }
  return (
    <button className={s.button({ close: false })} onClick={onClick}>
      {type === FLOATING_BUTTON_TYPE.DEFAULT ? (
        <IconAddFill size={24} />
      ) : (
        <IconEditFill size={24} />
      )}
    </button>
  );
};

export default FloatingIcon;
