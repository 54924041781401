import { model } from '@community-group/api/lib/group';
import { GroupMeetupSummaryPresentation } from '@community-group/api/lib/group/models';
import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

type GroupMeetupObjectResponse = {
  [key: string]: Array<model.GroupMeetupSummaryPresentation | undefined>;
};

export const convertMonthGroupingMeetupListFromAPI = (
  meetupListData?: InfiniteData<AxiosResponse<model.GroupMeetupListResponse, any>>
): GroupMeetupObjectResponse => {
  const flatten = convertFlattenMeetupLisFromAPI(meetupListData);

  const response = flatten.reduce((acc, cur) => {
    const meetupMonth = dayjs(cur?.meetDate).format('YYYY-MM');
    acc[meetupMonth] ? acc[meetupMonth].push(cur) : (acc[meetupMonth] = [cur]);
    return acc;
  }, {} as GroupMeetupObjectResponse);

  const reversed = new Map(Object.entries(response).reverse());
  const entries = Object.fromEntries(reversed);

  return entries;
};

export const convertFlattenMeetupLisFromAPI = (
  meetupListData?: InfiniteData<AxiosResponse<model.GroupMeetupListResponse, any>>
): GroupMeetupSummaryPresentation[] => {
  const flattenPages = meetupListData?.pages.map((page) => page.data.meetups);
  const flatten = flattenPages?.flat(1) || [];

  return flatten as GroupMeetupSummaryPresentation[];
};
