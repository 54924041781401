// https://github.com/KaterinaLupacheva/react-progress-bar

import { vars } from '@seed-design/design-token';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  value?: string | number;
  max?: string | number;
  margin?: string;
  padding?: string;
  transitionDuration?: string;
  transitionTimingFunction?: 'ease' | 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out';
  dir?: 'ltr' | 'rtl' | 'auto';
  height?: string;
  width?: string;
  borderRadius?: string;
  fillColor?: string;
  barColor?: string;
  animateOnRender?: boolean;
  initMaxedOnAnimation?: string | number;
}

/**
 * @deprecated "공통레포의 ProgressBar를 사용하세요."
 */

export const ProgressBar = ({
  value,
  max,
  margin,
  padding,
  transitionDuration = '1s',
  transitionTimingFunction = 'ease-in-out',
  dir = 'ltr',
  height = '4px',
  width = '100%',
  borderRadius = '4px',
  barColor = vars.$scale.color.grayAlpha50,
  fillColor = vars.$semantic.color.primary,
  animateOnRender = false,
  initMaxedOnAnimation = 0,
}: Props) => {
  const initCompletedOnAnimationStr =
    typeof initMaxedOnAnimation === 'number' ? `${initMaxedOnAnimation}%` : initMaxedOnAnimation;

  const [initWidth, setInitWidth] = useState<string>(initCompletedOnAnimationStr);

  const getFillerWidth = useCallback(
    (maxCompletedValue: Props['max'], completedValue: Props['value']) => {
      if (maxCompletedValue) {
        const ratio = Number(completedValue) / Number(maxCompletedValue);
        return ratio > 1 ? '100%' : `${ratio * 100}%`;
      }
      return initCompletedOnAnimationStr;
    },
    [initCompletedOnAnimationStr]
  );
  const fillWidth = getFillerWidth(max, value);

  const barProps: BarProps = {
    width,
    height,
    background: barColor,
    borderRadius,
    padding,
    margin,
  };

  const fillProps: FillProps = {
    height,
    width: animateOnRender ? initWidth : fillWidth,
    background: fillColor,
    transitionDuration,
    transitionTimingFunction,
  };

  useEffect(() => {
    if (animateOnRender) {
      requestAnimationFrame(() => setInitWidth(fillWidth));
    }
  }, [fillWidth, animateOnRender]);

  return (
    <div
      dir={dir}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={Number(max)}
      aria-valuetext={String(value)}
      style={{
        width,
        display: 'initial',
        alignItems: 'initial',
      }}
    >
      <div style={{ ...barProps }}>
        <div
          style={{
            borderRadius: 'inherit',
            display: 'flex',
            alignItems: 'center',
            height: fillProps.height,
            width: fillProps.width,
            background: fillProps.background,
            transition: `width ${fillProps.transitionDuration} ${fillProps.transitionTimingFunction}`,
            willChange: 'width, transition',
          }}
        />
      </div>
    </div>
  );
};

interface BarProps {
  width: string;
  height: string;
  background: string;
  borderRadius: string;
  padding?: string;
  margin?: string;
}

interface FillProps {
  height: string;
  width: string;
  background: string;
  transitionDuration?: string;
  transitionTimingFunction?: string;
}
