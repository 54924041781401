import { axiosClient } from '@community-group/api';
import { AxiosRequestConfig } from 'axios';

import { wrapSSRErrorHandler } from '@/api/instance/axios';

import { useKarrotCustomHeader } from './useKarrotCustomHeader';

type fetchInstanceProps = AxiosRequestConfig;

export const useFetchInstance = (fetchInstanceConfig?: fetchInstanceProps) => {
  const karrotCustomHeader = useKarrotCustomHeader();

  return wrapSSRErrorHandler(
    axiosClient.initFetchInstance(
      {
        ...fetchInstanceConfig,
        baseURL: globalThis.appConfig.apiEndPoints.community,
      },
      karrotCustomHeader
    )
  );
};
