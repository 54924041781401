import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import Cookies from 'universal-cookie';

import { COOKIE } from '@/constants/keys';

export type UserConfig = {
  regionId: number | null;
  regionProvinceName: string | null;
  regionCityName: string | null;
  regionTownName: string | null;
  userId: number | null;
  userNickname: string | null;
  userProfileImageUrl: string | null;
  userAuthToken: string | null;
  userHashId: string | null;
};

type UserConfigKey = keyof UserConfig;

type SetUserConfig = (nextConfig: Partial<UserConfig>) => void;

type Props = PropsWithChildren<UserConfig>;

const UserConfigContext = createContext<UserConfig>({
  regionId: null,
  regionProvinceName: null,
  regionCityName: null,
  regionTownName: null,
  userId: null,
  userNickname: null,
  userProfileImageUrl: null,
  userAuthToken: null,
  userHashId: null,
});

const setUserConfig = (() => {
  const userConfigCookieNameMap = new Map<UserConfigKey, string>([
    ['regionId', COOKIE.REGION_ID],
    ['regionProvinceName', COOKIE.REGION_PROVINCE_NAME],
    ['regionCityName', COOKIE.REGION_CITY_NAME],
    ['regionTownName', COOKIE.REGION_TOWN_NAME],
    ['userId', COOKIE.USER_ID],
    ['userNickname', COOKIE.USER_NICKNAME],
    ['userProfileImageUrl', COOKIE.USER_PROFILE_IMAGE_URL],
    ['userAuthToken', COOKIE.AUTH_TOKEN],
    ['userHashId', COOKIE.HASH_ID],
  ]);

  return (nextConfig: Partial<UserConfig>) => {
    const cookies = new Cookies();
    const cookieOptions = {
      maxAge: 60 * 60 * 24 * 365, // 1년
      path: '/',
    };
    Object.entries(nextConfig).forEach(([key, value]) => {
      const cookieName = userConfigCookieNameMap.get(key as UserConfigKey);
      if (!cookieName) return;

      const prevValue = cookies.get(cookieName);
      if (prevValue === value) return;

      cookies.set(cookieName, value, cookieOptions);
    });
  };
})();

export const UserConfigProvider = ({ children, ...userConfig }: Props) => {
  return <UserConfigContext.Provider value={userConfig}>{children}</UserConfigContext.Provider>;
};

export const useUserConfig = (): {
  userConfig: UserConfig;
  setUserConfig: SetUserConfig;
} => {
  const userConfig = useContext(UserConfigContext);

  return {
    userConfig,
    setUserConfig,
  };
};
