import clsx from 'clsx';
import React, { ForwardedRef, TextareaHTMLAttributes, useLayoutEffect } from 'react';

import * as s from './EditMultiLineTextarea.css';

type Props = {
  onChange: (value: string) => void;
  value: string;
  fixedHeight?: boolean;
  defaultRows?: number;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const EditMultiLineTextarea = (
  { fixedHeight = false, className, defaultRows = 3, ...rest }: Props,
  ref
) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const onHeightChange = React.useCallback(() => {
    if (fixedHeight && inputRef.current) {
      const input = inputRef.current;
      input.style.height = '';
    }
    if (!fixedHeight && inputRef.current) {
      const input = inputRef.current;
      const prevAlignment = input.style.alignSelf;
      const prevOverflow = input.style.overflow;
      const isFirefox = 'MozAppearance' in input.style;
      if (!isFirefox) {
        input.style.overflow = 'hidden';
      }
      input.style.alignSelf = 'start';
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight + (input.offsetHeight - input.clientHeight)}px`;
      input.style.overflow = prevOverflow;
      input.style.alignSelf = prevAlignment;
    }
  }, [inputRef, fixedHeight]);

  useLayoutEffect(() => {
    if (inputRef.current) {
      onHeightChange();
    }
  }, [onHeightChange, rest.value, inputRef]);

  function mergeRefs<T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> {
    if (refs.length === 1) {
      return refs[0];
    }

    return (value: T | null) => {
      for (const ref of refs) {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      }
    };
  }

  return (
    <textarea
      rows={defaultRows}
      ref={mergeRefs(ref, inputRef)}
      className={clsx(s.editMultilineTextarea, className)}
      {...rest}
    />
  );
};

export default React.forwardRef(EditMultiLineTextarea);
