import { bridge } from '@/contexts/Bridge';
import { isExposeInternalUser } from '@/utils/check';
import { initRedTeamVConsolePlugin } from '@/utils/vConsole/plugin/redTeam';

import { initInternalToolVConsolePlugin } from './plugin/initInternalToolVConsolePlugin';

const vconsoleConfig = {
  onReady: () => {
    const { appEnv, enabledRedTeam } = globalThis.appConfig;
    const vConsoleSwitch = document.querySelector('.vc-switch') as HTMLElement;
    vConsoleSwitch.style.position = 'fixed';
    vConsoleSwitch.style.top = '64px';
    vConsoleSwitch.style.height = '30px';

    // 프로덕션 환경에서는 vConsole 배경색을 변경
    if (appEnv === 'production') {
      if (enabledRedTeam) {
        vConsoleSwitch.style.backgroundColor = '#f74639';
      } else {
        vConsoleSwitch.style.backgroundColor = '#ff6f0f';
      }
    }
  },
};

export const initVConsole = async () => {
  const { default: vConsoleModule } = await import('vconsole');

  const renderVConsole = (appEnv: typeof globalThis.appConfig.appEnv) => {
    const vConsole = new vConsoleModule(vconsoleConfig);

    if (appEnv !== 'production') {
      // InternalTool은 alpha, dev 환경에서만 노출
      const internalToolVConsolePlugin = initInternalToolVConsolePlugin(vConsoleModule);
      vConsole.addPlugin(internalToolVConsolePlugin);
    }

    if (appEnv === 'production') {
      const redTeamPlugin = initRedTeamVConsolePlugin(vConsoleModule);
      vConsole.addPlugin(redTeamPlugin);
    }
  };

  const { appEnv } = globalThis.appConfig;

  const isAlphaStage = appEnv === 'alpha';

  if (isAlphaStage) renderVConsole('alpha');

  const { info } = (await bridge?.getUserInfo?.({})) ?? {};
  const userId = info?.user?.id ?? '';

  if (isExposeInternalUser(userId.toString(), false)) {
    renderVConsole('production');
    return;
  }
};
