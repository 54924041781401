import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
type Props = {
  userId: number;
  queryOptions?: {
    suspense?: boolean;
    hasOnError?: boolean;
    onError?: (error: unknown) => void;
  };
  queryKeys?: (string | number)[];
};

// 통합 프로필 조회 API
// 참고: https://daangn.slack.com/archives/C0336FFHA8L/p1714380677716699
export const useGetMainProfile = ({ userId, queryOptions, queryKeys = [] }: Props) => {
  const fetchInstance = useFetchInstance();
  const getMainProfile = groupClient.api.GroupProfileApi.apiV1GroupsProfileUserIdGet({
    axios: fetchInstance,
  });

  const { data, ...rest } = useQuery(
    [
      ...queryKeys,
      'MainProfile',
      'GroupProfile',
      groupClient.api.GroupProfileApi.getapiV1GroupsProfileUserIdGetPath(userId),
      userId,
    ],
    () => getMainProfile(userId),
    {
      suspense: true,
      ...queryOptions,
    }
  );

  const response = data?.data;

  return { data: response, ...rest };
};
