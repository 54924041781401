import { RefObject, useEffect, useMemo } from 'react';

const blackListExternalLink = [
  '오픈카톡',
  '오픈톡',
  '오키',
  'open.kakao',
  '오픈채팅',
  '오픈챗',
  'ㅇㅋㅂ',
  'ㅇㅗㅋㅏ',
  '카톡아이디',
  '카톡 아이디',
  'ㅋㅌㅂ',
  '옵챗',
  '옾챗',
  '참여코드',
  '단톡 주소',
  '갠톡',
];

function useCheckOpenKakao(
  ref?: RefObject<HTMLDivElement>,
  value?: string | null,
  isTalk?: boolean
) {
  const showOpenKakaoBanner = useMemo(() => {
    const removedSpaceInValue = value?.replace(/\s+/g, '') || '';
    return blackListExternalLink.find((link) => removedSpaceInValue?.includes(link));
  }, [value]);

  useEffect(() => {
    if (showOpenKakaoBanner && ref && isTalk) {
      ref?.current?.scrollIntoView({
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [isTalk, ref, showOpenKakaoBanner]);

  return {
    showOpenKakaoBanner,
  };
}

export default useCheckOpenKakao;
