import 'swiper/css';
import 'swiper/css/pagination';

import { groupClient } from '@community-group/api';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useOptimizedImageList } from './hooks/useOptimizedImageList';
import { getMinHeightImageList } from './utils/images';

export type DynamicSliderProps = {
  defaultHeight?: number;
  images: groupClient.model.Image[];
  onClick?: (index: number) => void;
};

export const DynamicSlider = ({
  defaultHeight,
  images,
  onClick: handleClick,
}: DynamicSliderProps) => {
  const imageHeight = useMemo(() => defaultHeight ?? getMinHeightImageList(images), [images]);
  const optimizedImages = useOptimizedImageList(images, imageHeight);

  return (
    <Swiper
      lazyPreloadPrevNext={1}
      pagination={{ dynamicBullets: true, clickable: true }}
      modules={[Pagination]}
      style={
        {
          '--swiper-pagination-color': vars.$static.color.staticWhite,
          '--swiper-pagination-bullet-opacity': 1,
          '--swiper-pagination-bullet-inactive-color': vars.$static.color.staticWhite,
          '--swiper-pagination-bullet-inactive-opacity': 0.4,
        } as { [key: string]: string | number }
      }
    >
      {optimizedImages.map((image, index) => (
        <SwiperSlide
          key={image.id}
          onClick={(e) => {
            e.stopPropagation();
            handleClick?.(index);
          }}
        >
          <img
            src={image.src}
            width={image.width}
            height={imageHeight}
            style={{
              width: '100%',
              objectFit: 'cover',
              backgroundColor: vars.$scale.color.gray00,
              border: `1px solid ${vars.$semantic.color.divider1}`,
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
