import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { Suspense } from 'react';

import { AppScreen } from '@/stackflow/components/AppScreen';

import CreateNewGroup from './components/CreateNewGroup';
import KickMember from './components/KickMember';
import PreviewSetting from './components/PreviewSetting';
import QuitGroup from './components/QuitGroup';
import QuitHostGroup from './components/QuitHostGroup';
import * as s from './index.css';

const InternalToolPage: ActivityComponentType = () => {
  return (
    <AppScreen
      appBar={{
        title: <div>Internal Tool</div>,
      }}
    >
      <Spacing size={16} />
      <div className={s.Wrapper}>
        <Suspense fallback={<div>Loading...</div>}>
          <PreviewSetting />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <CreateNewGroup />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <QuitHostGroup />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <QuitGroup />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <KickMember />
        </Suspense>
      </div>
    </AppScreen>
  );
};

export default InternalToolPage;
