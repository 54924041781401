import imageBlobReduce from 'image-blob-reduce';
import Pica from 'pica';
import { v4 } from 'uuid';

export type UploadImageType = {
  width: string;
  id: string;
  height: string;
  small: string;
  medium: string;
  large: string;
};

export const validateImagesFileType = (files: File[]) => {
  return files.every((file) => file && /^image\/(png|jpg|jpeg)/.test(file.type));
};

export const uploadImage = async ({
  image,
  postUploadImageV2,
}: {
  postUploadImageV2: (image: Blob) => Promise<UploadImageType>;
  image: Blob;
}) => {
  const response = await postUploadImageV2(image);

  return response;
};

const reduceBlobs = async (files: File[] | Blob[]) => {
  const pica = Pica({ features: ['js', 'wasm', 'cib'] });
  const reduce = new imageBlobReduce({ pica });

  return Promise.all(
    files.map(async (file) => {
      const blob = await reduce.toBlob(file, { max: 2532 });

      return new File([blob], v4(), { type: file.type });
    })
  );
};

export const uploadImages = async (
  postUploadImageV2: (image: Blob) => Promise<UploadImageType>,
  images: Blob[]
): Promise<PromiseSettledResult<UploadImageType>[]> => {
  const pressedImages = await reduceBlobs(images);

  const uploadedImages = pressedImages.map((image) => {
    return new Promise<UploadImageType>((resolve, reject) => {
      uploadImage({ postUploadImageV2, image })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });

  return Promise.allSettled(uploadedImages);
};
