import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics';
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change';
import {
  stackflow,
  useActions,
  UseActionsOutputType,
  UseStepActions,
  useStepActions,
} from '@stackflow/react';

import { bridge } from '@/contexts/Bridge';

import { activityPages } from './activity/pages';
import { routes } from './activity/routes';
import { checkVisitPlugin } from './plugins/checkVisitPlugin';
import { prevHistoryPlugin } from './plugins/prevHistoryPlugin';
import { previewNotificationPlugin } from './plugins/previewNotificationPlugin';

const initStackflow = () => {
  const handleDepthChange = (depth: number) => {
    if (import.meta.env.SSR) return;

    bridge.styleCurrentRouter({
      router: {
        backSwipable: depth === 1,
        navbar: false,
        scrollable: false,
        enableSafeAreaInsets: false,
        IOS_ONLY_hideKeyboardAccessoryView: true,
      },
    });
  };

  return stackflow({
    transitionDuration: 270,
    activities: activityPages,
    plugins: [
      historySyncPlugin({
        routes,
        fallbackActivity: () => 'NotFoundPage',
      }),
      basicRendererPlugin(),
      basicUIPlugin(({ initialContext }) => {
        return {
          bridge,
          theme: 'android',
          rootClassName: 'root',
          minSafeAreaInsetTop: initialContext.minSafeAreaInsetTop,
        };
      }),
      stackDepthChangePlugin({
        onInit: ({ depth }) => handleDepthChange(depth),
        onDepthChanged: ({ depth }) => handleDepthChange(depth),
      }),
      checkVisitPlugin(),
      prevHistoryPlugin(),
      previewNotificationPlugin(),
      karrotAnalyticsPlugin({
        bridge,
        serviceName: 'community_group',
        itemId: {
          GroupDetailPage: (activityParams) => {
            return activityParams.groupId;
          },
          GroupPostDetailPage(activityParams) {
            return activityParams.groupId;
          },
          GroupMeetupDetailPage(activityParams) {
            return activityParams.meetupId;
          },
        },
      }),
    ],
  });
};

const { Stack, activities } = initStackflow();

export type TypeActivities = typeof activities;
export type TypeActivityNames = keyof TypeActivities;

const useFlow = (): UseActionsOutputType<TypeActivities> => useActions();
const useStepFlow: UseStepActions<TypeActivities> = (activityName: TypeActivityNames) =>
  useStepActions(activityName as never);
const useConnectedFlow = () => useConnectedActions<TypeActivities>();

export { activities, Stack, useConnectedFlow, useFlow, useStepFlow };
