import { initMeetupTimeAt } from '@community-group/components';
import { Params } from '@sentry/react/types/types';
import { ActivityComponentType } from '@stackflow/react';
import { useForm } from 'react-hook-form';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import GroupChallengeCreateAccessoryBar from '../components/GroupChallengeCreateAccessoryBar';
import GroupChallengeForm from '../components/GroupChallengeForm';
import { useTempCreateGroupChallengeForm } from '../hooks/useTempCreateGroupChallengeForm';
import { GroupChallengeBaseForm } from '../types';

export const defaultGroupChallengeData: GroupChallengeBaseForm = {
  name: '',
  description: '',
  startedAt: initMeetupTimeAt(),
  totalDays: 28,
  certifyIntervalDays: 7,
  certifyIntervalTargetCount: 7,
  images: [],
  originImages: [],
};

const GroupChallengeNewPage: ActivityComponentType<Params> = () => {
  const { groupId } = usePathParams();
  const { defaultValue } = useQueryParams();

  const defaultData = defaultValue ? JSON.parse(defaultValue) : undefined;
  const formHandler = useForm<GroupChallengeBaseForm>({
    defaultValues: {
      ...defaultGroupChallengeData,
      ...defaultData,
    },
  });

  useTempCreateGroupChallengeForm({
    groupId,
    formHandler,
  });

  return (
    <AppScreen
      accessoryBar={
        <GroupChallengeCreateAccessoryBar groupId={groupId} formHandler={formHandler} />
      }
    >
      <GroupChallengeForm formHandler={formHandler} />
    </AppScreen>
  );
};

export default GroupChallengeNewPage;
