import { Spacing, Typography, VerticalSpacing } from '@community-group/components';

import * as s from './index.css';

type Props = {
  title?: string;
  description?: string;
  image?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const HomeBanner = ({ title, description, image, ...props }: Props) => {
  return (
    <div className={s.BigTitleWrapper} {...props}>
      <div className={s.BigTitleInnerWrapper}>
        {description && (
          <>
            <Typography typography="subtitle2Regular" color="gray900">
              {description}
            </Typography>
            <Spacing size={2} />
          </>
        )}
        {title && (
          <Typography typography="subtitle1Bold" color="gray900">
            {title}
          </Typography>
        )}
      </div>
      <VerticalSpacing size={14} />
      <img className={s.Image} src={image} width={72} height={72} />
    </div>
  );
};
