import { GroupLeaderBoardItem } from '@community-group/api/lib/group/models';
import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconLocationFill } from '@seed-design/icon';
import { useMemo } from 'react';

import GroupLevel2DBadge from '@/components/common/GroupLevel/GroupLevel2DBadge';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './RankGroupItem.css';

type Props = GroupLeaderBoardItem;

const RankGroupItem = (props: Props) => {
  return (
    <div className={s.OuterWrapper({ isTopRank: props.rank === 1 })}>
      <RankGroupItemCore {...props} />
    </div>
  );
};

const RankGroupItemCore = ({
  rank,
  groupId,
  groupName,
  groupThumbnailUrl,
  groupLevel,
  weeklyActivityPoint,
  displayRegionName,
}: Props) => {
  const { push } = useFlow();
  const rankTypo = useMemo(() => {
    if (rank === 1) return 'subtitle1Bold';
    return 'subtitle2Bold';
  }, [rank]);

  const rankColor = useMemo(() => {
    if (rank === 1) return 'primary';
    if (rank <= 3) return 'gray900';
    return 'gray600';
  }, [rank]);

  const handelClickGroupItem = () => {
    trackEvent({
      event: 'click_leaderboard_item_leaderboard_page',
      params: {
        groupId: groupId,
        groupName: groupName,
        currentRanking: rank,
        currentWeekPoint: weeklyActivityPoint,
      },
    });
    push('GroupDetailPage', {
      groupId: groupId.toString(),
    });
  };

  return (
    <div className={s.CoreWrapper} onClick={handelClickGroupItem}>
      <div className={s.RankWrapper}>
        <Typography typography={rankTypo} color={rankColor}>
          {rank}
        </Typography>
      </div>

      <VerticalSpacing size={12} />

      <div className={s.GroupThumbnailWrapper}>
        <img src={groupThumbnailUrl.medium} className={s.Thumbnail} />
        <div className={s.ThumbnailOverlay} />
        {rank <= 3 && (
          <div className={s.BadgeIcon}>
            <GroupLevel2DBadge level={groupLevel} size={24} />
          </div>
        )}
      </div>

      <VerticalSpacing size={16} />

      <div className={s.GroupInfoWrapper}>
        <Typography typography="subtitle1Bold" color="gray900" ellipsisAfterLines={1}>
          {groupName}
        </Typography>
        <Spacing size={4} />
        <div className={s.GroupSubInfoWrapper}>
          <div className={s.RegionIconWrapper}>
            <IconLocationFill
              color={rank === 1 ? vars.$scale.color.grayAlpha500 : vars.$scale.color.gray500}
              width={12}
              height={12}
            />
            <VerticalSpacing size={2} />
            <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
              {displayRegionName}
            </Typography>
          </div>
        </div>
      </div>

      <VerticalSpacing size={16} />

      <div className={s.GroupActivityPointWrapper}>
        <Typography typography="caption1Regular" color={rankColor}>
          {weeklyActivityPoint.toLocaleString()}점
        </Typography>
      </div>
    </div>
  );
};

export default RankGroupItem;
