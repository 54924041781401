import clsx from 'clsx';

import * as s from './settingMenuItem.css';

interface Props {
  warning?: boolean;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  onClick?: () => void;
  renderRight?: React.ReactNode;
  className?: string;
}

/**
 * @deprecated "공통레포의 SettingList.Item을 사용하세요." https://daangn.height.app/T-77506
 */
const SettingMenuItem = ({
  warning,
  title,
  description,
  onClick,
  renderRight,
  className,
}: Props) => (
  <div
    onClick={onClick}
    className={clsx(
      'setting-menu-item',
      className,
      s.settingItemWrapperStyle({
        warning,
        clickable: !!onClick,
      })
    )}
  >
    <div className={clsx('setting-menu-left-side', s.leftSideStyle)}>
      {title}
      {description && <p>{description}</p>}
    </div>
    {renderRight && (
      <div className={clsx('setting-menu-right-side', s.rightSideStyle)}>{renderRight}</div>
    )}
  </div>
);

export default SettingMenuItem;
