import {
  AsyncBoundary,
  Divider,
  HorizontalSpacing,
  isMember,
  isSuperHost,
  Typography,
  useBottomSheet,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useEffect, useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import {
  convertGroupMainProfileToGroupMemberSummary,
  useGetGroupMainProfile,
} from '@/api/hooks/useGetGroupMainProfile';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetMe } from '@/api/hooks/useGetMe';
import { Container } from '@/components/common/Container';
import IconWrapper from '@/components/common/Icons';
import { MemberProfileMeetupMemberReviews } from '@/components/common/MemberProfileMeetupMemberReviews';
import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';
import { useHandleProfileMoreButton } from '@/components/group/memberList/hooks/useHandleProfileMoreButton';
import ProfileTemperature from '@/components/group/profile/GroupProfile/Detail/components/ProfileTemperature';
import VerificationInfoSection from '@/components/group/profile/GroupProfile/Detail/components/VerificationInfoSection';
import { useBridge } from '@/contexts/Bridge';
import useCheckGroupProfileCreated from '@/hooks/useCheckGroupProfileCreated';
import useCheckHasPermission from '@/hooks/useCheckHasPermission';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import { SnsTagSection } from '../../GroupProfile/Detail/components/SnsTagSection';
import { GroupDirectChatButton } from '../../GroupProfile/shared/GroupDirectChatButton';
import MyRequestDirectChatPageAppBarRightButton from '../../MainProfile/Components/MyRequestDirectChatPageAppBarRightButton';
import Information from '../components/Information';
import { GroupProfilePostAndCommentsTab } from '../components/PostAndCommentsTab/PostAndCommentsTab';
import Activity from '../components/UserActivity';
import {
  MemberProfileSectionKey,
  useMemberProfileScrollTo,
} from '../hooks/useMemberProfileScrollTo';
import GroupEditMyProfileBottomSheet from './bottomSheet/GroupEditMyProfileBottomSheet';
import * as s from './index.css';

export type GroupUserProfileDetailPageParams = Pick<PageParams, 'groupId' | 'userId' | 'from'>;

// 모임원 프로필 상세 페이지 (모임 상세 > 멤버 리스트 > 프로필)
const GroupUserProfileDetailPage: ActivityComponentType<GroupUserProfileDetailPageParams> = () => {
  const { groupId, userId } = usePathParams();

  const { data: profile, isLoading } = useGetGroupMainProfile({
    groupId: Number(groupId),
    userId: Number(userId),
  });

  const { renderProfileMoreButton } = useHandleProfileMoreButton({
    groupId,
    user: convertGroupMainProfileToGroupMemberSummary(profile),
  });

  const GroupProfileAppBarRightButtonList = useCallback(() => {
    const buttonList = [] as React.ReactNode[];

    if (profile?.isMyProfile) {
      buttonList.push(<MyRequestDirectChatPageAppBarRightButton />);
    }

    if (isMember(profile?.role)) {
      const component = renderProfileMoreButton();
      if (component) {
        buttonList.push(<IconWrapper>{component}</IconWrapper>);
      }
    }

    return <>{buttonList}</>;
  }, [profile?.isMyProfile, profile?.role, renderProfileMoreButton]);

  // 프로필 데이터를 받아오지 못하거나, 프로필 데이터가 없거나, 계정이 삭제된 경우
  const isAccountDeleted =
    !isLoading && (!profile || Boolean(profile?.deletedAt) || Boolean(profile?.isAccountDeleted));
  if (isAccountDeleted) {
    return (
      <AppScreen>
        <DeletedAccountProfile />
      </AppScreen>
    );
  }

  return (
    <AppScreen
      appBar={{
        title: '모임 프로필',
        renderRight: () => {
          return <GroupProfileAppBarRightButtonList />;
        },
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <ProfileWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

const DeletedAccountProfile = () => {
  return (
    <div className={s.DeletedAccountProfileWrapper}>
      <Typography typography="subtitle1Regular" color="gray600">
        앗! 죄송해요.
        <br />
        존재하지 않는 사용자예요.
      </Typography>
    </div>
  );
};

const ProfileWrapper = () => {
  const { groupId, userId } = usePathParams();
  const { from } = useQueryParams();

  useMemberProfileScrollTo({ referContent: MemberProfileSectionKey.MEETUP_MEMBER_REVIEW_MESSAGES });

  const { data: profile } = useGetGroupMainProfile({
    groupId: Number(groupId),
    userId: Number(userId),
  });
  const { data: me } = useGetGroupMe(groupId);
  const { data: userMe } = useGetMe();

  const isMyProfile = useMemo(() => {
    return userMe?.id === profile?.id;
  }, [userMe, profile]);

  const { state: profileState } = useCheckGroupProfileCreated({});

  const { group } = useGetGroupDetail(groupId);
  const { open: openBottomSheet } = useBottomSheet();
  const hasOpenDirectChatPermission = useCheckHasPermission('openDirectChat');

  const { push, replace } = useFlow();

  useEnterTrackEvent({
    event: 'enter_member_profile',
    params: {
      hasSnsTags: (profile.snsTags && profile?.snsTags?.length > 0) ?? false,
      hasVerifiedInfo: group?.canViewVerifiedUserInfo,
      verifiedInfo: profile?.verifiedUserInfoText,
      role: me?.currentUser.role,
      from,
    },
    sample: true,
  });

  const { bridge } = useBridge();
  useEffect(() => {
    // 통합프로필이 없으면 본인 모임프로필을 조회할 수 없게 변경
    if (me?.currentUser.id === profile?.id) {
      if (profileState.needToProfileSet) {
        bridge.openToast({
          toast: {
            body: '프로필이 없어요. 프로필을 생성해주세요.',
          },
        });
        replace(
          'GroupProfileCreatePage',
          {},
          {
            animate: false,
          }
        );
      }
    }
  }, [bridge, me?.currentUser.id, profile?.id, profileState.needToProfileSet, replace]);

  const isRender1on1Chat = useMemo(() => {
    if (me?.currentUser.id !== profile?.id && hasOpenDirectChatPermission) return true;
    return false;
  }, [hasOpenDirectChatPermission, me?.currentUser.id, profile?.id]);

  const isRenderActivitySection = useMemo(() => {
    if (!profile?.activities && !profile?.application) return false;
    if (isSuperHost(me?.currentUser.role) || me?.currentUser?.id === Number(userId)) return true;
    return false;
  }, [
    me?.currentUser?.id,
    me?.currentUser.role,
    profile?.activities,
    profile?.application,
    userId,
  ]);

  const isRenderMeetupMemberReviews = useMemo(() => {
    const filteredPositiveMeetupMemberReviews = (profile?.meetupMemberReviews ?? []).filter(
      ({ feedbackTypeId }) => MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId)
    );

    return filteredPositiveMeetupMemberReviews.length > 0;
  }, [profile]);

  return (
    <LayoutWrapper>
      <Container paddingY={24}>
        <Information
          profile={profile}
          currentUser={{
            id: me?.currentUser.id ?? 0,
            role: me?.currentUser?.role ?? 'none',
            state: me?.currentUser?.state ?? 'notMember',
            verified: userMe.verified ?? false,
          }}
        />
        <SnsTagSection
          snsTags={profile?.snsTags}
          isMyProfile={profile?.id === me?.currentUser.id}
        />
        {isRender1on1Chat && (
          <>
            <GroupDirectChatButton
              groupId={Number(groupId) ?? 0}
              targetUserId={Number(userId) ?? 0}
            />
            <HorizontalSpacing size={16} />
          </>
        )}
        {me?.currentUser?.id === Number(userId) && (
          <>
            <BoxButton
              size="medium"
              variant="secondary"
              UNSAFE_style={{ width: '100%' }}
              onClick={() => {
                const isSubNicknameSettingOn = group?.subNicknameSetting.isSettingOn;

                if (!isSubNicknameSettingOn) {
                  push('GroupProfileEditPage', {
                    groupId,
                    userId: profile?.id.toString(),
                  });
                  return;
                }

                openBottomSheet({
                  element: (
                    <GroupEditMyProfileBottomSheet
                      pushEditProfilePage={() => {
                        push('GroupProfileEditPage', {
                          groupId,
                          userId: profile?.id.toString(),
                        });
                      }}
                      pushEditSubNicknamePage={
                        isSubNicknameSettingOn
                          ? () => {
                              push('GroupEditMemberSubNicknamePage', {
                                groupId,
                                userId: profile?.id.toString() ?? '',
                              });
                            }
                          : undefined
                      }
                    />
                  ),
                });
              }}
            >
              모임 프로필 수정
            </BoxButton>
            <HorizontalSpacing size={16} />
          </>
        )}
        <VerificationInfoSection
          profile={profile}
          canViewVerifiedUserInfo={group?.canViewVerifiedUserInfo}
          currentUser={{
            id: me?.currentUser.id ?? 0,
            role: me?.currentUser?.role ?? 'none',
            state: me?.currentUser?.state ?? 'notMember',
            verified: userMe.verified ?? false,
          }}
        />
        <HorizontalSpacing size={16} />
        <ProfileTemperature temperature={profile.temperature} />
      </Container>

      {isRenderActivitySection && (
        <>
          <HorizontalSpacing size={8} fill={vars.$semantic.color.divider1} />
          <Container paddingY={24}>
            <Activity />
          </Container>
          <HorizontalSpacing size={8} fill={vars.$semantic.color.divider1} />
        </>
      )}
      {isRenderMeetupMemberReviews && (
        <>
          {!isRenderActivitySection && (
            <Divider size={1} padding={16} background={vars.$semantic.color.divider1} />
          )}
          <HorizontalSpacing size={8} />
          <MemberProfileMeetupMemberReviews
            userId={(profile?.id ?? '').toString()}
            groupId={groupId}
            meetupMemberReviews={profile?.meetupMemberReviews ?? []}
            meetupMemberReviewMessages={profile?.meetupMemberReviewMessages ?? []}
            isMyProfile={isMyProfile}
          />
        </>
      )}
      <GroupProfilePostAndCommentsTab />
    </LayoutWrapper>
  );
};

export default withAsyncBoundary(GroupUserProfileDetailPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <DeletedAccountProfile />
    </AppScreen>
  ),
});
