import { Typography } from '@community-group/components';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import * as s from './FormRadioForApproval.css';

interface Props {
  name: string;
  title: string;
  subtitle?: string;
  value?: string;
  register: UseFormRegister<FieldValues>;
  onClick?: () => void;
  checked: boolean;
}

export const FormRadioForApproval = ({
  name,
  title,
  value,
  subtitle,
  register,
  onClick,
  checked,
}: Props) => (
  <section onClick={onClick}>
    <label className={s.checkboxWrapper({ checked })}>
      <input
        style={{ display: 'none' }}
        {...register(name, { required: true })}
        type="radio"
        value={value}
      />
      <div className={s.content}>
        <Typography typography="label2Bold" color="gray900">
          {title}
        </Typography>
        <Typography typography="label3Regular" color="gray700">
          {subtitle}
        </Typography>
      </div>
      <div className={s.radioButton({ checked })} />
    </label>
  </section>
);
