import { groupClient } from '@community-group/api';
import { GroupMeetupMemberReviewRequest } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupMeetupMemberReviewRequest: GroupMeetupMemberReviewRequest;
};

type Props = {
  groupId: number;
  meetupId: number;
} & UseMutationOptions<AxiosResponse<void>, Error, Params>;

export const usePostGroupMeetupReviewMembers = ({
  groupId,
  meetupId,
  onError,
  onSuccess,
}: Props) => {
  const fetchInstance = useFetchInstance();
  const PostGroupMeetupReviewMembers =
    groupClient.api.GroupMeetupReviewApi.apiV1GroupsGroupIdMeetupsMeetupIdReviewMembersPost({
      axios: fetchInstance,
    });

  const fetchPostGroupMeetupReviewMembers = ({ groupMeetupMemberReviewRequest }: Params) => {
    return PostGroupMeetupReviewMembers(groupId, meetupId, groupMeetupMemberReviewRequest);
  };

  return useThrottleMutation(fetchPostGroupMeetupReviewMembers, {
    onError,
    onSuccess,
  });
};
