import { withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';
import { MouseEventHandler, useMemo } from 'react';

import MemberMissionBanner from '@/components/common/MemberMissionBanner';

import useMemberMissionList, { MemberMission } from '../../hooks/useMemberMissionList';

type Props = {
  groupId: number;
  onClick: MouseEventHandler;
};

const SidebarMemberMissionBanner = ({ groupId, onClick }: Props) => {
  const missionList = useMemberMissionList(groupId.toString());
  if (missionList.length === 0) return null;

  return <SidebarMemberMissionBannerCore missionList={missionList} onClick={onClick} />;
};

type CoreProps = {
  missionList: MemberMission[];
  onClick: MouseEventHandler;
};

const SidebarMemberMissionBannerCore = ({ missionList, onClick }: CoreProps) => {
  const title = useMemo(() => {
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? '모임 가입 미션';
  }, [missionList]);

  const progress = useMemo(() => {
    const completedMissionCount = missionList.filter(({ isCompleted }) => isCompleted).length;
    const totalMissionCount = missionList.length;
    const value = (completedMissionCount / totalMissionCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = (e) => {
    onClick(e);
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  return (
    <MemberMissionBanner
      title={title}
      progress={progress}
      size="small"
      onClick={handleBannerClick}
      actionButton={<IconChevronRightFill size={16} color="gray600" />}
    />
  );
};

export default withAsyncBoundary(SidebarMemberMissionBanner, {});
