import { VirtualKeyboardContext } from '@community-group/components';
import { PropsWithChildren, useMemo } from 'react';
import { useEffect, useState } from 'react';

import { StackflowTheme } from '@/components/common/TransparentNavScreen/Navbar';
import { useBridge } from '@/contexts/Bridge';
import useFixedLayoutSize from '@/stackflow/hooks/useFixedLayoutSize';
import { useTheme } from '@/stackflow/hooks/useTheme';

export const VirtualKeyboardProvider = ({ children }: PropsWithChildren) => {
  const [keyboardOpened, setKeyboardOpened] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  const { bridge } = useBridge();
  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;

  const handleKeyboardOpenForAndroid = (value: boolean) => {
    if (deviceType !== 'android') return;
    setKeyboardOpened(value);
  };

  useEffect(() => {
    // 안드로이드에서는 키보드 브릿지를 사용하지 않는다.
    if (deviceType === 'android') {
      return;
    }
    bridge.subscribeKeyboardNotification({}, (err, res) => {
      if (err) {
        return;
      }
      console.log('res', res);
      switch (res?.notificationName) {
        case 'willShow':
          !keyboardOpened && setKeyboardOpened(true);
          setKeyboardHeight(res.keyboardHeight);
          break;
        case 'willHide':
          setKeyboardOpened(false);
          setKeyboardHeight(res.keyboardHeight);
          break;
        case 'willChangeHeight':
          setKeyboardHeight(res.keyboardHeight);
          break;
      }
    });
  }, [bridge, deviceType, keyboardOpened]);

  return (
    <VirtualKeyboardContext.Provider
      value={{
        keyboardHeight,
        keyboardOpened,
        handleKeyboardOpenForAndroid,
      }}
    >
      <UseFixedLayoutWrapper />
      {children}
    </VirtualKeyboardContext.Provider>
  );
};

const UseFixedLayoutWrapper = () => {
  useFixedLayoutSize();
  return null;
};
