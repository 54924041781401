import { vars } from '@seed-design/design-token';

import IconHeart from './IconHeart';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  filled?: boolean;
  size?: number;
}

export const HeartButton = ({ onClick, filled, size = 24 }: Props) => {
  const strokeColor = filled ? vars.$semantic.color.primary : vars.$scale.color.gray600;
  const fillColor = filled ? vars.$scale.color.carrot500 : 'transparent';

  return (
    <button
      onClick={onClick}
      style={{
        width: size,
        height: size,
      }}
    >
      <IconHeart size={size} strokeColor={strokeColor} fillColor={fillColor} />
    </button>
  );
};
