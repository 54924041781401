import { Typography } from '@community-group/components';
import { PropsWithChildren } from 'react';

import ChipRadio from '@/components/common/base/Input/Radio/Chip';

import * as s from './FeedSummarySection.css';

type Props = PropsWithChildren<{
  boardOptions: { key: number; value: string }[];
  selectedBoardKey: number;
  onBoardChange: (option: { key: number; value: string }) => void;
  totalCount?: number;
}>;

const FeedSummarySection = ({
  boardOptions,
  selectedBoardKey,
  onBoardChange,
  totalCount = 0,
  children,
}: Props) => {
  return (
    <section className={s.Container}>
      <div className={s.TitleContainer}>
        <Typography typography="title3Bold" color="gray900">
          게시글 {totalCount < 1 ? '' : totalCount}
        </Typography>
      </div>
      <ChipRadio
        id="group-detail-board-category"
        label=""
        options={boardOptions}
        selectedOptionKey={selectedBoardKey}
        onChange={onBoardChange}
      />
      {children}
    </section>
  );
};

export default FeedSummarySection;
