import { useCallback } from 'react';

import { useGetGroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import { useGetMyGroupMainProfile } from '@/api/hooks/useGetMyGroupMainProfile';

import { GroupDirectChatStatusOnAwaitingApprovalButton } from './DirectChatStatusButton/GroupDirectChatStatusOnAwaitingApprovalButton';
import { GroupDirectChatStatusOnEnabledButton } from './DirectChatStatusButton/GroupDirectChatStatusOnEnabledButton';
import { GroupDirectChatStatusOnRequiredApprovalButton } from './DirectChatStatusButton/GroupDirectChatStatusOnRequiredApprovalButton';
import * as s from './style.css';

type Props = {
  groupId: number;
  targetUserId: number;
};

export const GroupDirectChatButton = ({ groupId, targetUserId }: Props) => {
  const { data: myData } = useGetMyGroupMainProfile({ groupId });

  const { data } = useGetGroupMainProfile({
    userId: targetUserId,
    groupId,
  });

  const { directChatEnabledStatus, nickname, requestGroupDirectChatId } = data;

  const ChatButtons = useCallback(() => {
    if (directChatEnabledStatus === 'required_approval') {
      return (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnRequiredApprovalButton
            groupId={groupId}
            targetUserId={targetUserId}
            targetUserName={nickname}
            requestUserRole={myData?.role}
          />
        </div>
      );
    }
    if (directChatEnabledStatus === 'awaiting_approval' && !!requestGroupDirectChatId) {
      return (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnAwaitingApprovalButton
            groupId={groupId}
            targetUserId={targetUserId}
            requestUserRole={myData?.role}
            requestGroupDirectChatId={requestGroupDirectChatId}
          />
        </div>
      );
    }
    if (directChatEnabledStatus === 'enabled') {
      return (
        <div className={s.Wrapper}>
          <GroupDirectChatStatusOnEnabledButton
            groupId={groupId}
            targetUserId={targetUserId}
            requestUserRole={myData?.role}
            requestUserDirectChatEnabledStatus={directChatEnabledStatus}
          />
        </div>
      );
    }

    return <></>;
  }, [directChatEnabledStatus, requestGroupDirectChatId]);

  return <ChatButtons />;
};
