import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';

import UploadSeparateTab from './UploadSeparateTab';
import * as s from './VideoPOCPage.css';

const VideoPOCPage: ActivityComponentType = () => {
  return (
    <AppScreen
      appBar={{
        title: '영상 업로드',
      }}
    >
      <div className={s.Wrapper}>
        <UploadSeparateTab />
      </div>
    </AppScreen>
  );
};

export default VideoPOCPage;
