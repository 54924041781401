import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

interface Props {
  currentRole?: GroupMemberRoleEnum;
  accessibleRoles?: GroupMemberRoleEnum[];
  children: React.ReactNode;
}

const CheckAccessibleRole = ({ currentRole = 'none', accessibleRoles, children }: Props) => {
  if (!accessibleRoles) {
    return <>{children}</>;
  }

  const isAccessibleRole = accessibleRoles.includes(currentRole);

  if (isAccessibleRole) {
    return <>{children}</>;
  }

  return <></>;
};

export default CheckAccessibleRole;
