import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import {
  HelpBubbleTrigger,
  HorizontalSpacing,
  isMember,
  isSuperHost,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import {
  IconConfirmationProfileFill,
  IconInfoRegular,
  IconLocationFill,
  IconProfileFill,
} from '@seed-design/icon';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { GroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';

import * as s from './VerificationInfoSection.css';
type Props = {
  profile?: Pick<
    GroupMainProfile,
    | 'id'
    | 'role'
    | 'joinedAt'
    | 'userRegion'
    | 'userRegionCheckInCount'
    | 'verifiedUserInfoText'
    | 'state'
    | 'activities'
  > & {
    verified?: boolean;
  };
  canViewVerifiedUserInfo?: boolean;
  currentUser?: Pick<GroupCurrentUser, 'id' | 'role' | 'state'> & { verified?: boolean };
  groupCreated?: string;
};

const VerificationInfoSection = ({
  profile,
  canViewVerifiedUserInfo,
  currentUser,
  groupCreated,
}: Props) => {
  const [shownTooltip, setShownTooltip] = useState(true);

  const handleShownTooltip = () => {
    setShownTooltip(!shownTooltip);
  };

  const renderVerifiedInfo = () => {
    if (!profile?.verified) return;

    if (!profile.verifiedUserInfoText || !canViewVerifiedUserInfo) {
      return '본인인증 완료';
    }

    if (!currentUser?.verified) {
      return '본인인증 완료';
    }

    if (isSuperHost(currentUser?.role)) {
      return `본인인증 완료`;
    }

    if (
      (currentUser?.state === 'applied' ||
        isMember(currentUser?.role) ||
        profile.id === currentUser?.id) &&
      profile.role === 'superHost'
    ) {
      return `본인인증 완료`;
    }

    return `본인인증 완료`;
  };

  const renderRoleText = useMemo(() => {
    if (!profile) return '';
    if (profile.role === 'superHost') {
      return '모임장';
    }
    if (profile.role === 'manager') {
      return '운영진';
    } else return '';
  }, [profile]);
  return (
    <div className={s.Wrapper}>
      {profile?.verified && (
        <>
          <div className={s.IconWrapper}>
            <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
            <VerticalSpacing size={8} />
            <Typography color="gray900" typography="label3Regular">
              {renderVerifiedInfo()}
            </Typography>
            <VerticalSpacing size={4} />
            <HelpBubbleTrigger
              title={`휴대폰 본인인증 절차를 통과한\n이웃이에요.`}
              isOpen={!shownTooltip}
              UNSAFE_style={{
                whiteSpace: 'pre-wrap',
              }}
              positioning={{
                placement: 'bottom-start',
              }}
              zIndex={3}
              marginLeft={-14}
              closeOnInteractOutside
              onOpenChange={handleShownTooltip}
            >
              <IconInfoRegular
                data-tooltip-id="info-mode"
                size={16}
                color={vars.$scale.color.gray600}
              />
            </HelpBubbleTrigger>
          </div>
          <Spacing size={8} />
        </>
      )}
      {profile?.role !== 'none' && (
        <>
          <p className={s.detailInfoTextWrapper}>
            <IconProfileFill size={16} color={vars.$scale.color.gray600} />
            <VerticalSpacing size={8} />
            <Typography color="gray900" typography="label3Regular">
              {renderRoleText}
            </Typography>
            {profile?.role !== 'member' && (
              <Typography
                color="gray900"
                typography="label3Regular"
                style={{ padding: '0 0.25rem' }}
              >
                ∙
              </Typography>
            )}
            <Typography color="gray900" typography="label3Regular">
              {profile?.joinedAt && <>{dayjs(profile?.joinedAt).format('YYYY년 M월 D일')} 가입</>}
            </Typography>
          </p>
          <HorizontalSpacing size={8} />
        </>
      )}
      {groupCreated && (
        <>
          <div className={s.IconWrapper}>
            <IconProfileFill size={16} color={vars.$scale.color.gray600} />
            <VerticalSpacing size={8} />
            <Typography color="gray800" typography="label3Regular">
              {dayjs(groupCreated).format('YYYY년 M월 D일')} 모임 개설
            </Typography>
          </div>
          <HorizontalSpacing size={8} />
        </>
      )}
      <div className={s.IconWrapper}>
        <IconLocationFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography color="gray900" typography="label3Regular">
          {profile?.userRegion.town}{' '}
          {profile?.userRegionCheckInCount !== 0 && `인증 ${profile?.userRegionCheckInCount}회`}
        </Typography>
      </div>
    </div>
  );
};

export default VerificationInfoSection;
