import { useEffectOnce } from '@community-group/components';
import { useCallback } from 'react';

// DidMount 후 순차적으로 실행되는 함수
// 예시) 페이지 진입 시에 다른 페이지 Push 혹은 bottomSheet를 띄워주는 경우 사용
const useDidMountSequentialProcess = (callbackList: (() => boolean | Promise<boolean>)[]) => {
  const callback = useCallback(async () => {
    for (const callbackItem of callbackList) {
      const result = await callbackItem();
      if (result) break;
    }
  }, [callbackList]);

  // useEffect가 2번 호출되는 이슈가 있어서 useEffectOnce로 변경
  useEffectOnce(() => {
    callback();
  });

  return;
};

export default useDidMountSequentialProcess;
