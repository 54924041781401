type Temperature = {
  color: string;
  background: string;
  displayText: string;
  emoji: string;
};

export const getTemperature = (temperature: number): Temperature => {
  const displayText = `${temperature.toFixed(1)}°C`;
  if (temperature < 30) {
    return {
      color: '#777F87',
      background: 'rgba(119, 127, 135, 0.12)',
      displayText,
      emoji:
        'https://assetstorage.krrt.io/1414875091366735739/9379c509-3d57-44c2-a870-4c3f5fa687ca/width=18,height=18.svg',
    };
  }
  if (temperature < 36.3) {
    return {
      color: '#368FE2',
      background: 'rgba(54, 143, 226, 0.12)',
      displayText,
      emoji:
        'https://assetstorage.krrt.io/1414875091366735739/c6d80e3b-4ec6-4c0f-9c13-ce1c0893d07e/width=18,height=18.svg',
    };
  }
  if (temperature < 37.6) {
    return {
      color: '#16B6E9',
      background: 'rgba(22, 182, 233, 0.12)',
      displayText,
      emoji:
        'https://assetstorage.krrt.io/1414875091366735739/96457473-93fa-40e9-9e65-3a08d3c088a8/width=18,height=18.svg',
    };
  }
  if (temperature < 42) {
    return {
      color: '#30C795',
      background: 'rgba(48, 199, 149, 0.12)',
      displayText,
      emoji:
        'https://assetstorage.krrt.io/1414875091366735739/38348494-18ca-4b46-ab36-f4bedaa822c2/width=18,height=18.svg',
    };
  }
  if (temperature < 52) {
    return {
      color: '#FFA113',
      background: 'rgba(255, 161, 19, 0.12)',
      displayText,
      emoji:
        'https://assetstorage.krrt.io/1414875091366735739/451bcf40-13e0-4aaa-a36e-96160a9a7ea9/width=18,height=18.svg',
    };
  }
  return {
    color: '#FF6F0F',
    background: 'rgba(255, 111, 15, 0.12)',
    displayText,
    emoji:
      'https://assetstorage.krrt.io/1414875091366735739/0ba28e57-8830-4296-aad4-26dab901a6c2/width=18,height=18.svg',
  };
};
