import {
  AccessStatusServiceEnum,
  AccessStatusServiceResponseStatus,
} from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { ComponentType, useMemo } from 'react';

import { useGetAccessStatusService } from '@/api/hooks/useGetAccessStatusService';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openRestrictedDealFeedback } from '@/utils/link';

import { AccessStatus } from '../components/AccessStatus';

export type AccessStatusServiceHandlingPageParams = Pick<
  PageParams,
  'serviceType' | 'groupId' | 'meetupId' | 'postId' | 'accessStatusOption'
>;

const AccessStatusServiceHandlingPage: ActivityComponentType<
  AccessStatusServiceHandlingPageParams
> = () => {
  return (
    <AppScreen disabledClientOnly={true}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <AccessStatusHandlingView />
      </AsyncBoundary>
    </AppScreen>
  );
};

const AccessStatusHandlingView = () => {
  const {
    serviceType = 'group',
    groupId = '',
    meetupId = '',
    postId = '',
    accessStatusOption,
  } = useQueryParams();

  const serviceId = useMemo(() => {
    if (serviceType === 'meetup') return meetupId;
    if (serviceType === 'post') return postId;

    return groupId;
  }, [groupId, meetupId, postId, serviceType]);

  const { data: errorData } = useGetAccessStatusService({
    serviceType,
    serviceId,
  });

  const AccessStatus = () => {
    if (accessStatusOption === 'restricted') {
      return 'not_found';
    }
    return errorData?.data.status ?? 'not_found';
  };

  const AccessStatusComponents: {
    [key in AccessStatusServiceEnum]: ComponentType<StatusParams>;
  } = {
    group: GroupAccessStatus,
    meetup: MeetupAccessStatus,
    post: PostAccessStatus,
  };

  const CurrentAccessStatusComponent = AccessStatusComponents[serviceType];

  return CurrentAccessStatusComponent ? (
    <CurrentAccessStatusComponent status={AccessStatus()} groupId={groupId} />
  ) : (
    <ViewError />
  );
};

export default AccessStatusServiceHandlingPage;

type StatusParams = {
  status: AccessStatusServiceResponseStatus;
  groupId: string;
};

const PostAccessStatus = ({ groupId }: StatusParams) => {
  const { replace } = useFlow();

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.preventDefault();
    replace('GroupDetailPage', { groupId });
  };

  return (
    <AccessStatus
      message="앗! 존재하지 않는 게시글이에요."
      buttonLabel="모임으로 가기"
      onClick={handleClick}
    />
  );
};

const MeetupAccessStatus = ({ groupId }: StatusParams) => {
  const { replace } = useFlow();

  const handleClick = (event: React.MouseEvent<Element>) => {
    event.preventDefault();
    replace('GroupDetailPage', { groupId });
  };

  return (
    <AccessStatus
      message="앗! 존재하지 않는 일정이에요."
      buttonLabel="모임으로 가기"
      onClick={handleClick}
    />
  );
};

const GroupAccessStatus = ({ status }: StatusParams) => {
  const { replace } = useFlow();

  const { message, buttonLabel, handleClick } = useMemo(() => {
    switch (status) {
      case 'restricted': {
        return {
          message:
            '운영 정책 위반으로\n접근이 제한된 모임이에요. \n궁금한 점이 있다면 아래 버튼을 눌러 \n문의를 남겨주세요.',
          buttonLabel: '문의하기',
          handleClick: (event: React.MouseEvent<Element>) => {
            event.preventDefault();
            openRestrictedDealFeedback();
          },
        };
      }
      case 'permanent_restrictions': {
        return {
          message: '운영 정책 위반으로\n접근이 제한된 모임이에요.',
          buttonLabel: '모임 홈으로 가기',
          handleClick: (event: React.MouseEvent<Element>) => {
            event.preventDefault();
            replace('HomePage', {});
          },
        };
      }
      default: {
        return {
          message: '앗! 존재하지 않는 모임이에요.',
          buttonLabel: '모임 홈으로 가기',
          handleClick: (event: React.MouseEvent<Element>) => {
            event.preventDefault();
            replace('HomePage', {});
          },
        };
      }
    }
  }, [status]);

  return <AccessStatus message={message} buttonLabel={buttonLabel} onClick={handleClick} />;
};
