import { useStickInputStore } from '@community-group/components';

import * as s from './index.css';

interface Props {
  center?: boolean;
  children: React.ReactNode;
}

export const View = ({ center, children }: Props) => {
  const { setFocused } = useStickInputStore();

  const handleFocus = () => {
    setFocused(false);
  };

  return (
    <div
      className={s.wrapper}
      onTouchStart={handleFocus}
      onMouseDown={handleFocus}
      style={{
        justifyContent: center ? 'center' : 'flex-start',
      }}
    >
      {children}
    </div>
  );
};
