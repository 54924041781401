type Props = { size?: number };

function Large2DLevel3({ size = 48 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20.3909C0 19.5936 0.646326 18.9473 1.44361 18.9473H13.8947V29.0525H8.66165C3.87795 29.0525 0 25.1746 0 20.3909Z"
        fill="url(#paint0_linear_8594_118730)"
      />
      <path
        d="M48 20.3909C48 19.5936 47.3537 18.9473 46.5564 18.9473H34.1053V29.0525H39.3383C44.122 29.0525 48 25.1746 48 20.3909Z"
        fill="url(#paint1_linear_8594_118730)"
      />
      <path
        d="M26.9986 5.99989C25.1422 4.9281 22.855 4.9281 20.9986 5.99989L9.91016 12.4018C8.05375 13.4736 6.91016 15.4544 6.91016 17.598V30.4018C6.91016 32.5454 8.05375 34.5262 9.91016 35.598L20.9986 41.9999C22.855 43.0717 25.1422 43.0717 26.9986 41.9999L38.0871 35.598C39.9435 34.5262 41.0871 32.5454 41.0871 30.4018V17.598C41.0871 15.4544 39.9435 13.4736 38.0871 12.4018L26.9986 5.99989Z"
        fill="#3FC06A"
        stroke="#A5E999"
        strokeWidth="3"
      />
      <mask
        id="mask0_8594_118730"
        {...({ maskType: 'alpha' } as any)}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="38"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5161 5.09673L38.6083 11.5005C40.781 12.7584 42.1278 15.0836 42.1278 17.5993V30.4068C42.1278 32.9226 40.781 35.2478 38.6083 36.5057L27.5161 42.9094C25.3434 44.1673 22.6497 44.1673 20.477 42.9094L9.38476 36.5057C7.21206 35.2478 5.86523 32.9226 5.86523 30.4068V17.5993C5.86523 15.0836 7.21206 12.7584 9.38476 11.5005L20.477 5.09673C22.6497 3.83885 25.3434 3.83885 27.5161 5.09673Z"
          fill="#DDC7B8"
        />
      </mask>
      <g mask="url(#mask0_8594_118730)">
        <g filter="url(#filter0_d_8594_118730)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5174 5.09812L38.6059 11.5C40.7844 12.7578 42.1264 15.0823 42.1264 17.5979V30.4017C42.1264 32.9173 40.7844 35.2418 38.6059 36.4996L27.5174 42.9015C25.3389 44.1593 22.6548 44.1593 20.4762 42.9015L9.38778 36.4996C7.20923 35.2418 5.86719 32.9173 5.86719 30.4017V17.5979C5.86719 15.0823 7.20923 12.7578 9.38778 11.5L20.4762 5.09812C22.6548 3.84034 25.3389 3.84034 27.5174 5.09812ZM26.2468 7.29885C24.8545 6.495 23.1391 6.495 21.7468 7.29885L10.6584 13.7008C9.26606 14.5046 8.40836 15.9902 8.40836 17.5979V30.4017C8.40836 32.0094 9.26606 33.495 10.6584 34.2989L21.7468 40.7008C23.1391 41.5046 24.8545 41.5046 26.2468 40.7008L37.3353 34.2989C38.7276 33.495 39.5853 32.0094 39.5853 30.4017V17.5979C39.5853 15.9902 38.7276 14.5046 37.3353 13.7008L26.2468 7.29885Z"
            fill="#A5E999"
          />
        </g>
        <g filter="url(#filter1_d_8594_118730)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5174 5.09812L38.6059 11.5C40.7844 12.7578 42.1264 15.0823 42.1264 17.5979V30.4017C42.1264 32.9173 40.7844 35.2418 38.6059 36.4996L27.5174 42.9015C25.3389 44.1593 22.6548 44.1593 20.4762 42.9015L9.38778 36.4996C7.20923 35.2418 5.86719 32.9173 5.86719 30.4017V17.5979C5.86719 15.0823 7.20923 12.7578 9.38778 11.5L20.4762 5.09812C22.6548 3.84034 25.3389 3.84034 27.5174 5.09812ZM26.2468 7.29885C24.8545 6.495 23.1391 6.495 21.7468 7.29885L10.6584 13.7008C9.26606 14.5046 8.40836 15.9902 8.40836 17.5979V30.4017C8.40836 32.0094 9.26606 33.495 10.6584 34.2989L21.7468 40.7008C23.1391 41.5046 24.8545 41.5046 26.2468 40.7008L37.3353 34.2989C38.7276 33.495 39.5853 32.0094 39.5853 30.4017V17.5979C39.5853 15.9902 38.7276 14.5046 37.3353 13.7008L26.2468 7.29885Z"
            fill="#A5E999"
          />
        </g>
      </g>
      <g filter="url(#filter2_d_8594_118730)">
        <path
          d="M31.7535 23.448C31.0095 22.704 29.3775 21.768 27.5295 22.416C27.9375 21.552 28.2135 20.58 28.2135 19.548C28.2135 15.876 25.4175 15 24.0135 15C22.6095 15 19.8135 15.876 19.8135 19.548C19.8135 20.568 20.0895 21.552 20.4975 22.416C18.6375 21.768 17.0055 22.716 16.2735 23.448C15.2895 24.432 13.8855 26.928 16.5135 29.628C17.6518 30.7985 20.3661 32.2285 22.5065 32.7656C22.8851 32.8606 23.2335 32.563 23.2335 32.1727V29.784L19.4655 27.612C19.0935 27.396 18.9615 26.916 19.1775 26.544C19.3935 26.172 19.8735 26.04 20.2455 26.256L23.2335 27.984V23.388C23.2335 22.956 23.5815 22.608 24.0135 22.608C24.4455 22.608 24.7935 22.956 24.7935 23.388V27.984L27.7815 26.256C28.1535 26.04 28.6335 26.172 28.8495 26.544C29.0655 26.916 28.9335 27.396 28.5615 27.612L24.7935 29.784V32.1727C24.7935 32.563 25.1419 32.8606 25.5205 32.7656C27.6609 32.2285 30.3752 30.7985 31.5135 29.628C34.1415 26.928 32.7495 24.432 31.7535 23.448Z"
          fill="url(#paint2_linear_8594_118730)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8594_118730"
          x="3.32601"
          y="0.343021"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118730" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118730"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8594_118730"
          x="3.32601"
          y="2.8842"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118730" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118730"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8594_118730"
          x="14.3706"
          y="15"
          width="19.2891"
          height="19.3787"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.96" />
          <feGaussianBlur stdDeviation="0.317647" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118730" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118730"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8594_118730"
          x1="0"
          y1="23.9999"
          x2="13.8947"
          y2="23.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73D573" />
          <stop offset="1" stopColor="#3FC06A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8594_118730"
          x1="48"
          y1="23.9999"
          x2="34.1053"
          y2="23.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73D573" />
          <stop offset="1" stopColor="#3FC06A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8594_118730"
          x1="24.0154"
          y1="15"
          x2="24.0154"
          y2="32.916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Large2DLevel3;
