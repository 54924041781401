import { groupClient } from '@community-group/api';
import { GroupBotStatusForm } from '@community-group/api/lib/group/models';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  form: GroupBotStatusForm;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupBotStatus = (props: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupBotStatus = groupClient.api.GroupBotApi.apiV1GroupsIdGroupBotStatusPut({
    axios: fetchInstance,
  });

  return useMutation(({ groupId, form }: Params) => {
    const id = Number(groupId);
    return putGroupBotStatus(id, form);
  }, props);
};
