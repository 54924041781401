import { Image, Video } from '@community-group/api/lib/group/models';
import { isClient } from '@community-group/components';

const MAX_SLIDER_HEIGHT = 480; // ImageSlider 컴포넌트의 최대 높이
const MIN_SLIDER_HEIGHT = 220; // ImageSlider 컴포넌트의 최소 높이
const OLD_IMAGE_SLIDER_HEIGHT = 272; // 기존 ImageSlider 컴포넌트의 고정 높이

export const getMinHeightImageList = (images: (Image | Video)[]): number => {
  let minHeight = images[0].height ?? OLD_IMAGE_SLIDER_HEIGHT;

  images.forEach((image) => {
    const calculatedHeight = calculateImageRatioDeviceHeight(image);

    if (calculatedHeight < minHeight) {
      minHeight = calculatedHeight;
    }

    // TODO: 이미지업로드 v2로 인해 100width로 고정된 이미지들 최소 높이 주기
    if (minHeight < 150) {
      minHeight = OLD_IMAGE_SLIDER_HEIGHT;
    }
  });

  return minHeight;
};

const calculateImageRatioDeviceHeight = ({ width, height }: Image | Video) => {
  const imageRatio = isClient() ? window.innerWidth / width : 0;
  const adjustRatioHeight = imageRatio * height;

  const isLandscape = width >= height; //가로 이미지 여부
  const tempHeightValue = isLandscape ? adjustRatioHeight : height;

  if (tempHeightValue < MIN_SLIDER_HEIGHT) return MIN_SLIDER_HEIGHT;
  if (tempHeightValue > MAX_SLIDER_HEIGHT) return MAX_SLIDER_HEIGHT;

  // 소수점 이슈 : https://daangn.slack.com/archives/C0336FFHA8L/p1715936410762929
  return Math.floor(tempHeightValue);
};
