import { Typography } from '@community-group/components';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as s from './PhotoContestSampleSection.css';

const PhotoContestSampleSection = () => {
  const imageUrls = [
    'https://assetstorage.krrt.io/1025317940251365954/35726dab-ec07-4144-9156-3b821125dda3/width=504,height=504.png',
    'https://assetstorage.krrt.io/1025317940251365954/aad774ef-1f05-4f65-89ff-bf49fece278f/width=504,height=504.png',
    'https://assetstorage.krrt.io/1025317940251365954/b3088980-e012-4161-897b-ebbffb6e295c/width=504,height=504.png',
    'https://assetstorage.krrt.io/1025317940251365954/5c5d01ff-fa55-438d-87cc-280e5dd523d6/width=504,height=504.png',
  ];

  return (
    <>
      <div className={s.TitleWrapper}>
        <Typography typography="title3Bold" color="gray900">
          이런 사진이 좋아요!
        </Typography>
      </div>
      <Swiper className={s.Swiper} slidesPerView="auto" spaceBetween={8} lazyPreloadPrevNext={1}>
        {imageUrls.map((url, index) => (
          <SwiperSlide key={url} className={s.SwiperSlide}>
            <div className={s.ImageContainer}>
              <div className={s.ImageBorder} />
              <img
                src={url}
                className={s.Image}
                style={{ aspectRatio: '1 / 1' }}
                alt={`${index + 1}번째 예시 이미지`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PhotoContestSampleSection;
