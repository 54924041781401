import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<undefined, Error, string>;

export const useDeleteGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();

  const deleteGroup = async (id: string) => {
    const { data } = await fetchInstance.delete<undefined, AxiosResponse>(`${GROUP_URL}/${id}`);
    return data;
  };

  return useThrottleMutation(deleteGroup, {
    onError,
    onSuccess,
  });
};
