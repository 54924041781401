import { ReactNode } from 'react';
import { $Values } from 'utility-types';

import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';

import ModalContent from './components/Content';
import ModalHeader from './components/Header';
import ModalWrapper from './components/Wrapper';

interface Props {
  type?: $Values<typeof MODAL_KEY>;
  title?: string;
  children?: ReactNode;
  right?: ReactNode;
  isLoading?: boolean;
  isFullType?: boolean;
  hasHeader?: boolean;
  onRightClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onClose?: () => void;
  isOpen?: boolean;
  paddingY?: number;
  paddingX?: number;
  innerPaddingBottom?: number;
  onCancel?: () => void;
  preventOutsideClick?: boolean;
}

export const NotIncludeBackButtonControlModal = ({
  children,
  isFullType,
  type = MODAL_KEY.ACTION_MODAL,
  onClose,
  paddingY = 16,
  paddingX = 16,
  onRightClick,
  hasHeader,
  right,
  title,
  onCancel,
  preventOutsideClick,
  innerPaddingBottom,
}: Props) => {
  const { setOpenWebviewModal } = useStore();

  return (
    <ModalWrapper
      isFullType={isFullType}
      onOutsideClick={() => {
        if (preventOutsideClick) return;
        onClose && onClose();
        setOpenWebviewModal(type, false);
      }}
      paddingY={paddingY}
      paddingX={paddingX}
      innerPaddingBottom={innerPaddingBottom}
    >
      {isFullType && hasHeader && (
        <ModalHeader
          onCancel={onCancel}
          type={type}
          onRightClick={onRightClick}
          right={right}
          title={title}
        />
      )}
      <ModalContent isFullType={isFullType}>{children}</ModalContent>
    </ModalWrapper>
  );
};
