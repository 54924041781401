import { GroupLevelUpMissions } from '@community-group/api/lib/group/models';

export const GroupLevel = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
} as const;

export type GroupLevel = keyof typeof GroupLevel;

export type MissionId = keyof GroupLevelUpMissions;
