import { makeStorage } from './storage';

type AppLinkInfo = {
  isCommunityAppLink: boolean;
};

export const appLinkInfo = makeStorage<AppLinkInfo>({
  isCommunityAppLink: false,
});

export const getWebviewSchemePrefix = () => {
  const { isCommunityAppLink } = appLinkInfo.getData() ?? {};
  return isCommunityAppLink ? 'community-web' : 'minikarrot';
};

// TODO: LNB 등에서 레드닷 꺼지는 것을 적용하기 위해서는 서버쪽에서 appLink를 넣어줘야 함
// (참고: https://daangn.slack.com/archives/C0336FFHA8L/p1671085805394299)
export const initAppLink = () => {
  const appLink = new URLSearchParams(window.location.search).get('appLink');
  appLinkInfo.setData({
    isCommunityAppLink: appLink === 'community-web',
  });
};
