import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { ButtonHTMLAttributes } from 'react';

import { useKeyboardSize } from '../../../hooks';
import * as s from './BottomBasicButton.css';
import { BoxButton } from './BoxButton';

export interface BottomFixedButtonProps extends ButtonHTMLAttributes<Element> {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  theme?: 'primary' | 'primaryLow' | 'secondary';
}

// Figma URL: https://www.figma.com/file/u1jLOFOxfC6hKDkHLFLK8U/App-Pattern?node-id=1446%3A31970&t=J4ciQa0Tfx0iSmHo-4
export const BottomBasicButton: React.FC<BottomFixedButtonProps> = ({
  children,
  loading,
  theme = 'primary',
  disabled,
  onClick,
}: BottomFixedButtonProps) => {
  const { isKeyboardOn } = useKeyboardSize();

  return (
    <AnimatePresence>
      <motion.div
        className={s.Wrapper}
        initial={{
          padding: '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
        }}
        animate={{
          padding: isKeyboardOn
            ? ''
            : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
          bottom: 0,
        }}
        exit={{ bottom: 0, padding: 0 }}
        transition={{
          duration: 0,
        }}
      >
        <BoxButton
          size="xlarge"
          width="100%"
          isDisabled={disabled}
          onClick={onClick}
          isLoading={loading}
          UNSAFE_style={{
            borderRadius: isKeyboardOn ? '0' : undefined,
            padding: 0,
            transition: 'all 150ms',
          }}
        >
          {children}
        </BoxButton>
      </motion.div>
    </AnimatePresence>
  );
};
