import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import { getDateDiffFromNow } from '../../utils';
import { DEFAULT_PROFILE_IMG, MeetupProgressStatus } from '.';
import {
  MeetupListCardItemInfo,
  MeetupListCardItemInfoProps,
} from './components/MeetupListCardItemInfo';
import * as s from './MeetupCommonListCardItem.css';

type Props = HTMLAttributes<HTMLDivElement> &
  MeetupListCardItemInfoProps & {
    progressStatus: MeetupProgressStatus;
    label?: string;
    title: React.ReactNode;
    memberList: string[];
    image?: string;
    currentParticipantsNumber: number;
    maximumParticipantsNumber: number;
    maximumDisplayMemberProfileCount?: number;
    createdAt: string;
  };

export const MeetupCommonListCardItem: React.FC<Props> = ({
  title,
  infoList,
  progressStatus: { status, description: progressStatusDescription },
  label,
  memberList,
  currentParticipantsNumber,
  maximumParticipantsNumber,
  maximumDisplayMemberProfileCount = 3,
  createdAt,
  image,
  ...props
}: Props) => {
  return (
    <div {...props} className={clsx([props.className, s.container])}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <span className={s.badge({ status })}>{progressStatusDescription}</span> {label}
        </div>
        <div className={s.body}>
          <div className={s.bodyLeft}>
            <h4 className={s.title}>{title}</h4>
            <div
              className={s.spacing}
              style={{
                height: '8px',
              }}
            />
            <MeetupListCardItemInfo infoList={infoList} />
          </div>
          {image && (
            <div className={s.bodyRight}>
              <img className={s.image} src={image} />
            </div>
          )}
        </div>
        <div className={s.footer}>
          <div className={s.memberList}>
            <div className={s.memberImageList}>
              {Array.from({
                length: Math.min(maximumDisplayMemberProfileCount, currentParticipantsNumber),
              }).map((_, index) => {
                const userProfile = memberList[index];
                return (
                  <img
                    className={s.memberImageItem}
                    style={{
                      left: `${index * -6}px`,
                    }}
                    key={`${userProfile ?? 'empty-avatar'}-${index}`}
                    src={userProfile ?? DEFAULT_PROFILE_IMG}
                  />
                );
              })}
            </div>
            <div
              className={s.memberCount}
              style={{
                marginLeft: `${
                  (Math.min(maximumDisplayMemberProfileCount, currentParticipantsNumber) - 1) * -6
                }px`,
              }}
            >
              {currentParticipantsNumber}/{maximumParticipantsNumber}명
            </div>
          </div>
          <p className={s.createdAt}>{getDateDiffFromNow(new Date(createdAt)).text} 전</p>
        </div>
      </div>
    </div>
  );
};
