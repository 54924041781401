import { postUploadImageV2 } from '@community-group/api';
import { useHookFormImages } from '@community-group/components';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';

type Props = {
  formHandler: any;
};

const MAX_UPLOAD_VIDEO_COUNT = 10;

export const usePostFormPhotoHandler = ({ formHandler }: Props) => {
  const fetchCommunityInstance = useFetchInstance();

  const uploadedVideosCount = formHandler.watch('uploadVideos')?.length ?? 0;

  const { bridge } = useBridge();
  const { setImages } = useHookFormImages({
    handleHookFormContext: () => formHandler,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
    allowImageCount: MAX_UPLOAD_VIDEO_COUNT - uploadedVideosCount,
  });

  const openPhotoSelector = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const originImages = await bridge.driver.compat.pickImages();

    if (originImages) {
      setImages(originImages);
    }
  };

  return {
    openPhotoSelector,
  };
};
