import { useEffect, useRef, useState } from 'react';

import Lottie, { LottieProps } from '.';

type LottieURLProps = Omit<LottieProps, 'animationData'> & {
  src: string;
};

export const LottieURL = ({ src, ...lottieProps }: LottieURLProps) => {
  const [lottieFile, setLottieFile] = useState<any>();
  const lottieRef = useRef(null);

  useEffect(() => {
    if (!lottieFile) {
      fetch(src)
        .then((response) => {
          return response.json();
        })
        .then((lottie) => {
          setLottieFile(lottie);
        });

      return undefined;
    }
  }, [lottieRef.current, lottieFile]);

  if (!lottieFile) return <></>;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Lottie {...lottieProps} lottieRef={lottieRef} animationData={lottieFile} />;
};
