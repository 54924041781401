import { model } from '@community-group/api/lib/group';
import { useQuery } from '@tanstack/react-query';

import { CATEGORIES_URL, getCategories } from '../base/categories';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetCategories = () => {
  const fetchInstance = useFetchInstance();
  const { data } = useQuery([CATEGORIES_URL], () => getCategories(fetchInstance), {
    suspense: true,
  });

  return data as model.CategoriesResponse;
};
