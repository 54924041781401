import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { HTMLAttributes } from 'react';
import { useRef, useState } from 'react';

import * as s from './style.css';

type Props = HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  fieldLabel: React.ReactNode;
  fieldIcon?: React.ReactNode;
  fieldName: string;
  fieldValue: React.ReactNode;
};

export const GroupChallengeFormFieldWrapper = ({
  children,
  fieldLabel,
  fieldIcon,
  fieldName,
  fieldValue,
  onClick: handleClick,
  ...props
}: Props) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const [bodyToggle, setBodyToggle] = useState(false);
  const handleBodyToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleClick?.(event);

    if (children === undefined) return;
    setBodyToggle(!bodyToggle);
  };

  return (
    <div {...props} className={clsx([props.className, s.Wrapper])}>
      <div
        className={s.ListHeader}
        data-field-name={fieldName}
        data-field-toggle={bodyToggle}
        onClick={handleBodyToggle}
      >
        <div className={s.ListHeaderLeft}>
          {fieldIcon && <div className={s.IconWrapper}>{fieldIcon}</div>}
          <div className={s.Name}>{fieldLabel}</div>
        </div>
        <div className={s.ListHeaderRight}>{fieldValue}</div>
      </div>
      <AnimatePresence>
        {bodyToggle && (
          <motion.div
            key={fieldLabel?.toString()}
            {...motionConfig}
            onAnimationComplete={(animationDefinition: { opacity: number }) => {
              if (children && animationDefinition.opacity === 1) {
                bottomRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <div className={s.ScrollIntoViewBlock} ref={bottomRef} />
    </div>
  );
};

const motionConfig = {
  initial: {
    opacity: 0,
    height: 0,
    y: '10px',
    overflow: 'hidden',
    willChange: 'transform, opacity, height',
  },
  animate: { opacity: 1, height: 'auto', y: 0, animationTimingFunction: 'ease' },
  transition: {
    duration: 0.3,
    when: 'beforeChildren',
  },
  exit: { opacity: 0, height: 0, y: '10px', animationTimingFunction: 'linear' },
};
