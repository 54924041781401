import { Spinner } from '@community-group/components';
import { $Values } from 'utility-types';

import { ORDER_SEARCH } from '@/api/base/group';
import { useGetSearchGroupList } from '@/api/hooks/useGetSearchGroupList';
import { Container } from '@/components/common/Container';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './SearchResultLoader.css';

interface Props {
  category?: number[];
  order: $Values<typeof ORDER_SEARCH>;
  referrer: string;
}

export const SearchResultLoader = (filter: Props) => {
  const { query = '' } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetSearchGroupList({
    query,
    ...filter,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.Trigger} ref={ref} />
      <div className={s.Loading}>
        <Spinner variant="primary" size="small" />
      </div>
    </Container>
  );
};
