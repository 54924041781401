import useInitAppInfo from '@/hooks/useInitAppInfo';
import useInitUTMReferrer from '@/hooks/useInitUTMReferrer';

const Initialize = () => {
  useInitAppInfo();

  useInitUTMReferrer();

  return null;
};

export default Initialize;
