import { ReactNode } from 'react';

interface Props {
  isFullType?: boolean;
  children: ReactNode;
}

const ModalContent = ({ isFullType, children }: Props) => {
  return (
    <div style={isFullType ? { height: 'calc(100% - 44px)', overflow: 'auto' } : {}}>
      <div>{children}</div>
    </div>
  );
};

export default ModalContent;
