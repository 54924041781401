import { Children, forwardRef, Fragment, HTMLAttributes, isValidElement, Ref } from 'react';

import { Spacing } from '../Spacing';

type Props = { name: string; gap: number } & HTMLAttributes<HTMLDivElement>;

export const ContainerWithGap = forwardRef(
  ({ children, name, gap, ...props }: Props, ref: Ref<HTMLDivElement>) => {
    const elements = Children.toArray(children).filter(isValidElement);
    const lastIndex = elements.length - 1;

    return (
      <div ref={ref} {...props}>
        {elements.map((element, index) => (
          <Fragment key={`${name}-${index}-${gap}`}>
            {element}
            {lastIndex !== index && <Spacing size={gap} />}
          </Fragment>
        ))}
      </div>
    );
  }
);
