import clsx from 'clsx';

import * as s from './index.css';

type FormContainerProps = React.HTMLAttributes<HTMLFormElement>;
export const FormContainer = (props: FormContainerProps) => <form {...props} />;

type FormItemProps = React.HTMLAttributes<HTMLDivElement> & { marginBottom?: number };
export const FormItem = ({ marginBottom = 36, ...props }: FormItemProps) => (
  <div {...props} style={{ marginBottom, ...props.style }} />
);

type FormTitleProps = React.HTMLAttributes<HTMLParagraphElement>;
export const FormTitle = (props: FormTitleProps) => (
  <p {...props} className={clsx([props.className, s.formTitle])} />
);

type FormDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;
export const FormDescription = (props: FormDescriptionProps) => (
  <p {...props} className={clsx([props.className, s.formDescription])} />
);
