import { AsyncBoundary, Typography, ViewError, ViewLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import { useGetUserChatRequestGroupDirectChats } from '@/api/hooks/useGetUserChatRequestGroupDirectChats';
import { AppScreen } from '@/stackflow/components/AppScreen';

import { RequestDirectChatItem } from '../Components/RequestDirectChatItem';
import * as s from './style.css';

const MainProfileMyInfoRequestDirectChatsPage: ActivityComponentType = () => {
  return (
    <AppScreen
      appBar={{
        title: '채팅 요청',
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
      }}
      disabledClientOnly={true}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <MainProfileMyInfoRequestDirectChatsView />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default MainProfileMyInfoRequestDirectChatsPage;

const MainProfileMyInfoRequestDirectChatsView = () => {
  const { data } = useGetUserChatRequestGroupDirectChats();

  const requestDirectChats = data?.requestGroupDirectChats ?? [];

  if (requestDirectChats.length <= 0) {
    return (
      <Typography className={s.EmptyList} typography="bodyM1Regular" color="gray600">
        수락 대기 중인 요청이 없어요.
      </Typography>
    );
  }

  return (
    <div className={s.Wrapper}>
      {requestDirectChats.map((data) => (
        <RequestDirectChatItem key={data.id} requestDirectChat={data} />
      ))}
    </div>
  );
};
