import { motion } from 'framer-motion';
import { useMemo } from 'react';

import LockLv1 from '@/components/common/GroupLevel/assets/badge/2d/Lock_LV1.png';
import LockLv2 from '@/components/common/GroupLevel/assets/badge/2d/Lock_LV2.png';
import LockLv3 from '@/components/common/GroupLevel/assets/badge/2d/Lock_LV3.png';
import LockLv4 from '@/components/common/GroupLevel/assets/badge/2d/Lock_LV4.png';
import LockLv5 from '@/components/common/GroupLevel/assets/badge/2d/Lock_LV5.png';
import UnLockLv5 from '@/components/common/GroupLevel/assets/badge/2d/UnLock_LV5.png';

import * as s from './GroupLevelProgress.css';

type Props = {
  progress: number;
  nextLevel: number;
  size: 'small' | 'large';
  currentLevel?: number;
};

const GroupLevelProgress = ({ progress, nextLevel, size, currentLevel }: Props) => {
  const isLarge = size === 'large';
  const circleSize = isLarge ? 44 : 32;
  const strokeWidth = isLarge ? 3 : 2;
  const iconSize = isLarge ? 28 : 20;

  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  const lockBadge = useMemo(() => {
    if (currentLevel === 5) return UnLockLv5;

    switch (nextLevel) {
      case 1:
        return LockLv1;
      case 2:
        return LockLv2;
      case 3:
        return LockLv3;
      case 4:
        return LockLv4;
      case 5:
        return LockLv5;
      default:
        return LockLv1;
    }
  }, [nextLevel]);

  return (
    <div className={s.Container({ size })}>
      <svg className={s.Svg} viewBox={`0 0 ${circleSize} ${circleSize}`}>
        <circle className={s.Circle({ size })} cx={circleSize / 2} cy={circleSize / 2} r={radius} />
        <motion.circle
          className={s.Progress({ size, nextLevel })}
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          initial={{ strokeDasharray: circumference, strokeDashoffset: circumference }}
          animate={{
            strokeDashoffset: circumference - (progress / 100) * circumference,
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
      </svg>
      <div
        className={s.Content}
        style={{
          width: iconSize,
          height: iconSize,
        }}
      >
        <img src={lockBadge} alt="lockBadge" className={s.LockBadge} />
      </div>
    </div>
  );
};

export default GroupLevelProgress;
