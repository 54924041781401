import {
  ChipToggleButton as SproutChipToggleButton,
  SeedChipToggleButtonProps,
} from '@daangn/sprout-components-chips';
import { forwardRef } from 'react';

export const ChipToggleButton = forwardRef<HTMLDivElement, SeedChipToggleButtonProps>(
  ({ children, size = 'medium', isSelected = false, onClick, ...props }, ref) => {
    return (
      <SproutChipToggleButton
        ref={ref}
        isSelected={isSelected}
        size={size}
        {...props}
        onClick={onClick}
      >
        {children}
      </SproutChipToggleButton>
    );
  }
);
