import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  useBottomSheet,
  ViewError,
} from '@community-group/components';

type Props = {
  pushEditProfilePage: () => void;
  pushEditSubNicknamePage?: () => void;
};

const GroupEditMyProfileBottomSheet = (props: Props) => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewError />}>
      <GroupEditMyProfileOptionsWrapper {...props} />
    </AsyncBoundary>
  );
};

const GroupEditMyProfileOptionsWrapper = ({
  pushEditProfilePage,
  pushEditSubNicknamePage,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();
            pushEditProfilePage();
          }}
        >
          모임 프로필 수정
        </ActionListItem>
        {pushEditSubNicknamePage && (
          <ActionListItem
            onClick={async () => {
              await closeBottomSheet();
              pushEditSubNicknamePage();
            }}
          >
            별명 수정
          </ActionListItem>
        )}
      </ActionList>
    </ActionSheet>
  );
};

export default GroupEditMyProfileBottomSheet;
