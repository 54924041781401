import {
  ChipFilter as SproutChipFilter,
  SeedChipFilterProps,
} from '@daangn/sprout-components-chips';

export const ChipFilter: React.FC<SeedChipFilterProps> = ({
  label,
  selectedValue,
  prefix,
  onClick,
  ...props
}: SeedChipFilterProps) => {
  return (
    <SproutChipFilter
      selectedValue={selectedValue}
      label={label}
      prefix={prefix}
      {...props}
      onClick={onClick}
    />
  );
};
