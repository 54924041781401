import { useCallback } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';

import { FileUploadState, UploadStatus, uploadVideo } from '../api/upload';
import { UploadVideoResponse } from '../types/Video';
import { generateThumbnailFromVideo, getThumbnailFromPosterUri } from '../utils/Thumbnail';
import { ValidationConfig, validationVideoFile } from '../utils/validation';

interface UseUploadVideosProps {
  uploads: FileUploadState[];
  setUploads: (updater: (prevUploads: FileUploadState[]) => FileUploadState[]) => void;
  validationConfig?: ValidationConfig;
  onUploadStart?: (file: File) => void;
  onUploadSuccess?: (file: File, response: UploadVideoResponse) => void;
  onUploadError?: (file: File, error: Error) => void;
  onValidationError?: (message: string) => void;
}

export const useUploadVideos = ({
  uploads,
  setUploads,
  validationConfig = {
    // default validation config
    maxVideoSizeMB: 1024 * 1024 * 1024, // 1GB
    maxVideoDurationHours: 600, // 10 minutes
    allowedFileTypesRegex: '^video/(3gpp|3gp2|3gpp2|mpeg|mp4|x-m4v|quicktime|webm)',
  },
  onUploadStart,
  onUploadSuccess,
  onUploadError,
  onValidationError,
}: UseUploadVideosProps) => {
  const fetchInstance = useFetchInstance();

  const addThumbnail = useCallback(
    async (file: File, id: string) => {
      const thumbnail = await generateThumbnailFromVideo(file);
      setUploads((prev) => {
        const temp = prev.map((upload) => (upload.id === id ? { ...upload, thumbnail } : upload));
        return temp;
      });
    },
    [setUploads]
  );

  // 파일 추가 및 업로드 시작
  const addFiles = (files: File[]) => {
    files.forEach(async (file) => {
      // file validation
      const validationMessage = await validationVideoFile(file, validationConfig);
      if (validationMessage) {
        onValidationError?.(validationMessage);
        return;
      }

      try {
        const id = file.name + new Date().getTime();
        // generate thumbnail

        const thumbnail = await generateThumbnailFromVideo(file);

        const newUpload: FileUploadState = {
          id,
          file,
          thumbnail,
          status: 'idle' as UploadStatus,
          progress: 0,
        };

        setUploads((prev) => [...prev, newUpload]);
        // addThumbnail(file, id);
        startUpload(newUpload);
      } catch (error) {
        console.error('Failed to generate thumbnail:', error);
      }
    });
  };

  // 파일 업로드
  const startUpload = useCallback(
    async (fileState: FileUploadState) => {
      setUploads((prev) =>
        prev.map((upload) =>
          upload.file === fileState.file ? { ...upload, status: 'uploading' } : upload
        )
      );

      onUploadStart?.(fileState.file);

      try {
        const response: UploadVideoResponse = await uploadVideo({
          fetchInstance,
          file: fileState.file,
          onProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploads((prev) =>
              prev.map((upload) =>
                upload.file === fileState.file ? { ...upload, progress } : upload
              )
            );
          },
        });

        setUploads((prev) =>
          prev.map((upload) =>
            upload.file === fileState.file
              ? {
                  ...upload,
                  status: 'completed',
                  videoInfo: {
                    id: response.videoId,
                    duration: response.payload.commit_result.Data.SourceInfo.Duration,
                    thumbnail: getThumbnailFromPosterUri(response.videoId),
                  },
                }
              : upload
          )
        );

        onUploadSuccess?.(fileState.file, response);
      } catch (error) {
        setUploads((prev) =>
          prev.map((upload) =>
            upload.file === fileState.file
              ? { ...upload, status: 'failed', error: (error as Error).message }
              : upload
          )
        );

        onUploadError?.(fileState.file, error as Error);
      }
    },
    [setUploads, onUploadStart, fetchInstance, onUploadSuccess, onUploadError]
  );

  // 업로드 취소
  const cancelUpload = (fileState: FileUploadState) => {
    setUploads((prev) => prev.filter((upload) => upload.file !== fileState.file));
  };

  // 업로드 재시도
  const retryUpload = (fileState: FileUploadState) => {
    startUpload(fileState);
  };

  return {
    addFiles,
    startUpload,
    cancelUpload,
    retryUpload,
  };
};
