import clsx from 'clsx';
import React from 'react';

import { Spinner } from '../base';
import * as s from './ImageLoader.css';

type Props = {
  src?: string;
  isLoading?: boolean;
  isCircle?: boolean;
} & Omit<JSX.IntrinsicElements['img'], 'ref'>;

export const ImageLoader: React.FC<Props> = ({ src, isLoading, isCircle, ...props }) => {
  return (
    <>
      {isLoading && (
        <div className={s.loaderWrapper({ isCircle })}>
          <Spinner size="small" />
        </div>
      )}
      <img alt="selected" src={src} {...props} className={clsx([props.className, s.image])} />
    </>
  );
};
