import { useGetCheckHasGroupProfile } from '@/api/hooks/useGetCheckHasGroupProfile';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useStore } from '@/store';

type Params = {
  currentPage?: 'GroupDetail' | 'GroupHome';
};

const useCheckGroupProfileCreated = ({ currentPage = 'GroupHome' }: Params) => {
  const { data } = useGetCheckHasGroupProfile();
  const { replace } = useFlow();
  const { groupId } = usePathParams();

  const { profileCheckPending } = useStore();

  const needToProfileSet = !data?.isMainProfileSet;

  const handleCheckGroupProfileCreated = () => {
    if (!needToProfileSet) return;

    // 현재 페이지가 그룹 상세 페이지이고, 그룹 아이디가 있을 경우 그룹 프로필 생성 페이지로 이동
    if (currentPage === 'GroupDetail' && groupId) {
      replace(
        'GroupProfileCreatePage',
        {
          from: 'MemberGroupDetail',
          groupId,
        },
        {
          animate: false,
        }
      );
      return;
    }
  };

  return {
    handleCheckGroupProfileCreated,
    state: {
      profileCheckPending,
      needToProfileSet,
      isMainProfileSet: data?.isMainProfileSet,
    },
  };
};

export default useCheckGroupProfileCreated;
