import murmurhash from 'murmurhash-js';

export const isSampledUser = (userId: number, sampleRate: number): boolean => {
  // MurmurHash를 사용하여 Hash 값 계산 (유저 ID 기반으로 일관성, 임의성과 공정성을 고려하여 Sampling을 하기 위해)
  const hash = murmurhash(String(userId));

  // 0 ~ 1 사이의 값으로 정규화
  const normalizedHash = hash / 0xffffffff;

  // 정규화된 값이 Sample Rate 보다 작으면 Sampled
  return normalizedHash < sampleRate;
};

// Amplitude의 Sample Rate | 0.3(= 30%)를 적용
// 참고: https://www.notion.so/Launch-d9a3ccb3eece4d65ba4c35526d97b075?pvs=4 (24년 1월 15일 기준)
export const AMPLITUDE_SAMPLE_RATE = 0.3;
