import { vars } from '@seed-design/design-token';

import { GroupLevel, MissionId } from './GroupLevelType';
import { GROUP_LEVEL_ICONS } from './levelIcons';
import * as s from './levelPolicyInformation.css';
import { MISSION_INFO, MissionInfoType } from './missions';

type CreatedGroupLevelPolicyType = {
  levelBadge: string;
  levelColor: string;
  missions?: ({
    missionId: MissionId;
    achieveGoal: string;
  } & MissionInfoType[keyof MissionInfoType])[];
  benefits?: { title: string; contents: JSX.Element }[];
};

// 레벨 정책 생성 함수
const createGroupLevelPolicy = ({
  levelBadge,
  levelColor,
  missions,
  benefits,
}: GroupLevelPolicyType): CreatedGroupLevelPolicyType => ({
  levelBadge,
  levelColor,
  missions: missions?.map(({ missionId, achieveGoal }) => ({
    missionId,
    achieveGoal,
    ...MISSION_INFO[missionId],
  })),
  benefits,
});

type GroupLevelPolicyType = {
  levelBadge: string;
  levelColor: string;
  missions?: {
    missionId: MissionId;
    achieveGoal: string;
  }[];
  benefits?: { title: string; contents: JSX.Element; description?: string }[];
};

// 혜택 안내 이미지
const BENEFIT = {
  LEVEL_BADGE_GROUP_IMAGE: {
    level_1:
      'https://assetstorage.krrt.io/1025317940251365954/08441e9e-b4c4-4fd7-b5a3-62e7ffcd78ee/width=1029,height=480.png',
    level_2:
      'https://assetstorage.krrt.io/1025317940251365954/53e6891c-5570-48e8-887f-7dd549e02d38/width=1029,height=480.png',
    level_3:
      'https://assetstorage.krrt.io/1025317940251365954/ae586708-32db-4c68-88aa-aa35357bf5e0/width=1029,height=480.png',
    level_4:
      'https://assetstorage.krrt.io/1025317940251365954/04f06d46-a443-47fc-9247-5420e3d0252c/width=1029,height=480.png',
    level_5:
      'https://assetstorage.krrt.io/1025317940251365954/b25208e3-dd9c-4bf1-bef3-9fd588f803c4/width=1029,height=480.png',
  },

  EVENT_SUPPORT_QUALIFICATION:
    'https://assetstorage.krrt.io/1025317940251365954/62863920-a47c-431e-88b2-19148a2dc52a/width=1029,height=480.png',
  EXCELLENT_GROUP_BADGE:
    'https://assetstorage.krrt.io/1025317940251365954/e72e125d-1e3b-415a-ba34-80c82d6d6342/width=1029,height=480.png',
};

// 그룹 레벨 정책 정보
export const GROUP_LEVEL_POLICY_INFORMATION: Record<GroupLevel, GroupLevelPolicyType> = {
  [GroupLevel[0]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[0]],
    levelColor: vars.$scale.color.gray700,
  }),
  [GroupLevel[1]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[1]],
    levelColor: '#815A4F',
    missions: [
      { missionId: 'hasGroupDescription', achieveGoal: '1회' },
      { missionId: 'hasGroupThumbnail', achieveGoal: '1회' },
      { missionId: 'writeFirstPost', achieveGoal: '1회' },
    ],
    benefits: [
      {
        title: '모임 상단에 레벨 배지가 노출돼요.',
        contents: (
          <img src={BENEFIT.LEVEL_BADGE_GROUP_IMAGE.level_1} className={s.BenefitImageContainer} />
        ),
      },
    ],
  }),

  [GroupLevel[2]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[2]],
    levelColor: '#3D7FFF',
    missions: [
      { missionId: 'writeMeetup', achieveGoal: '2회' },
      { missionId: 'writePost', achieveGoal: '3회' },
      { missionId: 'raiseMember', achieveGoal: '3명' },
    ],
    benefits: [
      {
        title: '모임 상단에 레벨 배지가 노출돼요.',
        contents: (
          <img src={BENEFIT.LEVEL_BADGE_GROUP_IMAGE.level_2} className={s.BenefitImageContainer} />
        ),
      },
    ],
  }),

  [GroupLevel[3]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[3]],
    levelColor: '#3FC06A',
    missions: [
      { missionId: 'activityPoint', achieveGoal: '150점' },
      { missionId: 'raiseMember', achieveGoal: '10명' },
    ],
    benefits: [
      {
        title: '모임 상단에 레벨 배지가 노출돼요.',
        contents: (
          <img src={BENEFIT.LEVEL_BADGE_GROUP_IMAGE.level_3} className={s.BenefitImageContainer} />
        ),
      },
      {
        title: '추후 진행될 모임 지원 이벤트의 지원 자격을 얻어요.',
        contents: (
          <img src={BENEFIT.EVENT_SUPPORT_QUALIFICATION} className={s.BenefitImageContainer} />
        ),
      },
    ],
  }),

  [GroupLevel[4]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[4]],
    levelColor: '#FF9C28',
    missions: [
      { missionId: 'activityPoint', achieveGoal: '500점' },
      { missionId: 'raiseMember', achieveGoal: '30명' },
    ],
    benefits: [
      {
        title: '모임 상단에 레벨 배지가 노출돼요.',
        contents: (
          <img src={BENEFIT.LEVEL_BADGE_GROUP_IMAGE.level_4} className={s.BenefitImageContainer} />
        ),
      },
      {
        title: '추후 진행될 모임 지원 이벤트의 지원 자격을 얻어요.',
        contents: (
          <img src={BENEFIT.EVENT_SUPPORT_QUALIFICATION} className={s.BenefitImageContainer} />
        ),
      },
    ],
  }),

  [GroupLevel[5]]: createGroupLevelPolicy({
    levelBadge: GROUP_LEVEL_ICONS._2d.large[GroupLevel[5]],
    levelColor: '#FF6F0F',
    missions: [
      { missionId: 'activityPoint', achieveGoal: '5,000점' },
      { missionId: 'raiseMember', achieveGoal: '50명' },
    ],
    benefits: [
      {
        title: '모임 상단에 레벨 배지가 노출돼요.',
        contents: (
          <img src={BENEFIT.LEVEL_BADGE_GROUP_IMAGE.level_5} className={s.BenefitImageContainer} />
        ),
      },
      {
        title: '추후 진행될 모임 지원 이벤트의 지원 자격을 얻어요.',
        contents: (
          <img src={BENEFIT.EVENT_SUPPORT_QUALIFICATION} className={s.BenefitImageContainer} />
        ),
      },
      {
        title:
          "모임 첫 화면에 ‘우수 모임 배너'가 노출되어 우리 모임을 더 매력적으로 노출할 수 있어요.",
        description: '우수 모임 배너는 미가입자에게만 보여요.',
        contents: <img src={BENEFIT.EXCELLENT_GROUP_BADGE} className={s.BenefitImageContainer} />,
      },
    ],
  }),
};
