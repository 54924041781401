import {
  GroupCurrentUser,
  GroupEmotionUserPresentation,
} from '@community-group/api/lib/group/models';
import { Avatar, AvatarGroup, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { uniqBy } from 'lodash-es';
import { MouseEventHandler, useMemo } from 'react';

import { shortening } from '@/utils/text';

import * as s from './index.css';

type Props = {
  currentUser: GroupCurrentUser;
  emotionCount: number;
  emotionUsers: GroupEmotionUserPresentation[];
  hasCurrentUserEmotion: boolean;
  onClick: MouseEventHandler;
};

const EmotionCountMessage = ({
  currentUser,
  emotionCount,
  emotionUsers,
  hasCurrentUserEmotion,
  onClick,
}: Props) => {
  const emotionUserWithCurrentUser = useMemo(() => {
    return currentUser && hasCurrentUserEmotion
      ? uniqBy([currentUser, ...emotionUsers], (user) => user.id)
      : emotionUsers;
  }, [currentUser, emotionUsers, hasCurrentUserEmotion]);

  const emotionMessage = useMemo(() => {
    if (emotionCount === 1) {
      return '1명이 공감했어요';
    }

    const userNickname = hasCurrentUserEmotion ? currentUser?.nickname : emotionUsers[0].nickname;
    const displayUserName = userNickname ? shortening(userNickname, 8) : '';
    const count = emotionCount - 1;

    return `${displayUserName} 외 ${count}명이 공감했어요`;
  }, [currentUser, emotionCount, emotionUsers, hasCurrentUserEmotion]);

  return (
    <div className={s.EmotionCountMessage} onClick={onClick} aria-hidden="true">
      <AvatarGroup size="xxsmall">
        {emotionUserWithCurrentUser.slice(0, 2).map((user) => (
          <Avatar
            key={user.id}
            src={user.profileImage}
            alt={user.nickname}
            fallback={
              <img
                src="https://dnvefa72aowie.cloudfront.net/origin/profile/profile_default.png"
                alt="profile_default_image"
              />
            }
          />
        ))}
      </AvatarGroup>
      <VerticalSpacing size={4} />
      <Typography typography="caption1Regular" color="gray700">
        {emotionMessage}
      </Typography>
    </div>
  );
};

export default EmotionCountMessage;
