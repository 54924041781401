import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

const getGetNotificationsPath = (id: string) =>
  groupClient.api.NotificationApi.getapiV1GroupsIdNotificationGetPath(Number(id));

export const useGetNotifications = (id: string, hasPermission?: boolean) => {
  const fetchInstance = useFetchInstance();
  const getNotifications = groupClient.api.NotificationApi.apiV2GroupsIdNotificationGet({
    axios: fetchInstance,
  });
  const { data, refetch } = useQuery(
    [getGetNotificationsPath(id), hasPermission ?? false],
    () => {
      if (!hasPermission) return null;
      return getNotifications(Number(id));
    },
    {
      suspense: true,
    }
  );

  const response = data?.data?.notification;

  return { data: response, refetch };
};
