import { Typography } from '@community-group/components';
import React from 'react';

import * as s from './ActionButtonItem.css';

type Props = {
  onClick: () => void;
  buttonText: string;
  icon: React.ReactNode;
};

const ActionButtonItem = ({ onClick, buttonText, icon }: Props) => {
  return (
    <div className={s.ActionButtonWrapper} onClick={onClick}>
      <div className={s.IconWrapper}>{icon}</div>
      <div className={s.TextWrapper}>
        <Typography typography="subtitle1Regular" color="gray900">
          {buttonText}
        </Typography>
      </div>
    </div>
  );
};

export default ActionButtonItem;
