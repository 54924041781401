import { VerticalSpacing } from '@community-group/components';

import IconApprovedGroup from '@/assets/images/icon_approved_group.svg';

const RepresentativeGroupBadge = () => (
  <>
    <img src={IconApprovedGroup} alt="대표 모임 뱃지" width={16} height={16} />
    <VerticalSpacing size={4} />
  </>
);

export default RepresentativeGroupBadge;
