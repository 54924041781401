import React from 'react';

import { ReferParams, UTMParams } from '@/utils/analytics/type';

const useInitUTMReferrer = () => {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    const utmTerm = urlParams.get('utm_term');
    const utmContent = urlParams.get('utm_content');

    const referRoot = urlParams.get('refer_root');
    const referSection = urlParams.get('refer_section');
    const referContent = urlParams.get('refer_content');
    const referContentIndex = urlParams.get('refer_content_index');
    const referAction = urlParams.get('refer_action');

    window.utm = {
      utm_source: utmSource || undefined,
      utm_medium: utmMedium || undefined,
      utm_campaign: utmCampaign || undefined,
      utm_term: utmTerm || undefined,
      utm_content: utmContent || undefined,
    } as UTMParams;

    window.referrer = {
      refer_root: referRoot || undefined,
      refer_section: referSection || undefined,
      refer_content: referContent || undefined,
      refer_content_index: referContentIndex || undefined,
      refer_action: referAction || undefined,
    } as ReferParams;
  }, []);
};

export default useInitUTMReferrer;
