import { groupClient } from '@community-group/api';
import { ChallengeCertifiedActivitiesPresentation } from '@community-group/api/lib/group/models';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = {
  groupId: number;
  challengeId: number;
};

export const getChallengeMyCertifyActivityPath = ({ groupId, challengeId }: Props) =>
  groupClient.api.GroupChallengeParticipantApi.getapiV1GroupsGroupIdChallengesChallengeIdCertifiedActivitiesMeGetPath(
    groupId,
    challengeId
  );

export const useGetChallengeMyCertifyActivity = ({ groupId, challengeId }: Props) => {
  const fetchInstance = useFetchInstance();

  const getChallengeMyCertifyActivity =
    groupClient.api.GroupChallengeParticipantApi.apiV1GroupsGroupIdChallengesChallengeIdCertifiedActivitiesMeGet(
      {
        axios: fetchInstance,
      }
    );
  const { data, refetch } = useQuery(
    [getChallengeMyCertifyActivityPath({ groupId, challengeId })],
    () => {
      // TODO: 모의 데이터 제거
      return { data: mockData };
      // return getChallengeMyCertifyActivity(groupId, challengeId);
    },
    {
      suspense: true,
    }
  );

  return { data: data?.data, refetch };
};

const mockData: ChallengeCertifiedActivitiesPresentation = {
  currentRank: 1,
  intervalCertifiedProgress: {
    current: 1,
    target: 7,
  },
  totalCertifiedProgress: {
    current: 1,
    target: 28,
  },
};
