import { UNSAFE_appInfo } from '../bridge/appInfo';

export const initClarity = async () => {
  if (typeof document === 'undefined') return;

  const userId = UNSAFE_appInfo.getData()?.user?.id ?? undefined;
  // 프로덕션 환경에서는 10% 유저만 클래리티를 사용하도록 설정
  const isProduction = globalThis.appConfig.appEnv === 'production';
  const isNotTester = userId % 10 !== 0;
  if (isProduction && isNotTester) return;

  const script = document.createElement('script');

  const clarityKey = isProduction ? 'l4n977stc7' : 'l1386vwbkx';

  script.innerHTML = `(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', '${clarityKey}');`;

  const head = document.querySelector('head');
  head?.appendChild(script);

  // setTimeout이 초기화 되지 않는 이슈가 있어서 setTimeout으로 처리
  // https://github.com/daangn/realty-client/pull/732
  setTimeout(() => {
    setClarityUserInfo();
  }, 500);
};

// 수집 가능 데이터 정책
// https://daangn.slack.com/archives/C04K5AF4B/p1680759414747789?thread_ts=1680168490.868989&cid=C04K5AF4B
export async function setClarityUserInfo() {
  const userId = UNSAFE_appInfo.getData()?.user.id.toString();
  try {
    window?.clarity?.('identify', userId);
  } catch (err: any) {
    if (err) {
      console.log(`[clarity error]: ${err.message}`);
    }
  }
}
