import { Container, Spinner } from '@community-group/components';

import { useGetMemberFeeds } from '@/api/hooks/useGetMemberFeeds';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './PostAndCommentsTab.css';

export const LoadMoreProfileFeeds = () => {
  const { groupId, userId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMemberFeeds({
    groupId: Number(groupId),
    userId: Number(userId),
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.Loading} ref={ref}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};
