import { GroupPhotoPresentation } from '@community-group/api/lib/group/models';
import { Image, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightThin, IconPushpinRegular } from '@seed-design/icon';
import { useCallback } from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './GroupDetailHeroLastSlider.css';

type Props = {
  photoList: GroupPhotoPresentation[];
  groupId: string;
  notMember?: boolean;
};

function GroupDetailHeroFirstSlider({ photoList, groupId, notMember }: Props) {
  const { push } = useFlow();

  const handleMoveToAlbum = useCallback(() => {
    trackEvent({
      event: 'click_hero_nudge_slider',
      params: {
        groupId,
        type: 'last_slide',
      },
      sample: true,
    });
    push('GroupSettingHeroImageSelectorPage', {
      groupId,
    });
  }, [groupId, push]);

  return (
    <div className={s.LastSlide} onClick={handleMoveToAlbum}>
      <Image
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        key={'last-slide'}
        src={photoList[photoList.length - 1].image.medium}
        alt="이미지"
        aria-hidden="true"
        width={'100%'}
        height={'100%'}
      />
      <div className={s.LastSlideInnerWrapper({ notMember })}>
        <div className={s.IconWrapper}>
          <IconPushpinRegular size={20} color={vars.$static.color.staticGray900} />
        </div>
        <Typography color="onPrimary" typography="caption1Regular">
          배경사진 설정하기
        </Typography>
      </div>
      <div className={s.LastSlideGradation} />
    </div>
  );
}

export default GroupDetailHeroFirstSlider;
