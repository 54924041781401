import { Typography } from '@community-group/components';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as s from './PhotoContestSampleSection.css';

const PhotoContestSampleSection = () => {
  const imageUrls = [
    'https://assetstorage.krrt.io/1029678394714174377/37b2b3f2-4e07-4e9b-b695-6bbf8ac1ca2f/width=504,height=504.png',
    'https://assetstorage.krrt.io/1029678394714174377/17d4b275-ab9d-457b-9e0a-310eefd5300d/width=504,height=504.png',
    'https://assetstorage.krrt.io/1029678394714174377/ae74ac5a-1a89-4cf8-8e5b-5e673f7c21a1/width=504,height=504.png',
    'https://assetstorage.krrt.io/1029678394714174377/10f9b530-9470-4aaf-a461-86ee2c31d801/width=504,height=504.png',
  ];

  return (
    <>
      <div className={s.TitleWrapper}>
        <Typography typography="title3Bold" color="gray900">
          이렇게 찍어보세요!
        </Typography>
      </div>
      <Swiper className={s.Swiper} slidesPerView="auto" spaceBetween={8} lazyPreloadPrevNext={1}>
        {imageUrls.map((url, index) => (
          <SwiperSlide key={url} className={s.SwiperSlide}>
            <div className={s.ImageContainer}>
              <div className={s.ImageBorder} />
              <img
                src={url}
                className={s.Image}
                style={{ aspectRatio: '1 / 1' }}
                alt={`${index + 1}번째 예시 이미지`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PhotoContestSampleSection;
