import { StoreSlice } from '..';

export type GroupMarketingSlice = {
  is11월독서MarketingJoinable: boolean;
  is11월독서MarketingCreate: boolean;
  set11월독서MarketingJoinable: (value: boolean) => void;
  set11월독서MarketingCreate: (value: boolean) => void;
};

export const createGroupMarketingSlice: StoreSlice<GroupMarketingSlice> = (set) => ({
  is11월독서MarketingJoinable: false,
  is11월독서MarketingCreate: false,
  set11월독서MarketingJoinable: (value: boolean) => set({ is11월독서MarketingJoinable: value }),
  set11월독서MarketingCreate: (value: boolean) => set({ is11월독서MarketingCreate: value }),
});
