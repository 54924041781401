import { Spacing } from '@community-group/components';
import { motion } from 'framer-motion';

import { MemberMission } from '@/components/group/DetailV2/hooks/useMemberMissionList';

import MemberMissionItem from './MemberMissionItem';
import * as s from './MemberMissionList.css';

type Props = {
  missionList: MemberMission[];
  opened: boolean;
};

const MemberMissionList = ({ opened, missionList }: Props) => {
  const opacityDuration = opened ? 0.5 : 0.2;
  const animateConfig = opened
    ? { opacity: 1, height: 'auto', transitionEnd: { display: 'block' } }
    : { opacity: 0, height: 0, transitionEnd: { display: 'none' } };

  const handleMissionItemClick = (item: MemberMission) => () => {
    if (item.isCompleted) return;
    item.onClick();
  };

  return (
    <motion.div
      style={{ width: '100%' }}
      animate={animateConfig}
      initial={false}
      transition={{
        opacity: { duration: opacityDuration, type: 'spring', bounce: 0.26 },
        height: { duration: 0.5, type: 'spring', bounce: 0.26 },
      }}
    >
      <Spacing size={16} />
      <ul className={s.MissionList}>
        {missionList.map((item, i) => (
          <>
            <MemberMissionItem
              key={item.title}
              icon={item.icon}
              title={item.title}
              isCompleted={item.isCompleted}
              onClick={handleMissionItemClick(item)}
            />
            {i === missionList.length - 1 ? null : <Spacing size={8} />}
          </>
        ))}
      </ul>
    </motion.div>
  );
};

export default MemberMissionList;
