import { getBoundsByCoordinates } from '@community-group/components';
import type { GeoJSONSourceRaw } from 'mapbox-gl';
import { useEffect } from 'react';

import { useMap } from '..';

type Props = {
  poiName: string;
  coordinates: [number, number][]; // [lng, lat]
  options?: {
    fitBounds?: boolean;
    removeOthers?: boolean;
    moveToCenter?: boolean;
  };
  getBoundsCoordinates?: (coordinates: [number, number][]) => [number, number][];
};

// NOTE: 이 컴포넌트는 Map 컴포넌트 내부에서만 사용되는 컴포넌트입니다.
// Map의 addPolygonLayer 메서드를 호출하여 Polygon을 추가할 수도 있고,
// map의 children으로 Polygon 컴포넌트를 호출하여 사용할 수도 있습니다.
export const Polygon = ({ poiName, coordinates, options }: Props) => {
  const { map: mapRef } = useMap();

  useEffect(() => {
    const geoJSONData = getPolygonCoordinates(poiName, coordinates);

    mapRef?.current?.addPolygonLayer(geoJSONData, {
      removeOthers: options?.removeOthers ?? true,
      moveToCenter: options?.moveToCenter ?? true,
    });

    if (options?.fitBounds) {
      const fitBoundData = getBoundsByCoordinates([[coordinates]], {
        areaMarginPixel: { bottom: 100, left: 30, right: 60, top: 30 },
        container: { clientHeight: 500, clientWidth: 410 },
      });

      mapRef.current?.fitBounds(fitBoundData);
    }
  }, [
    coordinates,
    mapRef,
    options?.fitBounds,
    options?.moveToCenter,
    options?.removeOthers,
    poiName,
  ]);

  return null;
};

// Coordinates[]를 GeoJSONSourceRaw로 변환하는 유틸 함수
export const getPolygonCoordinates = (placeName: string, coordinates: [number, number][]) => {
  const geoJSONData: GeoJSONSourceRaw = {
    type: 'geojson' as const,
    data: {
      properties: { name: placeName },
      type: 'Feature' as const,
      geometry: {
        type: 'MultiPolygon' as const,
        coordinates: [[coordinates]],
      },
    },
  };

  return geoJSONData;
};
