import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getUserProfileCommentsPath = (groupId: number, userId: number) =>
  groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersUserIdCommentsGetPath(groupId, userId);

export const useGetUserProfileComments = (id: number, userId: number) => {
  const fetchInstance = useFetchInstance();
  const getProfileComments = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersUserIdCommentsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getUserProfileCommentsPath(id, userId)],
    ({ pageParam = undefined }) => getProfileComments(id, userId, pageParam, 100),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
