import { INTERNAL_USER_ID_LIST } from '@/constants/user';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

const useLeaderBoard = () => {
  const useLeaderBoard = useFeatureFlag('useLeaderBoard');

  const { userConfig } = useUserConfig();

  const isInternalUser = INTERNAL_USER_ID_LIST.includes(userConfig?.userId?.toString() ?? '0');

  if (useLeaderBoard || isInternalUser) {
    return {
      isOpenedLeaderBoard: true,
    };
  }

  return {
    isOpenedLeaderBoard: false,
  };
};

export default useLeaderBoard;
