import 'react-day-picker/dist/style.css';

import clsx from 'clsx';
import ko from 'date-fns/locale/ko/index';
import dayjs from 'dayjs';
import { CaptionLabelProps, DayPicker, DayPickerSingleProps } from 'react-day-picker';

import * as s from './index.css';

type Props = Omit<DayPickerSingleProps, 'mode'>;

export const MeetupDatePicker = (props: Props) => (
  <div>
    <DayPicker
      mode="single"
      locale={ko}
      components={{
        CaptionLabel: ({ displayMonth }: CaptionLabelProps) => (
          <div className={s.caption}>{dayjs(displayMonth).format('YYYY년 MM월')}</div>
        ),
      }}
      {...props}
      className={clsx([props.className, s.dayPicker])}
    />
  </div>
);
