import {
  Callout,
  CalloutDescription,
  CalloutTitle,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence } from 'framer-motion';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutProfile } from '@/api/hooks/usePutProfile';
import { FormInput } from '@/components/common/base/Input/Text';
import SubNicknamePreview from '@/components/common/GroupMemberSubNicknamePreview/SubNicknamePreview';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupUserProfile } from '@/utils/refetch/groupUserProfile';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';

export type GroupSettingPageParams = Pick<PageParams, 'groupId' | 'userId'>;

const GroupEditMemberSubNicknamePage: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const { pop } = useFlow();
  const currentUser = useCurrentGroupMe();

  const { group } = useGetGroupDetail(groupId);

  const { watch, register } = useForm({
    defaultValues: {
      subNickname: currentUser.subNickname ?? '',
    } as FieldValues,
  });

  const subNicknameFieldValue = watch('subNickname');

  const handleErrorWithToast = useHandleErrorWithToast();

  const { mutate, isLoading: isSubmitEditProfileLoading } = usePutProfile({
    onSuccess: ({ data }) => {
      refetchGroupDetail({ groupId });
      refetchGroupUserProfile({ groupId, userId: data.groupMember.id.toString() ?? '' });
      pop();
    },
    onError: (error) => {
      handleErrorWithToast(error);
    },
  });

  const handleSubmit = () => {
    mutate({
      id: Number(groupId.toString()),
      groupMemberModifyForm: {
        profile: {
          subNickname: subNicknameFieldValue,
        },
      },
    });
  };

  return (
    <AppScreen
      appBar={{
        title: '별명',
        border: true,
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
        renderRight: () => {
          return (
            <AppBarTextSubmitButton
              onClick={handleSubmit}
              disabled={isSubmitEditProfileLoading || !subNicknameFieldValue}
              loading={isSubmitEditProfileLoading}
            />
          );
        },
      }}
    >
      <div style={{ padding: '1rem' }}>
        <Callout>
          <CalloutTitle>안내</CalloutTitle>
          <CalloutDescription>별명은 이 모임에서만 닉네임 옆에 표시돼요.</CalloutDescription>
        </Callout>
        <Spacing size={16} />
        <SubNicknamePreview subNicknameText={subNicknameFieldValue ?? ''} />
        <Spacing size={24} />
        <AnimatePresence>
          <Typography typography="label3Bold" color="gray900">
            {group?.subNicknameSetting?.requestText ?? '나이/성별/운동경력'}
          </Typography>
          <Spacing size={12} />
          <FormInput
            register={register}
            value={subNicknameFieldValue}
            name="subNickname"
            placeholder="별명을 입력해주세요"
            minLength={groupMemberProfileFieldSchema.subNickname.minLength ?? 0}
            maxLength={groupMemberProfileFieldSchema.subNickname.maxLength ?? 0}
          />
        </AnimatePresence>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupEditMemberSubNicknamePage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
