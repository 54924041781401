import { withAsyncBoundary } from '@community-group/components';
import { MouseEventHandler, useMemo } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetGroupMeetupEmotionList } from '@/api/hooks/useGetGroupMeetupEmotionList';
import EmotionCountMessage from '@/components/common/EmotionCountMessage';

import * as s from './GroupMeetupDetailEmotionCountMessage.css';

type Props = {
  groupId: string;
  meetupId: string;
  meetupEmotionCount: number;
  hasCurrentUserEmotion: boolean;
  onClick: MouseEventHandler;
};

const GroupMeetupDetailEmotionCountMessage = ({
  groupId,
  meetupId,
  meetupEmotionCount,
  ...props
}: Props) => {
  const { data: groupMe } = useGetGroupMe(groupId);
  const { data } = useGetGroupMeetupEmotionList({
    groupId: Number(groupId),
    meetupId: Number(meetupId),
    limit: 2,
  });

  const currentUser = groupMe?.currentUser;
  const emotionUsers = useMemo(
    () => data?.pages.flatMap((page) => page?.data.users) ?? [],
    [data?.pages]
  );
  const emotionCount =
    data?.pages.reduce((count, page) => count + page?.data.emotionCount, 0) ?? meetupEmotionCount;

  if (!currentUser || !emotionCount) return null;

  return (
    <div className={s.EmotionCountMessageWrapper}>
      <EmotionCountMessage
        currentUser={currentUser}
        emotionCount={emotionCount}
        emotionUsers={emotionUsers}
        {...props}
      />
    </div>
  );
};

export default withAsyncBoundary(GroupMeetupDetailEmotionCountMessage, {});
