import { Typography } from '@community-group/components';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';

import * as s from './index.css';

export type SelectBoxProps = {
  label: string;
  description?: string;
  selected?: boolean;
  renderRight?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const SelectBox = ({
  label,
  description,
  selected,
  renderRight,
  children,
  ...props
}: SelectBoxProps) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);

  return (
    <div {...props} className={clsx([props.className, s.wrapper({ selected })])}>
      <div className={s.parentWrapper}>
        <div className={s.leftWrapper}>
          <Typography typography="label2Bold" color="gray900">
            {label}
          </Typography>
          {description && (
            <Typography typography="label3Regular" color="gray700">
              {description}
            </Typography>
          )}
        </div>
        {renderRight && <div className={s.rightWrapper}>{renderRight}</div>}
      </div>

      <AnimatePresence>
        {selected && children && (
          <motion.div
            key={label}
            {...motionConfig}
            onAnimationComplete={(animationDefinition: { opacity: number }) => {
              if (children && animationDefinition.opacity === 1) {
                bottomRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }
            }}
          >
            <div className={s.childrenWrapperInner}>{children}</div>
          </motion.div>
        )}
        <div className={s.scrollIntoViewBlock} ref={bottomRef} />
      </AnimatePresence>
    </div>
  );
};

const motionConfig = {
  initial: {
    opacity: 0,
    height: 0,
    y: '10px',
    overflow: 'hidden',
    willChange: 'transform, opacity, height',
  },
  animate: { opacity: 1, height: 'auto', y: 0, animationTimingFunction: 'ease' },
  transition: {
    duration: 0.3,
    when: 'beforeChildren',
  },
  exit: { opacity: 0, height: 0, y: '10px', animationTimingFunction: 'linear' },
};
