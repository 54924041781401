import { Typography, withAsyncBoundary } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconExpandMoreRegular } from '@seed-design/icon';
import { IconLocationFill, IconProfileFill, IconRetryRegular } from '@seed-design/icon';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';
import React from 'react';

import { useGetExploreOtherGroups } from '@/api/hooks/useGetExploreOtherGroups';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import FormGuideCallout from '@/components/common/FormGuideCallout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import { EditDescriptionItemAccordion } from './EditDescriptionItemAccordion';
import * as s from './PopularGroupDescriptionList.css';

const ACCORDION_INITIAL_STATE = {
  0: false,
  1: false,
  2: false,
};

const PopularGroupDescriptionList = () => {
  const { groupId } = usePathParams();
  const { group: groupDetail } = useGetGroupDetail(groupId);
  const { data } = useGetExploreOtherGroups({
    categoryId: Number(groupDetail?.category?.id),
    regionId: Number(groupDetail?.groupCreatedRegion?.id),
  });
  const groupData = data?.data.groups ?? [];
  const [openStates, setOpenStates] = React.useState<{ [key: number]: boolean }>(
    ACCORDION_INITIAL_STATE
  );

  const [currentPage, setCurrentPage] = React.useState(0);
  const controls = useAnimationControls();

  if (groupData.length === 0) return null;

  const chunkDataArr = chunkArrayWithReduce(groupData, 3);
  const TOTAL_PAGE_NUM = Math.min(5, chunkDataArr.length);

  return (
    <div className={s.Wrapper}>
      <div className={s.TipLabel}>
        <FormGuideCallout calloutType="popular-group-description" />
      </div>
      <div className={s.ListWrapper}>
        {chunkDataArr[currentPage % TOTAL_PAGE_NUM].map((group, index) => {
          return (
            <EditDescriptionItemAccordion
              key={group.id}
              previewSection={
                <div className={s.ListItem}>
                  <img className={s.ItemImage} src={group.profileImage.medium} />
                  <div className={s.ItemInfoWrapper}>
                    <div className={s.ItemTitle}>{group.name}</div>
                    <div className={s.ItemDescription}>
                      <span className={s.Badge}>
                        <IconLocationFill size={12} color={vars.$scale.color.gray500} />
                        <VerticalSpacing size={2} />
                        <Typography
                          typography="caption1Regular"
                          color="gray600"
                          wordBreak="keep-all"
                        >
                          {group.region?.town}
                        </Typography>
                        <div className={s.Space}>⸱</div>
                      </span>
                      <span className={s.Badge}>
                        <IconProfileFill width={12} height={12} color={vars.$scale.color.gray500} />
                        <VerticalSpacing size={2} />
                        <Typography
                          typography="caption1Regular"
                          color="gray600"
                          wordBreak="keep-all"
                        >
                          {group.memberCount}명
                        </Typography>
                        <div className={s.Space}>⸱</div>
                      </span>
                      <span className={s.Badge}>
                        <Typography
                          typography="caption1Regular"
                          color="gray600"
                          wordBreak="keep-all"
                        >
                          {group.category?.name}
                        </Typography>
                      </span>
                    </div>
                  </div>
                  <div
                    className={s.OpenButtonWrapper}
                    style={{
                      transform: openStates[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  >
                    <IconExpandMoreRegular size={20} color={vars.$scale.color.gray900} />
                  </div>
                </div>
              }
              callback={(state) => {
                trackEvent({
                  event: 'click_edit_description_popular_group_item',
                  params: {
                    groupId: group.id,
                    groupName: group.name,
                    groupCategory: group.category?.name,
                    groupRegion: group.region?.town,
                    groupMemberCount: group.memberCount,
                    type: state,
                  },
                });
                setOpenStates((prev) => ({ ...prev, [index]: state === 'open' }));
              }}
            >
              {group.description}
            </EditDescriptionItemAccordion>
          );
        })}
        <div className={s.MoreButtonWrapper}>
          <div
            className={s.MoreButton}
            onClick={async () => {
              setCurrentPage((prev) => prev + 1);
              setOpenStates(ACCORDION_INITIAL_STATE);
              trackEvent({ event: 'click_edit_description_popular_group_refresh' });
              await controls.start({
                transform: `rotate(${(currentPage + 1) * 360}deg)`,
              });
            }}
          >
            <AnimatePresence>
              <motion.div
                initial={{ transform: 'rotate(0deg)' }}
                transition={{
                  duration: 0.3,
                }}
                className={s.RetryIconWrapper}
                animate={controls}
              >
                <IconRetryRegular size={16} color={vars.$scale.color.gray900} />
              </motion.div>
            </AnimatePresence>
            <p>
              모임 소개 더보기 {(currentPage % TOTAL_PAGE_NUM) + 1}/{chunkDataArr.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAsyncBoundary(PopularGroupDescriptionList, {
  pendingFallback: null,
  rejectedFallback: <></>,
});

const chunkArrayWithReduce = (array, size) =>
  array.slice(0, 15).reduce((acc, val, i) => {
    if (i % size === 0) {
      acc.push([val]);
    } else {
      acc[acc.length - 1].push(val);
    }
    return acc;
  }, []);
