import { AsyncBoundary, ViewLoader } from '@community-group/components';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { ActivityComponentType, useActivity } from '@stackflow/react';
import { MouseEvent, useMemo } from 'react';

import { useGetGroupMeetupEmotionList } from '@/api/hooks/useGetGroupMeetupEmotionList';
import EmotionUserList from '@/components/common/EmotionUserList';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

export type MeetupDetailListListPageParams = Pick<PageParams, 'groupId' | 'meetupId'>;

const MeetupDetailLikeListPage: ActivityComponentType<MeetupDetailListListPageParams> = () => {
  const { replace, pop } = useFlow();
  const { isRoot } = useActivity();
  const { groupId = '' } = usePathParams();

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isRoot) {
      return replace('GroupDetailPage', {
        groupId,
      });
    } else {
      pop();
    }
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        backButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        title: '공감',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <MeetupLikeList />
      </AsyncBoundary>
    </AppScreen>
  );
};

const MeetupLikeList = () => {
  const { groupId, meetupId } = usePathParams();
  const groupNumberId = Number(groupId);
  const meetupNumberId = Number(meetupId);

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetGroupMeetupEmotionList({
    groupId: groupNumberId,
    meetupId: meetupNumberId,
    joinedUser: true,
  });
  const joinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
      from: 'like_list',
    });
  };

  return (
    <LayoutWrapper>
      <EmotionUserList
        users={joinedUserList}
        onClickUserProfile={getMoveGroupUserProfileDetailPage}
        shouldFetchNextUsers={Boolean(hasNextPage && !isFetchingNextPage)}
        fetchNextUsers={fetchNextPage}
      />
      {!hasNextPage && (
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
          <MeetupLikeListNotJoinedUser
            groupId={groupNumberId}
            meetupId={meetupNumberId}
            onClickUserProfile={getMoveGroupUserProfileDetailPage}
          />
        </AsyncBoundary>
      )}
    </LayoutWrapper>
  );
};

type Props = {
  groupId: number;
  meetupId: number;
  onClickUserProfile: (userId: number) => () => void;
};

const MeetupLikeListNotJoinedUser = ({ groupId, meetupId, onClickUserProfile }: Props) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetGroupMeetupEmotionList({
    groupId,
    meetupId,
    joinedUser: false,
  });
  const notJoinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  return (
    <EmotionUserList
      users={notJoinedUserList}
      onClickUserProfile={onClickUserProfile}
      shouldFetchNextUsers={Boolean(hasNextPage && !isFetchingNextPage)}
      fetchNextUsers={fetchNextPage}
    />
  );
};

export default MeetupDetailLikeListPage;
