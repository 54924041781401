import {
  ActionList,
  ActionListItem,
  isHigherManager,
  useBottomSheet,
  useSnackbarAdapter,
} from '@community-group/components';
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import { useCallback, useState } from 'react';

import { useDeletePinHeroImage } from '@/api/hooks/useDeletePinHeroImage';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { usePutSetHeroImage } from '@/api/hooks/usePutSetHeroImage';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupPostReport } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import { MediaListType } from './MediaViewerSlider';
import * as s from './SetHeroImageOptions.css';

// Image 타입이 아닌 Media(Image | Video) 타입을 사용하는 options
type Props = {
  groupId: string;
  currentMedia?: MediaListType;
  pinnedPhotoCount: number;
  isPinnable: boolean;
};
const SetHeroMediaOptions = ({
  groupId,
  currentMedia,
  pinnedPhotoCount,
  isPinnable: isPinnacle,
}: Props) => {
  const [openConfirmSetHeroImageDialog, setOpenConfirmSetHeroImageDialog] = useState(false);
  const isPinned = currentMedia?.isPinned ?? false;
  const publishType = currentMedia?.post?.publishType;
  const handleErrorWithToast = useHandleErrorWithToast();
  const snackbarAdapter = useSnackbarAdapter();
  const { data: me } = useGetGroupMe(groupId);

  const { pop } = useFlow();
  const { mutate: putHeroImage } = usePutSetHeroImage({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      trackEvent({
        event: 'click_imageviewer_pin_the_post',
        params: {
          groupId,
        },
      });
      snackbarAdapter.create({
        message: '배경사진으로 설정했어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      pop();
    },
  });
  const { mutate: deleteHeroImage } = useDeletePinHeroImage({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      trackEvent({
        event: 'click_imageviewer_unpin_the_post',
        params: {
          groupId,
        },
      });
      snackbarAdapter.create({
        message: '배경사진이 해제됐어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      pop();
    },
  });

  const { open: openBottomSheet, close: closeBottomSheet } = useBottomSheet();
  const handleSetHeroSliderImage = useCallback(() => {
    closeBottomSheet();

    // 이미 고정되어져 있는 경우 제거
    if (isPinned) {
      deleteHeroImage({
        groupId: Number(groupId),
        imageId: currentMedia?.id ?? '',
      });
      return;
    }

    if (publishType === 'group_only') {
      snackbarAdapter.create({
        message: '모임에만 공개된 글에 포함된 사진은 배경사진으로 설정할 수 없어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      return;
    }

    // 6개 이상 고정되어져 있는 경우 알림 띄우기
    if (pinnedPhotoCount === 6) {
      setOpenConfirmSetHeroImageDialog(true);
      return;
    }

    // 이미지 고정
    putHeroImage({
      groupId: Number(groupId),
      imageId: currentMedia?.id ?? '',
    });
  }, [
    closeBottomSheet,
    currentMedia?.id,
    deleteHeroImage,
    groupId,
    isPinned,
    pinnedPhotoCount,
    publishType,
    putHeroImage,
    snackbarAdapter,
  ]);

  const handelOpenPostReport = useCallback(() => {
    openGroupPostReport({
      groupId,
      postId: currentMedia?.postId?.toString() ?? '',
    });
  }, [currentMedia?.postId, groupId]);

  const handleMoreOption = useCallback(() => {
    const renderText = isPinned ? '사진고정 해제' : '사진고정';
    const isRenderPinOption = isHigherManager(me?.currentUser.role) && isPinnacle;
    const isPostAuthor = me?.currentUser.id === currentMedia?.post?.author?.userId;

    openBottomSheet({
      element: (
        <div className={s.BottomSheetWrapper}>
          <ActionList>
            {isRenderPinOption && (
              <ActionListItem onClick={handleSetHeroSliderImage}>{renderText}</ActionListItem>
            )}

            {!isPostAuthor && <ActionListItem onClick={handelOpenPostReport}>신고</ActionListItem>}
          </ActionList>
        </div>
      ),
    });
  }, [
    currentMedia?.post?.author?.userId,
    handelOpenPostReport,
    handleSetHeroSliderImage,
    isPinnacle,
    isPinned,
    me?.currentUser.id,
    me?.currentUser.role,
    openBottomSheet,
  ]);

  return (
    <>
      <div className={s.MoreButton} onClick={handleMoreOption}>
        <IconMoreVertRegular size={24} color={vars.$static.color.staticWhite} />
      </div>
      <DialogContainer>
        {openConfirmSetHeroImageDialog && (
          <AlertDialog
            title="배경사진은 최대 6장까지 설정할 수 있어요."
            description="이 사진을 배경사진으로 설정하면, 가장 오래된 사진이 배경사진에서 해제돼요."
            primaryActionLabel="설정    "
            onPrimaryAction={() => {
              setOpenConfirmSetHeroImageDialog(false);
              putHeroImage({
                groupId: Number(groupId),
                imageId: currentMedia?.id ?? '',
              });
            }}
            secondaryActionLabel="취소"
            secondaryActionIntent="neutral"
            onSecondaryAction={() => {
              setOpenConfirmSetHeroImageDialog(false);
            }}
          />
        )}
      </DialogContainer>
    </>
  );
};

export default SetHeroMediaOptions;
