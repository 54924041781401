type Props = { size?: number };

function Large2DLevel5({ size = 48 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8594_118732)">
        <path
          d="M-0.00195312 20.7518C-0.00195312 19.7552 0.805954 18.9473 1.80256 18.9473H13.8928V31.5788H10.8251C4.84549 31.5788 -0.00195312 26.7314 -0.00195312 20.7518Z"
          fill="#D9D9D9"
        />
        <path
          d="M-0.00195312 20.7518C-0.00195312 19.7552 0.805954 18.9473 1.80256 18.9473H13.8928V31.5788H10.8251C4.84549 31.5788 -0.00195312 26.7314 -0.00195312 20.7518Z"
          fill="url(#paint0_linear_8594_118732)"
        />
        <path
          d="M47.998 20.7518C47.998 19.7552 47.1901 18.9473 46.1935 18.9473H34.1033V31.5788H37.171C43.1506 31.5788 47.998 26.7314 47.998 20.7518Z"
          fill="#D9D9D9"
        />
        <path
          d="M47.998 20.7518C47.998 19.7552 47.1901 18.9473 46.1935 18.9473H34.1033V31.5788H37.171C43.1506 31.5788 47.998 26.7314 47.998 20.7518Z"
          fill="url(#paint1_linear_8594_118732)"
        />
        <path
          d="M27.0006 5.99989C25.1442 4.9281 22.857 4.9281 21.0006 5.99989L9.91211 12.4018C8.0557 13.4736 6.91211 15.4544 6.91211 17.598V30.4018C6.91211 32.5454 8.0557 34.5262 9.91211 35.598L21.0006 41.9999C22.857 43.0717 25.1442 43.0717 27.0006 41.9999L38.089 35.598C39.9454 34.5262 41.089 32.5454 41.089 30.4018V17.598C41.089 15.4544 39.9454 13.4736 38.089 12.4018L27.0006 5.99989Z"
          fill="url(#paint2_linear_8594_118732)"
          stroke="#FFD2B9"
          strokeWidth="3"
        />
        <mask
          id="mask0_8594_118732"
          {...({ maskType: 'alpha' } as any)}
          maskUnits="userSpaceOnUse"
          x="5"
          y="4"
          width="38"
          height="40"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.518 5.09527L38.6102 11.499C40.783 12.7569 42.1298 15.0821 42.1298 17.5979V30.4054C42.1298 32.9211 40.783 35.2463 38.6102 36.5042L27.518 42.908C25.3453 44.1659 22.6517 44.1659 20.479 42.908L9.38672 36.5042C7.21401 35.2463 5.86719 32.9211 5.86719 30.4054V17.5979C5.86719 15.0821 7.21401 12.7569 9.38672 11.499L20.479 5.09527C22.6517 3.83738 25.3453 3.83738 27.518 5.09527Z"
            fill="#DDC7B8"
          />
        </mask>
        <g mask="url(#mask0_8594_118732)">
          <g filter="url(#filter0_d_8594_118732)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.5194 5.09666L38.6078 11.4986C40.7864 12.7564 42.1284 15.0809 42.1284 17.5964V30.4003C42.1284 32.9158 40.7864 35.2403 38.6078 36.4981L27.5194 42.9C25.3408 44.1578 22.6567 44.1578 20.4782 42.9L9.38973 36.4981C7.21118 35.2403 5.86914 32.9158 5.86914 30.4003V17.5964C5.86914 15.0809 7.21118 12.7564 9.38973 11.4986L20.4782 5.09666C22.6567 3.83887 25.3408 3.83887 27.5194 5.09666ZM26.2488 7.29738C24.8565 6.49353 23.1411 6.49353 21.7488 7.29738L10.6603 13.6993C9.26801 14.5032 8.41032 15.9887 8.41032 17.5964V30.4003C8.41032 32.008 9.26801 33.4935 10.6603 34.2974L21.7488 40.6993C23.1411 41.5032 24.8565 41.5032 26.2488 40.6993L37.3372 34.2974C38.7295 33.4935 39.5872 32.008 39.5872 30.4003V17.5964C39.5872 15.9887 38.7295 14.5032 37.3372 13.6993L26.2488 7.29738Z"
              fill="#FFD2B9"
            />
          </g>
          <g filter="url(#filter1_d_8594_118732)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.5194 5.09666L38.6078 11.4986C40.7864 12.7564 42.1284 15.0809 42.1284 17.5964V30.4003C42.1284 32.9158 40.7864 35.2403 38.6078 36.4981L27.5194 42.9C25.3408 44.1578 22.6567 44.1578 20.4782 42.9L9.38973 36.4981C7.21118 35.2403 5.86914 32.9158 5.86914 30.4003V17.5964C5.86914 15.0809 7.21118 12.7564 9.38973 11.4986L20.4782 5.09666C22.6567 3.83887 25.3408 3.83887 27.5194 5.09666ZM26.2488 7.29738C24.8565 6.49353 23.1411 6.49353 21.7488 7.29738L10.6603 13.6993C9.26801 14.5032 8.41032 15.9887 8.41032 17.5964V30.4003C8.41032 32.008 9.26801 33.4935 10.6603 34.2974L21.7488 40.6993C23.1411 41.5032 24.8565 41.5032 26.2488 40.6993L37.3372 34.2974C38.7295 33.4935 39.5872 32.008 39.5872 30.4003V17.5964C39.5872 15.9887 38.7295 14.5032 37.3372 13.6993L26.2488 7.29738Z"
              fill="#FFD2B9"
            />
          </g>
        </g>
        <g filter="url(#filter2_d_8594_118732)">
          <path
            d="M32.6842 24.66C32.0362 24.024 31.0042 23.856 30.1402 23.88C31.9642 22.032 31.0042 20.196 30.2962 19.488C29.7202 18.912 28.4002 18.192 26.9242 18.912C26.9602 18.696 26.9842 18.468 26.9842 18.24C26.9842 15.612 24.9802 14.988 23.9842 14.988C22.9882 14.988 20.9842 15.612 20.9842 18.24C20.9842 18.468 21.0082 18.696 21.0442 18.912C19.5802 18.192 18.2482 18.912 17.6722 19.488C16.9642 20.196 16.0042 22.032 17.8282 23.88C16.9642 23.856 15.9322 24.024 15.2842 24.66C14.5762 25.344 13.6402 27.36 15.8122 29.736C17.1993 31.2522 20.3113 32.4022 22.4799 32.8235C22.8561 32.8965 23.1922 32.6006 23.1922 32.2174V29.88L18.6682 27.768C18.2722 27.588 18.1042 27.12 18.2962 26.736C18.4762 26.34 18.9442 26.172 19.3282 26.364L23.1922 28.164V25.02L20.3122 23.352C19.9402 23.136 19.8082 22.656 20.0242 22.284C20.2402 21.912 20.7202 21.78 21.0922 21.996L23.1922 23.208V19.788C23.1922 19.356 23.5402 19.008 23.9722 19.008C24.4042 19.008 24.7522 19.356 24.7522 19.788V23.196L26.8402 21.996C27.2122 21.78 27.6922 21.912 27.9082 22.284C28.1242 22.656 27.9922 23.136 27.6202 23.352L24.7522 25.008V28.188L28.6882 26.352C29.0722 26.172 29.5402 26.34 29.7202 26.724C29.9002 27.108 29.7322 27.576 29.3482 27.756L24.7642 29.892V32.2054C24.7642 32.5886 25.1002 32.8845 25.4764 32.8115C27.6451 32.3902 30.757 31.2402 32.1442 29.724C34.3162 27.348 33.3802 25.332 32.6722 24.648L32.6842 24.66Z"
            fill="url(#paint3_linear_8594_118732)"
            shapeRendering="crispEdges"
          />
        </g>
        <circle cx="13.0043" cy="19.1786" r="1.35" fill="#FFFAFA" />
        <circle cx="36.1488" cy="22.2643" r="1.35" fill="#FFFAFA" />
        <circle cx="29.977" cy="14.55" r="1.35" fill="#FFFAFA" fillOpacity="0.8" />
      </g>
      <defs>
        <filter
          id="filter0_d_8594_118732"
          x="3.32796"
          y="0.341556"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118732" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118732"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8594_118732"
          x="3.32796"
          y="2.88273"
          width="41.3411"
          height="44.7723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.27059" />
          <feGaussianBlur stdDeviation="1.27059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118732" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118732"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8594_118732"
          x="13.8276"
          y="14.988"
          width="20.3009"
          height="19.442"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.96" />
          <feGaussianBlur stdDeviation="0.317647" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8594_118732" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8594_118732"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8594_118732"
          x1="-0.00195312"
          y1="25.2631"
          x2="13.8928"
          y2="25.2631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9E66" />
          <stop offset="1" stopColor="#FF6F0F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8594_118732"
          x1="47.998"
          y1="25.2631"
          x2="34.1033"
          y2="25.2631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9E66" />
          <stop offset="1" stopColor="#FF6F0F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8594_118732"
          x1="24.0006"
          y1="6.69604"
          x2="24.0006"
          y2="41.3037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6F0F" />
          <stop offset="1" stopColor="#E45224" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8594_118732"
          x1="23.9782"
          y1="14.988"
          x2="23.9782"
          y2="32.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
        <clipPath id="clip0_8594_118732">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Large2DLevel5;
