import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import clsx from 'clsx';
import { Suspense, useMemo } from 'react';

import Loading from '@/components/common/Loading';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';

import ImageViewerSlider from '../components/ImageViewerSlider';
import * as s from './ImageViewer.css';

export type ImagesImageViewerProps = {
  initialIndex: string;
  imageUrls: string;
};

const ImagesImageViewer = ({ initialIndex, imageUrls }: ImagesImageViewerProps) => {
  const { pop } = useFlow();
  const parsedImageList = JSON.parse(imageUrls) as string[];

  const imageList = useMemo(() => {
    return parsedImageList.map((el, idx) => {
      return {
        id: idx.toString(),
        imageUrl: el,
      };
    });
  }, [parsedImageList]);

  return (
    <AppScreen hasAppBar={false} layoutRef={undefined} onPull={undefined}>
      <div className={clsx(s.Wrapper, 'imageSliderWrapper')}>
        <Suspense
          fallback={
            <div className={s.LoaderWrapper}>
              <Loading size="medium" />
            </div>
          }
        >
          <ImageViewerSlider
            imageData={{
              imageList: imageList,
              initialIndex: Number(initialIndex),
            }}
          />
        </Suspense>
        <div className={s.CloseButton} onClick={pop}>
          <IconCloseRegular size={24} color={vars.$static.color.staticWhite} />
        </div>
      </div>
    </AppScreen>
  );
};

export default ImagesImageViewer;
