import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { formatDate, KARROT_DATE_FORMAT } from '@/utils/date';

import { storageInstance } from './api';
import { storage } from './localStorage';
import { StorageData } from './type';

// TODO: localStorage 접근하는 부분이 Server Side에서 호출될 수 있는 시나리오가 있는지
// 체크가 필요해요.
export const useStorage = <T extends keyof StorageData>(
  key: T,
  initValue: StorageData[T]
): [StorageData[T], (value: StorageData[T]) => void] => {
  const queryClient = useQueryClient();

  const { userConfig } = useUserConfig();
  const getStorageKey = ({ hashId, key }: { hashId: string | null; key: string }) => {
    return `${hashId ?? 'no-hash-id'}-${key}`;
  };

  const { data, isLoading, refetch } = useQuery(
    [getStorageKey({ hashId: userConfig.userHashId, key })],
    async () => {
      const localData = storage<T>(key).get();

      if (localData !== null && localData !== undefined) {
        return {
          value: JSON.stringify(localData),
          metadata: {},
        };
      }

      return {
        value: null,
        metadata: {},
      };
      // TODO: SSR 옮기 때 다시 활성화 할 것.
      // const serverData = await storageInstance.get(getStorageKey(key));
      // return serverData;
    },
    {
      retry: 0,
      suspense: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      // Client에서만 useStorage로 접근 가능하도록 설정.
      enabled: !import.meta.env.SSR,
    }
  );

  const { mutate } = useMutation((data: StorageData[T]) => {
    return new Promise((resolve) => {
      storageInstance.put(getStorageKey({ hashId: userConfig.userHashId, key }), {
        value: JSON.stringify(data),
        metadata: {
          updatedAt: formatDate(new Date(), KARROT_DATE_FORMAT),
        },
      });

      resolve({});
    });
  });

  const dataValue = data?.value ? JSON.parse(data.value) : initValue;

  const handleSetDataValue = (value: StorageData[T]) => {
    const stringifyCurrentValue = JSON.stringify(value);
    const stringifyDataValue = JSON.stringify(dataValue);

    if (stringifyCurrentValue == stringifyDataValue) return;

    queryClient.invalidateQueries([getStorageKey({ hashId: userConfig.userHashId, key })]);

    storage<T>(key).set(value);
    refetch();
    // TODO: SSR 옮기 때 다시 활성화 할 것.
    // mutate(value, {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries([getStorageKey(key)]);
    //   },
    // });
  };

  // LocalStorage 데이터가 유실되었을 경우 서버 데이터로 복구
  useEffect(() => {
    if (isLoading) return;

    const localData = storage<T>(key).get();
    if (localData === null || localData === undefined) {
      storage<T>(key).set(dataValue);
    }
  }, [isLoading]);

  return [dataValue, handleSetDataValue];
};
