import clsx from 'clsx';

import * as s from './style.css';

type Props = {
  warning?: boolean;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  onClick?: () => void;
  renderRight?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

const Item = ({
  warning,
  title,
  description,
  onClick,
  renderRight,
  className,
  disabled,
}: Props) => (
  <li
    onClick={onClick}
    className={clsx(
      'setting-menu-item',
      className,
      s.ItemWrapper({
        warning,
        clickable: !!onClick,
        disabled,
      })
    )}
  >
    <div className={clsx('setting-menu-left-side', s.ItemLeftSide)}>
      <h6 className={s.ItemLeftTitle}>{title}</h6>
      {description && <p className={s.ItemLeftDescription}>{description}</p>}
    </div>
    {renderRight && (
      <div className={clsx('setting-menu-right-side', s.ItemRightSide)}>{renderRight}</div>
    )}
  </li>
);

export default Item;
