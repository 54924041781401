import { AsyncBoundary, Typography, ViewLoader } from '@community-group/components';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { ActivityComponentType, useActivity } from '@stackflow/react';
import { Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { useGetComment } from '@/api/hooks/useGetComment';
import { useGetComments } from '@/api/hooks/useGetComments';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import CommentFormAccessoryBar from '@/components/group/CommentFormAccessoryBar';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import CommentFeed from '../../CommentList/components/CommentFeed';
import * as s from './index.css';

type Params = Pick<
  PageParams,
  | 'groupId'
  | 'relatedId'
  | 'commentId'
  | 'commentCount'
  | 'groupName'
  | 'disabledCommentInput'
  | 'relatedContentType'
>;

const CommentDetailPage: ActivityComponentType<Params> = () => {
  const { isRoot } = useActivity();
  const { replace, pop } = useFlow();
  const { groupId, relatedId, commentId, relatedContentType } = usePathParams();
  const {
    commentCount,
    groupName,
    disabledCommentInput: disabledCommentInputQuery,
  } = useQueryParams();
  const [subCommentCount, setSubCommentCount] = useState(commentCount ? Number(commentCount) : 0);
  const [disabledCommentInput, setDisabledCommentInput] = useState(
    disabledCommentInputQuery === 'true'
  );

  const BottomRef = useRef<HTMLDivElement>(null);

  const handleBackButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (isRoot) {
      if (relatedContentType === 'post') {
        return replace('GroupPostDetailPage', {
          groupId,
          postId: relatedId,
        });
      } else {
        return replace('GroupMeetupDetailPage', {
          groupId,
          meetupId: relatedId,
        });
      }
    } else {
      pop();
    }
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: handleBackButtonClick,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: handleBackButtonClick,
        },
        title: (
          <div className={s.Title}>
            <Typography typography="subtitle1Bold" color="gray900">
              댓글 {subCommentCount + 1}
            </Typography>
            <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
              {groupName}
            </Typography>
          </div>
        ),
      }}
      accessoryBar={
        !disabledCommentInput && (
          <CommentFormAccessoryBar
            listRef={BottomRef}
            groupId={groupId}
            relatedId={relatedId}
            relatedContentType={relatedContentType}
            parentCommentId={commentId}
            placeholder="답글을 입력해주세요."
          />
        )
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <CommentDetailCore
          groupId={groupId}
          relatedId={relatedId}
          parentCommentId={commentId}
          relatedContentType={relatedContentType}
          setSubCommentCount={setSubCommentCount}
          disabledCommentInput={disabledCommentInput}
          setDisabledCommentInput={setDisabledCommentInput}
        />
        <div ref={BottomRef} className={s.Bottom} />
      </AsyncBoundary>
    </AppScreen>
  );
};

type Props = {
  groupId: string;
  relatedId: string;
  parentCommentId: string;
  relatedContentType: 'post' | 'meetup';
  setSubCommentCount: Dispatch<SetStateAction<number>>;
  disabledCommentInput: boolean;
  setDisabledCommentInput: Dispatch<SetStateAction<boolean>>;
};

const CommentDetailCore = ({
  groupId,
  relatedId,
  parentCommentId,
  relatedContentType,
  setSubCommentCount,
  disabledCommentInput,
  setDisabledCommentInput,
}: Props) => {
  const { data: comment } = useGetComment({
    groupId,
    relatedId: relatedId,
    commentId: parentCommentId,
    relatedContentType,
  });
  const {
    data: subComments,
    hasNextPage,
    fetchNextPage,
  } = useGetComments({
    groupId,
    relatedId: relatedId,
    commentId: parentCommentId,
    relatedContentType,
  });

  useEffect(() => {
    setSubCommentCount(comment?.data.comment.subCommentCount ?? 0);
  }, [setSubCommentCount, comment?.data.comment.subCommentCount]);

  useEffect(() => {
    const isDeletedComment = Boolean(comment?.data.comment.deletedAt);
    const isDeletedAccount = Boolean(comment?.data.comment.author.isAccountDeleted);
    setDisabledCommentInput((prev) => prev || isDeletedComment || isDeletedAccount);
  }, [setDisabledCommentInput, comment?.data.comment]);

  const feedData = useMemo(() => {
    if (!comment) return [];

    const parentComment = {
      ...comment.data.comment,
      subComments: subComments?.pages.flatMap((page) => page.data.comments) ?? [],
    };
    return [parentComment];
  }, [comment, subComments?.pages]);

  return (
    <CommentFeed
      groupId={groupId}
      relatedId={relatedId}
      commentId={parentCommentId}
      relatedContentType={relatedContentType}
      comments={feedData}
      disabledCommentInput={disabledCommentInput}
    >
      {hasNextPage && <LoadMoreListContainer callback={fetchNextPage} />}
    </CommentFeed>
  );
};

export default CommentDetailPage;
