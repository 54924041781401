import { DialogContainer } from '@daangn/sprout-components-dialog';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useModalState } from '../../hooks/useModalState';

interface DialogContextProps {
  isOpen: boolean;
  open: (options: DialogOptions) => void;
  close: () => Promise<void>;
}
interface DialogOptions {
  isOpen?: boolean;
  element: ReactNode;
  onOutsideClick?: () => void;
  onClose?: () => void;
}

const DialogContext = createContext<DialogContextProps>({
  isOpen: true,
  open: (options: DialogOptions) => undefined,
  close: async () => undefined,
});

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | undefined>();
  const isOpenRef = useRef(isOpen);

  const { isModalOpen, setModalClose } = useModalState({
    isModalOpen: isOpen,
    setModalClose: () => setIsOpen(false),
  });

  const open = useCallback((options: DialogOptions) => {
    if (isOpenRef.current) {
      setModalClose();
    }
    setDialogOptions({ ...options });
    setIsOpen(true);
  }, []);

  const close = useCallback(
    () =>
      new Promise<void>((resolve) => {
        if (isOpenRef.current) {
          setModalClose();
        }
        setTimeout(() => resolve(undefined), ANIMATION_DURATION);
      }),
    []
  );

  const controls = useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);

  useEffect(() => {
    isOpenRef.current = isModalOpen;
  }, [isModalOpen]);

  return (
    <DialogContext.Provider value={controls}>
      {children}
      <DialogContainer onOutsideClick={dialogOptions?.onOutsideClick}>
        {isOpen && dialogOptions?.element}
      </DialogContainer>
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextProps => {
  const controls = useContext(DialogContext);

  if (controls === undefined) {
    throw new Error('DialogContext 안에서 사용해주세요.');
  }

  return controls;
};

const ANIMATION_DURATION = 100;
