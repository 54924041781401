import clsx from 'clsx';

import * as s from './style.css';

type Props = {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  paddingX?: number;
  paddingY?: number;
  className?: string;
};

const Wrapper = ({ title, children, paddingX = 0, paddingY = 8, className }: Props) => (
  <section
    className={clsx([s.Wrapper, className])}
    style={{
      padding: `${paddingY}px ${paddingX}px`,
    }}
  >
    {title && <h4 className={s.Title}>{title}</h4>}
    <ul>{children}</ul>
  </section>
);

export default Wrapper;
