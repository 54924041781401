import { Category } from '@community-group/api/lib/group/models';
import { BoxButton, useBottomSheet } from '@community-group/components';
import { useMemo, useState } from 'react';

import * as s from './groupSelectCategoryBottomSheet.css';

interface Props {
  categoryList: Category[];
  selectedCategoryId: number;
  setSelectedCategoryId: (selectedCategoryId: number) => void;
}

export const GroupSelectCategoryBottomSheet = ({
  categoryList,
  selectedCategoryId,
  setSelectedCategoryId,
}: Props) => {
  const [currentSelectCategoryId, setCurrentSelectCategoryId] =
    useState<number>(selectedCategoryId);
  const { closeAsync } = useBottomSheet();

  const handleSubmit = () => {
    setSelectedCategoryId(currentSelectCategoryId);
    closeAsync();
  };

  const isButtonDisabled = useMemo(() => {
    if (currentSelectCategoryId === selectedCategoryId) return true;

    return false;
  }, [currentSelectCategoryId, selectedCategoryId]);

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>카테고리</h1>
      <div className={s.chipWrapper}>
        {categoryList.map((category) => (
          <div
            className={s.chip({ checked: category.id === currentSelectCategoryId })}
            key={category.id}
            onClick={() => {
              if (category.id === currentSelectCategoryId) return;

              setCurrentSelectCategoryId(category.id);
            }}
          >
            {category.name}
          </div>
        ))}
      </div>
      <div className={s.buttonWrapper}>
        <BoxButton width="100%" isDisabled={isButtonDisabled} size="xlarge" onClick={handleSubmit}>
          수정
        </BoxButton>
      </div>
    </div>
  );
};
