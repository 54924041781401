import { isNotMember } from '@community-group/components';
import { useState } from 'react';

import { usePostVoteEndPoll } from '@/api/hooks/usePostVoteEnd';
import { usePostVotePoll } from '@/api/hooks/usePostVotePoll';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';
import { refetchHomeMyGroupTab } from '@/utils/refetch/home';

import Poll from '..';
import { PollMode } from '../type/poll';
import { PollCardProps } from './type';

type Props = PollCardProps & {
  setCurrentPollMode: (mode: PollMode) => void;
  onSelectedOptionIdsChange?: (onChanged: () => void) => void;
};

export const PollCardItem = ({
  groupPostInfo,
  poll,
  myPollVoting,
  currentUserInfo,
  onVotedMembersClick,
  setCurrentPollMode,
  onSelectedOptionIdsChange,
}: Props) => {
  const { groupId, postId, groupName } = groupPostInfo;
  const isNotGroupMember = isNotMember(currentUserInfo?.role);

  const [selectedOptionIds, setSelectedOptionIds] = useState(myPollVoting?.optionIds);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { isLoading: isEndPullLoading } = usePostVoteEndPoll({});
  const { mutate, isLoading } = usePostVotePoll({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchPostDetail({ groupId, postId });
      refetchGroupDetail({ groupId });
      refetchHomeMyGroupTab();

      trackEvent({
        event: 'click_vote_poll',
        params: {
          groupId,
          groupName,
          pollId: poll.id,
          pollName: poll.title,
        },
        sample: true,
      });
    },
  });

  return (
    <Poll
      isLoading={isLoading || isEndPullLoading}
      poll={poll}
      hideVoteButton={isNotGroupMember}
      onModeChange={setCurrentPollMode}
      selectedOptionIds={selectedOptionIds}
      onSelectedOptionIdsChange={(optionIds) => {
        onSelectedOptionIdsChange?.(() => {
          setSelectedOptionIds(optionIds);
        });
      }}
      onVote={async (optionIds) => {
        mutate({
          groupId,
          postId,
          pollId: poll.id,
          votePollForm: {
            optionIds,
          },
        });
      }}
      onVotedMembersClick={() => {
        onVotedMembersClick?.(poll.id);
      }}
    />
  );
};
