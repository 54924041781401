import { getGroupMeetupDetailPath } from '@/api/hooks/useGetGroupMeetupDetail';
import { queryClient } from '@/api/instance';
type Params = {
  groupId: string;
  meetupId: string;
};

/*
 *  일정 상세 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupMeetupDetail = ({ groupId, meetupId }: Params) => {
  queryClient.refetchQueries([getGroupMeetupDetailPath(groupId, meetupId)]);
};
