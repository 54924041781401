import {
  GroupFeedMeetupInfoPresentation,
  GroupMeetupExposureRangeEnum,
  GroupMeetupProgressStatusPresentation,
  GroupMeetupSummaryPresentation,
} from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { groupBy, toPairs } from 'lodash-es';
import { PropsWithChildren, useCallback, useMemo } from 'react';

import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { getMeetupStatusColor } from '@/components/group/Meetup/Detail/utils/progressStatus';
import { useFlow } from '@/stackflow';

import GroupDetailFeedMeetupItem from '../GroupDetailFeedMeetupItem';
import * as s from './index.css';

type GroupMeetups = GroupFeedMeetupInfoPresentation[] | GroupMeetupSummaryPresentation[];

type Props = PropsWithChildren<{
  title?: string;
  groupId: string;
  meetups: GroupMeetups;
  shouldSetSubNickname: boolean;
  shouldReverse?: boolean;
  renderEmptyView?: () => JSX.Element;
}>;

const GroupDetailFeedMeetupList = ({ renderEmptyView, meetups, children, ...props }: Props) => {
  if (meetups.length === 0) return renderEmptyView ? renderEmptyView() : null;

  return (
    <GroupDetailFeedMeetupListCore {...props} meetups={meetups}>
      {children}
    </GroupDetailFeedMeetupListCore>
  );
};

const GroupDetailFeedMeetupListCore = ({
  title,
  groupId,
  meetups,
  shouldSetSubNickname,
  shouldReverse,
  children,
}: Props) => {
  const { handleGroupOnly, isGroupMember } = useHandleGroupOnly({ groupId });

  const getMeetupLabel = useCallback(
    (
      meetupState: GroupMeetupProgressStatusPresentation,
      exposureRange: GroupMeetupExposureRangeEnum
    ) => {
      const showGroupOnlyLabel = isGroupMember && exposureRange !== 'ALL';
      const exposureRangeLabel = showGroupOnlyLabel ? ' ∙ 모임에만 공개' : undefined;

      return (
        <>
          <Typography typography="caption1Regular" color={getMeetupStatusColor(meetupState.status)}>
            {meetupState.description}
          </Typography>
          {exposureRangeLabel}
        </>
      );
    },
    [isGroupMember]
  );

  const { push } = useFlow();

  const handleMeetupListItemClick = (meetup: GroupFeedMeetupInfoPresentation) => () => {
    const blockedGroupOnly = !isGroupMember && meetup.exposureRange !== 'ALL';

    return handleGroupOnly({
      isBlock: blockedGroupOnly,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.enterGroupMeetup,
      onSettled: () => {
        push('GroupMeetupDetailPage', {
          meetupId: meetup.id.toString(),
          groupId,
          from: 'groupDetailPage',
        });
      },
      onSuccess() {
        if (!shouldSetSubNickname) return;
        push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
          groupId,
        });
      },
    });
  };

  const meetupPairs = useMemo(() => {
    const yearGroup = groupBy(meetups, (meetup: GroupFeedMeetupInfoPresentation) => {
      const meetDate = new Date(meetup.meetDate);
      return meetDate.getFullYear();
    });
    const pairs = toPairs(yearGroup) as [string, GroupMeetups][];

    return shouldReverse ? pairs.reverse() : pairs;
  }, [meetups, shouldReverse]);
  const hasDifferentYearMeetups = useMemo(() => meetupPairs.length > 1, [meetupPairs]);

  return (
    <section className={s.Section}>
      {title && (
        <div className={s.Header}>
          <Typography typography="title3Bold">{title}</Typography>
        </div>
      )}
      {hasDifferentYearMeetups ? (
        meetupPairs.map(([year, meetupsByYear]) => (
          <div key={`${title}-${year}`}>
            <div className={s.YearHeader}>
              <Typography typography="subtitle1Bold" color="gray900">
                {year}년
              </Typography>
            </div>
            <ul className={s.List}>
              {meetupsByYear.map((meetup) => (
                <GroupDetailFeedMeetupItem
                  key={meetup.id}
                  title={meetup.title}
                  label={getMeetupLabel(meetup.progressStatus, meetup.exposureRange)}
                  meetDate={meetup.meetDate}
                  currentParticipantsNumber={meetup.currentParticipantsNumber}
                  maximumParticipantsNumber={meetup.maximumParticipantsNumber}
                  image={meetup.imageUrl}
                  meetTime={meetup?.meetTime}
                  showMonth
                  blockedGroupOnly={!isGroupMember && meetup.exposureRange !== 'ALL'}
                  onClick={handleMeetupListItemClick(meetup)}
                />
              ))}
            </ul>
          </div>
        ))
      ) : (
        <ul className={s.List}>
          {meetups.map((meetup) => (
            <GroupDetailFeedMeetupItem
              key={meetup.id}
              title={meetup.title}
              label={getMeetupLabel(meetup.progressStatus, meetup.exposureRange)}
              meetDate={meetup.meetDate}
              currentParticipantsNumber={meetup.currentParticipantsNumber}
              maximumParticipantsNumber={meetup.maximumParticipantsNumber}
              image={meetup.imageUrl}
              meetTime={meetup?.meetTime}
              showMonth
              blockedGroupOnly={!isGroupMember && meetup.exposureRange !== 'ALL'}
              onClick={handleMeetupListItemClick(meetup)}
            />
          ))}
        </ul>
      )}
      {meetups.length > 3 && children && <div className={s.ButtonWrapper}>{children}</div>}
    </section>
  );
};

export default GroupDetailFeedMeetupList;
