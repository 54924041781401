import { useActivityParams } from '@stackflow/react';

import { useUserConfig } from '@/contexts/UserConfig';

/*
 *  Refer 위해 사용하는 QueryParams를 정의합니다.
 */
export interface ReferQueryParams {
  refer_root?: string;
  refer_section?: string;
  refer_content?: string;
  refer_content_index?: string;
  refer_action?: string;
}

interface extendsReferQueryParams {
  checkinReigionId?: number;
  checkinMostAccurate?: string;
}

export const useReferQueryParams = (): extendsReferQueryParams => {
  const { userConfig } = useUserConfig();
  const userRegionData = {
    id: userConfig.regionId ?? 0,
    name: userConfig.regionCityName ?? '',
  };

  const queryParams = useActivityParams<ReferQueryParams>();

  return {
    ...queryParams,
    checkinReigionId: userRegionData.id,
    checkinMostAccurate: userRegionData.name,
  };
};
