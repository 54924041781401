import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<void>,
  Error,
  {
    groupId: string;
    postId: string;
    pollId: string;
    votePollForm?: model.VotePollForm;
  }
>;

export const usePostVotePoll = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postVote = groupClient.api.PollApi.apiV1GroupsGroupIdPostsPostIdPollsPollIdVotesPost({
    axios: fetchInstance,
  });

  const postVoteWrapperFp = ({
    groupId,
    postId,
    pollId,
    votePollForm,
  }: {
    groupId: string;
    postId: string;
    pollId: string;
    votePollForm: model.VotePollForm;
  }) => {
    return postVote(Number(groupId), Number(postId), pollId, votePollForm);
  };

  return useThrottleMutation(postVoteWrapperFp, {
    onError,
    onSuccess,
  });
};
