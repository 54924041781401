import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

interface Props {
  regionId: number;
  meetupStatus?: ('opened' | 'fullyRecruited' | 'closed' | 'deleted' | 'canceled')[];
}

export const useGetGroupMeetups = ({ regionId, meetupStatus = ['opened'] }: Props) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetups = groupClient.api.MeetupApi.apiV1GroupMeetupsGet({ axios: fetchInstance });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [`${GROUP_URL}/group-meetups`, regionId],
    ({ pageParam = null }) =>
      getGroupMeetups(pageParam ? pageParam : undefined, 20, regionId, meetupStatus),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
