import { AsyncBoundary, Checkbox, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { usePutGroup } from '@/api/hooks/usePutGroup';
import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

export type GroupSettingApplicationQuestionBottomSheetParams = Pick<
  PageParams,
  'groupId' | 'initialQuestionRequired' | 'initialQuestionValue'
>;

const GroupSettingApplicationQuestionBottomSheet: ActivityComponentType<
  GroupSettingApplicationQuestionBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingApplicationQuestionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingApplicationQuestionBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { initialQuestionRequired, initialQuestionValue } = useQueryParams();

  const { pop } = useFlow();

  const { watch, register, control } = useForm<FieldValues>({
    defaultValues: {
      question: initialQuestionValue,
      required: initialQuestionRequired === 'true',
    },
  });

  const questionFieldValue = watch('question');
  const requiredFieldValue = watch('required');
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });

      bridge.openToast({
        toast: {
          body: '수정되었어요.',
        },
      });
    },
  });

  const isButtonDisabled = useMemo(() => {
    if (questionFieldValue.trim().length === 0) return true;

    if (
      initialQuestionRequired === requiredFieldValue.toString() &&
      initialQuestionValue === questionFieldValue
    )
      return true;

    return false;
  }, [questionFieldValue, requiredFieldValue]);

  const handleSubmit = () => {
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          applicationConfig: {
            haveQuestion: true,
            questions: [
              {
                question: questionFieldValue,
                required: requiredFieldValue,
              },
            ],
          },
        },
      },
      {
        onSuccess: () => {
          pop();
        },
      }
    );
  };

  return (
    <>
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900">
        가입 질문
      </Typography>
      <Spacing size={16} />
      <FormInput
        register={register}
        name="question"
        value={questionFieldValue}
        placeholder="ex) 모임에서 어떤 활동을 하고 싶나요?"
      />
      <Controller
        control={control}
        name="required"
        render={({ field: { onChange, name, value } }) => (
          <Checkbox size="large" marginTop={12} onChange={onChange} name={name} isSelected={value}>
            필수로 답변 받기
          </Checkbox>
        )}
      />
      <Spacing size={8} />
      <FixedButton disabled={isButtonDisabled} onClick={handleSubmit} isLoading={isLoading}>
        저장
      </FixedButton>
    </>
  );
};

export default GroupSettingApplicationQuestionBottomSheet;
