import { BoxButton, Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { format } from 'date-fns';

import ListItem from '@/components/common/LiItem';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openLink } from '@/utils/link';

import * as s from './GroupLeaderBoardGuideBottomSheet.css';

export type GroupLeaderBoardGuideBottomSheetParams = Pick<PageParams, 'baseDate'>;

const GroupLeaderBoardGuideBottomSheet: ActivityComponentType<
  GroupLeaderBoardGuideBottomSheetParams
> = () => {
  const { baseDate = '' } = useQueryParams();
  const { pop } = useFlow();

  const baseDateString = baseDate ? format(new Date(baseDate), 'MM월 dd일') : '';

  const handleMoveToFAQ = () => {
    openLink('https://www.daangn.com/wv/faqs/16312');
  };

  const GuideContentData = (baseDateString: string) => [
    {
      title: `${baseDateString} 기준, Lv.4 이상인 우리 동네 모임의 주간 랭킹이에요.`,
    },
    {
      title: '랭킹은 매주 월요일 00시 00분부터 일요일 23시 59분까지의 활동점수로 집계돼요.',
    },
    {
      component: (
        <li className={s.LiStyle}>
          <Typography typography="caption1Regular" color="gray700" className={s.InnerTextWrapper}>
            •
          </Typography>
          <VerticalSpacing size={6} />
          <Typography typography="caption1Regular" color="gray700" className={s.InnerTextWrapper}>
            랭킹에 활용되는 주간 활동점수는 매주 리셋되고, 글쓰기, 일정 만들기 등을 통해서 모을 수
            있어요.{' '}
            <Typography
              onClick={handleMoveToFAQ}
              typography="caption1Regular"
              color="gray700"
              className={s.InnerUnderlineTextWrapper}
            >
              더 알아보기
            </Typography>
          </Typography>
        </li>
      ),
    },
    {
      title: '랭킹에는 내가 가입할 수 있는 동네의 모임만 노출돼요.',
    },
    {
      title: '아래의 경우, 랭킹 집계에서 제외될 수 있어요.',
      content: [
        {
          title: '무의미하거나 반복적인 글, 댓글을 작성한 경우',
        },
        {
          title: '운영 정책을 위반한 글, 댓글을 작성한 모임인 경우',
        },
      ],
    },
    {
      title: '랭킹 기준 등은 당근의 내부 판단에 따라 변경될 수 있어요.',
    },
  ];

  return (
    <BottomSheet
      style={{
        padding: 0,
      }}
    >
      <div className={s.Wrapper}>
        <Typography typography="title2Bold" className={s.HeaderWrapper}>
          랭킹 안내
        </Typography>
        <Spacing size={20} />
        <div className={s.ListWrapper}>
          <ListItem items={GuideContentData(baseDateString)} />
        </div>
        <Spacing size={14} />
        <div className={s.CloseButtonWrapper}>
          <BoxButton width="100%" variant="secondary" onClick={() => pop()} size="xlarge">
            <Typography typography="label1Bold" color="gray900">
              닫기
            </Typography>
          </BoxButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default GroupLeaderBoardGuideBottomSheet;
