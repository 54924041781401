import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export type ViewRange = 'my' | 'adjacent' | 'range2' | 'range3';

export const MY_GROUP_POST_REQUEST_LIMIT = 20;

export const getMyGroupPostListPath = () => `${GROUP_URL}/my-groups/posts`;

export const useGetMyGroupPostList = () => {
  const fetchInstance = useFetchInstance();
  const getMyGroupPostList = groupClient.api.MyGroupsApi.apiV1GroupsMyGroupsPostsGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getMyGroupPostListPath()],
    ({ pageParam = null }) =>
      getMyGroupPostList(pageParam ? pageParam : undefined, MY_GROUP_POST_REQUEST_LIMIT),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
