import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import clsx from 'clsx';
import { Suspense, useMemo } from 'react';

import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import Loading from '@/components/common/Loading';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';

import ImageViewerSlider from '../components/ImageViewerSlider';
import * as s from './ImageViewer.css';
export type PostDetailImageViewerProps = {
  groupId: string;
  postId: string;
  initialIndex: number;
};

// 게시글 상세 이미지 뷰어 : 상단 고정 버튼
const PostDetailImageViewer = ({ groupId, postId, initialIndex }: PostDetailImageViewerProps) => {
  const { pop } = useFlow();

  const { post } = useGetPostDetail(groupId, postId);

  const imageList = useMemo(() => {
    return post.images.map((image) => {
      return {
        id: image.id,
        imageUrl: image.large,
        postId: post.id,
      };
    });
  }, [post.images, post.id]);

  return (
    <AppScreen hasAppBar={false} layoutRef={undefined} onPull={undefined}>
      <div className={clsx(s.Wrapper, 'imageSliderWrapper')}>
        <Suspense
          fallback={
            <div className={s.LoaderWrapper}>
              <Loading size="medium" />
            </div>
          }
        >
          <ImageViewerSlider
            zoomable
            imageData={{
              imageList: imageList,
              initialIndex,
            }}
            renderBottom={() => <div className={s.TopGradation} />}
          />
        </Suspense>
        <div className={s.CloseButton} onClick={pop}>
          <IconCloseRegular size={24} color={vars.$static.color.staticWhite} />
        </div>
      </div>
    </AppScreen>
  );
};

export default PostDetailImageViewer;
