import { FieldError, FieldErrors } from 'react-hook-form';
import { ZodError, ZodType } from 'zod';

import { bridge } from '@/contexts/Bridge';

export const validateSchemaWithMessage = (
  schema: ZodType,
  value: Record<string, unknown> | string
): { success: boolean; message?: string } => {
  const validatedResult = schema.safeParse(value);

  if (validatedResult.success) return { success: true };

  if (validatedResult.error) {
    const zodError = validatedResult.error as ZodError;

    const firstError = zodError.issues[0];

    if (firstError) {
      return { success: false, message: firstError.message };
    }
  }

  return { success: false, message: '' };
};

export const validateSchemaWithHandleToast = (
  schema: ZodType,
  value: Record<string, unknown> | string
): boolean => {
  const validatedResult = validateSchemaWithMessage(schema, value);

  if (validatedResult.success) return true;

  if (validatedResult.message && validatedResult.message.length > 0) {
    bridge.openToast({
      toast: {
        body: validatedResult.message,
      },
    });
  }

  return false;
};

export const validateSchemaWithBoolean = (
  schema: ZodType,
  value: Record<string, unknown> | string
): boolean => {
  const validatedResult = validateSchemaWithMessage(schema, value);
  return validatedResult.success;
};

type HookFormError<T> = {
  key: keyof T;
  value: FieldError;
};
export const convertHookFormErrorObjectToArray = <T extends object>(
  errors: FieldErrors<T>
): HookFormError<T>[] =>
  Object.entries(errors).map(([key, value]) => ({
    key,
    value,
  })) as HookFormError<T>[];
