import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { isAxiosError } from 'axios';

import { UNSAFE_appInfo } from './bridge/appInfo';

function createSentry() {
  function init() {
    console.log('[Sentry] init');

    const { appEnv, sentry } = globalThis.appConfig;

    if (!sentry) return;

    Sentry.init({
      environment: appEnv,
      dsn: sentry.dsn,
      tracesSampleRate: 0,
      normalizeDepth: 6,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0, // TODO: 임시대응 (참고: https://daangn.slack.com/archives/CNEDLBHU0/p1710209130534169) (기존:  0.05)
      // integrations: [new Sentry.Replay({ maskAllText: false })],
      ignoreErrors: ['Non-Error exception captured'],
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      beforeSend: (event, hint) => {
        const error = hint.originalException;

        if (isAxiosError(error)) {
          if (error.response?.status === 404) return null;
          if (error.response?.status === 403) return null;
          if (error.response?.status === 400) return null;
        }

        return event;
      },
    });
  }

  function error(e: Error | unknown, transactionName?: string) {
    Sentry.captureException(e, (scope) => {
      transactionName && scope.setTransactionName(transactionName);
      return scope;
    });
  }

  // tag와 함께 context를 추가할 수 있음 ex) api request detail, api response detail
  function setContext(name: string, info: Record<string, any>) {
    Sentry.setContext(name, info);
  }

  // sentry dashboard에서 tag로 검색할 수 있음 ex) api
  function setTag(name: string, tag: string) {
    Sentry.setTag(name, tag);
  }

  function setUser(info: Record<string, any>) {
    Sentry.setUser(info);
  }

  function addBreadcrumb(info: Record<string, any>) {
    Sentry.addBreadcrumb(info);
  }

  function setFingerprint(key: string[]) {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setFingerprint(key);
      return scope;
    });
  }

  function setLevel(key: SeverityLevel) {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setLevel(key);
      return scope;
    });
  }

  function setUserInfo() {
    const userInfo = UNSAFE_appInfo.getData().user;
    const regionInfo = UNSAFE_appInfo.getData().region;

    sentry.setTag('accessToken', userInfo.authToken);
    sentry.setUser({
      id: userInfo.id,
      username: userInfo.nickname,
      region: regionInfo.name,
      profileImage: userInfo.profileImage,
    });
    const sessionUUID = globalThis?.appConfig?.sessionUUID ?? '';
    sentry.setTag('sessionUUID', sessionUUID);
  }

  return {
    init,
    error,
    setContext,
    setTag,
    setUser,
    addBreadcrumb,
    setUserInfo,
    setFingerprint,
    setLevel,
  };
}

const sentry = createSentry();

export default sentry;

// https://github.com/daangn/community/blob/65bd220f8c7035254983188c048fe351509862e9/library/lib-context/src/main/kotlin/com/daangn/SystemExceptions.kt#L42
// https://github.com/daangn/community/blob/65bd220f8c7035254983188c048fe351509862e9/domain/group-domain/src/main/kotlin/com/daangn/group/GroupExceptions.kt#L11
// /packages/api/services/client/axios/handler/sentryErrorReport.ts 에서도 사용중이에요.

export const sentryErrorLevelHandler = (responseCode: string): SeverityLevel => {
  // 예측할 수 있는 에러는 log 처리
  if (responseCode.includes('400')) return 'log';
  if (responseCode.includes('404')) return 'log';

  if (responseCode === '401.not_authenticated') return 'error'; // 로그아웃되었어요. 다시 로그인해주세요.
  if (responseCode === '401.invalid_authentication_token') return 'error'; // An error occurred during authentication

  if (responseCode === '500.application_error') return 'log'; //차단된 모임이에요
  if (responseCode === '500.can_not_use_talk') return 'log'; // 대화 기능을 잠시 사용할 수 없어요.

  return 'error';
};
