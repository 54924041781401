import { ContentType, ShareTargetListPresentation } from '@community-group/api/lib/group/models';

import { useGetShareTargetList } from '@/api/hooks/useGetShareTargetList';

import TargetItem from './TargetItem';
import * as s from './TargetList.css';

type Props = {
  setSelectedTargetList: (selectedList: ShareTargetListPresentation[]) => void;
  selectedTargetList: ShareTargetListPresentation[];
  contentType: ContentType;
  contentId: string;
};

const TargetList = ({
  contentType,
  contentId,
  selectedTargetList,
  setSelectedTargetList,
}: Props) => {
  const { data } = useGetShareTargetList({
    contentId,
    contentType,
  });
  const targetList = (data?.targets as ShareTargetListPresentation[]) || [];

  return (
    <div className={s.TargetListWrapper}>
      {targetList.map((target, index) => {
        const isSelected = selectedTargetList.some((selected) => selected.id === target.id);
        const handleSelectTarget = () => {
          if (isSelected) {
            setSelectedTargetList(
              selectedTargetList.filter((selected) => selected.id !== target.id)
            );
          } else {
            setSelectedTargetList([...selectedTargetList, target]);
          }
        };
        return (
          <TargetItem
            key={index}
            isSelected={isSelected}
            profileImages={target.thumbnailImages}
            name={target.name}
            subText={target.subText}
            handleSelectTarget={handleSelectTarget}
          />
        );
      })}
    </div>
  );
};

export default TargetList;
