import { BoxButton, Spacing, Spinner, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import React from 'react';

import PollLayout from './PollLayout';
import PollOptionRow from './PollOptionRow';
import * as s from './PollStyle.css';
import { PollProps } from './type/poll';

type Props = Pick<
  PollProps,
  | 'selectedOptionIds'
  | 'isLoading'
  | 'poll'
  | 'onModeChange'
  | 'onSelectedOptionIdsChange'
  | 'onVote'
  | 'onItemClick'
  | 'onVoteButtonClick'
  | 'hideVoteButton'
  | 'onVotedMembersClick'
  | 'onVotedMembersClick'
>;
const Poll = ({
  selectedOptionIds = [],
  isLoading = false,
  poll,
  onModeChange,
  onSelectedOptionIdsChange,
  onVote,
  onItemClick,
  hideVoteButton,
  onVoteButtonClick,
  onVotedMembersClick,
}: Props) => {
  const hideVoteMembers = poll.totalVoteCount < 1;

  const handleVotedMembersClick = () => {
    if (hideVoteMembers) return;
    onVotedMembersClick?.();
  };

  return (
    <PollLayout
      headRight={
        <div className={s.pollInfo} onClick={handleVotedMembersClick}>
          <div>{poll.totalVoterCount}명 참여</div>
          <VerticalSpacing size={2} />
          {!hideVoteMembers && (
            <IconChevronRightRegular size={15} color={vars.$scale.color.gray600} />
          )}
        </div>
      }
    >
      <form>
        <h1 className={s.pollTitle}>{poll.title}</h1>
        {poll.isMultiple && (
          <>
            <Spacing size={6} />
            <Typography typography="caption2Regular" color="gray600">
              복수선택
            </Typography>
          </>
        )}
        <Spacing size={12} />
        <div className={s.pollItemRows}>
          {poll.options.map((pollOption, index) => (
            <li key={index}>
              <PollOptionRow
                optionId={pollOption.id}
                title={pollOption.title}
                isSelected={selectedOptionIds?.includes(pollOption.id) ?? false}
                onSelect={(optionId) => {
                  onItemClick?.();
                  if (isLoading) {
                    return;
                  }
                  let nextSelectedOptionIds: string[] = [];

                  if (poll.isMultiple) {
                    nextSelectedOptionIds = selectedOptionIds?.includes(optionId)
                      ? selectedOptionIds.filter((id) => id !== optionId)
                      : [...(selectedOptionIds ?? []), optionId];
                  } else if (selectedOptionIds?.includes(optionId)) {
                    nextSelectedOptionIds = [];
                  } else {
                    nextSelectedOptionIds = [optionId];
                  }

                  onSelectedOptionIdsChange(nextSelectedOptionIds);
                }}
              />
              {index !== poll.options.length - 1 && <Spacing size={8} />}
            </li>
          ))}
        </div>
        {!hideVoteButton && (
          <div className={s.pollActionWrapper}>
            <BoxButton
              size="large"
              width="100%"
              variant="primaryLow"
              isDisabled={isLoading}
              onClick={(event) => {
                event.preventDefault();

                onVoteButtonClick?.();
                onModeChange('result');
                onVote(selectedOptionIds);

                return;
              }}
            >
              {isLoading ? <Spinner size="small" variant="gray" /> : '투표하기'}
            </BoxButton>
          </div>
        )}
      </form>
    </PollLayout>
  );
};

export default React.memo(Poll);
