import {
  getDateDiffFromNow,
  HorizontalSpacing,
  isSuperHost,
  Spacing,
  Typography,
  withAsyncBoundary,
} from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconViewCountRegular } from '@seed-design/icon';
import { IconConfirmationProfileFill } from '@seed-design/icon';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useGetParentingGroupStorage } from '@/hooks/useGetParentingGroupStorage';
import { useSetParentingGroupStorage } from '@/hooks/useSetParentingGroupStorage';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { numberToLocaleString } from '@/utils/number';

import ParentingGroupKidProfile from './ParentingGroupKidProfile';
import * as s from './UserActivity.css';

const UserActivity = () => {
  const { groupId, userId } = usePathParams();
  const { data: profile } = useGetGroupMainProfile({
    groupId: Number(groupId),
    userId: Number(userId),
  });
  const { group } = useGetGroupDetail(groupId);
  const currentMe = useCurrentGroupMe();

  useSetParentingGroupStorage();
  const { isKidProfileEnabled } = useGetParentingGroupStorage(Number(groupId));

  const { activities, application } = profile || { activities: undefined, application: undefined };

  if (!isSuperHost(currentMe.role) && currentMe.id !== Number(userId)) return <></>;

  return (
    <div>
      {activities && (
        <>
          <div className={s.header}>
            <Typography typography="title3Bold" color="gray900">
              활동 내역
            </Typography>
            <HorizontalSpacing size={6} />
            <div className={s.caption}>
              <IconViewCountRegular size={16} color={vars.$scale.color.gray600} />
              <Typography typography="label3Regular" color="gray600">
                모임장만 볼 수 있어요.
              </Typography>
            </div>
          </div>
          <HorizontalSpacing size={24} />
          {profile?.verifiedUserInfoText && group?.canViewVerifiedUserInfo && (
            <>
              <div className={s.IconWrapper}>
                <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
                <VerticalSpacing size={8} />
                <Typography color="gray900" typography="label3Regular">
                  {profile?.verifiedUserInfoText}
                </Typography>
              </div>
              <Spacing size={16} />
            </>
          )}
          <div className={s.contentsWrapper}>
            <Contents
              label="최근 방문"
              contents={`${
                getDateDiffFromNow(new Date(Date.parse(String(activities.lastVisitedAt)))).text
              } 전`}
            />
            <Contents
              label="일정 결석"
              contents={numberToLocaleString(activities.noAttendanceMeetupCount)}
            />
          </div>
        </>
      )}
      {application && (
        <>
          <Spacing size={16} />
          <div className={s.header}>
            <Typography typography="subtitle2Bold" color="gray900">
              가입 질문 답변
            </Typography>
          </div>
          <Spacing size={8} />
          <div className={s.applicationContent}>
            <Typography color="gray600" typography="bodyL2Regular">
              Q. {application.question}
            </Typography>
            <Spacing size={4} />
            <Typography color="gray900" typography="bodyL2Regular">
              {application.answer}
            </Typography>
          </div>
        </>
      )}
      {isKidProfileEnabled && <ParentingGroupKidProfile groupId={groupId} />}
    </div>
  );
};

export default withAsyncBoundary(UserActivity, {});

const Contents = ({ label, contents }: { label: string; contents: string }) => (
  <div className={s.contentsItem}>
    <h6>{label}</h6>
    <p>{contents}</p>
  </div>
);
