import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { GroupAvatar, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconConfirmationProfileFill } from '@seed-design/icon';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { useUserConfig } from '@/contexts/UserConfig';

import * as s from './verification.css';

type Props = {
  verified?: boolean;
};

const VerificationPreview = ({ verified = false }: Props) => {
  const { data: profile, isLoading } = useGetMyMainProfile({ suspense: false, hasOnError: false });
  const { userConfig } = useUserConfig();

  const profileData = profile
    ? {
        nickname: `${profile?.nickname}`,
        id: profile?.id ?? 0,
        isAccountDeleted: false,
        profileImage: profile?.profileImage || '',
        role: 'none' as GroupMemberRoleEnum,
        description: profile?.description,
        userRegion: profile?.userRegion ?? {
          id: 0,
          name: '',
          province: '',
          city: '',
          town: '',
        },
      }
    : {
        nickname: `${userConfig.userNickname}`,
        id: userConfig.userId ?? 0,
        isAccountDeleted: false,
        profileImage: userConfig.userProfileImageUrl || '',
        role: 'none' as GroupMemberRoleEnum,
        description: '',
        userRegion: {
          id: userConfig.regionId ?? 0,
          name: userConfig.regionTownName ?? '',
          province: userConfig.regionProvinceName ?? '',
          city: userConfig.regionCityName ?? '',
          town: userConfig.regionTownName ?? '',
        },
      };

  if (isLoading) return <div className={s.wrapper} />;

  return (
    <div className={s.wrapper}>
      <GroupAvatar src={profileData.profileImage} size="large" />
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900" style={{ minHeight: '1.5625rem' }}>
        {profile?.nickname}
      </Typography>

      {verified && (
        <div className={s.textBox}>
          <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
          <Typography typography="label3Regular" color="gray800">
            본인인증 완료
          </Typography>
        </div>
      )}
    </div>
  );
};

export default VerificationPreview;
