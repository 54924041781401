import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export type MemberListOrderType =
  | 'entirePostCountDesc'
  | 'entireMeetupCountDesc'
  | 'joinedAtDesc'
  | 'joinedAtAsc'
  | 'lastVisitedAtDesc';

export const getMemberListPath = (groupId: string) => `${getGroupPath(groupId)}/members`;

export type MemberListParams = {
  groupId?: string;
  order?: MemberListOrderType;
  shownOnlyHost?: boolean;
  shownMemberActivities?: boolean;
  shownMemberApplication?: boolean;
  limit?: number;
  options?: {
    enabled?: boolean;
    onError: (error: Error) => void;
  };
};

export const useGetMemberList = ({
  groupId,
  order,
  shownOnlyHost,
  shownMemberActivities,
  shownMemberApplication,
  limit = 30,
  options,
}: MemberListParams) => {
  const fetchInstance = useFetchInstance();
  const getMemberList = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useInfiniteQuery(
      [
        getMemberListPath(groupId ?? ''),
        order,
        shownOnlyHost,
        shownMemberActivities,
        shownMemberApplication,
      ],
      ({ pageParam = undefined }) =>
        getMemberList(
          Number(groupId),
          order ?? 'joinedAtAscWithRole',
          shownOnlyHost ?? false,
          shownMemberActivities ?? false,
          shownMemberApplication ?? false,
          pageParam ? pageParam : undefined,
          limit
        ),
      {
        ...options,
        suspense: true,
        getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
        enabled: options?.enabled ?? true,
      }
    );

  useEffect(() => {
    refetch();
  }, [groupId, order, shownOnlyHost]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};

export const MEMBER_LIST_ORDER_TYPE: { [key in MemberListOrderType]: string } = {
  joinedAtDesc: '최근 가입 순',
  joinedAtAsc: '가입 오래된 순',
  lastVisitedAtDesc: '마지막 방문 순',
  entirePostCountDesc: '게시글 많은 순',
  entireMeetupCountDesc: '일정 참여 많은 순',
};
