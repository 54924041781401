import { Spinner } from '@community-group/components';

import {
  SEARCH_MEETUP_ORDER_TYPE,
  useGetMeetupSearchList,
} from '@/api/hooks/useGetMeetupSearchList';
import { Container } from '@/components/common/Container';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from '../SearchResultList/SearchResultLoader.css';

interface Props {
  order: SEARCH_MEETUP_ORDER_TYPE;
}

export const SearchResultMeetupLoader = ({ order }: Props) => {
  const { query = '' } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMeetupSearchList({
    searchQuery: decodeURIComponent(query),
    order,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.Trigger} ref={ref} />
      <div className={s.Loading}>
        <Spinner variant="primary" size="small" />
      </div>
    </Container>
  );
};
