import { useKeyboardSize } from '@community-group/components';
import { BoxButton } from '@community-group/components';

import * as s from './AccessoryBarButtonGroup.css';
interface Props {
  buttonDisabled?: boolean;
  isLoading?: boolean;
  onBack?: () => void;
  onSubmit: () => void;
  submitText?: string;
}

const AccessoryBarButtonGroup = ({
  buttonDisabled,
  isLoading = false,
  onBack,
  onSubmit,
  submitText = '다음',
}: Props) => {
  const { isKeyboardOn } = useKeyboardSize();

  return (
    <div className={s.wrapper({ isKeyboardOn })}>
      {onBack && (
        <div className={s.backButtonWrapper} onClick={onBack}>
          <BoxButton width="100%" variant="secondary" size="xlarge">
            <div className={s.buttonText}>이전</div>
          </BoxButton>
        </div>
      )}
      <BoxButton
        UNSAFE_style={{ padding: 0 }}
        width="100%"
        isDisabled={buttonDisabled}
        onClick={() => onSubmit()}
        size="xlarge"
        isLoading={isLoading}
      >
        <div className={s.buttonText}>{submitText}</div>
      </BoxButton>
    </div>
  );
};

export default AccessoryBarButtonGroup;
