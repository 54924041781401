import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { GroupLeaderBoardRank50Presentation } from '@community-group/api/lib/group/models';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type OptionType = UseQueryOptions<
  AxiosResponse<model.GroupLeaderBoardRank50Presentation, any> | undefined,
  unknown,
  AxiosResponse<model.GroupLeaderBoardRank50Presentation, any> | undefined,
  string[]
>;

export const getGroupLeaderBoardRankPath = (id: number) =>
  groupClient.api.GroupLeaderboardApi.getapiV1GroupsIdLeaderboardRankGetPath(id);

type Params = {
  groupId: number;
};

export const useGetGroupLeaderBoardRank = ({ groupId }: Params, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getGroupLeaderBoardRank =
    groupClient.api.GroupLeaderboardApi.apiV1GroupsIdLeaderboardRankGet({
      axios: fetchInstance,
    });

  const { data, isLoading, isError, refetch } = useQuery(
    [getGroupLeaderBoardRankPath(groupId)],
    () => {
      return getGroupLeaderBoardRank(groupId);
    },
    {
      ...options,
      suspense: options?.suspense ?? true,
      enabled: !!groupId,
      useErrorBoundary: true,
    }
  );

  const response = data?.data as GroupLeaderBoardRank50Presentation;

  return { data: response, isLoading, isError, refetch };
};
