import { PollPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconPollFill } from '@seed-design/icon';
import { MouseEvent } from 'react';

import * as s from './PollBanner.css';

type Props = {
  poll: PollPresentation;
  onClick?: (e: MouseEvent) => void;
};

export const PollBanner = ({ poll, onClick }: Props) => {
  return (
    <div className={s.Container} onClick={onClick} aria-hidden="true">
      <div className={s.ImageWrapper}>
        <IconPollFill width={24} height={24} color={vars.$semantic.color.primary} />
      </div>
      <div className={s.ContentsWrapper}>
        <div className={s.Contents}>
          <Typography typography="bodyM2Regular" color="gray900" ellipsisAfterLines={1}>
            {poll.title}
          </Typography>
          <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
            투표 ∙ {poll.totalVoterCount}명 참여
          </Typography>
        </div>
      </div>
    </div>
  );
};
