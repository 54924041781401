import {
  AsyncBoundary,
  BoxButton,
  Typography,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import icon_checked_control_circle from '@/assets/images/icon_checked_control_circle.png';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { refetchGroupRunningGrowth } from '@/utils/refetch/runningGrowth';

import * as s from './GroupMeetupCreateDoneReviewPage.css';

const GroupMeetupCreateDoneReviewPage: ActivityComponentType = () => {
  return (
    <AppScreen>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupMeetupCreateDoneReview />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupMeetupCreateDoneReviewPage;

const GroupMeetupCreateDoneReview = () => {
  const { groupId } = useQueryParams();
  useSetFixedLayoutSize();

  const pop = useBack();

  return (
    <div className={s.Wrapper}>
      <div className={s.ContentsWrapper}>
        <img className={s.CheckItemIcon} src={icon_checked_control_circle} alt={`체크 아이콘`} />
        <Typography typography="title2Bold">후기 작성을 완료했어요</Typography>
      </div>
      <div className={s.BottomContentsWrapper}>
        <BoxButton
          width="100%"
          size="xlarge"
          onClick={() => {
            refetchGroupRunningGrowth({ groupId: Number(groupId) });
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </div>
  );
};
