import { groupClient } from '@community-group/api';
import { ShareContentRequest } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<AxiosResponse<void>, Error, ShareContentRequest>;

export const usePostShareContent = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroupShareContent = groupClient.api.ShareContentsApi.apiV1ShareContentPost({
    axios: fetchInstance,
  });

  const fetchPostGroupShareContentFp = (shareContentRequest: ShareContentRequest) => {
    return postGroupShareContent(shareContentRequest);
  };

  return useThrottleMutation(fetchPostGroupShareContentFp, {
    onError,
    onSuccess,
  });
};
