import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getMyGroupListPath = () =>
  groupClient.api.MyGroupsApi.getapiV1GroupsSummaryMyGroupsGetPath();

export const useGetMyGroupList = () => {
  const fetchInstance = useFetchInstance();

  const getMyGroupList = groupClient.api.MyGroupsApi.apiV1GroupsSummaryMyGroupsGet({
    axios: fetchInstance,
  });
  const { data, refetch } = useQuery(
    [getMyGroupListPath()],
    () => getMyGroupList({ fetchInstance }),
    {
      suspense: true,
    }
  );

  return { data: data?.data, refetch };
};
