import { IconChevronRightFill } from '@seed-design/icon';

import HomeIcon from '@/assets/images/icon_home.svg';
import { openGroupHome } from '@/utils/link';

import * as s from './SearchHomeBanner.css';

const SearchHomeBanner = () => {
  const handleClick = () => {
    openGroupHome();
  };

  return (
    <>
      <div className={s.Wrapper} onClick={handleClick}>
        <div className={s.LeftSideWrapper}>
          <img className={s.Image} src={HomeIcon} />
          <div className={s.ContentsWrapper}>
            <h3 className={s.Title}>우리동네 모임을 더 보고 싶으신가요?</h3>
            <p className={s.Description}>모임 홈에서 더 많은 모임을 볼 수 있어요.</p>
          </div>
        </div>
        <div className={s.IconWrapper}>
          <IconChevronRightFill size={20} />
        </div>
      </div>
      <div className={s.Spacing} />
    </>
  );
};

export default SearchHomeBanner;
