import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as s from './PhotoContestHeroSlide.css';

const PhotoContestHeroSlide = () => {
  const imageUrls = [
    'https://assetstorage.krrt.io/1029678394714174377/82d71e81-e246-4332-9e82-719eb2025e78/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1029678394714174377/16dd8223-e9d8-4c33-92c8-551b38c4c733/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1029678394714174377/8d820fbd-1637-413d-9124-3ad879a8a0f1/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1029678394714174377/9e698257-8be2-403e-b402-16fdfb3e84bc/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1029678394714174377/ba4bf80e-164a-4ba7-905a-98f7eaa13588/width=1029,height=840.png',
  ];

  return (
    <div className={s.Wrapper}>
      <Swiper
        className={s.Swiper}
        lazyPreloadPrevNext={1}
        loop
        autoplay={{ disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        {imageUrls.map((url, index) => (
          <SwiperSlide key={url} className={s.SwiperSlide}>
            <img src={url} className={s.Image} alt={`${index + 1}번째 설명 이미지`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PhotoContestHeroSlide;
