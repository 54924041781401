import { RefObject } from 'react';

export const scrollCommentListBottom = (
  listRef: RefObject<HTMLDivElement>,
  commentCount: number
) => {
  const scrollDelay = 400;
  const retryLimit = 3;

  // 등록순으로 정렬된 경우 불러오지 않은 댓글이 60개 미만으로 남았을 때만 자동 스크롤을 지원
  const limitCount = 60;
  const allowFallbackScroll = commentCount <= limitCount;

  (function scrollOldestComment(retry = 0) {
    if (retry > retryLimit) return;

    setTimeout(() => {
      if (!allowFallbackScroll) return;

      const target = listRef.current;
      if (!target) {
        // 모든 댓글을 불러온 뒤에 새로운 댓글이 달린 경우
        // https://daangn.height.app/T-24701
        scrollOldestComment(retry + 1);
        return;
      }

      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      scrollOldestComment(retry + 1);
    }, scrollDelay);
  })();
};
