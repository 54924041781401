import { Marker as DaangnMarker, Pin as DaangnPin } from '@community-group/components';
import { useCallback } from 'react';

import { Pin } from './Pin';

// TODO: MarkerProps 외부 export 요청해보기.
type MarkerProps = Parameters<typeof DaangnMarker>[0] & {
  label?: string;
  pinType?: Parameters<typeof DaangnPin>[0]['type'];
};

export const Marker = ({ position, children, label, pinType = 'pin-single-type' }: MarkerProps) => {
  const renderChildren = useCallback(() => {
    if (children) return children;
    return <Pin type={pinType} label={label} />;
  }, [children, label, pinType]);

  return <DaangnMarker position={position}>{renderChildren()}</DaangnMarker>;
};
