import { BoxButton, Typography } from '@community-group/components';
import React from 'react';

import { useFlow } from '@/stackflow';

import * as s from './EmptyHeroImageBox.css';

type Props = {
  groupId: string;
};

export default function EmptyHeroImageBox({ groupId }: Props) {
  const { push } = useFlow();

  const handleWritePostButton = () => {
    push('GroupPostNewPage', {
      groupId,
      from: 'HeroImage',
    });
  };
  return (
    <div className={s.Wrapper}>
      <div className={s.InnerWrapper}>
        <Typography color="gray600" typography="subtitle1Regular">
          모임 상단에 사진을 노출하려면,
          <br />
          사진이 포함된 게시글을 작성해보세요.
        </Typography>
        <BoxButton variant="secondary" width={120} size="medium" onClick={handleWritePostButton}>
          게시글 쓰기
        </BoxButton>
      </div>
    </div>
  );
}
