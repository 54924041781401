import { vars } from '@seed-design/design-token';
import { useRef } from 'react';

import { useFocusElement, useScrollPicker } from '../../hooks';
import * as s from './ScrollableTimeSelect.css';

export const HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const MINUTES = [0, 10, 20, 30, 40, 50];

interface Props {
  type: 'HOURS' | 'MINUTES';
  height?: number;
  gap?: number;
  onChange: (value: any) => void;
  selectedTime: (typeof HOURS)[number] | (typeof MINUTES)[number];
}

export const ScrollableTimeSelect = ({
  type,
  height = 46,
  gap = 17,
  onChange,
  selectedTime,
}: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const unit = height + gap;

  const { focused, onFocus, onBlur } = useFocusElement();
  const handleTouchEnd = () => {
    onBlur();
  };
  const handleTouchStart = () => {
    onFocus();
  };

  const selectList = type === 'HOURS' ? HOURS : MINUTES;
  const { handleScroll, handleSelectItem } = useScrollPicker({
    selectedItemIndex: selectList.findIndex((item) => item === selectedTime),
    unit,
    selectList,
    setSelectedItem: onChange,
    ref,
  });

  const getTimePickerColorStyle = (selected: boolean) => {
    if (selected) {
      if (focused) return vars.$scale.color.carrot500;

      return vars.$scale.color.gray900;
    }

    if (focused) return vars.$scale.color.carrot200;

    return vars.$scale.color.gray300;
  };

  return (
    <ul
      className={s.ScrollableUl}
      style={{
        gridGap: `${gap}px`,
      }}
      ref={ref}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onScroll={handleScroll}
    >
      <div className={s.TimePickerTransparent} style={{ height: `${height}px` }}>
        --
      </div>
      {selectList.map((item, index) => (
        <li
          className={s.TimePicker({ selected: selectedTime === item })}
          style={{
            height: `${height}px`,
            color: getTimePickerColorStyle(selectedTime === item),
          }}
          key={`${type}-${item}`}
          onClick={() => {
            handleSelectItem(index);
          }}
        >
          {item === 0 ? '00' : item}
        </li>
      ))}
      <div className={s.TimePickerTransparent} style={{ height: `${height}px` }}>
        --
      </div>
    </ul>
  );
};
