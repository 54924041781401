import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import { AppScreenProps } from '@stackflow/plugin-basic-ui';
import { Ref } from 'react';

import { AppScreen } from '../AppScreen';
import { LayoutWrapper } from '../Layout';
import { Layout, LayoutRefProps } from '../Layout/components/ContentsLayout';

type AppFullScreenBottomSheetProps = {
  accessoryBar?: React.ReactNode;
  layoutRef?: Ref<LayoutRefProps>;
} & AppScreenProps;

export const AppFullScreenBottomSheet = ({
  layoutRef,
  accessoryBar,
  children,
  ...props
}: AppFullScreenBottomSheetProps) => {
  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => {
            return <IconCloseRegular size={24} color={vars.$scale.color.gray900} />;
          },
        },
        ...props.appBar,
      }}
      CUPERTINO_ONLY_modalPresentationStyle="fullScreen"
      {...props}
    >
      <Layout ref={layoutRef} accessoryBar={accessoryBar}>
        <LayoutWrapper padding="0 1rem">{children}</LayoutWrapper>
      </Layout>
    </AppScreen>
  );
};
