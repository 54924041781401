import { BoxButton, Typography, VerticalSpacing } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import IMG_TADA from '@/assets/images/confetti_move.png';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openRunningGrowthRewardSurvey } from '@/utils/link';

import * as s from './RunningGrowthCompleteBottomSheet.css';

export type RunningGrowthMissionCompleteBottomSheetParams = Pick<PageParams, 'groupId'>;

const RunningGrowthMissionCompleteBottomSheet: ActivityComponentType = () => {
  const { step = '' } = useQueryParams();
  const { pop } = useFlow();

  const handleQuestCompletedBottomSheetClick = () => {
    openRunningGrowthRewardSurvey(parseInt(step, 10));
  };

  return (
    <BottomSheet style={{ padding: '0' }}>
      <div className={s.ContentsWrapper}>
        <Typography typography="title2Bold" color="gray900" textAlign="center">
          축하해요! 🎉
          <br />
          {step}단계 미션을 완료했어요.
        </Typography>
        <Spacing size={6} />
        <Typography typography="subtitle1Regular" color="gray700" textAlign="center">
          리워드를 신청하면,
          <br />
          담당자 확인 후 리워드가 발송돼요.
          <br />
          이미 신청했다면 담당자 확인을 기다려주세요.
        </Typography>
        <Spacing size={26} />
        <div className={s.HeroWrapper}>
          <img src={IMG_TADA} alt="축하해요! 🎉" className={s.HeroImage} />
        </div>
      </div>

      <Spacing size={22} />
      <div className={s.BottomWrapper}>
        <BoxButton width="6.3125rem" size="xlarge" variant="secondary" onClick={pop}>
          닫기
        </BoxButton>
        <VerticalSpacing size={8} />
        <BoxButton width="100%" size="xlarge" onClick={handleQuestCompletedBottomSheetClick}>
          리워드 신청하기
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default RunningGrowthMissionCompleteBottomSheet;
