import { Container } from '@community-group/components';
import { Spinner } from '@community-group/components';

import {
  GetGroupMeetupMembersProps,
  useGetGroupMeetupMembers,
} from '@/api/hooks/useGetGroupMeetupMembers';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './LoadMoreMemberList.css';

type Props = GetGroupMeetupMembersProps;

export const LoadMoreGroupMeetupMemberList = (groupMeetupMembersParams: Props) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetGroupMeetupMembers(groupMeetupMembersParams);

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner size="small" variant="primary" />
      </div>
    </Container>
  );
};
