import { Skeleton } from '@community-group/components';

import * as s from './MeetupDetailHeader.css';

export const MeetupDetailHeaderSkeleton = () => {
  return (
    <div className={s.OuterWrapper}>
      <div className={s.Wrapper}>
        <div className={s.ImageWrapper}>
          <div className={s.ImgSkeleton}></div>
        </div>
        <div className={s.ContentWrapper}>
          <Skeleton width="90px" height="20px" enableAnimation />
          <Skeleton width="220px" height="18px" enableAnimation />
        </div>
      </div>
    </div>
  );
};
