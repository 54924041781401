import { isClient } from '@community-group/components';

import { StorageData } from './type';

export const storage = <T extends keyof StorageData>(key: T) => {
  const storageKey = `${GROUP_STORAGE_KEY_SUFFIX}${key}`;

  const get = (): StorageData[T] => {
    const value = isClient() ? window.localStorage.getItem(storageKey) : null;

    return JSON.parse(value as string);
  };
  const set = (value: StorageData[T]) => {
    if (value == undefined || value == null) {
      return window.localStorage.removeItem(storageKey);
    }

    const stringifiedValue = JSON.stringify(value);

    window.localStorage.setItem(storageKey, stringifiedValue);
  };

  const remove = () => {
    window.localStorage.removeItem(storageKey);
  };

  return { get, set, remove };
};

const GROUP_STORAGE_KEY_SUFFIX = 'community-group/';
