import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import React from 'react';

import { Typography } from '../base';
import * as s from './FeedbackBanner.css';

type Props = {
  titleText: string;
  onClick: () => void;
  variant?: 'outlined' | 'default';
};

export const FeedbackBanner = React.memo(({ titleText, onClick, variant = 'default' }: Props) => {
  if (variant === 'outlined')
    return (
      <div className={s.OutlinedFeedbackBanner} onClick={onClick}>
        <Typography typography="bodyM2Regular" color="gray700">
          {titleText}
        </Typography>
        <p className={s.LinkButton}>
          <Typography typography="label3Regular" color="gray900">
            설문
          </Typography>
          <IconChevronRightFill width={14} height={14} color={vars.$scale.color.gray900} />
        </p>
      </div>
    );

  return (
    <div className={s.Wrapper} onClick={onClick}>
      <Typography typography="bodyM2Regular" color="gray700">
        {titleText}
      </Typography>
      <p className={s.LinkButton}>
        <Typography typography="label3Regular" color="gray900">
          설문
        </Typography>
        <IconChevronRightFill width={14} height={14} color={vars.$scale.color.gray900} />
      </p>
    </div>
  );
});
