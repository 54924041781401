import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getGroupPath } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

const getMembersInfoPath = (groupId: string) => `${getGroupPath(groupId)}/members/info`;

export const useGetMembersForMention = (groupId?: string, limit = 2000, enabled = true) => {
  const fetchInstance = useFetchInstance();
  const getMemberList = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersInfoGet({
    axios: fetchInstance,
  });

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  } = useInfiniteQuery(
    [getMembersInfoPath(groupId ?? '')],
    ({ pageParam = undefined }) =>
      getMemberList(Number(groupId), pageParam ? pageParam : undefined, limit),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
      enabled,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
