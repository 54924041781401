import { Spinner } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion, MotionProps } from 'framer-motion';
import { ReactNode } from 'react';

import * as s from './FixedButton.css';

type Props = {
  children: ReactNode;
  bottom?: number;
  fixed?: boolean;
  isLoading?: boolean;
  hasBorder?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style' | 'className'> &
  MotionProps;

export const FixedButton = ({ children, bottom, fixed, isLoading, hasBorder, ...props }: Props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '3rem' }}>
      {!hasBorder ? (
        <motion.button
          className={s.fixedButton({ fixed, isLoading })}
          style={{
            bottom:
              bottom === 0
                ? `calc(env(safe-area-inset-bottom) + ${bottom}px)`
                : `calc(${bottom}px + 16px)`,
          }}
          whileTap={{ backgroundColor: vars.$semantic.color.primaryPressed }}
          {...props}
        >
          {isLoading ? <Spinner size="small" variant="white" /> : children}
        </motion.button>
      ) : (
        <motion.button
          className={s.borderStyleFixedBottom({ fixed, isLoading })}
          style={{
            bottom:
              bottom === 0
                ? `calc(env(safe-area-inset-bottom) + ${bottom}px)`
                : `calc(${bottom}px + 16px)`,
          }}
          {...props}
        >
          {isLoading ? <Spinner size="small" variant="white" /> : children}
        </motion.button>
      )}
    </div>
  );
};
