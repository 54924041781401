export * from './ImageLoader';
import 'react-lazy-load-image-component/src/effects/blur.css';

import React from 'react';
import type { LazyLoadImageProps } from 'react-lazy-load-image-component';
import * as LazyLoad from 'react-lazy-load-image-component';

export type ImageType = {
  src?: string;
} & LazyLoadImageProps;

export const Image: React.FC<ImageType> = ({ src, ...props }) => {
  return (
    <LazyLoad.LazyLoadImage
      effect="blur"
      style={{ objectFit: 'cover' }}
      alt={src}
      height={props.height}
      src={src}
      width={props.width}
      {...props}
    />
  );
};
