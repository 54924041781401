import { Dialog, useDialog } from '@community-group/components';

import { useDeleteApplication } from '@/api/hooks/useDeleteApplication';
import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useGetMe } from '@/api/hooks/useGetMe';
import { usePutGroupMeetupJoin } from '@/api/hooks/usePutGroupMeetupJoin';
import {
  JoinGroupStateProps,
  useJoinGroupState,
} from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import { useHandleVerificationBottomSheet } from '@/components/group/new/Verification/hooks/useHandleVerificationBottomSheet';
import { openVerifyNeighborhood } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useFlow } from '@/stackflow';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams } from '@/utils/analytics/type';
import { openLink } from '@/utils/link';
import { refetchCurrentUser } from '@/utils/refetch/currentUser';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';

import useCheckGroupProfileCreated from './useCheckGroupProfileCreated';
import useCurrentGroupMe from './useCurrentGroupMe';
import { useHandleErrorWithToast } from './useHandleErrorWithToast';

type Props = JoinGroupStateProps & {
  meetupId: string;
  regionId: number;
  regionName: string;
  onSuccess?: () => void;
};

export const useJoinGroupMeetup = ({
  regionId,
  regionName,
  groupId,
  meetupId,
  onSuccess,
}: Props) => {
  const { push, pop } = useFlow();

  const { open: openDialog, close: closeDialog } = useDialog();
  const handleErrorWithToast = useHandleErrorWithToast();

  const { groupInfo, userState, joinApprovalRequireReason, approvalConditions } = useJoinGroupState(
    { groupId }
  );

  const { data: groupMeetupData } = useGetGroupMeetupDetail({
    groupId,
    meetupId,
  });

  const { data: userMe } = useGetMe();

  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  const { state: profileState } = useCheckGroupProfileCreated({});

  const currentUser = useCurrentGroupMe();

  const hasQuestion =
    approvalConditions === 'optional_answer' || approvalConditions === 'require_answer';

  const trackJoinGroupMeetupEvent = () => {
    trackEvent({
      event: 'click_join_group_meetup',
      params: {
        meetupId,
        groupId: groupId.toString() ?? '',
        userRole: currentUser.role,
        currentMeetupJoinStatus: groupMeetupData.currentUserInfo.meetupJoinStatus,
        category: groupInfo.categoryName,
        currentUserState: userState,
        isInstantMeetupJoin: true,
        joinApprovalRequireReason,
      },
      loggerType: extendAppsflyerLoggerType,
    });
  };

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  const trackJoinGroupEvent = () => {
    const isFirstJoinGroup = !eventMyInfo?.data.isJoinedGroup;

    const eventParams: GroupEventParams = {
      joinType: groupInfo.joinType,
      groupId: groupInfo.id,
      groupName: groupInfo.name,
      groupCategory: groupInfo.categoryName,
      checkinReigionId: regionId,
      checkinMostAccurate: regionName,
      isInstantMeetupJoin: !!meetupId,
      joinApprovalRequireReason,
      needVerification: groupInfo.needVerification,
      canViewVerifiedUserInfo: groupInfo.canViewVerifiedUserInfo,
      watched: groupInfo.watched,
      isFirstJoinGroup,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
    };

    trackEvent({
      event: 'click_join_group',
      params: eventParams,
      loggerType: extendAppsflyerLoggerType,
    });

    if (isFirstJoinGroup) {
      trackEvent({
        event: 'click_first_join_group',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });
    }
  };
  const { bridge } = useBridge();
  const { mutate: mutateGroupMeetupJoin } = usePutGroupMeetupJoin({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      trackJoinGroupEvent();

      if (['none', 'optional_answer', 'require_answer'].includes(approvalConditions)) {
        bridge.openToast({
          toast: {
            body: '참여를 신청했어요. 승인되면 다시 알려드릴게요.',
          },
        });
      }

      refetchGroupMeetupDetail({
        groupId,
        meetupId,
      });

      refetchGroupDetail({
        groupId,
      });
      trackJoinGroupMeetupEvent();

      if (hasQuestion) {
        pop();
      }

      if (joinApprovalRequireReason === 'none') {
        openLink(groupMeetupData.channelInfo.targetUri);
        return;
      }
    },
  });

  const handleJoinGroup = async () => {
    if (joinApprovalRequireReason === 'not_certificated_region') {
      openDialog({
        element: (
          <Dialog
            title="동네인증"
            description={`모임에 가입하려면 ${groupInfo.regionName} 동네인증이 필요해요.`}
            onPrimaryAction={async () => {
              await closeDialog();
              trackEvent({ event: 'click_verify_neighboorhood' });
              openVerifyNeighborhood();
            }}
            primaryActionLabel="인증"
            secondaryActionLabel="취소"
            secondaryActionIntent="nonpreferred"
            onSecondaryAction={async () => {
              trackEvent({ event: 'click_cancel_verify_neighboorhood' });
              await closeDialog();
            }}
          />
        ),
      });
      return;
    }

    const getCertificationCandidateType = () => {
      if (!groupInfo.needVerification || (userMe.verified && !groupInfo.canViewVerifiedUserInfo)) {
        return 'pass_verification';
      }

      if (!groupInfo.canViewVerifiedUserInfo) {
        return 'join_group';
      }

      if (userMe.verified) {
        return 'join_group_with_user_info_and_verified';
      }

      return 'join_group_with_user_info_and_not_verified';
    };

    // 본인인증 결과 체크
    const verified = await handleVerificationBottomSheet({
      certificationCandidateType: getCertificationCandidateType(),
    });

    if (!verified) {
      return;
    }

    // 가입 승인이 필요없는 경우, 가입 범위에 해당하지 않거나, 가입 승인이 필요한 경우 (but 질문 없음)
    if (joinApprovalRequireReason === 'none') {
      if (profileState.needToProfileSet) {
        push('GroupProfileCreatePage', {
          groupId,
          instantJoinMeetupId: meetupId,
        });
        return;
      }
      mutateGroupMeetupJoin(
        {
          groupId: groupId,
          meetupId,
          groupMeetupJoinForm: {
            profile: undefined,
            answer: undefined,
          },
        },
        {
          onSuccess() {
            onSuccess?.();
          },
        }
      );
      return;
    }

    // 가입 범위에 해당하지 않거나, 가입 승인이 필요한 경우 (but 질문 없음)
    if (
      joinApprovalRequireReason === 'not_joinable_region_range' ||
      approvalConditions === 'none'
    ) {
      openDialog({
        element: (
          <Dialog
            title="가입 신청"
            description={`모임장의 승인이 필요한 모임이에요. ${groupInfo.name} 모임에 가입할까요?`}
            onPrimaryAction={async () => {
              await closeDialog();
              if (profileState.needToProfileSet) {
                push('GroupProfileCreatePage', {
                  groupId,
                });
                return;
              }
              mutateGroupMeetupJoin({
                groupId: groupId,
                meetupId,
                groupMeetupJoinForm: {
                  profile: undefined,
                  answer: undefined,
                },
              });
            }}
            primaryActionLabel="가입 신청"
            secondaryActionLabel="취소"
            onSecondaryAction={async () => {
              trackEvent({
                event: 'click_cancel',
                params: {
                  type: 'join_group',
                },
              });
              await closeDialog();
            }}
          />
        ),
      });
      return;
    }

    if (approvalConditions === 'optional_answer' || approvalConditions === 'require_answer') {
      if (profileState.needToProfileSet) {
        push('GroupProfileCreatePage', {
          groupId,
          instantJoinMeetupId: meetupId,
        });
        return;
      }
      push('GroupProfileApprovalPage', {
        groupId,
        instantJoinMeetupId: meetupId,
      });
      return;
    }
  };

  const { mutate: deleteApplicationMutate } = useDeleteApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({
        groupId,
      });
      refetchCurrentUser({
        groupId,
      });
      bridge.openToast({
        toast: {
          body: '가입 신청을 취소했어요.',
        },
      });
    },
  });
  const handleJoinCancel = () => {
    openDialog({
      element: (
        <Dialog
          description="가입 신청을 그만둘까요?"
          primaryActionLabel="그만두기"
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            closeDialog();
          }}
          onPrimaryAction={async () => {
            await closeDialog();
            await deleteApplicationMutate(Number(groupId));
          }}
        />
      ),
    });
  };

  return {
    handleClick: userState === 'applied' ? handleJoinCancel : handleJoinGroup,
  };
};
