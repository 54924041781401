import create, { GetState, SetState } from 'zustand';

import { createGlobalStateSlice, GlobalStateSlice } from './globalState/globalStateSliceStore';
import { createGroupSlice, GroupCreateSlice } from './groupCreate/createGroupSlice';
import {
  createGroupMarketingSlice,
  GroupMarketingSlice,
} from './groupMarketing/groupMarketingSlice';
import { createHomeHeaderSlice, HomeSlice } from './home/homeSliceStore';
import { createMeetupHomeSlice, MeetupHomeSlice } from './meetupHome/meetupHomeSliceStore';
import { createModalSlice, ModalSlice } from './modal/modalSliceStore';
import { createPostSlice, PostSlice } from './post/postSliceStore';
import { createSearchHistoryListSlice, SearchHistoryListSlice } from './searchHistoryList';
import { createInputSizeSlice, InputSizeSlice } from './stickyInput/inputSizeSliceStore';

export type StoreSlice<T> = (set: SetState<StoreState>, get: GetState<StoreState>) => T;

export type StoreState = ModalSlice &
  HomeSlice &
  PostSlice &
  SearchHistoryListSlice &
  InputSizeSlice &
  MeetupHomeSlice &
  GlobalStateSlice &
  GroupCreateSlice &
  GroupMarketingSlice;

export const useStore = create<StoreState>((set, get) => ({
  ...createModalSlice(set, get),
  ...createHomeHeaderSlice(set, get),
  ...createPostSlice(set, get),
  ...createSearchHistoryListSlice(set, get),
  ...createInputSizeSlice(set, get),
  ...createMeetupHomeSlice(set, get),
  ...createGlobalStateSlice(set, get),
  ...createGroupSlice(set, get),
  ...createGroupMarketingSlice(set, get),
}));
