import { vars } from '@seed-design/design-token';
import { useRef } from 'react';

import { useFocusElement, useScrollPicker } from '../../hooks';
import * as s from './ScrollableDayPeriodSelect.css';

export const DAY_PERIOD = ['오전', '오후'];
interface Props {
  height?: number;
  gap?: number;
  onChange: (value: any) => void;
  selectedDayPeriod: (typeof DAY_PERIOD)[number];
}

export const ScrollableDayPeriodSelect = ({
  height = 26,
  gap = 24,
  onChange,
  selectedDayPeriod,
}: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const unit = height + gap;

  const { focused, onFocus, onBlur } = useFocusElement();
  const handleTouchEnd = () => {
    onBlur();
  };
  const handleTouchStart = () => {
    onFocus();
  };

  const { handleScroll, handleSelectItem } = useScrollPicker({
    selectedItemIndex: DAY_PERIOD.findIndex((period) => period === selectedDayPeriod),
    unit,
    selectList: DAY_PERIOD,
    setSelectedItem: onChange,
    ref,
  });

  const getTimePickerColorStyle = (selected: boolean) => {
    if (selected) {
      if (focused) return vars.$scale.color.carrot500;

      return vars.$scale.color.gray900;
    }

    if (focused) return vars.$scale.color.carrot200;

    return vars.$scale.color.gray300;
  };

  return (
    <div className={s.Wrapper} style={{ height: `${height * 3 + gap * 2}px` }}>
      <ul
        className={s.ScrollableUl}
        style={{
          gridGap: `${gap}px`,
        }}
        ref={ref}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onScroll={handleScroll}
      >
        <div className={s.TimePickerTransparent} style={{ height: `${height}px` }}>
          --
        </div>
        {DAY_PERIOD.map((period, index) => (
          <li
            className={s.TimePicker}
            style={{
              height: `${height}px`,
              color: getTimePickerColorStyle(selectedDayPeriod === period),
            }}
            key={period}
            onClick={() => {
              handleSelectItem(index);
            }}
          >
            {period}
          </li>
        ))}
        <div className={s.TimePickerTransparent} style={{ height: `${height}px` }}>
          --
        </div>
      </ul>
    </div>
  );
};
