// 11월 독서 프로모션 가입 마케팅 프로모션 기간인지 확인하는 함수
export const isDuringRealJoin11월독서GroupMarketingPromotion = () => {
  // 한국시 10월 28일 00시 부터 11월 10일 00시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 9, 27, 15, 0)); // 월은 0부터 시작하므로 10월은 9로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 11, 9, 15, 0)); // 월은 0부터 시작하므로 12월은 11로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};
