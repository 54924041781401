import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.BoardManagedResponse>,
  Error,
  {
    id: number;
    postBoardManagedForm?: model.BoardManagedForm;
  }
>;

export const usePutBoardManagedStatus = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putModify = groupClient.api.PostBoardApi.apiV1GroupsIdPostsBoardManagedPut({
    axios: fetchInstance,
  });

  const putModifyWrapperFp = ({
    id,
    postBoardManagedForm,
  }: {
    id: number;
    postBoardManagedForm?: model.BoardManagedForm;
  }) => {
    return putModify(id, postBoardManagedForm);
  };

  return useThrottleMutation(putModifyWrapperFp, {
    onError,
    onSuccess,
  });
};
