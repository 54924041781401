import React from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Props = {
  params: string;
  additionalCondition?: boolean;
  callback: () => void;
};

const useCheckScrollActionParams = () => {
  const { scrollTo } = useQueryParams();

  const checkFromHandler = React.useCallback(
    ({ params: checkFrom, callback, additionalCondition = true }: Props) =>
      () => {
        if (scrollTo === checkFrom && additionalCondition) {
          callback();
          return true;
        }
        return false;
      },
    [scrollTo]
  );

  return checkFromHandler;
};

export default useCheckScrollActionParams;
