import {
  AsyncBoundary,
  Checkbox,
  Switch,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { FormInput } from '@/components/common/base/Input/Text';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import { FormRadioForApproval } from './components/FormRadioForApproval';
import * as s from './index.css';

export const APPROVAL = {
  APPROVAL: 'approval',
  FREE: 'free',
} as const;

export type GroupNewSetApprovalPageParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'createType'
  | 'from'
  | 'description'
  | 'joinGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetApprovalPage: ActivityComponentType<GroupNewSetApprovalPageParams> = () => {
  const { push, pop } = useFlow();
  const { from, createGroupFlowType, ...postNewGroupPayload }: GroupNewSetApprovalPageParams =
    useQueryParams();

  const { register, handleSubmit, watch, setValue, control } = useCreateForm({
    defaultValues: {
      joinType: APPROVAL.FREE,
      question: undefined,
      hasQuestion: false,
      requiredQuestion: undefined,
    } as FieldValues,
  });

  const joinTypeFieldValue = watch('joinType');
  const questionFieldValue = watch('question');
  const requiredFieldValue = watch('requiredQuestion');
  const hasQuestionFieldValue = watch('hasQuestion');

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: { step: 'approval', createGroupFlowType: createGroupFlowType ?? 'normal' },
  });
  useSetFixedLayoutSize(false);
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false });

  const { nextPageName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    push(nextPageName, {
      ...postNewGroupPayload,
      joinType: joinTypeFieldValue,
      question: questionFieldValue,
      requiredQuestion: requiredFieldValue,
      haveQuestion: hasQuestionFieldValue.toString(),
      from,
      createGroupFlowType,
    });
  });

  const isButtonDisabled = useMemo(() => {
    if (!joinTypeFieldValue) return true;
    if (joinTypeFieldValue === APPROVAL.APPROVAL) {
      if (hasQuestionFieldValue) {
        if (!questionFieldValue) return true;
        if (!questionFieldValue.trim()) return true;
      }
    }
    return false;
  }, [hasQuestionFieldValue, questionFieldValue, joinTypeFieldValue]);

  const handleBack = () => {
    pop();
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetApprovalPage')}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            onBack={handleBack}
            onSubmit={onSubmit}
            buttonDisabled={isButtonDisabled}
          />
        </>
      )}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0 1rem">
          <div className={s.title}>가입은 어떻게 받을까요?</div>
          <FormRadioForApproval
            register={register}
            value={APPROVAL.FREE}
            name="joinType"
            title="바로 가입"
            subtitle="누구나 가입할 수 있어요."
            checked={joinTypeFieldValue === 'free'}
          />
          <Spacing size={8} />
          <FormRadioForApproval
            register={register}
            value={APPROVAL.APPROVAL}
            name="joinType"
            title="승인 후 가입"
            subtitle="모임장이 승인해야 가입할 수 있어요."
            checked={joinTypeFieldValue === 'approval'}
          />
          <AnimatePresence>
            {joinTypeFieldValue === 'approval' && (
              <motion.div
                style={{ display: 'flex', flexDirection: 'column' }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.1,
                }}
                exit={{ opacity: 0 }}
              >
                <Spacing size={8} />
                <div>
                  <div className={s.questionHeader}>
                    <div className={s.questionHeaderLeft}>
                      <p>가입 질문</p>
                      <span>가입 시 받을 질문을 입력할 수 있어요.</span>
                    </div>
                    <Switch
                      onChange={(value) => setValue('hasQuestion', value)}
                      isSelected={hasQuestionFieldValue}
                    />
                  </div>
                  <AnimatePresence>
                    {hasQuestionFieldValue && (
                      <motion.div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                        }}
                        transition={{
                          duration: 0.1,
                        }}
                        exit={{ opacity: 0 }}
                      >
                        <FormInput
                          register={register}
                          value={questionFieldValue}
                          name="question"
                          placeholder="ex) 모임에서 어떤 활동을 하고 싶나요?"
                        />
                        <Controller
                          control={control}
                          name="requiredQuestion"
                          render={({ field: { onChange, name, value } }) => (
                            <Checkbox
                              marginTop={12}
                              onChange={onChange}
                              name={name}
                              isSelected={value}
                            >
                              필수로 답변 받기
                            </Checkbox>
                          )}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <Spacing size={32} />
        </LayoutWrapper>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetApprovalPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
