import { Spinner } from '@community-group/components';

import { useGetMeetupsWithDayFilter } from '@/api/hooks/allTypeMeetup/useGetMeetupsWithDayFilter';
import { MeetupFilterType } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection';
import { useUserConfig } from '@/contexts/UserConfig';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { formatDate } from '@/utils/date';

import { Container } from '../Container';
import * as s from './LoadMoreMeetupCalendar.css';

type LoadAllTypeMeetupProps = {
  filter: MeetupFilterType;
  day: string;
};

export const LoadMoreMeetupCalendar = ({ filter, day }: LoadAllTypeMeetupProps) => {
  const { userConfig } = useUserConfig();
  const regionId = userConfig.regionId ?? 0;

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMeetupsWithDayFilter({
    regionId,
    ...filter,
    day,
    showRenderLimit: false,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      hasNextPage && !isFetchingNextPage && fetchNextPage();
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.Trigger} ref={ref} />
      <div className={s.Loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};
