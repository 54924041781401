import { Activity } from '@stackflow/core';
import { useStack } from '@stackflow/react';

import { TypeActivities, useFlow } from '@/stackflow';

import useActiveActivities from './useActiveActivities';

const useBackToRootActivity = () => {
  const stack = useStack();
  const { pop } = useFlow();
  const { diffActivitiesFromCurrent } = useActiveActivities();

  const rootActivity = stack.activities
    .filter((a) => a.isActive === true)
    .find((a) => a.zIndex === 0);

  const handleBackToRootActivity = (callback?: (rootActivity?: Activity) => void) => {
    const popDiff = diffActivitiesFromCurrent(rootActivity?.name as keyof TypeActivities);
    if (popDiff !== -1) {
      pop(popDiff);
    }
    if (callback) {
      callback(rootActivity);
    }
  };

  return {
    rootActivity,
    handleBackToRootActivity,
  };
};

export default useBackToRootActivity;
