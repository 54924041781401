import { useFlow } from '@/stackflow';
import useCurrentGroupMe from './useCurrentGroupMe';
import { AccessStatusServiceEnum } from '@community-group/api/lib/group/models';
import { useEffect } from 'react';

export const useHandleRestrictedUser = ({
  serviceType,
  groupId,
  meetupId,
  postId,
}: {
  serviceType?: AccessStatusServiceEnum;
  groupId: string;
  meetupId?: string;
  postId?: string;
}) => {
  const { replace } = useFlow();
  const currentUser = useCurrentGroupMe();

  const handleRestrictedUser = () => {
    replace('AccessStatusServiceHandlingPage', {
      serviceType: serviceType,
      groupId: groupId ?? '',
      meetupId: meetupId ?? '',
      postId: postId ?? '',
      accessStatusOption: 'restricted',
    });
  };

  useEffect(() => {
    if (currentUser.state === 'restricted') {
      handleRestrictedUser();
    }
  }, [currentUser]);

  return { handleRestrictedUser };
};
