import { vars } from '@seed-design/design-token';
import { ComponentType, ReactNode, useMemo, useRef, useState } from 'react';

import { useTheme } from '@/stackflow/hooks/useTheme';

import * as s from './index.css';
import Navbar, { StackflowTheme, TransparentNavbarStyle } from './Navbar';
import { TransparentNavScreenContext } from './TransparentNavScreenContext';

export type AppendRightProps = {
  iconColor: string;
};

type Props = {
  name?: string;
  renderRight?: ComponentType<AppendRightProps>;
  appendBottom?: ReactNode;
  backgroundColor?: string;
  hasBottomFloating?: boolean;
  navbarStyle: TransparentNavbarStyle;
  explicitIconColor?: string;
  explicitIconStyle?: 'back' | 'backwardNew' | 'close';
  children?: ReactNode;
};

const TransparentNavScreen = ({
  name,
  renderRight: AppendRight,
  appendBottom,
  hasBottomFloating,
  children,
  backgroundColor,
  navbarStyle,
  explicitIconColor,
  explicitIconStyle,
}: Props) => {
  const { getTheme } = useTheme();
  const deviceType = useMemo(() => getTheme(), []) as StackflowTheme;

  const [isNavbarTitleShow, setIsNavbarTitleShow] = useState(false);
  const iconColor = useMemo(() => {
    if (navbarStyle === 'hard') {
      return vars.$scale.color.gray900;
    }

    if (isNavbarTitleShow) {
      return vars.$scale.color.gray900;
    }

    return vars.$static.color.staticWhite;
  }, [navbarStyle, isNavbarTitleShow]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const transparentContextValue = useMemo(() => ({ scrollRef, setIsNavbarTitleShow }), []);

  return (
    <div className={s.Container} style={{ backgroundColor }}>
      <Navbar
        title={isNavbarTitleShow ? name : ''}
        showNavigationBg={navbarStyle === 'hard' || isNavbarTitleShow}
        showNavigationShadow={isNavbarTitleShow}
        renderRight={
          AppendRight && (
            <div className={s.IconWrapper}>
              <AppendRight iconColor={explicitIconColor || iconColor} />
            </div>
          )
        }
        appendBottom={appendBottom}
        iconColor={explicitIconColor || iconColor}
        navbarStyle={navbarStyle}
        explicitIconStyle={explicitIconStyle}
        theme={deviceType}
      />
      <div
        ref={scrollRef}
        className={s.ScrollableContainer({
          themeType: navbarStyle !== 'hard' ? undefined : deviceType,
          hasBottomPadding: hasBottomFloating,
        })}
      >
        <TransparentNavScreenContext.Provider value={transparentContextValue}>
          {children}
        </TransparentNavScreenContext.Provider>
      </div>
    </div>
  );
};

export default TransparentNavScreen;
