import { useActivity } from '@stackflow/react';

import { useBridge } from '@/contexts/Bridge';
import useActiveActivities from '@/hooks/useActiveActivities';
import * as stackflow from '@/stackflow';

export const useBack = () => {
  const stack = stackflow?.useFlow();
  const { isRoot } = useActivity();
  const { activeActivities } = useActiveActivities();
  const { bridge } = useBridge();
  const handlePop = (e?: any) => {
    if (isRoot || activeActivities.length === 1) {
      bridge.closeRouter({});
      return;
    }
    e?.preventDefault();
    e?.stopPropagation();
    stack?.pop();
  };

  return handlePop;
};
