export const convertNumberToKoreanUnit = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  }
  if (num < 10000) {
    return (Math.floor(num / 100) / 10).toFixed(1).replace(/\.0$/, '') + '천';
  }
  if (num < 100000) {
    return (Math.floor(num / 1000) / 10).toFixed(1).replace(/\.0$/, '') + '만';
  }
  if (num < 1000000) {
    return (Math.floor(num / 10000) / 10).toFixed(1).replace(/\.0$/, '') + '만';
  }
  if (num < 10000000) {
    return (Math.floor(num / 100000) / 10).toFixed(1).replace(/\.0$/, '') + '십만';
  }
  if (num < 100000000) {
    return (Math.floor(num / 1000000) / 10).toFixed(1).replace(/\.0$/, '') + '백만';
  }
  if (num < 1000000000) {
    return (Math.floor(num / 10000000) / 10).toFixed(1).replace(/\.0$/, '') + '천만';
  }
  return (Math.floor(num / 100000000) / 10).toFixed(1).replace(/\.0$/, '') + '억';
};

export const numberToLocaleString = (num: number) => {
  return num.toLocaleString('ko-KR');
};
