import { Container, Spinner } from '@community-group/components';

import { useGetGroupMeetupAttendanceMembers } from '@/api/hooks/useGetGroupMeetupAttendanceMembers';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './LoadMoreAttendanceMemberList.css';

type Params = {
  groupId: string;
  meetupId: string;
};

export const LoadMoreAttendanceMemberList = ({ groupId, meetupId }: Params) => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetGroupMeetupAttendanceMembers({
    groupId,
    meetupId,
  });

  const { ref } = useInfinityScroll({
    callback: () => hasNextPage && !isFetchingNextPage && fetchNextPage(),
    intersectionOptions: {
      threshold: 0,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner size="small" variant="primary" />
      </div>
    </Container>
  );
};
