import { useMemo } from 'react';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';

import { GroupMainProfile } from './useGetGroupMainProfile';
import { useGetMyGroupProfile } from './useGetMyGroupProfile';

type Props = {
  groupId: number;
  queryOptions?: {
    enabled?: boolean;
    suspense?: boolean;
    hasOnError?: boolean;
    onError?: (error: unknown) => void;
    refetchOnWindowFocus?: boolean;
  };
};

export const getMyGroupMainProfileQueryKey = (groupId: number) => ['MyGroupMainProfile', groupId];

export const useGetMyGroupMainProfile = ({ groupId, queryOptions }: Props) => {
  const queryKeys = getMyGroupMainProfileQueryKey(groupId);

  const {
    data: myMainData,
    isLoading: isMyMainLoading,
    refetch: refetchMyMain,
  } = useGetMyMainProfile({
    ...queryOptions,
    queryKeys,
  });

  const {
    data: myGroupData,
    isLoading: isMyGroupLoading,
    refetch: refetchMyGroup,
  } = useGetMyGroupProfile({
    groupId,
    queryOptions: {
      ...queryOptions,
    },
    queryKeys,
  });

  const data = useMemo(() => {
    return {
      ...myMainData,
      ...myGroupData,
    };
  }, [myMainData, myGroupData]) as GroupMainProfile;

  const isLoading = isMyMainLoading || isMyGroupLoading;
  const refetch = () => {
    refetchMyMain();
    refetchMyGroup();
  };

  return { data, isLoading, refetch };
};
