import { ComponentType } from 'react';

import { useHydrated } from '../../hooks';

// TODO: lazy load도 동작되게 하기.
type Props = {
  render: () => JSX.Element | null;
  fallback?: React.ReactNode;
};

// Ref: https://github.com/sergiodxa/remix-utils/blob/main/src/react/client-only.tsx
export const ClientOnly = ({ render, fallback = null }: Props) => {
  return useHydrated() ? <>{render()}</> : <>{fallback}</>;
};

export const withClientOnly = <Props extends Record<string, unknown> = Record<string, never>>(
  WrappedComponent: ComponentType<Props>
) => {
  return (props: Props) => {
    return <ClientOnly render={() => <WrappedComponent {...props} />} fallback={null} />;
  };
};
