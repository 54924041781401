import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { ActionListItem, BoxButton, Dialog, useDialog } from '@community-group/components';
import { useState } from 'react';

import { useGetGroup1on1PayTalkTargetUri } from '@/api/hooks/useGetGroup1on1PayTalkTargetUri';
import { useGetGroup1on1TalkTargetUri } from '@/api/hooks/useGetGroup1on1TalkTargetUri';
import { useGetMoneyTransferTicket } from '@/api/hooks/useGetMoneyTransferTicket';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenGroupPayTransferDirectChat } from '@/hooks/useOpenGroupPayTransferDirectChat';
import { useVisibilityEvent } from '@/hooks/useVisibilityEvent';
import { trackEvent } from '@/utils/analytics';
import { openAppStore, openGroupPayTransferDirectChat, openHref } from '@/utils/link';
import { checkHigherTargetVersion } from '@/utils/version';

type Props = {
  groupId: number;
  targetUserId: number;
};

export const GroupDirectPayChatStatusOnEnabledActionListItem = ({
  groupId,
  targetUserId,
}: Props) => {
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [talkTargetUri, setTalkTargetUri] = useState<string>('');
  const [ticketId, setTicketId] = useState<string>('');

  const { open: openDialog, close: closeDialog } = useDialog();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = useGetGroup1on1PayTalkTargetUri({
    onError: handleErrorWithToast,
  });

  const { openAsyncPayTransferDirectChat } = useOpenGroupPayTransferDirectChat({
    onError: handleErrorWithToast,
  });

  const handleTransferClick = () => {
    if (isTransferLoading) return;

    setIsTransferLoading(true);

    trackEvent({
      event: 'click_pay_transfer_direct_chat_button',
      params: {
        groupId,
        type: 'profile_detail',
        directChatEnabledStatus: 'enabled',
      },
    });

    mutate(
      {
        id: groupId.toString() ?? '0',
        memberId: targetUserId.toString(),
      },
      {
        onSuccess: ({ data }) => {
          setTalkTargetUri(data?.talkTargetUri);
          const chatChannelId = data?.talkTargetUri.split('channels/')?.[1] ?? '';

          if (chatChannelId) {
            openAsyncPayTransferDirectChat(
              {
                chatChannelId,
                transferReferrer: 'GROUP_PROFILE',
              },
              {
                onSuccess: (data) => {
                  const { ticketId } = data.data;
                  setTicketId(ticketId);

                  // 참고: https://www.notion.so/daangn/72f46c103a6a4bdd92af3cbed48dc2c8?pvs=4#5a97441c0b904d3b9a394d03ba7921f4
                  if (checkHigherTargetVersion(241600)) {
                    openGroupPayTransferDirectChat(ticketId);
                  } else {
                    trackEvent({
                      event: 'click_pay_transfer_direct_show_update_alert',
                      params: {
                        groupId,
                        type: 'profile_detail',
                        directChatEnabledStatus: 'enabled',
                      },
                    });
                    setIsTransferLoading(false);
                    openDialog({
                      element: (
                        <Dialog
                          description="송금을 위해 당근 앱을 업데이트해 주세요."
                          secondaryActionLabel="다음에 하기"
                          onSecondaryAction={() => {
                            closeDialog();
                          }}
                          primaryActionLabel="스토어 가기"
                          onPrimaryAction={async () => {
                            await closeDialog();
                            openAppStore();
                          }}
                        />
                      ),
                    });
                  }
                },
              }
            );
          }
        },
        onError: () => {
          setTicketId('');
          setTalkTargetUri('');
          setIsTransferLoading(false);
        },
      }
    );
  };

  const { fetch: checkMoneyTransferStatus } = useGetMoneyTransferTicket();

  useVisibilityEvent({
    enabled: isTransferLoading && !!talkTargetUri,
    callback: async (visibilityState) => {
      if (visibilityState === 'visible') {
        const chatChannelId = talkTargetUri.split('channels/')?.[1] ?? '';

        try {
          if (chatChannelId) {
            const { transferStatus = 'wait' } = await checkMoneyTransferStatus(ticketId);

            if (transferStatus === 'success') {
              trackEvent({
                event: 'click_pay_transfer_direct_success',
                params: {
                  groupId,
                  type: 'profile_detail',
                  directChatEnabledStatus: 'enabled',
                },
              });
              openHref(talkTargetUri);

              setTalkTargetUri('');
              setTicketId('');
            }

            setIsTransferLoading(false);
          }
        } catch {
          setIsTransferLoading(false);
        }
      }
    },
  });

  return (
    <>
      <ActionListItem onClick={handleTransferClick}>송금하기</ActionListItem>
    </>
  );
};
