import { useEffect } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

export enum MemberProfileSectionKey {
  MEETUP_MEMBER_REVIEW_MESSAGES = 'meetup_member_review_messages',
}

type Props = {
  referContent: MemberProfileSectionKey;
};

export const useMemberProfileScrollTo = ({ referContent }: Props) => {
  const { refer_content } = useQueryParams();

  useEffect(() => {
    if (refer_content === referContent) {
      const target = document.getElementById(referContent);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [refer_content, referContent]);
};
