import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Request = { id: number; userId: number; form: model.GroupMemberApplicationAcceptanceForm };

type Props = UseMutationOptions<AxiosResponse, Error, Request>;
export const usePatchApplicationAcceptance = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const patchAcceptance =
    groupClient.api.GroupApplicationApi.apiV1GroupsIdApplicationsUserIdAcceptancePatch({
      axios: fetchInstance,
    });

  const patchAcceptanceWrapperFp = (req: Request) => {
    return patchAcceptance(req.id, req.userId, req.form);
  };

  return useThrottleMutation(patchAcceptanceWrapperFp, {
    onError,
    onSuccess,
  });
};
