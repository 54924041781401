import { vars } from '@seed-design/design-token';
import React from 'react';

// eslint-disable-next-line no-useless-escape
const regexForMarkup = /\[.*?]\{([0-9]+?)\}/g;
const getBracketsText = /\[(.+)\]\{([0-9]+?)\}/;

interface MentionAccumulator {
  elements: React.ReactNode[];
  cursor: number;
}

export const getElementFromMention = (
  text: string,
  type: 'string' | 'tag' = 'tag',
  onClickMention?: (userId: string, event: React.MouseEvent) => void
): React.ReactNode[] => {
  const mentions = text.match(regexForMarkup);
  if (!mentions) return [<span key="default">{text}</span>];

  const result = mentions.reduce<MentionAccumulator>(
    (acc, mention, index, arr) => {
      const mentionIndex = text.indexOf(mention, acc.cursor);
      if (mentionIndex > acc.cursor) {
        acc.elements.push(
          <span key={`text-${index}`}>{text.substring(acc.cursor, mentionIndex)}</span>
        );
      }
      acc.cursor = mentionIndex + mention.length;

      const display = getBracketsText.exec(mention);
      if (display) {
        const [_, mentionText, userId] = display;
        const key = `mention-${index}`;

        if (type === 'string') {
          acc.elements.push(mentionText);
        } else {
          acc.elements.push(
            <span
              key={key}
              className={`mention`}
              style={{ color: vars.$scale.color.blue600 }}
              onClick={(event) => (onClickMention ? onClickMention(userId, event) : undefined)}
            >
              {mentionText}
            </span>
          );
        }
      }

      if (index === arr.length - 1 && acc.cursor < text.length) {
        acc.elements.push(<span key={`text-end-${acc.cursor}`}>{text.substring(acc.cursor)}</span>);
      }

      return acc;
    },
    { elements: [], cursor: 0 }
  );

  return result.elements;
};
