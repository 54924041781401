import { GroupMemberSummaryWithActivityPresentation } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  Dialog,
  isMember,
  useDialog,
  useKeyboardSize,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useState } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { getMemberListPath, useGetMemberList } from '@/api/hooks/useGetMemberList';
import { usePatchGroupMembersRole } from '@/api/hooks/usePatchGroupMembersRole';
import { queryClient } from '@/api/instance';
import { Container } from '@/components/common/Container';
import EmptySection from '@/components/common/Empty';
import { LoadMoreMemberListContainer } from '@/components/common/LoadMoreContainer';
import MemberSearchBar from '@/components/common/MemberSearchBar';
import ProfileListItem from '@/components/common/Profile/ListItem';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './memberDelegateSuperHost.css';

export type GroupSettingMemberDelegateSuperHostPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingMemberDelegateSuperHostPage: ActivityComponentType<
  GroupSettingMemberDelegateSuperHostPageParams
> = () => {
  return (
    <AppScreen appBar={{ title: '모임장 위임' }}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <DelegateSuperHostWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupSettingMemberDelegateSuperHostPage;

const DelegateSuperHostWrapper = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isFocusedSearchBar, setIsFocusedSearchBar] = useState(false);
  const { keyboardHeight } = useKeyboardSize();

  const { groupId } = usePathParams();
  const { data, hasNextPage } = useGetMemberList({ groupId });

  const memberList = data?.pages.map(({ data }) => data.members);
  const flattenMemberList = memberList?.flat(1) ?? [];
  const filteredMemberList = flattenMemberList.filter(({ nickname }) => {
    if (!searchValue) return true;

    return nickname.toLowerCase().includes(searchValue.toLowerCase());
  });

  const { data: me, refetch: refetchGroupMe } = useGetGroupMe(groupId);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: patchGroupMembersRole } = usePatchGroupMembersRole({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupMe();
      queryClient.refetchQueries([getMemberListPath(groupId ?? ''), undefined, undefined]);
    },
  });

  const { open: openDialog, close: closeDialog } = useDialog();
  const { bridge } = useBridge();
  const handleProfileClick = (user: GroupMemberSummaryWithActivityPresentation) => {
    openDialog({
      element: (
        <Dialog
          title="모임장 위임"
          description={`${convertNicknameDisplay({
            nickname: user.nickname,
            subNickname: user.subNickname,
          })}님에게 모임장을 위임할까요? 위임하면 ${convertNicknameDisplay({
            nickname: me?.currentUser.nickname ?? '',
            subNickname: me?.currentUser.subNickname,
          })}님은 멤버로 변경돼요.`}
          primaryActionLabel="위임"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                member: user,
                role: 'superHost',
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${convertNicknameDisplay({
                        nickname: user.nickname,
                        subNickname: user.subNickname,
                      })}님에게 모임장을 위임했어요.`,
                    },
                  });
                },
              }
            );
            await closeDialog();
          }}
        />
      ),
    });
  };

  const paddingBottomStyle = isFocusedSearchBar
    ? `calc(env(safe-area-inset-bottom) + ${keyboardHeight}px )`
    : 'env(safe-area-inset-bottom)';

  const FilteredMemberList = useCallback(() => {
    if (filteredMemberList.length <= 1) {
      return <EmptySection>위임할 수 있는 이웃이 없어요.</EmptySection>;
    }

    return (
      <ul>
        {filteredMemberList.map((member) => {
          if (member.id === me?.currentUser.id) return null;

          return (
            <li
              className={s.listItem}
              key={member.id}
              onClick={() => {
                handleProfileClick(member);
              }}
            >
              <ProfileListItem user={member} />
            </li>
          );
        })}
      </ul>
    );
  }, [filteredMemberList, me?.currentUser.id, handleProfileClick]);

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: paddingBottomStyle,
      }}
    >
      {isMember(me?.currentUser.role) && (
        <>
          <div
            style={{
              paddingTop: '0.5rem',
            }}
          >
            <MemberSearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsFocusedSearchBar={setIsFocusedSearchBar}
              placeholder="닉네임을 검색해보세요"
            />
          </div>
        </>
      )}
      <Container paddingX={0} paddingY={0}>
        <FilteredMemberList />
        {hasNextPage && <LoadMoreMemberListContainer />}
      </Container>
    </div>
  );
};
