import { GroupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import {
  ActionItem,
  ActionList,
  ActionListItem,
  ActionSheet,
  Dialog,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import { useCallback, useMemo } from 'react';

import { getGroupPath } from '@/api/base/group';
import { getGroupMainProfileQueryKey } from '@/api/hooks/useGetGroupMainProfile';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { MemberListOrderType } from '@/api/hooks/useGetMemberList';
import { usePatchGroupMembersRole } from '@/api/hooks/usePatchGroupMembersRole';
import { queryClient } from '@/api/instance';
import { useBridge } from '@/contexts/Bridge';
import useActiveActivities from '@/hooks/useActiveActivities';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupProfileReport, openHref } from '@/utils/link';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';
import { isHigherManager, isMember, isSuperHost } from '@/utils/role';

import GroupPayChatActionListItem from '../../profile/GroupProfile/shared/GroupPayChatActionListItem';
import { DeleteMemberDialog } from '../../Setting/components/DeleteMemberDialog';
import { useManagerMemberList } from './useManagerMemberList';

type Props = {
  groupId: string;
  user: GroupMemberSummaryPresentation;
  shownOnlyHost?: boolean;
  selectedMemberOrderType?: MemberListOrderType;
};

export const useHandleProfileMoreButton = ({
  groupId,
  user,
  shownOnlyHost,
  selectedMemberOrderType,
}: Props) => {
  const { data: myInfo } = useGetGroupMe(groupId);
  const { push } = useFlow();
  const { activeActivities } = useActiveActivities();

  const currentActivity = activeActivities[activeActivities.length - 1];

  const myRole = myInfo?.currentUser.role;

  const { hostMemberList } = useManagerMemberList(groupId);
  const hasRestrictPermission = myInfo?.currentUser.permissions.restrictMember;

  const { banLabel, handleBanProfile } = useHandleBanProfile(
    groupId,
    user,
    shownOnlyHost,
    selectedMemberOrderType
  );
  const { roleLabel, handleRoleProfile } = useHandleRoleProfile(
    groupId,
    user,
    hostMemberList,
    shownOnlyHost,
    selectedMemberOrderType
  );

  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const handleMoreButtonClick = () => {
    const actionItemList: ActionItem[] = [];

    const isAbleChangeRole = isSuperHost(myRole) && isMember(user.role) && !user.isAccountDeleted;
    const isAbleBan = isMember(user.role) && !isSuperHost(user.role) && hasRestrictPermission;
    const showPayChatButton =
      isMember(myRole) && currentActivity.name === 'GroupUserProfileDetailPage';

    if (isAbleChangeRole) {
      actionItemList.push({
        label: roleLabel,
        onClick: async () => {
          handleRoleProfile();
        },
      });
    }

    actionItemList.push({
      label: '신고',
      onClick: async () => {
        await closeBottomSheet();
        openGroupProfileReport({
          groupId,
          userId: String(user?.id),
        });
      },
    });

    if (isAbleBan) {
      actionItemList.push({
        label: banLabel,
        color: vars.$semantic.color.danger,
        onClick: async () => {
          handleBanProfile();
        },
      });
    }

    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            {showPayChatButton && (
              <GroupPayChatActionListItem
                groupId={parseInt(groupId)}
                targetUserId={user.id}
                actionFlowPush={
                  push as unknown as (activity: string, params: Record<string, string>) => void
                }
              />
            )}
            {actionItemList.map((actionItem) => (
              <ActionListItem
                key={actionItem.label}
                onClick={actionItem.onClick}
                color={actionItem.color}
              >
                {actionItem.label}
              </ActionListItem>
            ))}
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  const renderProfileMoreButton = useCallback(() => {
    if (myRole === 'none') return null;

    if (myInfo?.currentUser.id === user.id) return null;

    return (
      <button onClick={handleMoreButtonClick}>
        <IconMoreVertRegular size={24} />
      </button>
    );
  }, [myRole, user?.role, hostMemberList, shownOnlyHost, selectedMemberOrderType]);

  return {
    renderProfileMoreButton,
  };
};

const useHandleBanProfile = (
  groupId: string,
  user: GroupMemberSummaryPresentation,
  shownOnlyHost?: boolean,
  selectedMemberOrderType?: MemberListOrderType
) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const refetchMemberList = useCallback(() => {
    setTimeout(() => {
      queryClient.refetchQueries([
        `${getGroupPath(groupId)}/members`,
        selectedMemberOrderType,
        shownOnlyHost,
      ]);

      queryClient.refetchQueries([`${getGroupPath(groupId)}/members`, undefined, true]);
      queryClient.refetchQueries(getGroupMainProfileQueryKey(user.id, parseInt(groupId)));
      refetchGroupMemberList({
        groupId,
        selectedMemberOrderType,
        shownOnlyHost,
      });
    }, 200);
  }, [groupId, selectedMemberOrderType, shownOnlyHost, user?.id]);

  const handleBanProfile = async () => {
    await closeDialog();
    openDialog({
      element: (
        <DeleteMemberDialog
          groupId={groupId}
          userId={user.id.toString()}
          nickname={user.nickname}
          subNickname={user.subNickname}
          confirmCallback={() => {
            refetchMemberList();
          }}
        />
      ),
      onOutsideClick: closeDialog,
    });
  };

  return {
    banLabel: '모임에서 내보내기',
    handleBanProfile,
  };
};

const useHandleRoleProfile = (
  groupId: string,
  user: GroupMemberSummaryPresentation,
  hostMemberList: GroupMemberSummaryPresentation[],
  shownOnlyHost?: boolean,
  selectedMemberOrderType?: MemberListOrderType
) => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: patchGroupMembersRole } = usePatchGroupMembersRole({
    onError: handleErrorWithToast,
  });

  const isHigherManagerRole = useMemo(
    () => isHigherManager(user.role),
    [groupId, user?.role, shownOnlyHost, selectedMemberOrderType]
  );
  const roleLabel = useMemo(
    () => (isHigherManagerRole ? '운영진에서 제외' : '운영진으로 설정'),
    [isHigherManagerRole]
  );

  const hostMemberCount = useMemo(() => hostMemberList.length, [hostMemberList]);

  const refetchMemberList = useCallback(() => {
    setTimeout(() => {
      queryClient.refetchQueries([
        `${getGroupPath(groupId)}/members`,
        selectedMemberOrderType,
        shownOnlyHost,
      ]);

      queryClient.refetchQueries([`${getGroupPath(groupId)}/members`, undefined, true]);
      queryClient.refetchQueries(getGroupMainProfileQueryKey(user.id, parseInt(groupId)));
    }, 30);
  }, [groupId, selectedMemberOrderType, shownOnlyHost, user?.id]);
  const { bridge } = useBridge();
  const handleSetHost = useCallback(async () => {
    await closeDialog();

    // TODO: hostMemberCount가 실시간으로 반영 안되는 버그가 있어요. 대응이 필요해요.

    if (hostMemberCount >= 10) {
      return bridge.openToast({
        toast: {
          body: '운영진은 최대 10명까지 설정할 수 있어요.',
        },
      });
    }

    return openDialog({
      element: (
        <Dialog
          title="운영진 설정"
          description={`${user.nickname}님을 운영진으로 설정할까요?`}
          primaryActionLabel="설정"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                member: user,
                role: 'manager',
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${user.nickname}님을 운영진으로 설정했어요.`,
                    },
                  });

                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [groupId, hostMemberCount, user?.role, shownOnlyHost, selectedMemberOrderType]);

  const handleUnsetHost = useCallback(async () => {
    await closeDialog();

    return openDialog({
      element: (
        <Dialog
          title="운영진 제외"
          description={`${user.nickname}님을 운영진에서 제외할까요?`}
          primaryActionLabel="제외"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            patchGroupMembersRole(
              {
                groupId,
                member: user,
                role: 'member',
              },
              {
                onSuccess: () => {
                  bridge.openToast({
                    toast: {
                      body: `${user.nickname}님을 운영진에서 제외했어요.`,
                    },
                  });

                  refetchMemberList();
                },
              }
            );

            await closeDialog();
          }}
          onSecondaryAction={closeDialog}
        />
      ),
    });
  }, [groupId, hostMemberList, user?.role, shownOnlyHost, selectedMemberOrderType]);

  return {
    roleLabel: useMemo(() => roleLabel, [roleLabel]),
    handleRoleProfile: useMemo(
      () => (isHigherManagerRole ? handleUnsetHost : handleSetHost),
      [isHigherManagerRole, handleSetHost, handleUnsetHost]
    ),
  };
};
