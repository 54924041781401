import { FeedbackRatingEnum } from '@community-group/api/lib/group/models';

import dislike from '@/components/group/Meetup/Review/assets/dislike.png';
import excellent from '@/components/group/Meetup/Review/assets/excellent.png';
import gray_dislike from '@/components/group/Meetup/Review/assets/gray_dislike.png';
import gray_excellent from '@/components/group/Meetup/Review/assets/gray_excellent.png';
import gray_like from '@/components/group/Meetup/Review/assets/gray_like.png';
import like from '@/components/group/Meetup/Review/assets/like.png';

import ReviewRatingFieldItem from './ReviewRatingFieldItem';
import * as s from './style.css';

type Props = {
  value?: FeedbackRatingEnum;
  onChange: (value: FeedbackRatingEnum) => void;
};

export const ReviewRatingFields = ({ value, onChange }: Props) => {
  const handleChange = (currentValue: FeedbackRatingEnum) => {
    if (currentValue === value) return;

    onChange(currentValue);
  };

  return (
    <div className={s.Wrapper}>
      <ReviewRatingFieldItem
        text="별로예요"
        onClick={() => {
          handleChange('DISLIKE');
        }}
        isSelected={value === 'DISLIKE'}
        defaultColor="gray600"
        selectedColor="gray700"
        defaultIcon={gray_dislike}
        selectedIcon={dislike}
      />
      <ReviewRatingFieldItem
        text="좋아요"
        onClick={() => {
          handleChange('LIKE');
        }}
        isSelected={value === 'LIKE'}
        defaultColor="gray600"
        selectedColor="green500"
        defaultIcon={gray_like}
        selectedIcon={like}
      />
      <ReviewRatingFieldItem
        text="최고예요"
        onClick={() => {
          handleChange('EXCELLENT');
        }}
        isSelected={value === 'EXCELLENT'}
        defaultColor="gray600"
        selectedColor="carrot500"
        defaultIcon={gray_excellent}
        selectedIcon={excellent}
      />
    </div>
  );
};
