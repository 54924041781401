import { vars } from '@seed-design/design-token';

interface Props {
  strokeColor?: string;
  size?: number;
}

const ThinX = ({ size = 18, strokeColor: propsStrokeColor }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$scale.color.gray900;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75527 2.75527C2.92896 2.58158 3.21057 2.58158 3.38427 2.75527L9 8.371L14.6157 2.75527C14.7894 2.58158 15.071 2.58158 15.2447 2.75527C15.4184 2.92896 15.4184 3.21057 15.2447 3.38427L9.629 9L15.2447 14.6157C15.4184 14.7894 15.4184 15.071 15.2447 15.2447C15.071 15.4184 14.7894 15.4184 14.6157 15.2447L9 9.629L3.38427 15.2447C3.21057 15.4184 2.92896 15.4184 2.75527 15.2447C2.58158 15.071 2.58158 14.7894 2.75527 14.6157L8.371 9L2.75527 3.38427C2.58158 3.21057 2.58158 2.92896 2.75527 2.75527Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default ThinX;
