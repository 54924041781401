import { Spacing, Typography } from '@community-group/components';

import { GroupLevel } from '../../utils/GroupLevelType';
import { GROUP_LEVEL_POLICY_INFORMATION } from '../../utils/levelPolicyInformation';
import * as s from './MissionAchievePercentage.css';

type Props = {
  currentLevel: number;
  nextLevel: number;
  currentAchievePercentage: number;
};

const MissionAchievePercentage = ({ currentLevel, nextLevel, currentAchievePercentage }: Props) => {
  const remainPercentage = 100 - Math.floor(currentAchievePercentage);

  const isMaxLevel = currentLevel === 5;

  const renderTextInfo = () => {
    if (isMaxLevel) {
      return (
        <div className={s.InfoTextWrapper}>
          <Typography typography="subtitle2Regular" color="gray700">
            미션을 완료하고 다음 달에도 <b>Lv.5</b>를 유지해보세요!
          </Typography>
        </div>
      );
    }
    return (
      <div className={s.InfoTextWrapper}>
        <Typography typography="subtitle2Regular" color="gray700">
          다음 레벨까지 <b>{remainPercentage}%</b> 남았어요!
        </Typography>
        <Typography typography="subtitle2Regular" color="gray600">
          Lv.{nextLevel}
        </Typography>
      </div>
    );
  };

  return (
    <div className={s.Container}>
      {renderTextInfo()}
      <Spacing size={12} />
      <div className={s.ProgressBarWrapper}>
        <div
          className={s.ProgressBar}
          style={{
            width: `${currentAchievePercentage}%`,
            background: GROUP_LEVEL_POLICY_INFORMATION[currentLevel]?.levelColor,
          }}
        />
      </div>
    </div>
  );
};

export default MissionAchievePercentage;
