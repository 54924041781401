import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupEventParams, SpecificEventType } from '@/utils/analytics/type';

import * as s from './CreateMeetupButton.css';
import { useInitialCreateGroupLLMExperiment } from '@/components/group/new/hooks/useInitialCreateGroupLLMExperiment';
type Props = {
  eventName: SpecificEventType;
  eventParams: GroupEventParams;
};

const CreateMeetupButton = (props: Props) => {
  const { push } = useFlow();
  const { handleActivityCreateGroupLLMExperiment } = useInitialCreateGroupLLMExperiment({});

  return (
    <button
      className={s.button}
      onClick={() => {
        trackEvent({ event: props.eventName, params: props.eventParams });
        handleActivityCreateGroupLLMExperiment();
      }}
    >
      모임 만들기
    </button>
  );
};

export default CreateMeetupButton;
