import { withClientOnly } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconViewCountRegular } from '@seed-design/icon';
import { UseFormWatch } from 'react-hook-form';

import { useUserConfig } from '@/contexts/UserConfig';

import { GroupPostFormHandlerProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  mode: 'new' | 'edit';
  watch: UseFormWatch<GroupPostFormHandlerProps>;
};

// 조건에 따른 메시지와 스타일을 정의
const getNoticeProps = (watch, regionName) => {
  if (watch('isGroupOnly')) {
    return {
      message: '모임에 가입한 멤버만 글을 볼 수 있어요.',
      color: vars.$scale.color.gray600,
      Icon: IconViewCountRegular,
    };
  }

  if (watch('isNoticed')) {
    return {
      message: '모임 공지사항으로 등록해요.',
      color: vars.$scale.color.gray600,
      Icon: IconViewCountRegular,
    };
  }

  if (watch('isPublished')) {
    return {
      message: `${regionName} 근처 모든 이웃들에게 보여요.`,
      color: vars.$scale.color.gray600,
      Icon: IconViewCountRegular,
    };
  }

  return {
    message: '모임에 가입하지 않아도 누구나 볼 수 있어요.',
    color: vars.$scale.color.gray600,
    Icon: IconViewCountRegular,
  };
};

const AppBarNotice = ({ watch }: Props) => {
  const { userConfig } = useUserConfig();
  const { color, Icon, message } = getNoticeProps(watch, userConfig.regionTownName);

  return (
    <div>
      <div style={{ color }} className={s.NoticeArea}>
        {Icon && <Icon width={16} height={16} color={color} />}
        <p className={s.NoticeText}>{message}</p>
      </div>
    </div>
  );
};

export default withClientOnly(AppBarNotice);
