import { ActionableCallout, Spacing, Typography } from '@community-group/components';
import { useFlow } from '@stackflow/react/future';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useGetGroupLeaderBoardRank } from '@/api/hooks/useGetGroupLeaderBoardRank';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

const GroupLevelLeaderBoardRank = () => {
  const { groupId } = usePathParams();

  const { data } = useGetGroupLeaderBoardRank({ groupId: Number(groupId) });

  const { data: leaderBoardData } = useGetGroupLeaderBoard({ weekAgo: 0 });
  const hasOver5RankGroups = leaderBoardData?.rankList.length > 5;

  const { push } = useFlow();
  const rank = data?.rank;

  const onClickLeaderBoard = () => {
    trackEvent({
      event: 'click_current_rank_banner_level_page',
      params: {
        groupId,
        currentRanking: rank,
        includedRanking: !!rank,
      },
    });
    push('GroupLeaderBoard', {});
  };

  if (!hasOver5RankGroups) return null;

  if (!rank) {
    return (
      <>
        <ActionableCallout onClick={onClickLeaderBoard}>
          <Typography typography="bodyM2Regular" color="gray900">
            이번 주 <b>실시간 모임 랭킹</b>을 확인해보세요!
          </Typography>
        </ActionableCallout>
        <Spacing size={20} />
      </>
    );
  }

  return (
    <>
      <ActionableCallout variant="info" onClick={onClickLeaderBoard}>
        <Typography typography="bodyM2Regular" color="blue950">
          <b style={{ marginRight: 8 }}>실시간 모임 랭킹 {rank}위</b>
          활동 점수를 모아서 더 높은 순위에 도전해보세요.
        </Typography>
      </ActionableCallout>
      <Spacing size={20} />
    </>
  );
};

export default GroupLevelLeaderBoardRank;
