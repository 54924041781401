import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupDetailPath = (id?: string) => `${GROUP_URL}/${id}`;

type OptionType = UseQueryOptions<
  AxiosResponse<model.GroupDetailResponse, any> | undefined,
  unknown,
  AxiosResponse<model.GroupDetailResponse, any> | undefined,
  string[]
>;

export const useGetGroupDetail = (id?: string, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getGroupDetail = groupClient.api.GroupApi.apiV1GroupsIdGet({
    axios: fetchInstance,
  });

  const { data, refetch, isInitialLoading } = useQuery(
    [getGroupDetailPath(id)],
    () => {
      if (!id) return;
      return getGroupDetail(Number(id));
    },
    {
      ...options,
      retry: 1,
      suspense: options?.suspense ?? true,
    }
  );
  const response = data?.data.group;

  return { group: response, refetch, isLoading: isInitialLoading };
};
