import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useConnectedFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

export type MemberRejectMenuBottomSheetRequest = 'close' | 'reject' | 'rejectWithMessage';

const MemberRejectMenuBottomSheet: ActivityComponentType = () => {
  const { pop } = useConnectedFlow();
  const { groupId } = usePathParams();

  const handleClose = () => pop().send('close');
  const handleRejectWithMessage = () => {
    trackEvent({
      event: 'click_application_reject_with_comment_menu',
      params: { groupId },
    });
    pop().send('rejectWithMessage');
  };
  const handleReject = () => {
    trackEvent({
      event: 'click_application_reject_menu',
      params: { groupId },
    });
    pop().send('reject');
  };

  return (
    <BottomSheet style={{ padding: '0px' }} safeAreaInsetBottomRemove>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <ActionSheet
          bottomButton={{
            label: '닫기',
            onClick: handleClose,
          }}
        >
          <ActionList>
            <ActionListItem onClick={handleRejectWithMessage}>거절하고 의견 보내기</ActionListItem>
            <ActionListItem onClick={handleReject}>바로 거절하기</ActionListItem>
          </ActionList>
        </ActionSheet>
      </AsyncBoundary>
    </BottomSheet>
  );
};

export default MemberRejectMenuBottomSheet;
