import { GroupMeetupProgressStatusPresentationStatus } from '@community-group/api/lib/group/models';

export const isHostDeletedGroupMeetup = (
  progressStatus: GroupMeetupProgressStatusPresentationStatus
) => progressStatus === 'deleted';

export const isCanceledGroupMeetup = (
  progressStatus: GroupMeetupProgressStatusPresentationStatus
) => progressStatus === 'canceled';

export const isDeletedGroupMeetup = (progressStatus: GroupMeetupProgressStatusPresentationStatus) =>
  isHostDeletedGroupMeetup(progressStatus) || isCanceledGroupMeetup(progressStatus);

export const isOpenedGroupMeetup = (progressStatus: GroupMeetupProgressStatusPresentationStatus) =>
  progressStatus === 'opened' || progressStatus === 'fullyRecruited';

export const getMeetupStatusColor = (
  progressStatus?: GroupMeetupProgressStatusPresentationStatus
) => {
  if (progressStatus === 'opened') return 'primary';
  if (progressStatus === 'fullyRecruited') return 'warning';
  return 'gray600';
};
