import { groupClient } from '@community-group/api';
import {
  GroupMeetupCreateForm,
  GroupMeetupCreateResponse,
} from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Params = {
  groupId: number;
  meetupCreateForm: GroupMeetupCreateForm;
};
type Props = UseMutationOptions<AxiosResponse<GroupMeetupCreateResponse>, Error, Params>;

export const usePostGroupMeetup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroupMeetup = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsPost({
    axios: fetchInstance,
  });

  const fetchPostGroupMeetup = ({ groupId, meetupCreateForm }: Params) => {
    return postGroupMeetup(groupId, meetupCreateForm);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(fetchPostGroupMeetup, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'create_group_meetup',
        groupId: variables.groupId,
      });
    },
  });
};
