import { vars } from '@seed-design/design-token';

interface Props {
  color?: string;
  size?: number;
}

const CategoryGroup = ({ size = 20, color }: Props) => {
  const fill = color ?? vars.$scale.color.gray700;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.7806 10.075C12.289 10.075 10.939 10.7167 9.97229 11.7417C8.99729 10.7167 7.65562 10.075 6.16395 10.075C3.41395 10.075 1.13895 12.25 0.838954 15.05C0.797287 15.4167 0.988954 15.775 1.33062 15.925C3.64729 16.9167 6.66395 17.525 9.97229 17.525C13.2806 17.525 16.2973 16.9167 18.614 15.925C18.9556 15.7833 19.139 15.4167 19.1056 15.05C18.8056 12.25 16.539 10.075 13.7806 10.075Z"
        fill={fill}
      />
      <path
        d="M5.83895 9.26667C7.70752 9.26667 9.22229 7.7519 9.22229 5.88333C9.22229 4.01477 7.70752 2.5 5.83895 2.5C3.97039 2.5 2.45562 4.01477 2.45562 5.88333C2.45562 7.7519 3.97039 9.26667 5.83895 9.26667Z"
        fill={fill}
      />
      <path
        d="M14.0973 9.26667C15.9659 9.26667 17.4806 7.7519 17.4806 5.88333C17.4806 4.01477 15.9659 2.5 14.0973 2.5C12.2287 2.5 10.714 4.01477 10.714 5.88333C10.714 7.7519 12.2287 9.26667 14.0973 9.26667Z"
        fill={fill}
      />
    </svg>
  );
};

export default CategoryGroup;
