import { getDateDiffFromNow, Spacing } from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { ListBasic } from '@/components/common/base/List/ListBasic';
import { ProfileImage } from '@/components/common/Image/Profile';
import LinkifyContainer from '@/components/common/Linkify';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { convertNicknameDisplay } from '@/utils/nickname';

import GroupMeetupDetailImageList from './GroupMeetupDetailImageList';
import GroupMeetupDetailPlace from './GroupMeetupDetailPlace';
import GroupMeetupDetailReaction from './GroupMeetupDetailReaction';
import GroupMeetupInfoList from './GroupMeetupInfoList';
import * as s from './index.css';
import MeetupDetailBreadcrumbs from './MeetupDetailBreadcrumbs';

type Params = {
  groupId: string;
  meetupId: string;
};

export const GroupMeetupInfoSection = ({ groupId, meetupId }: Params) => {
  const { push } = useFlow();

  const { data } = useGetGroupMeetupDetail({ groupId, meetupId });

  const host = data?.host;

  const userDescription = useMemo(() => {
    const checkIn = `${host?.region.name} 인증 ${host?.regionCheckinsCount}회`;
    const createdAt = ` • ${getDateDiffFromNow(new Date(Date.parse(data?.createdAt))).text} 전`;
    const isBlockedGroupOnly = data?.exposureRange === 'GROUP_MEMBER';
    const groupOnly = isBlockedGroupOnly ? ` • 모임에만 공개` : '';
    return `${checkIn}${createdAt}${groupOnly}`;
  }, [data?.createdAt, data.exposureRange, host?.region.name, host?.regionCheckinsCount]);

  return (
    <div className={s.Wrapper}>
      <Spacing size={12} />
      <MeetupDetailBreadcrumbs />
      <div className={s.ProfileWrapper}>
        <ListBasic
          prefixIcon={<ProfileImage size={36} borderRadius={36} src={host?.profileImage ?? ''} />}
          text={
            <span className={s.Nickname}>
              {convertNicknameDisplay({
                nickname: host?.nickname ?? '',
                subNickname: host?.subNickname,
              })}
            </span>
          }
          description={userDescription}
          onClick={() => {
            push('GroupUserProfileDetailPage', {
              groupId,
              userId: host?.userId.toString() ?? '',
            });

            trackEvent({
              event: 'click_profile',
              params: {
                clickedUserId: host?.userId,
                clickedUserRole: 'host',
                name: 'groupMeetupInfoSection',
              },
            });
          }}
        />
      </div>
      <Spacing size={4} />
      <h3 className={s.Title}>
        <span className={s.TitleSpan} style={{ color: data?.progressStatus.color }}>
          {data?.progressStatus.description}
        </span>
        {data?.title}
      </h3>
      <Spacing size={16} />
      <GroupMeetupInfoList
        meetupDateText={data.meetupTimeAtDisplayText ?? undefined}
        meetupLocationText={data.poi?.name}
      />
      <Spacing size={16} />
      {data?.description && (
        <>
          <p className={s.Description}>
            <LinkifyContainer text={data.description}></LinkifyContainer>
          </p>
          <Spacing size={16} />
        </>
      )}
      {(data?.images ?? []).length > 0 && (
        <>
          <GroupMeetupDetailImageList images={data?.images} />
          <Spacing size={16} />
        </>
      )}
      {data?.poi && (
        <>
          <GroupMeetupDetailPlace place={data.poi} />
          <Spacing size={16} />
        </>
      )}
      <GroupMeetupDetailReaction groupId={groupId} meetupId={meetupId} />
    </div>
  );
};
