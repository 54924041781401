import { CommentResponse } from '@community-group/api/lib/group/models';
import { AxiosResponse } from 'axios';
import { RefObject } from 'react';

import { getPostCommentPath } from '@/api/hooks/useGetComment';
import { getPostCommentsPath } from '@/api/hooks/useGetComments';
import { getCommentTotalCountPath } from '@/api/hooks/useGetCommentTotalCount';
import { getMemberMissionListPath } from '@/api/hooks/useGetMemberMissionList';
import { getPostDetailPath, useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { usePostComment } from '@/api/hooks/usePostComment';
import { queryClient } from '@/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import { scrollCommentListBottom } from '../utils/scrollCommentListBottom';

type Params = {
  ref: RefObject<HTMLDivElement>;
  groupId: string;
  postId: string;
  commentId: string;
};

export const useHandlePostComment = ({ ref, groupId, postId, commentId }: Params) => {
  const { refetch: refetchPostDetail } = useGetPostDetail(groupId, postId);

  const handleErrorWithToast = useHandleErrorWithToast();
  return usePostComment({
    relatedContentType: 'post',
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      // IOS에서는 refetch만으로 update 되지 않아서, queryClient를 이용하여 강제로 update
      const postDetail = await refetchPostDetail();
      queryClient.setQueryData([getPostDetailPath(groupId, postId)], (prev: any) => {
        return postDetail.data ?? prev;
      });

      await queryClient.refetchQueries([getPostCommentsPath(groupId, postId)]);
      await queryClient.refetchQueries([getPostCommentPath(groupId, postId, commentId)]);
      await queryClient.refetchQueries([getCommentTotalCountPath(groupId, postId, 'post')]);
      await queryClient.refetchQueries([getMemberMissionListPath(Number(groupId))]);

      // 댓글 상세
      if (commentId) {
        const commentDetail = await queryClient.getQueryData<AxiosResponse<CommentResponse>>([
          getPostCommentPath(groupId, postId, commentId),
        ]);
        scrollCommentListBottom(ref, commentDetail?.data?.comment.subCommentCount ?? 0);
        return;
      }

      // 게시글 상세
      scrollCommentListBottom(ref, postDetail.data?.data.post.commentCount ?? 0);
    },
  });
};
