import { groupClient } from '@community-group/api';
import { RequestGroupDirectChatCreateForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '../../utils/ReactQuery/useThrottleMutation';
import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<AxiosResponse<void>, Error, RequestGroupDirectChatCreateForm>;

export const usePostUserChatRequestGroupPayDirectChats = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postUserChatRequestGroupDirectChats =
    groupClient.api.GroupDirectChatApi.apiV1UserChatRequestGroupPayDirectChatsPost({
      axios: fetchInstance,
    });

  const postUserChatRequestGroupDirectChatsFp = (form: RequestGroupDirectChatCreateForm) => {
    return postUserChatRequestGroupDirectChats(form);
  };

  return useThrottleMutation(postUserChatRequestGroupDirectChatsFp, {
    onError,
    onSuccess,
  });
};
