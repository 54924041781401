import { UserMeetupMemberReview } from '@community-group/api/lib/group/models';
import { HorizontalSpacing, Spacing, Typography } from '@community-group/components';

import { ReviewLabelItem } from '@/components/common/MemberProfileMeetupMemberReviews/ReviewLabelItem';

import * as s from './style.css';
type Props = {
  reviews: UserMeetupMemberReview[];
};
const ReceivedReviewsSection = ({ reviews }: Props) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.TitleSection}>
        <Typography as="h6" typography="title3Bold">
          받은 후기
        </Typography>
      </div>
      {!reviews.length && (
        <div className={s.EmptySection}>
          <Typography typography="subtitle1Regular" color="gray600">
            받은 후기가 없어요.
          </Typography>
        </div>
      )}
      {reviews.length > 0 && (
        <>
          <HorizontalSpacing size={16} />
          <div className={s.LabelListWrapper}>
            {reviews.map((review) => (
              <ReviewLabelItem key={review.feedbackTypeId} meetupMemberReview={review} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReceivedReviewsSection;
