import { GroupMeetupSummaryPresentation } from '@community-group/api/lib/group/models';
import { differenceInHours, differenceInMinutes } from 'date-fns';

const TIME_GAP = 168; // 7일

export const filteredPublicMeetup = (meetupList: GroupMeetupSummaryPresentation[]) => {
  const isInTimeGap = (meetDate: string, meetTime?: string) => {
    // 정확한 모임 시간이 있는 경우
    if (meetTime) {
      const diff = differenceInHours(new Date(meetTime), new Date());
      if (diff < 0) return false; // 모임시간 지난 모임
      if (diff === 0) {
        const diffMin = differenceInMinutes(new Date(meetTime), new Date());
        if (diffMin <= 0) return false; // 모임시간 지난 모임
        return true; // 아직 60분 이내의 시간이 남은 모임
      }
      if (diff >= TIME_GAP) return false;
      return true;
    }
    // 날짜만 정해져있는 경우
    const diff = differenceInHours(new Date(meetDate), new Date().setUTCHours(0, 0, 0, 0));
    if (diff < 0) return false; // 모임시간 지난 모임
    if (diff >= TIME_GAP) return false; // TIME_GAP 시간 이상 남은 모임
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return meetupList.filter(({ exposureRange, meetDate, meetTime }) => {
    if (exposureRange !== 'ALL') return false;
    const isOpenedMeetup = isInTimeGap(meetDate, meetTime);
    return isOpenedMeetup;
  });
};
