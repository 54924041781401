import React from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

const useCheckIsNewParams = () => {
  const { isNew: isNewToString } = useQueryParams();
  const isNew = isNewToString === 'true';

  const isNewCheck = React.useCallback(() => {
    if (isNew) {
      return true;
    }
    return false;
  }, [isNew]);

  return isNewCheck;
};

export default useCheckIsNewParams;
