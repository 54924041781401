import { ViewError } from '@community-group/components';

import { useFlow } from '@/stackflow';

export const NotFoundView = () => {
  const { replace } = useFlow();

  const home = () => {
    replace('HomePage', {});
  };

  return <ViewError buttonText="홈으로 이동" onClickButton={home} />;
};
