import { groupClient } from '@community-group/api';

export const isSuperHost = (role?: groupClient.model.GroupMemberRoleEnum) => {
  if (role === groupClient.model.GroupMemberRoleEnum.superHost) return true;
  return false;
};

export const isNotSuperHost = (role: groupClient.model.GroupMemberRoleEnum) => {
  if (role !== groupClient.model.GroupMemberRoleEnum.superHost) return true;
  return false;
};

export const isHigherManager = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  const managerRoles = ['superHost', 'host', 'manager'];

  return managerRoles.includes(role);
};

export const isNotMember = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  if (role === groupClient.model.GroupMemberRoleEnum.none) return true;
  return false;
};

export const isMember = (role: groupClient.model.GroupMemberRoleEnum = 'none') => {
  if (role !== groupClient.model.GroupMemberRoleEnum.none) return true;
  return false;
};
