import { Media, Video } from '@community-group/api/lib/group/models';

import { FileUploadState, UploadStatus } from '../types/Video';

export const uploadedVideoFormatter = (media: Media[]): FileUploadState[] => {
  try {
    const video = media.filter((media) => media.type === 'video').map((el) => el.media) as Video[];

    const formattedVideos = video.map((uploadedVideo) => {
      return {
        id: uploadedVideo.id,
        file: new File([], uploadedVideo.id),
        thumbnail: uploadedVideo.thumbnailUrl,
        status: 'completed' as UploadStatus,
        progress: 100,
        uploadedVideoInfo: {
          id: uploadedVideo.id,
          duration: uploadedVideo.duration,
        },
      };
    });

    return formattedVideos;
  } catch (error) {
    return [];
  }
};
