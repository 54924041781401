import { Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconChevronRightRegular } from '@seed-design/icon';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './ReviewItem.css';

interface Props {
  groupId: string;
}

const MeetupReviewsMoreButton = ({ groupId }: Props) => {
  const { push } = useFlow();

  const handleMoreMeetupReviews = () => {
    trackEvent({
      event: 'click_see_more_meetup_reviews',
      params: {
        groupId,
      },
    });
    push('GroupDetailNotMemberMeetupReviewListPage', { groupId });
  };

  return (
    <div className={s.MoreButtonWrapper} onClick={handleMoreMeetupReviews}>
      <div className={s.MoreButton}>
        <Typography typography="label3Regular" color="gray900">
          더보기
        </Typography>
        <VerticalSpacing size={2} />
        <IconChevronRightRegular size={14} />
      </div>
    </div>
  );
};

export default MeetupReviewsMoreButton;
