import { BoxButton, useSnackbarAdapter } from '@community-group/components';
import { Radio, RadioGroup } from '@daangn/sprout-components-radio-group';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getMyGroupListPath } from '@/api/hooks/useGetMyGroupList';
import { queryClient } from '@/api/instance';
import { PreviewResponse } from '@/api/nodejs/preview';

import { useWebviewSwitcher } from '../hooks/useWebviewSwitcher';
import * as s from './QuitGroup.css';

const NO_PREVIEW = 'no-preview';

const PreviewSetting = () => {
  const [open, setOpen] = useState(false);
  const { switchedOrigin, turnOn, turnOff } = useWebviewSwitcher();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(switchedOrigin ?? undefined);

  const snackbarAdapter = useSnackbarAdapter();

  const { data } = useQuery<PreviewResponse['urls']>(['previewList'], () =>
    fetch(`/api/previewable-webviews`).then((res) => res.json())
  );

  const items =
    (data &&
      data
        .filter((preview) => preview?.title && preview?.endpoints)
        ?.map((preview) => ({
          title: preview?.title,
          host: preview?.endpoints?.[0]?.host,
        }))
        .filter((el) => el)) ??
    [];

  useEffect(() => {
    if (items?.find((item) => item.host === switchedOrigin)) {
      setPreviewUrl(switchedOrigin);
    }
  }, [switchedOrigin]);

  if (import.meta.env.SSR) {
    return null;
  }

  const handleSetPreviewUrl = () => {
    if (!previewUrl || previewUrl === NO_PREVIEW) {
      turnOff();
      snackbarAdapter.create({
        message: '프리뷰 url이 해제 되었어요. 웹뷰를 닫고 다시 열어주세요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      return;
    }
    if (previewUrl) {
      turnOn(previewUrl);
      snackbarAdapter.create({
        message: '프리뷰 url이 설정 되었어요. 웹뷰를 닫고 다시 열어주세요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
    }
  };

  return (
    <div>
      <div
        className={s.TitleWrapper}
        onClick={() => {
          setOpen((prev) => !prev);
          queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
        }}
      >
        <h3>프리뷰 설정</h3>
        {open ? '▲' : '▼'}
      </div>
      {open && (
        <form className={s.FormWrapper}>
          <div className={s.GroupListWrapper}>
            <RadioGroup value={previewUrl} onChange={setPreviewUrl} size={'small'}>
              {items ? (
                items?.map((preview, index) => (
                  <>
                    {index === 0 && (
                      <Radio key={NO_PREVIEW} value={NO_PREVIEW}>
                        해제
                      </Radio>
                    )}
                    <Radio key={preview.host} value={preview.host}>
                      {preview.title}
                    </Radio>
                  </>
                ))
              ) : (
                <div>preview가 없습니다.</div>
              )}
            </RadioGroup>
          </div>
          <BoxButton onClick={handleSetPreviewUrl}>프리뷰 적용하기</BoxButton>
        </form>
      )}
    </div>
  );
};

export default PreviewSetting;
