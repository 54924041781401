import { BoxButton, useKeyboardState, useSnackbarAdapter } from '@community-group/components';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { usePostGroupChallenge } from '@/api/hooks/usePostGroupChallenge';

import { GroupChallengeBaseForm } from '../types';
import * as s from './GroupChallengeCreateAccessoryBar.css';

type Props = {
  groupId: string;
  formHandler: UseFormReturn<GroupChallengeBaseForm>;
};

const GroupChallengeCreateAccessoryBar = ({ groupId, formHandler }: Props) => {
  const snackbarAdapter = useSnackbarAdapter();

  const { opened: isOpenedKeyboard } = useKeyboardState();

  const isSubmitReady = useMemo(() => {
    const formData = formHandler.watch();

    const originImages = formData.originImages ?? [];

    if (originImages.find((image) => image.status !== 'completed')) return false;

    return true;
  }, [formHandler]);

  // TODO: 챌린지 mutate query 추가
  const { mutate, isLoading } = usePostGroupChallenge({});

  const validateCreateChallenge = ({ name, description }: GroupChallengeBaseForm) => {
    if (!name || name.length < 1) return { message: '챌린지 이름을 입력해주세요.', isValid: false };
    if (name.length > 24) return { message: '챌린지 이름은 최대 24자입니다.', isValid: false };

    if (description.length < 3)
      return { message: '본문이 너무 짧아요. 3자 이상 작성해주세요.', isValid: false };

    return { message: '', isValid: true };
  };

  const disabledButton = useMemo(() => {
    const title = formHandler.watch('name');
    const description = formHandler.watch('description');

    if ((!title || title.length === 0) && (!description || description.length === 0)) return true;

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHandler.watch('name'), formHandler.watch('description')]);

  const handleSubmit = () => {
    const currentForm = formHandler.getValues();
    const { originImages, ...form } = currentForm;

    // TODO: 챌린지 생성 유효성 검사
    const { isValid, message } = validateCreateChallenge(currentForm);

    if (!isValid) {
      return snackbarAdapter.create({
        message,
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
    }

    const groupChallengeCreateForm = {
      ...form,
      images: originImages.map((image) => image.id),
    };
    // TODO: 챌린지 생성 요청 로그
    console.log(groupChallengeCreateForm);
    mutate(
      {
        groupId: Number(groupId),
        groupChallengeCreateForm,
      },
      {
        onSuccess: ({ data }) => {
          if (!data.challengeId) return;
          // TODO: 챌린지 상세 페이지로 이동
          // replace('GroupChallengeDetailPage', {
          //   groupId,
          //   challengeId: data.challengeId.toString(),
          // });
        },
        onError: () => {
          snackbarAdapter.create({
            message: '챌린지 생성에 실패했어요. 잠시 후에 다시 시도해 주세요.',
            type: 'default',
            timeout: 3000,
          });
        },
      }
    );
  };

  return (
    <div
      className={s.Wrapper}
      style={{
        paddingBottom: isOpenedKeyboard
          ? `calc(0.5rem)`
          : `calc(env(safe-area-inset-bottom) + .5rem)`,
      }}
    >
      <BoxButton
        size="xlarge"
        width="100%"
        isDisabled={isLoading || !isSubmitReady || disabledButton}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        챌린지 만들기
      </BoxButton>
    </div>
  );
};

export default GroupChallengeCreateAccessoryBar;
