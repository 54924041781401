import { AsyncBoundary, encodeURL, Typography } from '@community-group/components';
import ReactPlayer from 'react-player/lazy';

import { useGetOpenGraph } from '@/api/hooks/useGetOpenGraph';
import { useBridge } from '@/contexts/Bridge';
import { isYoutubeLink } from '@/utils/check';
import { parseLink } from '@/utils/link';

import * as s from './index.css';

type Props = {
  text: string;
  type?: 'FEED' | 'DETAIL';
  margin?: string;
};

const OpenGraph = ({ text, type = 'FEED', margin }: Props) => {
  const url = parseLink(text);

  if (!url) return null;
  if (isYoutubeLink(url)) {
    return <ReactPlayer url={url} width="100%" height="100%" />;
  }

  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
      <OpenGraphWrapper url={url} type={type} margin={margin} />
    </AsyncBoundary>
  );
};

const OpenGraphWrapper = ({
  url,
  type,
  margin,
}: {
  url: string;
  type: 'FEED' | 'DETAIL';
  margin?: string;
}) => {
  const { data } = useGetOpenGraph(url);

  const { bridge } = useBridge();

  const hasOpenGraphData = data?.openGraph?.title;

  return hasOpenGraphData ? (
    <div style={{ margin, width: '100%' }}>
      <div className={s.Wrapper}>
        <div className={s.Divider} />
        <div className={s.ContentWrapper}>
          <div
            className={s.ContentTextWrapper}
            onClick={(e) => {
              e.stopPropagation();
              bridge.pushRouter({
                router: {
                  remote: encodeURL(data.openGraph.url),
                  navbar: true,
                  enableSafeAreaInsets: false,
                  backSwipable: true,
                },
              });
            }}
          >
            {data.openGraph.domain && (
              <Typography typography="bodyM2Regular" color="gray600" className={s.Url}>
                {data.openGraph.domain}
              </Typography>
            )}
            <Typography typography="bodyM1Bold" color="gray700" className={s.Title}>
              {data.openGraph.title}
            </Typography>
            {type === 'DETAIL' && (
              <Typography typography="subtitle2Regular" color="gray600" className={s.Description}>
                {data.openGraph.description}
              </Typography>
            )}
          </div>
          {data.openGraph.imageURI && <img className={s.Image} src={data.openGraph.imageURI} />}
        </div>
      </div>
    </div>
  ) : null;
};

export default OpenGraph;
