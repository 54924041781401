import { BoxButton } from '@community-group/components';
import { IconCloseRegular } from '@seed-design/icon';

import * as s from './BottomSheetForm.css';
interface Props {
  isLoading: boolean;
  title: string;
  buttonText: string;
  description?: string;
  buttonDisabled?: boolean;
  onSubmit: () => void;
  onClose?: () => void;
  children: React.ReactElement;
}
const BottomSheetForm = ({
  isLoading,
  title,
  description,
  buttonText,
  buttonDisabled,
  onSubmit,
  onClose,
  children,
}: Props) => {
  return (
    <div className={s.bottomSheetFormWrapper}>
      {onClose && (
        <div className={s.closeWrapper}>
          <button
            onClick={() => {
              onClose();
            }}
          >
            <IconCloseRegular size={24} />
          </button>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className={s.bottomSheetFormTitle}>{title}</div>
        {description && <p className={s.bottomSheetFormDescription}>{description}</p>}
        <div className={s.bottomSheetFormContent}>{children}</div>
        <BoxButton
          width="100%"
          isLoading={isLoading}
          size="xlarge"
          isDisabled={buttonDisabled}
          type={isLoading ? 'button' : 'submit'}
        >
          {buttonText}
        </BoxButton>
      </form>
    </div>
  );
};

export default BottomSheetForm;
