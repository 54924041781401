import { GroupCurrentUser } from '@community-group/api/lib/group/models';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const useCurrentGroupMe = (groupId?: string) => {
  const { groupId: pathGroupId } = usePathParams();
  const groupIdToUse = groupId || pathGroupId;
  const { data } = useGetGroupMe(groupIdToUse);
  const currentUser = data?.currentUser as GroupCurrentUser;

  return currentUser;
};

export default useCurrentGroupMe;
