import { GroupPermissionRoleEnum } from '@community-group/api/lib/group/models';
import { GroupPermissionsResponsePermissions } from '@community-group/api/lib/group/models/group-permissions-response-permissions';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { $Keys } from 'utility-types';

import { useGetPermission } from '@/api/hooks/useGetPermission';
import { usePutModifyGroupPermission } from '@/api/hooks/usePutModifyGroupPermision';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { ActivityQueryParams, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import { GROUP_ROLE_TO_TEXT } from '../GroupSettingPermissionPage';

export type GroupSettingEditPermissionBottomSheetParams = Pick<
  PageParams & ActivityQueryParams,
  'groupId' | 'targetPermission'
>;

const GroupSettingEditPermissionBottomSheet: ActivityComponentType<
  GroupSettingEditPermissionBottomSheetParams
> = () => {
  return (
    <BottomSheet
      style={{
        padding: '0',
      }}
      safeAreaInsetBottomRemove
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingEditPermissionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingEditPermissionBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { pop } = useFlow();
  const { targetPermission } = useQueryParams();

  const { data: permissionData } = useGetPermission(groupId);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate } = usePutModifyGroupPermission({
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '권한이 변경되었어요.',
        },
      });

      refetchGroupDetail({ groupId });
      refetchGroupSetting({ groupId });
      pop();
    },
    onError: handleErrorWithToast,
  });

  const handleModifyPermission = (role: GroupPermissionRoleEnum) => {
    const permission = targetPermission as $Keys<GroupPermissionsResponsePermissions>;

    mutate(
      {
        groupId,
        groupPermissionModifyRequest: {
          [permission]: role,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_edit_permission',
            params: {
              groupId,
              role,
              permission,
            },
          });
        },
      }
    );
  };

  const renderRoleList = () => {
    if (!permissionData) return null;

    const roleOrder = ['superHost', 'manager', 'member'];

    const sortedAbleRoles = permissionData[
      targetPermission as $Keys<GroupPermissionsResponsePermissions>
    ].ableRoles.sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b));

    return sortedAbleRoles.map((role) => {
      return (
        <ActionListItem key={role} onClick={() => handleModifyPermission(role)}>
          {GROUP_ROLE_TO_TEXT[role]}
        </ActionListItem>
      );
    });
  };

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>{renderRoleList()}</ActionList>
    </ActionSheet>
  );
};

export default GroupSettingEditPermissionBottomSheet;
