import { AccessStatusServiceEnum } from '@community-group/api/lib/group/models';
import axios from 'axios';

import { useFlow } from '@/stackflow';

type HandleErrorParams = {
  serviceType: AccessStatusServiceEnum;
  groupId?: string;
  meetupId?: string;
  postId?: string;
  error: any;
};

export const useHandleErrorWithThrowAccessStatusPage = () => {
  const { replace } = useFlow();

  const handleError = ({
    serviceType,
    groupId = '',
    meetupId = '',
    postId = '',
  }: Omit<HandleErrorParams, 'error'>) => {
    if (!import.meta.env.SSR) {
      replace('AccessStatusServiceHandlingPage', {
        serviceType,
        groupId,
        meetupId,
        postId,
      });
    }
  };

  return (params: HandleErrorParams) => {
    const { error, ...errorParams } = params;

    if (axios.isAxiosError(error)) {
      const errorStatus = error.response?.status;

      if (errorStatus === 404 || errorStatus === 403) {
        return handleError(errorParams);
      }
    }
  };
};
