import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import { ChipRadio, ChipRadioGroup, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCalendarRegular } from '@seed-design/icon';
import React from 'react';

import { GroupChallengeFormProps } from '../../../types';
import { GroupChallengeFormFieldWrapper } from '../FieldWrapper';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  dateFieldName: keyof GroupChallengeCreateForm;
  onClick?: () => void;
};

const GroupChallengeFormDurationField = ({ formHandler, dateFieldName, onClick }: Props) => {
  return (
    <GroupChallengeFormFieldWrapper
      onClick={onClick}
      fieldIcon={<IconCalendarRegular size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="진행 기간"
      fieldName={dateFieldName}
      fieldValue={
        <Typography textAlign="right" typography="label2Regular" color="gray900">
          {Math.floor(Number(formHandler.watch(dateFieldName)) / 7)}주
        </Typography>
      }
    >
      <div className={s.Wrapper}>
        <ChipRadioGroup
          value={formHandler.watch(dateFieldName)?.toString()}
          onChange={(value) => {
            formHandler.setValue(dateFieldName, Number(value));
          }}
        >
          <ChipRadio value="7">1주</ChipRadio>
          <ChipRadio value="14">2주</ChipRadio>
          <ChipRadio value="21">3주</ChipRadio>
          <ChipRadio value="28">4주</ChipRadio>
        </ChipRadioGroup>
      </div>
    </GroupChallengeFormFieldWrapper>
  );
};

export default GroupChallengeFormDurationField;
