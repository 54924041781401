import { VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconIosShareRegular, IconMoreVertRegular } from '@seed-design/icon';

import * as s from './style.css';

const MoreMenu = () => {
  return (
    <div className={s.MoreMenu}>
      <IconIosShareRegular size={24} color={vars.$scale.color.gray900} />
      <VerticalSpacing size={16} />
      <IconMoreVertRegular size={24} color={vars.$scale.color.gray900} />
    </div>
  );
};

export default MoreMenu;
