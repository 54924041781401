import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';
import throttle from 'lodash-es/throttle';

export const useThrottleMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
  wait?: number
) => {
  const mutation = useMutation<TData, TError, TVariables, TContext>(mutationFn, options);

  const throttleMutate = throttle(mutation.mutate, wait ?? 500);

  return {
    ...mutation,
    mutate: throttleMutate,
  };
};
