import { AsyncBoundary, BoxButton, Spacing, Typography } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { IconCloseRegular } from '@seed-design/icon';
import React, { PropsWithChildren } from 'react';

import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './RunningPhotoGuideBottomSheet.css';

const BAD_IMAGE =
  'https://assetstorage.krrt.io/1025317940251365954/f9ebdfeb-f191-4b3f-8bf5-33b7a1f1cbfa/width=513,height=513.png';
const GOOD_IMAGE =
  'https://assetstorage.krrt.io/1025317940251365954/903a8d9e-df1b-49db-9591-a46f87056dee/width=513,height=513.png';

const RunningPhotoGuideBottomSheet = () => {
  const { pop } = useFlow();

  const handlePop = () => {
    pop();
  };

  return (
    <BottomSheet
      style={{
        padding: 0,
      }}
    >
      <div className={s.Wrapper}>
        <Typography typography="title2Bold">러닝 인증샷을 자랑해주세요!</Typography>
        <Spacing size={6} />
        <Typography typography="bodyM1Regular" color="gray700">
          &apos;운동&apos; 카테고리로 개설된 모임만 참여할 수 있어요.
        </Typography>
        <Spacing size={28} />

        <div className={s.ImagesWrapper}>
          <div className={s.ImageWrapper}>
            <img src={BAD_IMAGE} alt="나쁜 예시 이미지" className={s.Image} />
          </div>
          <div className={s.ImageWrapper}>
            <img src={GOOD_IMAGE} alt="좋은 예시 이미지" className={s.Image} />
          </div>
        </div>
        <Spacing size={16} />
        <div className={s.SubInfoTextWrapper}>
          <ListItem>모임 참여자들의 얼굴을 가리지 않은 사진을 올려주세요.</ListItem>
          <ListItem>주제와 맞지 않는 글은 이벤트 참여가 취소될 수 있어요.</ListItem>
        </div>
        <Spacing size={16} />
        <div className={s.ButtonWrapper}>
          <BoxButton onClick={handlePop} variant="primary" width="100%" size="xlarge">
            알겠어요
          </BoxButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default RunningPhotoGuideBottomSheet;

const ListItem = ({ prefix = '∙', children }: PropsWithChildren<{ prefix?: string }>) => (
  <div className={s.ListItem}>
    <Typography typography="caption1Regular" color="gray600">
      {prefix}
    </Typography>
    <Typography typography="caption1Regular" color="gray600">
      {children}
    </Typography>
  </div>
);
