import { model } from '@community-group/api/lib/group';
import { AxiosInstance, AxiosResponse } from 'axios';

export const CATEGORIES_URL = 'api/v1/group-categories';

export const getCategories = async (axios: AxiosInstance) => {
  const { data } = await axios.get<undefined, AxiosResponse<model.CategoriesResponse>>(
    CATEGORIES_URL
  );

  return data;
};
