import { Spinner } from '../base';
import * as s from './ViewLoader.css';

type Props = { height?: number; backgroundColor?: string; size?: 'medium' | 'small' };

export const ViewLoader = ({ height = 200, size = 'medium' }: Props) => (
  <div className={s.view} style={{ minHeight: `${height}px` }}>
    <Spinner size={size} />
  </div>
);
