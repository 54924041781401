import { Spinner } from '@community-group/components';

import { useGetGroupMeetups } from '@/api/hooks/useGetGroupMeetups';
import { useGetMe } from '@/api/hooks/useGetMe';
import { Container } from '@/components/common/Container';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './RecommendMeetupLoader.css';

const RecommendMeetupLoader = () => {
  const { data: me } = useGetMe();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetGroupMeetups({
    regionId: me.regionCheckIns.regionId,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });
  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="primary" size="small" />
      </div>
    </Container>
  );
};

export default RecommendMeetupLoader;
