import { Tabs } from '@karrotframe/tabs';
import React, { useMemo, useRef } from 'react';
import { $Values } from 'utility-types';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { HOME_ACTIVE_TAB } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import ExplorerGroupTab from '../ExplorerGroupTab';
import MeetupTab from '../MeetupTab';
import MyGroupTab from '../MyGroupTab';
import * as s from './index.css';

export const MINIMUN_MY_GROUP_NUM = 3; // 홈탭 순서 결정을 위한 최소 joined my 그룹 수

const HomeContentsSections = ({
  activeTabKey,
  setActiveTabKey,
}: {
  activeTabKey: $Values<typeof HOME_ACTIVE_TAB>;
  setActiveTabKey: React.Dispatch<React.SetStateAction<$Values<typeof HOME_ACTIVE_TAB>>>;
}) => {
  const { data } = useGetMyGroupList();

  const myGroupTab = useMemo(() => {
    return {
      key: HOME_ACTIVE_TAB.MT_GROUP_TAB,
      buttonLabel: <span>내 모임</span>,
      buttonAriaLabel: '내 모임',
      render: () => {
        if (activeTabKey !== HOME_ACTIVE_TAB.MT_GROUP_TAB) return null;
        return <MyGroupTab />;
      },
    };
  }, [activeTabKey]);

  const explorerGroupTab = useMemo(() => {
    return {
      key: HOME_ACTIVE_TAB.EXPLORER_GROUP_TAB,
      buttonLabel: <span>전체 모임</span>,
      buttonAriaLabel: '전체 모임',
      render: () => {
        if (activeTabKey !== HOME_ACTIVE_TAB.EXPLORER_GROUP_TAB) return null;
        return <ExplorerGroupTab />;
      },
    };
  }, [activeTabKey]);

  const meetupTab = useMemo(() => {
    return {
      key: HOME_ACTIVE_TAB.MEETUP_TAB,
      buttonLabel: <span>모임 일정</span>,
      buttonAriaLabel: '모임 일정',
      render: () => {
        if (activeTabKey !== HOME_ACTIVE_TAB.MEETUP_TAB) return null;
        return <MeetupTab />;
      },
    };
  }, [activeTabKey]);

  const tabs = useMemo(() => {
    if (!data?.groups || data.groups.length === 0) {
      return [explorerGroupTab, meetupTab];
    }
    return [explorerGroupTab, meetupTab, myGroupTab];
  }, [explorerGroupTab, data, meetupTab, myGroupTab]);

  const ref = useRef<HTMLDivElement>(null);
  // tab 전환시 최상단으로 스크롤
  const scrollToTabTop = () => {
    const rect = ref.current?.getClientRects()[0];
    if (rect?.top && rect.top < 50) {
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  return (
    <div className={s.tabsWrapper} ref={ref}>
      <Tabs
        className="custom_tab"
        activeTabKey={activeTabKey}
        disableSwipe
        tabs={tabs}
        onTabChange={(key) => {
          scrollToTabTop();
          if (key !== activeTabKey) {
            if (key === HOME_ACTIVE_TAB.MT_GROUP_TAB)
              trackEvent({ event: 'click_home_tab_my_group_tab', sample: true });
            if (key === HOME_ACTIVE_TAB.MEETUP_TAB)
              trackEvent({ event: 'click_home_explorer_meetup_tab', sample: true });
            if (key === HOME_ACTIVE_TAB.EXPLORER_GROUP_TAB)
              trackEvent({ event: 'click_home_explorer_group_tab', sample: true });
          }

          setActiveTabKey(key as $Values<typeof HOME_ACTIVE_TAB>);
        }}
      />
    </div>
  );
};

export default React.memo(HomeContentsSections);
