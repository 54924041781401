import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export type SEARCH_MEETUP_ORDER_TYPE = 'created_at_desc' | 'meet_at_asc';

type Params = {
  searchQuery?: string;
  referrer?: string;
  limit?: number;
  order?: SEARCH_MEETUP_ORDER_TYPE;
};

export const useGetMeetupSearchList = (params: Params) => {
  const fetchInstance = useFetchInstance();
  const getMeetupSearchList = groupClient.api.GroupMeetupSearchApi.apiV1GroupMeetupSearchGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [
      groupClient.api.GroupMeetupSearchApi.getapiV1GroupMeetupSearchGetPath(
        params.searchQuery ?? '',
        20,
        params.referrer ?? '',
        params.order ?? 'meet_at_asc'
      ),
      params,
    ],
    ({ pageParam = undefined }) =>
      getMeetupSearchList(
        params.searchQuery ?? '',
        20,
        params.referrer ?? '',
        params.order ?? 'meet_at_asc',
        pageParam
      ),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.nextCursor ? data?.nextCursor : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
