import { postUploadImageV2 } from '@community-group/api';
import { ImageFieldItem, useHookFormImages } from '@community-group/components';
import { UseFormReturn } from 'react-hook-form';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';

import { FormType } from '../pages/UploadSeparateTab';
import { ImageField } from '../pages/VideoPOCPage.css';

type Props = {
  formMethods: UseFormReturn<FormType, any, undefined>;
};

function UploadedImageList({ formMethods }: Props) {
  const fetchCommunityInstance = useFetchInstance();
  const { bridge } = useBridge();

  const { images, removeImages, retryImage } = useHookFormImages({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleHookFormContext: () => formMethods,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) => postUploadImageV2({ imageFile, fetchCommunityInstance }),
    },
  });

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        padding: '10px 0',
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
      }}
    >
      {images.map(({ id, image }) => (
        <ImageFieldItem
          key={id}
          imageId={id}
          image={image}
          className={ImageField}
          onRemoveClick={() => {
            removeImages(id);
          }}
          onRetryClick={() => {
            retryImage(id);
          }}
        />
      ))}
    </div>
  );
}

export default UploadedImageList;
