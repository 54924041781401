import { UserProfileSnsTagPresentation } from '@community-group/api/lib/group/models';

import { SnsTagMySection } from './SnsTagMySection';
import { SnsTagOtherMemberSection } from './SnsTagOtherMemberSection';

type Props = {
  snsTags?: UserProfileSnsTagPresentation[];
  isMyProfile?: boolean;
};

export const SnsTagSection = ({ snsTags, isMyProfile }: Props) => {
  if (!snsTags) return <></>;

  if (isMyProfile) {
    return <SnsTagMySection snsTags={snsTags} />;
  }

  return <SnsTagOtherMemberSection snsTags={snsTags} />;
};
