import { Image as ImageType } from '@community-group/api/lib/group/models';
import { Image, Spacing } from '@community-group/components';
import { Fragment } from 'react/jsx-runtime';

import * as s from './index.css';

type Props = {
  images: ImageType[];
  onImageClick: (index: number) => void;
};

const ContentImageList = ({ images, onImageClick }: Props) => {
  return (
    <div className={s.ImageList}>
      {images.map((image, index) => {
        const isPortrait = image.width < image.height;
        const aspectRatio = isPortrait ? '3 / 4' : 'initial';
        const isLast = index === images.length - 1;

        return (
          <Fragment key={image.id}>
            <div className={s.ImageContainer} onClick={() => onImageClick(index)}>
              <Image
                src={image.medium}
                className={s.Image}
                style={{ aspectRatio }}
                alt={`${index + 1}번째 이미지`}
                aria-hidden="true"
              />
              <div className={s.ImageBorder} />
            </div>
            {!isLast && <Spacing size={16} />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default ContentImageList;
