import React, { CSSProperties, forwardRef } from 'react';

import { Typography } from '../../base';
import { useStickInputStore } from '..';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';
import { getPlainTextFromMention } from '../utils/mention';
import * as s from './InputSection.css';
import StickyMentionsInput from './MentionsInput';

type Props = {
  placeholder: string;
  mentionConfig: any;
  onFocusEvent: (e: React.MouseEvent) => void;
  type: 'new' | 'edit';
};

const InputSection = forwardRef(
  ({ placeholder, mentionConfig, onFocusEvent, type }: Props, ref: React.Ref<HTMLDivElement>) => {
    const { focused } = useStickInputStore();
    const { watch } = useStickyInputFormContext();
    const contentsValue = watch('content');

    const inputPositionStyle: CSSProperties = !focused
      ? {
          position: 'absolute',
          top: 'calc(-100vh + 200px)',
        }
      : {
          position: 'relative',
          top: '0',
        };

    return (
      <div className={s.Body} ref={ref}>
        <div
          style={{
            opacity: 1,
            position: 'relative',
            bottom: '0',
          }}
        >
          <div style={inputPositionStyle}>
            <StickyMentionsInput
              name="content"
              placeholder={placeholder}
              mentionConfig={mentionConfig}
            />
          </div>

          {!focused && (
            <div
              className={s.FakeInput}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onFocusEvent(e);
              }}
            >
              <Typography
                typography={'bodyM1Regular'}
                color="gray600"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  maxHeight: '1.25ren',
                }}
              >
                {contentsValue.length > 0
                  ? getPlainTextFromMention(contentsValue as string, 'string')
                  : placeholder}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default InputSection;
