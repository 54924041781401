import { groupClient } from '@community-group/api';
import { RequestGroupDirectChatConfirmForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type RequestParams = {
  groupDirectChatId: number;
  requestGroupDirectChatConfirmForm: RequestGroupDirectChatConfirmForm;
};

export type Props = UseMutationOptions<AxiosResponse<void>, Error, RequestParams>;

export const usePutUserChatRequestGroupDirectChats = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();

  const putUserChatRequestGroupDirectChats =
    groupClient.api.GroupDirectChatApi.apiV1UserChatRequestGroupDirectChatsRequestGroupDirectChatIdPut(
      {
        axios: fetchInstance,
      }
    );

  const putUserChatRequestGroupDirectChatsFp = ({
    groupDirectChatId,
    requestGroupDirectChatConfirmForm,
  }: RequestParams) =>
    putUserChatRequestGroupDirectChats(groupDirectChatId, requestGroupDirectChatConfirmForm);

  return useThrottleMutation(putUserChatRequestGroupDirectChatsFp, {
    onError,
    onSuccess,
  });
};
