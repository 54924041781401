import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

interface Props {
  groupId?: string;
}

export const getDeletableGroupPath = (groupId: number) =>
  groupClient.api.SettingsApi.getapiV1GroupsGroupIdSettingsDeletableGetPath(groupId);

const useGetDeletableGroup = ({ groupId }: Props) => {
  const fetchInstance = useFetchInstance();
  const getGetDeletableGroup = groupClient.api.SettingsApi.apiV1GroupsGroupIdSettingsDeletableGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useQuery(
    [getDeletableGroupPath(Number(groupId))],
    () => getGetDeletableGroup(Number(groupId)),
    {
      suspense: true,
      enabled: !!groupId,
    }
  );

  return {
    data: data?.data,
    refetch,
  };
};

export default useGetDeletableGroup;
