import { GroupDetailPresentation, PostDetail } from '@community-group/api/lib/group/models';
import { useMemo } from 'react';

import { Breadcrumbs as CommonBreadcrumbs } from '@/components/common/Breadcrumbs';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { shortening } from '@/utils/text';

import * as s from './Breadcrumbs.css';
import { PhotoContestBreadcrumbs } from './PhotoContestBreadcrumbs';

interface Props {
  post: PostDetail;
  group?: GroupDetailPresentation;
}

const PostDetailBreadcrumbs = ({ post, group }: Props) => {
  const { groupId } = usePathParams();
  const { push } = useFlow();

  const boardCategoryKey = useMemo(() => {
    const boardCategory = post.boardCategories?.[0];
    if (!boardCategory || boardCategory.type === 'default') return '0';

    return boardCategory.id.toString();
  }, [post.boardCategories]);

  const getBreadcrumbList = () => {
    const breadcrumbList: string[] = [];

    if (post?.postType?.type === 'meetupReview') {
      breadcrumbList.push('일정 후기');
      return breadcrumbList;
    }

    if (group?.isBoardManaged) {
      const boardName = shortening(post.boardCategories?.[0].category ?? '', 30);
      breadcrumbList.push(boardName);

      return breadcrumbList;
    }

    return breadcrumbList;
  };

  // TODO: 모임사진전

  const handleClickPhotoContest = () => {
    push('PhotoContestPage', {});
  };

  if (!group || getBreadcrumbList().length === 0) {
    if (post?.isPhotoContestEntry) {
      return <PhotoContestBreadcrumbs onClick={handleClickPhotoContest} text="전국모임자랑" />;
    }

    return null;
  }

  return (
    <div className={s.Wrapper}>
      {post?.isPhotoContestEntry && (
        <PhotoContestBreadcrumbs onClick={handleClickPhotoContest} text="전국모임자랑" />
      )}
      <CommonBreadcrumbs
        prefixIconType="post"
        items={getBreadcrumbList()}
        onClick={() => {
          push('GroupDetailPage', {
            groupId,
            activeTab: 'feed',
            boardCategoryKey,
          });
        }}
      />
    </div>
  );
};

export default PostDetailBreadcrumbs;
