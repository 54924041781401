import { GroupSummaryPresentation } from '@community-group/api/lib/group/models';

import { StoreSlice } from '..';

export type PostSlice = {
  editMode: boolean;
  editForm: {
    postId?: number;
    postContent?: string;
    isGroupOnly?: boolean;
    talkId?: number;
    talkContent?: string;
    meetupId?: number;
  };
  replyMode: boolean;
  replyForm: {
    authorId?: number;
    authorName?: string;
  };
  trigger: boolean;
  recommendGroup: GroupSummaryPresentation[];
  setTrigger: (trigger: boolean) => void;
  setEditMode: (
    edit: boolean,
    {
      postId,
      postContent,
      isGroupOnly,
      talkId,
      talkContent,
      meetupId,
    }: {
      postId?: number;
      postContent?: string;
      isGroupOnly?: boolean;
      talkId?: number;
      talkContent?: string;
      meetupId?: number;
    }
  ) => void;
  setReplyForm: (
    reply: boolean,
    {
      authorId,
      authorName,
    }: {
      authorId?: number;
      authorName?: string;
    }
  ) => void;
  setRecommendGroup: (recommendGroup: GroupSummaryPresentation[]) => void;
};

export const createPostSlice: StoreSlice<PostSlice> = (set) => ({
  editMode: false,
  replyMode: false,
  trigger: false,
  editForm: {
    postId: undefined,
    postContent: undefined,
    isGroupOnly: undefined,
    talkId: undefined,
    talkContent: undefined,
    meetupId: undefined,
  },
  replyForm: {
    authorId: undefined,
    authorName: undefined,
  },
  recommendGroup: [],
  setEditMode: (editMode, editForm) => {
    set((state) => ({
      ...state,
      editMode: editMode,
      editForm: {
        ...state.editForm,
        ...editForm,
      },
    }));
  },
  setReplyForm: (replyMode, replyForm) => {
    set((state) => ({
      ...state,
      replyMode: replyMode,
      replyForm: {
        ...state.replyForm,
        ...replyForm,
      },
    }));
  },
  setTrigger: (trigger) => {
    set((state) => ({
      ...state,
      trigger,
    }));
  },
  setRecommendGroup: (recommendGroup: GroupSummaryPresentation[]) => {
    set((state) => {
      return { ...state, recommendGroup };
    });
  },
});
