import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getParentingGroupKidsPath = (groupId: number) =>
  groupClient.api.ParentingGroupApi.getapiV1GroupsGroupIdParentingKidsGetPath(groupId);

export const useGetParentingGroupKids = (groupId: string, disabled?: boolean) => {
  const fetchInstance = useFetchInstance();
  const getKids = groupClient.api.ParentingGroupApi.apiV1GroupsGroupIdParentingKidsGet({
    axios: fetchInstance,
  });

  const id = Number(groupId);
  const { data, ...response } = useQuery([getParentingGroupKidsPath(id)], () => getKids(id), {
    suspense: true,
    enabled: !disabled,
  });

  return {
    data: data?.data,
    ...response,
  };
};
