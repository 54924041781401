// upload.ts
import { UploadVideoResponse } from '../types/Video';

// 업로드 상태 타입 정의
export type UploadStatus = 'idle' | 'uploading' | 'completed' | 'failed';

// 각 파일의 업로드 상태를 관리하기 위한 타입
export interface FileUploadState {
  id: string;
  file: File;
  thumbnail: string | null;
  status: UploadStatus;
  progress: number;
  error?: string;
  videoInfo?: {
    id: string;
    duration: number;
    thumbnail: string;
  };
}

const API_BASE_URL = globalThis.appConfig.apiEndPoints.community;

interface UploadVideoParams {
  fetchInstance: any;
  file: File;
  onProgress?: (event: ProgressEvent) => void;
}

// 업로드 함수
export const uploadVideo = async ({
  fetchInstance,
  file,
  onProgress,
}: UploadVideoParams): Promise<UploadVideoResponse> => {
  // 업로드 URL을 가져오기 위한 API 호출
  const response = await fetchInstance.post(`${API_BASE_URL}/api/v1/resource/videos`);

  if (response.status !== 200) {
    throw new Error('Failed to get upload URL');
  }

  const { id, url } = response.data;

  // 업로드 시작
  const formData = new FormData();
  formData.append('file', file);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);

    // 진행 상황 업데이트
    xhr.upload.onprogress = (event) => {
      if (onProgress && event.lengthComputable) {
        onProgress(event);
      }
    };

    xhr.onload = async () => {
      if (xhr.status === 200) {
        const result: UploadVideoResponse = JSON.parse(xhr.responseText);
        resolve({ ...result, videoId: id });
      } else {
        reject(new Error('Upload failed'));
      }
    };

    xhr.onerror = () => reject(new Error('Upload failed'));
    xhr.send(formData);
  });
};
