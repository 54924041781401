import clsx from 'clsx';

import * as s from './ListBasic.css';

// SIZE는 small, prefix는 medium만 구현 추후 필요하면 추가할 것
type Props = {
  size?: 'small';
  description?: string;
  prefixIcon: React.ReactNode;
  text: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const ListBasic = ({ size, description, prefixIcon, text, ...props }: Props) => (
  <div {...props} className={clsx([props.className, s.wrapper])}>
    {prefixIcon}
    <div className={s.contents}>
      <p className={s.text}>{text}</p>
      {description && <p className={s.description}>{description}</p>}
    </div>
  </div>
);
