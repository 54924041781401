import { AsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback, useEffect, useMemo } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';

import { usePostJoinRoom } from '@/api/hooks/apartment/usePostJoinRoom';
import { FormInput } from '@/components/common/base/Input/Text';
import BottomSheetForm from '@/components/common/Form/components/BottomSheetForm';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';
import { refetchGroupRooms } from '@/utils/refetch/rooms';
export type GroupChatRoomPrivateBottomSheetParams = Pick<PageParams, 'groupId' | 'roomId'>;

const GroupChatRoomPrivateBottomSheet: ActivityComponentType<
  GroupChatRoomPrivateBottomSheetParams
> = () => {
  return (
    <BottomSheet style={{ padding: '0' }}>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupChatRoomPrivateBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupChatRoomPrivateBottomSheetWrapper = () => {
  const handleErrorWithToast = useHandleErrorWithToast();
  const { pop } = useFlow();
  const { groupId, roomId } = usePathParams();
  const { bridge } = useBridge();
  const { mutate: mutateJoinRoom, isLoading } = usePostJoinRoom({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async ({ data }) => {
      refetchGroupRooms({ groupId: groupId ?? '' });
      trackEvent({
        event: 'click_join_talk_room',
        params: {
          groupId: groupId?.toString() ?? '',
          roomId: roomId?.toString() ?? '',
          isPrivate: true,
        },
      });
      bridge.openToast({
        toast: {
          body: `채팅방에 입장했어요.`,
        },
      });
      await pop();

      openLink(data.talkRoom.channelInfo?.targetUri);
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      password: '',
    },
  });

  const { password } = useWatch({
    control,
  });

  const onSubmit = useCallback(
    ({ password }: FieldValues) => {
      if (isLoading) {
        return;
      }
      mutateJoinRoom({
        id: Number(groupId),
        roomId: Number(roomId),
        talkRoomJoinForm: {
          password,
        },
      });
    },
    [groupId, isLoading, mutateJoinRoom, roomId]
  );

  const renderBottomSheetForm = useMemo(() => {
    return (
      <BottomSheetForm
        isLoading={isLoading}
        title="비공개 채팅방이에요"
        description="초대 코드를 입력해주세요."
        buttonText="참여하기"
        buttonDisabled={password?.length === 0 || !isDirty || isLoading}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => {
          pop();
        }}
      >
        <FormInput
          name="password"
          register={register}
          placeholder={'초대 코드'}
          autoFocus
          disabled={isLoading}
        />
      </BottomSheetForm>
    );
  }, [handleSubmit, isDirty, isLoading, onSubmit, password?.length, pop, register]);

  useEffect(() => {
    refetchGroupRooms({ groupId: groupId ?? '' });
  }, [groupId]);

  return renderBottomSheetForm;
};

export default GroupChatRoomPrivateBottomSheet;
