import {
  AsyncBoundary,
  CoveredLazyLoadImage,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './SelectGroupBeforeVerificationChange.css';

const SelectGroupBeforeVerificationChange: ActivityComponentType = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <SelectGroupBeforeVerificationChangeView />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const SelectGroupBeforeVerificationChangeView = () => {
  const { push, pop } = useFlow();
  const { data: myGroups } = useGetMyGroupList();

  const hostedGroups = myGroups?.groups.filter((group) => group.isHost) ?? [];

  useEnterTrackEvent({
    event: 'enter_create_group_meetup_bottom_sheet',
  });

  if (hostedGroups.length === 0) {
    return (
      <div className={s.view}>
        <Typography as="h1" typography="title2Bold" color="gray900">
          본인인증 모임으로 변경할 수 있는 모임이 없어요.
        </Typography>
      </div>
    );
  }

  return (
    <div className={s.view}>
      <Typography as="h1" typography="title2Bold" color="gray900">
        어떤 모임을 변경할까요?
      </Typography>
      <ul className={s.ul}>
        {hostedGroups.map((group) => {
          return (
            <li
              className={s.li}
              key={group?.id}
              onClick={() => {
                pop();
                push('GroupSettingEditVerification', {
                  groupId: group?.id.toString(),
                });
              }}
            >
              <CoveredLazyLoadImage
                src={group?.profileImage.small}
                width={40}
                height={40}
                isLazy={false}
                style={{
                  borderRadius: '0.375rem',
                }}
              />
              <Typography typography="bodyM1Regular" color="gray900">
                {group?.name}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectGroupBeforeVerificationChange;
