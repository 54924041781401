import {
  GroupLevelUpMissions,
  MissionAchieveCountPresentation,
} from '@community-group/api/lib/group/models';
import { Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCheckFill, IconChevronRightFill } from '@seed-design/icon';

import { MISSION_INFO } from '@/components/group/GroupLevel/utils/missions';

import * as s from './MissionItem.css';

type Props = {
  missionId: keyof GroupLevelUpMissions;
  missionStatus: MissionAchieveCountPresentation;
  onClickMission: (missionId: keyof GroupLevelUpMissions, onlyHost: boolean) => void;
};

function MissionItem({ missionId, missionStatus, onClickMission }: Props) {
  const missionInfo = MISSION_INFO[missionId];
  const successMission = missionStatus.currentCount >= missionStatus.achieveCount;
  const Icon = missionInfo.icon;

  const handleClickMission = () => {
    onClickMission(missionId, missionInfo.onlyHost);
  };

  return (
    <div className={s.Content({ successMission })} onClick={handleClickMission}>
      <div className={s.MissionStateWrapper}>
        <Icon
          size={16}
          color={successMission ? vars.$semantic.color.success : vars.$scale.color.gray700}
        />
        <VerticalSpacing size={12} />
        <Typography typography="subtitle2Regular" color={successMission ? 'green700' : 'gray900'}>
          {missionInfo.title}
        </Typography>
      </div>
      <VerticalSpacing size={12} />
      <div className={s.MissionStateWrapper}>
        <Typography typography="caption1Regular" color={successMission ? 'success' : 'gray600'}>
          {missionStatus.currentCount}/{missionStatus.achieveCount}
        </Typography>
        <VerticalSpacing size={8} />
        {successMission ? (
          <IconCheckFill size={16} color={vars.$semantic.color.success} />
        ) : (
          <IconChevronRightFill size={16} color={vars.$scale.color.gray600} />
        )}
      </div>
    </div>
  );
}

export default MissionItem;
