import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import React from 'react';

import { ContentItem, GuideContentData } from './GuideContents';
import * as s from './index.css';

//등급 산정 안내 섹션
const LevelCalculationGuide = ({ currentLevel }: { currentLevel: number }) => {
  const renderItems = (items: ContentItem[]) => {
    return (
      <ul className={s.UlStyle}>
        {items.map((item, index) => {
          const { title, content, table: Table, component } = item as ContentItem;
          return (
            <div className={s.IncludedContentsWrapper} key={index}>
              {title && (
                <li className={s.LiStyle}>
                  <Typography
                    typography="caption1Regular"
                    color="gray700"
                    className={s.InnerTextWrapper}
                  >
                    •
                  </Typography>
                  <VerticalSpacing size={6} />
                  <Typography
                    typography="caption1Regular"
                    color="gray700"
                    className={s.InnerTextWrapper}
                  >
                    {title}
                  </Typography>
                </li>
              )}
              {Table && <Table currentLevel={currentLevel} />}
              {component && <div className={s.ComponentsWrapper}>{component}</div>}
              {content && renderItems(content)}
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <div className={s.Container}>
      <Typography typography="title3Bold" color="gray900" className={s.SectionTitle}>
        모임 레벨 안내
      </Typography>
      <Spacing size={24} />
      {GuideContentData.map((section, index) => (
        <div key={index} style={{ marginBottom: '.875rem' }}>
          {section.title && (
            <Typography typography="subtitle2Bold" color="gray700" className={s.ContentsTitle}>
              {section.title}
            </Typography>
          )}
          {section.content && renderItems(section.content)}
        </div>
      ))}
    </div>
  );
};

export default LevelCalculationGuide;
