import { useEffectOnce } from '@community-group/components';

import {
  LocationEventLogRequest,
  usePutEventLogLocation,
} from '@/api/hooks/usePutEventLogLocation';

export const useEnterLocationEventLog = (params: LocationEventLogRequest) => {
  const { mutate } = usePutEventLogLocation();

  useEffectOnce(() => {
    mutate(params);
  });
};
