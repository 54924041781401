import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import {
  IconAndroidShareRegular,
  IconChattingSendRegular,
  IconIosShareRegular,
} from '@seed-design/icon';
import { useMemo } from 'react';

import { useDeviceConfig } from '@/contexts/DeviceConfig';

type Props = Parameters<typeof IconAndroidShareRegular>[0];

export const IconShareRegular = (props: Props) => {
  const { deviceConfig } = useDeviceConfig();
  const isAndroid = useMemo(() => deviceConfig.theme === 'android', [deviceConfig.theme]);

  return isAndroid ? <IconAndroidShareRegular {...props} /> : <IconIosShareRegular {...props} />;
};

type ExperimentProps = { currentUserRole?: GroupMemberRoleEnum } & Props;

export const IconShareRegularExperiment = ({ currentUserRole, ...props }: ExperimentProps) => {
  const isControl = useMemo(() => {
    const memberRole = currentUserRole ?? 'none';
    const isNotMember = memberRole === 'none';

    return isNotMember;
  }, [currentUserRole]);

  return isControl ? <IconShareRegular {...props} /> : <IconChattingSendRegular {...props} />;
};
