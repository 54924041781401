import {
  ActionableCallout,
  AsyncBoundary,
  CalloutDescription,
  CalloutTitle,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { RefObject, Suspense, useCallback } from 'react';

import useLeaderBoard from '@/components/group/GroupLeaderBoard/hooks/useLeaderBoard';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { trackEvent } from '@/utils/analytics';
import { formatDate } from '@/utils/date';
import { openLink } from '@/utils/link';

import { BannerSection } from './BannerSection';
import { ExplorerGroupTabPhotoContestBanner } from './BannerSection/PhotoContestBanner';
import ExplorerGroupListSection from './ExplorerGroupListSection';
import { ExplorerGroupListTitle } from './ExplorerGroupListSection/ExplorerGroupListTitle';
import LeaderBoardSection from './LeaderBoardSection';
import RecommendCurationGroupSection from './RecommendCurationGroupSection';
import RecommendNewGroupSection from './RecommendNewGroupSection';

const ExplorerGroupTab = ({
  explorerGroupSpacingRef,
}: {
  explorerGroupSpacingRef?: RefObject<HTMLDivElement>;
}) => {
  const { isOpenedLeaderBoard } = useLeaderBoard();
  const shownPhotoContest = useFeatureFlag('shownPhotoContest');

  const Banner = useCallback(() => {
    const NoticeBanner = (
      <div
        onClick={() => {
          trackEvent({
            event: 'click_home_banner_notice',
            params: {
              type: 'new_share_feature_banner',
            },
            sample: true,
          });
          openLink('https://www.daangn.com/wv/faqs/14487');
        }}
        style={{
          padding: '1rem 1rem 0.5rem 1rem',
        }}
      >
        <ActionableCallout width={'100%'}>
          <CalloutTitle>공지</CalloutTitle>
          <CalloutDescription>모임의 새로운 모습을 안내드려요.</CalloutDescription>
        </ActionableCallout>
      </div>
    );

    if (shownPhotoContest) {
      // 홀수 분이면 true
      const isShow = parseInt(formatDate(new Date(), 'mm')) % 2 === 0;

      return isShow ? <ExplorerGroupTabPhotoContestBanner /> : NoticeBanner;
    }

    return NoticeBanner;
  }, []);

  return (
    <div style={{ minHeight: '100vh', background: vars.$semantic.color.paperDefault }}>
      <BannerSection />
      {isOpenedLeaderBoard && (
        <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
          <LeaderBoardSection />
        </AsyncBoundary>
      )}
      <RecommendCurationGroupSection />
      <RecommendNewGroupSection />
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <ExplorerGroupListSection appendTop={<ExplorerGroupListTitle />} />
      </AsyncBoundary>
    </div>
  );
};

export default ExplorerGroupTab;
