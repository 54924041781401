import { GroupPermissionsResponsePermissions } from '@community-group/api/lib/group/models';
import { $Keys } from 'utility-types';

import useCurrentGroupMe from './useCurrentGroupMe';

const useCheckHasPermission = (permission: $Keys<GroupPermissionsResponsePermissions>) => {
  const currentUser = useCurrentGroupMe();

  if (currentUser.permissions[permission] === true) {
    return true;
  }
  return false;
};

export default useCheckHasPermission;
