import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetJustStartedGroupList } from '@/api/hooks/useGetJustStartedGroupList';
import FloatingIcon from '@/components/common/FloatingIcon';
import GroupInfoListViewItem from '@/components/common/GroupInfoListItem/ListView';
import { View } from '@/components/common/View';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';
import JustStartedGroupLoader from './JustStartedGroupLoader';
import { useInitialCreateGroupLLMExperiment } from '@/components/group/new/hooks/useInitialCreateGroupLLMExperiment';

const JustStartedGroupMorePage: ActivityComponentType = () => {
  // 2023-11 앰플리튜드 샘플링으로 주석처리
  // useEnterTrackEvent('enter_just_started_group_more_page');

  return (
    <AppScreen appBar={{ title: '새로운 모임' }}>
      <View>
        <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
          <JustStartedGroupMoreView />
        </AsyncBoundary>
      </View>
    </AppScreen>
  );
};

export default JustStartedGroupMorePage;

const JustStartedGroupMoreView = () => {
  const { push } = useFlow();

  const { data, hasNextPage } = useGetJustStartedGroupList({});
  const { handleActivityCreateGroupLLMExperiment } = useInitialCreateGroupLLMExperiment({});

  const groupList = data?.pages.map(({ data }) => data.groups) ?? [];
  const flattenGroupList = groupList.flat(1);

  return (
    <>
      <div className={s.wrapper}>
        {flattenGroupList.map((group, index) => (
          <GroupInfoListViewItem
            key={group?.id}
            group={group}
            config={{
              enableLazyLoadImage: false,
            }}
            onClick={() => {
              push('GroupDetailPage', {
                groupId: group?.id.toString(),
              });
              // 2023-11 앰플리튜드 샘플링으로 주석처리
              trackEvent({
                event: 'click_just_started_group',
                params: {
                  groupId: group?.id,
                  groupName: group?.name,
                  groupCategoryName: group?.category?.name,
                  listItemIndex: index,
                },
                sample: true,
              });
            }}
          />
        ))}
        {hasNextPage && <JustStartedGroupLoader />}
        <div className={s.safeAreaBottom} />
      </div>
      <FloatingIcon
        onClick={() => {
          handleActivityCreateGroupLLMExperiment();
        }}
      />
    </>
  );
};
