import { vars } from '@seed-design/design-token';

const defaultStyle = {
  control: {
    position: 'relative',
    backgroundColor: vars.$scale.color.gray100,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  highlighter: {
    lineHeight: '1.35rem',
  },
  input: {
    margin: 0,
    overflow: 'hidden',
    height: 'auto',
    caretColor: vars.$scale.color.gray900,
  },
  suggestions: {
    bottom: '.4375rem',
    top: 'unset',
    zIndex: 999,
    position: 'absolute',
    list: {
      width: '100vw',
      backgroundColor: vars.$semantic.color.paperDefault,
      fontSize: '1rem',
      marginBottom: '0px',
      fontWeight: 400,
      maxHeight: '12.375rem',
      overflow: 'auto',
      lineHeight: '1.375rem',
      zIndex: 999,
    },
    item: {
      padding: '0.6875rem 1rem',
      backgroundColor: vars.$semantic.color.paperDefault,
      '&first-child': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '&focused': {
        backgroundColor: vars.$semantic.color.grayPressed,
      },
    },
  },
};

export const StickyInputDefaultStyle = {
  ...defaultStyle,
  maxHeight: '6.5rem',
  '&multiLine': {
    control: {},
    highlighter: {
      lineHeight: '1.35rem',
      letterSpacing: '-0.02rem',
    },
    input: [
      {
        width: '100%',
        outline: 0,
        border: 0,
        color: vars.$scale.color.gray900,
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.35rem',
        padding: 0,
        userSelect: 'text',
        letterSpacing: '-0.02rem',
      },
    ],
  },
  suggestions: {
    ...defaultStyle.suggestions,
  },
};

export const StickyInputDefaultMentionStyle = {
  backgroundColor: vars.$scale.color.blueAlpha100,
};

export const CommentInputDefaultStyle = {
  ...defaultStyle,
  '&multiLine': {
    control: {
      backgroundColor: vars.$scale.color.gray100,
      fontSize: '1rem',
      fontWeight: 'normal',
      overflow: 'hidden',
      maxHeight: '6.5rem',
    },
    highlighter: {
      lineHeight: '1.35rem',
      padding: '0.7rem 2.3rem 0.7rem 1rem',
    },
    input: {
      width: '100%',
      outline: 0,
      border: 0,
      color: vars.$scale.color.gray900,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.35rem',
      padding: 0,
      userSelect: 'text',
    },
  },
  suggestions: {
    ...defaultStyle.suggestions,
    bottom: '32px',
    list: {
      ...defaultStyle.suggestions.list,
      marginBottom: '0px',
    },
  },
};

export const CommentInputDefaultMentionStyle = {
  backgroundColor: vars.$scale.color.blueAlpha100,
};
