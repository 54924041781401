import { vars } from '@seed-design/design-token';
import React, { useState } from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import useValidAgeRangeInput from '../hooks/use-validate-age-range-input';
import * as s from './AgeRangeInput.css';

/**
 * @FigmaURL
 * https://www.figma.com/file/ty7UxJ61CVPeVU2Gf1LJGQ/Components?node-id=888%3A0
 */
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  valueName: 'minAge' | 'maxAge';
  disable: boolean;
}

export const AgeInput = ({ watch, setValue, valueName, disable }: Props) => {
  const { validateMinValue, validateMaxValue } = useValidAgeRangeInput({ watch });
  const validation = valueName === 'minAge' ? validateMinValue : validateMaxValue;
  const [focused, setFocused] = useState(false);
  const [focusing, setFocusing] = useState(false);

  const ageRangeFieldValue = watch('ageRange');

  const handleFocus = () => {
    setFocused(true);
    setFocusing(true);
  };

  const handleBlur = (key: 'minAge' | 'maxAge') => {
    setFocusing(false);
    const prevValue = ageRangeFieldValue?.[key];
    if (prevValue === undefined)
      setValue('ageRange', {
        ...ageRangeFieldValue,
        [key]: '',
      });
  };

  const handleInputChange =
    (key: 'minAge' | 'maxAge') => (event: React.FormEvent<HTMLInputElement>) => {
      const prevValue = ageRangeFieldValue;
      const { value } = event.currentTarget;
      if (isNaN(parseInt(value, 10)) || value === '')
        return setValue('ageRange', {
          ...prevValue,
          [key]: '',
        });

      setValue('ageRange', {
        ...prevValue,
        [key]: parseInt(value, 10),
      });
    };
  const helperText = validation().helperText;

  const isError = () => {
    if (disable) return false;
    if (focusing) return false;
    if (focused && !validation().isValid) return true;
    return focused && !validation().isValid;
  };

  const getInputWrapperBackgroundColor = (isError, disable) => {
    if (disable) return vars.$scale.color.gray100;
    if (isError) return vars.$semantic.color.dangerLow;
    return vars.$semantic.color.paperDefault;
  };

  const getInputWrapperBorderColor = (isError, isFocus) => {
    if (isError) {
      return vars.$semantic.color.danger;
    }
    if (isFocus) {
      return vars.$scale.color.gray900;
    }

    return vars.$scale.color.gray400;
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        className={s.inputWrapper}
        style={{
          backgroundColor: getInputWrapperBackgroundColor(isError(), disable),
          border: `0.0625rem solid ${getInputWrapperBorderColor(isError(), focusing)}`,
        }}
      >
        <input
          className={s.input}
          type="number"
          pattern="[0-9]*"
          min="1"
          inputMode="numeric"
          onFocus={handleFocus}
          onBlur={() => handleBlur(valueName)}
          value={ageRangeFieldValue?.[valueName] ?? ''}
          onChange={handleInputChange(valueName)}
          disabled={disable}
        />
        <span className={s.endAdornment}>{'세'}</span>
      </div>
      <div className={s.contentsWrapper}>
        <p
          className={s.helperText}
          style={{
            color: !validation().isValid ? vars.$semantic.color.danger : vars.$scale.color.gray600,
          }}
        >
          {helperText}
        </p>
      </div>
    </div>
  );
};

export default AgeInput;
