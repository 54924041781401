import clsx from 'clsx';
import React from 'react';

import * as s from './style.css';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

const EmptySection = ({ children, ...props }: Props) => {
  return (
    <div {...props} className={clsx([props?.className, s.emptyWrapper])}>
      {children}
    </div>
  );
};

export default EmptySection;
