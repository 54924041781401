import React from 'react';

import * as s from './item.css';

type OptimizeImage = {
  id: string;
  src: string;
  width: number;
  height: number;
};

type Props = {
  image: OptimizeImage;
  aspectRatio: string;
  alt?: string;
};

function ImageItem({ image, aspectRatio, alt = '게시글 첨부 이미지' }: Props) {
  return (
    <div className={s.ImageContainer}>
      <div className={s.ImageBorder} />
      <img src={image.src} className={s.Image} style={{ aspectRatio }} alt={alt} />
    </div>
  );
}

export default ImageItem;
