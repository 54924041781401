import { IconCategoryGroup, Typography } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';

import { useFlow } from '@/stackflow';

import * as s from './index.css';

const GotoGroupHomeBanner = ({ isExistHeroSliderPhotos }: { isExistHeroSliderPhotos: boolean }) => {
  const { push } = useFlow();

  const handleGotoGroupHome = () => {
    push('HomePage', {
      from: 'NotMemberGroupDetail',
    });
  };
  return (
    <div
      className={s.Banner({ isPaperColorBackground: !isExistHeroSliderPhotos })}
      onClick={handleGotoGroupHome}
    >
      <div className={s.TextWrapper}>
        <IconCategoryGroup size={18} color="#EA8140" />
        <Typography typography="caption1Bold" color="gray700">
          당근 모임 바로가기
        </Typography>
      </div>
      <IconChevronRightFill size={16} />
    </div>
  );
};

export default GotoGroupHomeBanner;
