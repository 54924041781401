import addMinutes from 'date-fns/addMinutes/index';
import { useEffect, useState } from 'react';

import { formatDate } from '../../utils';
import { DAY_PERIOD, ScrollableDayPeriodSelect } from './ScrollableDayPeriodSelect';
import { HOURS, MINUTES, ScrollableTimeSelect } from './ScrollableTimeSelect';
import * as s from './style.css';

/**
 * @Reference
 * https://daangn.slack.com/archives/CGDR2PPM2/p1646297830341699?thread_ts=1646292670.169459&cid=CGDR2PPM2
 */
interface Props {
  timeAt?: string;
  itemHeight?: number;
  itemGap?: number;
  onChange: (timeAt: string) => void;
}

export const TimePicker = ({
  timeAt: defaultTimeAt,
  itemHeight = 46,
  itemGap = 17,
  onChange: handleChange,
}: Props) => {
  const [mounted, setMounted] = useState(false);
  const currentDate = ceilToNearestMinutes(defaultTimeAt ? new Date(defaultTimeAt) : new Date());
  const currentOnlyDate = formatDate(currentDate, 'yyyy-MM-dd');

  const [selectedTime, setSelectedTime] = useState<TimeData>({
    dayPeriod: formatDate(currentDate, 'a') as (typeof DAY_PERIOD)[number],
    hour: Number(formatDate(currentDate, 'h')) as (typeof HOURS)[number],
    minutes: Number(formatDate(currentDate, 'mm')) as (typeof MINUTES)[number],
  });

  const handleSelectedTime = (key: keyof TimeData) => (value: any) => {
    setSelectedTime((prevSelectedTime) => ({
      ...prevSelectedTime,
      [key]: value,
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    });

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (selectedTime?.hour !== undefined && selectedTime.minutes !== undefined) {
      const convertHour = convert12To24Hour(selectedTime);

      if (mounted) {
        handleChange(`${currentOnlyDate}T${convertHour}`);
      }
    }
  }, [selectedTime]);

  return (
    <div className={s.Wrapper}>
      <div className={s.ScrollableWrapper} style={{ height: `${itemHeight * 3 + itemGap * 2}px` }}>
        <ScrollableDayPeriodSelect
          selectedDayPeriod={selectedTime.dayPeriod}
          onChange={handleSelectedTime('dayPeriod')}
        />
        <div className={s.ScrollableTimeWrapper}>
          <ScrollableTimeSelect
            height={itemHeight}
            gap={itemGap}
            type="HOURS"
            selectedTime={selectedTime.hour}
            onChange={handleSelectedTime('hour')}
          />
          <ScrollableTimeSelect
            height={itemHeight}
            gap={itemGap}
            type="MINUTES"
            selectedTime={selectedTime.minutes}
            onChange={handleSelectedTime('minutes')}
          />
        </div>
      </div>
    </div>
  );
};

interface TimeData {
  dayPeriod: (typeof DAY_PERIOD)[number];
  hour: (typeof HOURS)[number];
  minutes: (typeof MINUTES)[number];
}

const CEIL_MINUTES = 10;
const ceilToNearestMinutes = (date: Date) => {
  const minutes = formatDate(date, 'mm');
  const diffCeilMinutes = CEIL_MINUTES - (Number(minutes) % CEIL_MINUTES);

  const calcDateAtNearestMinutes =
    diffCeilMinutes === CEIL_MINUTES ? date : addMinutes(date, diffCeilMinutes);

  return calcDateAtNearestMinutes;
};

const convert12To24Hour = (timeData: TimeData) => {
  const { dayPeriod, hour, minutes } = timeData;

  let currentHour = hour.toString();
  let currentMinutes = minutes.toString();

  if (currentHour === '12') {
    currentHour = '00';
  }

  if (hour < 10) {
    currentHour = `0${hour}`;
  }

  if (dayPeriod === '오후') {
    currentHour = `${parseInt(currentHour, 10) + 12}`;
  }

  if (currentMinutes === '0') {
    currentMinutes = '00';
  }

  return `${currentHour}:${currentMinutes}`;
};
