import { useCallback, useMemo, useState } from 'react';

interface ReturnType {
  focused: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

export const useFocusElement = (initialFocusedState = false): ReturnType => {
  const [focusedState, setFocusedState] = useState(initialFocusedState);

  const handleFocus = useCallback(() => setFocusedState(true), []);
  const handleBlur = useCallback(() => setFocusedState(false), []);

  return useMemo(
    () => ({
      focused: focusedState,
      onFocus: handleFocus,
      onBlur: handleBlur,
    }),
    [focusedState, handleBlur, handleFocus]
  );
};
