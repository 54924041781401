import { withAsyncBoundary } from '@community-group/components';
import { MouseEventHandler, useMemo } from 'react';

import { useGetCommentEmotions } from '@/api/hooks/useGetCommentEmotions';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import EmotionCountMessage from '@/components/common/EmotionCountMessage';

import * as s from './GroupMeetupDetailCommentListEmotionCountMessage.css';

type Props = {
  groupId: string;
  postId: string;
  commentId: string;
  commentEmotionCount: number;
  hasCurrentUserEmotion: boolean;
  onClick: MouseEventHandler;
};

const GroupMeetupDetailCommentListEmotionCountMessage = ({
  groupId,
  postId,
  commentId,
  commentEmotionCount,
  ...props
}: Props) => {
  const { data: groupMe } = useGetGroupMe(groupId);
  const { data } = useGetCommentEmotions({
    groupId: Number(groupId),
    relatedId: Number(postId),
    commentId: Number(commentId),
    relatedContentType: 'meetup',
    limit: 2,
  });

  const currentUser = groupMe?.currentUser;
  const emotionUsers = useMemo(
    () => data?.pages.flatMap((page) => page?.data.users) ?? [],
    [data?.pages]
  );
  const emotionCount =
    data?.pages.reduce((count, page) => count + page?.data.emotionCount, 0) ?? commentEmotionCount;

  if (!currentUser || !emotionCount) return null;

  return (
    <div className={s.Wrapper}>
      <EmotionCountMessage
        currentUser={currentUser}
        emotionCount={emotionCount}
        emotionUsers={emotionUsers}
        {...props}
      />
    </div>
  );
};

export default withAsyncBoundary(GroupMeetupDetailCommentListEmotionCountMessage, {});
