import { AxiosInstance, AxiosResponse } from 'axios';

type UsersMeDemographyResponse = {
  userGenderAgePresentation: {
    userId: number;
    ageRange?: string;
    gender: 'GRAY' | 'MALE' | 'FEMALE';
  };
};

export const COMMON_URL = '/api/v1';

type Params = {
  fetchInstance: AxiosInstance;
};
// 유저의 나이, 성별 정보를 랜딩해요.
export const getUsersMeDemography = async ({ fetchInstance }: Params) => {
  const { data } = await fetchInstance.get<undefined, AxiosResponse<UsersMeDemographyResponse>>(
    `${COMMON_URL}/users/me/demography`
  );

  return data;
};
