export interface ValidationConfig {
  maxVideoSizeMB: number;
  maxVideoDurationHours: number;
  allowedFileTypesRegex: string;
}

export const validationVideoFile = async (
  file: File,
  config: ValidationConfig
): Promise<string | undefined> => {
  if (!file) {
    return '파일이 존재하지 않아요.';
  }

  if (!validateVideoFileType(file.type, config.allowedFileTypesRegex)) {
    return '지원하지 않는 포맷이에요.';
  }

  if (byteToMegaByte(file.size) >= config.maxVideoSizeMB) {
    return `동영상 크기는 ${config.maxVideoSizeMB}MB까지 가능해요.`;
  }

  const duration = await getVideoDuration(file);
  if (duration && duration > config.maxVideoDurationHours * 60 * 60) {
    return `동영상 재생시간은 최대 ${config.maxVideoDurationHours}시간까지 가능해요.`;
  }

  return undefined;
};

const validateVideoFileType = (type: string, allowedFileTypesRegex: string): boolean => {
  return new RegExp(allowedFileTypesRegex).test(type);
};

const byteToMegaByte = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const getVideoDuration = (file: File) => {
  return new Promise<number | null>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(null);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};
