import { useFlow } from '@/stackflow';

import { HomeBanner } from '../Banner';
import IMG_PHOTO_CONTEST from './img_photo_contest.png';

export const ExplorerGroupTabPhotoContestBanner = () => {
  // TODO: 전국모임자랑
  const { push } = useFlow();
  const handleClick = () => {
    push('PhotoContestPage', {});
  };

  return (
    <HomeBanner
      title="전~국모임자랑📸"
      description="이벤트 참여하고 모임을 자랑해보세요!"
      image={IMG_PHOTO_CONTEST}
      onClick={handleClick}
    />
  );
};
