import { groupClient } from '@community-group/api';
import { CommentCreateForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Request = {
  groupId: string;
  relatedId: string;
  commentCreateForm: CommentCreateForm;
};

type Props = {
  relatedContentType: 'post' | 'meetup';
} & UseMutationOptions<number | undefined, Error, Request>;

export const usePostComment = ({ relatedContentType, onError, onSuccess }: Props) => {
  const isPostComment = relatedContentType === 'post';
  const apiMethod = isPostComment
    ? groupClient.api.CommentApi.apiV1GroupsIdPostsPostIdCommentsPost
    : groupClient.api.CommentApi.apiV1GroupsIdMeetupsMeetupIdCommentsPost;

  const fetchInstance = useFetchInstance();
  const postComment = apiMethod({
    axios: fetchInstance,
  });

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(
    async ({ groupId, relatedId, commentCreateForm }: Request) => {
      const res = await postComment(Number(groupId), Number(relatedId), commentCreateForm);
      return res.data.id;
    },
    {
      onError,
      onSuccess: (data, variables, context) => {
        onSuccess?.(data, variables, context);

        mutateEventLogLocation({
          eventName: 'create_group_post_comment',
          groupId: parseInt(variables.groupId ?? '0'),
        });
      },
    }
  );
};
