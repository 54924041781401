import { createContext, useContext } from 'react';

type ProviderValue = {
  keyboardOpened: boolean;
  keyboardHeight: number;
  handleKeyboardOpenForAndroid: (value: boolean) => void;
};

export const VirtualKeyboardContext = createContext<ProviderValue>({
  keyboardOpened: false,
  keyboardHeight: 0,
  handleKeyboardOpenForAndroid: (value: boolean) => {},
});

export const useKeyboardState = () => {
  const { keyboardOpened, keyboardHeight, handleKeyboardOpenForAndroid } =
    useContext(VirtualKeyboardContext);

  if (keyboardOpened == undefined || keyboardHeight == undefined) {
    throw new Error('VirtualKeyboard Provider 안에서 사용해주세요');
  }

  return { opened: keyboardOpened, keyboardHeight, handleKeyboardOpenForAndroid };
};
