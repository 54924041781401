import {
  UserProfileSnsTagPresentation,
  UserProfileSnsTagPresentationSnsType,
} from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
  useSnackbarAdapter,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { usePutGroupProfile } from '@/api/hooks/usePutGroupProfile';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';

import { openSnsLink } from '../utils/openSnsLink';
import { SnsTagItem } from './SnsTagItem';
import * as s from './SnsTagSection.css';

type Props = {
  snsTags: UserProfileSnsTagPresentation[];
};

export const SnsTagMySection = ({ snsTags }: Props) => {
  const { push } = useFlow();
  const { open: openBottomSheet, closeAsync } = useBottomSheet();

  const { data: profile } = useGetMyMainProfile({});

  const snackbarAdapter = useSnackbarAdapter();
  const handleErrorWithToast = useHandleErrorWithToast();

  const openSnackbar = (message: string) => {
    snackbarAdapter.create({
      message: message,
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
  };

  const { mutate: putMutate } = usePutGroupProfile({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupProfile();
      openSnackbar('소셜 계정을 삭제했어요.');
    },
  });

  const handleDeleteSnsTag = (snsId: string, snsType: string) => {
    const filtered = snsTags.filter((snsTag) => snsTag.snsId !== snsId);
    trackEvent({
      event: 'click_delete_sns_tag',
      params: {
        snsTagId: snsId,
        snsTagType: snsType,
      },
    });
    putMutate({
      groupMainProfileModifyForm: {
        nickname: profile?.nickname ?? '',
        snsTags: filtered,
      },
    });
  };

  const handlePushSnsTagSetBottomSheet = () => {
    push('BottomSheet/SnsTagSetBottomSheet', {});
  };

  const handleOnClickSnsTag = ({
    snsId,
    snsType,
  }: {
    snsId: string;
    snsType: UserProfileSnsTagPresentationSnsType;
  }) => {
    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            <ActionListItem
              onClick={() =>
                openSnsLink({
                  snsId,
                  snsType,
                })
              }
            >
              링크로 이동
            </ActionListItem>
            <ActionListItem
              onClick={async () => {
                await closeAsync();
                push('BottomSheet/SnsTagSetBottomSheet', {
                  snsSetBottomSheetType: 'edit',
                  snsSetBottomInitialToggle: snsType,
                });
              }}
            >
              수정
            </ActionListItem>
            <ActionListItem
              color={vars.$semantic.color.danger}
              onClick={async () => {
                await closeAsync();
                handleDeleteSnsTag(snsId, snsType);
              }}
            >
              삭제
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  return (
    <div className={s.SnsTagSection}>
      {snsTags.map((snsTag) => (
        <SnsTagItem
          key={snsTag.snsId}
          name={snsTag.snsId}
          iconType={snsTag.snsType}
          onClick={() => {
            handleOnClickSnsTag({
              snsId: snsTag.snsId,
              snsType: snsTag.snsType,
            });
          }}
        />
      ))}
      <SnsTagItem name="소셜 계정 추가" tagType="new" onClick={handlePushSnsTagSetBottomSheet} />
    </div>
  );
};
