import { groupClient } from '@community-group/api';
import {
  AsyncBoundary,
  ChipFilter,
  useBottomSheet,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { useState } from 'react';

import { ORDER_MEETUP_KR_TYPO } from '@/api/base/group';
import {
  SEARCH_MEETUP_ORDER_TYPE,
  useGetMeetupSearchList,
} from '@/api/hooks/useGetMeetupSearchList';
import SortingMeetupFilterBottomSheet from '@/components/common/SortingFilterBottomSheet/SortingMeetupFilterBottomSheet';
import { MeetupHomeListCard } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import SearchResultEmptyView from '../SearchResultList/EmptyView';
import * as s from './SearchResultMeetup.css';
import { SearchResultMeetupLoader } from './SearchResultMeetupLoader';

const SearchResultMeetup = () => {
  const [selectedSorting, setSelectedSorting] = useState<SEARCH_MEETUP_ORDER_TYPE>('meet_at_asc');
  const { open: openBottomSheet, close: closeBottomSheet } = useBottomSheet();
  const { query = '' } = usePathParams();

  const { data, hasNextPage } = useGetMeetupSearchList({
    order: selectedSorting,
    searchQuery: decodeURIComponent(query),
  });

  const meetups = data?.pages.map(({ data }) => data.meetups);
  const flatten = meetups?.flat(1) ?? ([] as groupClient.model.GroupMeetupSearchPresentation[]);

  const { push } = useFlow();

  return (
    <div>
      <div className={s.switchOrderWrapper}>
        <ChipFilter
          label={ORDER_MEETUP_KR_TYPO[selectedSorting]}
          onClick={() => {
            trackEvent({
              event: 'click_sorting_meetup',
              params: {
                selectedSorting: selectedSorting,
                referrer: 'home',
              },
            });
            openBottomSheet({
              element: (
                <SortingMeetupFilterBottomSheet
                  setSelectedSorting={setSelectedSorting}
                  selectedSorting={selectedSorting}
                />
              ),
              onDimClose: closeBottomSheet,
            });
          }}
        />
      </div>
      {flatten.length <= 0 && <SearchResultEmptyView isMeetupSearch />}
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        {flatten.map((meetup) => {
          return (
            <MeetupHomeListCard
              key={meetup.meetupId}
              title={meetup.title}
              headerLabel={[meetup.groupInfo.region.name]}
              meetupAtDisplayText={meetup.meetupAtDisplayText}
              currentParticipantsCount={meetup.currentParticipantCount}
              maximumParticipantsCount={meetup.maximumParticipantCount}
              image={meetup.image?.medium ?? ''}
              hostInfo={{
                image: meetup.groupInfo?.profileImage?.small ?? '',
                displayText: meetup.groupInfo?.name,
                imageShape: 'square',
              }}
              onClick={() => {
                trackEvent({
                  event: 'click_group_meetup_item',
                  params: {
                    meetupId: meetup.meetupId,
                    meetupName: meetup.title,
                    groupId: meetup.groupInfo?.groupId,
                    utm_medium: 'search_result',
                    referrer: 'search_result',
                    query: decodeURIComponent(query ?? ''),
                  },
                  sample: true,
                });
                push('GroupMeetupDetailPage', {
                  groupId: (meetup.groupInfo?.groupId ?? '').toString(),
                  meetupId: meetup.meetupId.toString(),
                });
              }}
              viewCount={meetup.viewCount}
            />
          );
        })}
      </AsyncBoundary>
      {hasNextPage && <SearchResultMeetupLoader order={selectedSorting} />}
    </div>
  );
};

export default SearchResultMeetup;
