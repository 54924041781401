import { ChipToggleButton } from '@community-group/components';
import { useEffect, useRef, useState } from 'react';

import { shortening } from '@/utils/text';

import * as s from './index.css';

interface Option {
  key: number | string;
  value: string;
  id?: string;
  count?: number;
}

interface Props {
  id: string;
  label: string;
  options: Option[];
  selectedOptionKey: Option['key'];
  onChange({ value, key }: { value: Option['value']; key: Option['key'] }): void;
  autoScroll?: boolean;
}

export function ChipRadio({ id, label, options, selectedOptionKey, onChange, autoScroll }: Props) {
  const [focusId, setFocusId] = useState('');

  const optionsWithIds = options.map((option) => {
    const clone = { ...option };
    clone.id = clone.id ?? `radio-option-${id}-${option.value}`;
    return clone;
  });

  const selectedOption = optionsWithIds.find((option) => option.key === selectedOptionKey);
  const selectedId = selectedOption ? selectedOption.id : '';

  function handleInitialContainerFocus() {
    if (!focusId && optionsWithIds[0].id) {
      setFocusId(optionsWithIds[0].id);
    }
  }

  function handleOptionClick(option: Option) {
    return () => {
      if (option.id) {
        setFocusId(option.id);
        onChange({ value: option.value, key: option.key });
      }
    };
  }

  function renderOptions() {
    return optionsWithIds.map((option) => {
      const isSelected = selectedId === option.id;
      const count = option.count && option.count > 0 ? option.count : undefined;

      return (
        <ScrollChip
          flexShrink={0}
          key={option.id}
          isSelected={isSelected}
          onClick={handleOptionClick(option)}
          autoScroll={autoScroll}
          count={count}
          UNSAFE_style={{
            marginRight: '0.5rem',
          }}
        >
          {shortening(option.value, 15)}
        </ScrollChip>
      );
    });
  }

  const labelId = `radio-label-${id}`;

  return (
    <div>
      <h3 id={labelId}>{label}</h3>
      <ul
        className={s.container}
        tabIndex={0}
        role="radiogroup"
        aria-labelledby={labelId}
        aria-activedescendant={focusId}
        onFocus={handleInitialContainerFocus}
      >
        {renderOptions()}
      </ul>
    </div>
  );
}

type ChipToggleButtonProps = Parameters<typeof ChipToggleButton>[0] & {
  autoScroll?: boolean;
};

const ScrollChip = ({ isSelected, autoScroll, ...props }: ChipToggleButtonProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!autoScroll || !ref.current || !isSelected) return;

    ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  }, [autoScroll, isSelected]);

  return <ChipToggleButton ref={ref} isSelected={isSelected} {...props} />;
};

export default ChipRadio;
