import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import clsx from 'clsx';
import { Suspense, useMemo } from 'react';

import { useGetGroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import Loading from '@/components/common/Loading';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';

import ImageViewerSlider from '../components/ImageViewerSlider';
import * as s from './ImageViewer.css';

export type AlbumImageViewerProps = {
  groupId: string;
  userId: string;
};

const ProfileImageViewer = ({ groupId, userId }: AlbumImageViewerProps) => {
  const { pop } = useFlow();

  const { data: profile } = useGetGroupMainProfile({
    groupId: Number(groupId),
    userId: Number(userId),
  });

  const imageList = useMemo(() => {
    return [
      {
        id: profile.id.toString(),
        imageUrl: profile.profileImage ?? '',
      },
    ];
  }, [profile.id, profile.profileImage]);

  return (
    <AppScreen hasAppBar={false} layoutRef={undefined} onPull={undefined}>
      <div className={clsx(s.Wrapper, 'imageSliderWrapper')}>
        <Suspense
          fallback={
            <div className={s.LoaderWrapper}>
              <Loading size="medium" />
            </div>
          }
        >
          <ImageViewerSlider
            imageData={{
              imageList: imageList,
              initialIndex: 0,
            }}
            renderBottom={() => <div className={s.TopGradation} />}
          />
        </Suspense>
        <div className={s.CloseButton} onClick={pop}>
          <IconCloseRegular size={24} color={vars.$static.color.staticWhite} />
        </div>
      </div>
    </AppScreen>
  );
};

export default ProfileImageViewer;
