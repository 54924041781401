import {
  AsyncBoundary,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useRef } from 'react';

import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { LayoutRefProps } from '@/stackflow/components/Layout/components/ContentsLayout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../../Common/GroupCreateAppScreen';
import useCreateForm from '../../hooks/useCreateForm';
import useCreateGroupFunnel from '../../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../../utils/indicatorCountHandler';
import AgeRange from '../ageRange';
import { AGE_RANGE_OPTIONS } from '../constants/age-range-constants';
import { MAXIMUM_MEMBER_OPTIONS } from '../constants/maximum-member-constants';
import useValidAgeRangeInput from '../hooks/use-validate-age-range-input';
import useValidMaximumMemberInput from '../hooks/use-validate-maximum-member-input';
import MaximumMemberChips from '../maximumMember';

export type GroupNewSetRecruitmentConditionPageParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'createType'
  | 'from'
  | 'description'
  | 'joinGrowth'
  | 'createGroupFlowType'
>;

const GroupNewSetRecruitmentConditionPage: ActivityComponentType<
  GroupNewSetRecruitmentConditionPageParams
> = () => {
  const { push, pop } = useFlow();
  const {
    from,
    createGroupFlowType,
    ...postNewGroupPayload
  }: GroupNewSetRecruitmentConditionPageParams = useQueryParams();

  const { register, handleSubmit, watch, setValue } = useCreateForm({
    defaultValues: {
      selectedAgeRangeChip: 0,
      selectedMaximumMemberChip: 0,
      ageRange: AGE_RANGE_OPTIONS[0].value,
      maximumMember: MAXIMUM_MEMBER_OPTIONS[0].value,
    },
  });
  const { validateMinValue, validateMaxValue } = useValidAgeRangeInput({ watch });
  const validateMaximumMemberValue = useValidMaximumMemberInput({ watch });

  const selectedAgeRangeChipFieldValue = watch('selectedAgeRangeChip');
  const selectedMaximumMemberChipFieldValue = watch('selectedMaximumMemberChip');
  const ageRangeFieldValue = watch('ageRange');
  const maximumMemberFieldValue = watch('maximumMember');

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'recruitmentCondition',
      createGroupFlowType: createGroupFlowType ?? 'normal',
    },
  });
  useSetFixedLayoutSize(false);
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  const { nextPageName } = useCreateGroupFunnel();

  const handleSubmitButton = handleSubmit(async () => {
    push(nextPageName, {
      ...postNewGroupPayload,
      maximumMemberCount: maximumMemberFieldValue,
      minAgeTag: ageRangeFieldValue?.minAge,
      maxAgeTag: ageRangeFieldValue?.maxAge,
      from,
      createGroupFlowType,
    });
  });

  const ableButton = () => {
    if (selectedAgeRangeChipFieldValue === 0 && selectedMaximumMemberChipFieldValue === 0)
      return true;
    if (
      validateMinValue().isValid &&
      validateMaxValue().isValid &&
      validateMaximumMemberValue().isValid
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (!ageRangeFieldValue) {
      setValue('selectedAgeRangeChip', 0);
      setValue('ageRange', AGE_RANGE_OPTIONS[0].value);
    }
    if (maximumMemberFieldValue === undefined) {
      setValue('selectedMaximumMemberChip', 0);
      setValue('maximumMember', MAXIMUM_MEMBER_OPTIONS[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = useRef<LayoutRefProps>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setViweportInput({ wrapperRef });
    }
  }, []);

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetRecruitmentConditionPage')}
      layoutRef={ref}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            onBack={pop}
            onSubmit={handleSubmitButton}
            buttonDisabled={!ableButton()}
          />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0rem 1rem" ref={wrapperRef}>
          <div>
            <p
              style={{
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '2rem',
                marginBottom: '1.5rem',
                marginTop: '1rem',
              }}
            >
              어떤 이웃과 함께하고 싶나요?
            </p>
            <div>
              <Typography as="div" typography="label3Bold" color="gray900">
                연령대
              </Typography>
              <Spacing size={12} />
              <AgeRange
                selectedAgeRangeChip={selectedAgeRangeChipFieldValue}
                setSelectedAgeRangeChip={(value) => setValue('selectedAgeRangeChip', value)}
                register={register}
                watch={watch}
                setValue={setValue}
              />
            </div>
            <Spacing size={32} />
            <div>
              <Typography as="div" typography="label3Bold" color="gray900">
                최대 인원
              </Typography>
              <Spacing size={12} />
              <MaximumMemberChips
                selectedChip={selectedMaximumMemberChipFieldValue}
                setSelectedChip={(value) => setValue('selectedMaximumMemberChip', value)}
                register={register}
                setValue={setValue}
                watch={watch}
              />
            </div>
            <Spacing size={48} />
          </div>
        </LayoutWrapper>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetRecruitmentConditionPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
