export const COOKIE = {
  REGION_ID: 'region-id',
  REGION_PROVINCE_NAME: 'region-province-name', // region.name1
  REGION_CITY_NAME: 'region-city-name', // region.name2
  REGION_TOWN_NAME: 'region-town-name', // region.name3
  USER_NICKNAME: 'user-nickname',
  USER_PROFILE_IMAGE_URL: 'user-profile-image-url',
  COMMENT_SORTING_TYPE: 'comment-sorting-type',
  ENABLED_RED_TEAM: 'enabled-red-team',

  // 'x-' 를 붙이는 건 HEADER와 함께 사용하던 컨벤션, 이제는 붙이지 않아도 무방해요.
  // 기존 쿠키와의 호환을 위해 변경하지 않고 남겨둬요!
  USER_ID: 'x-user-id',
  HASH_ID: 'x-hash-id',
  USER_AGENT: 'x-user-agent',
  AUTH_TOKEN: 'x-auth-token',
  IS_LEGACY_IOS: 'x-karrot-is-legacy-ios',

  SAFE_AREA_TOP: 'safe-area-inset-top',
  ENABLE_TRACKING_LOG_DEBUGGING: 'enable-tracking-log-debugging',

  HERO_IMAGE_BANNER_SHOWN_COUNT: 'heroImageBannerShownCount',

  KONTROL_TOKEN: 'kontrol-token',

  GROUP_PREVIEW_URL: 'webview-switcher-base-url-group',
};

// 네이티브, 서버와의 약속을 지키기 위해 값을 임의로 변경할 수 없어요!
export const X_HEADER = {
  USER_ID: 'x-karrot-user-id',
  USER_AGENT: 'x-user-agent',
  AUTH_TOKEN: 'x-karrot-auth-token',
  REGION_ID: 'x-karrot-region-id',
};
