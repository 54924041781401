interface Props {
  fill?: 'staticYellow300' | 'staticGreen400';
  size?: number;
}

const IconKing = ({ size = 16, fill: propsFill = 'staticYellow300' }: Props) => {
  const fill = propsFill === 'staticYellow300' ? '#F7BE68' : '#30C795';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99998 0.577275C7.61878 0.22001 8.38118 0.22001 8.99998 0.577275L13.9282 3.42257C14.547 3.77984 14.9282 4.44009 14.9282 5.15463V10.8452C14.9282 11.5598 14.547 12.22 13.9282 12.5773L8.99998 15.4226C8.38118 15.7798 7.61878 15.7798 6.99998 15.4226L2.07178 12.5773C1.45298 12.22 1.07178 11.5598 1.07178 10.8452V5.15463C1.07178 4.44009 1.45298 3.77984 2.07178 3.42257L6.99998 0.577275Z"
        fill={fill}
      />
      <path
        d="M8.25889 6.24604C8.60575 6.13628 8.85717 5.81184 8.85717 5.42868C8.85717 4.95529 8.47341 4.57153 8.00003 4.57153C7.52664 4.57153 7.14288 4.95529 7.14288 5.42868C7.14288 5.81185 7.39431 6.1363 7.74119 6.24604C7.15918 7.31307 6.03533 8.65287 4.59133 7.76352C4.75511 7.60741 4.85717 7.38712 4.85717 7.14299C4.85717 6.6696 4.47341 6.28585 4.00003 6.28585C3.52664 6.28585 3.14288 6.6696 3.14288 7.14299C3.14288 7.61638 3.52664 8.00013 4.00003 8.00013C4.0667 8.00013 4.13159 7.99252 4.19388 7.97812L5.00004 10.8573H11L11.8062 7.97813C11.8685 7.99252 11.9334 8.00013 12 8.00013C12.4734 8.00013 12.8572 7.61638 12.8572 7.14299C12.8572 6.6696 12.4734 6.28585 12 6.28585C11.5266 6.28585 11.1429 6.6696 11.1429 7.14299C11.1429 7.38712 11.2449 7.60742 11.4087 7.76353C9.96474 8.65286 8.8409 7.31306 8.25889 6.24604Z"
        fill="white"
      />
    </svg>
  );
};

export default IconKing;
