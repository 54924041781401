type Props = React.SVGProps<SVGSVGElement>;

export const PinSelected = (props: Props) => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2847_40410)">
      <ellipse cx="31" cy="55.5" rx="10.5" ry="3.5" fill="black" fillOpacity="0.15" />
    </g>
    <path
      d="M51.5 20.3824C51.5 23.2756 50.956 25.7786 50.097 27.9275C46.2698 37.5018 36.8973 44.3461 32.7475 53.7782L32.2051 55.0109C31.9915 55.4964 31.5199 55.8086 31 55.8086C30.4801 55.8086 30.0085 55.4964 29.7949 55.0109L29.2525 53.7782C25.1027 44.3461 15.7302 37.5018 11.903 27.9275C11.044 25.7786 10.5 23.2756 10.5 20.3824C10.5 9.12549 19.6782 0 31 0C42.3218 0 51.5 9.12549 51.5 20.3824Z"
      fill="#FF6F0F"
    />
    <path
      d="M51 20.3824C51 23.2112 50.4684 25.6513 49.6327 27.7419C47.752 32.4467 44.5032 36.4945 41.1561 40.5898C41.0535 40.7153 40.9508 40.8408 40.8481 40.9664C37.6168 44.9179 34.3325 48.9341 32.2898 53.5769L31.7475 54.8095C31.6116 55.1184 31.3164 55.3086 31 55.3086C30.6836 55.3086 30.3884 55.1184 30.2525 54.8095L29.7102 53.5769C27.6675 48.9341 24.3832 44.9179 21.1519 40.9664C21.0492 40.8408 20.9465 40.7152 20.8439 40.5898C17.4968 36.4945 14.2479 32.4467 12.3673 27.7419C11.5316 25.6513 11 23.2112 11 20.3824C11 9.40434 19.9516 0.5 31 0.5C42.0484 0.5 51 9.40434 51 20.3824Z"
      stroke="black"
      strokeOpacity="0.1"
    />
    <circle cx="31" cy="20.5" r="7" fill="white" />
    <defs>
      <filter
        id="filter0_f_2847_40410"
        x="17.5"
        y="49"
        width="27"
        height="13"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_2847_40410" />
      </filter>
    </defs>
  </svg>
);
