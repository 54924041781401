import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';

import { useKeyboardSize, useWrapperOutsideClick } from '../../hooks';
import { HookFormImagePlugins } from '../HookForm/hooks/useHookFormImages';
import { FocusedLeftButtons } from './components/FocusedLeftButtons';
import FooterRightButtons from './components/FooterRightButtons';
import StickyInputImageList from './components/ImageList';
import InputSection from './components/InputSection';
import { StickyMentionsInputProps } from './components/MentionsInput';
import StickyInputFormProvider, {
  initialStickyInputFormData,
  StickyInputFormProviderProps,
  useStickyInputFormContext,
} from './provider/StickyInputFormProvider';
import * as s from './StickyInput.css';
import { useStickInputStore } from './store';

export type StickyInputProps = Pick<StickyInputFormProviderProps, 'initialValues' | 'onSubmit'> &
  Pick<StickyMentionsInputProps, 'mentionConfig'> & {
    type?: 'new' | 'edit';
    header?: {
      focused?: React.ReactNode;
      unfocused?: React.ReactNode;
    };
    body?: {
      focused?: React.ReactNode;
      unfocused?: React.ReactNode;
    };
    footer?: {
      focused?: { left?: React.ReactNode; right?: React.ReactNode };
      unfocused?: { left?: React.ReactNode; right?: React.ReactNode };
    };
    placeholder?: string;
    plugins: HookFormImagePlugins;
    fixed?: boolean;
  };

export const StickyInput: React.FC<StickyInputProps> = ({
  type = 'new',
  body = { focused: undefined, unfocused: undefined },
  footer = {
    focused: {
      left: undefined,
      right: undefined,
    },
    unfocused: {
      left: undefined,
      right: undefined,
    },
  },
  onSubmit,
  initialValues,
  mentionConfig,
  placeholder = '',
  plugins,
  fixed = true,
}: StickyInputProps) => {
  const { setFocused } = useStickInputStore();
  const { isKeyboardOn } = useKeyboardSize();

  const ref = useRef<HTMLDivElement>(null);

  const formEventHandler = (event?: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
    // submit 인 경우 재 focusing을 하지 않도록 처리, 키보드가 뜨다 꺼지는 현상 방지를 위해
    const targetEl = event?.target as HTMLElement;
    if (targetEl?.localName === 'button') return;
    setFocused(true);
    const textareaEl = ref.current?.querySelector('[data-name="content"]');
    (textareaEl as HTMLTextAreaElement)?.focus();
  };

  const {
    focused: bodyFocused = (
      <div className={s.Body}>
        <StickyInputImageList plugins={plugins} style={{ marginTop: '8px' }} />
      </div>
    ),
  } = body;

  const footerLeftFocused = footer?.focused?.left ?? (
    <FocusedLeftButtons plugins={plugins} type={type} onFocusEvent={formEventHandler} />
  );

  const footerLeftUnfocused = footer?.unfocused?.left ?? (
    <FocusedLeftButtons plugins={plugins} type={type} onFocusEvent={formEventHandler} />
  );

  const formRef = useRef<HTMLDivElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={s.Container({ fixed })} ref={containerRef}>
      <StickyInputFormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        onFocused={formEventHandler}
      >
        <OutsideClickBlurWrapper containerRef={containerRef} />
        <div className={s.Wrapper({ focused: isKeyboardOn })} ref={formRef}>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, display: 'none' }}
            >
              {bodyFocused}
            </motion.div>
          </AnimatePresence>
          <div className={s.Footer}>
            <div className={s.ButtonWrapper}>
              {isKeyboardOn ? footerLeftFocused : footerLeftUnfocused}
            </div>
            <InputSection
              type={type}
              placeholder={placeholder}
              mentionConfig={mentionConfig}
              onFocusEvent={formEventHandler}
              ref={ref}
            />
            <FooterRightButtons type={type} />
          </div>
        </div>
      </StickyInputFormProvider>
    </div>
  );
};

//useStickyInputFormContext를 사용하기 위해 별도의 컴포넌트로 분리
const OutsideClickBlurWrapper = ({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLElement>;
}) => {
  const { focused, setFocused, stickyInputProps } = useStickInputStore();
  const { setValue, reset, resetField } = useStickyInputFormContext();

  const clickOutside = () => {
    // Form 외부 이벤트를 받아 unfocused 상태로 변경한다.

    if (stickyInputProps?.type === 'edit') {
      reset(initialStickyInputFormData);
      resetField('content');
      setFocused(false);
      return;
    }
    setFocused(false);

    (document.activeElement as HTMLTextAreaElement)?.blur();

    setValue('allowMultiline', false);
  };

  useWrapperOutsideClick(containerRef, clickOutside, () => {
    if (!focused) return;
    const el = containerRef.current?.querySelector('[data-name="content"]');
    (el as HTMLInputElement)?.focus();
    setFocused(true);
  });
  return null;
};
