import { useMemo } from 'react';

import useActiveActivities from '@/hooks/useActiveActivities';

import {
  CREATE_GROUP_DEFAULT_FLOW,
  CREATE_GROUP_FLOW_RECOMMEND,
} from '../utils/createGroupControl';

const useCreateGroupFunnel = () => {
  const { activeActivities } = useActiveActivities();
  const currentActivity = activeActivities[activeActivities.length - 1];

  // 다음 페이지 이름을 가져온다.
  const nextPageName = useMemo(() => {
    const nextPageIndex =
      CREATE_GROUP_DEFAULT_FLOW.findIndex((page) => page === currentActivity.name) + 1;
    return CREATE_GROUP_DEFAULT_FLOW[nextPageIndex];
  }, [currentActivity.name]);

  const nextPageRecommendFlowName = useMemo(() => {
    const nextPageIndex =
      CREATE_GROUP_FLOW_RECOMMEND.findIndex((page) => page === currentActivity.name) + 1;

    return CREATE_GROUP_FLOW_RECOMMEND[nextPageIndex];
  }, [currentActivity.name]);

  return { nextPageName, nextPageRecommendFlowName };
};

export default useCreateGroupFunnel;
