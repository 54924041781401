import { IconChevronLeftRegular } from '@seed-design/icon';
import { ActivityComponentType, useActivity } from '@stackflow/react';

import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import CommentEmotionList from '../components/CommentEmotionList';

type Params = Pick<PageParams, 'groupId' | 'relatedId' | 'commentId' | 'relatedContentType'>;

const CommentEmotionListPage: ActivityComponentType<Params> = () => {
  const { replace, pop } = useFlow();
  const { isRoot } = useActivity();
  const { groupId, relatedId, commentId, relatedContentType } = usePathParams();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isRoot) {
      return replace('GroupDetailPage', {
        groupId,
      });
    } else {
      pop();
    }
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        backButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: (event) => {
            handleBackButtonClick(event);
          },
        },
        title: '공감',
      }}
    >
      <CommentEmotionList
        groupId={Number(groupId)}
        relatedId={Number(relatedId)}
        commentId={Number(commentId)}
        relatedContentType={relatedContentType}
      />
    </AppScreen>
  );
};

export default CommentEmotionListPage;
