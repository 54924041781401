import { TypeActivities } from '@/stackflow';

const CREATE_GROUP_PAGE_LIST: { [key: string]: keyof TypeActivities } = {
  SET_CATEGORY: 'GroupNewSetGroupCategoryPage',
  SET_CATEGORY_WITH_LLM: 'GroupNewSetGroupCategoryRecommendPage',
  SET_NAME: 'GroupNewSetGroupNamePage',
  SET_APPROVAL: 'GroupNewSetApprovalPage',
  SET_RECRUITMENT_CONDITION: 'GroupNewSetRecruitmentConditionPage',
  SET_REGION_RANGE: 'GroupNewSetGroupRegionRangePage',
  SET_VERIFICATION: 'GroupNewSetVerificationPage',
  SET_SUB_NICKNAME: 'GroupNewSetSubNicknamePage',
  SET_PROFILE_IMAGE: 'GroupNewSetProfileImagePage',
  MOVE_TO_CREATE_GROUP_DETAIL: 'GroupDetailPage',
} as const;

export const CREATE_GROUP_DEFAULT_FLOW = [
  CREATE_GROUP_PAGE_LIST.SET_CATEGORY,
  CREATE_GROUP_PAGE_LIST.SET_NAME,
  CREATE_GROUP_PAGE_LIST.SET_REGION_RANGE,
  CREATE_GROUP_PAGE_LIST.SET_APPROVAL,
  CREATE_GROUP_PAGE_LIST.SET_RECRUITMENT_CONDITION,
  CREATE_GROUP_PAGE_LIST.SET_VERIFICATION,
  CREATE_GROUP_PAGE_LIST.SET_SUB_NICKNAME,
  CREATE_GROUP_PAGE_LIST.SET_PROFILE_IMAGE,
  CREATE_GROUP_PAGE_LIST.MOVE_TO_CREATE_GROUP_DETAIL,
];

export const CREATE_GROUP_FLOW_RECOMMEND = [
  CREATE_GROUP_PAGE_LIST.SET_CATEGORY_WITH_LLM,
  CREATE_GROUP_PAGE_LIST.SET_REGION_RANGE,
  CREATE_GROUP_PAGE_LIST.SET_APPROVAL,
  CREATE_GROUP_PAGE_LIST.SET_RECRUITMENT_CONDITION,
  CREATE_GROUP_PAGE_LIST.SET_VERIFICATION,
  CREATE_GROUP_PAGE_LIST.SET_SUB_NICKNAME,
  CREATE_GROUP_PAGE_LIST.SET_PROFILE_IMAGE,
  CREATE_GROUP_PAGE_LIST.MOVE_TO_CREATE_GROUP_DETAIL,
];
