import { Container, Spacing } from '@community-group/components';
import { useEffect, useRef } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './ExplorerGroupListTitle.css';

export const ExplorerGroupListTitle = () => {
  const { scrollTo } = useQueryParams();
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (scrollTo === 'home-list' && titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTo]);

  return (
    <Container paddingY={0} className={s.wrapper}>
      <Spacing size={24} />
      <h3 className={s.title} ref={titleRef}>
        우리동네 모임
      </h3>
      <Spacing size={8} />
    </Container>
  );
};
