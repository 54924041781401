import { Spacing, Typography } from '@community-group/components';
import { addDays, addWeeks, format, getWeekOfMonth, startOfISOWeek, startOfMonth } from 'date-fns';
import React, { useMemo } from 'react';

import * as s from './LeaderBoardHeader.css';

type Props = {
  isCurrentWeek: boolean;
  baseWeek: number;
  baseYear: number;
};

const LeaderBoardHeader = ({ isCurrentWeek, baseWeek, baseYear }: Props) => {
  const titleText = isCurrentWeek ? '이번 주 모임 Top 50' : '지난 주 모임 Top 50';

  const subText = useMemo(() => {
    const { month, weekOfMonth } = yearAndWeekToMonthAndWeek(baseYear, baseWeek);
    return `${month}월 ${weekOfMonth}주차(월-일) 실시간 랭킹`;
  }, [baseWeek, baseYear]);

  return (
    <div className={s.Wrapper}>
      <Typography typography="title1Bold">{titleText}</Typography>
      <Spacing size={6} />
      <Typography typography="bodyL1Regular" color="gray600">
        {subText}
      </Typography>
    </div>
  );
};

export default LeaderBoardHeader;

const yearAndWeekToMonthAndWeek = (year: number, week: number) => {
  // 기준 연도의 첫 번째 ISO 주의 시작 날짜를 계산
  const firstISOWeekStart = startOfISOWeek(new Date(year, 0, 4));
  const startDate = addWeeks(firstISOWeekStart, week - 1);

  const dayOfFirstISOWeekStart = startDate.getDay();
  const diffDaysOfFirstThursdayOfMonth = 4 - dayOfFirstISOWeekStart;

  // 해당 주의 목요일 계산
  const thursdayDate = addDays(startDate, diffDaysOfFirstThursdayOfMonth);
  // 목요일이 있는 날이 속한 날이 month
  const month = format(thursdayDate, 'M');

  const firstDayOfMonth = startOfMonth(thursdayDate);
  const dayOfFirstDayOfMonth = firstDayOfMonth.getDay();
  // 월요일이 1, 일요일이 7
  const isBeforeThanFirstThursdayOfMonth = dayOfFirstDayOfMonth <= 4;

  // 월의 첫번째 주가 목요일을 포함하지 않으면 getWeekOfMonth에서 1을 빼줘야함
  const subWeek = isBeforeThanFirstThursdayOfMonth ? 0 : 1;

  const weekOfMonth = getWeekOfMonth(thursdayDate) - subWeek;

  return { month, weekOfMonth };
};
