import { useGetGroupMemberManagementList } from '@/api/hooks/useGetMemberManagementList';

export const useManagerMemberList = (groupId: string) => {
  const { data } = useGetGroupMemberManagementList(groupId);
  const members = data?.members ?? [];
  const hostMemberList = members.filter((member) => member.role === 'manager');

  return {
    hostMemberList,
    hostMemberCount: hostMemberList.length,
  };
};
