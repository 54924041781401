import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  topic: string;
};

export const useGetCurationList = ({ topic }: Params) => {
  const fetchInstance = useFetchInstance();

  const getCurationList = groupClient.api.GroupCurationApi.apiV1GroupsCurationGet({
    axios: fetchInstance,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [groupClient.api.GroupCurationApi.getapiV1GroupsCurationGetPath(topic), topic],
    ({ pageParam = undefined }) => getCurationList(topic, pageParam, 30),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
