import { UploadVideoResponse } from '@community-group/components';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios, { AxiosInstance } from 'axios';

type UploadRequestProps = {
  file: File;
  onProgress?: (event: ProgressEvent) => void;
};

// UseMutationOptions 타입 정의
type UploadVideoOptions = { fetchInstance: AxiosInstance } & UseMutationOptions<
  UploadVideoResponse,
  Error,
  UploadRequestProps
>;

const API_BASE_URL = globalThis.appConfig.apiEndPoints.community;

// 업로드 URL을 가져오는 함수
const getUploadUrl = async ({
  fetchInstance,
  file,
}: {
  fetchInstance: AxiosInstance;
  file: File;
}): Promise<{ id: string; url: string }> => {
  const response = await fetchInstance.post<{ id: string; url: string }>(
    `${API_BASE_URL}/api/v1/resource/videos`,
    {
      fileName: file.name,
    }
  );

  if (response.status !== 200) {
    throw new Error(`Failed to get upload URL \n ${response.data}`);
  }

  return response.data;
};

// 파일을 업로드하는 함수
const uploadFileToUrl = async ({
  url,
  file,
  onProgress,
}: {
  url: string;
  file: File;
  onProgress?: (event: ProgressEvent) => void;
}): Promise<UploadVideoResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        // 진행 상황 업데이트
        if (onProgress && event.total) {
          const progressEvent: ProgressEvent = {
            lengthComputable: true,
            loaded: event.loaded,
            total: event.total,
          } as ProgressEvent;

          onProgress(progressEvent);
        }
      },
    });

    return response.data as UploadVideoResponse;
  } catch (error) {
    throw new Error(`Failed to get upload video file \n ${error}`);
  }
};

// 통합된 업로드 함수
const uploadVideoCombined =
  ({ fetchInstance }: { fetchInstance: AxiosInstance }) =>
  async ({ file, onProgress }: UploadRequestProps) => {
    const { id, url } = await getUploadUrl({ fetchInstance, file });

    const result = await uploadFileToUrl({ url, file, onProgress });

    return { ...result, videoId: id } as UploadVideoResponse;
  };

// React Query의 mutation 훅으로 통합된 업로드 함수 제공
export const useUploadVideo = ({ fetchInstance, onError, onSuccess }: UploadVideoOptions) => {
  return useMutation(uploadVideoCombined({ fetchInstance }), {
    onError,
    onSuccess,
  });
};
