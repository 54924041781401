import { ORDER_HOME } from '@/api/base/group';
import { useGetHomeList } from '@/api/hooks/useGetHomeList';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './PopularityGroupListSection.css';
import { SearchResultGroupFilterProps } from './SearchResultGroupFilter';
import { SearchResultItem } from './SearchResultList/SearchResultItem';

type Props = Partial<Pick<SearchResultGroupFilterProps, 'selectedCategoryList'>>;

const PopularityGroupListSection = ({ selectedCategoryList = [] }: Props) => {
  const { push } = useFlow();

  const selectedCategoryIdList = (selectedCategoryList ?? []).map(({ id }) => id);
  const currentCategoryIdList =
    selectedCategoryIdList.length > 0 ? selectedCategoryIdList : undefined;

  const { data } = useGetHomeList({
    order: ORDER_HOME.RECOMMEND_SCORE_DESC,
    category: currentCategoryIdList,
  });

  const groupList = data?.pages[0].data.groups;
  const hidePopularityGroupList = !groupList;

  if (hidePopularityGroupList) return <></>;

  return (
    <>
      <div className={s.Spacing} />
      <div className={s.Wrapper}>
        <div className={s.TitleWrapper}>
          <h3 className={s.Title}>추천 모임</h3>
        </div>
        <div className={s.GroupListWrapper}>
          {groupList.map((group, index) => (
            <SearchResultItem
              key={group?.id}
              result={{
                ...group,
                region: group?.region ?? {
                  id: 0,
                  name: '',
                  province: '',
                  city: '',
                  town: '',
                },
                displayRegionName: group?.region?.town ?? '',
                someOfMemberProfileImages: group?.memberProfileImages,
              }}
              onClick={() => {
                trackEvent({
                  event: 'click_popularity_group',
                  params: {
                    groupId: group?.id,
                    groupName: group?.name,
                    groupCategoryName: group?.category?.name,
                    listItemIndex: index,
                  },
                });

                push('GroupDetailPage', {
                  groupId: group?.id.toString(),
                });
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PopularityGroupListSection;
