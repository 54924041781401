import { useEffect, useRef } from 'react';
interface useModalStateProps {
  isModalOpen: boolean;
  setModalClose: () => void;
}
/**
 * 안드로이드 back 버튼 history 대응
 * @param props
 */
export const useModalState = (props: useModalStateProps) => {
  const { isModalOpen } = props;
  const prevPath = useRef('');

  useEffect(() => {
    if (isModalOpen) {
      const hashUrlRegex = new RegExp('#(\\/.*?)(\\?.*)?$', 'g');
      const [, path] = hashUrlRegex.exec(window.location.href) ?? [];
      prevPath.current = path;
      window.history.pushState({}, '');
    }
  }, [isModalOpen]);

  useEffect(() => {
    const onPopState = (event: any) => {
      const hashUrlRegex = new RegExp('#(\\/.*?)(\\?.*)?$', 'g');
      const [, path] = hashUrlRegex.exec(event.target.location.href) ?? [];

      if (prevPath.current !== path) {
        prevPath.current = path;
        return;
      }
      props.setModalClose();
    };

    if (isModalOpen) {
      window.addEventListener('popstate', onPopState);
    }

    return () => window.removeEventListener('popstate', onPopState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleModalClose = () => {
    window.history.back();
    props.setModalClose();
  };

  return { isModalOpen, setModalClose: handleModalClose };
};
