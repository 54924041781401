export const getAgeDisplayText = ({
  min,
  max,
}: {
  min: number | undefined | null;
  max: number | undefined | null;
}) => {
  if (min === null || max === null) return;
  if (min === null || max === null) return;
  if (min === undefined || max === undefined) return;
  if (isNaN(min) || isNaN(max)) return;
  if (min === max) return `${min}세`;
  if (
    Math.floor((min ?? 0) / 10) === Math.floor((max ?? 0) / 10) &&
    Number(min) % 10 === 0 &&
    Number(max) % 10 === 9
  )
    return `${min}대`;
  if (max === 99) return `${min}세 이상`;
  if (min === 16) return `${max}세 이하`;
  return `${min}~${max}세`;
};
