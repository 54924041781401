import {
  Switch,
  Typography,
  useBottomSheet,
  useSnackbarAdapter,
  withAsyncBoundary,
} from '@community-group/components';
import { SettingList } from '@community-group/components/features';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';

import { useGetGroupBotDetail } from '@/api/hooks/useGetGroupBotDetail';
import { useGetGroupBotFeatures } from '@/api/hooks/useGetGroupBotFeatures';
import { usePutGroupBotChatGreetingMessage } from '@/api/hooks/usePutGroupBotChatGreetingMessage';
import { usePutGroupBotStatus } from '@/api/hooks/usePutGroupBotStatus';
import { useConnectedFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import SettingGroupBotMessageBottomSheet from './SettingGroupBotMessageBottomSheet';
import * as s from './SettingGroupBotSection.css';

type Props = {
  groupId: string;
};

const SettingGroupBotSection = ({ groupId }: Props) => {
  const { data: groupBotDetail, refetch: refetchGroupBotDetail } = useGetGroupBotDetail(groupId);
  const { data: groupBotFeatures, refetch: refetchBotFeatures } = useGetGroupBotFeatures(groupId);
  const { mutateAsync: putGroupButStatus } = usePutGroupBotStatus({});
  const { mutateAsync: putChatGreetingMessage } = usePutGroupBotChatGreetingMessage({});

  const { push } = useConnectedFlow();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const { create: openSnackbar, dismiss: closeSnackbar } = useSnackbarAdapter();

  const editChatGreetingMessage = async () => {
    const result = await push('GroupSettingEditGroupBotPage', {
      groupId,
      calloutType: 'group-bot-greeting-message',
    });
    if (result !== 'submit') return;

    openSnackbar({
      message: '전체 채팅방 환영 메시지를 수정했어요.',
      type: 'default',
      timeout: 3000,
      onClick: closeSnackbar,
    });
  };

  if (!groupBotDetail || !groupBotFeatures) return null;

  return (
    <SettingGroupBotSectionCore
      groupBotEnabled={groupBotDetail.data.status === 'enabled'}
      groupChatGreetingMessage={groupBotFeatures.data.groupChatGreetingMessage}
      onGroupBotSwitchClick={async (isEnabled) => {
        const nextStatus = isEnabled ? 'enabled' : 'disabled';

        trackEvent({
          event: 'click_bot_setting',
          params: {
            type: nextStatus === 'enabled' ? 'on' : 'off',
          },
        });

        await putGroupButStatus({
          groupId,
          form: {
            status: nextStatus,
          },
        });
        await refetchGroupBotDetail();
      }}
      onGroupChatGreetingMessageClick={async () => {
        const hasMessage = Boolean(groupBotFeatures.data.groupChatGreetingMessage);
        if (!hasMessage) {
          await editChatGreetingMessage();
          return;
        }

        openBottomSheet({
          element: (
            <SettingGroupBotMessageBottomSheet
              onEditClick={async () => {
                await closeBottomSheet();
                await editChatGreetingMessage();
              }}
              onDeleteClick={async () => {
                await closeBottomSheet();
                await putChatGreetingMessage({
                  groupId,
                  form: {
                    message: '',
                  },
                });
                await refetchBotFeatures();
                openSnackbar({
                  message: '전체 채팅방 환영 메시지를 삭제했어요.',
                  type: 'default',
                  timeout: 3000,
                  onClick: closeSnackbar,
                });
              }}
            />
          ),
          onDimClose: closeBottomSheet,
        });
      }}
    />
  );
};

type CoreProps = {
  groupBotEnabled: boolean;
  groupChatGreetingMessage?: string;
  onGroupBotSwitchClick?: (isEnabled: boolean) => void;
  onGroupChatGreetingMessageClick?: () => void;
};

const SettingGroupBotSectionCore = ({
  groupBotEnabled,
  groupChatGreetingMessage,
  onGroupBotSwitchClick,
  onGroupChatGreetingMessageClick,
}: CoreProps) => {
  const buttonDisabled = !groupBotEnabled;

  return (
    <SettingList.Wrapper>
      <SettingList.Item
        title="모임장봇 사용"
        renderRight={<Switch onChange={onGroupBotSwitchClick} isSelected={groupBotEnabled} />}
      />
      <SettingList.Item
        title="전체 채팅방 환영 메시지"
        description={
          groupChatGreetingMessage
            ? groupChatGreetingMessage
            : '새로운 멤버가 들어오면 환영 메시지를 보내요.'
        }
        renderRight={
          <button className={s.Button} disabled={buttonDisabled}>
            {groupChatGreetingMessage ? (
              <IconChevronRightRegular size={20} color={vars.$scale.color.gray900} />
            ) : (
              <Typography typography="bodyM1Regular" color="primary">
                입력하기
              </Typography>
            )}
          </button>
        }
        onClick={buttonDisabled ? undefined : onGroupChatGreetingMessageClick}
        disabled={buttonDisabled}
      />
    </SettingList.Wrapper>
  );
};

export default withAsyncBoundary(SettingGroupBotSection, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
