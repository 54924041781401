import type { LngLatBoundsLike } from 'mapbox-gl';

type Coordinate = [number, number];
export type Coordinates = Coordinate[];

export const ensureLongitudeLatitudeOrder = (coordinates: Coordinates): Coordinates => {
  return coordinates.map((coordinate) => {
    const [first, second] = coordinate;

    if (first >= -90 && first <= 90) {
      return [second, first];
    }

    return coordinate;
  });
};

export const getBoundsCoordinates = (coordinates: Coordinates): LngLatBoundsLike => {
  const minLng = Math.min(...coordinates.map((coordinate) => coordinate[0]));
  const minLat = Math.min(...coordinates.map((coordinate) => coordinate[1]));
  const maxLng = Math.max(...coordinates.map((coordinate) => coordinate[0]));
  const maxLat = Math.max(...coordinates.map((coordinate) => coordinate[1]));

  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
};

export const getPolygonCenter = (coordinates: Coordinates): Coordinate => {
  const bounds = getPolygonBounds(coordinates);

  return [(bounds.minY + bounds.maxY) / 2, (bounds.minX + bounds.maxX) / 2];
};

const getPolygonBounds = (
  polygonCoordinates: Coordinates
): { minX: number; minY: number; maxX: number; maxY: number } => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  polygonCoordinates.forEach((coordinate) => {
    minX = Math.min(minX, coordinate[0]);
    minY = Math.min(minY, coordinate[1]);
    maxX = Math.max(maxX, coordinate[0]);
    maxY = Math.max(maxY, coordinate[1]);
  });

  return { minX, minY, maxX, maxY };
};

export const estimateZoomLevel = (
  polygonCoordinates: Coordinates,
  mapDimension: { width: number; height: number }
): number => {
  const bounds = getPolygonBounds(polygonCoordinates);

  const WORLD_SIZE = 512; // constant for 256 tile size
  const ZOOM_MAX = 21;

  const lngDiff = bounds.maxX - bounds.minX;
  const latDiff = bounds.maxY - bounds.minY;

  const yFraction = latDiff / 360;
  const latZoom = Math.log(mapDimension.height / WORLD_SIZE / yFraction) / Math.LN2;

  const xFraction = lngDiff / 360;
  const lngZoom = Math.log(mapDimension.width / WORLD_SIZE / xFraction) / Math.LN2;

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};
