import { Spacing, Typography } from '@community-group/components';
import { useEffect, useRef, useState } from 'react';

const MAX_LINE = 3;
const LINE_HEIGHT = 24;

type Props = {
  description: string;
};

const GroupSummaryDescription = ({ description }: Props) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore((value) => !value);
  };

  useEffect(() => {
    const current = textRef.current;
    if (!current) return;

    // 실제 텍스트 높이와 라인 하이트를 바탕으로 줄 수 계산
    const computedStyle = window.getComputedStyle(current);
    const actualLineHeight = parseInt(computedStyle.lineHeight, 10) || LINE_HEIGHT;
    const actualLines = Math.ceil(current.scrollHeight / actualLineHeight);
    setIsOverflow(actualLines > MAX_LINE);
  }, []);

  return (
    <div onClick={handleShowMore}>
      <Typography
        ref={textRef}
        typography="bodyL1Regular"
        color="gray900"
        wordBreak="break-all"
        style={{
          maxHeight: showMore ? '100%' : `${LINE_HEIGHT * MAX_LINE}px`,
          whiteSpace: showMore ? 'pre-wrap' : undefined,
          overflow: 'hidden',
        }}
      >
        {description}
      </Typography>
      {isOverflow && (
        <>
          <Spacing size={4} />
          <Typography typography="bodyL1Regular" color="gray700">
            ...{showMore ? '접기' : '더보기'}
          </Typography>
        </>
      )}
    </div>
  );
};

export default GroupSummaryDescription;
