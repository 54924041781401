import { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';

import { uploadImages, UploadImageType } from '../utils/image';

export type OriginImage = {
  image: Blob;
  id: string;
};

export type HookFormImageDataSet = {
  images: UploadImageType[];
  originImages: OriginImage[];
};

export type HookFormImagePlugins = {
  bridge: { openToast: (config: { toast: { body: string } }) => void } | null;
  postUploadImageV2: (image: Blob) => Promise<UploadImageType>;
};

interface Props {
  allowImageCount?: number;
  handleHookFormContext: () => UseFormReturn<FieldValues>;
  plugins: HookFormImagePlugins;
}

export const useHookFormImages = ({
  allowImageCount = 10,
  handleHookFormContext: useHookFormContext,
  plugins: { bridge, postUploadImageV2 },
}: Props) => {
  const { watch, setValue } = useHookFormContext();

  const handleUploadImages = useCallback(
    async (images: OriginImage[]) => {
      const blobImages = images.map(({ image }) => image);
      const uploadedImages = await uploadImages(postUploadImageV2, blobImages);

      const mappedUpdatedImages = uploadedImages.map((image, index) => {
        if (image.status === 'fulfilled') {
          return {
            ...image.value,
            blobId: images[index].id,
          };
        }

        return {
          image: blobImages[index],
          id: images[index].id.replace('temp', 'error'),
          blobId: images[index].id,
        };
      });

      const updatedOriginImages = watch('originImages').map((image: OriginImage) => ({
        ...image,
        id:
          mappedUpdatedImages.find((uploadedImage) => uploadedImage.blobId === image.id)?.id ??
          image.id,
      }));

      const filteredOriginImages = updatedOriginImages.filter((image: OriginImage) => {
        if (image.id.includes('error')) {
          return false;
        }

        if (image.id.includes('temp')) {
          return true;
        }

        return true;
      });

      setValue('originImages', [...updatedOriginImages]);
      setValue('images', [...filteredOriginImages]);
    },
    [postUploadImageV2, setValue, watch]
  );

  const setImages = useCallback(
    async (blobImages: Blob[]) => {
      const originImages = watch('originImages');

      if (originImages.length + blobImages.length > allowImageCount) {
        return bridge?.openToast({ toast: { body: '첨부파일은 최대 10개까지만 가능합니다.' } });
      }

      const mappedBlobImages = blobImages.map((blobImage) => ({
        image: blobImage,
        id: `temp-${v4()}`,
      }));

      setValue('originImages', [...originImages, ...mappedBlobImages]);

      handleUploadImages(mappedBlobImages);
    },
    [allowImageCount, bridge, handleUploadImages, setValue, watch]
  );

  const removeImages = useCallback(
    (imageId: string) => {
      const originImages = watch('originImages');
      const images = watch('images');

      const selectedOriginImage = originImages.find((image: OriginImage) => image.id === imageId);
      const selectedOriginImageIndex = originImages.findIndex(
        (image: OriginImage) => image.id === imageId
      );

      // REMOVE originImages
      originImages.splice(selectedOriginImageIndex, 1);
      setValue('originImages', [...originImages]);

      // REMOVE images
      const selectedImageIndex = images.findIndex(
        (image: OriginImage) => image.id === selectedOriginImage?.id
      ); // 없을 땐 -1 return;

      if (selectedImageIndex >= 0) {
        images.splice(selectedImageIndex, 1);
        setValue('images', [...images]);
      }
    },
    [setValue, watch]
  );

  const retryImage = useCallback(
    (imageId: string) => {
      const originImages = watch('originImages');

      const selectedOriginImage = originImages.find((image: OriginImage) => image.id === imageId);

      if (!selectedOriginImage) return;

      const replacedSelectedOriginImage = {
        ...selectedOriginImage,
        id: selectedOriginImage.id.replace('error', 'temp'),
      };

      const replacedOriginImages = originImages.map((image: OriginImage) => {
        if (image.id === imageId) {
          return replacedSelectedOriginImage;
        }

        return image;
      });

      setValue('originImages', [...replacedOriginImages]);
      handleUploadImages([replacedSelectedOriginImage]);
    },
    [setValue, watch]
  );

  return {
    images: watch('originImages') as OriginImage[],
    setImages,
    removeImages,
    retryImage,
  };
};
