import { useActivity } from '@stackflow/react';
import { useCallback, useEffect } from 'react';

import { useBridge } from '@/contexts/Bridge';

import useActiveActivities from './useActiveActivities';

type StyleCurrentRouterParams = {
  scrollable?: boolean;
  backSwipable?: boolean;
  IOS_ONLY_hideKeyboardAccessoryView?: boolean;
};

export const useUpdateStyleCurrentRouter = (
  {
    scrollable = false,
    backSwipable,
    IOS_ONLY_hideKeyboardAccessoryView = true,
  }: StyleCurrentRouterParams,
  reset = false
) => {
  const { activeActivities } = useActiveActivities();
  const { isTop } = useActivity();

  const { bridge } = useBridge();

  const initDefaultStyle = useCallback(() => {
    bridge.styleCurrentRouter({
      router: {
        // noti로 접근하는 경우 & 기존 페이지에서 접근하는 경우
        backSwipable: backSwipable ?? activeActivities.length === 1,
        navbar: false,
        scrollable: false,
        enableSafeAreaInsets: false,
        IOS_ONLY_hideKeyboardAccessoryView: true,
      },
    });
  }, [activeActivities.length, backSwipable]);

  useEffect(() => {
    if (isTop) {
      bridge.styleCurrentRouter({
        router: {
          // noti로 접근하는 경우 & 기존 페이지에서 접근하는 경우
          backSwipable: backSwipable ?? activeActivities.length === 1,
          navbar: false,
          scrollable,
          enableSafeAreaInsets: false,
          IOS_ONLY_hideKeyboardAccessoryView,
        },
      });
      return;
    }
    initDefaultStyle();

    return () => {
      if (reset) {
        initDefaultStyle();
      }
    };
  }, [
    isTop,
    activeActivities,
    backSwipable,
    scrollable,
    IOS_ONLY_hideKeyboardAccessoryView,
    reset,
    initDefaultStyle,
  ]);
};

export const useHandleUpdateStyleCurrentRouter = () => {
  const { bridge } = useBridge();

  const updateStyleCurrentRouter = ({
    scrollable = false,
    backSwipable = false,
    IOS_ONLY_hideKeyboardAccessoryView = true,
  }: StyleCurrentRouterParams) => {
    bridge.styleCurrentRouter({
      router: {
        // noti로 접근하는 경우 & 기존 페이지에서 접근하는 경우
        backSwipable,
        navbar: false,
        scrollable,
        enableSafeAreaInsets: false,
        IOS_ONLY_hideKeyboardAccessoryView,
      },
    });
  };

  return updateStyleCurrentRouter;
};
