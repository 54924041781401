import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMeetupAttendancePath = (groupId: string, meetupId: string) =>
  `/api/v1/groups/${groupId}/meetups/${meetupId}/attendance`;

export type GetGroupMeetupAttendanceProps = {
  groupId: string;
  meetupId: string;
  limit?: number;
};

export const useGetGroupMeetupAttendanceMembers = ({
  groupId,
  meetupId,
  limit = 30,
}: GetGroupMeetupAttendanceProps) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetupAttendance =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdAttendanceGet({
      axios: fetchInstance,
    });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useInfiniteQuery(
      [getGroupMeetupAttendancePath(groupId, meetupId)],
      ({ pageParam = undefined }) =>
        getGroupMeetupAttendance(parseInt(groupId), parseInt(meetupId), pageParam, limit),
      {
        suspense: true,
        getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
        enabled: true,
      }
    );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
