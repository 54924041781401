import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';

import { trackEvent } from '@/utils/analytics';
import { openGroupPostReport } from '@/utils/link';

import { FeedModalProps } from './FeedModal';

const NormalUserRoleOptionsList = ({ groupId, post, onReportPost }: FeedModalProps) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={() => {
            if (onReportPost) return onReportPost();
            trackEvent({
              event: 'click_report',
              params: {
                type: 'post',
              },
            });
            closeBottomSheet();
            openGroupPostReport({
              groupId: String(groupId),
              postId: String(post.id),
            });
          }}
        >
          신고
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};

export default NormalUserRoleOptionsList;
