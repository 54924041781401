import {
  BoardCategoryPresentation,
  GroupCurrentUser,
  GroupDetailPresentation,
} from '@community-group/api/lib/group/models';
import { Divider, Typography, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo, useState } from 'react';

import { useGetGroupFeedSummary } from '@/api/hooks/useGetGroupFeedSummary';
import { useGetGroupFeedSummaryMeetup } from '@/api/hooks/useGetGroupFeedSummaryMeetup';
import { mapGroupFeedToPostDetail } from '@/components/group/Detail/utils/mapGroupFeedToPostDetail';
import { trackEvent } from '@/utils/analytics';

import GroupDetailFeedMeetupList from '../GroupDetailFeedMeetupList';
import GroupDetailSummaryMoreButton from '../GroupDetailSummaryMoreButton';
import FeedSummaryList from './FeedSummaryList';
import FeedSummarySection from './FeedSummarySection';
import * as s from './index.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  shouldSetSubNickname: boolean;
};

type BoardCategory = {
  key: number;
  value: string;
};

const GroupDetailFeedSummary = ({ group, currentUser, shouldSetSubNickname }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const defaultBoards = useMemo(() => [{ key: 0, value: '전체' }], []);
  const [selectedBoard, setSelectedBoard] = useState<BoardCategory>(defaultBoards[0]);
  const handleBoardChange = (category: BoardCategory) => {
    trackEvent({
      event: 'click_board_category_chip',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUser.role,
        version: '2',
        boardName: category.value,
      },
      sample: true,
    });

    setSelectedBoard(category);
  };

  const { data: feedSummary } = useGetGroupFeedSummary(Number(groupId));
  const { data: meetupSummary } = useGetGroupFeedSummaryMeetup(Number(groupId));

  const boards = useMemo(() => {
    if (!feedSummary) return defaultBoards;

    const customBoards = feedSummary.categoryFeeds
      .filter((feed) => feed.feeds && feed.feeds.length !== 0)
      .map((feed) => feed.boardCategory)
      .filter((category): category is BoardCategoryPresentation => category !== undefined)
      .map((category) => ({
        key: category.id,
        value: category.category,
      }));

    return [...defaultBoards, ...customBoards];
  }, [feedSummary, defaultBoards]);
  const feeds = useMemo(() => {
    if (!feedSummary) return [];

    switch (selectedBoard.key) {
      case 0:
        return feedSummary.allFeeds;
      case 1:
        return feedSummary.meetupFeeds;
      default: {
        const categoryFeeds = feedSummary.categoryFeeds.find(
          (feed) => feed.boardCategory?.category === selectedBoard.value
        );
        return categoryFeeds?.feeds || [];
      }
    }
  }, [feedSummary, selectedBoard]);

  return (
    <>
      <Divider size={8} />
      <FeedSummarySection
        totalCount={feedSummary?.totalCount ?? 0}
        boardOptions={boards}
        selectedBoardKey={selectedBoard.key}
        onBoardChange={handleBoardChange}
      >
        {selectedBoard.key === 1 ? (
          <GroupDetailFeedMeetupList
            groupId={groupId}
            meetups={meetupSummary?.meetups ?? []}
            shouldSetSubNickname={shouldSetSubNickname}
            renderEmptyView={() => (
              <Typography className={s.EmptyMessage} typography="bodyM1Regular" color="gray600">
                아직 일정이 없어요.
                <br />
                가장 먼저 일정을 만들어보세요.
              </Typography>
            )}
          >
            <GroupDetailSummaryMoreButton
              group={group}
              summaryType="meetups"
              currentUserRole={currentUser.role}
            />
          </GroupDetailFeedMeetupList>
        ) : (
          <FeedSummaryList
            totalCount={feedSummary?.totalCount ?? 0}
            feeds={feeds.map(mapGroupFeedToPostDetail(group, currentUser))}
            currentUser={currentUser}
          >
            <GroupDetailSummaryMoreButton
              group={group}
              summaryType="feeds"
              currentUserRole={currentUser.role}
            />
          </FeedSummaryList>
        )}
      </FeedSummarySection>
    </>
  );
};

export default withAsyncBoundary(GroupDetailFeedSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
