import { vars } from '@seed-design/design-token';

interface Props {
  fill?: 'white' | 'dark';
  size?: number;
}

const IconTwitter = ({ size = 16, fill = 'dark' }: Props) => {
  const fillColor = fill === 'white' ? vars.$scale.color.gray00 : vars.$scale.color.gray900;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.1428 7.16437L13.6108 2.07837H12.5524L8.6712 6.49357L5.5736 2.07837H2L6.6852 8.75557L2 14.0884H3.0584L7.1544 9.42477L10.4264 14.0884H14M3.4404 2.85997H5.0664L12.5516 13.3452H10.9252"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconTwitter;
