import { BoardCategoryPresentation } from '@community-group/api/lib/group/models';
import { Dialog, useDialog, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { BoxButton, ViewError } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { IconAddRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import { Reorder } from 'framer-motion';
import { useEffect, useState } from 'react';

import { GROUP_URL } from '@/api/base/group';
import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { usePutBoardCategories } from '@/api/hooks/usePutBoardCategoires';
import { queryClient } from '@/api/instance';
import IconWrapper from '@/components/common/Icons';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppBarTextSubmitButton } from '@/stackflow/components/AppBarTextSubmitButton';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import { BoardCategoryItem } from '../components/BoardCategoryItem';

export type GroupSettingPageParams = Pick<PageParams, 'groupId'>;

const BoardCategorySetting: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();

  const { data: categories } = useGetBoardTypeList(Number(groupId), 1000000);
  const { open: openDialog, close: closeDialog } = useDialog();

  const { pop } = useFlow();
  const { push: asyncPush } = useConnectedActions();

  const refetchQuries = () => {
    queryClient.refetchQueries({
      queryKey: [`${GROUP_URL}/${groupId}/posts/board-categories`, `${GROUP_URL}/${groupId}/posts`],
    });
  };

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutBoardCategories({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: `click_board_category_edit`,
        params: {
          groupId,
        },
      });
      refetchQuries();
    },
  });

  const { mutate: mutatePutBoardCategoryForBackButton } = usePutBoardCategories({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: `click_board_category_edit`,
        params: {
          groupId,
        },
      });
      refetchQuries();
      pop();
    },
  });

  const [sortMode, setSortMode] = useState(false);

  const [items, setItems] = useState(categories?.boardCategories || []);

  useEffect(() => {
    setItems(categories?.boardCategories || []);
  }, [categories?.boardCategories]);

  const handleOnClickCreateBoardCategory = async () => {
    const data: any = await asyncPush('BottomSheet/GroupSettingCreateBoardCategoryBottomSheet', {
      groupId,
    });

    const categories = data?.data.boardCategories as Array<BoardCategoryPresentation>;

    const newItems =
      categories.filter((item) => {
        return !items.find((oldItem) => oldItem.category === item.category);
      }) ?? [];
    setItems([...items, newItems[0]]);
  };

  const handleOnClickConfirm = () => {
    mutate({
      id: Number(groupId),
      postBoardCategoryModifyForm: {
        boardCategories: items,
      },
    });
    setSortMode(!sortMode);
  };

  const handleDeleteItem = (category: string) => {
    openDialog({
      element: (
        <Dialog
          title="게시판을 삭제할까요?"
          description="게시판을 삭제해도 게시글은 자유 게시판에서 볼 수 있어요."
          primaryActionLabel="삭제"
          secondaryActionLabel="취소"
          onPrimaryAction={async () => {
            await mutate({
              id: Number(groupId),
              postBoardCategoryModifyForm: {
                boardCategories: items.filter((item) => item.category !== category),
              },
            });
            await setItems(items.filter((item) => item.category !== category));
            await closeDialog();
          }}
        />
      ),
    });
  };

  const handleOnBackButtonClick = () => {
    const isEdit = items.some(
      (item, index) => item.category !== categories?.boardCategories?.[index]?.category
    );

    if (!sortMode || !isEdit) pop();
    openDialog({
      element: (
        <Dialog
          description="게시판 순서를 변경할까요?"
          primaryActionLabel="변경"
          secondaryActionLabel="취소"
          onSecondaryAction={async () => {
            closeDialog();
          }}
          onPrimaryAction={async () => {
            closeDialog();

            mutatePutBoardCategoryForBackButton({
              id: Number(groupId),
              postBoardCategoryModifyForm: {
                boardCategories: items,
              },
            });
          }}
        />
      ),
    });
  };

  return (
    <AppScreen
      appBar={{
        title: '게시판 관리',
        backButton: {
          render: () => <></>,
        },
        renderLeft: () => (
          <IconWrapper>
            <IconChevronLeftRegular onClick={handleOnBackButtonClick} />
          </IconWrapper>
        ),
        renderRight: () => (
          <AppBarTextSubmitButton
            onClick={handleOnClickConfirm}
            disabled={isLoading}
            loading={isLoading}
          >
            {sortMode ? '완료' : '편집'}
          </AppBarTextSubmitButton>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <LayoutWrapper>
        <Reorder.Group axis="y" onReorder={setItems} values={items}>
          {items.map((item) => (
            <BoardCategoryItem
              key={item.id}
              item={item}
              sortMode={sortMode}
              onDeleteItem={handleDeleteItem}
            />
          ))}
        </Reorder.Group>

        <div
          style={{
            padding: '0.5rem 1rem',
          }}
        >
          <BoxButton
            variant="primaryLow"
            prefix={<IconAddRegular size={16} />}
            onClick={handleOnClickCreateBoardCategory}
            size="medium"
            width="100%"
          >
            게시판 만들기
          </BoxButton>
        </div>
      </LayoutWrapper>
    </AppScreen>
  );
};

export default withAsyncBoundary(BoardCategorySetting, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
