import { TextButton } from '@community-group/components';

import ThinX from '@/components/common/Icons/thinX';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { trackEvent } from '@/utils/analytics';

import * as s from './RecentSearchedListSection.css';

interface Props {
  shownHistoryLength?: number;
}

const RecentSearchListSection = ({ shownHistoryLength = 6 }: Props) => {
  const {
    searchHistoryList,
    addSearchHistoyItem,
    removeSearchHistoyItemIndex,
    removeSearchHistoyItemAll,
  } = useStore();
  const { push } = useFlow();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    value: string,
    index: number
  ) => {
    event.preventDefault();

    removeSearchHistoyItemIndex(index);
    addSearchHistoyItem(value);

    trackEvent({
      event: 'click_recent_searched_item',
      params: {
        listItemIndex: index,
        query: value,
      },
      sample: true,
    });

    push('SearchResultPage', {
      query: encodeURIComponent(value),
    });
  };
  const handleRemoveButtonClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.stopPropagation();

    removeSearchHistoyItemIndex(index);
  };

  if (searchHistoryList.length === 0) return <></>;

  return (
    <>
      <div className={s.Wrapper}>
        <div className={s.TitleWrapper}>
          <h3 className={s.Title}>최근 검색어</h3>
          <TextButton variant="secondary" size="small" onClick={removeSearchHistoyItemAll}>
            모두 지우기
          </TextButton>
        </div>
        <ul className={s.ListWrapper}>
          {searchHistoryList.slice(0, shownHistoryLength).map((value, index) => (
            <li
              className={s.ListItem}
              key={`${value}-${index}`}
              onClick={(event) => handleListItemClick(event, value, index)}
            >
              <span>{value}</span>
              <button
                className={s.IconButton}
                onClick={(event) => handleRemoveButtonClick(event, index)}
              >
                <ThinX size={14} />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default RecentSearchListSection;
