import { Spacing, Typography } from '@community-group/components';
import { IconCloseRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import React, { Fragment } from 'react';

import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import { ChallengeCustomItem, ChallengeTemplateItem } from '../components/ChallengeTemplateItem';
import { useTempCreateGroupChallengeFormData } from '../hooks/useTempCreateGroupChallengeForm';
import { GroupChallengeBaseForm } from '../types';
import { defaultGroupChallengeData } from './GroupChallengeNewPage';
import * as s from './GroupChallengeNewTemplatePage.css';

type Params = Pick<PageParams, 'groupId'>;

export const CreateChallengeTemplate = [
  {
    name: '매일 운동 인증해요! 💪',
    defaultData: {
      name: '매일 운동 인증해요! 💪',
    },
  },
  {
    name: '하루 물 2L 마시기 💧',
    defaultData: {
      name: '하루 물 2L 마시기 💧',
    },
  },
  {
    name: '독서 습관 만들어요 📚',
    defaultData: {
      name: '독서 습관 만들어요 📚',
    },
  },
  {
    name: '꾸준히 영어 공부 인증해요! ✍️',
    defaultData: {
      name: '꾸준히 영어 공부 인증해요! ✍️',
    },
  },
  {
    name: '미라클 모닝 도전! ☀️',
    defaultData: {
      name: '미라클 모닝 도전! ☀️',
    },
  },
];

const GroupChallengeNewTemplatePage: ActivityComponentType<Params> = () => {
  const { push } = useFlow();
  const { groupId } = usePathParams();

  const { tempStoredData } = useTempCreateGroupChallengeFormData({
    groupId,
  });

  const handleClickChallengeTemplateItem = (defaultData?: GroupChallengeBaseForm) => {
    push('GroupChallengeNewPage', {
      groupId,
      defaultValue: defaultData ? JSON.stringify(defaultData) : undefined,
    });
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          renderIcon: () => <IconCloseRegular size={24} />,
        },
        closeButton: {
          renderIcon: () => <IconCloseRegular size={24} />,
        },
      }}
    >
      <div className={s.Wrapper}>
        <Typography typography="title1Bold">어떤 챌린지를 진행할까요?</Typography>
        <Spacing size={24} />
        {/* 임시 저장 데이터 */}
        {tempStoredData && (
          <Fragment key={tempStoredData.name}>
            <ChallengeTemplateItem
              isTempSavedData
              name={tempStoredData.name}
              onClick={() =>
                handleClickChallengeTemplateItem({
                  ...tempStoredData,
                  originImages: [],
                })
              }
              defaultData={{
                name: '',
              }}
            />
            <Spacing size={12} />
          </Fragment>
        )}
        {CreateChallengeTemplate.map((item) => {
          return (
            <Fragment key={item.name}>
              <ChallengeTemplateItem
                {...item}
                onClick={() =>
                  handleClickChallengeTemplateItem({
                    ...defaultGroupChallengeData,
                    ...item.defaultData,
                  })
                }
              />
              <Spacing size={12} />
            </Fragment>
          );
        })}
        <ChallengeCustomItem onClick={() => handleClickChallengeTemplateItem()} />
      </div>
    </AppScreen>
  );
};

export default GroupChallengeNewTemplatePage;
