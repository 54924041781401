import { vars } from '@seed-design/design-token';

interface Props {
  color?: string;
  size?: number;
}

const CategoryTogether = ({ size = 20, color }: Props) => {
  const fill = color ?? vars.$scale.color.gray700;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6659 2.26655H11.6659V1.54655C11.6659 1.17988 11.3659 0.879883 10.9992 0.879883C10.6325 0.879883 10.3325 1.17988 10.3325 1.54655V2.26655H5.67253V1.54655C5.67253 1.17988 5.37253 0.879883 5.00586 0.879883C4.63919 0.879883 4.33919 1.17988 4.33919 1.54655V2.26655H3.33919C2.60586 2.26655 2.00586 2.86655 2.00586 3.59988V12.9332C2.00586 13.6665 2.60586 14.2666 3.33919 14.2666H12.6725C13.4059 14.2666 14.0059 13.6665 14.0059 12.9332V3.59988C14.0059 2.86655 13.4059 2.26655 12.6725 2.26655H12.6659ZM10.2925 9.38655L9.37253 10.4132L9.50586 11.7999C9.51919 11.9066 9.40586 11.9866 9.31252 11.9399L8.04586 11.3732L6.77919 11.9399C6.67919 11.9866 6.57253 11.9066 6.58586 11.7999L6.71919 10.4132L5.79919 9.38655C5.72586 9.30655 5.76586 9.17988 5.87253 9.15988L7.22586 8.85322L7.92586 7.66655C7.97919 7.57322 8.11252 7.57322 8.16586 7.66655L8.86586 8.85322L10.2192 9.15988C10.3192 9.18655 10.3592 9.30655 10.2925 9.38655ZM12.6659 5.59988H3.33253V3.59988H12.6659V5.59988Z"
        fill={fill}
      />
    </svg>
  );
};

export default CategoryTogether;
