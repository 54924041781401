import { GroupLevelUpMissions } from '@community-group/api/lib/group/models';

export const getUnCompletedMissions = (missions?: GroupLevelUpMissions) => {
  if (!missions) return [];

  const uncompletedMissions = missions
    ? Object.values(missions)?.filter((el) => el && el?.currentCount < el?.achieveCount)
    : [];

  return uncompletedMissions;
};
