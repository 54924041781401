import { StoreSlice } from '..';

export type MeetupHomeSlice = {
  hideHeaderTitle: boolean;
  setHideHeaderTitle: (show: boolean) => void;
  listFilterKey: MeetupHomeFilter;
  setListFilterKey: (
    key: keyof MeetupHomeFilter,
    value: MeetupHomeFilter[keyof MeetupHomeFilter]
  ) => void;
};

export const createMeetupHomeSlice: StoreSlice<MeetupHomeSlice> = (set) => ({
  hideHeaderTitle: true,
  setHideHeaderTitle: (show) => {
    set((state) => ({ ...state, hideHeaderTitle: show }));
  },
  listFilterKey: {
    order: 'created_at_desc',
    statuses: ['opened', 'fully_recruited', 'closed'],
    category: undefined,
  },
  setListFilterKey: (
    key: keyof MeetupHomeFilter,
    value: MeetupHomeFilter[keyof MeetupHomeFilter]
  ) => {
    set((state) => ({ ...state, listFilterKey: { ...state.listFilterKey, [key]: value } }));
  },
});

export const MeetupHomeFilterCondition = {
  created_at_desc: '작성순',
  meet_at_desc: '일정순',
} as const;

export type Order = keyof typeof MeetupHomeFilterCondition;
export type Statuses = 'opened' | 'fully_recruited' | 'closed';

type MeetupHomeFilter = {
  order: Order;
  statuses: Statuses[];
  category: number | undefined;
};
