import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, LegacyRef, useMemo } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { v4 } from 'uuid';

import * as s from './FilterBadge.css';

export type Props = {
  name: string;
  value: number;
  checked: boolean;
  text: string;
  onClick?: (value: number) => void;
  register?: UseFormRegister<FieldValues>;
} & InputHTMLAttributes<HTMLInputElement>;

const FilterBadgeRadio = forwardRef(
  (
    { name, value = 0, text, register, checked, onClick, ...props }: Props,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const uniqueId = useMemo(() => v4(), []);
    return (
      <div className={s.badgeRadioBoxWrapper} ref={ref}>
        <input
          id={uniqueId}
          className="input"
          type="radio"
          defaultValue={value}
          onClick={onClick}
          {...props}
          {...(register && register(name))}
          style={{
            ...props.style,
            display: 'none',
          }}
        />
        <label style={{ padding: '0 0.6875rem' }} htmlFor={uniqueId}>
          <p className={s.text({ checked })}>{text}</p>
        </label>
      </div>
    );
  }
);

type BadgeRadioWrapperProps = InputHTMLAttributes<HTMLDivElement>;

export const BadgeRadioWrapper = forwardRef((props: BadgeRadioWrapperProps, ref: any) => (
  <div ref={ref} className={clsx(props.className, s.badgeRadioWrapper)} {...props} />
));

export default FilterBadgeRadio;
