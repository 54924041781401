import { $Values } from 'utility-types';

import { StoreSlice } from '..';

export const MODAL_KEY = {
  ACTION_MODAL: 'actionModal',
  FULL_PAGE: 'fullPage',
  JOIN_MODAL: 'joinModal',
  ON_BOARDING_TALK: 'onBoardingTalk',
  POST_DETAIL: 'postDetail',
  IMAGE_DETAIL: 'imageDetail',
  POST_MODAL: 'postModal',
  WRITING_MENU_MODAL: 'writingMenuModal',
  RECOMMEND_3_OTHER_GROUPS: 'recommend3OtherGroups',
  TALK_ROOM_INFO_MODAL: 'talkRoomInfoModal',
  SELECT_BOARD_TYPE_BOTTOM_SHEET: 'selectBoardTypeBottomSheet',
} as const;

export const modalState = {
  [MODAL_KEY.ACTION_MODAL]: false,
  [MODAL_KEY.FULL_PAGE]: false,
  [MODAL_KEY.JOIN_MODAL]: false,
  [MODAL_KEY.ON_BOARDING_TALK]: false,
  [MODAL_KEY.POST_DETAIL]: false,
  [MODAL_KEY.IMAGE_DETAIL]: false,
  [MODAL_KEY.POST_MODAL]: false,
  [MODAL_KEY.WRITING_MENU_MODAL]: false,
  [MODAL_KEY.RECOMMEND_3_OTHER_GROUPS]: false,
  [MODAL_KEY.TALK_ROOM_INFO_MODAL]: false,
  [MODAL_KEY.SELECT_BOARD_TYPE_BOTTOM_SHEET]: false,
};

export const profileDataState = {
  id: 0,
};

export type ModalSlice = {
  openWebviewModal: typeof modalState;
  idForProfileModal: typeof profileDataState;
  setOpenWebviewModal: (type: $Values<typeof MODAL_KEY>, value: boolean) => void;
  setIdForProfileModal: (id: number) => void;
};

export const createModalSlice: StoreSlice<ModalSlice> = (set) => ({
  openWebviewModal: modalState,
  idForProfileModal: profileDataState,
  setOpenWebviewModal: (type: $Values<typeof MODAL_KEY>, value: boolean) => {
    set((state) => ({
      ...state,
      openWebviewModal: {
        ...state.openWebviewModal,
        [type]: value,
      },
    }));
  },
  setIdForProfileModal: (id: number) => {
    set((state) => ({
      ...state,
      idForProfileModal: {
        id: id,
      },
    }));
  },
});
