import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

export const useGetSearchHotKeywords = () => {
  const fetchInstance = useFetchInstance();
  const getSearchHotKeywords = groupClient.api.SearchApi.apiV1GroupsSearchHotKeywordsGet({
    axios: fetchInstance,
  });

  const { data, refetch, isInitialLoading } = useQuery(
    [`${GROUP_URL}/search/hot-keywords`],
    getSearchHotKeywords,
    {
      suspense: true,
    }
  );

  const response = data?.data.keywords ?? [];

  return { data: response, refetch, isLoading: isInitialLoading };
};
