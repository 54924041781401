import {
  ActionableCallout as SproutActionableCallout,
  SeedActionableCalloutProps,
} from '@daangn/sprout-components-callout';

export const ActionableCallout = ({
  children,
  variant = 'normal',
  isDisabled,
  onClick,
  ...props
}: SeedActionableCalloutProps) => {
  return (
    <SproutActionableCallout variant={variant} isDisabled={isDisabled} onClick={onClick} {...props}>
      {children}
    </SproutActionableCallout>
  );
};
