import { vars } from '@seed-design/design-token';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import random from 'lodash-es/random';
import React from 'react';

import * as s from './index.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  borderRadius?: string;
  enableAnimation?: boolean;
  backgroundColor?: string;
}

const Skeleton: React.FC<Props> = ({
  width = '100%',
  height = '1rem',
  borderRadius = '0.125rem',
  enableAnimation = false,
  backgroundColor = vars.$scale.color.gray200,
  ...props
}: Props) => (
  <div
    {...props}
    className={clsx([
      props.className,
      s.wrapper({
        enableAnimation,
      }),
    ])}
    style={{
      ...props.style,
      ...assignInlineVars({ [s.animationTransformVar]: `scaleX(${random(0.7, 0.8)})` }),
      width,
      height,
      borderRadius,
      backgroundColor,
      animationDelay: `${random(0, 0.2).toString()}s`,
      animationDuration: `${random(1.4, 1.6).toString()}s`,
    }}
  />
);

export default Skeleton;
