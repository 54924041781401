import { AsyncBoundary, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { usePutGroup } from '@/api/hooks/usePutGroup';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { MAXIMUM_MEMBER_OPTIONS } from '@/components/group/new/RecruitmentCondition/constants/maximum-member-constants';
import useValidMaximumMemberInput from '@/components/group/new/RecruitmentCondition/hooks/use-validate-maximum-member-input';
import MaximumMemberChips from '@/components/group/new/RecruitmentCondition/maximumMember';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

export type GroupSettingEditMaximumMemberBottomSheetParams = Pick<
  PageParams,
  'groupId' | 'initialMaximumMemberCount' | 'currentMemberCount'
>;

const GroupSettingEditMaximumMemberBottomSheet: ActivityComponentType<
  GroupSettingEditMaximumMemberBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingEditMaximumMemberBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingEditMaximumMemberBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { initialMaximumMemberCount = '-1', currentMemberCount = '1' } = useQueryParams();

  const { pop } = useFlow();

  const initSelectedChipId = () => {
    if (initialMaximumMemberCount === '-1') {
      return 0;
    }

    const selectedIndex = MAXIMUM_MEMBER_OPTIONS.map((option, index) => {
      const maximumMemberCount = Number(initialMaximumMemberCount);
      if (initialMaximumMemberCount === undefined) return 0;

      if (option.value === maximumMemberCount) return index;

      return;
    }).filter((element) => element) as number[];

    if (selectedIndex.length === 0) return (MAXIMUM_MEMBER_OPTIONS.length - 1) as number;

    return selectedIndex[0] as number;
  };

  const [selectedChip, setSelectedChip] = useState(initSelectedChipId());
  const { register, watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      maximumMember: Number(initialMaximumMemberCount),
    },
  });

  const maximumMemberCountFieldValue = watch('maximumMember');
  const validateMaximumMemberValue = useValidMaximumMemberInput({
    watch,
    currentMemberCount: Number(currentMemberCount),
  });
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });

      bridge.openToast({
        toast: {
          body: '수정되었어요.',
        },
      });
    },
  });

  const handleSubmut = () => {
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          maximumMemberCount: maximumMemberCountFieldValue,
        },
      },
      {
        onSuccess: () => {
          pop();
        },
      }
    );
  };

  const isButtonDisabled = useMemo(() => {
    if (!validateMaximumMemberValue().isValid) return true;

    return false;
  }, [maximumMemberCountFieldValue, validateMaximumMemberValue().isValid]);

  return (
    <>
      <Spacing size={8} />
      <Typography typography="title3Bold" color="gray900">
        최대 가입 인원
      </Typography>
      <Spacing size={16} />
      <MaximumMemberChips
        alwaysShowInput={true}
        register={register}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        watch={watch}
        setValue={setValue}
      />
      <Spacing size={16} />
      <FixedButton disabled={isButtonDisabled} onClick={handleSubmut} isLoading={isLoading}>
        수정
      </FixedButton>
    </>
  );
};

export default GroupSettingEditMaximumMemberBottomSheet;
