import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode, useRef } from 'react';

import * as s from './index.css';

export interface Props {
  isOpen: boolean;
  children: ReactNode;
  dimDuration?: number;
}

export const BottomSheet = ({ children, dimDuration = 0.2 }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <motion.div
      className={clsx(['bottom-sheet', s.wrapper])}
      ref={ref}
      initial={{ height: 0 }}
      animate={{
        height: 'auto',
      }}
      transition={{
        type: 'spring',
        bounce: 0.1,
        duration: dimDuration,
      }}
      exit={{ height: 0 }}
    >
      {children}
    </motion.div>
  );
};
