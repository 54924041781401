import { useActivityParams } from '@stackflow/react';

export interface ActivityPathParams {
  groupId: string;
  postId: string;
  commentId: string;
  roomId: string;
  meetupId: string;
  challengeId: string;
  userId: string;
  query: string;
  pollId: string;
  code: string;
  chatChannelId: string;
  relatedId: string; // 댓글이 달린 대상의 ID
  relatedContentType: 'post' | 'meetup'; // 댓글이 달린 대상의 타입
  kidId: string;
}

export const usePathParams = (): ActivityPathParams => {
  return useActivityParams<ActivityPathParams>();
};
