import { UserMeetupMemberReviewMessage } from '@community-group/api/lib/group/models';
import { GroupAvatar, Typography } from '@community-group/components';
import { IconViewCountOffFill } from '@seed-design/icon';

import { ProfileMeetupMemberReviewMessageItemMoreButton } from '@/components/group/profile/MemberProfileDetail/components/MeetupMemberReviewMessageItem/MoreButton';
import { getDateDiffFromNow } from '@/utils/date';

import * as s from './style.css';

type Props = {
  meetupMemberReviewMessage: UserMeetupMemberReviewMessage;
  isMyProfile: boolean;
};

export const ReviewMessageItem = ({ meetupMemberReviewMessage, isMyProfile }: Props) => {
  const { author, reviewExposureRange, createdAt, reviewMessage, id } = meetupMemberReviewMessage;
  const isReadOnlyMe = reviewExposureRange === 'ONLY_ME';

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <div className={s.ProfileWrapper}>
          <GroupAvatar size="xsmall" src={author.profileImage} />
          <div className={s.TextWrapper}>
            <Typography typography="caption1Bold" color="gray900" ellipsisAfterLines={1}>
              {author.nickname}
            </Typography>
            {isReadOnlyMe ? (
              <Typography
                typography="caption1Regular"
                color="gray600"
                ellipsisAfterLines={1}
                style={{
                  minWidth: '4.6875rem',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {' ⸱ '}
                  <IconViewCountOffFill size={15} />
                  숨긴 후기
                </span>
              </Typography>
            ) : (
              <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
                {' ⸱ '}
                {getDateDiffFromNow(new Date(createdAt)).text} 전
              </Typography>
            )}
          </div>
        </div>
        {isMyProfile && (
          <ProfileMeetupMemberReviewMessageItemMoreButton
            reviewMessageId={id}
            reviewMessage={reviewMessage}
            reviewMessageExposureRange={reviewExposureRange}
          />
        )}
      </div>
      <div>
        <Typography
          typography="bodyM1Regular"
          ellipsisAfterLines={2}
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {reviewMessage}
        </Typography>
      </div>
    </div>
  );
};
