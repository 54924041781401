import { Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import VideoPlayerItem from '../components/VideoPlayerItem';

const ViewVideoPage: ActivityComponentType = () => {
  const { videoIds = '', content = '', imageIds = '' } = useQueryParams();

  const videoIdArr = videoIds.includes(',') ? videoIds.split(',') : videoIds ? [videoIds] : [];
  const imageIdArr = imageIds.includes(',') ? imageIds.split(',') : imageIds ? [imageIds] : [];

  const decodeVideoIds = videoIdArr.map((el) => decodeURIComponent(el));
  const decodeImageIds = imageIdArr.map((el) => el);

  console.log('videoIds', videoIds);
  console.log('imageIds', imageIds, imageIdArr, decodeImageIds);

  const decodeContent = decodeURIComponent(content);
  return (
    <AppScreen
      appBar={{
        title: '영상 보기',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '10px',

          whiteSpace: 'pre-wrap',
        }}
      >
        <Typography typography="bodyM1Regular">{decodeContent}</Typography>
        {decodeVideoIds.map((id) => (
          <VideoPlayerItem key={id} id={id} />
        ))}
        {decodeImageIds.map((id) => {
          return (
            <img
              key={id}
              src={getImageUrl(id)}
              alt="image"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '300px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          );
        })}
      </div>
    </AppScreen>
  );
};

export default ViewVideoPage;

const getImageUrl = (imageId: string) => {
  return `https://community-api-cdn.alpha.kr.karrotmarket.com/v1/resource/images/load?id=${imageId}&q=100&s=640x640&t=inside`;
};
