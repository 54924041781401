import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { isHigherManager } from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { usePutBoardManagedStatus } from '@/api/hooks/usePutBoardManagedStatus';
import { bridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow, useStepFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { SpecificEventType } from '@/utils/analytics/type';
import { getPermalink, openGroupReport, openGuideBook } from '@/utils/link';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import { useGroupDetailContext } from '../components/GroupDetailContext';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const useGroupDetailSidebar = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const trackEventWithParams = useCallback(
    (eventName: SpecificEventType) => {
      trackEvent({
        event: eventName,
        params: {
          groupId,
          groupName: group.name,
          groupCategoryName: group.category.name,
          isGroupCategoryOn: group.isBoardManaged,
          role: currentUser.role,
          version: '2',
        },
        sample: true,
      });
    },
    [groupId, group.name, group.category.name, group.isBoardManaged, currentUser.role]
  );

  const { openSidebar } = useQueryParams();
  const isSidebarOpened = openSidebar === 'true';
  const { setSelectedBoardKey } = useGroupDetailContext();

  const step = useStepFlow('GroupDetailPage');
  const activity = useFlow();
  const handleBoardItemChange = (id: number) => () => {
    trackEventWithParams('click_sidebar_board_category');
    step.stepPop();
    setSelectedBoardKey(id, true);
  };

  const handleSidebarClose = () => {
    step.stepPop();
  };
  const handleProfileClick = () => {
    trackEventWithParams('click_sidebar_my_profile');
    step.stepPop();
    activity.push('GroupUserProfileDetailPage', {
      groupId,
      userId: currentUser.id.toString(),
    });
  };
  const handleAlbumMenuClick = () => {
    trackEventWithParams('click_sidebar_album');
    step.stepPop();
    activity.push('GroupDetailAlbumPage', { groupId });
  };
  const handleMemberMenuClick = () => {
    trackEventWithParams('click_sidebar_members');
    step.stepPop();
    if (isHigherManager(currentUser.role)) {
      activity.push('GroupMemberListForHostPage', {
        groupId,
      });
    } else {
      activity.push('GroupMemberListPage', {
        groupId,
      });
    }
  };
  const handleSettingMenuClick = () => {
    trackEventWithParams('click_sidebar_settings');
    step.stepPop();
    activity.push('GroupSettingPage', {
      groupId,
    });
  };
  const handleApplicationBannerClick = () => {
    step.stepPop();
    activity.push('GroupMemberListForHostPage', {
      groupId,
      tab: 'pending',
    });
  };
  const handleMissionBannerClick = () => {
    trackEventWithParams('click_sidebar_mission_banner');
    step.stepPop();
  };
  const handleHomeMenuClick = () => {
    trackEventWithParams('click_sidebar_home');
    handleBoardItemChange(0)();
  };
  const handleMeetupMenuClick = () => {
    trackEventWithParams('click_sidebar_meetup');
    step.stepPop();
    activity.push('GroupDetailMeetupPage', {
      groupId,
    });
  };
  const handleNoticeMenuClick = () => {
    trackEventWithParams('click_sidebar_notices');
    step.stepPop();
    activity.push('GroupDetailNoticeMorePage', {
      groupId,
    });
  };
  const handleEditBoardButtonClick = () => {
    trackEventWithParams('click_sidebar_board_settings');
    step.stepPop();
    activity.push('BoardCategorySetting', {
      groupId: groupId,
    });
  };
  const handleBoardItemClick = handleBoardItemChange;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate } = usePutBoardManagedStatus({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupDetail({ groupId });
    },
  });
  const handleCreateBoardButtonClick = () => {
    trackEventWithParams('click_sidebar_add_board');
    if (!group.isBoardManaged) {
      mutate({
        id: Number(groupId),
        postBoardManagedForm: {
          isBoardManaged: true,
        },
      });
    }
    step.stepPop();
    activity.push('BoardCategorySetting', {
      groupId: groupId,
      // FIXME: 게시판 만들기 바텀시트가 열린 상태가 되도록 수정
    });
  };
  const handleReportButtonClick = () => {
    trackEventWithParams('click_sidebar_report');
    step.stepPop();
    openGroupReport({
      groupId,
    });
  };
  const handleGuideBookButtonClick = () => {
    trackEventWithParams('click_sidebar_guide_book');
    step.stepPop();
    openGuideBook();
  };
  const handleShareButtonClick = (permalinkId: string) => () => {
    trackEventWithParams('click_sidebar_share');
    bridge.share({
      share: {
        url: getPermalink(permalinkId),
        text: `${group.name} 모임 공유하기`,
      },
    });
  };

  const handleGroupLevelBannerClick = () => {
    trackEventWithParams('click_sidebar_level_banner');
    step.stepPop();
    activity.push('GroupLevelDetailPage', {
      groupId,
    });
  };

  return {
    isOpened: isSidebarOpened,
    onClickClose: handleSidebarClose,
    onProfileClick: handleProfileClick,
    onAlbumMenuClick: handleAlbumMenuClick,
    onMemberMenuClick: handleMemberMenuClick,
    onSettingMenuClick: handleSettingMenuClick,
    onApplicationBannerClick: handleApplicationBannerClick,
    onMissionBannerClick: handleMissionBannerClick,
    onHomeMenuClick: handleHomeMenuClick,
    onMeetupMenuClick: handleMeetupMenuClick,
    onNoticeMenuClick: handleNoticeMenuClick,
    onEditBoardButtonClick: handleEditBoardButtonClick,
    onBoardItemClick: handleBoardItemClick,
    onCreateBoardButtonClick: handleCreateBoardButtonClick,
    onReportButtonClick: handleReportButtonClick,
    onGuideBookButtonClick: handleGuideBookButtonClick,
    onShareButtonClick: handleShareButtonClick,
    onGroupLevelBannerClick: handleGroupLevelBannerClick,
  };
};

export default useGroupDetailSidebar;
