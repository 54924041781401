import { model } from '@community-group/api/lib/group';
import { AsyncBoundary, Typography, ViewLoader } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconAddRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';

import { useGetMyRoomList } from '@/api/hooks/apartment/useGetMyRoomList';
import { useGetRoomList } from '@/api/hooks/apartment/useGetRoomList';
import { LoadMoreMyRoomList, LoadMoreRoomList } from '@/components/common/LoadMoreContainer';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import RoomItem from './components/Item';
import useInformationPreEnterRoom from './hooks/useInformationPreEnterRoom';
import * as s from './index.css';

const RoomList = () => {
  return (
    <div>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <RoomListWrapper />
      </AsyncBoundary>
    </div>
  );
};

const RoomListWrapper = () => {
  const { groupId } = usePathParams();
  const { data, hasNextPage } = useGetRoomList(Number(groupId));
  const { data: myRoom, hasNextPage: hasNextMyRoom } = useGetMyRoomList(Number(groupId));
  const { push } = useFlow();

  const { openForm: openInformationModal } = useInformationPreEnterRoom(groupId);

  const isRoomEmpty = useMemo(
    () => data?.pages[0].data.talkRooms && data?.pages[0].data.talkRooms.length < 1,
    [data]
  );
  const isMyRoomEmpty = useMemo(
    () => myRoom?.pages[0].data.talkRooms && myRoom?.pages[0].data.talkRooms.length < 1,
    [myRoom]
  );

  const renderEmptyMessage = useCallback(() => {
    if (!isRoomEmpty || !isMyRoomEmpty) return null;

    return (
      <div className={s.EmptyMessageWrapper}>
        <Typography typography="bodyM1Regular" color="gray600">
          참여할 수 있는 채팅방이 없어요.
        </Typography>
      </div>
    );
  }, [isRoomEmpty, isMyRoomEmpty]);

  const renderMyRoom = useCallback(() => {
    if (isMyRoomEmpty) return null;

    return (
      <>
        {myRoom && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography className={s.Title} typography="subtitle1Bold" color="gray900">
              참여 중
            </Typography>
            <div className={s.List}>
              {myRoom &&
                myRoom.pages.map((page) =>
                  page.data.talkRooms?.map((room) => (
                    <RoomItem
                      roomId={room.roomId}
                      key={room.roomId}
                      description={room.description}
                      name={room.name}
                      isNew={room.lastTalkInfo?.isNew}
                      imageSrc={room.profileImage?.small}
                      lastTalkInfo={room.lastTalkInfo}
                      type="my"
                      notificationState={room.talkNotificationState}
                      memberCount={room.memberCount}
                      admissionType={room.admissionType}
                      roomType={room.roomType}
                      chatInfo={room.channelInfo}
                      profileImages={room.someOfMemberProfileImages}
                    />
                  ))
                )}
            </div>
            {hasNextMyRoom && <LoadMoreMyRoomList />}
          </motion.div>
        )}
      </>
    );
  }, [isMyRoomEmpty, myRoom, hasNextMyRoom]);

  const renderRoom = useCallback(() => {
    if (isRoomEmpty) return null;

    const handleClickJoin = async (room: model.TalkRoomPresentation) => {
      await openInformationModal(room);
    };

    return (
      <>
        {data && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography className={s.Title} typography="subtitle1Bold" color="gray900">
              전체
            </Typography>
            <div className={s.List}>
              {data &&
                data.pages.map((page) =>
                  page.data.talkRooms?.map((room) => (
                    <div key={room.roomId} onClick={() => handleClickJoin(room)}>
                      <RoomItem
                        roomId={room.roomId}
                        description={room.description}
                        name={room.name}
                        memberCount={room.memberCount}
                        imageSrc={room.profileImage?.small}
                        type="all"
                        admissionType={room.admissionType}
                        roomType={room.roomType}
                        profileImages={room.someOfMemberProfileImages}
                        chatInfo={room.channelInfo}
                      >
                        {!room.isJoined && (
                          <BoxButton
                            variant="secondary"
                            size="medium"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClickJoin(room);
                            }}
                          >
                            입장
                          </BoxButton>
                        )}
                      </RoomItem>
                    </div>
                  ))
                )}
            </div>
            {hasNextPage && <LoadMoreRoomList />}
          </motion.div>
        )}
      </>
    );
  }, [isRoomEmpty, data, hasNextPage, openInformationModal]);

  const handleCreateButton = () => {
    push('TalkRoomNewInformationPage', {
      groupId,
    });
  };

  const currentUser = useCurrentGroupMe();

  const isManager = () => {
    return currentUser?.role === 'superHost' || currentUser?.role === 'manager';
  };

  return (
    <div className={s.Wrapper}>
      {isManager() && (
        <div className={s.ButtonWrapper}>
          <BoxButton
            prefix={
              <div className={s.IconWrapper}>
                <IconAddRegular size={16} fill={vars.$scale.color.gray900} />
              </div>
            }
            size="large"
            variant="secondary"
            width="100%"
            onClick={handleCreateButton}
          >
            채팅방 만들기
          </BoxButton>
        </div>
      )}
      {renderMyRoom()}
      {renderRoom()}
      {renderEmptyMessage()}
      <Spacing size={80} />
    </div>
  );
};
export default RoomList;
