import { GroupSuperHostPresentation } from '@community-group/api/lib/group/models';

export const validHostInfoData = (hostInfo: GroupSuperHostPresentation | undefined) => {
  if (!hostInfo) return false;

  if (
    !hostInfo?.id ||
    !hostInfo?.profileImage ||
    !hostInfo?.nickname ||
    !hostInfo?.userRegionCheckIn
  )
    return false;

  return true;
};
