import { isNotMember, isSuperHost } from '@community-group/components';
import { useCallback } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetMe } from '@/api/hooks/useGetMe';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useStorage } from '@/hooks/useStorage';

import { useHandleVerificationBottomSheet } from '../../new/Verification/hooks/useHandleVerificationBottomSheet';

interface Props {
  groupId: string;
}

const useCheckNeedToVerificationRequiredAfterSuperHostUpdate = ({ groupId }: Props) => {
  const { group } = useGetGroupDetail(groupId);
  const { data: me } = useGetMe();
  const currentUser = useCurrentGroupMe();
  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  const [isIdentityVerificationNoticeCheckedStorage] = useStorage(
    'isIdentityVerificationNoticeChecked',
    {
      [groupId]: false,
    }
  );

  const isIdentityVerificationNoticeChecked = isIdentityVerificationNoticeCheckedStorage?.[groupId];

  const checkNeedToVerificationRequiedAfterSuperHost = useCallback(() => {
    if (isNotMember(currentUser.role)) return false;
    if (!group?.needVerification && !group?.canViewVerifiedUserInfo) return false;
    if (isSuperHost(currentUser.role)) return false;
    if (isIdentityVerificationNoticeChecked) return false;

    // 정보공개 모임이면서 인증된 사용자가 아닐 때
    if (group.canViewVerifiedUserInfo && !me?.verified) {
      handleVerificationBottomSheet({
        certificationCandidateType:
          'joined_group_with_user_info_and_not_verified_leader_update_state',
        groupIdForChangeVerification: groupId,
      });
      return true;
    }

    // 본인인증모임이면서 인증된 사용자가 아닐 때
    if (group.needVerification && !group.canViewVerifiedUserInfo && !me?.verified) {
      handleVerificationBottomSheet({
        certificationCandidateType: 'joined_group_and_not_verified_leader_update_state',
        groupIdForChangeVerification: groupId,
      });
      return true;
    }

    // 정보공개 모임이면서 인증된 사용자일 때
    if (
      group.canViewVerifiedUserInfo &&
      me?.verified &&
      group.verificationStatusFromSuperHostUpdated
    ) {
      handleVerificationBottomSheet({
        certificationCandidateType: 'joined_group_with_user_info_and_verified_leader_update_state',
        groupIdForChangeVerification: groupId,
      });
      return true;
    }

    // 본인인증모임이면서 인증된 사용자일 때
    if (
      group.needVerification &&
      !group.canViewVerifiedUserInfo &&
      me?.verified &&
      group.verificationStatusFromSuperHostUpdated
    ) {
      handleVerificationBottomSheet({
        certificationCandidateType: 'joined_group_and_verified_leader_update_state',
        groupIdForChangeVerification: groupId,
      });
      return true;
    }
    return false;
  }, [
    currentUser.role,
    group?.needVerification,
    group?.canViewVerifiedUserInfo,
    group?.verificationStatusFromSuperHostUpdated,
    isIdentityVerificationNoticeChecked,
    me?.verified,
    handleVerificationBottomSheet,
    groupId,
  ]);

  return checkNeedToVerificationRequiedAfterSuperHost;
};

export default useCheckNeedToVerificationRequiredAfterSuperHostUpdate;
