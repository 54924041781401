import { useEffect, useMemo, useState } from 'react';

import { isClient } from '../../utils';
import { useKeyboardState } from '../useKeyboardState';

function useKeyboardSize(bridge?: any, backSwipable = false) {
  const { opened, keyboardHeight } = useKeyboardState();

  const [visualViewportOffsetTop, setVisualViewportOffsetTop] = useState(
    isClient() ? window.visualViewport?.offsetTop ?? 0 : 0
  );

  useEffect(() => {
    let offsetTopTimer: number | undefined = undefined;
    const resizeHandler = () => {
      offsetTopTimer = window.setTimeout(() => {
        setVisualViewportOffsetTop(window.visualViewport?.offsetTop ?? 0);
      }, 150);

      return () => offsetTopTimer && clearTimeout(offsetTopTimer);
    };
    window.visualViewport?.addEventListener('resize', resizeHandler);
    window.visualViewport?.addEventListener('scroll', resizeHandler);
    return () => {
      window.visualViewport?.removeEventListener('resize', resizeHandler);
      window.visualViewport?.removeEventListener('scroll', resizeHandler);
    };
  }, [keyboardHeight, opened]);

  return { keyboardHeight, visualViewportOffsetTop, isKeyboardOn: opened };
}

export default useKeyboardSize;
