import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { GroupMeetupDetailPresentation } from '@community-group/api/lib/group/models';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { GROUP_URL } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

type OptionType = UseQueryOptions<
  AxiosResponse<model.GroupMeetupDetailResponse, any> | undefined,
  unknown,
  AxiosResponse<model.GroupMeetupDetailResponse, any> | undefined,
  string[]
>;

export const getGroupMeetupDetailPath = (groupId: string, meetupId: string) =>
  `${GROUP_URL}/${groupId}/meetups/${meetupId}`;

type Params = {
  groupId: string;
  meetupId: string;
};

export const useGetGroupMeetupDetail = ({ groupId, meetupId }: Params, options?: OptionType) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetupDetail = groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdGet({
    axios: fetchInstance,
  });

  const { data, isLoading, isError, refetch } = useQuery(
    [getGroupMeetupDetailPath(groupId, meetupId)],
    () => {
      return getGroupMeetupDetail(Number(groupId), Number(meetupId));
    },
    {
      ...options,
      suspense: options?.suspense ?? true,
      enabled: !!meetupId,
      useErrorBoundary: true,
    }
  );

  const response = data?.data.groupMeetup as GroupMeetupDetailPresentation;

  return { data: response, isLoading, isError, refetch };
};
