import { axiosClient } from '@community-group/api';

import { wrapSSRErrorHandler } from '@/api/instance/axios';

import { useKarrotCustomHeader } from './useKarrotCustomHeader';

export const useXPropertyFetchInstance = () => {
  const karrotCustomHeader = useKarrotCustomHeader();

  return wrapSSRErrorHandler(
    axiosClient.initFetchInstance(
      {
        baseURL: globalThis.appConfig.apiEndPoints.xproperty,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      {
        'X-Auth-Token': karrotCustomHeader['X-Auth-Token'],
      }
    )
  );
};
