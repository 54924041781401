import React, { ReactNode } from 'react';

import * as s from './NoMoreContentsBox.css';

interface Props {
  infoText: string;
  button: ReactNode;
  paddingTop?: string;
  paddingBottom?: string;
}

const NoMoreContentsBox = ({
  infoText,
  button,
  paddingBottom = '7.5rem',
  paddingTop = '0rem',
}: Props) => {
  return (
    <div
      className={s.noMeetupWrapper}
      style={{
        paddingTop,
        paddingBottom,
      }}
    >
      <p className={s.text}>{infoText}</p>
      {button}
    </div>
  );
};

export default NoMoreContentsBox;
