import { Dialog, Typography, useBottomSheet, useDialog } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular, IconCloseRegular } from '@seed-design/icon';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { getPhotoContestEntriesPath } from '@/api/hooks/useGetPhotoContestEntries';
import { queryClient } from '@/api/instance';
import { useFlow } from '@/stackflow';

import * as s from './photoContestNewGroupPost.css';

export const useHandlePhotoContestNewGroupPost = () => {
  const { push } = useFlow();
  const { push: asyncPush } = useConnectedActions();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const { data: myGroups } = useGetMyGroupList();

  const handleBottomSheetItemClick = async (groupId: string) => {
    await closeBottomSheet();
    await asyncPush('GroupPostNewPage', { groupId, from: 'groupPhotoContest' });

    queryClient.refetchQueries([getPhotoContestEntriesPath()]);
  };

  const handleNewGroupPost = () => {
    const myGroupList = myGroups?.groups ?? [];
    if (myGroupList.length > 0) {
      return openBottomSheet({
        element: (
          <div className={s.BottomSheetWrapper}>
            <div className={s.BottomSheetHeader}>
              <Typography as="h2" typography="title2Bold" color="gray900">
                모임 선택
              </Typography>
              <IconCloseRegular
                size={24}
                color={vars.$scale.color.gray900}
                onClick={closeBottomSheet}
              />
            </div>
            <div className={s.BottomSheetContent}>
              <ul className={s.BottomSheetList}>
                {myGroupList.map(({ profileImage, id, name }) => (
                  <li
                    className={s.ItemWrapper}
                    key={id}
                    onClick={() => {
                      handleBottomSheetItemClick(id.toString());
                    }}
                  >
                    <img
                      src={profileImage.small}
                      width={48}
                      height={48}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '0.375rem',
                      }}
                    />
                    <Typography
                      as="p"
                      typography="bodyM1Regular"
                      color="gray900"
                      ellipsisAfterLines={1}
                      style={{ width: '100%' }}
                    >
                      {name}
                    </Typography>
                    <IconChevronRightRegular size={16} color={vars.$scale.color.gray600} />
                  </li>
                ))}
                <p className={s.BottomSheetFooter} />
              </ul>
            </div>
          </div>
        ),
      });
    }

    // 다이얼로그
    openDialog({
      element: (
        <Dialog
          title="가입한 모임이 없어요"
          description="모임에 가입하고 게시글을 남겨보세요."
          primaryActionLabel="모임 둘러보기"
          onPrimaryAction={async () => {
            await closeDialog();
            push('HomePage', {});
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          secondaryActionIntent="nonpreferred"
        />
      ),
    });
  };

  return handleNewGroupPost;
};
