import {
  GroupCurrentUserPermissions,
  GroupPermissionsResponsePermissions,
} from '@community-group/api/lib/group/models';
import { $Keys } from 'utility-types';

interface Props {
  permission: $Keys<GroupPermissionsResponsePermissions>;
  currentPermission?: GroupCurrentUserPermissions;
  children: React.ReactNode;
}

const CheckAccessiblePermission = ({ permission, currentPermission, children }: Props) => {
  const hasPermission = currentPermission?.[permission];

  if (hasPermission) {
    return <>{children}</>;
  }

  return <></>;
};

export default CheckAccessiblePermission;
