import { useRef } from 'react';

import { useStorage } from '@/hooks/useStorage';

const useShownMemberMissionCompletedMessage = (
  targetId: string
): [boolean, (nextValue: boolean) => void] => {
  const [storage, setStorage] = useStorage('memberOnboardingMissionCompleted', {
    [targetId]: false,
  });

  const storageRef = useRef(storage?.[targetId] ?? false);
  const setStorageRef = useRef((nextValue: boolean) => {
    setStorage({
      ...storage,
      [targetId]: nextValue,
    });
  });

  return [storageRef.current, setStorageRef.current];
};

export default useShownMemberMissionCompletedMessage;
