import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { $Values } from 'utility-types';

import { GROUP_URL, ORDER_SEARCH } from '../base/group';
import { useFetchInstance } from './instance/useFetchInstance';

const getSearchGroupListKey = (
  query: string,
  category: number | number[] | undefined,
  order: string | undefined,
  referrer: string,
  splitSearch = false
) =>
  `${GROUP_URL}/search?query=${query}&category=${category}&order=${order}&referrer=${referrer}&splitSearch=${splitSearch}`;

export const useGetSearchGroupList = ({
  query,
  order,
  category,
  referrer,
  queryId,
  splitSearch,
}: {
  query: string;
  category?: number | number[];
  order?: $Values<typeof ORDER_SEARCH>;
  referrer: string;
  queryId?: string;
  splitSearch?: boolean;
}) => {
  const fetchInstance = useFetchInstance();

  const getSearchGroupList = groupClient.api.SearchApi.apiV2GroupsSearchGet({
    axios: fetchInstance,
  });

  const getCategoryParams = () => {
    if (typeof category === 'number') return [Number(category)];

    return category;
  };

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [getSearchGroupListKey(query, getCategoryParams(), order, referrer, splitSearch)],
    ({ pageParam = null }) =>
      getSearchGroupList(
        decodeURIComponent(query),
        pageParam ? pageParam : undefined,
        20,
        order,
        getCategoryParams(),
        'group',
        referrer,
        queryId,
        splitSearch
      ),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
