import { Spacing, Typography } from '@community-group/components';

import PhotoContestEntryList from './PhotoContestEntryList';
import * as s from './PhotoContestResultSection.css';

const PhotoContestResult = () => {
  const bannerImgUrl =
    'https://assetstorage.krrt.io/1025317940251365954/51f5ab0c-e668-4081-a3f5-dbf0bae4357b/width=1029,height=840.png';

  return (
    <>
      <img src={bannerImgUrl} className={s.Image} />
      <Spacing size={32} />
      <div className={s.TitleContainer}>
        <Typography typography="title3Bold" color="gray900">
          지난 참여작 📸
        </Typography>
        <Spacing size={2} />
        <Typography typography="caption1Regular" color="gray600">
          최종 수상자는 게시글의 공감 수와 운영진 평가 점수를 합산하여 선정될 예정이에요.
        </Typography>
      </div>

      <PhotoContestEntryList isContestResult />
    </>
  );
};

export default PhotoContestResult;
