import { FieldValues, UseFormWatch } from 'react-hook-form';

type Props = {
  watch: UseFormWatch<FieldValues>;
  currentMemberCount?: number;
};

const MINIMUM_MEMBER = 3;
const MAXIMUM_MEMBER = 2000;

const useValidMaximumMemberInput = ({ watch, currentMemberCount }: Props) => {
  const maximumMemberFieldValue = watch('maximumMember');

  const validateMaximumMemberValue = () => {
    if (!maximumMemberFieldValue)
      return { isValid: false, helperText: '최대 인원을 입력해주세요.' };
    if (isNaN(maximumMemberFieldValue)) return { isValid: false, helperText: undefined };

    if (maximumMemberFieldValue === -1) return { isValid: true, helperText: undefined };
    if (currentMemberCount && parseInt(maximumMemberFieldValue ?? '0', 10) < currentMemberCount) {
      return {
        helperText: '모임에 가입한 인원보다 적게 설정할 수 없어요.',
        isValid: false,
      };
    }
    if (parseInt(maximumMemberFieldValue ?? '0', 10) < MINIMUM_MEMBER)
      return {
        helperText: '최소 인원은 3명이에요.',
        isValid: false,
      };

    if (parseInt(maximumMemberFieldValue.toString() ?? '0', 10) > MAXIMUM_MEMBER)
      return {
        helperText: '최대 인원은 2000명이에요.',
        isValid: false,
      };
    return {
      isValid: true,
      helperText: '',
    };
  };

  return validateMaximumMemberValue;
};

export default useValidMaximumMemberInput;
