import { GroupMeetupPoiPresentation } from '@community-group/api/lib/group/models';
import { PlaceTagMap } from '@community-group/components';
import { useMemo } from 'react';

import { Position } from '@/hooks/useCurrentPosition';
import { calculateDistance } from '@/utils/geolocation/distance';

import * as s from './index.css';

type Props = {
  poi: GroupMeetupPoiPresentation;
  currentPosition?: Position;
  onCardClick: () => void;
};

export const PoiMapCard = ({ poi, currentPosition, onCardClick }: Props) => {
  const calculatedDistance = currentPosition
    ? calculateDistance({
        userPosition: currentPosition,
        poiPosition: poi.coordinates,
      })
    : undefined;

  const regionText = useMemo(() => {
    if (poi?.region?.name) {
      return `${poi.region.city} ${poi.region.town}`;
    }

    if (poi?.address) {
      return poi.address;
    }
    return '';
  }, [poi]);

  return (
    <div className={s.wrapper} onClick={onCardClick}>
      <PlaceTagMap
        theme="auto"
        placeTag={{
          id: poi.id.toString(),
          region: poi?.region
            ? {
                id: poi.region.id.toString(),
                displayName: poi.region.name,
              }
            : undefined,
          name: poi.name,
          address: poi.address,
          coordinates: poi.coordinates,
        }}
      />
      <div className={s.body}>
        <div className={s.contents}>
          <div className={s.titleWrapper}>
            <h6 className={s.title}>{poi.name}</h6>
            <span className={s.label}>{poi?.categoryName}</span>
          </div>
          <p className={s.description}>
            {currentPosition && (
              <span>{`${calculatedDistance?.distance}${calculatedDistance?.unit}  ⸱ `}</span>
            )}
            {regionText}
            {poi?.address && poi.reviewPostCount > 0 && ` ⸱ `}
            {poi.reviewPostCount > 0 && `후기 ${poi.reviewPostCount}`}
          </p>
        </div>
      </div>
    </div>
  );
};
