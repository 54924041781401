import { ParentingGroupKidGenderEnum } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Divider,
  Radio,
  RadioGroup,
  Spacing,
  Typography,
  VerticalSpacing,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconAddRegular, IconTrashRegular } from '@seed-design/icon';
import { FieldValues, useFieldArray, useFormContext, UseFormRegister } from 'react-hook-form';

import { FormInput } from '@/components/common/base/Input/Text';
import { groupMemberProfileFieldSchema } from '@/utils/validate/formSchema/subNickname';
import { validateSchemaWithMessage } from '@/utils/validate/util';

import { DEFAULT_KID_PROFILE, KID_COUNT_TEXT } from '../constants';
import * as s from './ParentingGroupKidProfileFormList.css';

const ParentingGroupKidProfileFormList = () => {
  const {
    register,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const registerWithType = register as unknown as UseFormRegister<FieldValues>;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'kids',
    rules: { maxLength: 10 },
  });
  const handleAddKidProfile = () => append(DEFAULT_KID_PROFILE);
  const handleRemoveKidProfile = (index) => () => remove(index);

  return (
    <>
      <ul>
        {fields.map((field, index) => {
          const hasSecondKid = fields.length > 1;
          const isFirstKid = index === 0;
          const isNotLastKid = index !== fields.length - 1;

          return (
            <li key={field.id}>
              {hasSecondKid && (
                <>
                  <div className={s.KidCountTextContainer}>
                    <Typography typography="subtitle1Bold" color="gray900">
                      {KID_COUNT_TEXT[index]} 아이
                    </Typography>
                    {index > 0 && (
                      <IconTrashRegular
                        size={24}
                        color={vars.$scale.color.gray900}
                        onClick={handleRemoveKidProfile(index)}
                      />
                    )}
                  </div>
                  <Spacing size={32} />
                </>
              )}
              <div className={s.TextContainer}>
                {isFirstKid ? (
                  <>
                    <Typography typography="label3Bold" color="gray900">
                      아이 생년월일
                    </Typography>
                    <VerticalSpacing size={4} />
                    <Typography typography="label3Regular" color="gray900">
                      (8자리)
                    </Typography>
                  </>
                ) : (
                  <Typography typography="label3Bold" color="gray900">
                    생년월일
                  </Typography>
                )}
              </div>
              {isFirstKid && (
                <>
                  <Spacing size={4} />
                  <Typography typography="label3Regular" color="gray600">
                    예비맘은 출산 예정일을 입력해주세요.
                  </Typography>
                </>
              )}
              <Spacing size={12} />
              <FormInput
                type="number"
                name={`kids.${index}.birthday`}
                register={registerWithType}
                placeholder="YYYYMMDD"
                onKeyUpCapture={() => {
                  const targetName = `kids.${index}.birthday`;
                  const result = validateSchemaWithMessage(
                    groupMemberProfileFieldSchema.kidProfileBirthday,
                    watch(targetName)
                  );
                  if (!result.success) return;
                  clearErrors(targetName);
                }}
                onBlurCapture={(e) => {
                  const result = validateSchemaWithMessage(
                    groupMemberProfileFieldSchema.kidProfileBirthday,
                    watch(e.target.name)
                  );

                  if (result.success) return;
                  setError(e.target.name, {
                    type: 'pattern',
                    message: result.message,
                  });
                }}
                isInvalid={Boolean(errors?.kids?.[index]?.birthday)}
                errorMessage={errors?.kids?.[index]?.birthday.message.toString()}
              />
              <Spacing size={32} />
              <Typography typography="label3Bold" color="gray900">
                아이 성별
              </Typography>
              <Spacing size={12} />
              <RadioGroup
                size="large"
                orientation="horizontal"
                defaultValue={watch(`kids.${index}.gender`)}
                onChange={(value) =>
                  setValue(`kids.${index}.gender`, value as ParentingGroupKidGenderEnum)
                }
              >
                <div className={s.RadioGroup}>
                  <Radio value="male" UNSAFE_className={s.RadioButton}>
                    남자
                  </Radio>
                  <Radio value="female" UNSAFE_className={s.RadioButton}>
                    여자
                  </Radio>
                </div>
              </RadioGroup>
              {hasSecondKid && isNotLastKid && (
                <>
                  <Spacing size={32} />
                  <Divider />
                  <Spacing size={32} />
                </>
              )}
            </li>
          );
        })}
      </ul>
      {KID_COUNT_TEXT.length > fields.length && (
        <>
          <Spacing size={32} />
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            prefix={<IconAddRegular size={16} color={vars.$scale.color.gray900} />}
            onClick={handleAddKidProfile}
          >
            {fields.length === 1 ? '둘째 추가하기' : '아이 추가하기'}
          </BoxButton>
        </>
      )}
    </>
  );
};

export default ParentingGroupKidProfileFormList;
