import {
  formatDate,
  Spacing,
  TextButton,
  Typography,
  VerticalSpacing,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconEditFill } from '@seed-design/icon';
import { isFuture } from 'date-fns';

import { useGetParentingGroupKids } from '@/api/hooks/useGetParentingGroupKids';
import { KID_COUNT_TEXT } from '@/components/group/ParentingGroup/constants';
import { useFlow } from '@/stackflow';

import * as s from './ParentingGroupKidProfile.css';

type Props = {
  groupId: string;
};

const ParentingGroupKidProfile = ({ groupId }: Props) => {
  const { data } = useGetParentingGroupKids(groupId);
  const { push } = useFlow();
  const handleEdit = () => {
    push('ParentingGroupKidProfileListPage', {
      groupId,
    });
  };

  if (!data || data.kids.length === 0) return null;

  const showKidNumberText = data.kids.length > 1;
  const totalCount = data.kids.length;

  return (
    <>
      <Spacing size={16} />
      <div className={s.TitleContainer}>
        <Typography typography="subtitle2Bold" color="gray900">
          아이 정보
        </Typography>
        <TextButton variant="secondaryLow" size="small" bold onClick={handleEdit}>
          <IconEditFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing size={4} />
          수정
        </TextButton>
      </div>
      <Spacing size={8} />
      <div className={s.Content}>
        {data.kids.map((kid, index) => {
          const birthday = new Date(kid.birthday);
          const postfixText = isFuture(birthday) ? '예정' : '출생';
          const birthdayText = formatDate(birthday, `yyyy년 M월 d일 ${postfixText}`);
          const genderText = kid.gender === 'male' ? '남자' : '여자';
          const isLast = index === totalCount - 1;

          return (
            <>
              <div key={kid.id} className={s.KidProfileTextContainer}>
                {showKidNumberText && (
                  <>
                    <Typography typography="bodyL2Bold" color="gray900">
                      {KID_COUNT_TEXT[index]}
                    </Typography>
                    <VerticalSpacing size={8} />
                  </>
                )}
                <Typography typography="bodyL2Regular" color="gray900">
                  {`${birthdayText} ⸱ ${genderText}`}
                </Typography>
              </div>
              {!isLast && <Spacing size={6} />}
            </>
          );
        })}
      </div>
    </>
  );
};

export default withAsyncBoundary(ParentingGroupKidProfile, {});
