import { useEffect, useMemo } from 'react';

import { useStorage } from './useStorage';
import { useXproperty } from './useXproperty';

export const useSetParentingGroupStorage = () => {
  const xProperty = useXproperty();
  const parentingGroup = useMemo(() => {
    const participants = xProperty?.groupMomGrowthParticipantGroupIds?.participants ?? [];
    return Object.fromEntries(
      new Map(
        participants.map((participant) => [
          participant.groupId,
          {
            isTargetGroup: true,
            isKidProfileEnabled: participant.kidProfileEnabled,
          },
        ])
      )
    );
  }, [xProperty?.groupMomGrowthParticipantGroupIds?.participants]);

  const [_, setParentingGroupStorage] = useStorage('parentingGroup', {});

  useEffect(() => {
    setParentingGroupStorage(parentingGroup);
  }, [parentingGroup, setParentingGroupStorage]);
};
