import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { AppScreen } from '@/stackflow/components/AppScreen';
import { NotFoundView } from '@/stackflow/components/NotFoundView';

const NotFoundPage = () => {
  useEffect(() => {
    if (globalThis.appConfig.isAppEnvProduction) {
      Sentry.captureMessage('404 Page Not Found', {
        extra: {
          pathname: location.pathname,
        },
      });
    }
  }, []);

  return (
    <AppScreen>
      <NotFoundView />
    </AppScreen>
  );
};

export default NotFoundPage;
