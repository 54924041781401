import { groupClient } from '@community-group/api';

import { queryClient } from '@/api/instance';

/*
 *  현재 프로필 상태를 refetch하는 함수
 */
export const refetchGroupProfile = () => {
  queryClient.refetchQueries({
    queryKey: ['MyMainProfile'],
  });
  queryClient.refetchQueries([
    'GroupProfleCheck',
    groupClient.api.GroupProfileApi.getapiV1GroupsProfileCheckGetPath(),
  ]);
  queryClient.refetchQueries([
    'GroupProfile',
    groupClient.api.GroupProfileApi.getapiV1GroupsProfileGetPath(),
  ]);
};
