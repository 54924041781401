import { AppScreen, AppScreenProps } from '@/stackflow/components/AppScreen';
import Indicator from '@/stackflow/components/AppScreen/Indicator';

type Props = Omit<AppScreenProps, 'accessoryBar'> & {
  indicator?: {
    maxCount: number;
    count: number;
  };
  accessoryBar?: ({ appendTop }: { appendTop: React.ReactNode }) => React.ReactNode;
};
const GroupCreateAppScreen = ({ children, indicator, accessoryBar, ...rest }: Props) => {
  return (
    <AppScreen
      {...rest}
      accessoryBar={accessoryBar?.({
        appendTop: <Indicator indicator={indicator} />,
      })}
    >
      {children}
    </AppScreen>
  );
};

export default GroupCreateAppScreen;
