import { BoxButton as SproutBoxButton, SeedBoxButtonProps } from '@daangn/sprout-components-button';
import React from 'react';

import { Spinner } from '../Spinner';
import * as s from './BoxButton.css';

export interface BoxButtonProps extends SeedBoxButtonProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: 'primary' | 'primaryLow' | 'secondary' | 'danger';
  size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
}

export const BoxButton: React.FC<BoxButtonProps> = ({
  isLoading = false,
  isDisabled = false,
  children,
  variant = 'primary',
  size = 'large',
  ...props
}: BoxButtonProps) => {
  const getLoadingSize = () => {
    if (size === 'large' || size === 'xlarge') {
      return 18;
    }
    if (size === 'xsmall') {
      return 11;
    }
    return 14;
  };

  const getLoadingColor = () => {
    if (variant === 'primary' || variant === 'danger') {
      return 'white';
    }
    if (variant === 'primaryLow') {
      return 'primary';
    }
    return 'gray';
  };

  return (
    <SproutBoxButton {...props} isDisabled={isDisabled} size={size} variant={variant}>
      {isLoading ? (
        <div className={s.LoadingWrapper}>
          <Spinner width={getLoadingSize()} height={getLoadingSize()} variant={getLoadingColor()} />
        </div>
      ) : (
        children
      )}
    </SproutBoxButton>
  );
};
