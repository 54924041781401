import { vars } from '@seed-design/design-token';
import { useCallback, useState } from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import { AppScreenProps } from '.';

const TopViewObserver = ({
  outsideRef,
}: {
  outsideRef: (node?: Element | null | undefined) => void;
}) => {
  return (
    <div style={{ height: '0px', position: 'relative' }}>
      <div
        ref={outsideRef}
        style={{ height: '30px', position: 'absolute', zIndex: '999', top: '0' }}
      />
    </div>
  );
};
export default TopViewObserver;

export const useTopBorder = ({
  borderAlways,
  appBar,
}: {
  borderAlways?: boolean;
  appBar?: AppScreenProps['appBar'];
}) => {
  const [border, setBorder] = useState(
    borderAlways && appBar?.border && appBar?.borderColor && appBar?.borderSize
      ? {
          border: appBar.border,
          borderColor: appBar.borderColor,
          borderSize: appBar.borderSize,
        }
      : { border: false }
  );

  const topSectionViewCallback = useCallback(
    (inView: boolean) => {
      if (!inView) {
        setBorder({
          border: true,
          borderColor: appBar?.borderColor ?? vars.$semantic.color.divider3,
          borderSize: appBar?.borderSize ?? '0.5px',
        });
        return;
      }
      setBorder({
        border: false,
      });
    },
    [appBar?.borderColor, appBar?.borderSize]
  );
  const { ref: intersectionObserverRef } = useIntersectionObserver({
    callback: topSectionViewCallback,
    intersectionOptions: {
      threshold: 0.1,
    },
  });

  return { border, intersectionObserverRef };
};
