import { UploadVideoValidationError } from '../hooks/useVideoValidation';
import { FileUploadState, VideoUploadErrorType } from '../types/Video';

export interface ValidationConfig {
  maxVideoCount: number;
  maxVideoSizeMB: number;
  maxVideoDurationMinutes: number;
  allowedFileTypesRegex: string;
}

export const DEFAULT_VALIDATION_CONFIG = {
  maxVideoCount: 10, // 10개 - 초과시 초과되는 파일부터 invalid
  maxVideoSizeMB: 1024, // 1GB
  maxVideoDurationMinutes: 10, // 10 minutes
  allowedFileTypesRegex: '^video/(3gpp|3gp2|3gpp2|mpeg|mp4|x-m4v|quicktime|webm)',
};

export const validationVideoFile = async (
  file: File,
  config: ValidationConfig
): Promise<Omit<UploadVideoValidationError, 'file'> | undefined> => {
  if (!file) {
    return {
      type: 'undefinedFile',
      message: {
        title: '첨부 에러',
        description: '파일이 존재하지 않아요.',
      },
    };
  }

  if (!validateVideoFileType(file.type, config.allowedFileTypesRegex)) {
    return {
      type: 'fileFormat',
      message: {
        title: '첨부 에러',
        description: '지원하지 않는 포맷이에요.',
      },
    };
  }

  if (byteToMegaByte(file.size) >= config.maxVideoSizeMB) {
    return {
      type: 'fileSize',
      message: {
        title: '최대 첨부 용량 초과',
        description: '동영상은 10분 이내, 1기가 이하의 파일만 첨부할 수 있어요.',
      },
    };
  }
  const duration = await getVideoDuration(file);

  if (duration && duration > config.maxVideoDurationMinutes * 60) {
    return {
      type: 'videoDuration',
      message: {
        title: '최대 첨부 용량 초과',
        description: '동영상은 10분 이내, 1기가 이하의 파일만 첨부할 수 있어요.',
      },
    };
  }
  if (duration === null) {
    return {
      type: 'videoDurationError',
      message: {
        title: '동영상 첨부 실패',
        description: '동영상 첨부에 실패했어요. 잠시후 다시 시도해주세요.',
      },
    };
  }

  return undefined;
};

const validateVideoFileType = (type: string, allowedFileTypesRegex: string): boolean => {
  return new RegExp(allowedFileTypesRegex).test(type);
};

const byteToMegaByte = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const getVideoDuration = (file: File) => {
  return new Promise<number | null>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(null);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const isExistUploadingVideo = (videos: FileUploadState[]): boolean => {
  return videos.some((video) => video.status === 'uploading');
};

export const isExistFailedVideo = (videos: FileUploadState[]): boolean => {
  return videos.some((video) => video.status === 'failed');
};

export const validateUploadingVideos = (videos: FileUploadState[]) => {
  if (isExistUploadingVideo(videos)) {
    return { type: 'uploading', message: '동영상 업로드 중이에요. 잠시만 기다려주세요.' };
  }

  if (isExistFailedVideo(videos)) {
    return { type: 'uploadFailed', message: '동영상 업로드에 실패했어요. 다시 시도해주세요.' };
  }

  return undefined;
};
