import { groupClient } from '@community-group/api';
import { GroupBotGroupChatGreetingMessageForm } from '@community-group/api/lib/group/models';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetchInstance } from './instance/useFetchInstance';

type Params = {
  groupId: string;
  form: GroupBotGroupChatGreetingMessageForm;
};

type Props = UseMutationOptions<AxiosResponse, Error, Params>;

export const usePutGroupBotChatGreetingMessage = (props: Props) => {
  const fetchInstance = useFetchInstance();
  const putGroupBotChatGreetingMessage =
    groupClient.api.GroupBotApi.apiV1GroupsIdGroupBotGroupChatGreetingMessagePut({
      axios: fetchInstance,
    });

  return useMutation(({ groupId, form }: Params) => {
    const id = Number(groupId);
    return putGroupBotChatGreetingMessage(id, form);
  }, props);
};
