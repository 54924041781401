import { Skeleton } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { Tabs } from '@karrotframe/tabs';

import * as style from './searchSkeleton.css';

const GroupHomeSkeleton = () => {
  return (
    <div className={style.wrapper}>
      <Tabs
        className="custom_tab"
        activeTabKey="a"
        disableSwipe
        onTabChange={() => {}}
        tabs={[
          {
            key: 'a',
            buttonLabel: '전체 모임',
            render: () => (
              <>
                <div
                  className={style.wrapper}
                  style={{
                    height: '384px',
                  }}
                >
                  <div className={style.header}>
                    <Skeleton width="7rem" height="1.5rem" enableAnimation />
                  </div>
                  <Spacing size={8} />
                  <ListSkeleton />
                </div>
                <div className={style.wrapper}>
                  <div className={style.header}>
                    <Skeleton width="7rem" height="1.5rem" enableAnimation />
                  </div>
                  <Spacing size={8} />
                  <ListSkeleton />
                </div>
              </>
            ),
          },
          {
            key: Math.random().toString(),
            buttonLabel: '모임 일정',
            render: () => <div></div>,
          },
        ]}
      />
    </div>
  );
};

export default GroupHomeSkeleton;

const ListSkeleton = () => (
  <div className={style.newListWrapper}>
    <div className={style.newListContentsWrapper}>
      {Array.from({ length: 3 }).map((item, index) => (
        <div className={style.newListItem} key={`talk-skeleton-${index}`}>
          <div className={style.profileWrapper}>
            <Skeleton width="4.5rem" height="4.5rem" borderRadius="1rem" />
          </div>
          <div className={style.newListContentsWrapper}>
            <Skeleton width="100%" height="21px" enableAnimation />
            <Spacing size={4} />
            <Skeleton width="4.875rem" height="14px" style={{ marginRight: '.375rem' }} />
            <div className={style.categoryListWrapper}>
              <Skeleton width="3rem" height="1rem" style={{ marginRight: '.375rem' }} />
              <Skeleton width="2rem" height="1rem" style={{ marginRight: '.375rem' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
