import { groupClient } from '@community-group/api';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse,
  Error,
  {
    groupId: string;
  }
>;

export const usePostWatchGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postWatchGroup = groupClient.api.GroupApi.watchGroup({
    axios: fetchInstance,
  });
  const wrapperFp = ({ groupId }: { groupId: string }) => {
    return postWatchGroup(Number(groupId));
  };

  return useThrottleMutation(wrapperFp, {
    onError,
    onSuccess,
  });
};
