import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedSummaryQueryKey = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedSummaryGetPath(groupId);

export const useGetGroupFeedSummary = (groupId: number) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useQuery([getGroupFeedSummaryQueryKey(groupId)], () => get(groupId), {
    suspense: true,
  });
  const response = data?.data;

  return { data: response, refetch };
};
