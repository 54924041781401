import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { useState } from 'react';

import { VerificationSuccessStatus } from '../bottomSheets/VerificationInduceBottomSheet';

export type CertificationCandidateType =
  | 'create_group' // 그룹 생성
  | 'join_group' // 그룹 가입
  | 'create_group_with_user_info' // 그룹 생성 (유저 정보 공개)
  | 'join_group_with_user_info_and_verified' // 그룹 가입 (유저 정보 공개, 인증 완료)
  | 'join_group_with_user_info_and_not_verified' // 그룹 가입 (유저 정보 공개, 인증 미완료)
  | 'pass_verification' // 인증 통과
  | 'joined_group_and_not_verified_leader_update_state' // 모임 가입 되어 있으면서 인증은 안되어 있고, 모임장이 모임의 인증 상태를 변경하는 경우
  | 'joined_group_with_user_info_and_not_verified_leader_update_state' // 모임 가입 되어 있으면서 인증은 안되어 있고, 모임장이 모임의 인증 상태를 정보공개로 변경하는 경우
  | 'joined_group_and_verified_leader_update_state' // 모임 가입 되어 있으면서 인증이 되어 있고, 모임장이 모임의 인증 상태를 변경하는 경우
  | 'joined_group_with_user_info_and_verified_leader_update_state'; // 모임 가입 되어 있으면서 인증이 되어 있고, 모임장이 모임의 인증 상태를 정보공개로 변경하는 경우

type VerificationBottomSheetParams = {
  certificationCandidateType?: CertificationCandidateType;
  groupIdForChangeVerification?: string;
};

export const useHandleVerificationBottomSheet = () => {
  const { push: asyncPush } = useConnectedActions();
  const [verified, setVerified] = useState(false);

  const handleVerificationBottomSheet = async ({
    certificationCandidateType = 'create_group',
    groupIdForChangeVerification,
  }: VerificationBottomSheetParams) => {
    if (certificationCandidateType === 'pass_verification') {
      setVerified(true);
      return true;
    }

    const { verification } = (await asyncPush('BottomSheet/VerificationInduceBottomSheet', {
      certificationCandidateType,
      groupIdForChangeVerification,
    })) as {
      verification: VerificationSuccessStatus;
    };

    if (verification === 'success') {
      setVerified(true);
      return true;
    }
    if (verification === 'fail') {
      setVerified(false);
      return false;
    }
  };

  return {
    verified,
    handleVerificationBottomSheet,
  };
};
