import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useLayoutEffect } from 'react';

import LottieDoneCheck from '@/assets/images/lottie_done_check.json';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './index.css';

//TODO: 10월 운동 프로모션 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
const DoneJoinGroup10월운동MarketingPromotionBottomSheet: ActivityComponentType = () => {
  const { groupId } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_join_group_marketing_10월_운동_bottom_sheet',
    params: {
      groupId,
    },
  });

  const [_, setMarketingPromotionBottomSheet] = useStorage(
    'promotion_group_2410_exercise_join',
    false
  );
  const { pop } = useFlow();
  const { type = '' } = useQueryParams();

  const selector = `lottie-done-check`;

  useLayoutEffect(() => {
    const container = document.querySelector(`#${selector}`) as HTMLElement;
    container.innerHTML = '';
    import('lottie-web/build/player/lottie_light.min.js').then((lottieLight) => {
      const lottieDoneCheckData = lottieLight.loadAnimation({
        container,
        renderer: 'svg',
        autoplay: false,
        loop: false,
        animationData: LottieDoneCheck,
      });
      lottieDoneCheckData.pause();
      lottieDoneCheckData.setSpeed(1);
      setTimeout(() => {
        lottieDoneCheckData.play();
      }, 300);
    });
  }, [selector]);

  const joinedText = {
    title: '모임에 가입했어요!',
    description: (
      <>
        목표한 운동을 꾸준히 실천하고 <br />
        게시판 3회 이상 인증하면 참여 완료!
      </>
    ),
  };

  const applyText = {
    title: '모임에 가입 신청했어요!',
    description: (
      <>
        모임 가입 승인 후<br />
        목표한 운동을 꾸준히 실천하고
        <br />
        게시판 3회 이상 인증하면 참여 완료!
      </>
    ),
  };

  const renderText = type === 'apply' ? applyText : joinedText;

  return (
    <BottomSheet
      onOutsideClick={() => {
        setMarketingPromotionBottomSheet(true);
        pop();
      }}
      style={{
        padding: '1.5rem 1rem .5rem 1rem',
      }}
    >
      <div className={s.BottomSheetWrapper}>
        <div id={selector} className={s.CheckIconImage} />
        <Spacing size={16} />
        <Typography typography="title2Bold">{renderText.title}</Typography>
        <Spacing size={6} />
        <Typography
          typography="bodyM1Regular"
          textAlign="center"
          style={{
            whiteSpace: 'pre-wrap !important',
          }}
          color="gray700"
        >
          {renderText.description}
        </Typography>
        <Spacing size={28} />
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            setMarketingPromotionBottomSheet(true);
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default DoneJoinGroup10월운동MarketingPromotionBottomSheet;
