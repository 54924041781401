import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChallengeFill, IconCommunityFill } from '@seed-design/icon';
import React, { useCallback } from 'react';

import IconCategoryGroup from '@/assets/images/icon_category_group.svg';

import * as s from './index.css';

type Props = {
  prefixIconType?: 'group' | 'post' | 'challenge';
  separator?: string;
  items: string[];
  onClick?: () => void;
};

export const Breadcrumbs = ({
  prefixIconType = 'group',
  separator = '/',
  items,
  onClick,
}: Props) => {
  const PrefixIcon = useCallback(() => {
    if (prefixIconType === 'group') return <img width={18} height={18} src={IconCategoryGroup} />;
    if (prefixIconType === 'post')
      return <IconCommunityFill size={18} color={vars.$scale.color.gray500} />;
    if (prefixIconType === 'challenge')
      return <IconChallengeFill size={18} color={vars.$scale.color.gray500} />;
    return <></>;
  }, [prefixIconType]);

  const itemLastIndex = items.length - 1;

  return (
    <div className={s.wrapper} onClick={onClick}>
      <div className={s.iconWrapper}>
        <PrefixIcon />
      </div>
      {items.map((item, index) => {
        const isNotSingleItem = index !== 0;
        const isLastIndex = itemLastIndex === index;
        const isLastOfMultipleItems = isLastIndex && isNotSingleItem;

        return (
          <React.Fragment key={`breadcrumbs-${item}`}>
            {isNotSingleItem && <div className={s.separator}>{separator}</div>}
            <Typography
              as="caption"
              typography="caption1Regular"
              color={isLastIndex ? 'gray900' : 'gray600'}
              ellipsisAfterLines={isLastOfMultipleItems ? undefined : 1}
              className={s.BreadcrumbsText}
            >
              {item}
            </Typography>
          </React.Fragment>
        );
      })}
    </div>
  );
};
