import { TypeActivities } from '@/stackflow';

import { CREATE_GROUP_DEFAULT_FLOW } from './createGroupControl';

export const indicatorCountHandler = (pageName: keyof TypeActivities) => {
  const maxCount = CREATE_GROUP_DEFAULT_FLOW.length - 1;
  const currentCount = CREATE_GROUP_DEFAULT_FLOW.findIndex((page) => page.includes(pageName)) + 1;

  return {
    count: currentCount,
    maxCount,
  };
};
