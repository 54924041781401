import { Map as DaangnMap, MapInstance } from '@community-group/components';
import React, { PropsWithChildren } from 'react';

// TODO: DaangnMapProps export 받아올 수 있도록 요청
export type DaangnMapProps = Parameters<typeof DaangnMap>[0];
export type MapProps = PropsWithChildren<DaangnMapProps>;

export const Map = React.memo(({ children, theme = 'auto', ...rest }: MapProps) => {
  const map = React.useRef<MapInstance | undefined>(undefined);

  return (
    <DaangnMapContext.Provider
      value={{
        map,
      }}
    >
      <DaangnMap theme={theme} {...rest} ref={map}>
        {children}
      </DaangnMap>
    </DaangnMapContext.Provider>
  );
});

type DaangnMapContextType = {
  map: React.MutableRefObject<MapInstance | undefined>;
};

export const DaangnMapContext = React.createContext<DaangnMapContextType>({
  map: { current: undefined },
});

export const useMap = () => {
  const map = React.useContext<{
    map: React.MutableRefObject<MapInstance | undefined>;
  }>(DaangnMapContext);

  if (map === undefined) {
    throw new Error('useMap must be used within a Map Components');
  }

  return map;
};
