import { Typography } from '@community-group/components';
import { useCallback } from 'react';
import React from 'react';

import * as s from './GroupCountInfo.css';

interface Props {
  postCount: number;
  meetupCount: number;
  likeCount: number;
}

const GroupCountInfo = ({ postCount, meetupCount, likeCount }: Props) => {
  const countList = [
    {
      text: '게시글',
      value: postCount,
    },
    {
      text: '일정',
      value: meetupCount,
    },
    {
      text: '관심',
      value: likeCount,
    },
  ];

  const filteredCountList = countList.filter((el) => el.value !== 0);
  const renderCountInfo = useCallback(
    () =>
      filteredCountList
        .filter((el) => el.value !== 0)
        .map((item, index) => {
          const isLastItem = index === filteredCountList.length - 1;

          return (
            <React.Fragment key={index}>
              <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
                {item.text} {item.value}
              </Typography>
              {!isLastItem && (
                <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
                  ⸱
                </Typography>
              )}
            </React.Fragment>
          );
        }),
    []
  );

  return <div className={s.Wrapper}>{renderCountInfo()}</div>;
};

export default GroupCountInfo;
