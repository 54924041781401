import { isNotMember } from '@community-group/components';
import React from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';

interface Props {
  groupId: string;
}

const useCheckNeedToSetSubNicknameBottomSheet = ({ groupId }: Props) => {
  const { push } = useFlow();

  const { group } = useGetGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();

  const checkNeedToSetSubNicknameBottomSheet = React.useCallback(() => {
    if (isNotMember(currentUser.role)) return false;
    if (!group?.subNicknameSetting?.isSettingOn) return false;
    if (currentUser.subNickname || currentUser.alias) return false;
    push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
      groupId: groupId,
    });
    return true;
  }, [
    currentUser.role,
    currentUser.subNickname,
    currentUser.alias,
    group?.subNicknameSetting?.isSettingOn,
    push,
    groupId,
  ]);

  return checkNeedToSetSubNicknameBottomSheet;
};

export default useCheckNeedToSetSubNicknameBottomSheet;
