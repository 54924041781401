import { useXproperty } from './useXproperty';

// !프로덕션에 정의했는지 확인하기
// 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR
// 알파 : https://xproperty.alpha.kr.wekarrot.net/admin/edit?pkey=community_group_web_feature_flags&country=KR

// 사용중인 피쳐플래그 타입
export type FeatureFlagKeysType = {
  shownPhotoContest?: boolean;
  useContestResult?: boolean; // 사진전 종료 결과 섹션 사용 여부
  photoContestVersion?: number;
  // 10월 운동 마케팅 프로모션
  shown10월운동MarketingPromotion?: boolean;

  huggiesGroupJoinMarketingPromotion?: boolean; // 허기스 그룹 가입 마케팅 프로모션
  groupBotEnabled?: boolean; // 모임봇 사용 여부
  blockRunningGrowthMission5?: boolean; // [알파] 러닝그로스 5단계 미션 날짜 차단 여부
  useGroupLevel?: boolean; // 모임 레벨 사용 여부
  runningMissionAllOpen?: boolean; // 러닝그로스 모든 미션 오픈 여부
  useLeaderBoard?: boolean; // 모임 리더보드 사용 여부
  useGroupChallenge?: boolean; // 모임 챌린지 사용 여부
  shown11월독서MarketingPromotion?: boolean; // 11월 독서 마케팅 프로모션
  usePrevWeekLeaderBoardBanner?: boolean; // 지난주 리더보드 배너 노출 여부 분기
};

type FeatureFlagType = keyof FeatureFlagKeysType;

export const useFeatureFlag = <T extends FeatureFlagType>(
  featureFlagName: T
): FeatureFlagKeysType[T] | false => {
  const xProperty = useXproperty();

  return xProperty?.communityGroupWebFeatureFlags?.[featureFlagName] ?? false;
};
