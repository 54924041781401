import { Dialog, useDialog } from '@community-group/components';

type Params = {
  onPrimaryAction: () => void;
};

export const useOpenDialogValidateFormPhotoContest = () => {
  const { open: openDialog, close: closeDialog } = useDialog();

  const openDialogValidateFormPhotoContest = ({ onPrimaryAction }: Params) => {
    openDialog({
      element: (
        <Dialog
          title="사진이 포함되지 않은 글은 이벤트에 참여할 수 없어요."
          description="이벤트에 참여하려면 사진이 포함된 글을 작성해주세요."
          primaryActionLabel="그냥 올리기"
          onPrimaryAction={() => {
            onPrimaryAction();
            closeDialog();
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
        />
      ),
    });
  };

  return openDialogValidateFormPhotoContest;
};
