import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getNoticesPath = (groupId: number) =>
  groupClient.api.NoticeApi.getapiV1GroupsIdNoticesGetPath(groupId);

export const useGetNotices = (id?: string) => {
  const fetchInstance = useFetchInstance();
  const getNotices = groupClient.api.NoticeApi.apiV1GroupsIdNoticesGet({ axios: fetchInstance });

  const { data, refetch } = useQuery([getNoticesPath(Number(id))], () => getNotices(Number(id)), {
    suspense: true,
  });
  const res = data?.data;

  return {
    data: res,
    refetch,
  };
};
