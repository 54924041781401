import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { isDevelopmentAppStage } from '@/utils/check';

import { isDuringRealJoin10월운동GroupMarketingPromotion } from '../utils';

// TODO: 10월 운동 마케팅 본 프로모션 관련 분기용 유틸 한번 더 진행하면 모듈로 분리
function useGroup10월운동MarketingPromotion() {
  const [marketingPromotionBottomSheet] = useStorage('promotion_group_2410_exercise_create', false);
  const { push } = useFlow();

  // 해당 진입 세션에서만 사전신청 가능해야해서 store에 저장
  const { is10월운동MarketingJoinable, is10월운동MarketingCreate } = useStore();

  const isOngoing10월운동GroupJoinMarketingPromotion = useFeatureFlag(
    'shown10월운동MarketingPromotion'
  );

  const validIsOnGoing10월운동GroupCreateMarketingPromotion = () => {
    //  개발환경일 경우 무조건 노출
    if (isDevelopmentAppStage()) return true;

    //피쳐플래그 오픈 여부 확인
    if (!isOngoing10월운동GroupJoinMarketingPromotion) return false;

    // 이미 오픈되었는지 여부 확인
    if (marketingPromotionBottomSheet) return false;

    // 이벤트 기간인지 여부 확인
    if (!isDuringRealJoin10월운동GroupMarketingPromotion()) return false;

    return true;
  };

  const handleOpen10월운동MarketingPromotionBottomSheet = ({
    groupId,
    type,
  }: {
    groupId: string;
    type: 'create' | 'join' | 'apply';
  }) => {
    const isValid = validIsOnGoing10월운동GroupCreateMarketingPromotion();

    if (!isValid) return false;
    if ((type === 'join' || type === 'apply') && is10월운동MarketingJoinable) {
      setTimeout(() => {
        push('BottomSheet/DoneJoinGroup10월운동MarketingPromotionBottomSheet', {
          groupId,
          type,
        });
      }, 500);
      return true;
    }

    if (type === 'create' && is10월운동MarketingCreate) {
      setTimeout(() => {
        push('BottomSheet/DoneCreateGroup10월운동MarketingPromotionBottomSheet', {
          groupId,
        });
      }, 500);
      return true;
    }
    return false;
  };

  return {
    handleOpen10월운동MarketingPromotionBottomSheet,
    validIsOnGoing10월운동GroupCreateMarketingPromotion,
  };
}

export default useGroup10월운동MarketingPromotion;
