import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetRestrictedMembers = (groupId?: string) => {
  const fetchInstance = useFetchInstance();
  const getApplications =
    groupClient.api.GroupRestrictedMemberApi.apiV1GroupsIdRestrictedMembersGet({
      axios: fetchInstance,
    });

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [
      groupClient.api.GroupRestrictedMemberApi.getapiV1GroupsIdRestrictedMembersGetPath(
        Number(groupId)
      ),
    ],
    ({ pageParam = undefined }) => getApplications(Number(groupId), pageParam, 30),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
  };
};
