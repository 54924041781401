import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';

import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { motion } from 'framer-motion';
import React from 'react';
import { Grid, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { useGetCurationCollection } from '@/api/hooks/useGetCurationCollection';
import { curationKeywords } from '@/components/group/Curation/Page/GroupCurationWithKeyword/curationKeywords';
import { SearchResultItem } from '@/components/group/Search/components/SearchResultList/SearchResultItem';
import { usePreventScrollOnSwipe } from '@/hooks/usePreventScrollOnSwipe';
import { useFlow } from '@/stackflow';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import * as style from './recommandCurationGroupSection.css';

export const DAANGN_RACE_GROUP_IDS = [4172, 4173, 424613, 425454, 425455, 425456, 425457];

const RecommendCurationGroupSection = () => {
  const { push } = useFlow();

  const keywords = ['골프', '탁구', '러닝', '육아', '반려동물', '등산', '배드민턴'];

  // TODO: 안정화되면 keyword -> topic으로 네이밍 변경
  const { keyword: keywordParams } = useQueryParams();

  const { data: curationCollection, isLoading } = useGetCurationCollection({
    topic: keywords,
    priorityOrderParams: keywordParams,
  });

  const swiperRef = usePreventScrollOnSwipe();

  if ((curationCollection ?? []).length === 0 && !isLoading) return <></>;
  return (
    <motion.div
      className={style.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Swiper
        slidesPerView={1}
        modules={[Pagination, Grid]}
        pagination={true}
        width={window.innerWidth - 40}
        touchMoveStopPropagation={true}
        ref={swiperRef as React.Ref<SwiperRef>}
        style={
          {
            '--swiper-pagination-color': vars.$semantic.color.primary,
            '--swiper-pagination-bullet-size': '6px',
            '--swiper-pagination-bullet-horizontal-gap': '4px',
            '--swiper-pagination-bullet-inactive-color': vars.$scale.color.grayAlpha500,
            '--swiper-pagination-bottom': '-2px',
          } as { [key: string]: string | number }
        }
      >
        {curationCollection?.map((groupList, index) => {
          // if (!curationCollection) return <></>;

          const isLastItems =
            curationCollection.filter((item) => item.groups.length > 0).length === index + 1;

          // if (groupList.groups.length === 0) return <></>;

          return (
            <SwiperSlide className={style.swiperSlideItem} key={groupList.topic}>
              <header
                className={style.header({
                  isLastItems,
                })}
              >
                <Typography typography="title3Bold" color="gray900">
                  {curationKeywords.find((item) => item.name === groupList.topic)?.description}
                </Typography>
                <Typography
                  typography="label3Regular"
                  color="gray900"
                  as="button"
                  className={style.textButton}
                  onClick={() => {
                    push('GroupCurationWithKeywordPage', {
                      keyword: groupList.topic,
                    });
                    trackEvent({
                      event: 'click_group_home_curation_more',
                      params: {
                        curationName: groupList.topic,
                        curationId: groupList.topic,
                        keyword: groupList.topic,
                      },
                      sample: true,
                    });
                  }}
                >
                  <IconChevronRightFill size={20} color={vars.$scale.color.gray900} />
                </Typography>
              </header>
              <Spacing size={12} />
              {groupList.groups.map((group, index) => (
                <SearchResultItem
                  key={group?.id}
                  textBold
                  result={{
                    ...group,
                    displayRegionName: group?.region?.town ?? '',
                    highlight: undefined,
                  }}
                  onClick={() => {
                    push('GroupDetailPage', {
                      groupId: group?.id.toString(),
                    });

                    trackEvent({
                      event: 'click_group_home_curation_item',
                      params: {
                        groupId: group?.id,
                        groupName: group?.name,
                        groupCategoryName: group?.category?.name,
                        listItemIndex: index,
                        keyword: groupList.topic,
                      },
                      sample: true,
                    });
                  }}
                />
              ))}
              <Spacing size={16} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </motion.div>
  );
};

export default React.memo(RecommendCurationGroupSection);
