export const MAXIMUM_MEMBER_OPTIONS = [
  {
    id: 0,
    name: '제한없음',
    value: -1,
  },
  {
    id: 1,
    name: '10명',
    value: 10,
  },
  {
    id: 2,
    name: '20명',
    value: 20,
  },
  {
    id: 3,
    name: '30명',
    value: 30,
  },
  {
    id: 4,
    name: '50명',
    value: 50,
  },
  {
    id: 5,
    name: '100명',
    value: 100,
  },
  {
    id: 6,
    name: '직접 입력',
    value: undefined,
    showCustomInput: true,
  },
];
