import { Spinner } from '@community-group/components';

import { GetGroupPhotoListProps, useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { Container } from '@/components/common/Container';
import * as loaderStyle from '@/components/common/LoadMoreContainer/loader.css';
import useInfinityScroll from '@/hooks/useInfinityScroll';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

export const GroupPhotoLoader = ({ params }: { params?: GetGroupPhotoListProps }) => {
  const { groupId } = usePathParams();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetGroupPhotoList({
    groupId,
    ...params,
  });

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0.3,
    },
  });

  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div
        className={loaderStyle.trigger}
        style={{
          top: '0',
        }}
        ref={ref}
      />
      <div className={loaderStyle.loading}>
        <Spinner variant="gray" />
      </div>
    </Container>
  );
};
