import { getPostCommentPath } from '@/api/hooks/useGetComment';
import { getPostCommentsPath } from '@/api/hooks/useGetComments';
import { getUserProfileCommentsPath } from '@/api/hooks/useGetUserProfileComments';
import { queryClient } from '@/api/instance';

/*
 *  멤버 프로필 코멘트 상태를 refetch하는 함수
 */
export const refetchGroupProfileComments = ({
  groupId,
  postId,
  commentId,
  authorId,
}: {
  groupId: string;
  postId: string;
  commentId?: string;
  authorId?: string;
}) => {
  queryClient.refetchQueries([getPostCommentsPath(groupId, postId.toString())]);
  if (commentId)
    queryClient.refetchQueries([
      getPostCommentPath(groupId, postId.toString(), commentId?.toString()),
    ]);
  if (authorId)
    queryClient.refetchQueries([getUserProfileCommentsPath(Number(groupId), Number(authorId))]);
};
