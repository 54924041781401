import { useState } from 'react';

import * as s from './smallBadge.css';
type Props = { theme?: 'light' | 'dark'; size?: number };

const SMALL_2D_LEVEL_1 =
  'https://assetstorage.krrt.io/1025317940251365954/daabde45-5a31-4734-b9d1-91368b69f633/width=84,height=84.png';

function Small2DLevel1({ theme = 'light', size = 14 }: Props) {
  const fillColor = theme === 'light' ? '#ffffff' : '#212124';

  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={s.BadgeWrapper}
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={SMALL_2D_LEVEL_1}
        className={s.BadgeImage}
        style={{
          width: size,
          height: size,
        }}
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <div
          className={s.BadgeOutlineWrapper}
          style={{
            width: size,
            height: size,
          }}
        >
          <svg
            width={size}
            height={size}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0834 2.09241C14.7943 1.3481 13.206 1.3481 11.9168 2.09241L4.72982 6.2418C3.44065 6.98611 2.64648 8.36164 2.64648 9.85024V18.149C2.64648 19.6376 3.44065 21.0132 4.72982 21.7575L11.9168 25.9069C13.206 26.6512 14.7943 26.6512 16.0834 25.9069L23.2704 21.7575C24.5596 21.0132 25.3537 19.6376 25.3537 18.149V9.85024C25.3537 8.36163 24.5596 6.98611 23.2704 6.2418L16.0834 2.09241Z"
              fill={fillColor}
              stroke={fillColor}
              strokeWidth="2.5"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

export default Small2DLevel1;
