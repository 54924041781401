import {
  GroupDetailPresentation,
  GroupFeedBulletinSummary,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Divider, ViewLoader, withAsyncBoundary } from '@community-group/components';

import { useGetGroupFeedSummaryBulletin } from '@/api/hooks/useGetGroupFeedSummaryBulletin';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import BulletinBannerSection from './BulletinBannerSection';

type Props = {
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
};

const GroupDetailBulletinBanner = ({ group, currentUserRole }: Props) => {
  const { data } = useGetGroupFeedSummaryBulletin(group.id);

  const handleFoldedBulletinItemClick = () => {
    const groupId = group.id.toString();
    trackEvent({
      event: 'click_unfold_notices',
      params: {
        groupId: groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
    });
  };

  const activity = useFlow();
  const handleBulletinItemClick = (bulletin: GroupFeedBulletinSummary) => () => {
    const groupId = group.id.toString();
    trackEvent({
      event: 'click_notice_item',
      params: {
        groupId: groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        type: bulletin.type,
        version: '2',
      },
      sample: true,
    });

    const bulletinId = bulletin.id.toString();
    if (bulletin.type === 'group_meetup') {
      activity.push('GroupMeetupDetailPage', {
        groupId,
        meetupId: bulletinId,
      });
      return;
    }
    activity.push('GroupPostDetailPage', {
      groupId,
      postId: bulletinId,
    });
  };

  if (!data || !data.bulletins || data?.bulletins?.length === 0) return null;

  return (
    <>
      <BulletinBannerSection
        bulletins={data.bulletins}
        onFoldedBulletinItemClick={handleFoldedBulletinItemClick}
        onBulletinItemClick={handleBulletinItemClick}
      />
      <Divider />
    </>
  );
};

export default withAsyncBoundary(GroupDetailBulletinBanner, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
