import { useState } from 'react';

import { PollMode } from '../type/poll';
import { PollCardItem } from './PollCardItem';
import { PollResultCardItem } from './PollResultCardItem';
import { PollCardProps } from './type';

type Props = {
  onSelectedOptionIdsChange?: (onChanged: () => void) => void;
} & PollCardProps;

export const PollCard = ({ poll, myPollVoting, onSelectedOptionIdsChange, ...props }: Props) => {
  const [currentPollMode, setCurrentPollMode] = useState<PollMode>(
    (myPollVoting?.optionIds?.length ?? 0) > 0 ? 'result' : 'poll'
  );

  if (currentPollMode === 'result' || poll.isEnded)
    return (
      <PollResultCardItem
        poll={poll}
        myPollVoting={myPollVoting}
        setCurrentPollMode={setCurrentPollMode}
        {...props}
      />
    );

  return (
    <PollCardItem
      poll={poll}
      myPollVoting={myPollVoting}
      setCurrentPollMode={setCurrentPollMode}
      onSelectedOptionIdsChange={onSelectedOptionIdsChange}
      {...props}
    />
  );
};
