import { model } from '@community-group/api/lib/group';
import { GroupAvatar, Spacing, Typography, VerticalSpacing } from '@community-group/components';
import React from 'react';

import * as s from './index.css';

type Props = {
  profileImage: string;
  nickname: string;
  onClick?: () => void;
  isDeletedAccount?: boolean;
  role: model.GroupMemberRoleEnum;
  children: React.ReactNode;
};

const ContentAuthorProfile = ({
  profileImage,
  nickname,
  onClick,
  isDeletedAccount,
  role,
  children,
}: Props) => {
  return (
    <div className={s.wrapper} onClick={onClick} aria-hidden="true">
      <GroupAvatar src={profileImage} role={role} />
      <VerticalSpacing size={12} />
      <div className={s.container}>
        <Typography typography="caption1Bold" color={isDeletedAccount ? 'gray500' : 'gray900'}>
          {nickname}
        </Typography>
        <Spacing size={2} />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ContentAuthorProfile;
