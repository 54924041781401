import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.NoticePostResponse>,
  Error,
  { id: number; postId: number }
>;

export const useDeleteNotice = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const DeleteNotice = groupClient.api.NoticeApi.apiV1GroupsIdPostsPostIdNoticesDelete({
    axios: fetchInstance,
  });

  const DeleteNoticeWrapperFp = ({ id, postId }: { id: number; postId: number }) => {
    return DeleteNotice(id, postId);
  };

  return useThrottleMutation(DeleteNoticeWrapperFp, {
    onError,
    onSuccess,
  });
};
