import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';
import React, { UIEvent, useCallback, useEffect } from 'react';
import { animationFrameScheduler, fromEvent, merge, timer } from 'rxjs';
import { map, takeUntil, takeWhile, tap } from 'rxjs/operators';

interface Props<T, U extends HTMLElement> {
  selectedItemIndex: number;
  unit: number;
  selectList: T[];
  setSelectedItem: React.Dispatch<React.SetStateAction<T>>;
  ref: React.RefObject<U>;
}

export const useScrollPicker = <T, U extends HTMLElement>({
  selectedItemIndex,
  unit,
  selectList,
  setSelectedItem,
  ref,
}: Props<T, U>) => {
  const onScroll = throttle((event: UIEvent<U>) => {
    if (event.currentTarget) {
      const currentIndex = Math.round(event.currentTarget.scrollTop / unit);

      setSelectedItem(selectList[currentIndex]);
    }
  }, 10);

  const onScrollEnd = debounce(
    useCallback((event: UIEvent<U>) => {
      const element = event.target as U;

      if (!element) return;

      const currentIndex = Math.round(element.scrollTop / unit);

      smoothScrollTo({
        element,
        point: unit * currentIndex,
      });
    }, []),
    100
  );

  const handleScroll: React.UIEventHandler<U> = useCallback((event) => {
    onScroll(event);
    onScrollEnd(event);
  }, []);

  const handleSelectItem = (index: number) => {
    const element = ref.current;

    if (!element) return;

    smoothScrollTo({
      element,
      point: unit * index,
    });
  };

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    element.scrollTo(0, unit * selectedItemIndex);
  }, []);

  return {
    handleSelectItem,
    handleScroll,
  };
};

function easeInOutQuad(t: number, b: number, c: number, d: number) {
  t /= d / 2;

  if (t < 1) {
    return (c / 2) * t * t + b;
  }

  t--;

  return (-c / 2) * (t * (t - 2) - 1) + b;
}

interface SmoothScrollToParams {
  element: HTMLElement;
  point?: number;
  duration?: number;
  direction?: 'vertical' | 'horizontal';
}
export const smoothScrollTo = ({
  element,
  point = element.scrollHeight - element.clientHeight,
  duration = 500,
  direction = 'vertical',
}: SmoothScrollToParams) => {
  const scrollDirection = direction === 'vertical' ? 'scrollTop' : 'scrollLeft';

  const cancelObservable = merge(fromEvent(element, 'touchstart'), fromEvent(element, 'touchmove'));

  const startTime = new Date().getTime();
  return timer(0, 20, animationFrameScheduler)
    .pipe(
      map(() => {
        const elapsedTime = new Date().getTime() - startTime;
        const delta = point - element[scrollDirection];

        return {
          position: easeInOutQuad(elapsedTime, element[scrollDirection], delta, duration),
          elapsedTime,
        };
      }),
      tap((target) => {
        element[scrollDirection] = target.position;
      }),
      // Stop timer
      takeWhile((target) => target.elapsedTime < duration),
      takeUntil(cancelObservable)
    )
    .toPromise();
};
