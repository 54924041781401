import { vars } from '@seed-design/design-token';
import React from 'react';

type Props = {
  groupImage: string;
  userProfileImage: string;
  size?: number;
};

const MyFeedProfileImage = ({ groupImage, userProfileImage, size = 40 }: Props) => {
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
      }}
    >
      <img
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '0.625rem',
          objectFit: 'cover',
          border: `1px solid ${vars.$scale.color.gray200}`,
        }}
        src={groupImage}
      />
      <div style={{ position: 'absolute', bottom: '-0.375rem', right: '-0.375rem' }}>
        <img
          style={{
            width: '1.75rem',
            height: '1.75rem',
            boxSizing: 'border-box',
            borderRadius: '50%',
            border: `2px solid ${vars.$semantic.color.paperDefault}`,
            backgroundColor: vars.$semantic.color.paperDefault,
            objectFit: 'cover',
          }}
          src={userProfileImage ?? ''}
        />
      </div>
    </div>
  );
};

export default React.memo(MyFeedProfileImage);
