import 'dayjs/locale/ko';

import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';

import * as s from './DayCalendarRadio.css';

dayjs.locale('ko');
dayjs.extend(weekday);

type Props = {
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
};

export const DayCalendarRadio = ({ setSelectedDay, selectedDay }: Props) => {
  const now = dayjs();
  const days = Array.from({ length: 7 }, (_, i) => now.add(i, 'day'));

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDay(e.target.value ?? '');
  };

  return (
    <div className={s.wrapper}>
      {days.map((day) => {
        const checked = selectedDay === day.format('YYYY-MM-DD');
        return (
          <label className={s.inputWrapper} key={day.format('DD')}>
            <input
              className={s.input}
              id="calendar-id"
              type="radio"
              name="day"
              value={day.format('YYYY-MM-DD')}
              onChange={handleDayChange}
              checked={checked}
            />
            <Typography
              className={s.text}
              typography="caption2Regular"
              color={checked ? 'gray900' : 'gray600'}
              as="p"
              textAlign="center"
              style={{
                background: checked ? vars.$scale.color.gray100 : 'transparent',
              }}
            >
              {dayjs().startOf('day').isSame(day.startOf('day')) ? '오늘' : day.format('dd')}
              <br />
              <Typography
                typography="label1Bold"
                as="strong"
                color={checked ? 'gray900' : 'gray600'}
              >
                {day.format('DD')}
              </Typography>
            </Typography>
          </label>
        );
      })}
    </div>
  );
};
