import {
  GroupLevelPresentation,
  GroupLevelUpMissions,
} from '@community-group/api/lib/group/models';

import useMissionClickHandler from '../../hooks/useMissionClickHandler';
import { MISSION_INFO } from '../../utils/missions';
import DetailLevelUpMissionItem from './LevelUpMissionItem';
import * as s from './LevelUpMissions.css';

type Props = {
  groupId: string;
  missions: GroupLevelPresentation['forLevelUp']['missions'];
};

function LevelUpMissions({ groupId, missions }: Props) {
  const missionList = Object.keys(missions);

  const { handleMissionClick } = useMissionClickHandler({ groupId });

  return (
    <div className={s.Container}>
      {missionList.map((key, index) => {
        const mission = missions[key];
        const missionId = key as keyof GroupLevelUpMissions;
        const missionInfo = MISSION_INFO[key];
        if (!mission || !missionInfo) return null; // 해당 미션이 없으면 렌더링하지 않음

        return (
          <DetailLevelUpMissionItem
            key={key}
            missions={missions}
            missionId={missionId}
            handleMissionClick={handleMissionClick}
            index={index}
          />
        );
      })}
    </div>
  );
}

export default LevelUpMissions;
