import { PostPoiPresentationType } from '@community-group/api/lib/group/models';

import THUMBNAIL_MAP from '@/assets/images/thumbnail_map.svg';
import { trackEvent } from '@/utils/analytics';
import { openPoiDetailPage } from '@/utils/link';

import * as s from './PoiBanner.css';
import { Typography } from '@community-group/components';

type Props = {
  poi: {
    id: number;
    name: string;
    type: PostPoiPresentationType;
    address?: string;
  };
  onClick?: () => void;
  renderRight?: () => React.ReactNode;
};

export const PoiBanner = ({ poi, onClick: handleClick, renderRight }: Props) => {
  return (
    <div
      className={s.wrapper}
      onClick={() => {
        if (handleClick) {
          return handleClick();
        }

        trackEvent({
          event: 'click_poi_detail',
          sample: true,
        });
        openPoiDetailPage({
          id: poi.id,
          type: poi.type,
        });
      }}
    >
      <img
        className={s.image}
        src={THUMBNAIL_MAP}
        alt="핀이 박혀있는 지도 썸네일"
        width={62}
        height={62}
      />
      <div className={s.contentsWrapper}>
        <div className={s.contents}>
          <Typography typography="bodyM2Regular" ellipsisAfterLines={1}>
            {poi.name}
          </Typography>
          {poi.address && (
            <Typography typography="caption1Regular" color="gray600" ellipsisAfterLines={1}>
              {poi.address}
            </Typography>
          )}
        </div>
        {renderRight && <div className={s.right}>{renderRight()}</div>}
      </div>
    </div>
  );
};
