import { useCallback } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';

type Props = {
  watch: UseFormWatch<FieldValues>;
};

const MINIMUM_AGE = 16;
const MAXIMUM_AGE = 99;

const useValidAgeRangeInput = ({ watch }: Props) => {
  const ageRangeFieldValue = watch('ageRange');

  const validateMinValue = useCallback(() => {
    if (!ageRangeFieldValue) return { isValid: false, helperText: undefined };

    if (
      ageRangeFieldValue &&
      ageRangeFieldValue.minAge === undefined &&
      ageRangeFieldValue.maxAge === undefined
    ) {
      return { isValid: true, helperText: undefined };
    }
    if (isNaN(ageRangeFieldValue?.minAge)) return { isValid: false, helperText: '' };
    if (ageRangeFieldValue?.minAge === undefined) return { isValid: false, helperText: '' };
    if (ageRangeFieldValue?.minAge?.toString() === '')
      return {
        helperText: '최소 나이를 입력해주세요.',
        isValid: false,
      };
    if (parseInt(ageRangeFieldValue?.minAge?.toString() ?? '0', 10) < MINIMUM_AGE)
      return {
        helperText: `${MINIMUM_AGE}세 이상부터 모임을 이용할 수 있어요.`,
        isValid: false,
      };
    if (ageRangeFieldValue?.maxAge !== undefined && ageRangeFieldValue?.maxAge?.toString() !== '') {
      if (
        parseInt(ageRangeFieldValue?.minAge?.toString() ?? '0', 10) >
        parseInt(ageRangeFieldValue?.maxAge?.toString() ?? '0', 10)
      ) {
        return {
          isValid: false,
          helperText: '최소 나이는 최대 나이보다 같거나 낮아야해요.',
        };
      }
    }
    if (parseInt(ageRangeFieldValue?.minAge?.toString() ?? '0', 10) > MAXIMUM_AGE)
      return {
        helperText: `최대 ${MAXIMUM_AGE}세 까지 입력할 수 있어요.`,
        isValid: false,
      };
    return {
      isValid: true,
      helperText: undefined,
    };
  }, [ageRangeFieldValue]);
  const validateMaxValue = useCallback(() => {
    if (!ageRangeFieldValue) return { isValid: false, helperText: undefined };
    if (
      ageRangeFieldValue &&
      ageRangeFieldValue.minAge === undefined &&
      ageRangeFieldValue.maxAge === undefined
    ) {
      return { isValid: true, helperText: undefined };
    }
    if (isNaN(ageRangeFieldValue?.maxAge)) return { isValid: false, helperText: undefined };
    if (ageRangeFieldValue?.maxAge === undefined) return { isValid: false, helperText: undefined };
    if (ageRangeFieldValue?.maxAge?.toString() === '')
      return {
        helperText: '최대 나이를 입력해주세요.',
        isValid: false,
      };

    if (parseInt(ageRangeFieldValue?.maxAge?.toString() ?? '0', 10) < MINIMUM_AGE)
      return {
        helperText: `${MINIMUM_AGE}세 이상부터 모임을 이용할 수 있어요.`,
        isValid: false,
      };

    if (parseInt(ageRangeFieldValue?.maxAge?.toString() ?? '0', 10) > MAXIMUM_AGE)
      return {
        helperText: `최대 ${MAXIMUM_AGE}세 까지 입력할 수 있어요.`,
        isValid: false,
      };

    return {
      isValid: true,
      helperText: '',
    };
  }, [ageRangeFieldValue]);

  return { validateMinValue, validateMaxValue };
};

export default useValidAgeRangeInput;
