import React from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

type Props = {
  from: string;
  additionalCondition?: boolean;
  callback: () => void;
};

const useCheckFromParams = () => {
  const { from } = useQueryParams();

  const checkFromHandler = React.useCallback(
    ({ from: checkFrom, callback, additionalCondition = true }: Props) =>
      () => {
        if (from === checkFrom && additionalCondition) {
          callback();
          return true;
        }
        return false;
      },
    [from]
  );

  return checkFromHandler;
};

export default useCheckFromParams;
