import { ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useGetCommentEmotions } from '@/api/hooks/useGetCommentEmotions';
import EmotionUserList from '@/components/common/EmotionUserList';

type Props = {
  groupId: number;
  relatedId: number;
  commentId: number;
  relatedContentType: 'post' | 'meetup';
  onClickUserProfile: (userId: number) => () => void;
};

const CommentEmotionListForNotMember = ({
  groupId,
  relatedId,
  commentId,
  relatedContentType,
  onClickUserProfile,
}: Props) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetCommentEmotions({
    groupId,
    relatedId,
    commentId,
    relatedContentType,
    joinedUser: false,
  });
  const notJoinedUserList = useMemo(
    () => data?.pages.flatMap((page) => page.data.users) ?? [],
    [data]
  );

  return (
    <EmotionUserList
      users={notJoinedUserList}
      onClickUserProfile={onClickUserProfile}
      shouldFetchNextUsers={Boolean(hasNextPage && !isFetchingNextPage)}
      fetchNextUsers={fetchNextPage}
    />
  );
};

export default withAsyncBoundary(CommentEmotionListForNotMember, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
