import { vars } from '@seed-design/design-token';

interface Props {
  strokeColor?: string;
  size?: number;
}

const Warning = ({ size = 18, strokeColor: propsStrokeColor }: Props) => {
  const strokeColor = propsStrokeColor ?? vars.$scale.color.gray600;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.32653C9.33813 5.32653 9.61225 5.60064 9.61225 5.93878V9.38265C9.61225 9.72079 9.33813 9.9949 9 9.9949C8.66187 9.9949 8.38776 9.72079 8.38776 9.38265V5.93878C8.38776 5.60064 8.66187 5.32653 9 5.32653Z"
        fill={strokeColor}
      />
      <path
        d="M9.76516 12.0612C9.76516 12.4839 9.42252 12.8265 8.99985 12.8265C8.57718 12.8265 8.23455 12.4839 8.23455 12.0612C8.23455 11.6385 8.57718 11.2959 8.99985 11.2959C9.42252 11.2959 9.76516 11.6385 9.76516 12.0612Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9ZM9 2.72449C5.53413 2.72449 2.72449 5.53413 2.72449 9C2.72449 12.4659 5.53413 15.2755 9 15.2755C12.4659 15.2755 15.2755 12.4659 15.2755 9C15.2755 5.53413 12.4659 2.72449 9 2.72449Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default Warning;
