import { Spinner } from '@community-group/components';

import * as s from './style.css';

const Loading = ({ size = 'small' }: { size?: 'small' | 'medium' }) => {
  return (
    <div className={s.loading}>
      <Spinner size={size} />
    </div>
  );
};

export default Loading;
