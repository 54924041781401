import { Typography } from '@community-group/components';

import { numberToLocaleString } from '@/utils/number';

import * as s from './ActivityBox.css';

type Props = {
  title: string;
  count: number;
};
const ActivityBox = ({ title, count }: Props) => {
  return (
    <div className={s.ActivityBox}>
      <Typography typography="caption1Regular" color="gray700">
        {title}
      </Typography>
      <Typography typography="subtitle1Bold" color="gray900">
        {numberToLocaleString(count)}
      </Typography>
    </div>
  );
};

export default ActivityBox;
