import clsx from 'clsx';

import * as s from './index.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
}

const IconWrapper = ({ onClick, children, ...props }: Props) => {
  return (
    <div onClick={onClick} {...props} className={clsx([props.className, s.styleIconWrapper])}>
      {children}
    </div>
  );
};
export default IconWrapper;
