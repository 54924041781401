import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';

import { GROUP_URL } from '../../base/group';
import { useFetchInstance } from '../instance/useFetchInstance';

export const useGetRoomList = (id: number) => {
  const fetchInstance = useFetchInstance();
  const getRoomList = groupClient.api.TalkRoomApi.apiV1GroupsIdRoomsGet({ axios: fetchInstance });

  const { fetchNextPage, isFetchingNextPage, data, refetch } = useInfiniteQuery(
    [`${GROUP_URL}/${id}/rooms`],
    ({ pageParam = undefined }) => getRoomList(id, pageParam, 15),
    {
      suspense: true,
      getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
      refetchInterval: 10000,
    }
  );

  return {
    fetchNextPage,
    hasNextPage: data?.pages[data.pages.length - 1].data.hasNext,
    isFetchingNextPage,
    data,
    refetch,
  };
};
