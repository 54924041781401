import Loading from '../Loading';
import * as s from './style.css';

const LoadingFullScreen = () => (
  <div className={s.loadingFullScreen}>
    <Loading size="medium" />
  </div>
);

export default LoadingFullScreen;
