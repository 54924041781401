import { ChallengeDetailPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography, VerticalSpacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCalendarFill, IconChallengeFill, IconChatBubbleCheckFill } from '@seed-design/icon';
import { format } from 'date-fns';
import { useMemo } from 'react';

import { getDateDiffFromFuture, getDateDiffFromNow } from '../../../utils/date';
import * as s from './style.css';

type Props = Pick<
  ChallengeDetailPresentation,
  'certifyIntervalTargetCount' | 'totalDays' | 'startedAt'
>;

const ChallengeDetailInfoList = ({ certifyIntervalTargetCount, totalDays, startedAt }: Props) => {
  // 시작일
  const dateInfo = useMemo(() => {
    const startedAtDate = new Date(startedAt);
    const today = new Date();

    const isToday = format(startedAtDate, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd');

    if (isToday) return '오늘 시작';

    if (startedAtDate > today) {
      const { text } = getDateDiffFromFuture(startedAtDate);
      return `${text} 뒤 시작`;
    }

    const { text } = getDateDiffFromNow(startedAtDate);
    return `진행 ${text}차`;
  }, [startedAt]);

  // 챌린지 기간
  const totalDaysText = useMemo(() => {
    return `${Math.floor(totalDays / 7)}주 동안`;
  }, [totalDays]);

  // 인증 빈도
  const certifyGoalCountText = useMemo(() => {
    if (certifyIntervalTargetCount === 7) return '매일 인증';
    return `주 ${certifyIntervalTargetCount}회 인증`;
  }, [certifyIntervalTargetCount]);

  return (
    <div className={s.Wrapper}>
      <div className={s.ItemWrapper}>
        <IconChallengeFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {dateInfo}
        </Typography>
      </div>
      <Spacing size={8} />
      <div className={s.ItemWrapper}>
        <IconCalendarFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {totalDaysText}
        </Typography>
      </div>
      <Spacing size={8} />
      <div className={s.ItemWrapper}>
        <IconChatBubbleCheckFill size={16} color={vars.$scale.color.gray600} />
        <VerticalSpacing size={8} />
        <Typography typography="subtitle1Regular" color="gray800">
          {certifyGoalCountText}
        </Typography>
      </div>
    </div>
  );
};

export default ChallengeDetailInfoList;
