import { AsyncBoundary, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useActivity } from '@stackflow/react';
import React from 'react';
import { useEffect } from 'react';

import { useGetMyGroupPostList } from '@/api/hooks/useGetMyGroupPostList';

import { MyFeedListWrapper } from './MyFeedList';

const MyGroupFeed = () => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
      <MyGroupFeedWrapper />
    </AsyncBoundary>
  );
};

const MyGroupFeedWrapper = () => {
  const { data, refetch: myGroupFeedRefetch } = useGetMyGroupPostList();
  const { isTop } = useActivity();

  useEffect(() => {
    if (isTop) {
      myGroupFeedRefetch();
    }
  }, [isTop, myGroupFeedRefetch]);

  return data && data.pages.length !== 0 ? (
    <div style={{ backgroundColor: vars.$semantic.color.paperDefault }}>
      <Typography
        as="h3"
        typography="title3Bold"
        color="gray900"
        style={{ padding: '1.5rem 1rem 0.5rem 1rem' }}
      >
        피드
      </Typography>
      <MyFeedListWrapper />
    </div>
  ) : null;
};

export default React.memo(MyGroupFeed);
