import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';

export type DeviceConfig = {
  theme: 'android' | 'cupertino';
  userAgent: string;
  xUserAgent: string;
  isLegacyIOS?: boolean;
};

type Props = PropsWithChildren<DeviceConfig>;

type ReturnValue = {
  deviceConfig: DeviceConfig;
  setDeviceConfig: (nextConfig: DeviceConfig) => void;
};

const DeviceConfig = createContext<ReturnValue>({
  deviceConfig: {
    theme: 'android',
    userAgent: '',
    xUserAgent: '',
    isLegacyIOS: false,
  },
  setDeviceConfig: () => {},
});

export const DeviceConfigProvider = ({ children, ...initialConfig }: Props) => {
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig>(initialConfig);

  return (
    <DeviceConfig.Provider
      value={{
        deviceConfig,
        setDeviceConfig,
      }}
    >
      {children}
    </DeviceConfig.Provider>
  );
};

export const useDeviceConfig = () => useContext(DeviceConfig);
