import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';

type Props = UseMutationOptions<
  AxiosResponse<model.GroupMemberModifyResponse>,
  Error,
  { id?: number; groupMemberModifyForm?: model.GroupMemberModifyForm }
>;

export const usePutProfile = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const putProfile = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersPut({
    axios: fetchInstance,
  });

  const putProfileWrapperFp = ({
    id,
    groupMemberModifyForm,
  }: {
    id: number;
    groupMemberModifyForm?: model.GroupMemberModifyForm;
  }) => {
    return putProfile(id, groupMemberModifyForm);
  };

  return useThrottleMutation(putProfileWrapperFp, {
    onError,
    onSuccess,
  });
};
