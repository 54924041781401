import { UploadImageType } from '@community-group/api';
import { useEffect, useState } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useBridge } from '@/contexts/Bridge';
import { trackEvent } from '@/utils/analytics';
import { uploadImages } from '@/utils/image';

type Params = {
  onUpload?: (id?: string) => void;
  defaultValue?: UploadImageType;
};

export const useHandleSetGroupProfile = ({ onUpload }: Params) => {
  const fetchCommunityInstance = useFetchInstance();

  const [profileImage, setProfileImage] = useState<UploadImageType>();
  const [blobs, setBlobs] = useState<Blob[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const updateProfileImage = async (blobs: Blob[]) => {
    setIsLoading(true);
    const uploadedImages = await uploadImages({
      images: blobs,
      fetchCommunityInstance,
    });

    setProfileImage(uploadedImages[0]);
    onUpload && onUpload(uploadedImages[0].id);
    setIsLoading(false);
  };
  const { bridge } = useBridge();
  useEffect(() => {
    if (!blobs) return;
    if (blobs.length < 1) return;

    if (blobs.length > 1) {
      bridge.openToast({
        toast: { body: '프로필 사진은 1장만 선택할 수 있어요.' },
      });
      return;
    }

    try {
      updateProfileImage(blobs);
    } catch {
      bridge.openToast({
        toast: { body: '이미지 업로드 중 오류가 발생했어요. 잠시 뒤 다시 시도해주세요.' },
      });
      setIsLoading(false);
    }
  }, [blobs]);

  const handleSetGroupProfile = async () => {
    trackEvent({ event: 'click_upload_group_profile', sample: true });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const blobs = await bridge.driver.compat.pickImages();
    setBlobs(blobs);
  };

  const handleReset = () => {
    setBlobs([]);
    setProfileImage(undefined);
    setIsLoading(false);
  };

  return {
    profileImage,
    originImage: blobs[0],
    isLoading: isLoading,
    handleSetGroupProfile,
    handleReset,
  };
};
