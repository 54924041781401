import { GroupFeedSummary } from '@community-group/api/lib/group/models';
import { Image, Spacing, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import React from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './ReviewItem.css';
import { getElementFromMention } from '@/components/common/Mention';

type Props = {
  groupId: string;
  item: GroupFeedSummary;
};

const ReviewItem = ({ groupId, item }: Props) => {
  const { push } = useFlow();
  const handleMoveToReviewDetail = () => {
    trackEvent({
      event: 'click_post_meetup_detail',
      params: {
        groupId,
        role: 'none',
      },
      sample: true,
    });
    push('GroupPostDetailPage', {
      groupId,
      postId: item.id.toString(),
    });
  };
  return (
    <div className={s.Wrapper} onClick={handleMoveToReviewDetail}>
      <Image
        src={item.images[0]?.medium}
        style={{
          width: '118px',
          height: '118px',
          borderRadius: '8px',
          objectFit: 'cover',
        }}
      />
      <Spacing size={8} />
      <Typography typography="subtitle2Regular" color="gray900" ellipsisAfterLines={2}>
        {getElementFromMention(item.content, 'string')}
      </Typography>
      <Spacing size={8} />
      <div className={s.ProfileWrapper}>
        <Image
          src={item.author.profileImage}
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
        <VerticalSpacing size={6} />
        <Typography typography="caption1Regular" color="gray700" ellipsisAfterLines={1}>
          {item.author.nickname}
        </Typography>
      </div>
    </div>
  );
};

export default ReviewItem;
