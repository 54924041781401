import { vars } from '@seed-design/design-token';
import PropTypes from 'prop-types';

import IconFlagWithCircle from '../assets/icons/svgs/IconFlagWithCircle';
import { defaultStyle } from './utils';
import { getSubstringIndex } from './utils';

function Suggestion({
  id,
  focused,
  ignoreAccents,
  index,
  onClick,
  onMouseEnter,
  query,
  renderSuggestion,
  suggestion,
  style,
}) {
  const rest = { onClick, onMouseEnter };

  const renderContent = () => {
    let display = getDisplay();
    const imageSrc = getProfileImage();
    const subDisplay = getSubDisplay();
    let highlightedDisplay = renderHighlightedDisplay(display, imageSrc, subDisplay);

    if (renderSuggestion) {
      return renderSuggestion(suggestion, query, highlightedDisplay, index, focused);
    }

    return highlightedDisplay;
  };

  const getDisplay = () => {
    if (typeof suggestion === 'string') {
      return suggestion;
    }

    let { id, display } = suggestion;

    if (id === undefined || !display) {
      return id;
    }

    return display;
  };

  const getProfileImage = () => {
    if (typeof suggestion === 'string') {
      return null;
    }

    let { imageSrc } = suggestion;

    if (imageSrc === undefined) {
      return null;
    }

    return imageSrc;
  };

  const getSubDisplay = () => {
    if (typeof suggestion === 'string') {
      return null;
    }

    let { subDisplay } = suggestion;

    if (subDisplay === undefined) {
      return null;
    }
    return subDisplay;
  };

  const renderHighlightedDisplay = (display, imageSrc, subDisplay) => {
    let i = getSubstringIndex(display, query, ignoreAccents);

    if (i === -1) {
      return <span {...style('display')}>{display}</span>;
    }

    if (subDisplay) {
      return (
        <div
          {...style('display')}
          id="mention-option"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <p id="mention-option">
            {display.substring(0, i)}
            <span
              {...style('highlight')}
              id="mention-option"
              style={{
                fontWeight: '700',
              }}
            >
              {display.substring(i, i + query.length)}
            </span>
            {display.substring(i + query.length)}
          </p>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '0.375rem',
              fontSize: '0.875rem',
              color: vars.$scale.color.gray600,
            }}
          >
            <IconFlagWithCircle size={16} /> {subDisplay}
          </p>
        </div>
      );
    }

    return (
      <div
        {...style('display')}
        id="mention-option"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {imageSrc && (
          <img
            id="mention-option"
            src={imageSrc}
            style={{
              borderRadius: '50%',
              height: '24px',
              width: '24px',
              marginRight: '0.5rem',
              objectFit: 'cover',
            }}
          />
        )}

        {display.substring(0, i)}
        <span
          {...style('highlight')}
          id="mention-option"
          style={{
            fontWeight: '700',
          }}
        >
          {display.substring(i, i + query.length)}
        </span>
        {display.substring(i + query.length)}
      </div>
    );
  };

  return (
    <li id={id} role="option" aria-selected={focused} {...rest} {...style}>
      {renderContent()}
    </li>
  );
}

Suggestion.propTypes = {
  id: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  ignoreAccents: PropTypes.bool,

  suggestion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      display: PropTypes.string,
    }),
  ]).isRequired,
  renderSuggestion: PropTypes.func,

  focused: PropTypes.bool,
  onClick: PropTypes.onClick,
  onMouseEnter: PropTypes.onMouseEnter,
  style: PropTypes.style,
};

const styled = defaultStyle(
  {
    cursor: 'pointer',
  },
  (props) => ({ '&focused': props.focused })
);

export default styled(Suggestion);
