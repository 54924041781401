import { GroupMeetupMemberSummaryPresentationWithAttendanceStatus } from '@community-group/api/lib/group/models';
import { BoxButton, GroupAvatar, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCheckRegular } from '@seed-design/icon';
import { useMemo } from 'react';

import { useUserConfig } from '@/contexts/UserConfig';
import { formatDate } from '@/utils/date';
import { convertNicknameDisplay } from '@/utils/nickname';

import * as s from './AttendanceMemberListItem.css';

type Props = {
  member: GroupMeetupMemberSummaryPresentationWithAttendanceStatus;
  onProfileClick?: () => void;
  isLoadingAttendanceButton?: boolean;
  onAttendanceClick: (
    attendanceStatus: GroupMeetupMemberSummaryPresentationWithAttendanceStatus['attendanceStatus']
  ) => void;
};

export const AttendanceMemberListItem = ({
  member,
  onProfileClick,
  isLoadingAttendanceButton,
  onAttendanceClick,
}: Props) => {
  const { userConfig } = useUserConfig();

  const isMe = member.userId === userConfig.userId;
  const shownAttendanceUpdatedAt =
    member.attendanceStatus === 'attendance' && member?.attendanceUpdatedAt;

  const buttonTheme = useMemo(() => {
    return member.attendanceStatus === 'attendance' ? 'secondary' : 'primary';
  }, [member.attendanceStatus]);
  const buttonLabel = useMemo(() => {
    if (member.attendanceStatus === 'attendance') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconCheckRegular
            width={16}
            height={16}
            color={vars.$scale.color.gray900}
            style={{ marginRight: '0.25rem' }}
          />{' '}
          완료
        </div>
      );
    }

    return '출석';
  }, [member.attendanceStatus]);

  return (
    <div className={s.wrapper}>
      <div className={s.profileWrapper} onClick={onProfileClick}>
        <GroupAvatar src={member.profileImage} size="small" />
        <div className={s.infoWrapper}>
          <div className={s.nicknameWrapper}>
            {isMe && <div className={s.badge}>나</div>}
            <Typography as="p" typography="bodyM2Regular" color="gray900">
              {convertNicknameDisplay({
                nickname: member.nickname,
                subNickname: member.subNickname,
              })}
            </Typography>
          </div>
          {shownAttendanceUpdatedAt && (
            <Typography
              as="p"
              color="gray600"
              typography="bodyM2Regular"
              style={{ marginTop: '2px' }}
            >
              {formatDate(new Date(member?.attendanceUpdatedAt ?? ''), 'M/d (E) aa h:mm 완료')}
            </Typography>
          )}
        </div>
      </div>

      <div className={s.buttonWrapper}>
        <BoxButton
          size="small"
          variant={buttonTheme}
          width="100%"
          UNSAFE_style={{ wordBreak: 'keep-all' }}
          onClick={() => {
            onAttendanceClick(member.attendanceStatus);
          }}
          isLoading={isLoadingAttendanceButton}
        >
          {buttonLabel}
        </BoxButton>
      </div>
    </div>
  );
};
