import { vi } from 'date-fns/locale';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as s from './AlbumVideoPlayer.css';
import VideoPlayer from './VideoPlayer';
import VideoPlayInfoButton from './VideoPlayInfoButton';

type Props = {
  videoUrl: string;
  thumbnailUrl: string;
  durationText: string;
  width: number;
  height: number;
};

function AlbumVideoPlayer({ videoUrl, thumbnailUrl, durationText, width, height }: Props) {
  const [playerState, setPlayerState] = useState<'loading' | 'ready' | 'playing' | 'paused'>(
    'loading'
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      // 비디오 재생
      videoRef.current?.play?.();
      videoRef.current?.requestFullscreen?.();

      const fullscreenchangeHandler = () => {
        if (document.fullscreenElement === null) {
          videoRef.current?.pause();
        }
      };

      videoRef.current?.addEventListener('fullscreenchange', fullscreenchangeHandler);

      return () =>
        videoRef.current?.removeEventListener('fullscreenchange', fullscreenchangeHandler);
    }
  }, [playerState]);

  const ratio = width / height;
  const isPortrait = ratio < 1;

  return (
    <div className={s.OuterWrapper}>
      <div
        className={s.InnerWrapper}
        style={{
          zIndex: playerState === 'playing' ? -1 : 10,
        }}
        onClick={() => {
          handlePlayVideo();
        }}
      >
        <div className={s.VideoInfoWrapper}>
          <VideoPlayInfoButton durationText={durationText} />
        </div>

        <img
          src={thumbnailUrl}
          style={{
            width: isPortrait ? 'fit-content' : '100%',
            height: isPortrait ? '100%' : 'fit-content',
            objectFit: 'contain',
            objectPosition: 'center',
            zIndex: playerState === 'playing' ? -1 : 9,
          }}
        />
        <div className={s.ContentsWrapper}>
          <VideoPlayer
            controls
            src={videoUrl}
            ref={videoRef}
            className={s.ContentsWrapper}
            onLoadedMetadata={() => {
              console.log('onLoadedMetadata', playerState, videoUrl, durationText);

              if (videoRef.current) {
                videoRef.current.play();
                videoRef.current.currentTime = 0;
                videoRef.current.pause();
              }
              if (playerState === 'loading') {
                setPlayerState('ready');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AlbumVideoPlayer;
