import { AxiosInstance, AxiosResponse } from 'axios';

import { myGroupReponse } from '../mock/home';

export const GROUP_URL = '/api/v1/groups';

export const getGroupPath = (groupId?: string | number) => `${GROUP_URL}/${groupId}`;

export const ORDER_HOME = {
  RECOMMEND_SCORE_DESC: 'recommendScoreDesc',
  POPULARITY_DESC: 'recommendDesc',
  CREATED_AT_DESC: 'createdAtDesc',
} as const;

export const ORDER_HOME_KR_TYPO = {
  [ORDER_HOME.RECOMMEND_SCORE_DESC]: '추천순',
  [ORDER_HOME.POPULARITY_DESC]: '인기순',
  [ORDER_HOME.CREATED_AT_DESC]: '최신순',
} as const;

export const ORDER_MEETUP = {
  CREATED_AT_DESC: 'created_at_desc',
  MEET_AT_ASC: 'meet_at_asc',
} as const;

export const ORDER_MEETUP_KR_TYPO = {
  [ORDER_MEETUP.CREATED_AT_DESC]: '최근 작성 순',
  [ORDER_MEETUP.MEET_AT_ASC]: '가까운 일정 순',
} as const;

export const ORDER_SEARCH = {
  RECOMMEND_SCORE_DESC: 'recommendScoreDesc',
  POPULARITY_DESC: 'popularityDesc',
  CREATED_AT_DESC: 'createdAtDesc',
} as const;

export const ORDER_SEARCH_KR_TYPO = {
  [ORDER_SEARCH.RECOMMEND_SCORE_DESC]: '추천순',
  [ORDER_SEARCH.POPULARITY_DESC]: '인기순',
  [ORDER_HOME.CREATED_AT_DESC]: '최신순',
} as const;

export const getMyGroupListPath = () => `${GROUP_URL}/summary/my-groups`;

type Params = {
  fetchInstance: AxiosInstance;
};
export const getMyGroupList = async ({ fetchInstance }: Params) => {
  const { data } = await fetchInstance.get<undefined, AxiosResponse<typeof myGroupReponse>>(
    getMyGroupListPath()
  );

  return data;
};
