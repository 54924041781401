import 'react-day-picker/dist/style.css';

import { formatDate } from '@community-group/components';
import ko from 'date-fns/locale/ko/index';
import { CaptionLabelProps, DayPicker, DayPickerSingleProps } from 'react-day-picker';

import * as s from './style.css';

type Props = Omit<DayPickerSingleProps, 'mode'>;

export const GroupChallengeFormDatePicker = (props: Props) => (
  <div>
    <DayPicker
      mode="single"
      locale={ko}
      components={{
        CaptionLabel: ({ displayMonth }: CaptionLabelProps) => (
          <div className={s.caption}>{formatDate(displayMonth, 'yyyy년 MM월')}</div>
        ),
      }}
      {...props}
      className={s.DayPicker}
    />
  </div>
);
