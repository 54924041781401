import { useCallback, useEffect } from 'react';

export type VisibilityState = Document['visibilityState'];

type Props = {
  enabled?: boolean;
  callback: (visibilityState: VisibilityState) => void;
};

export function useVisibilityEvent({ enabled = true, callback }: Props) {
  const handleVisibilityChange = useCallback(() => {
    callback(document.visibilityState);
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange, enabled]);
}
