import { useCallback, useRef } from 'react';

interface UsePickVideoFilesProps {
  onError?: (error: Error) => void; // 에러 발생 시의 콜백
}

export const usePickVideoFiles = ({ onError }: UsePickVideoFilesProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const pickVideoFiles = useCallback(() => {
    return new Promise<File[]>((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'video/*';
      input.multiple = true;
      input.hidden = true;

      input.click();

      input.onchange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (files) {
          const fileArray = Array.from(files);
          try {
            resolve(fileArray);
          } catch (error) {
            onError?.(error as Error);
            reject(new Error('Failed to process selected files'));
          }
        }

        // input 값을 초기화하여 같은 파일도 다시 업로드 가능하게 함
        target.value = '';
      };

      input.onerror = (error) => {
        onError?.(new Error('Failed to select files'));
      };

      document.body.appendChild(input);
      inputRef.current = input;
    });
  }, [onError]);

  return { pickVideoFiles };
};
