import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog';
import React from 'react';

type Props = {
  title: string;
  description?: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  onClose: () => void;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
};

function AlertModal({
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
}: Props) {
  return (
    <DialogContainer onOutsideClick={onClose}>
      <AlertDialog
        title={title}
        description={description ?? ''}
        primaryActionLabel={primaryActionLabel ?? '확인'}
        secondaryActionLabel={secondaryActionLabel}
        onPrimaryAction={onPrimaryAction}
        secondaryActionIntent="neutral"
        onSecondaryAction={onSecondaryAction}
      />
    </DialogContainer>
  );
}

export default AlertModal;
