import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { forwardRef } from 'react';

import * as s from './index.css';

type Props = React.HTMLAttributes<HTMLLIElement> & {
  color?: string;
};

export const ActionListItem = forwardRef(
  (
    { className, style, color = vars.$semantic.color.inkText, ...props }: Props,
    ref: React.Ref<HTMLLIElement>
  ) => (
    <li
      ref={ref}
      {...props}
      className={clsx(s.actionListItem, className)}
      style={{ ...style, color }}
    />
  )
);
