import { groupClient } from '@community-group/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupMeetupMembersPath = (groupId: string, meetupId: string) =>
  `/api/v1/groups/${groupId}/meetups/${meetupId}/members`;

export type GetGroupMeetupMembersProps = {
  groupId: string;
  meetupId: string;
  meetupJoinStatus: groupClient.model.GroupMeetupJoinStatusEnum;
  limit?: number;
};

export const useGetGroupMeetupMembers = ({
  groupId,
  meetupId,
  meetupJoinStatus,
  limit = 30,
}: GetGroupMeetupMembersProps) => {
  const fetchInstance = useFetchInstance();
  const getGroupMeetupMembers =
    groupClient.api.MeetupApi.apiV1GroupsGroupIdMeetupsMeetupIdMembersGet({ axios: fetchInstance });

  const fetchQueryKey = useMemo(
    () => [getGroupMeetupMembersPath(groupId, meetupId), meetupJoinStatus, limit],
    [groupId, meetupId, meetupJoinStatus, limit]
  );

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data, refetch, isLoading, isFetching } =
    useInfiniteQuery(
      fetchQueryKey,
      ({ pageParam = undefined }) =>
        getGroupMeetupMembers(
          parseInt(groupId),
          parseInt(meetupId),
          meetupJoinStatus,
          pageParam === null ? undefined : pageParam,
          limit
        ),
      {
        suspense: true,
        getNextPageParam: ({ data }) => (data.hasNext ? data?.endCursor ?? undefined : undefined),
        enabled: true,
      }
    );

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
