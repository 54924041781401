import { isNotMember } from '@community-group/components';

import { usePostVoteEndPoll } from '@/api/hooks/usePostVoteEnd';
import { usePostVotePoll } from '@/api/hooks/usePostVotePoll';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchPostDetail } from '@/utils/refetch/groupPostDetail';

import PollResult from '../PollResult';
import { PollMode } from '../type/poll';
import { PollCardProps } from './type';

type Props = PollCardProps & {
  setCurrentPollMode: (mode: PollMode) => void;
};

export const PollResultCardItem = ({
  groupPostInfo,
  poll,
  myPollVoting,
  currentUserInfo,
  onVotedMembersClick,
  setCurrentPollMode,
}: Props) => {
  const { groupId, postId, groupName } = groupPostInfo;
  const isNotGroupMember = isNotMember(currentUserInfo?.role);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { isLoading: isVotePoll } = usePostVotePoll({});
  const { mutate, isLoading } = usePostVoteEndPoll({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchPostDetail({ groupId, postId });
      refetchGroupDetail({ groupId });
      // TODO: Feed Refetch

      trackEvent({
        event: 'click_end_poll',
        params: {
          groupId,
          groupName,
          pollId: poll.id,
          pollName: poll.title,
        },
      });
    },
  });

  return (
    <PollResult
      isLoading={isLoading || isVotePoll}
      poll={poll}
      selectedOptionIds={myPollVoting?.optionIds}
      hideVoteButton={isNotGroupMember}
      currentUserRole={currentUserInfo?.role}
      onModeChange={setCurrentPollMode}
      onRevoteButtonClick={() => {}}
      onVoteEndButtonClick={() => {
        mutate({
          groupId,
          postId,
          pollId: poll.id,
          endPollForm: {
            isPollEnd: true,
          },
        });
      }}
      onVotedMembersClick={() => {
        onVotedMembersClick?.(poll.id);
      }}
    />
  );
};
