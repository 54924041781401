import { vars } from '@seed-design/design-token';

interface Props {
  fill?: string;
  size?: number;
}

const RemoveCircle = ({ size = 20, fill: prevFill }: Props) => {
  const fill = prevFill ?? vars.$scale.color.gray700;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0199 14.4801C13.3081 14.7682 13.7752 14.7682 14.0634 14.4801C14.3515 14.1919 14.3515 13.7247 14.0634 13.4366L11.0434 10.4167L14.0634 7.39673C14.3515 7.10858 14.3515 6.6414 14.0634 6.35326C13.7752 6.06511 13.3081 6.06511 13.0199 6.35326L9.99997 9.37319L6.98004 6.35326C6.6919 6.06511 6.22472 6.06511 5.93657 6.35326C5.64842 6.6414 5.64842 7.10858 5.93657 7.39673L8.9565 10.4167L5.93657 13.4366C5.64842 13.7247 5.64842 14.1919 5.93657 14.4801C6.22472 14.7682 6.6919 14.7682 6.98004 14.4801L9.99997 11.4601L13.0199 14.4801Z"
        fill={vars.$scale.color.gray00}
      />
    </svg>
  );
};

export default RemoveCircle;
