import { GroupMeetupMemberRoleEnum } from '@community-group/api/lib/group/models';

export enum MemberReviewFeedbackType {
  FEEDBACK = 'feedback',
  CS_CENTER_REPORT = 'csCenterReport',
  POST_REPORT_TYPE = 'postReportType',
}

export type DislikeReviewType = {
  id: number;
  optionName: string;
  title: string;
  subTitle: string;
  feedbackType: MemberReviewFeedbackType;
};

// review id 1은 좋아요 리뷰로 사용되고 있어서 제외
export const DISLIKE_REVIEWS: DislikeReviewType[] = [
  {
    id: 4,
    optionName: '직전에 불참한 멤버가 있어요.',
    title: '직전에 불참한 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.FEEDBACK,
  },
  {
    id: 8,
    optionName: '목적에 벗어난 행동을 하는 멤버가 있어요.',
    title: '목적에 벗어난 행동을 하는 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.FEEDBACK,
  },
  {
    id: 3,
    optionName: '불쾌한 말이나 행동을 하는 멤버가 있어요.',
    title: '불쾌한 말이나 행동을 하는 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.FEEDBACK,
  },
  {
    id: 2,
    optionName: '개인정보/사생활을 묻는 멤버가 있어요.',
    title: '개인정보/사생활을 묻는 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.FEEDBACK,
  },
  {
    id: 5,
    optionName: '종교 포교를 시도한 멤버가 있어요.',
    title: '종교 포교를 시도한 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.CS_CENTER_REPORT,
  },
  {
    id: 6,
    optionName: '원하지 않는 성적 행위를 하는 멤버가 있어요.',
    title: '원하지 않는 성적 행위를 하는 멤버를 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.CS_CENTER_REPORT,
  },
  {
    id: 7,
    optionName: '직접 입력',
    title: '별로 였던 점을 알려주세요',
    subTitle: '해당 내용은 당근 팀에게만 전달되니 안심하고 선택해주세요.',
    feedbackType: MemberReviewFeedbackType.POST_REPORT_TYPE,
  },
];

export type MeetupFeedbackType = {
  id: number;
  label: string;
  prefixIcon: string;
  postReport?: boolean;
  shownUserRole: GroupMeetupMemberRoleEnum[];
};

export const MEETUP_FEEDBACK_TYPES = {
  LIKE: new Map<number, MeetupFeedbackType>([
    [
      1,
      {
        id: 1,
        label: '또 만나고 싶어요',
        prefixIcon: '🧡',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
    [
      101,
      {
        id: 101,
        label: '매너가 좋아요',
        prefixIcon: '👍',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
    [
      102,
      {
        id: 102,
        label: '적극적으로 참여해요',
        prefixIcon: '💪',
        shownUserRole: ['PARTICIPANT'],
      },
    ],
    [
      105,
      {
        id: 105,
        label: '진행이 매끄러워요',
        prefixIcon: '🎤',
        shownUserRole: ['HOST'],
      },
    ],
    [
      103,
      {
        id: 103,
        label: '분위기 메이커예요',
        prefixIcon: '😎',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],

    [
      104,
      {
        id: 104,
        label: '이야기를 잘 들어줘요',
        prefixIcon: '👂',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
  ]),
  DISLIKE: new Map<number, MeetupFeedbackType>([
    [
      4,
      {
        id: 4,
        label: '불참했어요',
        prefixIcon: '😡',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
    [
      3,
      {
        id: 3,
        label: '불쾌한 말이나 행동을 해요',
        prefixIcon: '😡',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
    [
      2,
      {
        id: 2,
        label: '개인정보/사생활을 물어봐요',
        prefixIcon: '😡',
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
    [
      7,
      {
        id: 7,
        label: '직접입력',
        prefixIcon: '😡',
        postReport: true,
        shownUserRole: ['HOST', 'PARTICIPANT'],
      },
    ],
  ]),
};
