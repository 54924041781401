import { useActivity } from '@stackflow/react';

import useSuperHostOnboardingQuest from '../../DetailV2/hooks/useSuperHostOnboardingQuest';

const useCheckUnCompletedQuest = () => {
  const { name } = useActivity();
  const questList = useSuperHostOnboardingQuest();
  const uncompletedQuestList = questList.filter(
    ({ isCompleted, activityName }) => !isCompleted && name !== activityName
  );
  return uncompletedQuestList;
};

export default useCheckUnCompletedQuest;
