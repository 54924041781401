import {
  AsyncBoundary,
  CoveredLazyLoadImage,
  Typography,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';

import * as s from './GroupMeetupCreateBottomSheet.css';

const GroupMeetupCreateBottomSheet: ActivityComponentType = () => {
  return (
    <BottomSheet
      style={{
        padding: '1rem 1rem 0 1rem',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <GroupMeetupCreateBottomSheetView />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupMeetupCreateBottomSheetView = () => {
  const { push } = useFlow();
  const { data } = useGetMyGroupList();
  useEnterTrackEvent({
    event: 'enter_create_group_meetup_bottom_sheet',
  });

  return (
    <div className={s.view}>
      <Typography as="h1" typography="title2Bold" color="gray900">
        어떤 모임에서 일정을 만들까요?
      </Typography>
      <ul className={s.ul}>
        {data?.groups.map((group) => {
          return (
            <li
              className={s.li}
              key={group?.id}
              onClick={() => {
                push('GroupMeetupNewPage', {
                  groupId: group?.id.toString(),
                });
              }}
            >
              <CoveredLazyLoadImage
                src={group?.profileImage.small}
                width={48}
                height={48}
                isLazy={false}
                style={{
                  borderRadius: '0.375rem',
                }}
              />
              <Typography typography="bodyM1Regular" color="gray900">
                {group?.name}
              </Typography>
            </li>
          );
        })}
        <div className={s.safeAreaBottom} />
      </ul>
    </div>
  );
};

export default GroupMeetupCreateBottomSheet;
