import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const useGetGroupSuperHostDetail = (groupId?: string, enabled = true) => {
  const fetchInstance = useFetchInstance();

  const getGroupSuperHostDetail = groupClient.api.GroupMemberApi.apiV1GroupsIdMembersSuperHostGet({
    axios: fetchInstance,
  });

  const path = groupClient.api.GroupMemberApi.getapiV1GroupsIdMembersSuperHostGetPath(
    Number(groupId)
  );

  const { data, refetch, isInitialLoading } = useQuery(
    [path],
    () => getGroupSuperHostDetail(Number(groupId)),
    {
      enabled,
      suspense: true,
    }
  );

  const response = data?.data;

  return { data: response, refetch, isLoading: isInitialLoading };
};
