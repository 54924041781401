import {
  BottomBasicButton,
  MultilineTextField,
  Spacing,
  TextField,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import clsx from 'clsx';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import AvatarImage from '@/assets/images/avatar.png';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import useVisualViewPortFocusInput from '@/hooks/useVisualViewPortFocusInput';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseTalkRoomButton from '../../AppBarCloseTalkRoomButton';
import DefaultTalkRoomProfileImages from '../../DetailSetting/components/DefaultTalkRoomProfileImages';
import * as s from './index.css';

export type TalkRoomNewInformationPageParams = Pick<PageParams, 'groupId'>;

const MAX_NAME_LENGTH = 24;
const MAX_DESCRIPTION_LENGTH = 200;

const TalkRoomNewInformationPage: ActivityComponentType<TalkRoomNewInformationPageParams> = () => {
  const { groupId = '' } = usePathParams();
  const { push } = useFlow();
  const pop = useBack();
  const { watch, register, setValue } = useForm();
  const { onBlur: descriptionOnBlur, ...rest } = register('description', {
    maxLength: MAX_DESCRIPTION_LENGTH,
  });
  const descriptionWrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const nameValue = watch('name');
  const descriptionValue = watch('description');

  const me = useCurrentGroupMe();

  const submitHandler = () => {
    push('TalkRoomNewApprovalPage', {
      groupId,
      name: nameValue,
      description: descriptionValue,
      profileImageId: profileImage?.id,
    });
  };

  useVisualViewPortFocusInput({ wrapperRef });

  const {
    profileImage,
    isLoading: isProfileLoading,
    handleSetGroupProfile,
  } = useHandleSetGroupProfile({});

  const userImages = () => {
    return [me.profileImage, AvatarImage];
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => <AppBarCloseTalkRoomButton />,
        },
        closeButton: {
          render: () => <AppBarCloseTalkRoomButton />,
        },
      }}
      accessoryBar={
        <BottomBasicButton
          onClick={submitHandler}
          disabled={!nameValue || nameValue?.length === 0 ? true : false}
        >
          다음
        </BottomBasicButton>
      }
    >
      <div className={clsx(['content-wrapper', s.contentWrapper])} ref={wrapperRef}>
        <div className={s.informationFormWrapper}>
          <Typography as="div" typography="title1Bold">
            어떤 채팅방인가요?
          </Typography>
          <Spacing size={24} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DefaultTalkRoomProfileImages
              isLoading={isProfileLoading}
              userProfileImages={userImages()}
              onSetGroupProfile={handleSetGroupProfile}
              groupProfileImage={profileImage?.medium ?? undefined}
              canEdit
            />
          </div>

          <Spacing size={32} />
          <div className={s.formWrapper}>
            <Typography as="div" typography="label3Bold">
              방 제목
            </Typography>
            <Spacing size={12} />
            <TextField
              variant={'outlined'}
              value={watch('name')}
              onChange={(value) => {
                setValue('name', value ?? '');
              }}
              type="text"
              maxLength={MAX_NAME_LENGTH}
              placeholder="제목을 입력해주세요."
            />
            <Spacing size={8} />
          </div>
          <Spacing size={24} />
          <div className={s.formWrapper}>
            <Typography as="div" typography="label3Bold">
              방 소개 (선택)
            </Typography>
            <Spacing size={12} />
            <MultilineTextField
              value={watch('description')}
              onChange={(value) => {
                setValue('description', value ?? '');
              }}
              type="text"
              maxLength={MAX_DESCRIPTION_LENGTH}
              placeholder="어떤 주제로 대화하는지 자세히 알려주세요."
              onFocus={() => {
                if (descriptionWrapperRef.current) {
                  descriptionWrapperRef.current.style.borderColor = vars.$scale.color.gray900;
                }
              }}
              onBlur={(event) => {
                if (descriptionWrapperRef.current) {
                  descriptionWrapperRef.current.style.borderColor = vars.$scale.color.gray400;
                }
                descriptionOnBlur(event);
              }}
            />
          </div>
        </div>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(TalkRoomNewInformationPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
