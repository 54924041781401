import { useEffect } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

type Props = {
  callback?: (inView: boolean) => void;
  intersectionOptions: IntersectionOptions;
};
const useIntersectionObserver = ({
  callback,
  intersectionOptions = { rootMargin: '0px', threshold: 1 },
}: Props) => {
  const { ref, inView } = useInView(intersectionOptions);

  useEffect(() => {
    if (callback) callback(inView);
  }, [inView]);

  return { ref, inView };
};

export default useIntersectionObserver;
