import { SeedSwitchProps, Switch as SproutSwitch } from '@daangn/sprout-components-switch';

export const Switch = ({
  isSelected,
  onChange,
  isInvalid,
  isIndeterminate,
  isDisabled,
  ...props
}: SeedSwitchProps) => {
  return (
    <SproutSwitch
      isSelected={isSelected}
      onChange={onChange}
      isIndeterminate={isIndeterminate}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      {...props}
    ></SproutSwitch>
  );
};
