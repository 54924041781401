import {
  GroupCurrentUser,
  GroupDetailPresentation,
  GroupFeedSummary,
  PostDetail,
} from '@community-group/api/lib/group/models';

// NOTE: 신규 데이터 타입인 GroupFeedSummary를 FeedItem에 맞게 변환하는 함수
// TODO: FeedItem을 사용하는 모든 곳에서 GroupFeedSummary를 사용하게 되면 이 함수를 제거할 수 있음
export const mapGroupFeedToPostDetail =
  (group: GroupDetailPresentation, currentUser: GroupCurrentUser) =>
  (item: GroupFeedSummary): PostDetail => ({
    ...item,
    content: item.content,
    publishType: item.publishType === 'group_only' ? 'groupOnly' : 'public',
    pois: item.places,
    postType: {
      type: item.type === 'group_meetup_review' ? 'meetupReview' : 'post',
      meetupId: item.meetupInfo?.id,
    },
    author: {
      ...item.author,
      temperature: 35.5, // not use
      userRegion: {
        id: 0,
        name: '',
        province: '',
        city: '',
        town: '',
      }, // not use
      isAccountDeleted: item.author.isAccountDeleted,
      memberKey: '', // not use
      createdAt: '', // not use
      updatedAt: '', // not use
      verified: false, // not use
      description: '', // not use
      userRegionCheckInCount: 0, // not use
    },
    isSettingOnSubNickname: true, // not use

    groupInfo: {
      id: group.id,
      name: '', // not use
      profileImage: {
        id: '',
        url: '',
        small: '',
        medium: '',
        large: '',
        width: 0,
        height: 0,
        isDefault: undefined,
      }, // not use
      isBoardManaged: group.isBoardManaged, // FIXME: groupInfo로 전달하지 않도록 개선 필요
      isSettingOnSubNickname: true, // not use
      joinType: 'approval', // not use
      region: {
        id: 0,
        name: '',
        province: '',
        city: '',
        town: '',
      }, // not use
      needVerification: false, // not use
      joinApprovalRequireReason: 'none', // not use
      approvalConditions: 'none', // not use
      approvalQuestion: '', // not use
      category: {
        id: 0,
        name: '',
      }, // not use
      age: undefined, // not use
      ageRangeTag: undefined, // not use
    },
    currentUserInfo: {
      id: currentUser.id,
      role: currentUser.role,
      userState: currentUser.state,
      regionInfo: currentUser.regionInfo,
    },
    poll: item.poll
      ? {
          ...item.poll,
          options: [
            {
              id: '',
              title: '',
              voteCount: 0,
            },
          ], // not use
          isMultiple: false, // not use
          totalVoteCount: 0, // not use
          isEnded: false, // not use
          isHost: false, // not use
        }
      : undefined,
    readCount: item.readCount,
    permalinkId: item.permalinkId,

    isUpdated: false, // not use
    updatedAt: '', // not use
    noticedAt: undefined, // not use
    publishedAt: undefined, // not use
    deletedAt: undefined, // not use
    myPollVoting: undefined, // not use
    isPhotoContestEntry: false, // not use
  });
