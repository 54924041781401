import { z } from 'zod';

const name = z
  .string()
  .trim()
  .min(3, '게시판 이름을 3자 이상 입력해주세요')
  .max(24, '24자 이하로 입력해주세요.');

export const groupBoardCategoryFieldSchema = {
  name,
};

export const groupBoardCategoryFormSchema = z.object(groupBoardCategoryFieldSchema);
