import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Divider, isHigherManager, isManager } from '@community-group/components';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import GroupLevelBanner from '@/components/common/GroupLevelBanner';
import useRedTeamGroupLevel from '@/components/group/GroupLevel/hooks/useRedTeamGroupLevel';
import { isSuperHost } from '@/utils/role';

import RunningGrowthMissionBanner from '../../../Detail/components/RunningGrowth/RunningGrowthMissionBanner';
import GroupDetailOnboardingBannerForMember from './GroupDetailOnboardingBannerForMember';
import {
  GroupDetailOnboardingBannerForSuperHost,
  isBeforeGroupDescriptionWriteBannerExposureStartDate,
} from './GroupDetailOnboardingBannerForSuperHost';

const GroupDetailOnboardingBanner = ({
  currentUser,
  group,
}: {
  currentUser: GroupCurrentUser;
  group?: GroupDetailPresentation;
}) => {
  const groupId = String(group?.id);
  const { runningGrowthData } = useGetRunningGrowthMission(groupId);
  const useGroupLevel = useRedTeamGroupLevel();

  // ** 모임장이면서 러닝 그로스 데이터가 있는 경우
  if (isSuperHost(currentUser.role) && runningGrowthData && runningGrowthData?.length > 0) {
    return <RunningGrowthMissionBanner groupId={groupId} divider />;
  }

  // * 일반 멤버인 경우 (운영진, 모임장 제외)
  if (!isHigherManager(currentUser.role)) {
    return <MemberOnboardingBanner currentUser={currentUser} groupId={groupId} />;
  }

  // * 운영진인 경우
  if (isManager(currentUser.role)) {
    // 모임 레벨 배너 노출 여부 확인
    if (useGroupLevel) {
      return <GroupLevelBanner groupId={groupId} size="large" />;
    }

    // 모임 레벨 배너 미노출 시 멤버 온보딩 배너 노출
    return <MemberOnboardingBanner currentUser={currentUser} groupId={groupId} />;
  }

  // 모임 레벨 배너 노출 여부 확인
  if (useGroupLevel) {
    return <GroupLevelBanner groupId={groupId} size="large" />;
  }

  // * 모임장인 경우
  // ** 특정 일자 이후에 만들어진 모임만 온보딩 노출
  const groupCreationDate = group?.createdAt ?? '';
  if (isBeforeGroupDescriptionWriteBannerExposureStartDate(groupCreationDate)) {
    return (
      <>
        <Divider />
        <GroupDetailOnboardingBannerForSuperHost groupId={groupId} />
      </>
    );
  }

  return <Divider />;
};

export default GroupDetailOnboardingBanner;

// 공통 함수: 일반 멤버 혹은 운영진의 온보딩 배너 렌더링
const MemberOnboardingBanner = ({
  currentUser,
  groupId,
}: {
  currentUser: GroupCurrentUser;
  groupId: string;
}) => {
  // 공통 함수: 2주 이내 가입 여부 확인
  const isJoinedIn2Weeks = (joinedAt: string) =>
    new Date(joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);

  if (isJoinedIn2Weeks(currentUser.joinedAt)) {
    return <GroupDetailOnboardingBannerForMember groupId={groupId} />;
  }
  return <Divider />;
};
