import { ViewError } from '@community-group/components';

import * as s from './style.css';

type Props = {
  message: string;
  buttonLabel: string;
  onClick: (event: React.MouseEvent<Element>) => void;
};
export const AccessStatus = ({ message, buttonLabel, onClick }: Props) => {
  return (
    <div className={s.Wrapper}>
      <ViewError description={message} buttonText={buttonLabel} onClickButton={onClick} />
    </div>
  );
};
