import { useActivity } from '@stackflow/react';
import { useEffect } from 'react';

import { useBridge } from '@/contexts/Bridge';

const POI_SUBSCRIBE_RESPONSE_KEY = 'PLACE_SEARCH_SELECT_POI_TAG_FROM_WEBVIEW';
type PoiBridgeResponse = {
  id: string; // poi일 경우 poiId, biz일 경우 bizId
  type: 'POI' | 'BUSINESS_ACCOUNT' | 'LOCAL_PROFILE';
  name: string;
  address: {
    road: string;
    jibun: string;
    extra: string;
  };
};

export type PoiStream = {
  poiId: number;
  type: 'POI' | 'BUSINESS_ACCOUNT' | 'LOCAL_PROFILE';
  poiName: string;
};

type Params = {
  callback: (poi: PoiStream) => void;
};

export const useSubscribedPoiStream = ({ callback }: Params) => {
  const { isActive, isTop } = useActivity();
  const { bridge } = useBridge();
  useEffect(() => {
    if (!isTop) return;
    if (!isActive) return;

    const disposeSubscribedPoiStream = bridge.dangerouslySubscribeStream({}, (error, response) => {
      if (error) throw error;

      if (!response) return;

      const { stream } = response;

      if (stream.eventName === POI_SUBSCRIBE_RESPONSE_KEY) {
        const response = (stream.data ? JSON.parse(stream.data) : {}) as PoiBridgeResponse;

        console.log(stream.eventName, response);

        callback({
          poiId: parseInt(response.id),
          type: response.type,
          poiName: response.name,
        });
      }
    });

    return () => {
      disposeSubscribedPoiStream?.();
    };
  }, [isActive, isTop]);
};
