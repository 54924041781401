import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as s from './PhotoContestHeroSlide.css';

const PhotoContestHeroSlide = () => {
  const imageUrls = [
    'https://assetstorage.krrt.io/1087936079686579572/c5626d49-fb9b-42ba-9147-7ae763543ed5/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1025317940251365954/3a827bde-280f-41fd-af09-d727559cc017/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1087936079686579572/1ae85877-d581-4664-a27b-a3bdd256dce9/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1025317940251365954/e0dace76-ad19-4c9c-a5e3-f0cca4846cd1/width=1029,height=840.png',
    'https://assetstorage.krrt.io/1025317940251365954/15fa101c-f85c-42f1-818f-05069af982c1/width=1029,height=840.png',
  ];

  return (
    <div className={s.Wrapper}>
      <Swiper
        className={s.Swiper}
        lazyPreloadPrevNext={1}
        loop
        autoplay={{ disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        {imageUrls.map((url, index) => (
          <SwiperSlide key={url} className={s.SwiperSlide}>
            <img src={url} className={s.Image} alt={`${index + 1}번째 설명 이미지`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PhotoContestHeroSlide;
