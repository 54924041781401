import clsx from 'clsx';

import { getImagesForViewer } from '@/components/group/ImageViewerPage/utils/imageUtil';
import { useFlow } from '@/stackflow';

import { DynamicSlider, DynamicSliderProps } from './DynamicSlider';
import * as s from './index.css';
import { getMinHeightImageList } from './utils/images';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'>, DynamicSliderProps {}

export const ImageSlider: React.FC<Props> = ({
  images,
  onClick,
  defaultHeight,
  ...props
}: Props) => {
  const { push } = useFlow();

  const handleImageClick = (index: number) => {
    push('ImageViewerPage', {
      content: 'images',
      initialIndex: index.toString(),
      imageUrls: getImagesForViewer(images, 'large'),
    });
  };

  if (images.length <= 0) {
    return <></>;
  }

  const handleClick = (index: number) => {
    if (onClick) {
      return onClick(index);
    }

    handleImageClick(index);
  };

  return (
    <div
      className={clsx([props.className, s.wrapper])}
      {...props}
      style={{
        ...props.style,
        height: defaultHeight ?? getMinHeightImageList(images),
      }}
    >
      <DynamicSlider defaultHeight={defaultHeight} images={images} onClick={handleClick} />
    </div>
  );
};
