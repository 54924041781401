import { ResizeOptionParams, ResizeOptions } from './type';

type ParseResizeOption = Omit<Required<ResizeOptions>, 'file'> & Pick<ResizeOptions, 'file'>;

export const parseResizeImageOptionsParams = (options: ParseResizeOption): ResizeOptionParams => {
  const { quality, type, size, file } = options;

  return {
    q: getQuality(quality),
    s: size,
    t: type,
    f: file,
  };
};

const getQuality = (quality: ParseResizeOption['quality']): number => {
  if (quality === 'low') return 70;
  if (quality === 'medium') return 80;
  if (quality === 'high') return 90;

  if (quality < 1) {
    console.log('[ResizeImage] quality 값은 0보다 큰 숫자여야 합니다. 1로 변경되었습니다.');
    return 1;
  }

  if (quality > 100) {
    console.log('[ResizeImage] quality 값은 100보다 작은 숫자여야 합니다. 100으로 변경되었습니다.');
    return 100;
  }

  return quality;
};
