import { ImageLoader } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import React, { ReactElement } from 'react';

import AvartarIcon from '@/assets/images/avatar.png';
import CameraIcon from '@/assets/images/camera.png';

import * as s from './DefaultTalkRoomProfileImages.css';

interface Props {
  groupProfileImage?: string;
  userProfileImages: string[];
  size?: number;
  borderSize?: number;
  isLoading?: boolean;
  onSetGroupProfile?: () => void;
  canEdit?: boolean;
}

function DefaultTalkRoomProfileImages({
  groupProfileImage,
  userProfileImages,
  size = 64,
  borderSize = 4,
  isLoading,
  canEdit,
  onSetGroupProfile,
}: Props): ReactElement {
  const ProfileIconWithCameraIcon = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        {children}
        {canEdit && (
          <div className={s.cameraImageWrapper}>
            <img className={s.cameraImage} src={CameraIcon}></img>
          </div>
        )}
      </>
    );
  };

  const ProfileImages = () => {
    if (isLoading) {
      return (
        <div className={s.loaderWrapper}>
          <ImageLoader isLoading isCircle src={AvartarIcon} />
        </div>
      );
    }
    if (groupProfileImage)
      return (
        <div
          style={{
            position: 'relative',
            width: `${size + size / 2}px`,
            height: `${size + size / 2}px`,
          }}
          onClick={() => canEdit && onSetGroupProfile?.()}
        >
          <ProfileIconWithCameraIcon>
            <img className={s.image} src={groupProfileImage} />
          </ProfileIconWithCameraIcon>
        </div>
      );
    if (userProfileImages.length === 1)
      return <img className={s.image} src={userProfileImages[0]} />;
    return (
      <div
        style={{
          position: 'relative',
          width: `${size + size / 2}px`,
          height: `${size + size / 2}px`,
        }}
      >
        <img
          src={userProfileImages[0]}
          className={s.userProfile}
          style={{
            left: 0,
            top: 0,
            width: `${size}px`,
            height: `${size}px`,
            boxShadow: `0 0 0 ${borderSize}px ${vars.$semantic.color.paperDefault}`,
          }}
        />
        <div onClick={() => canEdit && onSetGroupProfile?.()}>
          <ProfileIconWithCameraIcon>
            <img
              src={userProfileImages[1]}
              className={s.userProfile}
              style={{
                right: 0,
                bottom: 0,
                width: `${size}px`,
                height: `${size}px`,
                boxShadow: `0 0 0 ${borderSize}px ${vars.$semantic.color.paperDefault}`,
              }}
            />
          </ProfileIconWithCameraIcon>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        position: 'relative',
        width: `${size + size / 2}px`,
        height: `${size + size / 2}px`,
      }}
    >
      {ProfileImages()}
    </div>
  );
}

export default DefaultTalkRoomProfileImages;
