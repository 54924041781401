import { Spinner } from '@community-group/components';

import { useGetJustStartedGroupList } from '@/api/hooks/useGetJustStartedGroupList';
import { Container } from '@/components/common/Container';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import * as s from './JustStartedGroupLoader.css';

const JustStartedGroupLoader = () => {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useGetJustStartedGroupList({});

  const { ref } = useInfinityScroll({
    callback: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    intersectionOptions: {
      threshold: 0,
    },
  });
  return (
    <Container paddingX={0} style={{ position: 'relative' }}>
      <div className={s.trigger} ref={ref} />
      <div className={s.loading}>
        <Spinner variant="primary" size="small" />
      </div>
    </Container>
  );
};

export default JustStartedGroupLoader;
