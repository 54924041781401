import { AsyncBoundary, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useConnectedFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { groupBoardCategoryFieldSchema } from '@/utils/validate/formSchema/boardCategory';
import { groupSubNicknameQuestionFieldSchema } from '@/utils/validate/formSchema/subNickname';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

export type EditGroupSubnicknameQuestionBottomSheetParams = Pick<PageParams, 'groupId'>;

const EditGroupSubnicknameQuestionBottomSheet: ActivityComponentType<
  EditGroupSubnicknameQuestionBottomSheetParams
> = () => {
  const { pop } = useConnectedFlow();
  return (
    <BottomSheet
      onOutsideClick={(e) => {
        e.preventDefault();
        pop().send({});
      }}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <EditGroupSubnicknameQuestionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const EditGroupSubnicknameQuestionBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { group } = useGetGroupDetail(groupId);

  const { pop } = useConnectedFlow();
  const { watch, register } = useForm<FieldValues>({
    defaultValues: {
      name: group?.subNicknameSetting.requestText ?? '',
    },
  });

  const nameFieldValue = watch('name');

  const handleEditName = async () => {
    pop().send({
      subNicknameSetting: {
        isSettingOn: true,
        requestText: nameFieldValue,
      },
    });
  };

  const isButtonDisabled = useMemo(() => {
    if (nameFieldValue.trim().length === 0) return true;

    return false;
  }, [nameFieldValue]);

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupSubNicknameQuestionFieldSchema, nameFieldValue))
      return false;

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    handleEditName();
  };

  return (
    <>
      <Spacing size={16} />
      <Typography typography="title3Bold" color="gray900">
        별명 입력 요청 문구
      </Typography>
      <Spacing size={6} />
      <Typography typography="subtitle1Regular" color="gray700">
        새로운 멤버가 가입했을 때 어떻게 별명을 작성하면 되는지 안내하는 문구를 적어주세요.
      </Typography>
      <Spacing size={16} />
      <FormInput
        register={register}
        name="name"
        placeholder="별명 입력 요청 문구를 적어주세요."
        minLength={groupBoardCategoryFieldSchema.name.minLength ?? 0}
        maxLength={groupBoardCategoryFieldSchema.name.maxLength ?? 0}
        value={nameFieldValue}
      />
      <Spacing size={16} />
      <FixedButton disabled={isButtonDisabled} onClick={handleSubmit}>
        저장
      </FixedButton>
    </>
  );
};

export default EditGroupSubnicknameQuestionBottomSheet;
