import {
  GroupMemberRoleEnum,
  GroupSidebarApplicationsPresentation,
} from '@community-group/api/lib/group/models';
import { isHigherManager } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconUserGroupFill } from '@seed-design/icon';
import { MouseEventHandler } from 'react';

import GroupLevelBanner from '@/components/common/GroupLevelBanner';
import useRedTeamGroupLevel from '@/components/group/GroupLevel/hooks/useRedTeamGroupLevel';

import GroupDetailSidebarBannerCore from './GroupDetailSidebarBannerCore';
import * as s from './SidebarBanner.css';
import SidebarMemberMissionBanner from './SidebarMemberMissionBanner';

type Props = {
  groupId: number;
  applications?: GroupSidebarApplicationsPresentation;
  onApplicationBannerClick: MouseEventHandler;
  onMissionBannerClick: MouseEventHandler;
  onGroupLevelBannerClick: () => void;
  currentUserRole: GroupMemberRoleEnum;
};

const SidebarBanner = ({
  groupId,
  applications,
  onApplicationBannerClick,
  onMissionBannerClick,
  onGroupLevelBannerClick,
  currentUserRole,
}: Props) => {
  const isSuperHost = currentUserRole === 'superHost';
  const useGroupLevel = useRedTeamGroupLevel();

  // 모임 레벨 배너 - 운영진 + 모임장 노출
  if (isHigherManager(currentUserRole) && useGroupLevel) {
    return (
      <GroupLevelBanner
        groupId={groupId.toString()}
        onClick={() => onGroupLevelBannerClick()}
        size="small"
      />
    );
  }

  if (applications) {
    const memberCount = applications.appliedApplicationCount.toLocaleString('ko-KR');

    return (
      <GroupDetailSidebarBannerCore
        title="가입 요청 확인하기"
        description={`${memberCount}명의 이웃이 기다려요`}
        categoryIcon={
          <div className={s.IconWrapper({ color: 'carrot50' })}>
            <IconUserGroupFill size={18} color={vars.$scale.color.carrot500} />
          </div>
        }
        onClick={onApplicationBannerClick}
      />
    );
  }

  if (!isSuperHost) {
    return <SidebarMemberMissionBanner groupId={groupId} onClick={onMissionBannerClick} />;
  }

  return null;
};

export default SidebarBanner;
