import { z } from 'zod';

export const groupSubNicknameQuestionFieldSchema = z
  .string()
  .trim()
  .min(2, '별명 메시지를 두 글자 이상 입력해주세요.')
  .max(24, '24자 이하로 입력해주세요.');

const subNickname = z
  .string()
  .trim()
  .min(2, '별명을 두 글자 이상 입력해주세요.')
  .max(16, '16자 이하로 입력해주세요.');

const kidProfileBirthday = z
  .string()
  .trim()
  .min(8, '생년월일 8자리를 입력해주세요.')
  .max(8, '생년월일 8자리를 입력해주세요.')
  .regex(/^\d{8}$/, '생년월일은 YYYYMMDD 형식으로 입력해주세요.')
  .refine((value) => {
    const year = parseInt(value.substring(0, 4));
    const month = parseInt(value.substring(4, 6));
    const day = parseInt(value.substring(6, 8));
    return year >= 1900 && year <= 2100 && month >= 1 && month <= 12 && day >= 1 && day <= 31;
  }, '올바른 생년월일을 입력해주세요.');

const kidProfile = z.object({
  birthday: kidProfileBirthday,
  gender: z.enum(['male', 'female']),
});

export const groupMemberProfileFieldSchema = {
  subNickname,
  kidProfile,
  kidProfileBirthday,
};
