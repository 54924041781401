import { Spacing, Typography } from '@community-group/components';
import clsx from 'clsx';

import * as s from './AttendanceCheckGuideTipSection.css';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const GroupMeetupAttendanceCheckGuideTipSection = ({ ...props }: Props) => {
  return (
    <section {...props} className={clsx([props.className, s.wrapper])}>
      <div className={s.titleWrapper}>
        <div className={s.badge}>TIP</div>
        <Typography as="h6" color="blue800" typography="bodyL2Regular">
          출석 체크하는 방법
        </Typography>
      </div>
      <Spacing size={12} />
      <div className={s.contents}>
        <Typography typography="bodyM2Regular" color="gray900" as="p">
          1. 모임 시간이 되면 모임장이 출석 체크 화면을 열어요.
        </Typography>
        <Spacing size={16} />
        <Typography typography="bodyM2Regular" color="gray900" as="p">
          2. 멤버들이 QR코드를 촬영하면 출석을 할 수 있어요.
        </Typography>
        <Spacing size={4} />
        <Typography
          typography="bodyM2Regular"
          color="gray600"
          as="p"
          style={{ paddingLeft: '1rem' }}
        >
          직접 버튼을 눌러 출석을 체크할 수도 있어요.
        </Typography>
      </div>
    </section>
  );
};
