import {
  GroupDetailPresentation,
  GroupFeedMeetupInfoPresentation,
  GroupFeedMeetupSummaryResponse,
  GroupMeetupExposureRangeEnum,
  GroupMeetupProgressStatusPresentation,
} from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { VerticalSpacing } from '@community-group/components';
import { IconChevronRightRegular } from '@seed-design/icon';
import React, { useCallback } from 'react';

import GroupDetailFeedMeetupItem from '@/components/group/DetailV2/components/GroupDetailFeedMeetupItem';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { getMeetupStatusColor } from '@/components/group/Meetup/Detail/utils/progressStatus';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';

interface Props {
  feedMeetup?: GroupFeedMeetupSummaryResponse;
  groupId: string;
  group: GroupDetailPresentation;
  shouldSetSubNickname: boolean;
}

const MeetupSection = ({ feedMeetup, groupId, group, shouldSetSubNickname }: Props) => {
  const { handleGroupOnly, isGroupMember } = useHandleGroupOnly({ groupId });
  const activity = useFlow();

  const getMeetupLabel = useCallback(
    (
      meetupState: GroupMeetupProgressStatusPresentation,
      exposureRange: GroupMeetupExposureRangeEnum
    ) => {
      const showGroupOnlyLabel = isGroupMember && exposureRange !== 'ALL';
      const exposureRangeLabel = showGroupOnlyLabel ? ' ∙ 모임에만 공개' : undefined;

      return (
        <>
          <Typography typography="caption1Regular" color={getMeetupStatusColor(meetupState.status)}>
            {meetupState.description}
          </Typography>
          {exposureRangeLabel}
        </>
      );
    },
    [isGroupMember]
  );
  const handleMeetupListItemClick =
    (meetup: GroupFeedMeetupInfoPresentation, index: number) => () => {
      const blockedGroupOnly = !isGroupMember && meetup.exposureRange !== 'ALL';

      handleGroupOnly({
        isBlock: blockedGroupOnly,
        blockGroupOnlyTitle: '모임 가입 안내',
        blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.enterGroupMeetup,
        onSettled: () => {
          trackEvent({
            event: 'click_meetup_item',
            params: {
              meetupId: meetup.id,
              meetupTitle: meetup.title,
              listItemIndex: index,
              groupId: groupId,
              groupName: group.name,
              groupCategoryName: group.category.name,
              isGroupCategoryOn: group.isBoardManaged,
              role: 'none',
              version: '1',
            },
            sample: true,
          });

          activity.push('GroupMeetupDetailPage', {
            meetupId: meetup.id.toString(),
            groupId,
          });
        },
        onSuccess() {
          if (!shouldSetSubNickname) return;
          activity.push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        },
      });
    };

  const handleMoveToMeetupList = () => {
    trackEvent({
      event: 'click_see_more_meetups',
      params: {
        groupId,
        meetupCount: feedMeetup?.meetups.length ?? 0,
      },
    });
    activity.push('GroupDetailMeetupListPage', {
      groupId,
    });
  };

  const showMoreButton = feedMeetup && feedMeetup.meetups.length >= 3;

  if (!feedMeetup || feedMeetup.meetups?.length === 0) return null;

  return (
    <section className={s.Wrapper}>
      <div className={s.TitleContainer}>
        <Typography typography="title3Bold">일정 {group.meetupCount}</Typography>
        {showMoreButton && (
          <div className={s.More} onClick={handleMoveToMeetupList}>
            <Typography typography="label3Regular" color="gray900">
              더보기
            </Typography>
            <VerticalSpacing size={2} />
            <IconChevronRightRegular size={14} />
          </div>
        )}
      </div>
      <Spacing size={24} />
      {feedMeetup.meetups.map((meetup, index) => (
        <GroupDetailFeedMeetupItem
          key={meetup.id}
          title={meetup.title}
          label={getMeetupLabel(meetup.progressStatus, meetup.exposureRange)}
          meetDate={meetup.meetDate}
          currentParticipantsNumber={meetup.currentParticipantsNumber}
          maximumParticipantsNumber={meetup.maximumParticipantsNumber}
          image={meetup.imageUrl}
          meetTime={meetup?.meetTime}
          showMonth
          blockedGroupOnly={!isGroupMember && meetup.exposureRange !== 'ALL'}
          onClick={handleMeetupListItemClick(meetup, index)}
        />
      ))}
    </section>
  );
};

export default MeetupSection;
