export const shortening = (text: string, length: number, suffix = '...') => {
  const textLength = countText(text);
  if (textLength <= length) return text;

  return substringText(text, length) + suffix;
};

const countText = (text: string): number => {
  if (typeof Intl.Segmenter !== 'undefined') {
    const segmenter = new Intl.Segmenter('en', { granularity: 'grapheme' });
    const segments = Array.from(segmenter.segment(text));

    return segments.length;
  }

  return Array.from(text).length;
};

const substringText = (text: string, length: number): string => {
  if (typeof Intl.Segmenter !== 'undefined') {
    const segmenter = new Intl.Segmenter('en', { granularity: 'grapheme' });
    const segments = Array.from(segmenter.segment(text));

    return segments.reduce((acc, segment, index) => {
      if (index >= length) return acc;

      return acc + segment.segment;
    }, '');
  }

  return text.substring(0, length);
};
