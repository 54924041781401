import { useBottomSheet } from '@community-group/components';
import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCheckRegular } from '@seed-design/icon';

import { MEMBER_LIST_ORDER_TYPE, MemberListOrderType } from '@/api/hooks/useGetMemberList';

import * as s from './MemberOrderTypeBottomSheet.css';

type Props = {
  selectedOrderType: MemberListOrderType;
  setSelectedOrderType: (orderType: MemberListOrderType) => void;
};

export const MemberOrderTypeBottomSheet = ({ selectedOrderType, setSelectedOrderType }: Props) => {
  const orderTypeKeys = Object.keys(MEMBER_LIST_ORDER_TYPE);
  const { closeAsync } = useBottomSheet();

  const handleClick = (value: MemberListOrderType) => {
    setSelectedOrderType(value);
    closeAsync();
  };

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>정렬</h1>
      <Spacing size={16} />
      <ul className={s.sortingList}>
        {orderTypeKeys.map((orderType) => (
          <li
            className={s.sortingItem({ selected: orderType === selectedOrderType })}
            key={orderType}
            onClick={() => {
              handleClick(orderType as MemberListOrderType);
            }}
          >
            {MEMBER_LIST_ORDER_TYPE[orderType as MemberListOrderType]}
            {orderType === selectedOrderType && (
              <div className={s.iconWrapper}>
                <IconCheckRegular size={24} color={vars.$scale.color.carrot500} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
