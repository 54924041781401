import clsx from 'clsx';
import React from 'react';

import ImageFieldItem from '../../HookForm/components/field/ImageFieldItem';
import { HookFormImagePlugins, useHookFormImages } from '../../HookForm/hooks/useHookFormImages';
import { useStickyInputFormContext } from '../provider/StickyInputFormProvider';
import * as s from './ImageList.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  plugins: HookFormImagePlugins;
}

const StickyInputImageList = ({ plugins, ...props }: Props) => {
  const { watch } = useStickyInputFormContext();
  const { removeImages, retryImage } = useHookFormImages({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleHookFormContext: useStickyInputFormContext,
    plugins,
  });

  const originImages = watch('originImages');

  if (originImages.length <= 0) return <></>;

  return (
    <div {...props} className={clsx([props.className, s.wrapper])}>
      {originImages.map(({ id, image }) => (
        <ImageFieldItem
          key={id}
          imageId={id}
          className={s.imageField}
          image={image}
          onRemoveClick={() => {
            removeImages(id);
          }}
          onRetryClick={() => {
            retryImage(id);
          }}
        />
      ))}
    </div>
  );
};

export default React.memo(StickyInputImageList);
