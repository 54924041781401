import { isHigherManager as checkIsHigherManager } from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GotoGroupHomeBanner from '../../DetailNotMember/components/GroupHeaderBanner';
import useGroupDetailGroupBotBannerClosed from '../hooks/useGroupDetailGroupBotBannerClosed';
import useGroupDetailHeroImageBannerClosed from '../hooks/useGroupDetailHeroImageBannerClosed';
import GroupDetailGroupBotBanner from './GroupDetailGroupBotBanner';
import GroupDetailHeroSliderBanner from './GroupDetailHeroSliderBanner';
import { GroupPhotoContestBanner } from './GroupPhotoContestBanner';

type Props = { groupId: number };

function GroupDetailBanner({ groupId }: Props) {
  const { refer_root = '' } = useQueryParams();

  const { data: groupMe } = useGetGroupMe(groupId.toString());
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);
  const isSuperHost = useMemo(() => currentUserRole === 'superHost', [currentUserRole]);
  const isHigherManager = useMemo(() => checkIsHigherManager(currentUserRole), [currentUserRole]);
  const isNotMember = useMemo(() => currentUserRole === 'none', [currentUserRole]);

  const isExistHeroSliderPhotos = useGetIsExistHeroSlider({ groupId: groupId.toString() });
  const shownPhotoContest = useFeatureFlag('shownPhotoContest');

  const groupBotEnabled = useFeatureFlag('groupBotEnabled');
  const [groupBotBannerClosed] = useGroupDetailGroupBotBannerClosed(groupId.toString());

  const [heroImageBannerClosed] = useGroupDetailHeroImageBannerClosed(groupId.toString());

  // TODO: 히어로 슬라이더 레드팀 분기
  // 히어로 슬라이더 관련 사진 첨부 or 게시글 작성 안내 배너
  if (isHigherManager && !heroImageBannerClosed) {
    return <GroupDetailHeroSliderBanner groupId={groupId} />;
  }

  if (groupBotEnabled && isSuperHost && !groupBotBannerClosed) {
    return <GroupDetailGroupBotBanner groupId={groupId} />;
  }

  // V2 사진전 배너 && 히어로 배너 노출 가능한 경우
  if (shownPhotoContest && groupMe?.currentUser?.role !== 'none') {
    return <GroupPhotoContestBanner />;
  }

  // 동네생활 피드에서 진입하는 경우 && 미가입자 인 경우
  if (refer_root === 'community_tab' && isNotMember) {
    return <GotoGroupHomeBanner isExistHeroSliderPhotos={isExistHeroSliderPhotos} />;
  }

  return null;
}

export default GroupDetailBanner;
