import { IconCloseRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';
import { useState } from 'react';

import * as s from './index.css';

const OpenKakaoBanner = ({ marginX = true }: { marginX?: boolean }) => {
  const [isToolTipOpen, setTooltipOpen] = useState(true);

  const handleBannerClose = () => {
    setTooltipOpen(false);
  };

  if (!isToolTipOpen) return <></>;

  return (
    <motion.div
      className={s.wrapper}
      style={{
        margin: marginX ? '1rem' : '1rem 0px',
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
      }}
    >
      <p>
        <span className={s.text}>
          모임에서 대화하는 게 가장 안전해요. <br /> 외부 채팅방 링크를 공유하면 모임이 제재될 수
          있어요.
        </span>
      </p>
      <div>
        <button onClick={handleBannerClose}>
          <IconCloseRegular size={18} />
        </button>
      </div>
    </motion.div>
  );
};

export default OpenKakaoBanner;
