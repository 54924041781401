import { ProfileInfoExposureRangeEnum } from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
  useSnackbarAdapter,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import { useCallback } from 'react';

import { usePutGroupProfileReviewMessage } from '@/api/hooks/usePutGroupProfileReviewMessage';
import { trackEvent } from '@/utils/analytics';

type Props = {
  reviewMessageId: number;
  reviewMessageExposureRange: ProfileInfoExposureRangeEnum;
  reviewMessage: string;
};

export const ProfileMeetupMemberReviewMessageItemMoreButton = ({
  reviewMessageId,
  reviewMessageExposureRange,
  reviewMessage,
}: Props) => {
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();
  const snackbarAdapter = useSnackbarAdapter();
  const { mutate } = usePutGroupProfileReviewMessage();

  const handleMoreButtonClick = useCallback(() => {
    if (reviewMessageExposureRange === 'ALL') {
      openBottomSheet({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            <ActionList>
              <ActionListItem
                onClick={async () => {
                  await closeBottomSheet();

                  mutate(
                    {
                      reviewMessageId,
                      reviewMessageExposureRange: 'ONLY_ME',
                    },
                    {
                      onSuccess: () => {
                        trackEvent({
                          event: 'click_hide_review_from_other',
                          params: {
                            reviewMessageId,
                            reviewMessage,
                          },
                        });
                        snackbarAdapter.create({
                          message: '후기를 숨겼어요',
                          type: 'default',
                          timeout: 3000,
                          onClick: () => {
                            snackbarAdapter.dismiss();
                          },
                        });
                      },
                    }
                  );
                }}
              >
                후기 숨기기
              </ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
      });

      return;
    }

    openBottomSheet({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            <ActionListItem
              onClick={async () => {
                await closeBottomSheet();

                mutate(
                  {
                    reviewMessageId,
                    reviewMessageExposureRange: 'ALL',
                  },
                  {
                    onSuccess: () => {
                      trackEvent({
                        event: 'click_show_review_from_other',
                        params: {
                          reviewMessageId,
                          reviewMessage,
                        },
                      });
                      snackbarAdapter.create({
                        message: '후기를 공개했어요',
                        type: 'default',
                        timeout: 3000,
                        onClick: () => {
                          snackbarAdapter.dismiss();
                        },
                      });
                    },
                  }
                );
              }}
            >
              후기 공개하기
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  }, [reviewMessageId, reviewMessageExposureRange]);

  return <IconMoreVertRegular color={vars.$scale.color.gray600} onClick={handleMoreButtonClick} />;
};
