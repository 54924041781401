export const VITE_APP_DEBUG_EVENT = import.meta.env.VITE_APP_DEBUG_EVENT === 'true';

export const VITE_APP_USER_AUTH_TOKEN = import.meta.env.VITE_APP_TOKEN ?? '';

export const alphaAuthToken = () => {
  const env_token = VITE_APP_USER_AUTH_TOKEN;
  if (!env_token) {
    // auth_token을 가져오는 방법은 아래를 참고해주세요
    // https://www.notion.so/f0984561b4b34cf5adc2adf58657b40f#fe5afcfcc0414dd19386f5d7f59c0cb9
    throw new Error('/.env 파일에 VITE_APP_TOKEN 을 정의해주세요.');
  }
  return env_token as string;
};

export const VITE_APP_USER_ID = parseInt(import.meta.env.VITE_APP_USER_ID as unknown as string);

export const VITE_APP_HASH_ID = import.meta.env.VITE_APP_HASH_ID as unknown as string;
