import { GroupMeetupMemberSummaryPresentation } from '@community-group/api/lib/group/models';
import { GroupAvatar, Typography, withAsyncBoundary } from '@community-group/components';
import { Spacing, VerticalSpacing } from '@community-group/components';
import { IconChevronRightRegular } from '@seed-design/icon';
import { MouseEventHandler, useMemo } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { useGetGroupMeetupMembers } from '@/api/hooks/useGetGroupMeetupMembers';

import * as s from './GroupMeetupMemberList.css';

type Props = {
  groupId: string;
  meetupId: string;
  hostUserId: number;
  onMeetupMemberListItemClick: (member: GroupMeetupMemberSummaryPresentation) => MouseEventHandler;
  onMoreButtonClick: MouseEventHandler;
};

const GroupMeetupMemberList = ({
  groupId,
  meetupId,
  hostUserId,
  onMeetupMemberListItemClick,
  onMoreButtonClick,
}: Props) => {
  const { data, hasNextPage } = useGetGroupMeetupMembers({
    groupId,
    meetupId,
    meetupJoinStatus: 'join',
    limit: 10,
  });
  const members = useMemo(() => data?.pages[0].data.members ?? [], [data?.pages]);
  const hasMoreButton = hasNextPage ?? false;

  return (
    <ul className={s.MemberList}>
      {members.map((member, index) => {
        const isHost = hostUserId === member.userId;
        const isLast = index === members.length - 1;

        return (
          <Fragment key={member.userId}>
            <li className={s.MemberListItem} onClick={onMeetupMemberListItemClick(member)}>
              <GroupAvatar src={member.profileImage} size="medium" />
              <Spacing size={8} />
              <Typography typography="label4Regular" color="gray900" ellipsisAfterLines={1}>
                {member.nickname}
              </Typography>
              {isHost ? (
                <div className={s.HostLabel}>
                  <Typography typography="label5Bold" color="gray00">
                    주최
                  </Typography>
                </div>
              ) : (
                <Typography typography="label4Regular" color="gray600" ellipsisAfterLines={1}>
                  {member.subNickname ?? member.description}
                </Typography>
              )}
            </li>
            {!isLast && <VerticalSpacing size={4} />}
            {isLast && hasMoreButton && (
              <li className={s.MemberListItem} onClick={onMoreButtonClick}>
                <div className={s.MoreButtonWrapper}>
                  <IconChevronRightRegular size={20} color="gray900" />
                </div>
                <Spacing size={8} />
                <Typography typography="label4Regular" color="gray900" ellipsisAfterLines={1}>
                  더보기
                </Typography>
              </li>
            )}
          </Fragment>
        );
      })}
    </ul>
  );
};

export default withAsyncBoundary(GroupMeetupMemberList, {});
