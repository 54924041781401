import { useCallback } from 'react';

import { FileUploadState } from '../types/Video';
import { generateThumbnailFromVideo } from '../utils/thumbnail';
import { DEFAULT_VALIDATION_CONFIG, ValidationConfig } from '../utils/video';
import { ManageUploadStateProps, useManageUploadState } from './useManageUploadState';
import { usePickVideoFiles } from './usePickVideoFiles';
import { UseUploadFileProps, useUploadVideoFile } from './useUploadVideoFile';
import { useVideoValidation } from './useVideoValidation';

export type VideoFileHandlerProps = {
  validationConfig?: Partial<ValidationConfig>;
  externalStateSelector?: ManageUploadStateProps['externalStateSelector'];
  theme?: 'android' | 'cupertino';
} & Omit<UseUploadFileProps, 'updateUploadStatus'>;

export const useVideoFileHandler = ({
  validationConfig = DEFAULT_VALIDATION_CONFIG,
  externalStateSelector,
  uploadVideo,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
  onValidationError,
  theme = 'android',
}: VideoFileHandlerProps) => {
  // 파일 상태 관리 -> 외부 상태와 동기화
  const { uploadedFiles, addUpload, updateUploadStatus, removeUpload } = useManageUploadState({
    externalStateSelector,
  });

  // 비디오 파일 선택
  const { pickVideoFiles } = usePickVideoFiles({});

  // 비디오 파일 유효성 검사
  const { validateAllFiles } = useVideoValidation({
    validationConfig,
  });

  // useUploadVideoFile 훅 사용
  const { startUpload } = useUploadVideoFile({
    uploadVideo,
    updateUploadStatus,
    onUploadStart,
    onUploadSuccess,
    onUploadError,
  });

  const addThumbnail = useCallback(
    async (file: File, id: string) => {
      const thumbnail = await generateThumbnailFromVideo(file, theme);
      updateUploadStatus(id, { thumbnail });
    },
    [theme, updateUploadStatus]
  );

  // 비디오 파일 선택 및 업로드 로직
  const handleVideoFileSelection = async () => {
    const selectedFiles = await pickVideoFiles();
    if (!selectedFiles || selectedFiles.length === 0) return;

    // 2. 모든 파일에 대한 유효성 검사 수행
    const { validFiles, validationErrors } = await validateAllFiles(
      selectedFiles,
      uploadedFiles.length
    );

    // 3. 유효성 검사에 실패한 파일이 있는 경우 업로드 중단
    if (validationErrors.length > 0) {
      onValidationError?.(validationErrors);
    }

    // 모든 파일을 병렬로 업로드
    await Promise.all(
      validFiles.map(async (file, index) => {
        // 2. 상태에 추가
        const id = `${index}-${file.name}-${Date.now()}`;

        const newFileState: FileUploadState = {
          id,
          file,
          thumbnail: undefined,
          status: 'idle',
          progress: 0,
        };
        addUpload(newFileState);

        // 3. 썸네일 생성 - 파일 업로드와 동기 처리
        addThumbnail(file, id);

        // 4. 파일 업로드 시작
        startUpload(newFileState);
      })
    );
  };

  // 파일 업로드 취소
  const cancelVideoUpload = (fileState: FileUploadState) => {
    removeUpload(fileState.id);
  };

  // 파일 업로드 재시도
  const retryVideoUpload = (fileState: FileUploadState) => {
    startUpload(fileState);
  };

  return {
    selectedFiles: uploadedFiles,
    handleVideoFileSelection,
    cancelVideoUpload,
    retryVideoUpload,
  };
};
