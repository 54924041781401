import { Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconHelpRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import LeaderBoardHeader from '../components/LeaderBoardHeader';
import RankGroupList from '../components/RankGroupList';
import * as s from './index.css';

type GroupLeaderBoardParams = Pick<PageParams, 'isCurrentWeek'>;

const GroupLeaderBoard: ActivityComponentType<GroupLeaderBoardParams> = () => {
  const { push } = useFlow();
  const { isCurrentWeek } = useQueryParams();
  const isCurrentWeekBoolean = !isCurrentWeek || isCurrentWeek !== 'false';
  const usePrevWeekLeaderBoardBanner = useFeatureFlag('usePrevWeekLeaderBoardBanner');

  const { data: leaderBoardData } = useGetGroupLeaderBoard({
    weekAgo: isCurrentWeekBoolean ? 0 : 1,
  });

  const [visibleAppBarDivider, setVisibleAppBarDivider] = useState(false);

  const { ref, inView } = useInView({
    root: null,
    rootMargin: '1px',
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setVisibleAppBarDivider(false);
    } else {
      setVisibleAppBarDivider(true);
    }
    return () => {};
  }, [inView]);

  return (
    <AppScreen
      appBar={{
        border: visibleAppBarDivider ? true : false,
        borderColor: vars.$semantic.color.divider1,
        borderSize: '1px',
        renderRight: () => (
          <div
            className={s.IconWrapper}
            onClick={() =>
              push('BottomSheet/GroupLeaderBoardGuideBottomSheet', {
                baseDate: leaderBoardData.baseDate,
              })
            }
          >
            <IconHelpRegular size={24} color={vars.$scale.color.gray900} />
          </div>
        ),
      }}
    >
      <div className={s.Wrapper}>
        <div style={{ width: '100%' }} ref={ref} />
        <LeaderBoardHeader
          baseYear={leaderBoardData.baseYear}
          baseWeek={leaderBoardData.baseWeek}
          isCurrentWeek={!!isCurrentWeekBoolean}
        />
        <RankGroupList
          isCurrentWeek={!!isCurrentWeekBoolean}
          usePrevWeekLeaderBoardBanner={usePrevWeekLeaderBoardBanner ?? false}
        />
        <Spacing size={60} />
      </div>
    </AppScreen>
  );
};

export default GroupLeaderBoard;
