import { Skeleton, Typography } from '@community-group/components';
import { Spacing } from '@community-group/components';

import { Container } from '@/components/common/Container';

import * as s from './FilterSkeleton.css';

const FilterSkeleton = () => (
  <div className={s.wrapper}>
    <Spacing size={24} />
    <Typography as="h3" typography="title3Bold" color="gray900" style={{ marginBottom: '1rem' }}>
      우리동네 모임
    </Typography>
    <Container className={s.categoryContainer} paddingY={0} paddingX={0}>
      {Array.from({ length: 8 }).map((item, index) => (
        <div className={s.categoryButton} key={index}>
          <Skeleton width="64px" height="64px" borderRadius="32px" />
          <Spacing size={12} />
          <Skeleton width="64px" height="20px" borderRadius="32px" />
        </div>
      ))}
    </Container>
    <Spacing size={12} />
    <div className={s.filterWrapper}>
      <Skeleton width="4.75rem" height="2rem" borderRadius="6.25rem" />
      <Skeleton width="5.9375rem" height="2rem" borderRadius="6.25rem" />
    </div>
  </div>
);

export default FilterSkeleton;
