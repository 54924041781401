import { ChallengeStatusEnum } from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import * as s from './style.css';

type Props = {
  status: ChallengeStatusEnum;
  name: string;
};
const ChallengeTitle = ({ status, name }: Props) => {
  const prefix: { color: string; text: string } = useMemo(() => {
    if (status === 'prepared') return { color: vars.$scale.color.yellow400, text: '진행 예정' };
    if (status === 'opened') return { color: vars.$semantic.color.primary, text: '진행중' };
    return { color: vars.$scale.color.gray600, text: '종료' };
  }, [status]);

  return (
    <div className={s.Wrapper}>
      <span className={s.InlineText} style={{ marginRight: '.25rem', color: prefix.color }}>
        {prefix.text}
      </span>
      <span className={s.InlineText}>{name}</span>
    </div>
  );
};

export default ChallengeTitle;
