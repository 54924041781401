import { ActivityComponentType } from '@stackflow/react';
import React from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { ActivityQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import Feed from '../../Detail/components/Feed';
import FeedList from '../../Detail/components/Feed/FeedList';

export type GroupDetailNotMemberMeetupReviewListPageParams = Pick<PageParams, 'groupId'>;

// 미가입자 일정 후기 리스트 페이지
const GroupDetailNotMemberMeetupReviewListPage: ActivityComponentType<
  GroupDetailNotMemberMeetupReviewListPageParams
> = (params) => {
  const { groupId = '0' } = usePathParams();
  const { group } = useGetGroupDetail(groupId);

  if (!group) return null;

  return (
    <AppScreen appBar={{ title: '일정 후기' }} disabledClientOnly>
      <FeedList selectedCategoryKey={0} groupDetail={group} feedType="group_meetup_review" />
    </AppScreen>
  );
};

export default GroupDetailNotMemberMeetupReviewListPage;
