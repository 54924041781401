import { GroupChallengeCreateForm } from '@community-group/api/lib/group/models';
import {
  addMonths,
  formatDate,
  KARROT_DATE_FORMAT,
  parse,
  Typography,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChallengeRegular } from '@seed-design/icon';
import { useFlow } from '@stackflow/react/future';
import { useMemo } from 'react';
import { DayModifiers } from 'react-day-picker';

import { GroupChallengeFormProps } from '../../../types';
import { GroupChallengeFormDatePicker } from '../DatePicker';
import { GroupChallengeFormFieldWrapper } from '../FieldWrapper';
import * as s from './style.css';

type Props = GroupChallengeFormProps & {
  dateFieldName: keyof GroupChallengeCreateForm;
  onClick?: () => void;
};

export const GroupChallengeFormDateTimeField = ({ formHandler, dateFieldName, onClick }: Props) => {
  const { push } = useFlow();

  const { watch } = formHandler;

  const today = new Date();
  const challengeStartTime = useMemo(
    () => new Date(watch(dateFieldName) as string),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch(dateFieldName)]
  );

  const currentTimeString = useMemo(
    () => formatDate(challengeStartTime, 'HH:mm:ss.SSSXXX'),
    [challengeStartTime]
  );

  const handleSelectDate = (day: Date, { disabled }: DayModifiers) => {
    if (disabled) return;

    const changedDate = parse(
      `${formatDate(day, 'yyyy-MM-dd')}T${currentTimeString}`,
      KARROT_DATE_FORMAT,
      new Date()
    );

    formHandler.setValue(dateFieldName, formatDate(changedDate, KARROT_DATE_FORMAT));
  };

  return (
    <GroupChallengeFormFieldWrapper
      onClick={onClick}
      fieldIcon={<IconChallengeRegular size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="시작일"
      fieldName={dateFieldName}
      fieldValue={
        <Typography textAlign="right" typography="label2Regular" color="gray900">
          {formatDate(challengeStartTime, 'M월 d일 (ccc)')}
        </Typography>
      }
    >
      <div className={s.Wrapper}>
        <GroupChallengeFormDatePicker
          disabled={[{ before: today }, { after: addMonths(today, 2) }]}
          fromMonth={today}
          toDate={addMonths(today, 2)}
          selected={challengeStartTime}
          onDayClick={handleSelectDate}
        />
      </div>
    </GroupChallengeFormFieldWrapper>
  );
};
