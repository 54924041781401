import { Image } from '@community-group/api/lib/group/models';
import { Spacing } from '@community-group/components';

import { useGetGroupLeaderBoard } from '@/api/hooks/useGetGroupLeaderBoard';

import PrevWeekBanner from './PrevWeekBanner';
import RankGroupItem from './RankGroupItem';
import * as s from './RankGroupList.css';

const RankGroupList = ({
  isCurrentWeek,
  usePrevWeekLeaderBoardBanner,
}: {
  isCurrentWeek: boolean;
  usePrevWeekLeaderBoardBanner: boolean;
}) => {
  const { data: leaderBoardData } = useGetGroupLeaderBoard({
    weekAgo: isCurrentWeek ? 0 : 1,
  });

  const leaderBoardList = leaderBoardData?.rankList ?? [];

  const showPrevWeekBanner = isCurrentWeek && usePrevWeekLeaderBoardBanner;

  return (
    <div className={s.Wrapper}>
      {leaderBoardList?.map((item) => {
        const BottomSpacingSize = item.rank === 1 ? 16 : 24;
        return (
          <div key={item.groupId}>
            <RankGroupItem
              rank={item.rank}
              groupId={item.groupId}
              groupName={item.groupName}
              groupThumbnailUrl={item.groupThumbnailUrl as Image}
              groupLevel={item.groupLevel}
              displayRegionName={item.displayRegionName}
              weeklyActivityPoint={item.weeklyActivityPoint}
            />
            <Spacing size={BottomSpacingSize} />
            {item.rank === 5 && showPrevWeekBanner && (
              <>
                <PrevWeekBanner />
                <Spacing size={24} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RankGroupList;
