import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { forwardRef, InputHTMLAttributes, ReactNode, useMemo } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { v4 } from 'uuid';

import * as s from './Badge.css';

export type Props = {
  name: string;
  value: number;
  children: ReactNode;
  checked: boolean;
  register?: UseFormRegister<FieldValues>;
  onClick?: () => void;
} & InputHTMLAttributes<HTMLInputElement>;

const FormBadgeRadio = ({
  name,
  value = 0,
  children,
  register,
  checked,
  onClick,
  ...props
}: Props) => {
  const uniqueId = useMemo(() => v4(), []);

  return (
    <motion.div
      className={s.badgeRadioBox({ checked })}
      animate={{
        backgroundColor: checked ? vars.$scale.color.gray700 : vars.$semantic.color.paperDefault,
        border: `solid 1px ${checked ? vars.$scale.color.gray700 : vars.$scale.color.gray200}`,
      }}
      initial={{ backgroundColor: vars.$semantic.color.paperDefault }}
      transition={{
        duration: 0.1,
      }}
      onClick={onClick}
    >
      <input
        id={uniqueId}
        className={clsx('input', s.badgeRadioHidden)}
        type="radio"
        defaultValue={value}
        {...props}
        {...(register && register(name))}
      />
      <label htmlFor={uniqueId}>{children}</label>
    </motion.div>
  );
};

export default FormBadgeRadio;

type BadgeRadioWrapperProps = InputHTMLAttributes<HTMLDivElement>;

export const BadgeRadioWrapper = forwardRef((props: BadgeRadioWrapperProps, ref: any) => (
  <div ref={ref} className={clsx(props.className, s.badgeRadioWrapper)} {...props} />
));
