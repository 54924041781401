import clsx from 'clsx';
import { useLayoutEffect } from 'react';
import React from 'react';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import * as s from './Description.css';

interface Props<T extends object> extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupFormDescriptionField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(name, event.target.value);
  };

  const onHeightChange = React.useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const prevAlignment = input.style.alignSelf;
      const prevOverflow = input.style.overflow;
      const isFirefox = 'MozAppearance' in input.style;
      if (!isFirefox) {
        input.style.overflow = 'hidden';
      }
      input.style.alignSelf = 'start';
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight + (input.offsetHeight - input.clientHeight)}px`;
      input.style.overflow = prevOverflow;
      input.style.alignSelf = prevAlignment;
    }
  }, [inputRef]);

  const value = watch(name);

  useLayoutEffect(() => {
    if (inputRef.current) {
      onHeightChange();
    }
  }, [onHeightChange, value, inputRef]);

  return (
    <textarea
      data-field-name={name}
      {...props}
      ref={inputRef}
      className={clsx([props.className, s.Textarea])}
      defaultValue={watch(name)}
      onChange={handleChange}
    />
  );
};
