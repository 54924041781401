import { resizeImage, ResizeOptions } from '@community-group/components';

type Props = {
  image: {
    url: string;
  };
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

export const AlbumPhotoItem = ({ image, ...props }: Props) => {
  const width = 'calc(100vw / 3)';
  const height = width;

  return (
    <img
      {...props}
      width={width}
      height={height}
      src={resizeImage(image.url, ResizeOptionConfig)}
      alt="?"
      style={{
        maxWidth: '100%',
        width,
        height,
        aspectRatio: '1/1',
      }}
    />
  );
};

const ResizeOptionConfig = {
  size: '320x320',
  quality: 'low',
  type: 'crop',
} as ResizeOptions;
